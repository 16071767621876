import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import { Provider } from 'mobx-react';
import { ToastContainer } from 'react-toastify';
// Project components
import GlobalStyle from './common/GlobalStyle/index';
import theme from './styles/themev2';
import store from './modules';
import Routes from './routes/Routes';
import { initGA } from './utilities/analytics';
// CSS
import 'assets/scss/modern.scss';

if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: 'https://45c5ef8a52364c51bbcef5bfc494694f@sentry.io/2224416',
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

const App = () => {
  useEffect(() => {
    initGA();
  }, []);

  return (
    // Add StrictMode once we got everything working
    // <React.StrictMode>
    <>
      <Provider {...store}>
        <Routes />
      </Provider>
      <GlobalStyle {...theme} />
      <ToastContainer position='top-right' theme='colored' />
    </>
    // </React.StrictMode>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
