import * as React from 'react';
import { BaseBox } from './styles';
import { BoxProps } from '../../@types';

export interface BaseBoxProps extends BoxProps {
  children?: React.ReactNode;
}

const Box = ({ children, ...rest }: BaseBoxProps) => <BaseBox {...rest}>{children}</BaseBox>;

const AuthBox = ({ children, ...rest }: BaseBoxProps) => (
  <BaseBox
    flex={['0 0 100%', '0 0 100%', '0 0 83.333333%']}
    maxWidth={['100%', '100%', '83.33333%']}
    marginLeft="8.33333%"
    {...rest}
  >
    {children}
  </BaseBox>
);

export { AuthBox };
export default Box;
