import React, { Component } from 'react';
import { Loading } from 'common';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

const Link = styled.label`
  color: var(--blue);
  text-decoration: underline;
  cursor: pointer;
`;

@inject('integrations')
@withRouter
@observer
class Integration extends Component {
  @observable emailValidated = false;
  @observable loading = true;
  @observable emailAddressError = false;
  @observable errorMessage = null;
  @observable loginSystem = 'Google';

  componentDidMount = () => {
    const {
      location: { search },
    } = this.props;

    this.loginSystem = this.props.match.params.loginSystem;

    let requestObj = {};
    if (search) {
      let hashes = search.slice(search.indexOf('?') + 1).split('&');
      let params = {};
      hashes.forEach((hash) => {
        let [key, val] = hash.split('=');
        params[key] = decodeURIComponent(val);
      });

      if (params.error) {
        if (params.error === 'access_denied') {
          this.errorMessage =
            'Access to your Google Calendar was denied. Please try again and grant Clearhead Access to your Calendar';
          this.loading = false;
        } else {
          this.errorMessage = params.error;
          this.loading = false;
        }
      } else if (!params.state || !params.code) {
        this.errorMessage = 'Invalid Address';
        this.loading = false;
      } else {
        this.requestObj = { state: params.state, code: params.code };

        if (this.loginSystem.toLowerCase() === 'google') {
          this.googleLogin(requestObj);
        } else if (this.loginSystem.toLowerCase() === 'outlook') {
          this.outlookLogin(requestObj);
        }
      }
    } else {
      this.errorMessage = 'Invalid Address';
      this.loading = false;
    }
  };

  googleLogin = (requestObj) => {
    const { integrations, history } = this.props;
    this.loading = true;
    if (this.requestObj) {
      integrations.connectToGoogleCalendar(this.requestObj).then((response) => {
        if (!response.hasError) {
          this.loading = false;
          // https://provider.dev.myclearhead.co.nz/my-account?section=integrations
          history.push('/app/my-account?section=integrations');
        } else {
          if (response.status === 302) {
            window.location = response.data;
          } else {
            if (response.errors && response.errors.length > 0) {
              this.errorMessage = response.errors[0].defaultMessage;
            } else {
              this.errorMessage = response.message;
            }
            this.emailValidated = false;
          }
        }
        this.loading = false;
      });
    }
  };

  outlookLogin = (requestObj) => {
    const { integrations, history } = this.props;
    this.loading = true;
    if (this.requestObj) {
      integrations
        .connectToOutlookCalendar(this.requestObj)
        .then((response) => {
          if (!response.hasError) {
            this.loading = false;
            history.push('/app/my-account?section=integrations');
          } else {
            if (response.status === 302) {
              window.location = response.data;
            } else {
              if (response.errors && response.errors.length > 0) {
                this.errorMessage = response.errors[0].defaultMessage;
              } else {
                this.errorMessage = response.message;
              }
              this.emailValidated = false;
            }
          }
          this.loading = false;
        });
    }
  };

  returnFromError = () => {
    this.props.history.push('/app/my-account');
  };

  returnToCalendar = () => {
    this.props.history.push('/app/appointment-calendar');
  };

  render() {
    const loginSystemDisplayName =
      this.loginSystem.charAt(0).toUpperCase() + this.loginSystem.slice(1);
    if (this.loading) {
      return (
        <div>
          <p className="h4">
            Connecting to your {loginSystemDisplayName} Calendar...
          </p>
          <Loading />
        </div>
      );
    } else if (this.errorMessage) {
      return (
        <div>
          <p className="h3">
            An error occurred while connecting to your {loginSystemDisplayName}{' '}
            Calendar:
          </p>
          <p className="h4">{this.errorMessage}</p>
          <p>
            Click <Link onClick={this.returnFromError}>here</Link> to return to
            the my account page
          </p>
        </div>
      );
    } else if (!this.loading) {
      return (
        <div>
          <p className="h2">
            {loginSystemDisplayName} Calendar Connected Successfully
          </p>
          <p className="h4">
            Your {loginSystemDisplayName} Calendar is currently being synced in
            the background. Depending on the size of your calendar this may take
            up to an hour. You can safely close this window.
          </p>
          <p>
            Click <Link onClick={this.returnToCalendar}>here</Link> to return to
            your calendar
          </p>
        </div>
      );
    }
  }
}

export default Integration;
