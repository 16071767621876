import React, { Component } from 'react';
import styled from 'styled-components';

import MissingSvg from 'jsx:assets/svg/question-mark.svg';

const Container = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
`;

const MissingIcon = styled(MissingSvg)`
  width: 50%;
  height: 50%;
`;

class MissingAvatar extends Component {
  render() {
    return (
      <Container className='avatar img-fluid rounded-circle mr-1'>
        <MissingIcon viewBox='0 0 24 24' />
      </Container>
    );
  }
}

export default MissingAvatar;
