import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import Box from '../Box';
import { BoxProps } from '../../@types';

interface IBaseCard extends BoxProps, React.HTMLAttributes<HTMLDivElement> {
  modifiers?: string[];
}

const CARD_MODIFIERS = {
  shadow: () => `
    box-shadow: 0 2px 4px 1px hsla(0, 0%, 0%, 0.15);
  `,
  subtleShadow: () => `
    box-shadow: 0 1px 4px -1px hsla(0,0%,0%,0.15);
  `,
  noShadow: () => `
    box-shadow: none;
  `,
  inverted: () => `
    background-color: #FBFBFB;
    border: 1px solid #EDF1F6;
  `,
  list: () => `
    box-shadow: none;
    padding: 0px;
    border-radius: 0px;
    &:not(:last-child) {
      border-bottom: 1px solid #f5f5f5;
    }
  `,
};

export const BaseCard = styled(Box)<IBaseCard>`
  position: relative;
  padding: 14px;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 0 1px hsla(0, 0%, 0%, 0.2), 0 0px 3px -1px hsla(0, 0%, 0%, 0.25);

  ${applyStyleModifiers(CARD_MODIFIERS)}
`;

export const CardHeader = styled.div`
  margin: -14px -14px 14px -14px;
  padding: 14px;
  border-bottom: 1px solid #f2f2f2;
`;

export const CardFooter = styled.div`
  margin: 14px -14px -14px -14px;
  padding: 14px;
  border-top: 1px solid #f2f2f2;
`;
