import React, { Component } from 'react';
import { Formik } from 'formik';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { timezoneOptions } from 'configuration/timezones';

import {
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  InputGroupText
} from 'reactstrap';
import Select from 'react-select';

import Day from './day';
import {
  InputNum,
  EndsGrid,
  CustomRadio,
  CustomInputBox,
  CustomRadioLabel,
  CustomInputGroup,
  SelectRepeat,
} from './styles';

require('moment-recur');

type Props = {
  onComplete: Function,
};

const selectRepeatOptions = [
  { label: 'Days', value: 'DAYS' },
  { label: 'Weeks', value: 'WEEKS' },
  { label: 'Months', value: 'MONTHS' },
];

const formatObj = {
  1: 'st',
  2: 'nd',
  3: 'rd',
  4: 'th',
  5: 'th',
};

const getFormat = (num) => `${num}${formatObj[num]}`;

@inject('auth')
@observer
class RepeatBookingForm extends Component<Props> {
  static defaultProps = {
    onComplete: () => {},
    currentDate: null,
  };

  getSubmitError = (status) => {
    if (status && status.message) {
      return <FormFeedback>{status.message}</FormFeedback>;
    }
    return null;
  };

  getCurrentDate = () => {
    const { currentDate } = this.props;
    const momentDate = moment(currentDate);
    return momentDate.isValid() ? momentDate : moment();
  };

  onValidate = (values) => {
    let errors = {};

    if (values.repeatOption.value === 'WEEKS') {
      const anyDaySelected =
        values.monday |
        values.tuesday |
        values.wednesday |
        values.thursday |
        values.friday |
        values.saturday |
        values.sunday;
      if (!anyDaySelected) {
        errors.repeatOn = 'Must have at least one day selected.';
      }
    }

    if (values.repeatOption === 'MONTHS') {
      if (!values.selectRepeatOn) {
        errors.selectRepeatOn = 'Must have at least one option selected.';
      }
    }

    return errors;
  };

  getRepeatWeek = ({ values, errors, setFieldValue }) => {
    return (
      <FormGroup>
        <Label className="mb-1">Repeat On</Label>

        <div className="d-flex flex-row">
          <Day
            isSelected={values.monday}
            onClick={(val) => setFieldValue('monday', val)}
          >
            M
          </Day>
          <Day
            isSelected={values.tuesday}
            onClick={(val) => setFieldValue('tuesday', val)}
          >
            Tu
          </Day>
          <Day
            isSelected={values.wednesday}
            onClick={(val) => setFieldValue('wednesday', val)}
          >
            W
          </Day>
          <Day
            isSelected={values.thursday}
            onClick={(val) => setFieldValue('thursday', val)}
          >
            Th
          </Day>
          <Day
            isSelected={values.friday}
            onClick={(val) => setFieldValue('friday', val)}
          >
            F
          </Day>
          <Day
            isSelected={values.saturday}
            onClick={(val) => setFieldValue('saturday', val)}
          >
            Sa
          </Day>
          <Day
            isSelected={values.sunday}
            onClick={(val) => setFieldValue('sunday', val)}
          >
            Su
          </Day>
        </div>
        {errors.repeatOn && <FormFeedback>{errors.repeatOn}</FormFeedback>}
      </FormGroup>
    );
  };

  getRepeatMonth = ({
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    setTouched,
  }) => {
    const currentDate = this.getCurrentDate();

    const selectOptions = [
      {
        label: `Monthly on the ${getFormat(
          currentDate.monthWeekByDay() + 1
        )} ${currentDate.format('dddd')}`,
        value: {
          sequenceOffset: currentDate.monthWeekByDay() + 1,
          isDate: false,
        },
      },
      {
        label: `Monthly on the ${currentDate.format('Do')}`,
        value: {
          sequenceOffset: 0,
          isDate: true,
        },
      },
    ];

    return (
      <FormGroup>
        <Label className="mb-1">Repeat On</Label>
        <Select
          name="selectRepeatOn"
          options={selectOptions}
          value={values.selectRepeatOn}
          onChange={(val) => setFieldValue('selectRepeatOn', val)}
          onBlur={() => setTouched({ selectRepeatOn: true })}
          blurInputOnSelect={true}
        />
        {touched.selectRepeatOn && errors.selectRepeatOn && (
          <FormFeedback>{errors.selectRepeatOn}</FormFeedback>
        )}
      </FormGroup>
    );
  };

  render() {
    let { formState = {} } = this.props;
    const { onComplete = () => {}, mode = 'create' } = this.props;

    const isEditing = mode === 'edit';

    const currentDate = this.getCurrentDate();

    const dayOfWeek = currentDate.format('dddd');

    const selectedTimezone = timezoneOptions.find(
      (a) => a.value === this.props.auth.currentPractitioner.timezone
    );

    const defaultFormState = {
      frequency: 1,
      repeatOption: { label: 'Weeks', value: 'WEEKS' },
      radioNever: false,
      radioOn: false,
      radioAfter: true,
      reoccurUntilDate: currentDate.format('YYYY-MM-DD'),
      numberOfOccurrences: 1,
      selectRepeatOn: {
        label: `Monthly on the ${getFormat(
          currentDate.monthWeekByDay() + 1
        )} ${currentDate.format('dddd')}`,
        value: {
          sequenceOffset: currentDate.monthWeekByDay() + 1,
          isDate: false,
        },
      },
      timezone: selectedTimezone,
      monday: dayOfWeek === 'Monday' || false,
      tuesday: dayOfWeek === 'Tuesday' || false,
      wednesday: dayOfWeek === 'Wednesday' || false,
      thursday: dayOfWeek === 'Thursday' || false,
      friday: dayOfWeek === 'Friday' || false,
      saturday: dayOfWeek === 'Saturday' || false,
      sunday: dayOfWeek === 'Sunday' || false,
    };

    if (isEditing) {
      switch (formState.unit) {
        case 'DAYS':
          formState.repeatOption = { label: 'Days', value: formState.unit };
          break;
        case 'WEEKS':
          formState.repeatOption = { label: 'Weeks', value: formState.unit };
          break;
        case 'MONTHS':
          formState.repeatOption = { label: 'Months', value: formState.unit };
          break;
        default:
          break;
      }

      const daysArr = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ];
      const weekdaysArr = formState.weekdays && formState.weekdays.split('');
      if (weekdaysArr) {
        daysArr.forEach(
          (day, i) => (formState[day] = !!parseInt(weekdaysArr[i]))
        );
      }

      if (formState.numberOfOccurrences) {
        formState.radioNever = false;
        formState.radioOn = false;
        formState.radioAfter = true;
      } else if (formState.reoccurUntilDate) {
        formState.radioNever = false;
        formState.radioOn = true;
        formState.radioAfter = false;
      }
    }

    const state = {
      ...defaultFormState,
      ...formState,
    };

    return (
      <Formik
        initialValues={state}
        enableReinitialize={true}
        validate={this.onValidate}
        onSubmit={(values, actions) => {
          const { sequenceOffset, isDate } = values.selectRepeatOn.value;
          const selectedUnit = values.repeatOption.value;

          let daysSelectedStr = null;
          if (selectedUnit === 'WEEKS') {
            const daysSelectedArr = [
              values.monday,
              values.tuesday,
              values.wednesday,
              values.thursday,
              values.friday,
              values.saturday,
              values.sunday,
            ];

            daysSelectedStr = '';
            daysSelectedArr.forEach((day) => {
              if (day) {
                daysSelectedStr += '1';
              } else {
                daysSelectedStr += '0';
              }
            });
          }

          const repeatSettings = {
            id: values.id,
            date: isDate,
            endTime: values.endTime,
            frequency: values.frequency,
            numberOfOccurrences: values.radioAfter
              ? values.numberOfOccurrences
              : null,
            recurrenceStartDate: values.recurrenceStartDate,
            reoccurUntilDate: values.radioOn
              ? values.reoccurUntilDate.toString()
              : null,
            sequenceOffset: sequenceOffset,
            startTime: values.startTime,
            unit: selectedUnit,
            weekdays: daysSelectedStr,
            timezone: values.timezone.value,
          };

          onComplete(repeatSettings, values);
        }}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label className="d-block mb-1">Repeat Every</Label>
                    <InputNum
                      bsSize="lg"
                      name="frequency"
                      type="number"
                      min="1"
                      value={values.frequency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.frequency && !!errors.frequency}
                      className="mr-2"
                    />
                    <SelectRepeat
                      name="repeatOption"
                      value={values.repeatOption}
                      options={selectRepeatOptions}
                      onChange={(val) => setFieldValue('repeatOption', val)}
                      onBlur={() => setTouched({ repeatOption: true })}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  {values.repeatOption.value === 'WEEKS' &&
                    this.getRepeatWeek({ values, errors, setFieldValue })}
                  {values.repeatOption.value === 'MONTHS' &&
                    this.getRepeatMonth({
                      values,
                      touched,
                      errors,
                      handleBlur,
                      setFieldValue,
                      setTouched,
                    })}
                </Col>
              </Row>

              <Row form>
                <Col>
                  <Label className="d-block w-100 mb-1">Ends</Label>
                  <EndsGrid>
                    <CustomInputGroup gridArea="inputAfter">
                      <CustomInputBox
                        type="number"
                        name="numberOfOccurrences"
                        min="1"
                        value={values.numberOfOccurrences}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!values.radioAfter}
                      />
                      <InputGroupText>
                        Occurrences
                      </InputGroupText>
                    </CustomInputGroup>

                    <CustomRadio
                      type="radio"
                      id="radioOn"
                      checked={values.radioOn}
                      onChange={() => {
                        setFieldValue('radioNever', false);
                        setFieldValue('radioOn', true);
                        setFieldValue('radioAfter', false);
                      }}
                      gridArea="radioOn"
                    >
                      <CustomRadioLabel>On</CustomRadioLabel>
                    </CustomRadio>

                    <CustomRadio
                      type="radio"
                      id="radioAfter"
                      checked={values.radioAfter}
                      onChange={() => {
                        setFieldValue('radioNever', false);
                        setFieldValue('radioOn', false);
                        setFieldValue('radioAfter', true);
                      }}
                      gridArea="radioAfter"
                    >
                      <CustomRadioLabel>After</CustomRadioLabel>
                    </CustomRadio>

                    <CustomInputBox
                      type="date"
                      name="reoccurUntilDate"
                      value={values.reoccurUntilDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      gridArea="reoccurUntilDate"
                      disabled={!values.radioOn}
                    />
                  </EndsGrid>
                </Col>
              </Row>
              <Row>
                {/* <Col>
                  <FormGroup>
                    <Label>Timezone {values.timezone.value}</Label>
                    <Select
                      classNamePrefix="react-select"
                      name="timezone"
                      options={timezoneOptions}
                      value={values.timezone}
                      onChange={(value) => {
                        setFieldValue('timezone', value);
                      }}
                      isLoading={this.isSubmitting}
                    />
                  </FormGroup>
                </Col> */}
              </Row>

              {this.getSubmitError(status)}

              <div className="text-center mt-3">
                <Button
                  color="primary"
                  size="lg"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {isEditing ? 'Update' : 'Confirm'}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

export default RepeatBookingForm;
