/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Button, Collapse, ListGroupItem, Label, Row, Col, Badge } from 'reactstrap';
import moment from 'moment';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';

import { RejectBookingModal } from 'common';

import TherapyLink from '../TherapyLink';

@inject('bookings')
@observer
class GroupContact extends Component {
  @observable rejectFormState = {};

  @observable event = null;

  @observable rejectModalOpen = false;

  constructor(props) {
    super(props);
    this.event = props.event;
  }

  toggleRejectModal = () => {
    this.rejectModalOpen = !this.rejectModalOpen;
  };

  openRejectModal = (id, mode = 'Appointment') => {
    this.rejectFormState = { appointmentId: id, mode };
    this.rejectModalOpen = true;
  };

  onAccept = async () => {
    const { bookings, event } = this.props;
    const response = await bookings.acceptBooking(event);
    if (!response.hasError) {
      bookings.fetchGroupBookings();
      this.event = response.data.appointment;
    }
  };

  onReject = (response) => {
    const { bookings } = this.props;
    if (response && !response.hasError) {
      bookings.fetchGroupBookings();
      this.event = null;
    }
    this.rejectModalOpen = false;
    this.rejectFormState = {};
  };

  renderActions = () => {
    const { isGroup } = this.props;
    if (isGroup) {
      if (this.event.status === 'Pending') {
        return (
          <div>
            <Button
              color='danger'
              size='sm'
              className='mr-1'
              onClick={() => this.openRejectModal(this.event.id)}
            >
              Decline
            </Button>
            <Button color='success' size='sm' onClick={() => this.onAccept()}>
              Accept
            </Button>
          </div>
        );
      }
    }
    return null;
  };

  renderStatus = () => {
    if (this.event.status === 'Pending') {
      return (
        <Badge className='ml-2' color='warning'>
          {this.event.status}
        </Badge>
      );
    } else if (this.event.status === 'Confirmed') {
      return (
        <Badge className='ml-2' color='success'>
          {this.event.status}
        </Badge>
      );
    } else if (this.event.status === 'Completed' || this.event.status === 'PendingCompletion') {
      return (
        <Badge className='ml-2' color='primary'>
          {this.event.status}
        </Badge>
      );
    } else if (
      this.event.status === 'Cancelled' ||
      this.event.status === 'Rejected' ||
      this.event.status === 'DidNotAttend'
    ) {
      return (
        <Badge className='ml-2' color='danger'>
          {this.event.status}
        </Badge>
      );
    }
  };

  render() {
    if (!this.event) {
      return null;
    }
    const { responses, contact, therapyLink } = this.event;
    const answeredQuestions = responses ? responses : [];
    const ids = `${this.event.id}-${
      this.event.recurringAppointment ? this.event.recurringAppointment.id : 0
    }`;
    const dateOfBirth = moment(contact.dateOfBirth).format('MMM Do YYYY');

    return (
      <>
        <Row>
          <Col>
            <strong className='d-block text-secondary'>Name</strong>
            <Label className='text-break'>{`${contact.firstName} ${contact.lastName}`}</Label>
            <div>{this.renderActions()}</div>
            {therapyLink && therapyLink.length ? (
              <>
                <strong className='d-block text-secondary'>Session link</strong>
                <TherapyLink link={therapyLink} />
              </>
            ) : null}
            <strong className='d-block text-secondary'>Email</strong>
            <Label className='text-break'>{contact.email || '-'}</Label>
            <strong className='d-block text-secondary'>Phone Number</strong>
            <Label className='text-break'>{contact.phoneNumber || '-'}</Label>
          </Col>
          <Col>
            <strong className='d-block text-secondary'>Ethnicity</strong>
            <Label className='text-break'>{contact.ethnicity || '-'}</Label>
            <strong className='d-block text-secondary'>Date of Birth</strong>
            <Label className='text-break'>{contact.dateOfBirth ? dateOfBirth : '-'}</Label>
            <strong className='d-block text-secondary'>Gender</strong>
            <Label className='text-break'>{contact.gender || '-'}</Label>
          </Col>
        </Row>
        <Row>
          {answeredQuestions.map((qa, i) => (
            <Col key={`qa-${i}`} className='col-12'>
              <strong className='d-block text-secondary'>{qa.question}</strong>
              <Label className='text-break'>{qa.answer}</Label>
            </Col>
          ))}
        </Row>
        <small className='mr-auto'>#{ids}</small>
        <RejectBookingModal
          isModalOpen={this.rejectModalOpen}
          toggleModal={this.toggleRejectModal}
          formState={this.rejectFormState}
          onComplete={this.onReject}
        />
      </>
    );
  }
}

export default GroupContact;
