// @flow
import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react';
import { Button, Form, FormGroup, FormFeedback, Label, Input } from 'reactstrap';
import PhoneInputField from '../../../common/components/PhoneInputField';

const schema = Yup.object().shape({
  contactEmailAddress: Yup.string().email('Please enter a valid email').required('Required'),
  phoneNumber: Yup.string().required('Required'),
});

type Props = {
  onComplete: Function;
  onBack: Function | undefined;
};

@observer
class ContactForm extends Component<Props> {
  static defaultProps = {
    onComplete: () => {},
    onBack: undefined,
  };

  render() {
    const { formState, onComplete, onBack } = this.props;
    return (
      <Formik
        initialValues={formState}
        validationSchema={schema}
        onSubmit={(values, actions) => {
          onComplete(values);
        }}
      >
        {(formik) => {
          const { values, errors, touched, handleBlur, handleSubmit, isSubmitting, setFieldValue } =
            formik;

          return (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label>Email Address</Label>
                <Input
                  bsSize='lg'
                  name='contactEmailAddress'
                  value={values.contactEmailAddress}
                  onChange={(e) => setFieldValue('contactEmailAddress', e.target.value.trim())}
                  onBlur={handleBlur}
                  invalid={touched.contactEmailAddress && !!errors.contactEmailAddress}
                />
                {errors.contactEmailAddress && (
                  <FormFeedback>{errors.contactEmailAddress}</FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Phone Number</Label>
                <PhoneInputField
                  name='phoneNumber'
                  defaultCountry={formState.country}
                  value={values.phoneNumber}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  invalid={touched.phoneNumber && !!errors.phoneNumber}
                  errors={errors.phoneNumber}
                />
              </FormGroup>

              <div className='text-center mt-3'>
                {onBack && (
                  <Button
                    color='primary'
                    size='lg'
                    className='mr-2'
                    onClick={onBack}
                    disabled={isSubmitting}
                  >
                    Back
                  </Button>
                )}
                <Button color='primary' size='lg' type='submit' disabled={isSubmitting}>
                  Next
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default ContactForm;
