import React, { Component } from 'react';
import { CardHeader, CardTitle, CardBody, Label, Col, Button } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { toast } from 'react-toastify';

import {
  CustomCard,
  QuestionRow,
  QuestionLoading,
  DetailsRow,
  EditIcon,
  DeleteIcon,
  UpIcon,
  DownIcon,
} from './styles';

@inject('screening')
@observer
class ScreeningQuestion extends Component {
  @observable updating = false;
  @observable deleting = false;

  onDelete = () => {
    const { question, screening } = this.props;

    this.deleting = true;
    screening.deleteQuestion(question).then((res) => {
      if (!res.hasError) {
        toast.success('Question deleted');
      }
      this.deleting = false;
    });
  };

  moveQuestionUp = () => {
    const { questions, updateOrdersAndSave } = this.props.screening;

    const currentIdx = questions.findIndex((q) => q.id === this.props.question.id);
    const newIdx = Math.max(currentIdx - 1, 0);
    if (currentIdx !== newIdx) {
      this.updating = true;
      updateOrdersAndSave(this.swap(questions.toJSON(), currentIdx, newIdx)).then(() => {
        this.updating = false;
      });
    }
  };

  moveQuestionDown = () => {
    const { questions, updateOrdersAndSave } = this.props.screening;

    const currentIdx = questions.findIndex((q) => q.id === this.props.question.id);
    const newIdx = Math.min(currentIdx + 1, questions.length - 1);
    if (currentIdx !== newIdx) {
      this.updating = true;
      updateOrdersAndSave(this.swap(questions.toJSON(), currentIdx, newIdx)).then(() => {
        this.updating = false;
      });
    }
  };

  swap = (arr, i, j) => {
    [arr[i], arr[j]] = [arr[j], arr[i]];
    return arr;
  };

  render() {
    const { question, index, questionVisibility, onEdit, screening } = this.props;
    const firstQuestionIndex = Math.min(...screening.questions.map((q: number) => q.order));
    const lastQuestionIndex = screening.questions.length - 1;

    return (
      <QuestionRow key={`question-${question.order}`}>
        {(this.updating || this.deleting) && <QuestionLoading />}
        {!question.locked && (
          <UpIcon
            first={index === firstQuestionIndex}
            onClick={this.moveQuestionUp}
            viewBox='0 0 24 24'
          />
        )}
        {!question.locked && (
          <DownIcon
            last={index === lastQuestionIndex}
            onClick={this.moveQuestionDown}
            viewBox='0 0 24 24'
          />
        )}
        <Col className='px-4'>
          <CustomCard>
            <CardHeader className='px-5 py-3'>
              <CardTitle className='mb-0 d-flex justify-content-between'>
                <span className='d-flex align-items-center'>
                  <h4 className='d-inline mb-0'>{question.question}</h4>
                </span>

                {!question.locked && (
                  <span>
                    <Button size='sm' className='h-full' onClick={onEdit.bind(this, question)}>
                      <EditIcon viewBox='0 0 24 24' />
                    </Button>
                    <Button
                      size='sm'
                      color='danger'
                      className='ml-2 h-full'
                      onClick={this.onDelete}
                    >
                      <DeleteIcon viewBox='0 0 24 24' />
                    </Button>
                  </span>
                )}
              </CardTitle>
            </CardHeader>

            <CardBody className='container-fluid p-5'>
              <DetailsRow>
                <Col>
                  <h5 className='text-secondary'>Question Type</h5>
                  <Label className='ml-3 mb-0'>{question.questionType}</Label>
                </Col>
                <Col>
                  <h5 className='text-secondary'>Show Question For...</h5>
                  <Label className='ml-3 mb-0'>{questionVisibility}</Label>
                </Col>
              </DetailsRow>
            </CardBody>
          </CustomCard>
        </Col>
      </QuestionRow>
    );
  }
}

export default ScreeningQuestion;
