import BaseService from '../BaseService';
import { BASE_API_URL } from 'configuration';

class LocationService {
	static getLocations = () => {
		return BaseService.get(`${BASE_API_URL}/practitioners/me/locations`);
	};
	static createLocation = data => {
		return BaseService.post(`${BASE_API_URL}/practitioners/me/locations`, data);
	};
	static editLocation = data => {
		return BaseService.put(`${BASE_API_URL}/practitioners/me/locations`, data);
	};
	static deleteLocation = id => {
		return BaseService.delete(`${BASE_API_URL}/practitioners/me/locations/${id}`);
	};
}

export default LocationService;
