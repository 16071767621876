import React, { Component } from 'react';
import { Container, Button } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';

import {
  visibilityOptions,
  typeOptions,
} from 'modules/screening/components/ScreeningQuestionForm/options';
import QuestionModal from './question_modal';
import ScreeningQuestion from './screening_question';
import { Loading } from 'common';

@inject('screening')
@observer
class ScreeningQuestions extends Component {
  @observable loading = true;
  @observable loadingActionId = null;
  @observable questionModalOpen = false;
  @observable mode = undefined;
  @observable questionFormState = {};

  constructor(props) {
    super(props);

    props.screening.fetchQuestions().then(() => {
      this.loading = false;
    });
  }

  toggleQuestionModal = () => {
    this.questionModalOpen = !this.questionModalOpen;
  };

  onAddQuestion = () => {
    this.mode = 'create';
    this.questionFormState = {};
    this.questionModalOpen = true;
  };

  onEditQuestion = (question) => {
    this.mode = 'edit';
    const hasAnswers = question.potentialAnswers && question.potentialAnswers.length > 0;
    this.questionFormState = {
      ...question,
      qVisibility: visibilityOptions.find((option) => option.value === question.questionVisibility),
      qType: typeOptions.find((option) => option.value === question.questionType),
      answersMandatory: hasAnswers,
      pAnswers:
        question.potentialAnswers &&
        question.potentialAnswers.map((answer) => ({ label: answer, value: answer })),
    };
    this.questionModalOpen = true;
  };

  onComplete = () => {
    this.questionFormState = {};
    this.questionModalOpen = false;
  };

  getQuestions = (screening) => {
    const { questions } = screening;

    if (questions && questions.length > 0) {
      return questions.map((question, idx) => {
        const questionVisibility = visibilityOptions.find(
          (option) => option.value === question.questionVisibility
        );

        return (
          <ScreeningQuestion
            key={`question-${question.order}`}
            question={question}
            index={idx}
            questionVisibility={questionVisibility.label}
            onEdit={this.onEditQuestion}
          />
        );
      });
    }
  };

  render() {
    const { screening } = this.props;

    var formState = {
      order: screening.questions ? screening.questions.length : 0,
      ...this.questionFormState,
    };

    return (
      <>
        <Container fluid>
          <div className='d-flex justify-content-between align-items-center mb-6'>
            <Heading className='text-xl text-dark-blue'>Manage Screening Questions</Heading>
            <Button
              color='primary'
              size='md'
              // style={{ height: '30px', width: '120px' }}
              onClick={this.onAddQuestion}
              className='rounded-full font-bold h-8'
            >
              Add Question
            </Button>
          </div>
          {this.loading && <Loading />}
          {this.getQuestions(screening)}
        </Container>

        <QuestionModal
          mode={this.mode}
          formState={formState}
          isModalOpen={this.questionModalOpen}
          toggleModal={this.toggleQuestionModal}
          onComplete={this.onComplete}
        />
      </>
    );
  }
}

export default ScreeningQuestions;
