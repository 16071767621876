/* Third party */
import React from "react";
import { Row, Container, Col } from "reactstrap";

/* Components */
import { Section, SectionSpacing, HeaderTitle } from '../../styles';

/* Functions */

const IndexSection = () => (
  <Section backgroundcolor="#4987ce">
    <SectionSpacing>
      <Container>
        <Row className="mt-4">
          <Col sm={12} md={{ size: 8, offset: 2 }}>
            <HeaderTitle className="mb-0 text-center pt-5 pb-5" color="#FFFFFF">Frequently Asked Questions</HeaderTitle>
          </Col>
        </Row>
      </Container>
    </SectionSpacing>
  </Section>
);

export default IndexSection;
