import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';

import AppointmentForm from 'modules/calendar/components/AppointmentForm';
import BlockForm from 'modules/calendar/components/BlockForm';

import '../styles.css';
import HorizontalSeparator from '../HorizontalSeparator';
import AppointmentOption from './AppointmentOption';

const moment = extendMoment(Moment);

type ModalType = 'create' | 'block';

@withRouter
@inject('bookings', 'locations', 'prices')
@observer
class BookingModal extends Component {
  @observable modalType: ModalType = 'create';

  constructor(props) {
    super(props);

    Promise.all([props.locations.getLocations(), props.prices.getPrices()]);
  }

  setModalType = (type: ModalType) => {
    this.modalType = type;
  };

  isRebook = () => {
    const { bookingType, booking } = this.props;
    return bookingType == 'rebook' && booking;
  };

  onComplete = () => {
    const { onComplete } = this.props;

    if (onComplete) {
      onComplete();
    } else {
      this.closeModal();
    }
  };

  closeModal = () => {
    this.props.toggleModal();
    this.modalType = 'create';
  };

  render() {
    const {
      isModalOpen,
      selectedTimeSlot = {},
      showTypeButtons = true,
      bookingType,
      booking,
    } = this.props;

    let formState = {};
    if (selectedTimeSlot.start && selectedTimeSlot.end) {
      const startMoment = moment(selectedTimeSlot.start).second(0);
      const endMoment = moment(selectedTimeSlot.end).second(0);

      formState.startTime = startMoment;
      formState.endTime = endMoment;
      formState.startTimeOption = {
        label: startMoment.format('h:mma'),
        value: startMoment,
      };
      formState.endTimeOption = {
        label: endMoment.format('h:mma'),
        value: endMoment,
      };

      const range = moment.range(startMoment, endMoment);
      // This will be triggered when a user clicks (without dragging) on a 15 minute slot
      if (range.diff('minutes') === 15) {
        endMoment.add(45, 'minutes');
        formState.endTime = endMoment;
        formState.endTimeOption = {
          label: endMoment.format('h:mma'),
          value: endMoment,
        };
      }
    }
    if (this.modalType === 'create' || this.modalType === 'group') {
      formState.appointmentStartDateTime = moment(selectedTimeSlot.start).format('YYYY-MM-DD');
      formState.contact = {};
    } else {
      formState.startDate = moment(selectedTimeSlot.start).format('YYYY-MM-DD');
      formState.endDate = moment(selectedTimeSlot.start).format('YYYY-MM-DD');
    }

    let title = 'Create Appointment';

    if (this.isRebook()) {
      formState.contact = booking.contact;
      formState.bookingFor = booking.bookingFor;
      formState.therapyForSomeoneElse = booking.therapyForSomeoneElse;
      title = 'Rebook Appointment';
    }

    return (
      <Modal isOpen={isModalOpen} toggle={this.closeModal} size='md' centered>
        <ModalHeader className='bg-light' toggle={this.closeModal}>
          <Heading className='text-dark-blue' as='h5'>
            {title}
          </Heading>
        </ModalHeader>

        {this.isRebook() && (
          <div className='w-full'>
            <div
              className={clsx(
                'mt-4 mx-4 p-2 border border-solid rounded-md bg-opacity-25 text-center bg-secondary-yellow-100 border-secondary-yellow-100'
              )}
            >
              <Text className='text-sm'>
                If you're unsure of a time to rebook with this client, please call them using their
                mobile number below. Once confirmed, please schedule it in the system.
              </Text>
            </div>
          </div>
        )}

        <ModalBody className='py-3 px-4'>
          {showTypeButtons && (
            <>
              <HorizontalSeparator className='justify-start mt-2 mb-2'>
                Select Type
              </HorizontalSeparator>
              <div className='mb-4 flex justify-content-start'>
                <AppointmentOption
                  title='New Appointment'
                  description='Book with EAP or private clients.'
                  selected={this.modalType === 'create'}
                  onClick={() => this.setModalType('create')}
                />
                <AppointmentOption
                  title='Block'
                  description="Block out time in your calendar so you don't get booked when you're unavailable."
                  selected={this.modalType === 'block'}
                  onClick={() => this.setModalType('block')}
                />
              </div>
            </>
          )}
          {this.modalType === 'create' && (
            <AppointmentForm formState={formState} onComplete={this.onComplete} mode='create' />
          )}
          {this.modalType === 'block' && (
            <BlockForm formState={formState} onComplete={this.onComplete} mode='create' />
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default BookingModal;
