import { SVGProps } from 'react';
const ChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 25' {...props}>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='m16 10.5-4 4-4-4'
    />
  </svg>
);
export default ChevronDown;
