import React from 'react';
import { Button, Card, CardHeader } from 'reactstrap';

import * as Icon from 'react-feather';

import InvoiceForm from 'modules/invoices/components/InvoiceForm';

const InvoiceFormCard = ({ initialValue, onSubmit, onDiscard }) => (
  <Card className='mb-1'>
    <CardHeader className='d-flex justify-content-between'>
      <div className='d-flex justify-content-start align-items-center'>
        <h1 className='h3 mb-0 mr-3'>New Invoice</h1>
      </div>
      <div>
        <Button color='warning' className='btn-pill' onClick={() => onDiscard()} outline>
          <Icon.Trash2 size={14} /> DISCARD
        </Button>
      </div>
    </CardHeader>
    <InvoiceForm initialValue={initialValue} onSubmit={onSubmit} />
  </Card>
);

export default InvoiceFormCard;
