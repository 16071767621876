import { flow, types } from 'mobx-state-tree';

import ScheduleService from 'services/ScheduleService';
import { tagManagerEvent } from '../../../utilities/analytics';

const Slot = types.model('Slot', {
	id: types.identifierNumber,
	chargeIds: types.maybeNull(types.array(types.number), []),
	locationIds: types.maybeNull(types.array(types.number), []),
	startTime: types.string,
	endTime: types.string,
	templateDay: types.string,
	allowOnHour: types.maybeNull(types.boolean),
	allowOnQuarterPastHour: types.maybeNull(types.boolean),
	allowOnHalfHour: types.maybeNull(types.boolean),
	allowOnQuarterToHour: types.maybeNull(types.boolean),
});

const Template = types.model('Template', {
	// id: types.identifierNumber,
	immediate: types.maybeNull(types.boolean),
	template: types.array(Slot),
});

const Schedule = types
	.model('Schedule', {
		schedule: types.maybeNull(Template),
	})
	.actions(self => ({
		getSchedule: flow(function*() {
			const res = yield ScheduleService.getSchedule();
			if (!res.hasError) {
				self.schedule = res.data;
			}
			return res;
		}),
		publishSchedule: flow(function*(data) {
			const res = yield ScheduleService.publishSchedule(data);
			if (!res.hasError) {
				self.schedule = res.data;

				tagManagerEvent('Schedule Published');
			}
			return res;
		}),
	}));

export default Schedule;
