import { flow, types } from 'mobx-state-tree';

import ScreeningService from 'services/ScreeningService';
import { tagManagerEvent } from '../../../utilities/analytics';

const Question = types.model('Question', {
  id: types.maybeNull(types.number),
  question: types.string,
  questionType: types.string,
  questionVisibility: types.string,
  order: types.number,
  locked: types.maybeNull(types.boolean),
  potentialAnswers: types.maybeNull(types.array(types.string)),
});

const Screening = types
  .model('Screening', {
    headline: types.maybeNull(types.string),
    questions: types.maybe(types.array(Question), []),
  })
  .views((self) => {
    return {
      get unlockedQuestions() {
        return self.questions.filter((t) => !t.locked);
      },
    };
  })
  .actions((self) => ({
    fetchQuestions: flow(function* () {
      const res = yield ScreeningService.fetchQuestions();
      if (!res.hasError) {
        const { headline, questions } = res.data.responseObject;
        self.headline = headline;
        const orderedQuestions = questions.sort((a, b) => a.order - b.order);
        self.questions = orderedQuestions;
      }
      return res;
    }),

    addQuestion: flow(function* (data) {
      data.locked = false;
      self.questions.push(data);
      const res = yield ScreeningService.updateQuestions({
        headline: self.headline,
        questions: self.unlockedQuestions,
      });

      if (!res.hasError) {
        tagManagerEvent('Screening Question Created');
      }
      return res;
    }),
    editQuestion: flow(function* (data) {
      const defaultQuestions = self.questions.filter((x) => x.id < 0);
      let newQuestions = self.unlockedQuestions;
      const oldIndex = newQuestions.findIndex((question) => question.id === data.id);
      newQuestions[oldIndex] = data;

      const res = yield ScreeningService.updateQuestions({
        headline: self.headline,
        questions: newQuestions,
      });

      if (!res.hasError) {
        const { questions } = res.data.responseObject;
        self.questions = [...defaultQuestions, ...questions];

        tagManagerEvent('Screening Question Updated');
      }

      return res;
    }),
    deleteQuestion: flow(function* (data) {
      self.questions.remove(data);
      self.updateQuestionOrders(self.questions.toJSON());
      const res = yield ScreeningService.updateQuestions({
        headline: self.headline,
        questions: self.unlockedQuestions,
      });

      if (!res.hasError) {
        tagManagerEvent('Screening Question Deleted');
      }

      return res;
    }),
    updateOrdersAndSave: flow(function* (questions) {
      self.updateQuestionOrders(questions);
      const res = yield ScreeningService.updateQuestions({
        headline: self.headline,
        questions: self.unlockedQuestions,
      });

      return res;
    }),

    updateQuestionOrders: function (questions) {
      let newQuestions = [...questions];
      newQuestions.forEach((question, newOrder) => {
        question.order = newOrder;
      });
      self.questions = newQuestions;
    },
  }));

export default Screening;
