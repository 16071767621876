import React from 'react';
import { components, ControlProps } from 'react-select';

import styled from 'styled-components';

import SearchSvg from 'jsx:assets/svg/search.svg';

export const SearchIcon = styled(SearchSvg)`
  width: 24px;
  height: 24px;
  fill: #333;
`;

const Control = ({ children, ...props }: ControlProps<any>) => (
  <components.Control {...props}>
    <SearchIcon />
    {children}
  </components.Control>
);

export default Control;
