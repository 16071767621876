import * as React from 'react';
import { useKeycloak } from '@react-keycloak-fork/web';
import { Loading } from 'common';

const Login = () => {
  const { keycloak } = useKeycloak();
  const redirectUri = `${window.location.origin}/app`;
  const loginRedirectUri = keycloak.createLoginUrl({ redirectUri });

  React.useEffect(() => {
    keycloak.login({
      redirectUri: loginRedirectUri,
    });
  });

  return <Loading />;
};

export default Login;
