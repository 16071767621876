import styled from 'styled-components';
import { Add } from '@clearhead-ltd/ui-components/dist/v2/Icons';

export const PlusIcon = styled(Add)`
  height: 38px;
  width: 38px;
  path {
    stroke: #4987ce;
    fill: #4987ce;
    stroke-width: 0.8;
  }
`;

export const CircleButton = styled.button`
  background-color: transparent;
  height: 113px;
  width: 113px;
  border-radius: 50%;
  border: 1px solid #4987ce;
  cursor: pointer;
`;

export const ProfileImageContainer = styled.img`
  height: 113px;
  width: 113px;
  border-radius: 50%;
  cursor: pointer;
`;

export const PencilButtonContainer = styled.button`
  background-color: white;
  position: absolute;
  height: 48px;
  width: 48px;
  border: 1px solid #4987ce;
  border-radius: 50%;
  cursor: pointer;
  bottom: 0;
  right: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
