import * as React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import useWizard from '../../../../common/useWizard';
import { WizardFormContext } from '../index';
import { FormContainer, FooterContainer, Container } from './styles';
import Logo from '@clearhead-ltd/ui-components/dist/v2/Logo';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import StepsProgressBar from '../../../../shared-ui/StepsProgressBar';

export const Footer = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: #a4c3e6;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const CHLogo = styled(Logo)`
  height: 37px;

  @media screen and (max-width: 320px) {
    width: 111px;
    height: 27px;
  }
`;

const FooterHeading = styled(Heading)`
  font-size: 18px;

  @media screen and (max-width: 320px) {
    font-size: 12px;
  }
`;

const StyledContainer = styled(Container)`
  // @media screen and (max-width: 768px) {
  //   overflow-y: auto;
  // }
`;

export const getFooter = () => {
  return (
    <>
      <FooterContainer className='flex items-center w-full justify-center'>
        <div className='py-2 grid justify-items-center'>
          <div className='flex'>
            <CHLogo className='align-self-center mx-auto' />
            <Heading
              className='px-3 my-auto text-primary-blue-100'
              style={{ fontSize: '30px' }}
              as='h4'
            >
              |
            </Heading>
            <FooterHeading className='text-primary-blue-100'>For Providers</FooterHeading>
          </div>
        </div>
      </FooterContainer>
    </>
  );
};

const WizardForm = ({ steps, initialData, customFooter, useFooterFor, initialStep = 0 }) => {
  const value = useWizard(initialData, steps.length, initialStep >= 0 ? initialStep : 0);
  const renderStep = React.useCallback(() => {
    const StepElement = steps[value.currentStep];
    return (
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={value.currentStep}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <StepElement />
        </motion.div>
      </AnimatePresence>
    );
  }, [value.currentStep, steps]);

  return (
    <WizardFormContext.Provider value={value}>
      <StyledContainer>
        <FormContainer>
          <div className='pt-10 md:w-3/4 lg:w-3/5 xl:w-6/12 xxl:w-5/12 xxxl:w-4/12 m-auto'>
            <Heading className='text-center mb-4 text-dark-blue' as='h3'>
              Account Set Up
            </Heading>
          </div>
          <StepsProgressBar
            className='mb-8 px-4'
            stepIndex={value.currentStep}
            stepCount={value.totalSteps}
          />
          <div>{renderStep()}</div>
        </FormContainer>
        {customFooter && (useFooterFor === 'all' || useFooterFor === value.currentStep)
          ? customFooter
          : getFooter()}
      </StyledContainer>
    </WizardFormContext.Provider>
  );
};
export default WizardForm;
