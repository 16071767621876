export const EthnicityOptions = [
  {
    label: 'Australian',
    options: [
			{
        label: 'Australian',
        value: 'Australian',
      },
      {
        label: 'Australian Aboriginal',
        value: 'Australian Aboriginal',
      },
      {
        label: 'Australian South Sea Islander',
        value: 'Australian South Sea Islander',
      },
      {
        label: 'Torres Strait Islander',
        value: 'Torres Strait Islander',
      },
      {
        label: 'Norfolk Islander',
        value: 'Norfolk Islander',
      },
    ],
  },
  {
    label: 'New Zealand',
    options: [
      {
        label: 'Māori',
        value: 'Māori',
      },
      {
        label: 'New Zealand European',
        value: 'New Zealand European',
      },
    ],
  },
  {
    label: 'Other European',
    options: [
      {
        label: 'British',
        value: 'British',
      },
      {
        label: 'Irish',
        value: 'Irish',
      },
      {
        label: 'Dutch',
        value: 'Dutch',
      },
      {
        label: 'Greek',
        value: 'Greek',
      },
      {
        label: 'Polish',
        value: 'Polish',
      },
      {
        label: 'South Slav',
        value: 'South Slav',
      },
      {
        label: 'Italian',
        value: 'Italian',
      },
      {
        label: 'German',
        value: 'German',
      },
      {
        label: 'Other European',
        value: 'Other European',
      },
      {
        label: 'Western European',
        value: 'Western European',
      },
      {
        label: 'Northern European',
        value: 'Northern European',
      },
      {
        label: 'Southern European',
        value: 'Southern European',
      },
      {
        label: 'South Eastern European',
        value: 'South Eastern European',
      },
      {
        label: 'Eastern European',
        value: 'Eastern European',
      },
    ],
  },
  {
    label: 'Pacific Peoples',
    options: [
      {
        label: 'Samoan',
        value: 'Samoan',
      },
      {
        label: 'Cook Island Māori',
        value: 'Cook Island Māori',
      },
      {
        label: 'Tongan',
        value: 'Tongan',
      },
      {
        label: 'Niuean',
        value: 'Niuean',
      },
      {
        label: 'Tokelauan',
        value: 'Tokelauan',
      },
      {
        label: 'Fijian',
        value: 'Fijian',
      },
      {
        label: 'Other Pacific Peoples',
        value: 'Other Pacific Peoples',
      },
    ],
  },
  {
    label: 'Asian',
    options: [
      {
        label: 'Southeast Asian',
        value: 'Southeast Asian',
      },
      {
        label: 'Chinese',
        value: 'Chinese',
      },
      {
        label: 'Indian',
        value: 'Indian',
      },
      {
        label: 'Southern Asian',
        value: 'Southern Asian',
      },
      {
        label: 'Central Asian',
        value: 'Central Asian',
      },
      {
        label: 'North-East Asian',
        value: 'North-East Asian',
      },
      {
        label: 'Other Asian',
        value: 'Other Asian',
      },
    ],
  },
  {
    label: 'Middle Eastern',
    options: [
      {
        label: 'Middle Eastern',
        value: 'Middle Eastern',
      },
      {
        label: 'Arab',
        value: 'Arab',
      },
      {
        label: 'Jewish',
        value: 'Jewish',
      },
      {
        label: 'Peoples of the Sudan',
        value: 'Peoples of the Sudan',
      },
      {
        label: 'Other Middle Eastern',
        value: 'Other Middle Eastern',
      },
    ],
  },
  {
    label: 'African',
    options: [
      {
        label: 'African',
        value: 'African',
      },
      {
        label: 'Central African',
        value: 'Central African',
      },
      {
        label: 'South African',
        value: 'South African',
      },
      {
        label: 'East African',
        value: 'East African',
      },
      {
        label: 'North African',
        value: 'North African',
      },
      {
        label: 'West African',
        value: 'West African',
      },
      {
        label: 'Other African',
        value: 'Other African',
      },
    ],
  },
  {
    label: 'Latin American',
    options: [
      {
        label: 'Latin American',
        value: 'Latin American',
      },
    ],
  },
	{
		"label": "Another Ethnicity",
		"options": [
			{
				"label": "Other Indigenous Peoples",
				"value": "Other Indigenous Peoples"
			},
			{
				"label": "Another Ethnicity",
				"value": "Another Ethnicity"
			}
		]
	}
];
