// @flow
import React, { Component } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import { Form, FormGroup, FormFeedback, Label, Input, Col, Row, Button } from 'reactstrap';

const schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
});

type Props = {
  onComplete: Function;
};

@inject('auth')
@withRouter
@observer
class PersonalInfoForm extends Component<Props> {
  formRef = null;

  static defaultProps = {
    onComplete: () => {},
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.mode === 'edit' && this.props.mode === 'read-only') {
      if (this.formRef) {
        this.formRef.resetForm();
      }
    }
  };

  getSubmitError = (status) => {
    if (status && status.message) {
      return <FormFeedback>{status.message}</FormFeedback>;
    }
    return null;
  };

  render() {
    const { auth, onComplete = () => {}, mode = 'read-only' } = this.props;
    const disabled = mode === 'read-only';
    return (
      <Formik
        innerRef={(ref) => (this.formRef = ref)}
        initialValues={auth.currentPractitioner}
        validationSchema={schema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          const { title, firstName, middleName, lastName } = values;

          const data = {
            id: auth.currentPractitioner.id,
            title,
            firstName,
            middleName,
            lastName,
          };
          auth.updatePractitioner(data).then((response) => {
            if (!response.hasError) {
              onComplete();
              toast.success('Personal info updated');
            } else {
              response.errors.forEach((err) => {
                actions.setFieldTouched(err.field, true, false);
                actions.setFieldError(err.field, err.defaultMessage);
              });
              actions.setStatus({ message: response.message });
              toast.error(response.message);
            }
            actions.setSubmitting(false);
          });
        }}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row form>
                <Col className='col-3'>
                  <Label>Title</Label>
                  <Input
                    bsSize='lg'
                    type='select'
                    name='title'
                    value={values.title}
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue('title', e.target.value)}
                    invalid={touched.title && errors.title}
                    disabled={disabled}
                  >
                    <option />
                    <option>Mr</option>
                    <option>Ms</option>
                    <option>Mrs</option>
                    <option>Mx</option>
                    <option>Dr</option>
                  </Input>
                  {touched.title && errors.title && <FormFeedback>{errors.title}</FormFeedback>}
                </Col>
                <Col className='col-9 col-xl-4'>
                  <FormGroup>
                    <Label>First Name *</Label>
                    <Input
                      bsSize='lg'
                      name='firstName'
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.firstName && !!errors.firstName}
                      disabled={disabled}
                    />
                    {touched.firstName && errors.firstName && (
                      <FormFeedback>{errors.firstName}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col className='col-12 col-sm-6 col-xl-5'>
                  <FormGroup>
                    <Label>Middle Name</Label>
                    <Input
                      bsSize='lg'
                      name='middleName'
                      value={values.middleName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.middleName && !!errors.middleName}
                      disabled={disabled}
                    />
                    {touched.middleName && errors.middleName && (
                      <FormFeedback>{errors.middleName}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col className='col-12 col-sm-6'>
                  <FormGroup>
                    <Label>Last Name *</Label>
                    <Input
                      bsSize='lg'
                      name='lastName'
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.lastName && !!errors.lastName}
                      disabled={disabled}
                    />
                    {touched.lastName && errors.lastName && (
                      <FormFeedback>{errors.lastName}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              {this.getSubmitError(status)}

              {!disabled && (
                <div className='text-center mt-3'>
                  <Button color='primary' size='lg' type='submit' disabled={isSubmitting}>
                    Save
                  </Button>
                </div>
              )}
            </Form>
          );
        }}
      />
    );
  }
}

export default PersonalInfoForm;
