// @flow
import React, { Component } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import { Button, Row, Col, Form, FormGroup, FormFeedback, Label, Input } from 'reactstrap';
import moment from 'moment';
import { timezoneOptions } from 'configuration/timezones';
import { alpha3ToAlpha2 } from 'i18n-iso-countries'; // Used to get country 2 letter codes
// Local imports
import PhoneInputField from '../../../common/components/PhoneInputField';

const schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  dateOfBirth: Yup.string()
    .test(
      'dateOfBirth',
      'Please enter a valid date of birth, you must be at least 1 years of age',
      (value) => {
        return value ? moment().diff(moment(value), 'years') >= 1 : true;
      }
    )
    .nullable(),
  email: Yup.string().email('Please enter a valid email').nullable(),
});

type Props = {
  onComplete: Function;
};

const defaultFormstate = {
  firstName: '',
  lastName: '',
  email: '',
};

@inject('clients', 'auth')
@observer
class ClientForm extends Component<Props> {
  static defaultProps = {
    onComplete: () => {},
  };

  render() {
    const {
      clients,
      submitType = 'create',
      formState = defaultFormstate,
      onComplete = () => {},
      auth: { currentPractitioner },
    } = this.props;
    const submit =
      submitType === 'create' ? clients.createClient : clients.editClient.bind(this, formState);
    const successMessage = submitType === 'create' ? 'Client created!' : 'Client updated!';

    const intitalFormState = {
      timezone: currentPractitioner.timezone,
      ...formState,
    };

    const defaultCountry = alpha3ToAlpha2(currentPractitioner.country);

    return (
      <Formik
        initialValues={intitalFormState}
        validationSchema={schema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          submit(values).then((response) => {
            if (!response.hasError) {
              onComplete();
              toast.success(successMessage);
            } else {
              response.errors.forEach((err) => {
                actions.setFieldTouched(err.field, true, false);
                actions.setFieldError(err.field, err.defaultMessage);
              });
              actions.setStatus({ message: response.message });
              toast.error(response.message);
            }
            actions.setSubmitting(false);
          });
        }}
      >
        {(formik) => {
          const {
            values,
            errors,
            status,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          } = formik;

          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for='firstName'>First Name *</Label>
                    <Input
                      bsSize='lg'
                      name='firstName'
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.firstName && !!errors.firstName}
                    />
                    {errors.firstName && <FormFeedback>{errors.firstName}</FormFeedback>}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for='lastName'>Last Name *</Label>
                    <Input
                      bsSize='lg'
                      name='lastName'
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.lastName && !!errors.lastName}
                    />
                    {errors.lastName && <FormFeedback>{errors.lastName}</FormFeedback>}
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for='email'>Email</Label>
                    <Input
                      bsSize='lg'
                      name='email'
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.email && !!errors.email}
                    />
                    {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for='phoneNumber'>Phone Number</Label>
                    <PhoneInputField
                      defaultCountry={defaultCountry}
                      name='phoneNumber'
                      value={values.phoneNumber}
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                      invalid={touched.phoneNumber && !!errors.phoneNumber}
                      errors={errors.phoneNumber}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for='dateOfBirth'>Date of Birth</Label>
                    <Input
                      bsSize='lg'
                      type='date'
                      name='dateOfBirth'
                      value={values.dateOfBirth}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.dateOfBirth && !!errors.dateOfBirth}
                    />
                    {errors.dateOfBirth && <FormFeedback>{errors.dateOfBirth}</FormFeedback>}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for='ethnicity'>Ethnicity</Label>
                    <Input
                      bsSize='lg'
                      type='select'
                      name='ethnicity'
                      value={values.ethnicity}
                      onChange={(e) => setFieldValue('ethnicity', e.target.value)}
                    >
                      <option />
                      <option>Pakeha / NZ European</option>
                      <option>Māori</option>
                      <option>Samoan</option>
                      <option>Tongan</option>
                      <option>Pacific Islander - Other</option>
                      <option>Chinese</option>
                      <option>Indian</option>
                      <option>Asian - Other</option>
                      <option>European - Other</option>
                      <option>African</option>
                      <option>Other</option>
                    </Input>
                    {errors.ethnicity && <FormFeedback>{errors.ethnicity}</FormFeedback>}
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for='timezone'>Timezone</Label>
                    <Input
                      bsSize='lg'
                      type='select'
                      name='timezone'
                      value={values.timezone}
                      onChange={(e) => setFieldValue('timezone', e.target.value)}
                    >
                      {timezoneOptions.map((timezone) => (
                        <option key={timezone.value} value={timezone.value}>
                          {timezone.label}
                        </option>
                      ))}
                    </Input>
                    {errors.timezone && <FormFeedback>{errors.timezone}</FormFeedback>}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for='gender'>Gender</Label>
                    <Input
                      bsSize='lg'
                      type='select'
                      name='ethnicity'
                      value={values.gender}
                      onChange={(e) => setFieldValue('gender', e.target.value)}
                    >
                      <option />
                      <option>Male</option>
                      <option>Female</option>
                      <option>Gender Diverse</option>
                      <option>Undisclosed</option>
                    </Input>
                    {errors.gender && <FormFeedback>{errors.gender}</FormFeedback>}
                  </FormGroup>
                </Col>
              </Row>

              {status?.message && <FormFeedback>{status.message}</FormFeedback>}

              <div className='text-center mt-3'>
                <Button color='primary' size='lg' type='submit' disabled={isSubmitting}>
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default ClientForm;
