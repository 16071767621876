import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faColumns } from '@fortawesome/free-solid-svg-icons';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import InvoiceStatusSelect from 'modules/invoices/components/InvoiceStatusSelect';
import currencyFormatter from 'utilities/currency_formatter';

@withRouter
@observer
class InvoiceLineItem extends Component {
  @observable dropdownOpen = false;

  onRowClick = (id: string) => {
    this.props.history.push(`/app/invoice/${id}`);
  };

  renderClient = (id, client) => {
    const { onClientClick } = this.props;
    return (
      <Button className='p-0 text-left' color='link' onClick={onClientClick}>
        <FontAwesomeIcon icon={faColumns} className='mr-2' />
        {client.contactName}
      </Button>
    );
  };

  renderStatus = (id, status) => {
    return (
      <InvoiceStatusSelect
        id={id}
        isOpen={this.dropdownOpen}
        toggle={() => (this.dropdownOpen = !this.dropdownOpen)}
        value={status}
      />
    );
  };

  renderAppointmentInfo = (confirmed, date) => {
    if (confirmed) {
      return (
        <>
          <NavLink to='/appointment-calendar' className='mr-2'>
            {moment(date).format('Do MMM YYYY')}
          </NavLink>
          <Button color='success' size='sm' className='btn-pill'>
            CONFIRMED
          </Button>
        </>
      );
    } else {
      return (
        <>
          <NavLink to='/appointment-calendar' className='mr-2'>
            {moment(date).format('Do MMM YYYY')}
          </NavLink>
          <Button color='warning' size='sm' className='btn-pill'>
            BOOKED
          </Button>
        </>
      );
    }
  };

  renderDueDate = (date) => {
    return `${moment.utc(date).local().format('Do MMM YYYY')} - ${moment.utc(date).fromNow()}`;
  };

  getTotalPrice = (item) => {
    let total = 0;
    if (item && item.lineItems && item.lineItems.length) {
      item.lineItems.forEach((item) => (total += item.total));
    }
    return total;
  };

  renderTotal = (item) => {
    const total = this.getTotalPrice(item);
    return total;
  };

  renderActions = () => {
    return (
      <Button outline color='info' onClick={() => console.log('Downloaded')}>
        <FontAwesomeIcon icon={faCog} />
      </Button>
    );
  };

  render() {
    const { item, showDueDate } = this.props;

    return (
      <tr
        className='border-1 border-primary-blue-15 cursor-pointer hover:bg-primary-blue-15'
        onClick={() => this.onRowClick(item.id)}
      >
        <td>{this.renderStatus(item.id, item.status)}</td>
        {showDueDate ? (
          <td>{item.status === 'CANCELLED' ? 'N/A' : this.renderDueDate(item.dueDate)}</td>
        ) : null}
        <td className='hidden sm:table-cell'>{this.renderDueDate(item.creationDateTime)}</td>
        <td>{item.client.contactName}</td>
        <td>{currencyFormatter().format(this.renderTotal(item))}</td>
        <td className='hidden sm:table-cell'>#{`${item.id}`.padStart(6, '0')}</td>
        {/* <td>
          {this.renderAppointmentInfo(
            item.appointment.confirmed,
            item.appointment.appointmentTime
          )}
        </td> */}
        {/* <td className="d-flex justify-content-end">{this.renderActions()}</td> */}
      </tr>
    );
  }
}

export default InvoiceLineItem;
