import React from 'react';
import HelpCirclePrimary from './HelpCirclePrimary';
import HelpCircleBooking from './HelpCircleBooking';

export type HelpCircleProps = {
  tooltipText?: string;
  colour?: string;
  height?: string;
  width?: string;
  place?: string;
  offset?: string;
  key?: string;
  appointment?: any;
  auth?: any;
  partnerBooking?: boolean;
};

const HelpCircle: {
  Primary: React.FC<HelpCircleProps>;
  Booking: React.FC<HelpCircleProps>;
} = {
  Primary: HelpCirclePrimary,
  Booking: HelpCircleBooking,
};

export default HelpCircle;
