import styled from 'styled-components';
import { ModifierKeys } from 'styled-components-modifiers';

export type BaseInputProps = {
  modifiers?: ModifierKeys;
};

export const BaseInput = styled.input<BaseInputProps>`
  font: var(--font-body);
  display: block;
  width: 100%;
  padding: 0.45rem 1rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 2;
  border-radius: 10px;
  border: solid 1px #4987ce80;
  color: #495057;
  box-sizing: border-box;

  :focus {
    box-shadow: 0 0 1px 1px var(--blue-100);
    outline: none;
  }

  &.naked {
    border: none;
    border-radius: 0;
    padding: 0;
  }
`;
