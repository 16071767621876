import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { FooterNav, wizardContainerStyle } from '../WizardForm/styles';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';
import { WizardFormContext } from '../index';
import ServicesComponent from '../../ManagePrices/ServicesComponent';

const AddServicesStep = inject(
  'prices',
  'app',
  'auth'
)(
  observer(() => {
    const [isLoading, setIsLoading] = React.useState(false);
    const { onPrevious, onNext, currentStep } = React.useContext(WizardFormContext);
    const [isValid, setIsValid] = React.useState(false);

    const goBack = async () => {
      setIsLoading(true);
      await onPrevious();
    };

    const onSubmitStep = React.useCallback(async () => {
      setIsLoading(true);
      if (isValid) {
        // Skip tenant selecting step
        await onNext();
      }
    }, [onNext, isValid]);

    return (
      <>
        <div className={wizardContainerStyle}>
          <div className='flex flex-col w-full items-center md:px-8  lg:px-8'>
            <ServicesComponent setIsValid={setIsValid} />

            <FooterNav className='md:mt-8 xs:mt-8'>
              <div className='flex justify-start'>
                {currentStep > 0 && (
                  <Button
                    variant='primary'
                    modifiers={'inverted'}
                    onClick={goBack}
                    disabled={isLoading}
                  >
                    Back
                  </Button>
                )}
              </div>
              <div className='flex justify-end'>
                <Button
                  variant={'primary'}
                  onClick={onSubmitStep}
                  data-test-id='complete-hear-about-us'
                  disabled={!isValid || isLoading}
                >
                  Next
                </Button>
              </div>
            </FooterNav>
          </div>
        </div>
      </>
    );
  })
);
export default AddServicesStep;
