/* Third party */
import React from "react";

/* Components */
import IndexSection from "./IndexSection";
import StorySection from "./StorySection";
import ValueSection from "./ValueSection";
import TeamSection from "./TeamSection";

const About = () => (
  <>
    <IndexSection />
    <StorySection />
    <ValueSection />
    <TeamSection />
  </>
);

export default About;
