// @flow
import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Button, Form, FormGroup, FormFeedback, Label, Input } from 'reactstrap';
import clsx from 'clsx';
import { alpha2ToAlpha3 } from 'i18n-iso-countries';
import { ProfessionOptions } from 'configuration';
import { upperCaseFirstLetter } from '../../../../utilities/styling';

const re =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

const schema = Yup.object().shape({
  firstName: Yup.string().trim().required('Required').min(2).max(50).label('First name'),
  lastName: Yup.string().trim().required('Required').min(2).max(50).label('Last name'),
  profession: Yup.string().required('Required').label('Profession'),
  websiteAddress: Yup.string().matches(re, 'Website should be a valid Website Address').nullable(),
});

type Props = {
  onComplete: Function;
  onBack: Function | undefined;
};

const link = (
  <a
    href={'https://www.myclearhead.com/provider-terms-of-use'}
    className='text-primary-blue-100'
    target='blank'
  >
    terms of use
  </a>
);

@inject('auth')
@observer
class ProfileForm extends Component<Props> {
  static defaultProps = {
    onComplete: () => {},
    onBack: undefined,
  };

  onSubmit = (values, actions) => {
    const { auth, onComplete } = this.props;

    const {
      firstName,
      lastName,
      contactEmailAddress,
      phoneNumber,
      profession,
      fullBiography,
      websiteAddress,
      country,
      region,
    } = values;

    if (profession === 'Other Specialist Provider') {
      actions.setFieldError('profession', 'Select another profession');
      actions.setSubmitting(false);
      return;
    }

    let data = {
      firstName,
      lastName,
      contactEmailAddress,
      phoneNumber,
      professions: profession ? [profession] : [],
      fullBiography,
      websiteAddress,
      country: country ? alpha2ToAlpha3(country) : null,
      region,
    };

    auth.signUp(data).then((response) => {
      if (!response.hasError) {
        onComplete(values);
      } else {
        response.errors.forEach((err) => {
          actions.setFieldTouched(err.field, true, false);
          actions.setFieldError(err.field, err.defaultMessage);
        });
        actions.setStatus({ message: response.message });
        toast.error(upperCaseFirstLetter(response.message));
      }
      actions.setSubmitting(false);
    });
  };

  render() {
    const { formState, onBack, auth, onComplete } = this.props;
    return (
      <Formik initialValues={formState} validationSchema={schema} onSubmit={this.onSubmit}>
        {(formik) => {
          const {
            values,
            errors,
            status,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setTouched,
            setFieldValue,
          } = formik;

          return (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label>First Name</Label>
                <Input
                  bsSize='lg'
                  name='firstName'
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.firstName && !!errors.firstName}
                />
                {errors.firstName && <FormFeedback>{errors.firstName}</FormFeedback>}
              </FormGroup>

              <FormGroup>
                <Label>Last Name</Label>
                <Input
                  bsSize='lg'
                  name='lastName'
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.lastName && !!errors.lastName}
                />
                {errors.lastName && <FormFeedback>{errors.lastName}</FormFeedback>}
              </FormGroup>

              <FormGroup>
                <Label className='mb-0'>
                  Select your main profession -{' '}
                  <span style={{ fontStyle: 'italic' }}>
                    This is a list of approved therapists from the EAPAA standards.
                  </span>
                </Label>
                <Select
                  className={clsx({
                    'is-invalid': touched.lastName && !!errors.profession,
                  })}
                  classNamePrefix='react-select'
                  name='profession'
                  options={ProfessionOptions}
                  value={ProfessionOptions.filter((o) => values.profession === o.value)}
                  onChange={(o) => {
                    setFieldValue('profession', o.value);
                  }}
                  onBlur={() => setTouched({ ...touched, profession: true })}
                />
                {values.profession === 'Other Specialist Provider' && (
                  <span className='text-red text-sm mt-1'>
                    Please note, you may not show up to some of our EAP clients given your
                    profession is not approved by EAPAA.
                  </span>
                )}
                {errors.profession && <FormFeedback>{errors.profession}</FormFeedback>}
              </FormGroup>

              <FormGroup>
                <Label>
                  Website Address - <span style={{ fontStyle: 'italic' }}>Optional</span>
                </Label>
                <Input
                  bsSize='lg'
                  name='websiteAddress'
                  value={values.websiteAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.websiteAddress && !!errors.websiteAddress}
                />
                {errors.websiteAddress && <FormFeedback>{errors.websiteAddress}</FormFeedback>}
              </FormGroup>

              <FormGroup check className='text-center mb-2'>
                <Label check>
                  <Input type='checkbox' required={true} /> I agree to Clearhead's {link}, and
                  understand that I will not be a Clearhead employee, but an independant contractor
                  who provides services through the platform.
                </Label>
              </FormGroup>

              <div className='text-center mt-3'>
                {onBack && (
                  <Button
                    color='primary'
                    size='lg'
                    className='mr-2'
                    onClick={onBack}
                    disabled={isSubmitting}
                  >
                    Back
                  </Button>
                )}
                <Button color='primary' size='lg' type='submit' disabled={isSubmitting}>
                  Next
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default ProfileForm;
