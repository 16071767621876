import styled, { css } from "styled-components";

const NavigationCss = css`
  background-color: ${props =>
    props.scrollposition > 20 ? "white;" : "transparent;"};
  box-shadow: ${props =>
    props.scrollposition > 20
      ? "0 2px 10px -2px hsla(0, 0%, 0%, 0.2);"
      : "none;"};
  transition: background-color 0.1s ease;

  .nav-link {
    font-size: 18px;
    color: ${props =>
    props.scrollposition > 20 ? "#4987ce;" : "white;"};
  }

  .menu-icon {
    color: ${props =>
    props.scrollposition > 20 ? "#4987ce;" : "white;"};
  }

  .nav-logo {
    path {
      fill: ${props =>
        props.scrollposition > 20 ? "#4987ce;" : "white;"};
    }
  }
`;

export const NavList = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > .nav-link {
    margin-right: 24px;
  }
`;

export const NavigationContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  ${NavigationCss}
`;

export const DropdownContent = styled.div`
  top: 75px;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  background-color: white;
  min-width: 160px;
  z-index: 1;
`;

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownText = styled.a`
  display: flex;
  justify-content: center;
  margin: 0 0 20px 0;
  padding: 0 20px 0 20px;
  font-size: 20px;
  color: black;
  &:not(.disable):hover {
    color: black;
    text-decoration: none;
  }
`;
