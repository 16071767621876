import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'reactstrap';

const Page404 = () => (
	<div className='text-center'>
		<h1 className='display-1 font-weight-bold'>404</h1>
		<p className='h1 mb-4'>Page not found.</p>
		<Link to='/'>
			<Button color='primary' size='lg'>
				Return to dashboard
			</Button>
		</Link>
	</div>
);

export default Page404;
