import * as React from 'react';
import { observer, inject } from 'mobx-react';
import LocationsComponent from './LocationsComponent';

const AddLocationsStep = inject()(
  observer(() => {
    return (
      <>
        <div className="w-full max-w-screen-md m-auto pb-10 xs:px-4 md:px-4">
          <div className="flex flex-col w-full items-center md:px-8 lg:px-8">
            <LocationsComponent />
          </div>
        </div>
      </>
    );
  })
);

export default AddLocationsStep;
