import React from "react";

/* Components */
import Main from "components/Main";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Background } from "./styles";

const Marketing = ({ children }) => (
  <Background>
    <Navbar />
    <Main>{children}</Main>
    <Footer />
  </Background>
);

export default Marketing;
