import styled from 'styled-components';
import Async from 'react-select/async';

import theme from 'styles/theme';

const AsyncSearch = styled(Async)`
  .react-select__control {
    padding-left: 7px;
    padding-right: 7px;
    min-height: calc(2.7rem + 2px);
    font-size: 1rem;

    &--is-focused {
      box-shadow: none;
    }
  }
  .react-select__single-value,
  .react-select__multi-value {
    left: 25px;
    font-weight: 400;
  }
  .react-select__option {
    cursor: pointer;
  }
  .react-select__control {
    cursor: text;
  }
  .react-select__loading-indicator * {
    font-size: 6px !important;
  }
  .react-select__indicator-separator,
  .react-select__indicator.react-select__dropdown-indicator {
    display: none;
  }

  /* older css */
  .react-select__control {
    border-radius: 4px;
    border: 1px solid hsla(0, 0%, 0%, 0.2);

    label {
      margin: 0;
    }
  }

  .react-select__control--is-focused {
    border-color: ${theme.primary};
  }

  .react-select__placeholder {
    color: #aaa;
  }

  .react-select__menu {
    top: auto;
    border-radius: 4px;
    box-shadow: 0 1px 10px 1px hsla(0, 0%, 0%, 0.2);
  }

  .react-select__option--is-focused {
    color: white;
    background-color: ${theme.secondary};

    :active {
      background-color: ${theme.secondary};
    }
  }

  .react-select__group-heading {
    margin-left: 7px;
    margin-right: 7px;
    padding: 0 5px;
    padding-bottom: 5px;
    color: ${theme.primary};
    font-weight: 600;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.1);
  }
  min-width: 300px;
`;

export default AsyncSearch;
