import React from 'react';
import { useKeycloak } from '@react-keycloak-fork/web'
import { Button } from "reactstrap";

const TryDifferentAccountButton = () => {
  const { keycloak } = useKeycloak();
  const redirectUri = new URL(`${window.location.origin}/app`);
  const loginRedirectUri = keycloak.createLoginUrl({
    redirectUri
  });
  return (
    <Button
      size="lg"
      className="align-middle d-none d-md-block"
      color="secondary"
      onClick={() =>
        keycloak.logout({
          redirectUri: loginRedirectUri
        })
      }
    >
      Log in with a different account
    </Button>
  )
}

export default TryDifferentAccountButton;