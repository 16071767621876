import { transparentize } from 'polished';

export interface IColor {
  100: string;
  50: string;
  15: string;
}

interface IFontConfig {
  family: string;
  weight: number;
  size: string;
}

interface ITypeWeights {
  light: number;
  regular: number;
  semiBold: number;
  bold: number;
}

interface ITypography {
  rootFontSize: string;
  headers: {
    h1: IFontConfig,
    h2: IFontConfig,
    h3: IFontConfig,
    h4: IFontConfig,
  };
  body: IFontConfig;
  link: IFontConfig;
  small: IFontConfig;
  buttons: IFontConfig;
  form: {
    placeholder: IFontConfig,
    labels: IFontConfig,
    inputs: IFontConfig,
  };
}

const weights: ITypeWeights = {
  light: 300,
  regular: 400,
  semiBold: 600,
  bold: 700,
};

const blue: IColor = {
  100: '#4987CE',
  50: transparentize(0.5, '#4987CE'),
  15: transparentize(0.85, '#4987CE'),
};

const darkBlue: IColor = {
  100: '#003473',
  50: transparentize(0.5, '#003473'),
  15: transparentize(0.85, '#003473'),
};

const green: IColor = {
  100: '#3BB897',
  50: transparentize(0.5, '#3BB897'),
  15: transparentize(0.85, '#3BB897'),
};

const darkGreen: IColor = {
  100: '#05805F',
  50: transparentize(0.5, '#05805F'),
  15: transparentize(0.85, '#05805F'),
};

const black: IColor = {
  100: '#000',
  50: transparentize(0.5, '#000'),
  15: transparentize(0.85, '#000'),
};

const grey: IColor = {
  100: '#707070',
  50: transparentize(0.5, '#707070'),
  15: transparentize(0.85, '#707070'),
};

const red: IColor = {
  100: '#FF2F58',
  50: transparentize(0.5, '#FF2F58'),
  15: transparentize(0.85, '#FF2F58'),
};

const darkRed: IColor = {
  100: '#C30026',
  50: transparentize(0.5, '#C30026'),
  15: transparentize(0.85, '#C30026'),
};

const orange: IColor = {
  100: '#FD7E14',
  50: transparentize(0.5, '#FD7E14'),
  15: transparentize(0.85, '#FD7E14'),
};

const purple: IColor = {
  100: '#6F42C1',
  50: transparentize(0.5, '#6F42C1'),
  15: transparentize(0.85, '#6F42C1'),
};

const primaryFont =
  'Source Sans Pro, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif';
const headingFont =
  'GT Super Text, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif';

const fonts: ITypography = {
  rootFontSize: '16px',
  headers: {
    h1: {
      family: headingFont,
      weight: weights.bold,
      size: '1.467rem',
    },
    h2: {
      family: headingFont,
      weight: weights.bold,
      size: '1.2rem',
    },
    h3: {
      family: headingFont,
      weight: weights.bold,
      size: '1.067rem',
    },
    h4: {
      family: headingFont,
      weight: weights.semiBold,
      size: '1rem',
    },
  },
  body: {
    family: primaryFont,
    weight: weights.regular,
    size: '1rem',
  },
  small: {
    family: primaryFont,
    weight: weights.regular,
    size: '0.9333rem',
  },
  buttons: {
    family: primaryFont,
    weight: weights.bold,
    size: '1rem',
  },
  link: {
    family: primaryFont,
    weight: weights.bold,
    size: '0.8667rem',
  },
  form: {
    placeholder: {
      family: primaryFont,
      weight: weights.regular,
      size: '1rem',
    },
    labels: {
      family: primaryFont,
      weight: weights.regular,
      size: '1rem',
    },
    inputs: {
      family: primaryFont,
      weight: weights.regular,
      size: '1rem',
    },
  },
};

export interface ITheme {
  colors: { [key: string]: IColor };
  fonts: ITypography;
  weights: ITypeWeights;
}

const theme = {
  colors: {
    blue,
    darkBlue,
    green,
    darkGreen,
    red,
    darkRed,
    orange,
    purple,
    grey,
    black,
  },
  fonts,
  weights,
};

export default theme;
