import styled from 'styled-components';
import { Card } from '@clearhead-ltd/ui-components/dist/v2/Card';
import FormDropdown from '@clearhead-ltd/ui-components/dist/v2/FormDropdown';

export const WizardStepContainer = styled.div`
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    max-width: 130px !important;
  }
  @media screen and (max-width: 767px) {
    column-gap: 20px;
    button {
      max-width: 130px !important;
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    column-gap: 20px;
    button {
      max-width: 130px !important;
      width: 100%;
    }
  }
`;

export const FooterNav = styled.footer`
  display: grid;
  width: 100%;
  background-color: #ecf2f9;

  bottom: 0;
  grid-template-columns: 1fr ${(props) => (props.skip ? ' 1fr' : '')} 1fr;
  grid-template-rows: auto;
  padding: 12px 0px;
  z-index: 0;

  @media screen and (max-width: 767px) {
    column-gap: 20px;
    button {
      max-width: 130px !important;
      width: 100%;
    }
  }
`;

export const FooterContainer = styled.div`
  background-color: #a4c3e6;
`;

export const Container = styled.div`
  display: grid;
  height: 100vh;
  grid-template-rows: 1fr 100px;
  background-color: #ecf2f9;
`;

export const FormContainer = styled.div`
  z-index: 1;
  background-color: #ecf2f9;
`;

export const OptionCard = styled(Card)`
  background: ${(props) => (props.active ? props.theme.secondaryColor : 'white')};
  ${(props) =>
    !props.active &&
    `
    border: 1px solid var(--blue-50);
  `}
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
`;

export const PillButton = styled.div`
  background: #4987ce;
  border: none;
  color: black;
  padding: 5px 15px;
  padding-right: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: default;
  border-radius: 100px;
`;

export const Image = styled.img`
  fill: white;
  transform: rotate(45deg);
  cursor: pointer;
`;

export const DropdownMenu = styled(FormDropdown)`
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__menu {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-style: solid;
    border-width: 1px 1px 1px 2px;
    border-radius: 4px;
    border-color: #a4c3e6;
    box-shadow: none;
  }

  .react-select__control {
    border-color: ${(props) => (props.colour ? props.colour : '#a4c3e6')};
    box-shadow: none;
  }

  .react-select__control:hover {
    border-color: ${(props) => (props.colour ? props.colour : '#a4c3e6')};
  }

  .react-select__control:focus-visible {
    border-color: ${(props) => (props.colour ? props.colour : '#a4c3e6')};
  }

  .react-select__option--is-focused {
    color: #003473;
  }
`;

export const DropdownColourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? '#4987CE'
        : isFocused
        ? '#E4EDF8'
        : 'white',
      color: isSelected ? '#FFF' : isFocused ? '#FFF' : 'black',
    };
  },
};

export const wizardContainerStyle =
  'md:w-3/4 lg:w-3/5 xl:w-6/12 xxl:w-5/12 xxxl:w-4/12 m-auto pb-10 xs:px-4 sm:px-4';
