import * as React from 'react';
import moment from 'moment';
import { PopupButton } from '@typeform/embed-react';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';

import OptionCard from './OptionCard';

type Props = {
  data: any;
  selected: boolean;
  practitionerId: number;
  therapistName: string;
  therapistEmail: string;
  clientId: number;
  defaultSelectedTenant?: string;
  onClick: (id: string) => void;
  className?: string;
};

const fundingEligibleOrganisations = ['organisation', 'unimed'];

const EligibilityOption: React.FC<Props> = ({
  data,
  selected,
  onClick,
  defaultSelectedTenant,
  therapistName,
  therapistEmail,
  className,
}) => {
  const ref = React.useRef<typeof PopupButton>();

  const openPopup = () => ref.current?.open();

  const currentLimit = React.useMemo(() => {
    if (data && data.limits && data.limits.length > 0) {
      return { ...data.limits[0] };
    }
    return '';
  }, [data]);

  const isSelected = React.useMemo(() => {
    return selected;
  }, [currentLimit, defaultSelectedTenant, selected]);

  const isDisabled = React.useMemo(() => {
    return (
      currentLimit.remainingSessions <= 0 &&
      data.extensionsSupported === false &&
      fundingEligibleOrganisations.indexOf(data.fundedBy) > -1 &&
      defaultSelectedTenant !== data.tenantName
    );
  }, [currentLimit, defaultSelectedTenant, data]);

  const handleOpen = () => {
    if (
      currentLimit.remainingSessions <= 0 &&
      data.extensionsSupported &&
      fundingEligibleOrganisations.indexOf(data.fundedBy) > -1
    ) {
      openPopup();
    } else if (
      currentLimit.remainingSessions <= 0 &&
      data.extensionsSupported === false &&
      fundingEligibleOrganisations.indexOf(data.fundedBy) > -1 &&
      defaultSelectedTenant === data.tenantName
    ) {
      onClick(currentLimit.id);
    } else if (
      currentLimit.remainingSessions <= 0 &&
      data.extensionsSupported === false &&
      fundingEligibleOrganisations.indexOf(data.fundedBy) > -1 &&
      defaultSelectedTenant !== data.tenantName
    ) {
      alert('This funding option is not available.');
      // OPEN POP UP AND SAY IT'S NOT SUPPORTED
    } else {
      onClick(currentLimit.id);
    }
  };

  const renderInformation = () => {
    if (
      currentLimit.remainingSessions <= 0 &&
      data.extensionsSupported &&
      fundingEligibleOrganisations.indexOf(data.fundedBy) > -1
    ) {
      if (defaultSelectedTenant !== data.tenantName) {
        return (
          <div className='flex flex-col justify-center items-end' onClick={() => openPopup()}>
            <Text className='text-xs font-bold'>{currentLimit.remainingSessions} left</Text>
            <Text className='text-xs font-bold text-primary-blue-100'>
              Request Session Extension
            </Text>
          </div>
        );
      } else {
        return;
      }
    }
    if (
      currentLimit.remainingSessions <= 0 &&
      data.extensionsSupported === false &&
      fundingEligibleOrganisations.indexOf(data.fundedBy) > -1
    ) {
      return;
    }
    if (
      currentLimit.remainingSessions > 0 &&
      fundingEligibleOrganisations.indexOf(data.fundedBy) > -1
    ) {
      if (defaultSelectedTenant !== data.tenantName) {
        return (
          <div className='flex flex-col justify-center items-end'>
            <Text className='text-xs font-bold'>{currentLimit.remainingSessions} left</Text>
            <Text className='text-xs italic'>
              Expires on {moment(currentLimit.endDate).format('DD/MM/YYYY')}
            </Text>
          </div>
        );
      } else {
        return;
      }
    }
    return null;
  };
  return (
    <div className={className}>
      <OptionCard
        onClick={handleOpen}
        isSelected={isSelected}
        isDisabled={isDisabled}
        showSessionExtension={data.extensionsSupported}
        onSessionExtensionClick={() => openPopup()}
        EntitlementInformation={renderInformation()}
        HeadingTitle={
          <>
            {data.entitlementDisplayName}
            {defaultSelectedTenant === data.tenantName ? (
              <Text className='ml-2 mb-0'>(Claimed)</Text>
            ) : null}
          </>
        }
      />
      <PopupButton
        id='X18ZnYic'
        ref={ref}
        className='hidden'
        hidden={{
          therapist_id: `${therapistName}`,
          client_id: `${currentLimit?.caseNumber}`,
          tenant_code: data.tenantName,
          therapist_email: therapistEmail,
        }}
        onReady={() => {
          // Hack to undo hack about a modal opening a modal leaving overflow: hidden on the body.
          // see file `src/common/styles.css`
          document.body.classList.add('modal-open');
        }}
        onClose={() => {
          document.body.classList.remove('modal-open');
        }}
      />
    </div>
  );
};

export default EligibilityOption;
