import { flow, types } from 'mobx-state-tree';

import LocationService from 'services/LocationService';
import { tagManagerEvent } from '../../../utilities/analytics';

export const Location = types.model('Location', {
  id: types.identifierNumber,
  city: types.maybeNull(types.string),
  floorNumber: types.maybeNull(types.string),
  formattedAddress: types.maybeNull(types.string),
  latitude: types.maybeNull(types.number),
  clearheadTelehealthEnabled: types.maybeNull(types.boolean),
  longitude: types.maybeNull(types.number),
  locationType: types.maybeNull(types.string),
  notes: types.maybeNull(types.string),
  postCode: types.maybeNull(types.string),
  primaryAddress: types.optional(types.boolean, false),
  streetAddress: types.maybeNull(types.string),
  suburb: types.maybeNull(types.string),
})
.preProcessSnapshot((snap) => {
  if (snap) {
    return {
      ...snap,
      primaryAddress: snap.primaryAddress || undefined,
    };
  }
});

const SavedLocation = types.model('SavedLocation', {
  id: types.identifierNumber,
  location: Location,
  nickname: types.maybeNull(types.string),
  privateLocation: types.boolean,
});

const Locations = types
  .model('Locations', {
    allLocations: types.array(SavedLocation),
  })
  .actions((self) => ({
    getLocations: flow(function* () {
      const res = yield LocationService.getLocations();
      if (!res.hasError) {
        self.allLocations = res.data.responseObject;
        self.sortByNickname();
      }
      return res;
    }),
    createLocation: flow(function* (data) {
      const res = yield LocationService.createLocation(data);
      if (!res.hasError) {
        self.allLocations.push(res.data.responseObject);
        self.sortByNickname();

        tagManagerEvent('Location Created');
      }
      return res;
    }),
    editLocation: flow(function* (data) {
      const res = yield LocationService.editLocation(data);
      if (!res.hasError) {
        console.log(self.allLocations.length);
        const newLocations = self.allLocations
          .slice()
          .filter((location) => location.id !== data.id);
        newLocations.push(res.data.responseObject);
        self.allLocations = newLocations;
        self.sortByNickname();

        tagManagerEvent('Location Updated');
      }
      return res;
    }),
    deleteLocation: flow(function* (data) {
      const res = yield LocationService.deleteLocation(data.id);
      if (!res.hasError) {
        self.allLocations.remove(data);

        tagManagerEvent('Location Deleted');
      }
      return res;
    }),

    sortByNickname: function (locations) {
      self.allLocations = self.allLocations
        .slice()
        .sort((a, b) => a.nickname.localeCompare(b.nickname));
    },
  }));

export default Locations;
