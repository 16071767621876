import * as React from 'react';
import { ModifierKeys } from 'styled-components-modifiers';
import { BaseTextArea } from './styles';

export interface ITextArea extends React.HTMLAttributes<HTMLTextAreaElement> {
  modifiers?: ModifierKeys;
  name?: string;
  invalid?: boolean;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, ITextArea>(
  ({ children, modifiers, className, ...rest }: ITextArea, ref: React.Ref<HTMLTextAreaElement>) => (
    <BaseTextArea ref={ref} className={className} modifiers={modifiers} {...rest}>
      {children}
    </BaseTextArea>
  )
);

TextArea.displayName = 'TextArea';

export default TextArea;
