import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import moment from 'moment';

class CustomDay extends Component {
  render() {
    let { date } = this.props;
    let range = CustomDay.range(date);
    return <TimeGrid {...this.props} range={range} />;
  }
}

const currentDay = moment(new Date(2022, 0, 20));
const startOfWeek = moment(currentDay).startOf('week');
const endOfWeek = moment(currentDay).endOf('week');

CustomDay.navigate = (date, action) => {
  const currentDate = moment(date);
  const canPrev =
    currentDate.isAfter(startOfWeek) && !currentDate.isSame(startOfWeek, 'day');
  const canNext =
    currentDate.isBefore(endOfWeek) && !currentDate.isSame(endOfWeek, 'day');

  switch (action) {
    case BigCalendar.Navigate.PREVIOUS:
      return canPrev
        ? currentDate.add(-1, 'days').toDate()
        : endOfWeek.toDate();

    case BigCalendar.Navigate.NEXT:
      return canNext
        ? currentDate.add(1, 'days').toDate()
        : startOfWeek.toDate();

    default:
      return currentDate;
  }
};

CustomDay.range = (date) => {
  const startOfGivenDate = moment(date).startOf('day').toDate();
  return [startOfGivenDate];
};

CustomDay.title = (date) => {
  return moment(date).format('dddd');
};

// CustomDay.applyScroll = (a, b, c, d, e, f, g) => {
// 	debugger;
// 	if (this._scrollRatio) {
// 		const { content } = this.refs;
// 		content.scrollTop = content.scrollHeight * this._scrollRatio;
// 		// Only do this once
// 		this._scrollRatio = null;
// 	}
// };

export default CustomDay;
