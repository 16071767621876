import React, { Component } from 'react';

import { Icon, CHLogo, GoogleLogo, OutlookLogo } from './styles';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

class Event extends Component {
  renderIcon = (source) => {
    switch (source) {
      case 'Google':
        return <GoogleLogo />;
      case 'Outlook':
        return <OutlookLogo />;
      default:
        return <CHLogo />;
    }
  }

  render() {
    const { event, title } = this.props;
    let isGroup = (event.event.type && event.event.type === 'Group') ? true : false;
    return (
      <>
        <div className="d-flex justify-content-start align-items-start">
          {isGroup ? (
            <FontAwesomeIcon
              className="align-middle mt-1 mr-2"
              icon={faUsers}
              size="sm"
              fixedWidth
            />
          ) : event.event.source && event.event.source !== 'Clearhead' && <div className="m-0 mr-2">
            <Icon title={`Imported from ${event.event.source}`}>{this.renderIcon(event.event.source)}</Icon>
          </div>}
          <h6 className="m-0">{title}</h6>
        </div>
      </>
    );
  }
}

export default Event;