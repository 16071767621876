import { flow, types } from 'mobx-state-tree';

import PriceService from 'services/PriceService';
import { tagManagerEvent } from '../../../utilities/analytics';

const Price = types
	.model('Price', {
		id: types.identifierNumber,
		name: types.string,
		cost: types.string,
		description: types.optional(types.string, ''),
		hasCriteria: types.boolean,
		criteria: types.optional(types.string, ''),
		bookingLength: types.optional(types.string, ''),
		sessionLength: types.string,
		defaultPrice: types.boolean,
	})
	.preProcessSnapshot(snap => {
		if (snap) {
			return {
				...snap,
				description: snap.description || undefined,
				criteria: snap.criteria || undefined,
				bookingLength: snap.bookingLength || undefined,
			};
		}
	});

const Prices = types
	.model('Prices', {
		allPrices: types.array(Price),
	})
	.actions(self => ({
		getPrices: flow(function*() {
			const res = yield PriceService.getPrices();
			if (!res.hasError) {
				self.allPrices = res.data.responseObject;
				self.sortByName();
			}
			return res;
		}),
		createPrice: flow(function*(data) {
			const res = yield PriceService.createPrice(data);
			if (!res.hasError) {
				self.allPrices.push(res.data.responseObject);
				self.sortByName();

				tagManagerEvent('Price Created');
			}
			return res;
		}),
		deletePrice: flow(function*(data) {
			const res = yield PriceService.deletePrice(data.id);
			if (!res.hasError) {
				self.allPrices.remove(data);

				tagManagerEvent('Price Deleted');
			}
			return res;
		}),
		editPrice: flow(function*(oldPrice, data) {
			const res = yield PriceService.editPrice(data);
			if (!res.hasError) {
				self.allPrices.remove(oldPrice);
				self.allPrices.push(res.data.responseObject);
				self.sortByName();

				tagManagerEvent('Price Updated');
			}
			return res;
		}),

		sortByName: function(locations) {
			self.allPrices = self.allPrices.slice().sort((a, b) => a.name.localeCompare(b.name));
		},
	}));

export default Prices;
