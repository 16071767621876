import React, { Component } from 'react';
import styled from 'styled-components';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import { Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Upload } from 'react-feather';
import { withRouter } from 'react-router-dom';
import { withKeycloak } from '@react-keycloak-fork/web';

import UploadPictureModal from 'common/UploadPictureModal';
import BasicInfoSection from './basic_info_section';
import ProfessionalInfoSection from './professional_info_section';
import { Loading } from 'common';
import { UrlParamRegex } from 'configuration';

import CalendarSyncSettings from './calendar_sync_settings';
import InvoiceSection from './invoice_section';

const Image = styled.img`
  object-fit: cover;
  width: 150px;
  height: 150px;
`;
@inject('auth')
@withRouter
@withKeycloak
@observer
class MyAccount extends Component {
  @observable selectedSection = 'basic-info';
  @observable pictureModalOpen = false;

  constructor(props) {
    super(props);

    const { location } = props;
    if (location.search) {
      while (true) {
        let paramArr = UrlParamRegex.exec(location.search);
        if (!paramArr) {
          break;
        }
        const key = paramArr[1];
        const value = paramArr[2];
        if (key === 'section') {
          this.selectSection(value);
        }
      }
    }
  }

  selectSection = (value) => {
    switch (value) {
      case 'basic-info':
        this.selectedSection = 'basic-info';
        break;
      case 'professional-info':
        this.selectedSection = 'professional-info';
        break;
      case 'change-password':
        this.selectedSection = 'change-password';
        break;
      case 'calendar-sync':
        this.selectedSection = 'calendar-sync';
        break;
      case 'integrations':
        this.selectedSection = 'integrations';
        break;
      case 'invoice-settings':
        this.selectedSection = 'invoice-settings';
        break;
      default:
        break;
    }
  };

  togglePictureModal = () => {
    this.pictureModalOpen = !this.pictureModalOpen;
  };

  onChangeSection = (section) => {
    this.selectedSection = section;
  };

  getSelectedSection = () => {
    if (this.selectedSection === 'basic-info') {
      return <BasicInfoSection />;
    } else if (this.selectedSection === 'professional-info') {
      return <ProfessionalInfoSection />;
    } else if (this.selectedSection === 'change-password') {
      return (
        <Card>
          <CardBody>{/* <ChangePasswordForm /> */}</CardBody>
        </Card>
      );
    } else if (this.selectedSection === 'calendar-sync') {
      return (
        <div>
          <CalendarSyncSettings />
        </div>
      );
    } else if (this.selectedSection === 'invoice-settings') {
      return (
        <div>
          <InvoiceSection />
        </div>
      );
    }
  };

  render() {
    const {
      auth: { currentPractitioner, userImage },
    } = this.props;

    const highlightedClassName = 'bg-primary border-primary';
    const basicInfoSelected = this.selectedSection === 'basic-info';
    const professionalInfoSelected = this.selectedSection === 'professional-info';
    const calendarSyncSelected = this.selectedSection === 'calendar-sync';
    const invoiceSelected = this.selectedSection === 'invoice-settings';

    if (!currentPractitioner) {
      return <Loading />;
    }

    return (
      <>
        <Container fluid>
          <Heading className='text-xl text-dark-blue mb-3'>My Account</Heading>
          <Row>
            <Col md='4' xl='3'>
              <Card className='overflow-hidden'>
                <CardBody className='flex flex-col items-center text-center'>
                  <Image src={userImage} className='img-fluid rounded-circle mb-2' />
                  <CardTitle tag='h5' className='mb-0'>
                    {currentPractitioner.name}
                  </CardTitle>

                  <Button
                    size='md'
                    color='primary'
                    className='mt-2 flex items-center'
                    onClick={this.togglePictureModal}
                  >
                    <Upload width={16} height={16} className='mr-2' />
                    Upload Picture
                  </Button>
                </CardBody>

                <hr className='my-0' />
                <div
                  onClick={this.onChangeSection.bind(this, 'basic-info')}
                  className={`p-3 cursor-pointer ${basicInfoSelected ? highlightedClassName : ''}`}
                >
                  <h5 className={`m-0 text-center ${basicInfoSelected ? 'text-white' : ''}`}>
                    Basic Info
                  </h5>
                </div>

                <hr className='my-0' />
                <div
                  onClick={this.onChangeSection.bind(this, 'professional-info')}
                  className={`p-3 cursor-pointer ${
                    professionalInfoSelected ? highlightedClassName : ''
                  }`}
                >
                  <h5 className={`m-0 text-center ${professionalInfoSelected ? 'text-white' : ''}`}>
                    About You
                  </h5>
                </div>

                <hr className='my-0' />
                <div
                  onClick={this.onChangeSection.bind(this, 'calendar-sync')}
                  className={`p-3 cursor-pointer ${
                    calendarSyncSelected ? highlightedClassName : ''
                  }`}
                >
                  <h5 className={`m-0 text-center ${calendarSyncSelected ? 'text-white' : ''}`}>
                    Calendar Integrations
                  </h5>
                </div>

                {this.props.auth.currentPractitioner.invoicingEnabled && (
                  <>
                    <hr className='my-0' />
                    <div
                      onClick={this.onChangeSection.bind(this, 'invoice-settings')}
                      className={`p-3 cursor-pointer ${
                        invoiceSelected ? highlightedClassName : ''
                      }`}
                    >
                      <h5 className={`m-0 text-center ${invoiceSelected ? 'text-white' : ''}`}>
                        Invoice Settings
                      </h5>
                    </div>
                  </>
                )}

                <hr className='my-0' />
              </Card>
            </Col>

            <Col md='8' xl='9'>
              {this.getSelectedSection()}
            </Col>
          </Row>
        </Container>

        <UploadPictureModal
          isModalOpen={this.pictureModalOpen}
          toggleModal={this.togglePictureModal}
        />
      </>
    );
  }
}

export default MyAccount;
