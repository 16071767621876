import { SVGProps } from 'react';
const QuestionMark = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props}>
    <circle
      cx={12.356}
      cy={12.723}
      r={10.5}
      stroke='currentColor'
      strokeWidth={2}
      transform='rotate(-45 12.356 12.723)'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M13.564 14.764c-.063.214-.097.547-.103.999h-2.13c.032-.954.116-1.613.253-1.977.136-.364.489-.783 1.057-1.258l.576-.482c.19-.152.342-.32.457-.5.21-.31.316-.652.316-1.024 0-.43-.117-.82-.351-1.173-.234-.353-.662-.529-1.282-.529-.61 0-1.043.217-1.298.652a2.64 2.64 0 0 0-.383 1.355h-2.28c.063-1.609.587-2.75 1.57-3.42.62-.43 1.384-.644 2.288-.644 1.189 0 2.176.304 2.963.914.786.61 1.18 1.513 1.18 2.71 0 .733-.172 1.351-.514 1.854-.2.304-.583.694-1.151 1.168l-.56.466c-.306.254-.508.55-.608.889Zm-.168 1.999v2h-2v-2h2Z'
      clipRule='evenodd'
    />
  </svg>
);
export default QuestionMark;
