import React, { Component } from 'react';
import { Button, Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import * as Icon from 'react-feather';
import moment from 'moment';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';

import { Loading } from 'common';
import formatDate from 'utilities/formatDate';

import InvoiceCard from './InvoiceCard';
import InvoiceFormCard from './InvoiceFormCard';
import HelpLink from './HelpLink';

@withRouter
@inject('invoices', 'calendar')
@observer
class Invoice extends Component {
  @observable loading = true;

  @observable loadingRelated = true;

  @observable relatedInvoices = [];

  @observable showNewForm = false;

  @observable active = null;

  constructor(props) {
    super(props);
  }

  componentDidMount = async () => {
    await this.fetchInvoices();
  };

  toggleActive = (id) => {
    if (this.active === id) {
      this.active = null;
    } else {
      this.active = id;
    }
  };

  @action
  setLoading = (value) => {
    this.loading = value;
  };

  renderInvoices = (invoicesList) => {
    const { invoices } = this.props;

    return invoicesList.map((data) => {
      const invoice = invoices.all.get(data.id);
      return (
        <InvoiceCard
          key={invoice.id}
          isOpen={invoicesList.length === 1 || invoice.id === this.active}
          item={invoice}
          onEdit={() => (this.active = invoice.id)}
          onUpdate={this.onUpdate}
          toggle={invoicesList.length > 1 ? () => this.toggleActive(invoice.id) : null}
        />
      );
    });
  };

  fetchInvoices = async (activeId = 0) => {
    const { match, invoices } = this.props;

    if (match.params.id && match.params.id.length) {
      const response = await invoices.get(match.params.id);
      if (!response.hasError) {
        this.active = activeId || response.data.id;
        const res = await invoices.related(match.params.id);
        if (!res.hasError) {
          this.relatedInvoices = res.data;
        }
      }
    }
    // this.loading = false;
    this.setLoading(false);
  };

  onUpdate = async (response) => {
    await this.fetchInvoices(response.data.id);
  };

  onSubmit = async (response) => {
    this.showNewForm = false;
    await this.fetchInvoices(response.data.id);
  };

  renderInvoiceForm = (item) => {
    if (this.showNewForm) {
      const initialValue = {
        id: 0,
        invoiceDate: moment().format('YYYY-MM-DD'),
        billFrom: item.billFrom,
        name: undefined,
        streetAddress: undefined,
        suburb: undefined,
        city: undefined,
        billToOption: {
          label: 'Client',
          value: 'CLIENT',
        },
        dueDate: moment().add(1, 'week').format('YYYY-MM-DD'),
        gstNumber: undefined,
        appointmentId: item.appointment.id,
        items: [],
        notes: undefined,
        client: {
          label: item.client.contactName,
          value: item.client.id,
          email: item.client.email,
        },
      };
      return (
        <InvoiceFormCard
          initialValue={initialValue}
          onSubmit={this.onSubmit}
          onDiscard={() => (this.showNewForm = false)}
        />
      );
    }
  };

  renderNewInvoice = (item) => {
    if (!this.loading && item) {
      const showCreateButton =
        item.status == 'CANCELLED' &&
        this.relatedInvoices.map((i) => i.status).filter((x) => x !== 'CANCELLED').length === 0;

      if (showCreateButton) {
        return (
          <Button
            color='primary'
            size='md'
            // style={{ height: '30px', width: '120px' }}
            onClick={async () => {
              this.loading = true;
              const response = await this.props.calendar.createInvoiceForAppointment(
                item.appointment.id
              );
              await this.fetchInvoices(response.id);
              this.props.history.replace(`/app/invoice/${response.id}`);
              this.loading = false;

              // this.showNewForm = true;
              // this.active = null;
            }}
            className='rounded-full font-bold h-8 d-flex flex-row justify-content-center align-items-center mb-1'
          >
            <Icon.PlusCircle size={14} className='mr-2' />
            Invoice
          </Button>
        );
      }
    }
  };

  render() {
    const { invoices, match } = this.props;
    const item = invoices.all.get(match.params.id);

    return (
      <Container fluid className='p-2 max-w-7xl'>
        <Row>
          <Col
            sm='12'
            md={{ size: 10, offset: 1 }}
            lg={{ size: 8, offset: 2 }}
            className='d-flex justify-content-start align-items-center pt-2 pb-2'
          >
            <div className='d-flex d-flex-row justify-content-between align-items-end w-full'>
              {item && (
                <div className='d-flex flex-column justify-content-start align-items-start'>
                  <Heading as='h3' className='text-dark-blue'>
                    Appointment #{item.appointment.id}
                  </Heading>
                  <p className='text-xs'>
                    {formatDate(item.appointment.appointmentTime)} with {item.client.contactName}
                  </p>
                </div>
              )}
              <div className='d-flex flex-col align-items-end'>
                {this.renderNewInvoice(item)}
                {item ? (
                  <div className='print:hidden'>
                    <HelpLink
                      variant='invoice'
                      invoiceId={item.id ?? 'unknown'}
                      appointmentId={item.appointment.id}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 12 }} md={{ size: 10, offset: 1 }} lg={{ size: 10, offset: 1 }}>
            {this.renderInvoiceForm(item)}
          </Col>
        </Row>
        {this.loading ? (
          <Loading />
        ) : (
          <>
            <Row>
              <Col sm={{ size: 12 }} md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
                {this.renderInvoices(item ? [item] : [])}
              </Col>
            </Row>
          </>
        )}
      </Container>
    );
  }
}

export default Invoice;
