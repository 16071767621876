import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';

import BookingsSection from './BookingsSection';

class Bookings extends Component {
  render() {
    return (
      <Container fluid className='p-0'>
        <div className='d-flex flex-wrap align-items-center mb-4'>
          <Heading className='text-xl text-dark-blue'>Booking Settings</Heading>
        </div>
        <BookingsSection />
      </Container>
    );
  }
}

export default Bookings;
