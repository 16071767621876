import React from 'react';
import clsx from 'clsx';

const defaultProps = {
  tag: 'ul',
};

const Timeline = ({ children, className, tag: Tag, ...rest }) => (
  <Tag className={clsx('timeline', className)} {...rest}>
    {children}
  </Tag>
);

Timeline.defaultProps = defaultProps;

export default Timeline;
