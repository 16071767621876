import React from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';

const TButton = styled(Button)`
	width: 16px;
	height: 16px;
	padding: 0;
	line-height: 16px;
`;

const TinyButton = ({ children, ...rest }) => {
	return <TButton {...rest}>{children}</TButton>;
};

export default TinyButton;
