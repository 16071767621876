import styled from 'styled-components';

export const FilterContainer = styled.div`
  position: relative;
  margin-bottom: 18px;
  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    top: 45%;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: #d4dde3;
  }
  @media screen and (max-width: 768px) {
    overflow-x: auto;
  }
`;

export const FilterButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f1f5fe;
  padding: 2px;
  border-radius: 12px;
`;

export const FilterButtonContainer = styled.div`
  background-color: #f1f5fe;
  padding: 2px;
`;

export const FilterButton = styled.button`
  padding: 10px 14px;
  border-radius: 10px;
  font-size: 1rem;
  outline: none;
  text-align: center;
  &:hover {
    background-color: #dee8fc;
  }
  &.is-active {
    background-color: white;
    font-weight: 700;
  }
`;

export const FilterCount = styled.span`
  font-weight: bold;
  padding: 0 8px;
  border-radius: 500px;
  font-size: 14px;
  line-height: 1.4;
  background: #d1e4f5;
  border: 1px solid #b2cfeb;
  color: #0a3361;
  margin-left: 3px;
`;
