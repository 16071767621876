import BaseService from '../BaseService';
import { BASE_API_URL } from 'configuration';

class ClientService {
	static getClients = ({ pageOffset, searchQuery, pageSize = 10 }) => {
		let suffix = '';
		if (searchQuery) {
			suffix = `&searchTerm=${searchQuery}`;
		}

		return BaseService.get(
			`${BASE_API_URL}/practitioners/me/contacts?pageOffset=${pageOffset}&pageSize=${pageSize}${suffix}`
		);
	};
	static createClient = data => {
		return BaseService.post(`${BASE_API_URL}/practitioners/me/contacts`, data);
	};
	static editClient = data => {
		return BaseService.put(`${BASE_API_URL}/practitioners/me/contacts`, data);
	};
	static deleteClient = id => {
		return BaseService.delete(`${BASE_API_URL}/practitioners/me/contacts/${id}`);
	};
	static getClientDetails = id => {
		return BaseService.get(`${BASE_API_URL}/practitioners/me/contacts/${id}`);
	};
	static getClientEligibility = id => {
		return BaseService.get(`${BASE_API_URL}/practitioners/me/contacts/${id}/entitlements`);
	};
}

export default ClientService;
