import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Card, CardBody, Button } from 'reactstrap';

import TryDifferentAccountButton from '../../../../common/TryDifferentAccountButton';
import { LogoContainer, CHLogo, CHText } from './styles';

@withRouter
@inject('auth')
@observer
class Forbidden extends Component {
  onSignUp = () => {
    const { history } = this.props;
    history.push('/auth/sign-up');
  };

  render() {
    return (
      <>
        <div className='text-center mt-4'>
          <LogoContainer>
            <CHLogo />
            <CHText />
          </LogoContainer>
          <p className='lead h5'>Forbidden</p>
        </div>

        <Card>
          <CardBody>
            <h4 className='text-center m-0'>
              You must sign up as a provider to log into the provider portal.
            </h4>
            <div className='d-flex justify-content-center mt-3'>
              <Button className='mr-3' color='primary' onClick={this.onSignUp}>
                Sign up
              </Button>
              <TryDifferentAccountButton />
            </div>
            <a
              className='d-block mt-3 text-center'
              href='https://www.myclearhead.com/app'
              rel='noopener noreferrer'
            >
              Not a mental health professional? Sign up here
            </a>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default Forbidden;
