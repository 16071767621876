import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';

import { ProfessionOptions } from 'configuration';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';
import DOBField from '../../../../shared-ui/DOBField';
import { FooterNav, wizardContainerStyle } from '../WizardForm/styles';
import { WizardFormContext } from '../index';
import { toast } from 'react-toastify';

type ProfessionalInfoStepProps = {
  auth: any;
};

const ProfessionalInfoStep: React.FC<ProfessionalInfoStepProps> = ({ auth }) => {
  const { onPrevious, onNext, updateData, data } = React.useContext(WizardFormContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [profession, setProfession] = React.useState<string>(data.profession ?? '');
  const [professionalStartDate, setProfessionalStartDate] = React.useState(
    data.professionalStartDate ?? auth.currentPractitioner.professionalStartDate ?? ''
  );

  const isValid = profession !== '' && professionalStartDate !== '';

  const onSubmit = async () => {
    setIsLoading(true);
    if (isValid) {
      updateData({ profession, professionalStartDate });

      auth
        .updatePractitioner({
          id: auth.currentPractitioner.id,
          professions: [profession],
          professionalStartDate: professionalStartDate,
        })
        .then((response) => {
          if (!response.hasError) {
            onNext();
          } else {
            toast.error(response.message);
          }
        });
    }
  };

  const goBack = async () => {
    await onPrevious();
  };

  return (
    <div className={wizardContainerStyle}>
      <div className='flex flex-col w-full items-center text-left'>
        <div className='max-w-xl text-center'>
          <Heading className='text-dark-blue mb-8' as='h3'>
            Professional Information
          </Heading>
        </div>

        <div className='mb-8 w-full'>
          <Heading className='text-dark-blue' as='h4'>
            Select your main profession
          </Heading>
          <Text className='italic mb-4'>
            This is a list of approved therapists from the EAPAA standards.
          </Text>
          <Select
            className='mb-4'
            classNamePrefix='react-select'
            name='profession'
            options={ProfessionOptions}
            value={ProfessionOptions.filter((o: any) => profession === o.value)}
            onChange={(o) => {
              setProfession(o.value);
            }}
          />
          {profession === 'Other Specialist Provider' && (
            <Text className='text-red'>
              Please note, you may not show up to some of our EAP clients given your profession is
              not approved by EAPAA.
            </Text>
          )}
        </div>

        <div className='mb-12 w-full'>
          <Heading className='text-dark-blue' as='h4'>
            When did you become fully qualified in your profession?
          </Heading>
          <Text className='mb-4 italic'>
            Please note, we're asking you this question again so it can be saved in your personal
            profile.
          </Text>
          <DOBField value={professionalStartDate} onChange={setProfessionalStartDate} />
        </div>

        <FooterNav className='md:mt-16 xs:mt-8'>
          <div className='flex justify-start'>
            <Button variant='primary' modifiers={'inverted'} onClick={goBack} disabled={isLoading}>
              Back
            </Button>
          </div>
          <div className='flex justify-end'>
            <Button
              variant='primary'
              onClick={onSubmit}
              data-test-id='complete-hear-about-us'
              disabled={!isValid}
            >
              Next
            </Button>
          </div>
        </FooterNav>
      </div>
    </div>
  );
};

export default withRouter(inject('auth')(observer(ProfessionalInfoStep)));
