import { flow, types, getEnv } from 'mobx-state-tree';
import moment from 'moment';
import 'moment-timezone';

import BookingService from 'services/BookingService';
import { Location } from '../../locations/models';
import { tagManagerEvent } from '../../../utilities/analytics';

const BookingContact = types
  .model('BookingContact', {
    id: types.identifierNumber,
    dateOfBirth: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    ethnicity: types.optional(types.string, ''),
    firstName: types.string,
    lastName: types.string,
    gender: types.optional(types.string, ''),
    nextSession: types.optional(types.string, ''),
    previousSession: types.optional(types.string, ''),
    phoneNumber: types.optional(types.string, ''),
    timezone: types.optional(types.string, ''),
    country: types.optional(types.string, ''),
    countryCode: types.maybeNull(types.string),
    eligibleForTherapy: types.optional(types.boolean, false),
    eligibilityVerified: types.optional(types.boolean, false),
    tenant: types.maybeNull(types.string),
    insuranceId: types.maybeNull(types.string),
    employeeNumber: types.maybeNull(types.string),
    maxAllowedSessions: types.maybeNull(types.number),
    remainingSessions: types.maybeNull(types.number),
    maxChargeSession: types.maybeNull(types.number),
  })
  .preProcessSnapshot((snap) => {
    if (snap) {
      return {
        ...snap,
        dateOfBirth: snap.dateOfBirth || undefined,
        email: snap.email || undefined,
        ethnicity: snap.ethnicity || undefined,
        gender: snap.gender || undefined,
        nextSession: snap.nextSession || undefined,
        previousSession: snap.previousSession || undefined,
        phoneNumber: snap.phoneNumber || undefined,
        timezone: snap.timezone || undefined,
        country: snap.country || undefined,
        eligibleForTherapy: snap.eligibleForTherapy || undefined,
        maxAllowedSessions: snap.maxAllowedSessions || undefined,
        maxChargeSession: snap.maxChargeSession || undefined,
      };
    }
  });

const BookingQuestion = types.model('BookingQuestion', {
  id: types.identifierNumber,
  order: types.integer,
  potentialAnswers: types.array(types.string),
  question: types.string,
  questionType: types.string,
  locked: types.maybeNull(types.boolean),
  questionVisibility: types.string,
});

const AnsweredQuestion = types.model('AnsweredQuestion', {
  question: types.string,
  answer: types.maybeNull(types.string),
});

const BookingForm = types.model('BookingForm', {
  headline: types.maybeNull(types.string),
  questions: types.array(BookingQuestion),
});

const Recurrence = types.model('Recurrence', {
  id: types.identifierNumber,
  completed: types.maybeNull(types.boolean), // There's no pseudo elements left in the reoccurence
  date: types.maybeNull(types.boolean), // Indicated whether the reoccursion appears on a specific day of the month
  endTime: types.maybeNull(types.string), // End time of appointment
  exclusions: types.maybeNull(types.array(types.string)), // List of dates of reccurances that are excluded from the recurrence
  frequency: types.maybeNull(types.number), // Number of unit to repeat by e.g. every two weeks
  generatedUntilDate: types.maybeNull(types.string), // The last date in the sequence between bookings and pseudo bookings
  numberOfOccurrences: types.maybeNull(types.number), // Number of bookings in the recurrence
  recurrenceStartDate: types.maybeNull(types.string), // Date of the first booking of the recurrence
  reoccurUntilDate: types.maybeNull(types.string), // End date of the last booking of the recurrence
  sequenceOffset: types.maybeNull(types.number), // Week offset from beginning of the month
  startTime: types.maybeNull(types.string), // Start time of appointment
  unit: types.maybeNull(types.string), // DAYS, WEEKS, MONTHS
  weekdays: types.maybeNull(types.string), // Days of the week for the reccurence
});

const PaymentOption = types.model('PaymentOption', {
  id: types.identifierNumber,
  name: types.string,
  sessionLength: types.maybeNull(types.string),
  bookingLength: types.maybeNull(types.string),
  cost: types.string,
  criteria: types.maybeNull(types.string),
  defaultPrice: types.boolean,
  description: types.maybeNull(types.string),
  hasCriteria: types.boolean,
});

export const Booking = types
  .model('Booking', {
    appointmentEndTimeUTC: types.maybeNull(types.string),
    appointmentTimeUTC: types.maybeNull(types.string),
    booked: types.maybeNull(types.boolean),
    bookingConfirmedOn: types.maybeNull(types.string),
    bookingRequestedOn: types.maybeNull(types.string),
    bookingFor: types.maybeNull(types.string),
    cancellationBy: types.maybeNull(types.string),
    cancellationReason: types.maybeNull(types.string),
    caseNumber: types.maybeNull(types.string),
    caseSessionNumber: types.maybeNull(types.integer),
    chargeId: types.maybeNull(types.number),
    clientTimezone: types.maybeNull(types.string),
    confirmed: types.maybeNull(types.boolean),
    contact: types.maybeNull(BookingContact),
    country: types.maybeNull(types.string),
    durationMin: types.maybeNull(types.integer),
    employeeNumber: types.maybeNull(types.string),
    expiresAt: types.maybeNull(types.string),
    form: types.maybeNull(BookingForm),
    groupId: types.maybeNull(types.number),
    id: types.identifierNumber,
    invoiceId: types.maybeNull(types.integer),
    invoiceStatus: types.maybeNull(types.string),
    partnerBooking: types.maybeNull(types.boolean),
    partnerOrganisation: types.maybeNull(types.string),
    paymentOptions: types.maybeNull(PaymentOption),
    recurringAppointment: types.maybeNull(Recurrence, {}),
    responses: types.maybeNull(types.array(AnsweredQuestion), []),
    serviceLocation: types.maybeNull(Location),
    source: types.maybeNull(types.string),
    status: types.maybeNull(types.string), // Historical, Pending, Confirmed, Rejected, Cancelled
    tenant: types.maybeNull(types.string),
    therapyForSomeoneElse: types.maybeNull(types.boolean),
    therapyFundedBy: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
  })
  .views((self) => ({
    get appointmentTime() {
      const { auth } = getEnv(self);

      return self.appointmentTimeUTC
        ? moment
            .utc(self.appointmentTimeUTC)
            .clone()
            .tz(
              auth.currentPractitioner.timezone
                ? auth.currentPractitioner.timezone
                : 'Pacific/Auckland'
            )
            .format('YYYY-MM-DDTHH:mm:ss')
        : null;
    },
    get appointmentEndTime() {
      const { auth } = getEnv(self);

      return self.appointmentEndTimeUTC
        ? moment
            .utc(self.appointmentEndTimeUTC)
            .clone()
            .tz(
              auth.currentPractitioner.timezone
                ? auth.currentPractitioner.timezone
                : 'Pacific/Auckland'
            )
            .format('YYYY-MM-DDTHH:mm:ss')
        : null;
    },
  }));

export const PseudoBooking = types
  .model('PseudoBooking', {
    appointmentEndTimeUTC: types.maybeNull(types.string),
    appointmentTimeUTC: types.maybeNull(types.string),
    booked: types.maybeNull(types.boolean),
    bookingConfirmedOn: types.maybeNull(types.string),
    bookingRequestedOn: types.maybeNull(types.string),
    chargeId: types.maybeNull(types.number),
    confirmed: types.boolean,
    contact: BookingContact,
    durationMin: types.integer,
    paymentOptions: types.maybeNull(PaymentOption),
    responses: types.maybeNull(types.array(AnsweredQuestion), []),
    form: types.maybeNull(BookingForm),
    serviceLocation: Location,
    source: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    recurringAppointment: types.maybeNull(Recurrence, {}),
  })
  .views((self) => ({
    get appointmentTime() {
      const { auth } = getEnv(self);

      return moment
        .utc(self.appointmentTimeUTC)
        .clone()
        .tz(
          auth.currentPractitioner.timezone ? auth.currentPractitioner.timezone : 'Pacific/Auckland'
        )
        .format('YYYY-MM-DDTHH:mm:ss');
    },
    get appointmentEndTime() {
      const { auth } = getEnv(self);

      return moment
        .utc(self.appointmentEndTimeUTC)
        .clone()
        .tz(
          auth.currentPractitioner.timezone ? auth.currentPractitioner.timezone : 'Pacific/Auckland'
        )
        .format('YYYY-MM-DDTHH:mm:ss');
    },
  }));

const Bookings = types
  .model('Bookings', {
    confirmedBookings: types.array(Booking),
    pendingBookings: types.array(Booking),
    pendingConfirmationBookings: types.array(Booking),
    historicalBookings: types.array(Booking),
    pendingManualBookings: types.array(Booking),

    lastConfirmed: types.maybe(types.boolean, true),
    lastPending: types.maybe(types.boolean, true),
    lastPendingConfirmation: types.maybe(types.boolean, true),
    lastHistorical: types.maybe(types.boolean, true),

    totalConfirmed: types.maybeNull(types.integer, 0),
    totalPending: types.maybeNull(types.integer, 0),
    totalPendingManual: types.maybeNull(types.integer, 0),
    totalPendingConfirmation: types.maybeNull(types.integer, 0),
    totalHistorical: types.maybeNull(types.integer, 0),

    totalConfirmedPages: types.maybeNull(types.integer, 0),
    totalPendingPages: types.maybeNull(types.integer, 0),
    totalPendingConfirmationPages: types.maybeNull(types.integer, 0),
    totalHistoricalPages: types.maybeNull(types.integer, 0),

    fetchedBooking: types.maybeNull(Booking, {}),

    pseudoBookingMonth: types.maybeNull(types.string),
    monthlyPseudoBookings: types.maybeNull(types.array(PseudoBooking, [])),
  })
  .views((self) => ({}))
  .actions((self) => ({
    resetAndFetch: () => {
      self.confirmedBookings = [];
      self.pendingBookings = [];
      self.historicalBookings = [];
      self.groupBookings = [];

      self.lastConfirmed = true;
      self.lastPending = true;
      self.lastHistorical = true;

      self.totalPendingManual = 0;
      self.totalConfirmed = 0;
      self.totalPending = 0;
      self.totalPendingConfirmation = 0;
      self.totalHistorical = 0;

      self.totalConfirmedPages = 0;
      self.totalPendingPages = 0;
      self.totalPendingConfirmationPages = 0;
      self.totalHistoricalPages = 0;

      self.fetchedBooking = null;

      const data = { pageOffset: 0, pageSize: 20 };
      self.getAllBookings(data);
    },
    getAllBookings: flow(function* (data) {
      yield Promise.all([
        self.getConfirmedBookings(data),
        self.getPendingBookings(data),
        self.getPendingConfirmationBookings(data),
        self.getHistoricalBookings(data),
        self.getPendingManualBookings(data),
      ]);
    }),
    getConfirmedBookings: flow(function* (data) {
      const res = yield BookingService.getConfirmedBookings(data);
      if (!res.hasError) {
        let { content, last, totalElements, totalPages } = res.data;

        if (data.pageOffset === 0) {
          self.confirmedBookings = content;
        } else {
          self.confirmedBookings = self.confirmedBookings.concat(content);
        }

        self.lastConfirmed = last;
        self.totalConfirmed = totalElements;
        self.totalConfirmedPages = totalPages;
      }
      return res;
    }),
    getPendingBookings: flow(function* (data) {
      const res = yield BookingService.getPendingBookings(data);
      if (!res.hasError) {
        let { content, last, totalElements, totalPages } = res.data;

        if (data.pageOffset === 0) {
          self.pendingBookings = content;
        } else {
          self.pendingBookings = self.pendingBookings.concat(content);
        }

        self.lastPending = last;
        self.totalPending = totalElements;
        self.totalPendingPages = totalPages;
      }
      return res;
    }),
    getPendingManualBookings: flow(function* (data) {
      const res = yield BookingService.getPendingManualBookings(data);
      if (!res.hasError) {
        let { content, totalElements } = res.data;

        if (data.pageOffset === 0) {
          self.pendingManualBookings = content;
        } else {
          self.pendingManualBookings = self.pendingBookings.concat(content);
        }
        self.totalPendingManual = totalElements;
      }
      return res;
    }),
    getPendingConfirmationBookings: flow(function* (data) {
      const res = yield BookingService.getPendingCompletionBookings(data);
      if (!res.hasError) {
        let { content, last, totalElements, totalPages } = res.data;

        if (data.pageOffset === 0) {
          self.pendingConfirmationBookings = content;
        } else {
          self.pendingConfirmationBookings = self.pendingConfirmationBookings.concat(content);
        }

        self.lastPendingConfirmation = last;
        self.totalPendingConfirmation = totalElements;
        self.totalPendingConfirmationPages = totalPages;
      }
      return res;
    }),
    getHistoricalBookings: flow(function* (data) {
      const res = yield BookingService.getHistoricalBookings(data);
      if (!res.hasError) {
        let { content, last, totalElements, totalPages } = res.data;
        if (data.pageOffset === 0) {
          self.historicalBookings = content;
        } else {
          self.historicalBookings = self.historicalBookings.concat(content);
        }

        self.lastHistorical = last;
        self.totalHistorical = totalElements;
        self.totalHistoricalPages = totalPages;
      }
      return res;
    }),
    getBookingData: flow(function* (id) {
      const res = yield BookingService.getBooking(id);
      self.fetchedBooking = res.data;
      return res.data;
    }),
    getManualBookingData: flow(function* (id) {
      const res = yield BookingService.getManualBooking(id);
      self.fetchedBooking = res.data;
      return res.data;
    }),
    acceptBooking: flow(function* (data) {
      const res = yield BookingService.acceptBooking({
        appointmentId: data.id,
      });
      if (!res.hasError) {
        const confirmedAppt = res.data.appointment;
        confirmedAppt.status = 'Confirmed';
        self.confirmedBookings.push(confirmedAppt);
        self.totalConfirmed += 1;
        self.totalPending -= 1;
        self.pendingBookings = self.pendingBookings.filter((pending) => pending.id !== data.id);

        if (data.type && data.type === 'Group') {
          tagManagerEvent('Group Appointment Approved');
        } else {
          tagManagerEvent('Appointment Approved');
        }
      }
      return res;
    }),
    rejectBooking: flow(function* (data) {
      const res = yield BookingService.rejectBooking(data);
      if (!res.hasError) {
        self.confirmedBookings = self.confirmedBookings.filter(
          (confirmed) => confirmed.id !== data.appointmentId
        );
        self.pendingBookings = self.pendingBookings.filter(
          (pending) => pending.id !== data.appointmentId
        );

        if (data.type && data.type === 'Group') {
          tagManagerEvent('Group Appointment Rejected');
        } else {
          tagManagerEvent('Appointment Rejected');
        }
      }
      return res;
    }),
    rejectManualBooking: flow(function* (data) {
      const res = yield BookingService.rejectManualBooking(data);
      if (!res.hasError) {
        self.pendingManualBookings = self.pendingManualBookings.filter(
          (pending) => pending.id !== data.appointmentId
        );
        self.totalPendingManual -= 1;
        tagManagerEvent('Manual Booking Rejected');
      }
      return res;
    }),

    pushConfirmedBooking: function (booking) {
      booking.status = 'Confirmed';
      self.confirmedBookings.push(booking);
      self.totalConfirmed += 1;
    },
    concatConfirmedBookings: function (bookingArr) {
      bookingArr.forEach((booking) => (booking.status = 'Confirmed'));
      self.confirmedBookings = self.confirmedBookings.concat(bookingArr);
      self.totalConfirmed += bookingArr.length;
    },
    pushPendingBooking: function (booking) {
      booking.status = 'Pending';
      self.pendingBookings.push(booking);
      self.totalPending += 1;
    },
    removeConfirmedBooking: function (bookingId) {
      self.confirmedBookings = self.confirmedBookings.filter((booking) => booking.id !== bookingId);
      self.totalConfirmed -= 1;
    },
    removePendingBooking: function (bookingId) {
      self.pendingBookings = self.pendingBookings.filter((pending) => pending.id !== bookingId);
      self.totalPending -= 1;
    },
    sortConfirmedDescending: function () {
      self.confirmedBookings.replace(
        self.confirmedBookings
          .slice()
          .sort((a, b) => a.appointmentTime.localeCompare(b.appointmentTime))
      );
    },
    sortPendingDescending: function () {
      self.pendingBookings.replace(
        self.pendingBookings
          .slice()
          .sort((a, b) => a.appointmentTime.localeCompare(b.appointmentTime))
      );
    },
    sortHistoricalAscending: function () {
      self.historicalBookings.replace(
        self.historicalBookings
          .slice()
          .sort((a, b) => b.appointmentTime.localeCompare(a.appointmentTime))
      );
    },
    getMonthlyPseudoBookings: flow(function* (date) {
      const startMonth = moment(date).startOf('month').add(-1, 'W');
      const endMonth = moment(date).endOf('month').add(1, 'W');

      const startDate = startMonth.format('YYYY-MM-DD');
      const endDate = endMonth.format('YYYY-MM-DD');

      const res = yield BookingService.getPseudoBookings({
        startDate,
        endDate,
      });
      if (!res.hasError) {
        self.monthlyPseudoBookings = res.data;
      }
      return res;
    }),
    editReoccuringBooking: flow(function* (recurrence) {
      const res = yield BookingService.editReoccuringBooking(recurrence);
      if (!res.hasError) {
        self.resetAndFetch();
      }
      return res;
    }),
    rejectReoccuringBooking: flow(function* (booking) {
      const res = yield BookingService.rejectReoccuringBooking(booking);
      if (!res.hasError) {
        if (booking.id) {
          self.confirmedBookings.remove(booking);
        } else {
          self.monthlyPseudoBookings.remove(booking);
        }
      }
      return res;
    }),
    rejectReoccuringOnwards: flow(function* (booking) {
      const res = yield BookingService.rejectReoccuringOnwards(booking);
      if (!res.hasError) {
        self.resetAndFetch();
      }
      return res;
    }),
    rejectAllReoccuring: flow(function* (booking) {
      const res = yield BookingService.rejectAllReoccuring(booking);
      if (!res.hasError) {
        self.resetAndFetch();
      }
      return res;
    }),
  }));

export default Bookings;
