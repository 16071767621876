import React, { Component } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import ChLogoSvg from 'jsx:assets/svg/ch-logo.svg';

export const Container = styled.span`
	position: relative;
	display: inline-block;
	width: min-content;
	height: min-content;
`;

const ChLogoIcon = styled(ChLogoSvg)`
	height: 24px;
	fill: var(--blue);
`;

class ClearheadHoverLogo extends Component {
	render() {
		const { key = '' } = this.props;

		return (
			<Container data-for={`help-circle_${key}`} data-tip={'This user has a Clearhead account'}>
				<ChLogoIcon />
				<ReactTooltip id={`help-circle_${key}`} data-offset={'bottom'} place={'bottom'} effect='solid' border />
			</Container>
		);
	}
}

export default ClearheadHoverLogo;
