import React, { Component } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak-fork/web';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { BlockLoading } from '../Loading';

import keycloak from '../../configuration/keycloak';

@inject('auth')
@observer
class AuthProvider extends Component {
  updateTokens = async (tokens) => {
    console.log('Update tokens');
    const success = await this.props.auth.login(tokens);
    if (success === false) {
      toast.error('Please sign up as a provider.');
    }
  };

  onEvent = (eventType) => {
    let isReady = false;
    switch (eventType) {
      case 'onReady':
      case 'onAuthRefreshSuccess':
      case 'onAuthSuccess':
        isReady = true;
        break;
      default:
        isReady = false;
    }
    console.log(isReady);
    if (isReady) {
      // let store know that the user is authenticated
    }
  };

  render() {
    return (
      <ReactKeycloakProvider
        enableLogging
        initOptions={{
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
        }}
        onTokens={this.updateTokens}
        authClient={keycloak}
        LoadingComponent={<BlockLoading />}
      >
        {this.props.children}
      </ReactKeycloakProvider>
    );
  }
}

export default AuthProvider;
