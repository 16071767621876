import { flow, types } from 'mobx-state-tree';

import CalendarSyncService from 'services/CalendarSyncService';
import { tagManagerEvent } from '../../../utilities/analytics';

const SyncedCalendar = types.model('SyncedCalendar', {
  providerName: types.string,
  profileName: types.string,
  profileId: types.string,

  enabled: types.boolean,
  calendarName: types.string,
  calendarId: types.string,
  publishEventsTo: types.boolean,
  copyDetails: types.boolean,
});

const CalendarSync = types
  .model('CalendarSync', {
    syncedCalendars: types.maybeNull(types.array(SyncedCalendar)),
  })
  .actions((self) => ({
    getCalendarSyncToken: flow(function* () {
      const response = yield CalendarSyncService.getCalendarSyncToken();
      if (!response.hasError) {
      }
      return false;
    }),
    getCalendars: flow(function* () {
      const response = yield CalendarSyncService.getSyncedCalendars();
      if (!response.hasError) {
        self.syncedCalendars = response.data;
      }
    }),
    saveSyncedCalendars: flow(function* () {
      const response = yield CalendarSyncService.saveSyncedCalendars(
        self.syncedCalendars
      );
      if (!response.hasError) {
        self.syncedCalendars = response.data;
      }
    }),
    connectToCalendar: flow(function* (data) {
      const res = yield CalendarSyncService.connectToCalendar(data);

      if (!res.hasError) {
        tagManagerEvent('Practitioner Connected Google Calendar');
      }
      return res;
    }),
    changeTimeZone: function (calendarId, newValue) {
      console.log('CTZ:', newValue);
      const syncedCalendar = self.syncedCalendars.find(
        (a) => a.calendarId === calendarId
      );
      if (syncedCalendar) {
        // syncedCalendar.tim
      }
    },
    toggleComponentEnabled: function (calendarId) {
      const syncedCalendar = self.syncedCalendars.find(
        (a) => a.calendarId === calendarId
      );
      if (syncedCalendar) {
        syncedCalendar.enabled = !syncedCalendar.enabled;
      }
    },
    toggleCopyDetails: function (calendarId) {
      const syncedCalendar = self.syncedCalendars.find(
        (a) => a.calendarId === calendarId
      );
      if (syncedCalendar) {
        syncedCalendar.copyDetails = !syncedCalendar.copyDetails;
      }
      console.log('compelted fine');
    },
    togglePublishTo: function (calendarId) {
      const syncedCalendar = self.syncedCalendars.find(
        (a) => a.calendarId === calendarId
      );
      self.syncedCalendars
        .filter((a) => a.publishEventsTo)
        .forEach((a) => (a.publishEventsTo = false));
      if (syncedCalendar) {
        syncedCalendar.publishEventsTo = !syncedCalendar.publishEventsTo;
      }
    },
  }));

export default CalendarSync;
