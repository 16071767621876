import { SVGProps } from 'react';
const ExclamationCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 16 16' {...props}>
    <circle cx={8.378} cy={8.122} r={7.622} />
    <path
      fill='#fff'
      d='M9.207 11.439a.83.83 0 1 1-1.658 0 .83.83 0 0 1 1.658 0Zm0-3.317a.83.83 0 0 1-1.658 0V4.806a.83.83 0 1 1 1.658 0v3.316Z'
    />
  </svg>
);
export default ExclamationCircle;
