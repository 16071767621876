import React from 'react';
import styled from 'styled-components';

const ContentContainer = styled.div`
  padding: 1rem;
`;

const Content = ({ children }) => (
  <ContentContainer>{children}</ContentContainer>
);

export default Content;
