import AppState from './app/models';
import Auth from './auth/models/auth';
import Locations from './locations/models';
import Prices from './prices/models';
import Clients from './clients/models';
import Schedule from './schedule/models';
import Invoices from './invoices/models';
import Bookings from './bookings/models';
import Calendar from './calendar/models';
import Practitioners from './practitioners/models';
import Screening from './screening/models';
import Integrations from './integrations/models';
import CalendarSync from './calendarsync/models';

let initialAuthState = {};

// if (localStorage.getItem(STORAGE_KEY)) {
// 	initialAuthState = JSON.parse(localStorage.getItem(STORAGE_KEY));
// 	if(initialAuthState && initialAuthState.currentUser && initialAuthState.currentUser.userId){
// 		initialAuthState.currentUser.userId = `${initialAuthState.currentUser.userId}`
// 	}
// }

const app = AppState.create();
const auth = Auth.create(initialAuthState, { app });
const bookings = Bookings.create({}, { app, auth });
const calendar = Calendar.create({}, { app, auth, bookings });
const clients = Clients.create({}, { app, auth });
const locations = Locations.create({}, { app, auth });
const prices = Prices.create({}, { app, auth });
const invoices = Invoices.create({}, { app, auth });
const schedule = Schedule.create({}, { app, auth });
const practitioners = Practitioners.create({}, { app, auth });
const screening = Screening.create({}, { app, auth });
const integrations = Integrations.create({}, { app, auth });
const calendarSync = CalendarSync.create({}, { app, auth });

const store = {
  app,
  auth,
  bookings,
  calendar,
  clients,
  invoices,
  locations,
  prices,
  schedule,
  practitioners,
  screening,
  integrations,
  calendarSync,
};

// onSnapshot(auth, async snapshot => {
// 	localStorage.setItem(STORAGE_KEY, JSON.stringify(snapshot));
// });

// onPatch(auth, patch => {
// 	if (patch.path.startsWith('/isAuthenticated') && !patch.value) {
// 		bookings.resetPatch();
// 		calendar.resetPatch();
// 	}
// });

export default store;
