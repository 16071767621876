import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { tagManagerEvent } from '../../../utilities/analytics';

const TherapyLink = ({ link }) => {
  const [copied, setCopy] = useState(false);

  const setTimeoutCopy = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 2000);

    tagManagerEvent('Telehealth Link Copied');
  };

  return (
    <CopyToClipboard
      onCopy={() => (!copied ? setTimeoutCopy() : console.log('copy blocked'))}
      text={link}
    >
      {copied ? (
        <Button className="mb-2" size="sm" color="success">
          Copied
        </Button>
      ) : (
        <Button
          style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
          className="mb-2"
          color="success"
          size="sm"
        >
          Click to copy to clipboard
        </Button>
      )}
    </CopyToClipboard>
  );
};

export default TherapyLink;
