import * as React from 'react';
import { observer, inject } from 'mobx-react';
import BookingCardSummary from './BookingCardSummary';
import BookingCardFooter from './BookingCardFooter';
import BookingCardActions from './BookingCardActions';

const BookingCard = inject(
  'auth',
  'bookings',
  'calendar'
)(
  observer((props: any) => {
    const [isHovering, setIsHovering] = React.useState<boolean>(false);
    const {
      booking: { contact, partnerBooking },
      expandBooking,
    } = props;

    return (
      <div
        className='w-full shadow-md border-top rounded-md mb-4 bg-white border-gray-200 border-solid border-2 cursor-pointer hover:border-blue-300'
        onClick={expandBooking}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className='flex flex-col sm:flex-row justify-between px-2 pt-3 pb-3'>
          <BookingCardSummary {...props} isHovering={isHovering} />
          <div onClick={(e) => e.stopPropagation()} className='cursor-default'>
            <BookingCardActions {...props} />
          </div>
        </div>
        <BookingCardFooter partnerBooking={partnerBooking} contact={contact} />
      </div>
    );
  })
);

export default BookingCard;
