import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Form, FormGroup, FormFeedback, Label } from 'reactstrap';
import Select from 'react-select';

// https://github.com/country-regions/country-region-data
import { allCountries } from 'country-region-data'; // Used to get regions in countries
// https://github.com/michaelwittig/node-i18n-iso-countries
import { getNames, registerLocale } from 'i18n-iso-countries'; // Used to get country 2 and 3 letter codes

const schema = Yup.object().shape({
  country: Yup.string().required('Required'),
  region: Yup.string().required('Required'),
});

const CountryForm = ({ formState, onComplete, onBack = undefined }) => {
  const [countryOptions, setCountryOptions] = React.useState();
  const [regionOptions, setRegionOptions] = React.useState();

  React.useEffect(() => {
    registerLocale(require('i18n-iso-countries/langs/en.json'));
    const countryOptions = createCountryOptions();
    setCountryOptions(countryOptions);

    if (formState.country && formState.region) {
      setRegionOptions(createRegionOptions(formState.country));
    }
  }, []);

  /** Create list of country option to populate dropdown */
  const createCountryOptions = () => {
    const countries = getNames('en');
    const countryMappedOptions = [];
    for (const [key, value] of Object.entries(countries)) {
      countryMappedOptions.push({ label: value, value: key });
    }

    return countryMappedOptions.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  };

  /** Create list of region option to populate dropdown */
  const createRegionOptions = (countryCode) => {
    // Fetches the country object which contains regions
    const country = allCountries.find((c) => {
      return c[1] === countryCode;
    });

    // Creates the region options
    if (country) {
      const regionMappedOptions = country[2].map((r) => {
        return { label: r[0], value: r[1] };
      });

      return regionMappedOptions;
    }
  };

  return (
    <Formik
      initialValues={formState}
      validationSchema={schema}
      onSubmit={(values, actions) => {
        onComplete(values);
      }}
    >
      {({ values, errors, touched, handleSubmit, isSubmitting, setFieldValue, setTouched }) => {
        /** Set selected country and create list of region options */
        const onCountrySelect = (option) => {
          setFieldValue('country', option.value);
          setFieldValue('region', null);
          // Set the region options from the selected country
          setRegionOptions(createRegionOptions(option.value));
        };

        const isValid = () => {
          return values.region && values.country;
        };

        return (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label>Country</Label>
              <Select
                classNamePrefix='react-select'
                name='country'
                options={countryOptions}
                value={countryOptions?.find((c) => c.value === values.country)}
                onBlur={() => setTouched({ ...touched, country: true })}
                onChange={(option) => onCountrySelect(option)}
              />
              {errors.country && <FormFeedback>{errors.country}</FormFeedback>}
            </FormGroup>

            <FormGroup>
              <Label>Region</Label>
              <Select
                classNamePrefix='react-select'
                name='region'
                options={regionOptions}
                value={regionOptions?.find((c) => c.value === values.region)}
                onBlur={() => setTouched({ ...touched, region: true })}
                onChange={(option) => setFieldValue('region', option.value)}
              />
              {errors.region && <FormFeedback>{errors.region}</FormFeedback>}
            </FormGroup>

            <div className='text-center mt-3'>
              {onBack && (
                <Button
                  color='primary'
                  size='lg'
                  className='mr-2'
                  onClick={onBack}
                  disabled={isSubmitting}
                >
                  Back
                </Button>
              )}
              <Button color='primary' size='lg' type='submit' disabled={isSubmitting || !isValid()}>
                Next
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CountryForm;
