import * as React from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';

import CircleButton from './CircleButton';
import InvoiceLink from '../../../Invoice/InvoiceLink';

const BookingCardActions = (props: any) => {
  const { type, openRejectModal, onEdit } = props;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const acceptBooking = () => {
    const {
      booking,
      bookings: { acceptBooking },
      onAccept,
    } = props;

    setIsLoading(true);

    acceptBooking(booking).then((res: any) => {
      if (!res.hasError) {
        onAccept();
        toast.success('Booking accepted');
      }
      setIsLoading(false);
    });
  };

  if (type.toLowerCase() === 'pending') {
    return (
      <div className='d-flex justify-content-end sm:justify-start mt-4 sm:mt-0'>
        <div className='d-flex flex-row flex-row-reverse sm:flex-col'>
          <div className='d-flex justify-content-around mb-0 sm:mb-2'>
            <CircleButton isAccept={false} onClick={openRejectModal} className='mr-4' />
            <CircleButton isAccept={true} onClick={acceptBooking} />
          </div>
          <div className='w-full flex justify-center mr-4 sm:mr-4'>
            <button onClick={onEdit} disabled={isLoading}>
              <Text className='text-primary-blue-100 font-bold cursor-pointer text-sm hover:underline'>
                Reschedule
              </Text>
            </button>
          </div>
        </div>
      </div>
    );
  } else if (type.toLowerCase() === 'manual') {
    return (
      <div className='d-flex justify-content-end mt-2 sm:mt-0'>
        <CircleButton isAccept={false} onClick={openRejectModal} className='mr-4' />
        <CircleButton isAccept={true} onClick={onEdit} />
      </div>
    );
  } else if (type.toLowerCase() === 'confirmed') {
    return (
      <div className='flex justify-content-end sm:justify-start mt-2 sm:justify-none sm:mt-0'>
        <Button
          color='primary'
          size='md'
          style={{ height: '30px', width: '120px' }}
          onClick={onEdit}
          disabled={isLoading}
          className='rounded-full font-bold h-8'
        >
          Reschedule
        </Button>
      </div>
    );
  } else if (type.toLowerCase() === 'historical') {
    return (
      <div className='flex sm:flex-col flex-row-reverse justify-content-start w-full sm:w-auto sm:justify-start sm:justify-none mt-2 sm:mt-0 items-center'>
        <Button
          color='primary'
          size='md'
          style={{ height: '30px', width: '120px' }}
          onClick={props.onRebook}
          disabled={isLoading}
          className='rounded-full font-bold h-8 ml-auto sm:mt-0'
        >
          Re-book
        </Button>
        <InvoiceLink
          isLoading={isLoading}
          props={props}
          className='sm:mt-4 mr-3 ml-auto sm:text-right w-1/2 sm:w-auto'
        />
      </div>
    );
  }

  return null;
};

export default BookingCardActions;
