import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import moment from 'moment';
import { observer, inject } from 'mobx-react';

import AppointmentForm from 'modules/calendar/components/AppointmentForm';
import BlockForm from 'modules/calendar/components/BlockForm';

@inject('locations', 'prices', 'bookings')
@observer
class EditBookingModal extends Component {
  constructor(props) {
    super(props);
    Promise.all([props.locations.getLocations(), props.prices.getPrices()]);
  }

  onAppointmentUpdate = (data) => {
    const { bookings, toggleModal } = this.props;

    if (data.isRepeating) {
      bookings.getAllBookings({ pageOffset: 0, pageSize: 100 });
      bookings.getMonthlyPseudoBookings(moment(data.appointmentStartDateTime).toDate());
    } else {
      bookings.getPendingManualBookings({ pageOffset: 0, pageSize: 100 });
      bookings.getConfirmedBookings({ pageOffset: 0 });
    }
    toggleModal();
  };

  render() {
    const { isModalOpen, toggleModal, locations, prices, booking = {}, mode = 'edit' } = this.props;
    const {
      id,
      appointmentTime,
      appointmentEndTime,
      contact = {},
      serviceLocation = {},
      chargeId,
      blockageTime,
      status,
      blockageEndTime,
    } = booking;

    const selectedSavedLocation = locations.allLocations.find(
      (savedLocation) => savedLocation.location.id === serviceLocation.id
    );
    const selectedPrice = prices.allPrices.find((price) => price.id === chargeId);

    // Set up the default state that should be there no matter what
    let formState = {
      ...booking,
      locationId: serviceLocation.id,
      location: selectedSavedLocation && selectedSavedLocation.nickname,
      contact,
      price:
        selectedPrice &&
        `${selectedPrice.name} - ${selectedPrice.sessionLength}min - $${selectedPrice.cost}`,
      chargeId: chargeId,
      sessionLength: selectedPrice && selectedPrice.sessionLength,
    };
    // If we're converting a block or editing a block, set up block state
    if (mode === 'convert' || !!blockageTime) {
      formState.appointmentStartDateTime = moment(blockageTime).format('YYYY-MM-DD');
      const startMoment = moment(blockageTime).second(0);
      const endMoment = moment(blockageEndTime).second(0);
      formState.startDate = startMoment.format('YYYY-MM-DD');
      formState.endDate = endMoment.format('YYYY-MM-DD');
      formState.startTime = startMoment;
      formState.endTime = endMoment;
      formState.startTimeOption = {
        label: startMoment.format('h:mma'),
        value: startMoment,
      };
      formState.endTimeOption = {
        label: endMoment.format('h:mma'),
        value: endMoment,
      };
      formState.blockId = id;
    } else if (mode === 'edit') {
      if (appointmentTime) {
        formState.appointmentStartDateTime = moment(appointmentTime).format('YYYY-MM-DD');
        const startMoment = moment(appointmentTime).second(0);
        const endMoment = moment(appointmentEndTime).second(0);
        formState.startTime = startMoment;
        formState.endTime = endMoment;
        formState.startTimeOption = {
          label: startMoment.format('h:mma'),
          value: startMoment,
        };
        formState.endTimeOption = {
          label: endMoment.format('h:mma'),
          value: endMoment,
        };
      }
    }

    return (
      <Modal isOpen={isModalOpen} toggle={toggleModal} size='md' centered>
        <ModalHeader className='bg-light' toggle={toggleModal}>
          {mode === 'edit'
            ? !!blockageTime
              ? 'Edit Block'
              : status === 'Manual'
              ? 'Approve Appointment'
              : 'Edit Appointment'
            : 'Convert Block To Appointment'}
        </ModalHeader>
        <ModalBody className='p-3'>
          {mode === 'edit' && !!blockageTime ? (
            <BlockForm formState={formState} onComplete={toggleModal} mode='edit' />
          ) : (
            <AppointmentForm
              formState={formState}
              onComplete={this.onAppointmentUpdate}
              mode={mode}
              status={status}
            />
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default EditBookingModal;
