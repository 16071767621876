import BaseService from '../BaseService';
import { BASE_API_URL } from 'configuration';
import moment from 'moment';

class BookingService {
  static getConfirmedBookings = ({ pageOffset, searchQuery, pageSize = 10 }) => {
    let suffix = '';
    if (searchQuery) {
      suffix = `&searchTerm=${searchQuery}`;
    }

    return BaseService.get(
      `${BASE_API_URL}/practitioners/me/appointments?status=Confirmed&pageOffset=${pageOffset}&pageSize=${pageSize}${suffix}`
    );
  };
  static getPendingBookings = ({ pageOffset, searchQuery, pageSize = 10 }) => {
    let suffix = '';
    if (searchQuery) {
      suffix = `&searchTerm=${searchQuery}`;
    }

    return BaseService.get(
      `${BASE_API_URL}/practitioners/me/appointments?status=Pending&pageOffset=${pageOffset}&pageSize=${pageSize}${suffix}`
    );
  };
  static getPendingCompletionBookings = ({ pageOffset, searchQuery, pageSize = 10 }) => {
    let suffix = '';
    if (searchQuery) {
      suffix = `&searchTerm=${searchQuery}`;
    }

    return BaseService.get(
      `${BASE_API_URL}/practitioners/me/appointments?status=PendingCompletion&pageOffset=${pageOffset}&pageSize=${pageSize}${suffix}`
    );
  };
  static getPendingManualBookings = ({ pageOffset, searchQuery, pageSize = 10 }) => {
    let suffix = '';
    if (searchQuery) {
      suffix = `&searchTerm=${searchQuery}`;
    }

    return BaseService.get(
      `${BASE_API_URL}/practitioners/me/manual-bookings?pageOffset=${pageOffset}&pageSize=${pageSize}${suffix}`
    );
  };
  static getHistoricalBookings = ({ pageOffset, searchQuery, pageSize = 10 }) => {
    let suffix = '';
    if (searchQuery) {
      suffix = `&searchTerm=${searchQuery}`;
    }
    return BaseService.get(
      `${BASE_API_URL}/practitioners/me/appointments?statuses=Completed%2CCancelled%2CDidNotAttend&pageOffset=${pageOffset}&pageSize=${pageSize}${suffix}`
    );
  };
  static getBooking = (id) => {
    return BaseService.get(`${BASE_API_URL}/practitioners/me/appointments/${id}`);
  };
  static getManualBooking = (id) => {
    return BaseService.get(`${BASE_API_URL}/practitioners/me/manual-bookings/${id}`);
  };

  static acceptBooking = (data) => {
    return BaseService.post(
      `${BASE_API_URL}/practitioners/me/appointments/${data.appointmentId}/approve`,
      data
    );
  };
  static rejectBooking = (data) => {
    return BaseService.post(
      `${BASE_API_URL}/practitioners/me/appointments/${data.appointmentId}/reject`,
      data
    );
  };

  static rejectManualBooking = (data) => {
    return BaseService.delete(
      `${BASE_API_URL}/practitioners/me/manual-bookings/${data.appointmentId}`,
      data
    );
  };

  static getPseudoBookings = ({ startDate, endDate }) => {
    return BaseService.get(
      `${BASE_API_URL}/practitioners/me/recurrences/sessions?fromDate=${startDate}&toDate=${endDate}`
    );
  };
  static editReoccuringBooking = (recurrence) => {
    return BaseService.put(
      `${BASE_API_URL}/practitioners/me/recurrences/${recurrence.id}`,
      recurrence
    );
  };
  static rejectReoccuringBooking = ({ recurringAppointment, appointmentTime }) => {
    const dateStr = moment(appointmentTime).format('YYYY-MM-DD');
    return BaseService.delete(
      `${BASE_API_URL}/practitioners/me/recurrences/${recurringAppointment.id}/${dateStr}`
    );
  };
  static rejectReoccuringOnwards = ({ recurringAppointment, appointmentTime }) => {
    const dateStr = moment(appointmentTime).format('YYYY-MM-DD');
    return BaseService.delete(
      `${BASE_API_URL}/practitioners/me/recurrences/${recurringAppointment.id}?fromDate=${dateStr}`
    );
  };
  static rejectAllReoccuring = ({ recurringAppointment }) => {
    return BaseService.delete(
      `${BASE_API_URL}/practitioners/me/recurrences/${recurringAppointment.id}`
    );
  };
}

export default BookingService;
