import { SVGProps } from 'react';
const ArrowLargeRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 25 24' {...props}>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M7.458 12h10.084M12 6l6 6-6 6'
    />
  </svg>
);
export default ArrowLargeRight;
