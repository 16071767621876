import React from 'react';
import ToggleButtonPrimary from './ToggleButtonPrimary';
import ToggleButtonSecondary from './ToggleButtonSecondary';

export type ToggleButtonProps = {
  className?: string;
  onClick?: () => void;
  active?: boolean;
  title: string | JSX.Element;
};

const ToggleButton: {
  Primary: React.FC<ToggleButtonProps>;
  Secondary: React.FC<ToggleButtonProps>;
} = {
  Primary: ToggleButtonPrimary,
  Secondary: ToggleButtonSecondary,
};

export default ToggleButton;
