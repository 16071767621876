import { flow, types } from 'mobx-state-tree';
import { toJS } from 'mobx';

import PractitionerService from 'services/PractitionerService';
import Practitioner from './practitioner';
import { STORAGE_KEY, AUTH_KEY } from 'configuration';
import { tagManagerEvent } from '../../../utilities/analytics';

const Auth = types
  .model('Auth', {
    currentPractitioner: types.maybeNull(Practitioner, {}),
    isAuthenticated: types.maybeNull(types.boolean, false),
    token: types.maybeNull(types.string, ''),
    userImage: types.maybeNull(types.string, ''),
  })
  .views((self) => ({
    get hasCompletedWizard() {
      return (
        self.currentPractitioner.onboarded &&
        (!self.currentPractitioner.invoicingEnabled || self.currentPractitioner.invoicingSetup)
      );
    },

    get hasCompletedProfessionalStep() {
      const p = self.currentPractitioner;
      const tags = p && p.tags && toJS(p.tags);
      if (
        self.isAuthenticated &&
        p &&
        tags &&
        tags.some((tag) => tag.tagType === 'Specialization') &&
        tags.some((tag) => tag.tagType === 'ClientType') &&
        tags.some((tag) => tag.tagType === 'TherapyType') &&
        p.primaryImageUrl
      ) {
        return true;
      }
      return false;
    },

    get hasCompletedPriceStep() {
      const p = self.currentPractitioner;
      const paymentOptions = p && p.paymentOptions && toJS(p.paymentOptions);
      if (self.isAuthenticated && p && paymentOptions && paymentOptions.length > 0) {
        return true;
      }
      return false;
    },

    get hasCompletedLocationStep() {
      const p = self.currentPractitioner;
      const locations = p && p.serviceLocations && toJS(p.serviceLocations);
      if (self.isAuthenticated && p && locations && locations.length > 0) {
        return true;
      }
      return false;
    },
  }))
  .actions((self) => ({
    login: flow(function* (tokens) {
      self.token = tokens.token;
      localStorage.setItem(
        AUTH_KEY,
        JSON.stringify({
          token: tokens.token,
        })
      );
      const refreshed = yield self.refresh();
      return refreshed;
    }),
    refresh: flow(function* () {
      if (self.token) {
        const practitionerResponse = yield PractitionerService.fetchPractitioner('me');
        if (!practitionerResponse.hasError) {
          self.currentPractitioner = practitionerResponse.data;
          self.isAuthenticated = true;
          self.isGuest = false;
          self.userImage = practitionerResponse.data.primaryImageUrl;
          if (window && window.FreshworksWidget) {
            window.FreshworksWidget('identify', 'ticketForm', {
              name: self.currentPractitioner.name,
              email: self.currentPractitioner.emailAddress,
              custom_fields: {
                practitionerId: self.currentPractitioner.id
              }
            });
          }
        }
        return true;
      }
      return undefined;
    }),
    logout: () => {
      self.currentPractitioner = null;
      self.isAuthenticated = false;
      self.token = '';
      self.userImage = '';
      localStorage.removeItem(STORAGE_KEY);
      localStorage.removeItem(AUTH_KEY);
    },
    signUp: flow(function* (data) {
      return yield PractitionerService.signUp(data);
    }),

    updatePractitioner: flow(function* (data) {
      const res = yield PractitionerService.updatePractitioner(data);

      if (!res.hasError) {
        self.currentPractitioner = res.data;

        tagManagerEvent('Practitioner Updated');
      }

      return res;
    }),
    updatePractitionerOriginalImage: flow(function* (formData) {
      const imgResponse = yield PractitionerService.updatePractitionerOriginalImage(
        self.currentPractitioner.id,
        formData
      );

      return imgResponse;
    }),
    updatePractitionerResizedImage: flow(function* (formData) {
      const imgResponse = yield PractitionerService.updatePractitionerResizedImage(
        self.currentPractitioner.id,
        formData
      );

      if (!imgResponse.hasError) {
        self.userImage = imgResponse.data.responseObject;
      }

      return imgResponse;
    }),
  }));

export default Auth;
