import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';

import { BookingCompleteScreenProps, errorMessage } from '..';
import ToggleButton from '../../../../../common/ToggleButton';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';

type ClientDNAScreenProps = {
  history: any;
  calendar: any;
  match: any;
} & BookingCompleteScreenProps;

const ClientDNAScreen: React.FC<ClientDNAScreenProps> = ({
  history,
  match,
  calendar,
  setScreen,
}) => {
  const [chargeForSession, setChargeForSession] = React.useState<boolean>(true);

  const onNext = () => {
    const bookingId = match.params.id;
    if (bookingId) {
      const data = {
        completionStatus: 'CLIENT_DID_NOT_ATTEND',
        chargeForSession,
      };

      calendar.completeAppointment(bookingId, data).then((res) => {
        if (!res.hasError) {
          if (chargeForSession) {
            history.push(`/app/invoice/${res.invoiceId}`);
          } else {
            setScreen('COMPLETE_CLIENT_DNA');
          }
        } else {
          toast.error(errorMessage);
        }
      });
    }
  };

  return (
    <>
      <div className='mb-12'>
        <div className='flex sm:items-center flex-col sm:flex-row'>
          <div className='mb-4 sm:mb-0'>
            <Text className='text-lg mr-28 mb-2'>
              Would you like to charge for the appointment?
            </Text>
            <div className='mr-8 text-sm whitespace-pre-line'>
              According to our{' '}
              <a
                className='text-sm'
                href='https://support.myclearhead.com/help/clearhead-s-client-cancellation-policy'
                target='_blank'
              >
                Cancellation Policy
              </a>
              {` you can charge for appointments that were not attended \nor cancelled less than 24 hours before the appointment date and time.`}
            </div>
          </div>
          <div>
            <ToggleButton.Secondary
              className='mr-2 mb-2'
              onClick={() => setChargeForSession(true)}
              active={chargeForSession}
              title='Yes'
            />
            <ToggleButton.Secondary
              onClick={() => setChargeForSession(false)}
              active={!chargeForSession}
              title='No'
            />
          </div>
        </div>
      </div>
      <Button color='primary' size='lg' className='rounded-full h-10 w-32' onClick={onNext}>
        Finish
      </Button>
    </>
  );
};

export default withRouter(inject('calendar')(observer(ClientDNAScreen)));
