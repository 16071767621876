import axios from 'axios';
import { AUTH_KEY } from 'configuration';
import { toast } from 'react-toastify';

const processSuccess = (response) => ({
  data: response.data,
  hasError: false,
  authError: false,
  status: response.status,
  stacktrace: response.config,
  message: null,
  retry: false,
});

const getTokens = () => {
  let keys = {};
  if (localStorage.getItem(AUTH_KEY)) {
    keys = JSON.parse(localStorage.getItem(AUTH_KEY));
  }
  return keys;
};

const processError = async (error) => {
  let message = 'Sorry something went wrong.';
  let retry = false;
  let status = 500;
  let errors = [];
  let authError = false;
  if (error && error.response && error.response.data) {
    status = error.response.status;
    errors = error.response.data.errors || [];

    switch (status) {
      case 400:
        if (error.response.data.message) {
          message = error.response.data.message;
        } else {
          message = 'Sorry some fields were not correct.';
        }
        break;
      case 401:
        authError = true;
        retry = false;
        message = retry ? 'Token refreshed.' : 'Login failed.';
        break;
      default:
        message = 'Sorry, something went wrong.';
        break;
    }
  }

  toast.error(message);

  const response = {
    status,
    authError,
    hasError: true,
    data: null,
    errors,
    message,
    stacktrace: null,
    retry,
  };
  if (process.env.NODE_ENV === 'development') {
    response.stacktrace = error;
  }
  if (process.env.NODE_ENV !== 'development') {
    response.message = message;
  }
  return response;
};

const request = axios.create({
  timeout: 120000,
  headers: { 'Cache-Control': 'no-cache' },
});

request.interceptors.response.use(
  (response) => processSuccess(response),
  async (error) => await processError(error)
);

request.interceptors.request.use((config) => {
  if (config.headers.ignoreAuthorization) {
    return config;
  }
  if (!(config.headers.Authorization && config.headers.Authorization.length > 0)) {
    const { token } = getTokens();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
});

class Requestor {
  static get(url, headers = {}, params = {}) {
    return request.get(url, {
      headers: { ...headers },
      params,
    });
  }

  static post(url, data = {}, headers = {}, params = {}) {
    return request.post(url, data, {
      headers: { ...headers },
      params,
    });
  }

  static put(url, data = {}, headers = {}, params = {}) {
    return request.put(url, data, {
      headers: { ...headers },
      params,
    });
  }

  static delete(url, data = {}, headers = {}, params = {}) {
    return request.delete(url, {
      data,
      headers: { ...headers },
      params,
    });
  }
}

export default Requestor;
