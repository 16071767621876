import * as React from 'react';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import InputTextField, { TextFieldProps } from '../TextField';

export type DOBFieldProps = {
  formFieldClassName?: string;
  enableAutoTab?: boolean;
} & TextFieldProps;

// UX inspired from following article but with auto-tab logic by default
// https://www.smashingmagazine.com/2021/05/frustrating-design-patterns-birthday-picker/

const DOBField: React.FC<DOBFieldProps> = ({
  className,
  formFieldClassName,
  value,
  onChange = () => void 0,
  enableAutoTab = true,
  invalid,
  valid,
  ...rest
}) => {
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const dayRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const daysInMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Used for doAutoTab and date validation
  const refs = [dayRef, monthRef, yearRef];
  const maxValues = [daysInMonth[Number(month) - 1], 12, new Date().getFullYear()];

  // Function used to update state based on the value prop
  useEffect(() => {
    const dateRegex = /^(\d{4})-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
    const matchArray = value?.match(dateRegex);
    if (matchArray) {
      setYear(matchArray[1]);
      setMonth(matchArray[2]);
      setDay(matchArray[3]);
    }
  }, [value]);

  // Function used to call the onChange prop
  const onControlledChange = (dayString: string, monthString: string, yearString: string) => {
    const day = Number(dayString);
    const month = Number(monthString);
    const year = Number(yearString);

    if (
      day > 0 &&
      day <= maxValues[0] &&
      month > 0 &&
      month <= maxValues[1] &&
      year > 1900 &&
      year <= maxValues[2]
    ) {
      // Valid value, pass it to the parent
      const date = `${year}-${month.toString().padStart(2, '0')}-${day
        .toString()
        .padStart(2, '0')}`;
      onChange(date);
    } else {
      // Invalid value, send an empty string to the parent
      onChange('');
    }
  };

  const doAutoTab = (index: number, oldValue: string, newValue: string) => {
    if (!enableAutoTab) {
      return;
    }

    if (
      newValue.length > oldValue.length &&
      newValue.length === 2 &&
      Number(newValue) <= maxValues[index] &&
      index < 3
    ) {
      // Move to next field and select content
      refs[index + 1]?.current?.focus();
      refs[index + 1]?.current?.select();
    }
  };

  const onDayChange = (newValue: string) => {
    if (/^[0-9]*$/.test(newValue)) {
      doAutoTab(0, day, newValue);
      setDay(newValue);
      onControlledChange(newValue, month, year);
    }
  };

  const onMonthChange = (newValue: string) => {
    if (/^[0-9]*$/.test(newValue)) {
      doAutoTab(1, month, newValue);
      setMonth(newValue);
      onControlledChange(day, newValue, year);
    }
  };

  const onYearChange = (newValue: string) => {
    if (/^[0-9]*$/.test(newValue)) {
      doAutoTab(3, year, newValue);
      setYear(newValue);
      onControlledChange(day, month, newValue);
    }
  };

  return (
    <div className='flex flex-col'>
      <div className='flex items-center gap-1 w-80'>
        <InputTextField
          className={clsx(formFieldClassName, 'flex-none !w-20 h-full')}
          placeholder='Day'
          maxLength={3}
          invalid={invalid}
          valid={valid}
          value={day}
          onChange={onDayChange}
          ref={dayRef}
          {...rest}
        />
        <Text>/</Text>
        <InputTextField
          className={clsx(formFieldClassName, 'flex-none !w-20 h-full')}
          placeholder='Month'
          maxLength={3}
          invalid={invalid}
          valid={valid}
          value={month}
          onChange={onMonthChange}
          ref={monthRef}
          {...rest}
        />
        <Text>/</Text>
        <InputTextField
          className={clsx(formFieldClassName, 'flex-grow h-full')}
          placeholder='Year'
          maxLength={5}
          invalid={invalid}
          valid={valid}
          value={year}
          onChange={onYearChange}
          ref={yearRef}
          {...rest}
        />
      </div>
      {Number(day) > maxValues[0] && (
        <Text className='mt-2 text-red'>Please enter a valid date.</Text>
      )}
    </div>
  );
};

export default DOBField;
