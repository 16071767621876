import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import { FooterNav, wizardContainerStyle } from '../WizardForm/styles';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';
import { PillButton, Image } from '../WizardForm/styles';
import Plus from 'assets/svg/plus.svg';
import { WizardFormContext } from '../index';
import { DropdownMenu, DropdownColourStyles } from '../WizardForm/styles';
import PractitionerService from 'jsx:services/PractitionerService';

const TagsStep = inject('auth')(
  observer((props) => {
    const { onPrevious, onNext, updateData, data, currentStep } =
      React.useContext(WizardFormContext);

    let [concerns, setConcerns] = React.useState(data.concernsTags ? data.concernsTags : []);

    let [clients, setClients] = React.useState(data.clientTags ? data.clientTags : []);

    let [therapy, setTherapy] = React.useState(data.therapyTags ? data.therapyTags : []);

    const [isLoading, setIsLoading] = React.useState(false);
    const [concernsTags, setConcernsTags] = React.useState([]);
    const [clientTags, setClientTags] = React.useState([]);
    const [therapyTags, setTherapyTags] = React.useState([]);

    React.useEffect(() => {
      if (props.auth.currentPractitioner) {
        PractitionerService.fetchPractitioner(props.auth.currentPractitioner.id).then((res) => {
          let therapyInitial = [];
          let clientsInitial = [];
          let specialisationInitial = [];

          res.data.tags.forEach((tag) => {
            switch (tag.tagType) {
              case 'TherapyType':
                therapyInitial = therapyInitial.concat({
                  label: tag.description ?? tag.name,
                  value: tag.name,
                });

                break;
              case 'ClientType':
                clientsInitial = clientsInitial.concat({
                  label: tag.description ?? tag.name,
                  value: tag.name,
                });
                break;
              case 'Specialization':
                specialisationInitial = specialisationInitial.concat({
                  label: tag.description ?? tag.name,
                  value: tag.name,
                });
                break;
              default:
                break;
            }

            setConcerns(specialisationInitial);
            setClients(clientsInitial);
            setTherapy(therapyInitial);
          });
        });
      }
    }, [props.auth.currentPractitioner]);

    React.useEffect(() => {
      PractitionerService.getTags().then((res) => {
        const filterSpecialisation = res.data.filter((type) => type.tagType === 'Specialization');
        setTags(filterSpecialisation, setConcernsTags);

        const filterClients = res.data.filter((type) => type.tagType === 'ClientType');
        setTags(filterClients, setClientTags);

        const filterTherapy = res.data.filter((type) => type.tagType === 'TherapyType');
        setTags(filterTherapy, setTherapyTags);
      });
    }, []);

    function setTags(data, setTags) {
      let dropDownTags = [];
      data.forEach((object) => {
        const obj = { label: object.description ?? object.name, value: object.name };
        dropDownTags = dropDownTags.concat(obj);
      });
      setTags(dropDownTags);
    }

    const goBack = async () => {
      setIsLoading(true);
      updateData({
        concernsTags: concerns,
        clientTags: clients,
        therapyTags: therapy,
      });
      await onPrevious();
    };

    const isValid = React.useCallback(() => {
      return concerns.length !== 0 && clients.length !== 0 && therapy.length !== 0;
    }, [concerns, clients, therapy]);

    const onSubmit = React.useCallback(async () => {
      setIsLoading(true);
      if (isValid()) {
        const tags = createTagsObject();

        updateData({
          concernsTags: concerns,
          clientTags: clients,
          therapyTags: therapy,
        });

        const { auth } = props;

        auth.updatePractitioner({
          id: auth.currentPractitioner.id,
          tags: tags,
        });

        await onNext();
      }
    }, [concerns, clients, therapy, onNext, isValid]);

    //create a tags array to be sent to the api
    function createTagsObject() {
      let tagsObject = [];
      concerns.forEach((concern) => {
        tagsObject = tagsObject.concat(concern.value);
      });

      clients.forEach((client) => {
        tagsObject = tagsObject.concat(client.value);
      });

      therapy.forEach((therapyType) => {
        tagsObject = tagsObject.concat(therapyType.value);
      });
      return tagsObject;
    }

    function containsTag(concerns, option) {
      if (option?.length !== 0) {
        return concerns.value === option[0].value;
      } else {
        return false;
      }
    }

    // functions for first tag (therapy concerns)
    const updateConcerns = (option) => {
      const contains = concerns.some((e) => containsTag(e, option));
      if (!contains && option) {
        setConcerns(concerns.concat(option));
      }
    };

    // functions for second tag (client)
    const updateClients = (option) => {
      const contains = clients.some((e) => containsTag(e, option));
      if (!contains && option) {
        setClients(clients.concat(option));
      }
    };

    // functions for third tag (therapy types )
    const updateTherapy = (option) => {
      const contains = therapy.some((e) => containsTag(e, option));
      if (!contains && option) {
        setTherapy(therapy.concat(option));
      }
    };

    const tagsOnClick = (name, tagType) => {
      if (tagType === 1) {
        setConcerns(concerns.filter((item) => item.value !== name.value));
      } else if (tagType === 2) {
        setClients(clients.filter((item) => item.value !== name.value));
      } else if (tagType === 3) {
        setTherapy(therapy.filter((item) => item.value !== name.value));
      }
    };

    return (
      <>
        <div className={wizardContainerStyle}>
          <div className='flex flex-col w-full'>
            <Heading className=' text-center text-dark-blue mb-8' as='h3'>
              Setting up Tags
            </Heading>

            {/* First tag */}
            <div className='text-left'>
              <Heading className='text-dark-blue mb-2' as='h4'>
                What are your areas of specialisation?
              </Heading>
              <Text className='mb-2'>
                For example, anger management, anxiety, and behavioural change
              </Text>
              <DropdownMenu
                styles={DropdownColourStyles}
                name='Concerns'
                className='bg-white w-full rounded-md border-primary-blue-50 mb-4 focus:outline-none'
                placeholder='Enter text'
                isMulti={true}
                isSearchable={true}
                value={null}
                options={concernsTags}
                onChange={updateConcerns}
              />
              <div className='mb-8'>
                {concerns.length !== 0
                  ? concerns.map((name) => (
                      <PillButton key={name.value}>
                        <div className='flex'>
                          <Text className='text-white flex justify-start'>{name.label}</Text>
                          <Image
                            src={Plus}
                            alt=''
                            onClick={() => tagsOnClick(name, 1)}
                            style={{ filter: 'invert(1)' }}
                          />
                        </div>
                      </PillButton>
                    ))
                  : null}
              </div>
            </div>

            {/* Second tag */}
            <div className='text-left'>
              <Heading className='text-dark-blue mb-2' as='h4'>
                What client groups do you focus on?
              </Heading>
              <Text className='mb-4'>For example, adults, adolescents</Text>
              <DropdownMenu
                styles={DropdownColourStyles}
                name='Clients'
                className='bg-white w-full rounded-md border-primary-blue-50 mb-4'
                placeholder='Enter text'
                isMulti={true}
                isSearchable={true}
                value={null}
                options={clientTags}
                onChange={updateClients}
              />
              <div className='mb-8'>
                {clients.length !== 0
                  ? clients.map((name) => (
                      <PillButton key={name.value}>
                        <div className='flex'>
                          <Text className='text-white flex justify-start'>{name.label}</Text>
                          <Image
                            src={Plus}
                            alt=''
                            onClick={() => tagsOnClick(name, 2)}
                            style={{ filter: 'invert(1)' }}
                          />
                        </div>
                      </PillButton>
                    ))
                  : null}
              </div>
            </div>

            {/* Third tag */}
            <div className='text-left'>
              <Heading className='text-dark-blue mb-2' as='h4'>
                What types of therapy (i.e. modalities) do you offer?
              </Heading>
              <Text className='mb-4'>For example, Cognitive Behavioural Therapy</Text>
              <Text className='mb-2 italic text-sm'>
                Please note, you can include other modalities as keywords in your bio. This means
                potential clients can search for you if they're interested in modalities that we
                don't have listed.
              </Text>
              <DropdownMenu
                styles={DropdownColourStyles}
                name='Therapy'
                className='bg-white w-full rounded-md border-primary-blue-50 mb-4'
                placeholder='Enter text'
                isMulti={true}
                isSearchable={true}
                value={null}
                options={therapyTags}
                onChange={updateTherapy}
              />
              <div className='mb-4'>
                {therapy.length !== 0
                  ? therapy.map((name) => (
                      <PillButton key={name.value}>
                        <div className='flex'>
                          <Text className='text-white flex justify-start'>{name.label}</Text>
                          <Image
                            src={Plus}
                            alt=''
                            onClick={() => tagsOnClick(name, 3)}
                            style={{ filter: 'invert(1)' }}
                          />
                        </div>
                      </PillButton>
                    ))
                  : null}
              </div>
            </div>

            <FooterNav className='xs:mt-8'>
              <div className='flex justify-start'>
                {currentStep > 0 && (
                  <Button
                    variant='primary'
                    modifiers={'inverted'}
                    onClick={goBack}
                    disabled={isLoading}
                  >
                    Back
                  </Button>
                )}
              </div>
              <div className='flex justify-end'>
                <Button
                  variant='primary'
                  onClick={onSubmit}
                  data-test-id='complete-hear-about-us'
                  disabled={!isValid() || isLoading}
                >
                  Next
                </Button>
              </div>
            </FooterNav>
          </div>
        </div>
      </>
    );
  })
);

export default TagsStep;
