/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar';
import { Button, Card, CardBody, Container, UncontrolledAlert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './big-calendar.css';
import { observer, inject, Observer } from 'mobx-react';
import { observable } from 'mobx';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { toast } from 'react-toastify';
import debounce from 'debounce';
import { isMobile } from 'react-device-detect';
import { faBell } from '@fortawesome/free-regular-svg-icons';

import {
  ViewBookingModal,
  Key,
  BlockLoading,
  CalendarToolbar,
  HelpCircle,
  BookingModal,
} from 'common';
import { HeaderContainer } from './styles';
import DateSelectModal from './date_select_modal';
import Event from './Event';

require('moment-recur');

const moment = extendMoment(Moment);
moment.locale(['en-nz', 'en-au'], {
  week: {
    dow: 1,
    doy: 1,
  },
});
const localizer = BigCalendar.momentLocalizer(moment);

const scrollToDate = moment().hour(9).minute(0).second(0).toDate();

@inject('schedule', 'bookings', 'calendar', 'auth', 'invoices')
@observer
class AppointmentCalendar extends Component {
  @observable loading = true;
  @observable selectedTimeSlot = {};
  @observable selectedEvent = {};
  @observable selectedEventStatus = null;

  @observable appointmentModalOpen = false;
  @observable viewEventModalOpen = false;
  @observable dateSelectModalOpen = false;

  @observable selectedPrices = [];
  @observable selectedLocations = [];
  @observable bookings = [];

  @observable calendarView = BigCalendar.Views.WEEK;
  @observable calendarViewOptionsVisible = true;
  @observable selectedDate = null;
  @observable currentMonth = null;

  @observable actionsDropdownOpen = false;

  @observable invoiceLoading = false;
  @observable invoice = null;
  @observable availabilityTimeRanges = [];

  constructor(props) {
    super(props);

    props.schedule.getSchedule().then((res) => {
      this.createAvailabilityTimeRanges(moment().toDate());
    });

    const data = { pageOffset: 0, pageSize: 20 };
    const startOfMonth = moment().startOf('month').subtract(6, 'days');
    const endOfMonth = moment().endOf('month').add(6, 'days');

    Promise.all([
      props.bookings.getAllBookings(data),
      props.calendar.getBlocks({ fromDate: startOfMonth, toDate: endOfMonth }),
      this.getPseudoBookings(moment().toDate()),
    ]).then(() => {
      this.loading = false;
    });
  }

  componentDidMount() {
    this.checkCalendarSize();
    window.addEventListener('resize', debounce(this.checkCalendarSize, 25));
    this.props.invoices.getSettings();
  }

  checkCalendarSize = () => {
    const calendarCard = document.getElementById('calendarCard');

    if (calendarCard) {
      const cardRect = calendarCard.getBoundingClientRect();
      if (cardRect.width < 500) {
        this.calendarView = BigCalendar.Views.DAY;
        this.calendarViewOptionsVisible = false;
      } else {
        if (this.calendarView === BigCalendar.Views.DAY) {
          this.calendarView = BigCalendar.Views.WEEK;
        }
        this.calendarViewOptionsVisible = true;
      }
    } else if (isMobile) {
      this.calendarView = BigCalendar.Views.DAY;
      this.calendarViewOptionsVisible = false;
    }
  };

  createAvailabilityTimeRanges = (date) => {
    const {
      schedule: { schedule },
    } = this.props;

    if (!schedule) {
      return [];
    }

    this.availabilityTimeRanges = schedule.template.map((slot) => {
      const { templateDay, startTime, endTime } = slot;

      // Subtract 15 minutes to fix a bug where the availability ranges were displaying 15 minutes late
      const startTimeM = moment(startTime, 'HH:mm:ss').subtract(15, 'minutes');
      const endTimeM = moment(endTime, 'HH:mm:ss').subtract(15, 'minutes');

      // Define start of the range
      const start = moment(date).isoWeekday(templateDay);
      start.hour(startTimeM.hour());
      start.minute(startTimeM.minute());
      start.second(startTimeM.second());

      // Define end of the range
      const end = moment(start);
      end.hour(endTimeM.hour());
      end.minute(endTimeM.minute());
      end.second(endTimeM.second());

      return moment.range(start, end);
    });
  };

  onSelectSlot = (slot) => {
    if (this.validTimeSelected(slot)) {
      this.selectedPrices = [];
      this.selectedLocations = [];
      this.selectedTimeSlot = slot;
      this.appointmentModalOpen = true;
    }
  };

  onSelectEvent = (event) => {
    this.invoice = null;
    this.invoiceLoading = true;
    this.selectedEvent = event.event;
    this.selectedEventStatus = event.status;
    this.viewEventModalOpen = true;
  };

  toggleAppointmentModal = () => {
    this.appointmentModalOpen = !this.appointmentModalOpen;
    if (!this.appointmentModalOpen) {
      this.selectedTimeSlot = {};
    }
  };

  toggleViewEventModal = () => {
    this.viewEventModalOpen = !this.viewEventModalOpen;
  };

  toggleDateSelectModal = () => {
    this.dateSelectModalOpen = !this.dateSelectModalOpen;
  };

  toggleActionsDropdown = () => {
    this.actionsDropdownOpen = !this.actionsDropdownOpen;
  };

  validTimeSelected = (slot) => {
    const {
      bookings: { confirmedBookings, pendingBookings },
    } = this.props;

    const nowLastMonth = moment().add(-1, 'months');
    const lastMonth = moment().add(-1, 'months').endOf('day');
    const requestedStart = moment(slot.start);
    const requestedEnd = moment(slot.end);
    const requestedRange = moment.range(requestedStart, requestedEnd);

    const invalidSelection =
      (this.calendarView !== BigCalendar.Views.MONTH &&
        requestedStart.isSameOrBefore(nowLastMonth)) ||
      (this.calendarView === BigCalendar.Views.MONTH && requestedStart.isSameOrBefore(lastMonth));

    if (invalidSelection) {
      toast.error('Cannot create an appointment over 1 month in the past');
      return false;
    }

    let bookings = confirmedBookings.concat(pendingBookings);

    const hasBookingCollision = bookings.some((booking) => {
      const bookingStart = moment(booking.appointmentTime);
      const bookingEnd = moment(booking.appointmentEndTime);
      const bookingRange = moment.range(bookingStart, bookingEnd);
      return bookingRange.overlaps(requestedRange);
    });

    // const hasBlockCollision = allBlocks.some(block => {
    // 	const blockStart = moment(block.blockageTime);
    // 	const blockEnd = moment(block.blockageEndTime);
    // 	const blockRange = moment.range(blockStart, blockEnd);
    // 	return blockRange.overlaps(requestedRange);
    // });

    if (hasBookingCollision) {
      toast.error('Appointment not created as it overlaps with an existing appointment');
      return false;
    }

    return true;
  };

  createSlot = () => {
    if (!this.selectedTimeSlot.start || !this.selectedTimeSlot.end) {
      toast.error('A start time and end time must be selected');
    } else if (this.selectedPrices.length === 0) {
      toast.error('Must select at least one price');
    } else if (this.selectedLocations.length === 0) {
      toast.error('Must select at least one location');
    } else if (this.validTimeSelected(this.selectedTimeSlot)) {
      const requestedStart = moment(this.selectedTimeSlot.start);
      const requestedEnd = moment(this.selectedTimeSlot.end);
      this.bookings.push({
        start: moment(this.selectedTimeSlot.start).toDate(),
        end: moment(this.selectedTimeSlot.end).toDate(),
        startTime: requestedStart.format('HH:mm:ss'),
        endTime: requestedEnd.format('HH:mm:ss'),
        templateDay: requestedStart.format('dddd'),
        chargeIds: this.selectedPrices,
        locationIds: this.selectedLocations,
      });
      this.toggleAppointmentModal();
    }
  };

  deleteSlot = () => {
    this.bookings = this.bookings.filter((slot) => slot.id !== this.selectedTimeSlot.id);
    this.selectedTimeSlot = null;
    this.appointmentModalOpen = false;
  };

  // This function gets called for each 15min slot on the calendar
  // Shade all slots that aren't within specified availability hours grey
  slotPropGetter = (date) => {
    const availableSlot = this.availabilityTimeRanges.some((range) =>
      range.contains(date, { excludeEnd: true })
    );

    if (!availableSlot) {
      return {
        className: 'grey-bg',
      };
    }
  };

  // Assign classNames to events based on their type for colouring
  eventPropGetter = (event, start, end, isSelected) => ({
    className: event.status ? `${event.status.toLowerCase()}-booking` : '',
  });

  getBookings = () => {
    const {
      bookings: {
        confirmedBookings,
        pendingBookings,
        pendingManualBookings,
        pendingConfirmationBookings,
        historicalBookings,
        monthlyPseudoBookings,
      },
      calendar: { allBlocks },
    } = this.props;

    let allBookings = confirmedBookings
      .concat(pendingBookings)
      .concat(pendingManualBookings)
      .concat(pendingConfirmationBookings)
      .concat(historicalBookings)
      .concat(monthlyPseudoBookings ?? []);

    // remove individual group appointments
    allBookings = allBookings.filter((x) => !x.groupId);
    allBookings = allBookings.filter((x) => x.status !== 'Cancelled' && x.status !== 'Rejected');

    allBookings = allBookings.map((booking) => {
      let title = '';
      if (booking.type === 'Group') {
        if (booking.appointments && booking.appointments.length) {
          title = `Booking with ${booking.appointments.length} ${
            booking.appointments.length > 1 ? 'people' : 'person'
          }`;
        } else {
          title = 'Booking with 0 people';
        }
      } else {
        title = `${booking.contact.firstName} ${booking.contact.lastName}`;
      }
      return {
        start: moment(booking.appointmentTime).toDate(),
        end: moment(booking.appointmentEndTime).toDate(),
        title,
        status: booking.status,
        event: booking,
      };
    });

    let blocks = allBlocks.map((block) => ({
      start: moment(block.blockageTime).toDate(),
      end: moment(block.blockageEndTime).toDate(),
      title: block.title,
      status: 'Block',
      event: block,
    }));

    return allBookings.concat(blocks);
  };

  getDataForMonth = async (date) => {
    const { calendar } = this.props;

    const startOfMonth = moment(date).startOf('month').subtract(6, 'days');
    const endOfMonth = moment(date).endOf('month').add(6, 'days');

    // Redraw open hour ranges based off the new week selected
    this.createAvailabilityTimeRanges(date);
    // this.getPseudoBookings(date);

    return await Promise.all([calendar.getBlocks({ fromDate: startOfMonth, toDate: endOfMonth })]);
  };

  onNavigate = async (date) => {
    this.selectedDate = date;

    await this.getDataForMonth(date);
    this.loading = false;
  };

  onViewChange = (view) => {
    this.calendarView = view;
  };

  getPseudoBookings = (date) => {
    const { bookings } = this.props;

    if (!this.currentMonth || !this.currentMonth.contains(date)) {
      this.loading = true;
      const mDate = moment(date);
      this.currentMonth = moment.range(
        moment(mDate).startOf('month'),
        moment(mDate).endOf('month')
      );
      bookings.getMonthlyPseudoBookings(date).then((res) => {
        this.loading = false;
      });
    }
  };

  getAlertComponent = () => {
    const {
      bookings: { pendingBookings, pendingManualBookings, pendingConfirmationBookings },
      history,
    } = this.props;

    if (pendingBookings.length > 0 || pendingManualBookings.length > 0) {
      return (
        <UncontrolledAlert color='warning' className='alert-outline'>
          <div className='alert-icon'>
            <FontAwesomeIcon icon={faBell} fixedWidth />
          </div>
          <div className='alert-message'>
            <a
              onClick={() => {
                history.push('/app/bookings?tab=PENDING');
              }}
            >
              <span>You have new referrals awaiting your approval. </span>
              <span className='underline font-semibold'>Click here to view</span>
            </a>
          </div>
        </UncontrolledAlert>
      );
    } else if (pendingConfirmationBookings?.length > 0) {
      return (
        <UncontrolledAlert color='warning' className='alert-outline'>
          <div className='alert-icon'>
            <FontAwesomeIcon icon={faBell} fixedWidth />
          </div>
          <div className='alert-message'>
            <a
              onClick={() => {
                history.push('/app/bookings?tab=PENDING-COMPLETION');
              }}
            >
              <span>You have bookings awaiting to be marked as completed. </span>
              <span className='underline font-semibold'>Click here to view</span>
            </a>
          </div>
        </UncontrolledAlert>
      );
    } else {
      return;
    }
  };

  getInvoiceAlertComponent = () => {
    const { history } = this.props;
    const invoiceDetails = this.props.invoices.settings;
    if (
      !this.props.auth?.currentPractitioner.invoicingSetup &&
      this.props.auth?.currentPractitioner?.invoicingEnabled
    ) {
      return (
        <UncontrolledAlert color='warning' className='alert-outline'>
          <div className='alert-icon'>
            <FontAwesomeIcon icon={faBell} fixedWidth />
          </div>
          <div className='alert-message'>
            <a
              onClick={() => {
                history.push('/app/setup-wizard');
              }}
            >
              <span>You have missing invoicing details. </span>
              <span className='underline font-semibold'>Click here to view</span>
            </a>
          </div>
        </UncontrolledAlert>
      );
    } else {
      return;
    }
  };

  setCalendarDate = (date) => {
    this.onNavigate(date);
    this.dateSelectModalOpen = false;
  };

  dayViewFormat = (_ref) => {
    const date = moment(_ref);
    return date.format('ddd Do MMMM');
  };

  weekViewFormat = (_ref) => {
    const start = moment(_ref.start);
    const end = moment(_ref.end);

    if (start.isSame(end, 'month')) {
      return `${start.format('Do')} — ${end.format('Do')} ${start.format('MMM')}`;
    }
    return `${start.format('Do MMM')} — ${end.format('Do MMM')}`;
  };

  listViewFormat = (_ref) => {
    const start = moment(_ref.start);
    const end = moment(_ref.end);
    return `${start.format('Do MMM')} — ${end.format('Do MMM')}`;
  };

  render() {
    const {
      bookings: { pendingBookings, pendingManualBookings },
      auth: { currentPractitioner },
    } = this.props;

    const isPendingBookings = pendingBookings.length > 0 || pendingManualBookings.length > 0;

    return (
      <>
        {this.getAlertComponent()}
        {this.getInvoiceAlertComponent()}
        <Container fluid>
          <HeaderContainer className='mb-2'>
            <div className='d-flex align-items-center'>
              <Heading className='text-xl text-dark-blue d-inline-block h3 mb-0 mr-2'>
                Appointment Calendar -{' '}
                {currentPractitioner && currentPractitioner.timezone
                  ? currentPractitioner.timezone
                  : 'A'}
              </Heading>
              <HelpCircle.Primary tooltipText='This is where you manage your pending and existing appointments.<br/>You can also create one-off appointments here or block off time on your calendar.' />
            </div>

            <div className='d-flex flex-wrap'>
              <Button
                color='primary'
                size='md'
                onClick={this.toggleAppointmentModal}
                disabled={this.loading}
                className='rounded-full font-bold h-8'
              >
                Create Appointment
              </Button>
            </div>
          </HeaderContainer>

          <div className='mb-2'>
            <Key colour='#4caf50'>Confirmed</Key>
            <Key colour='#ff9800'>New Referrals / Pending Completion</Key>
            <Key colour='#9d7bd8'>Completed</Key>
            <Key colour='#333'>Blocked</Key>
          </div>

          <Card id='calendarCard' className='mb-0'>
            {this.loading && <BlockLoading />}
            <CardBody>
              <Observer>
                {() => (
                  <BigCalendar
                    className={`calendar-${this.calendarView}-view ${
                      this.calendarViewOptionsVisible ? '' : 'mobile'
                    }`}
                    date={this.selectedDate}
                    localizer={localizer}
                    events={this.getBookings()}
                    view={this.calendarView}
                    step={15}
                    timeslots={4}
                    scrollToTime={scrollToDate}
                    formats={{
                      dayFormat: 'ddd Do',
                      dayHeaderFormat: this.dayViewFormat,
                      dayRangeHeaderFormat: this.weekViewFormat,
                      agendaHeaderFormat: this.listViewFormat,
                    }}
                    views={['day', 'week', 'month', 'agenda']}
                    components={{
                      event: Event,
                      toolbar: CalendarToolbar,
                    }}
                    slotPropGetter={this.slotPropGetter}
                    eventPropGetter={this.eventPropGetter}
                    onSelectSlot={this.onSelectSlot}
                    onSelectEvent={this.onSelectEvent}
                    onNavigate={this.onNavigate}
                    onView={this.onViewChange}
                    selectable
                    getNow={() => {
                      const tzTime = moment.tz(currentPractitioner.timezone);

                      return new Date(
                        tzTime.year(),
                        tzTime.month(),
                        tzTime.date(),
                        tzTime.hour(),
                        tzTime.minute()
                      );
                    }}
                  />
                )}
              </Observer>
            </CardBody>
          </Card>
        </Container>

        <BookingModal
          isModalOpen={this.appointmentModalOpen}
          toggleModal={this.toggleAppointmentModal}
          selectedTimeSlot={this.selectedTimeSlot}
        />

        <ViewBookingModal
          isModalOpen={this.viewEventModalOpen}
          toggleModal={this.toggleViewEventModal}
          booking={this.selectedEvent}
          bookingStatus={this.selectedEventStatus}
          invoice={this.invoice}
          invoiceLoading={this.invoiceLoading}
        />

        <DateSelectModal
          isModalOpen={this.dateSelectModalOpen}
          toggleModal={this.toggleDateSelectModal}
          defaultDate={this.selectedDate}
          setCalendarDate={this.setCalendarDate}
        />
      </>
    );
  }
}

export default AppointmentCalendar;
