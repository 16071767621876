"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = modifiedStyles;

var _isFunction = _interopRequireDefault(require("lodash/isFunction"));

var _isObject = _interopRequireDefault(require("lodash/isObject"));

var _normalizeModifiers = _interopRequireDefault(require("./normalizeModifiers"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Evaluates if the first argument is of the ModifierObjValue type
 * @param {*} val
 * @returns {val is ModifierObjValue}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isModifierObjValue(val) {
  return (0, _isObject.default)(val) && !!val.styles;
}
/**
 * Extracts and builds the required style string based on the provided values.
 * @export
 * @param {ModifierKeys} [modifierKeys=[]]
 * @param {ModifiersConfig} [modifierConfig={}]
 * @param {ComponentProps} [componentProps]
 * @returns {SimpleInterpolation}
 */


function modifiedStyles() {
  var modifierKeys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var modifierConfig = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var componentProps = arguments.length > 2 ? arguments[2] : undefined;
  var stylesArr = (0, _normalizeModifiers.default)(modifierKeys).reduce(function (acc, modifierName) {
    var modifierConfigValue = modifierConfig[modifierName];

    if ((0, _isFunction.default)(modifierConfigValue)) {
      var config = modifierConfigValue(componentProps);
      var styles = isModifierObjValue(config) ? config.styles : config;
      return Array.isArray(styles) ? acc.concat(styles.join('')) : acc.concat(styles);
    }

    return acc;
  }, []);
  return stylesArr.join(' ');
}