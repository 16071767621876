import React from 'react';
import { observer, inject } from 'mobx-react';
import { FooterNav, wizardContainerStyle } from '../WizardForm/styles';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import UploadPictureModal from 'common/UploadPictureModal';
import PencilImage from './pencil.png';
import DefaultProfileImage from './default-profile.svg';
import { WizardFormContext } from '../index';
import { ProfileImageContainer, PencilButtonContainer } from './styles';

const ProfileImageStep = inject('auth')(
  observer((props) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const { onPrevious, onNext, currentStep } = React.useContext(WizardFormContext);
    const [isLoading, setIsLoading] = React.useState(false);

    const goBack = async () => {
      setIsLoading(true);
      await onPrevious();
    };

    const isValid = React.useCallback(() => {
      if (props.auth.currentPractitioner) {
        return props.auth.userImage !== undefined && !props.auth.userImage.includes('avataaars');
      } else {
        return false;
      }
    }, []);

    const onSubmit = React.useCallback(async () => {
      setIsLoading(true);
      if (isValid()) {
        // Skip tenant selecting step
        await onNext();
      }
    }, [onNext, isValid]);

    const togglePictureModal = () => {
      setIsModalOpen(!isModalOpen);
    };

    const getPractitionerImage = () => {
      const {
        auth: { userImage },
      } = props;
      return (
        <>
          <div className='relative mx-auto' style={{ width: '113px' }}>
            <ProfileImageContainer
              src={
                userImage.includes('avataaars') || userImage === null || userImage === ''
                  ? DefaultProfileImage
                  : userImage
              }
              alt='profileImage'
              className='object-contain inline-block rounded-full'
              onClick={() => setIsModalOpen(true)}
            />
            <PencilButtonContainer
              onClick={() => setIsModalOpen(true)}
              className='focus:outline-none'
            >
              <img src={PencilImage} alt='pencilImage' height={20} width={20} />
            </PencilButtonContainer>
          </div>
        </>
      );
    };

    return (
      <>
        <div className={wizardContainerStyle}>
          <div className='flex flex-col w-full items-center'>
            <div className='max-w-lg text-center'>
              <Heading className='text-dark-blue mb-2' as='h3'>
                Add a Profile Photo
              </Heading>
              <Text>Crop your photo into a square shape for best results.</Text>
              <Text className='mb-12'>Max size 5mb.</Text>
              <div className='flex justify-center'>{getPractitionerImage()}</div>
              <Button
                variant='primary'
                className='mt-4'
                onClick={() => {
                  setIsModalOpen(true);
                }}
                disabled={isLoading}
              >
                Add Photo
              </Button>

              <UploadPictureModal isModalOpen={isModalOpen} toggleModal={togglePictureModal} />
            </div>

            <FooterNav className='md:mt-16 xs:mt-8'>
              <div className='flex justify-start'>
                {currentStep > 0 && (
                  <Button
                    variant='primary'
                    modifiers={'inverted'}
                    onClick={goBack}
                    disabled={isLoading}
                  >
                    Back
                  </Button>
                )}
              </div>
              <div className='flex justify-end'>
                <Button
                  variant='primary'
                  onClick={onSubmit}
                  data-test-id='complete-hear-about-us'
                  disabled={!isValid() || isLoading}
                >
                  Next
                </Button>
              </div>
            </FooterNav>
          </div>
        </div>
      </>
    );
  })
);
export default observer(ProfileImageStep);
