import React from 'react';
import clsx from 'clsx';
import RawToggleButton from './RawToggleButton';
import { ToggleButtonProps } from '.';

const ToggleButtonSecondary: React.FC<ToggleButtonProps> = ({
  className = '',
  onClick = () => void 0,
  active = true,
  title,
}) => (
  <RawToggleButton
    className={clsx(
      'border-secondary-purple-100 hover:bg-secondary-purple-50 hover:text-white',
      className
    )}
    activeClassName='bg-secondary-purple-500 text-white'
    inactiveClassName='bg-transparent text-secondary-purple-100'
    onClick={onClick}
    active={active}
    title={title}
  />
);

export default ToggleButtonSecondary;
