import React from 'react';
import styled from 'styled-components';

const OptionLabelDiv = styled.div<IOptionLabel>`
  display: flex;
  flex-direction: column;
  min-height: 60px;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border: 1px solid var(--primary-15);
  border-radius: 8px;
`;

export interface IOptionLabel {
  className?: string;
}

const OptionLabel: React.FC<IOptionLabel> = ({ className, children }) => {
  return <OptionLabelDiv className={className}>{children}</OptionLabelDiv>;
};

export default OptionLabel;