import clsx from 'clsx';
import React from 'react';

type BookingStatusLabelProps = {
  bookingStatus?: string;
  className?: string;
  cancelledBy?: string;
};
const BookingStatusLabel: React.FC<BookingStatusLabelProps> = ({
  bookingStatus,
  className,
  cancelledBy,
}) => {
  let statusClassname;
  let labelText;

  switch (bookingStatus) {
    case 'Rejected': {
      statusClassname = 'text-red bg-red border-red';
      labelText = 'Rejected';
      break;
    }
    case 'Cancelled': {
      statusClassname = 'text-red bg-red border-red';
      switch (cancelledBy) {
        case 'Practitioner': {
          labelText = `Cancelled by practitioner`;
          break;
        }
        case 'User': {
          labelText = 'Cancelled by client';
          break;
        }
        case 'System': {
          labelText = 'Cancelled by us';
          break;
        }
        default: {
          labelText = 'Cancelled';
          break;
        }
      }
      break;
    }
    case 'DidNotAttend': {
      statusClassname = 'text-red bg-red border-red';
      labelText = 'Completed | DNA';
      break;
    }
    case 'PendingCompletion': {
      statusClassname = 'text-dark-yellow bg-secondary-yellow-100 border-secondary-yellow-100';
      labelText = 'Awaiting Completion';
      break;
    }
    case 'Completed': {
      statusClassname = 'text-dark-green bg-dark-green border-dark-green';
      labelText = 'Completed';
      break;
    }
    case 'Confirmed': {
      statusClassname = 'text-primary-blue-100 bg-primary-blue-100 border-primary-blue-100';
      labelText = 'Confirmed';
      break;
    }
    case 'Pending':
    default: {
      // Pending
      statusClassname = 'hidden';
      labelText = '';
      break;
    }
  }

  return (
    <div
      className={clsx(
        className,
        statusClassname,
        'border bg-opacity-25 text-center w-fit h-auto max-h-auto rounded-full px-3'
      )}
    >
      {labelText}
    </div>
  );
};

export default BookingStatusLabel;
