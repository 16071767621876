import clsx from 'clsx';
import React from 'react';
import Accept from 'jsx:assets/svg/tick.svg';
import Decline from 'jsx:assets/svg/close.svg';

type CircleButtonProps = {
  isAccept: boolean;
  className?: string;
  onClick: () => void;
};
const CircleButton: React.FC<CircleButtonProps> = ({ className, isAccept, onClick }) => {
  return (
    <button
      className={clsx(
        className,
        isAccept ? 'bg-dark-green' : 'bg-red',
        'w-9 h-9 rounded-full flex items-center justify-center'
      )}
      onClick={onClick}
    >
      {isAccept ? <Accept className='fill-white' /> : <Decline className='fill-white' />}
    </button>
  );
};

export default CircleButton;
