import BaseService from '../BaseService';
import { BASE_API_URL } from 'configuration';
import moment from 'moment';

class CalendarService {
  static createAppointment = (data) => {
    return BaseService.post(`${BASE_API_URL}/practitioners/me/appointments`, data);
  };

  static completeAppointment = (appointmentId, data) => {
    return BaseService.post(
      `${BASE_API_URL}/practitioners/me/appointments/${appointmentId}/complete`,
      data
    );
  };

  static completeAppointment = (appointmentId, data) => {
    return BaseService.post(
      `${BASE_API_URL}/practitioners/me/appointments/${appointmentId}/complete`,
      data
    );
  };

  static approveManualPendingAppointment = (data) => {
    return BaseService.put(`${BASE_API_URL}/practitioners/me/manual-bookings/${data.id}`, data);
  };

  static updateAppointment = (data) => {
    if (!data.id && data.recurringAppointment && data.recurringAppointment.id) {
      const date = moment(data.appointmentTime).format('YYYY-MM-DD');
      return BaseService.put(
        `${BASE_API_URL}/practitioners/me/recurrences/${data.recurringAppointment.id}/${date}`,
        data
      );
    } else {
      return BaseService.put(`${BASE_API_URL}/practitioners/me/appointments/${data.id}`, data);
    }
  };

  static getBlocks = ({ fromDate, toDate }) => {
    const urlParts = [];
    if (fromDate) {
      const fromStr = moment(fromDate).format('YYYY-MM-DD');
      urlParts.push(`from=${fromStr}`);
    }
    if (toDate) {
      const toStr = moment(toDate).format('YYYY-MM-DD');
      urlParts.push(`to=${toStr}`);
    }
    const queryString = urlParts.join('&');
    const url = `${BASE_API_URL}/practitioners/me/blocks?${queryString}`;
    return BaseService.get(url);
  };
  static createBlock = (data) => {
    return BaseService.post(`${BASE_API_URL}/practitioners/me/blocks`, data);
  };
  static editBlock = (data) => {
    return BaseService.put(`${BASE_API_URL}/practitioners/me/blocks/${data.id}`, data);
  };
  static deleteBlock = (id) => {
    return BaseService.delete(`${BASE_API_URL}/practitioners/me/blocks/${id}`);
  };

  static getContactsBySearchTerm = (searchTerm, includeDetails = false) => {
    const encodedsearchTerm = encodeURIComponent(searchTerm);
    return BaseService.get(
      `${BASE_API_URL}/practitioners/me/contacts?pageOffset=0&pageSize=100&searchTerm=${encodedsearchTerm}&includeDetails=${includeDetails}`
    );
  };
}

export default CalendarService;
