import React from 'react';
import { PopupButton } from '@typeform/embed-react';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const typeformIdForVariant = {
  invoice: 'GIgexWny',
  settings: 'nocmmkdV',
};

type HelpLinkProps = {
  auth?: any; // We should change this to a proper type
  className?: string;
  variant: 'invoice' | 'settings';
  label?: string;
  invoiceId?: string;
  appointmentId?: string;
};

const HelpLink: React.FC<HelpLinkProps> = ({
  auth,
  className,
  variant,
  label = 'Need help?',
  invoiceId = '',
  appointmentId = '',
}) => {
  const {
    currentPractitioner: { id },
  } = auth;

  return (
    <PopupButton
      id={typeformIdForVariant[variant]}
      hidden={{
        practitioner_id: id,
        invoice_id: invoiceId,
        appointment_id: appointmentId,
      }}
      className={className}
      buttonProps={{ type: 'button' }}
      onReady={() => {
        // Hack to undo hack about a modal opening a modal leaving overflow: hidden on the body.
        // see file `src/common/styles.css`
        document.body.classList.add('modal-open');
      }}
      onClose={() => {
        document.body.classList.remove('modal-open');
      }}
    >
      <Text className='text-primary-blue-100 font-bold cursor-pointer text-sm hover:text-dark-blue'>
        {label}
      </Text>
    </PopupButton>
  );
};

export default inject('auth')(observer(HelpLink));
