import { LocationPin, VideoCamera } from '@clearhead-ltd/ui-components/dist/v2/Icons';
import * as React from 'react';
import moment from 'moment-timezone';
import BookingStatusLabel from '../BookingStatusLabel';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import styled from 'styled-components';

const dayInMs = 86400000;
const hourInMs = 3600000;
const minuteInMs = 60000;

const StyledLocationPin = styled(LocationPin)`
  height: 30px;
  width: 30px;
  path {
    stroke: #003473;
  }
`;

const StyledVideoCamera = styled(VideoCamera)`
  height: 24px;
  width: 24px;
  path {
    stroke: #003473;
  }
`;

const BookingCardSummary: React.FC = (props: any) => {
  const {
    booking: {
      appointmentTime,
      contact: { firstName, lastName, country },
      serviceLocation: { locationType },
      status,
      cancellationBy,
    },
  } = props;

  const startDateTime = moment(appointmentTime);

  const getExpiresAtString = () => {
    const { expiresAt } = props.booking;
    if (expiresAt) {
      let expiryString = '';
      const difference = moment(expiresAt).diff(moment.now()); // difference in ms
      if (difference < 0) {
        return;
      }

      if (difference > dayInMs) {
        const numDays = Math.floor(difference / dayInMs);
        expiryString = `${numDays} day${numDays > 1 ? 's' : ''}`;
      } else if (difference > hourInMs) {
        const numHours = Math.floor(difference / hourInMs);
        expiryString = `${numHours} hour${numHours > 1 ? 's' : ''}`;
      } else {
        const numMinutes = Math.floor(difference / minuteInMs);
        expiryString = `${numMinutes} minute${numMinutes > 1 ? 's' : ''}`;
      }

      return (
        <div className='text-dark-blue flex mb-1'>
          <Text className='text-sm'>Expires in</Text>
          <Text className='text-sm ml-1 font-semibold'>{expiryString}</Text>
        </div>
      );
    }
  };

  return (
    <div className='flex pr-3'>
      <div className='flex justify-content-center align-items-start w-8 mr-2'>
        {locationType == 'Online' ? <StyledVideoCamera /> : <StyledLocationPin />}
      </div>
      <div>
        <Heading as='h5' className={`text-dark-blue mb-2 ${props.isHovering ? 'underline' : ''}`}>
          {`${firstName} ${lastName}`}
        </Heading>
        <BookingStatusLabel className='mb-1' bookingStatus={status} cancelledBy={cancellationBy} />
        {status === 'Pending' || status === 'Manual' ? getExpiresAtString() : null}
        {appointmentTime ? (
          <Text className='text-sm text-dark-blue mb-1'>
            {startDateTime.format('Do MMMM - h:mm a')}
          </Text>
        ) : null}
        {status === 'Manual' ? (
          <div className='py-2 px-4 bg-secondary-yellow-50 rounded-md mb-1'>
            <Text className='text-dark-blue text-xs font-semibold'>
              Select a date & time that suits you.
            </Text>
          </div>
        ) : null}

        {country && <Text className='text-dark-blue text-sm'>Based in {country}</Text>}
      </div>
    </div>
  );
};

export default BookingCardSummary;
