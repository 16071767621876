import styled from "styled-components";
import { Button } from "reactstrap";

export const CenterText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 80px 0 0 0;
  flex-direction: column;
`;

export const HeadingText = styled.h1`
  font-size: 30px;
  color: black;
  flex-direction: column;
`;

export const Text = styled.p`
  font-size: 18px;
  margin: 10px 10px 30px 10px;
`;

export const Image = styled.img`
  width: 160px;
  margin: 0 0 10px 0;
  border-radius: 80px;
`;

export const Child = styled.section`
  background-color: #f5f6ff;
`;

export const GetStartedButton = styled(Button)`
  font-size: 20px;
  width: 230px;
  margin: 0 10px 10px 0;
`;

export const FreeTrialButton = styled(Button)`
  font-size: 20px;
  width: 150px;
  margin: 0 0 10px 0;
`;

export const NameText = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

export const OccupationText = styled.div`
  margin: 0 0 20px 0;
  font-size: 14px;
  color: grey;
`;
