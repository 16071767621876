import { SVGProps } from 'react';
const ArrowLargeLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 25 24' {...props}>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M17.542 12H7.458M13 18l-6-6 6-6'
    />
  </svg>
);
export default ArrowLargeLeft;
