export const DayTags = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
];

export const TimeZonetags = [{ label: 'Auckland Time', value: '09:00:00' }];

export const days = [
  { day: 'MON', name: 'Monday', date: '17' },
  {
    day: 'TUE',
    name: 'Tuesday',
    date: '18',
  },
  {
    day: 'WED',
    name: 'Wednesday',
    date: '19',
  },
  {
    day: 'THU',
    name: 'Thursday',
    date: '20',
  },
  { day: 'FRI', name: 'Friday', date: '21' },
  {
    day: 'SAT',
    name: 'Saturday',
    date: '22',
  },
  { day: 'SUN', name: 'Sunday', date: '23' },
];

export const CheckboxBooleans = {
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
  Sunday: false,
};

export const PopoverCheckboxState = [
  {
    Monday: [
      { day: 'Monday', checked: false },
      { day: 'Tuesday', checked: false },
      { day: 'Wednesday', checked: false },
      { day: 'Thursday', checked: false },
      { day: 'Friday', checked: false },
      { day: 'Saturday', checked: false },
      { day: 'Sunday', checked: false },
    ],
    Tuesday: [
      { day: 'Monday', checked: false },
      { day: 'Tuesday', checked: false },
      { day: 'Wednesday', checked: false },
      { day: 'Thursday', checked: false },
      { day: 'Friday', checked: false },
      { day: 'Saturday', checked: false },
      { day: 'Sunday', checked: false },
    ],
    Wednesday: [
      { day: 'Monday', checked: false },
      { day: 'Tuesday', checked: false },
      { day: 'Wednesday', checked: false },
      { day: 'Thursday', checked: false },
      { day: 'Friday', checked: false },
      { day: 'Saturday', checked: false },
      { day: 'Sunday', checked: false },
    ],
    Thursday: [
      { day: 'Monday', checked: false },
      { day: 'Tuesday', checked: false },
      { day: 'Wednesday', checked: false },
      { day: 'Thursday', checked: false },
      { day: 'Friday', checked: false },
      { day: 'Saturday', checked: false },
      { day: 'Sunday', checked: false },
    ],
    Friday: [
      { day: 'Monday', checked: false },
      { day: 'Tuesday', checked: false },
      { day: 'Wednesday', checked: false },
      { day: 'Thursday', checked: false },
      { day: 'Friday', checked: false },
      { day: 'Saturday', checked: false },
      { day: 'Sunday', checked: false },
    ],
    Saturday: [
      { day: 'Monday', checked: false },
      { day: 'Tuesday', checked: false },
      { day: 'Wednesday', checked: false },
      { day: 'Thursday', checked: false },
      { day: 'Friday', checked: false },
      { day: 'Saturday', checked: false },
      { day: 'Sunday', checked: false },
    ],
    Sunday: [
      { day: 'Monday', checked: false },
      { day: 'Tuesday', checked: false },
      { day: 'Wednesday', checked: false },
      { day: 'Thursday', checked: false },
      { day: 'Friday', checked: false },
      { day: 'Saturday', checked: false },
      { day: 'Sunday', checked: false },
    ],
  },
];

export const defaultTimeObj = {
  startTime: { label: '9:00am', value: '09:00:00' },
  endTime: { label: '5:00pm', value: '17:00:00' },
};

export const weeklyHoursInitialState = {
  Monday: [],
  Tuesday: [],
  Wednesday: [],
  Thursday: [],
  Friday: [],
  Saturday: [],
  Sunday: [],
};
