import React, { Component } from 'react';
import Select from 'react-select';
import { observer, inject } from 'mobx-react';
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import { Card, CardBody, Label, Row, Col } from 'reactstrap';
import clsx from 'clsx';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';

import Switch from '../../../common/Switch';
import { timezoneOptions } from '../../../configuration/timezones';

import { HelpCircle } from 'common';

const options = [
  { label: '12 Hours', value: 12 },
  { label: '24 Hours', value: 24 },
  { label: '2 Days', value: 48 },
  { label: '3 Days', value: 72 },
  { label: '4 Days', value: 96 },
  { label: '5 Days', value: 120 },
  { label: '6 Days', value: 144 },
  { label: '7 Days', value: 168 },
];

@inject('auth')
@observer
class BookingsSection extends Component {
  @observable selectedOption = null;
  @observable isSubmitting = false;

  @observable acceptingNewClientsUpdating = false;
  @observable acceptingNewClients = true;
  @observable onlineBookingEnabledUpdating = false;
  @observable onlineBookingEnabled = true;
  @observable onlineBookingEnabledUpdating = false;

  @observable showOnlineFlag = true;
  @observable timezone = 'Pacific/Auckland';

  constructor(props) {
    super(props);

    const {
      auth: { currentPractitioner },
    } = props;
    this.acceptingNewClients = currentPractitioner.acceptingNewClients;
    this.onlineBookingEnabled = currentPractitioner.onlineBookingEnabled;
  }

  onSelectChange = (option) => {
    const { auth } = this.props;

    this.isSubmitting = true;
    this.selectedOption = option;

    auth
      .updatePractitioner({
        id: auth.currentPractitioner.id,
        hoursNotice: option.value,
      })
      .then((res) => {
        this.isSubmitting = false;

        if (res.hasError) {
          toast.error('Something went wrong');
        } else {
          toast.success('Booking advance time updated');
        }
      });
  };

  onTimezoneChange = (option) => {
    const { auth } = this.props;

    this.isSubmitting = true;
    this.timezone = option.value;

    auth
      .updatePractitioner({
        id: auth.currentPractitioner.id,
        timezone: option.value,
      })
      .then((res) => {
        this.isSubmitting = false;

        if (res.hasError) {
          toast.error('Something went wrong');
        } else {
          toast.success('Booking advance time updated');
        }
      });
  };

  showReminder = () => {
    const params = new URLSearchParams(location.search);
    const unavailableAlert = params.get('unavailable');
    if (unavailableAlert) {
      return (
        <div className='w-full'>
          <div
            className={clsx(
              'mt-4 p-2 mb-4 border border-solid rounded-md bg-opacity-25 text-center bg-secondary-yellow-100 border-secondary-yellow-100'
            )}
          >
            <Text className='text-sm'>
              If you are not accepting new clients, please turn off "Accepting new clients" below.
            </Text>
          </div>
        </div>
      );
    }
  };

  toggleAcceptingClients = () => {
    // If it's not already updating..
    if (!this.acceptingNewClientsUpdating) {
      this.acceptingNewClients = !this.acceptingNewClients;
      this.acceptingNewClientsUpdating = true;

      const { auth } = this.props;
      auth
        .updatePractitioner({
          id: auth.currentPractitioner.id,
          acceptingNewClients: this.acceptingNewClients,
        })
        .then((res) => {
          if (res.hasError) {
            toast.error('Accepting new clients: Failed to update');
            // If it failed, reset the state back to what it was before
            this.acceptingNewClients = !this.acceptingNewClients;
          } else {
            toast.success('Accepting new clients: Updated');
          }

          this.acceptingNewClientsUpdating = false;
        });
    }
  };

  toggleOnlineBooking = () => {
    // If it's not already updating..
    if (!this.onlineBookingEnabledUpdating) {
      this.onlineBookingEnabled = !this.onlineBookingEnabled;
      this.onlineBookingEnabledUpdating = true;

      const { auth } = this.props;
      auth
        .updatePractitioner({
          id: auth.currentPractitioner.id,
          onlineBookingEnabled: this.onlineBookingEnabled,
        })
        .then((res) => {
          if (res.hasError) {
            toast.error('Online Calendar enabled: Failed to update');
            // If it failed, reset the state back to what it was before
            this.onlineBookingEnabled = !this.onlineBookingEnabled;
          } else {
            toast.success('Online Calendar enabled: Updated');
          }

          this.onlineBookingEnabledUpdating = false;
        });
    }
  };

  render() {
    const {
      auth: { currentPractitioner },
    } = this.props;

    const selectedOption =
      currentPractitioner.calendarSyncEnabled && currentPractitioner.hoursNotice
        ? options.find((a) => a.value === currentPractitioner.hoursNotice)
        : { label: '6 Days', value: 144 };

    const selectedTimezone = currentPractitioner.timezone
      ? timezoneOptions.find((a) => a.value === currentPractitioner.timezone)
      : { label: 'Auckland, Wellington', value: 'Pacific/Auckland' };

    return (
      <>
        {this.showReminder()}
        <Card>
          <CardBody>
            <Row>
              <Col className='col-12 col-lg-6 col-xl-4 mb-4 lg:mb-0'>
                <Switch checked={this.acceptingNewClients} onChange={this.toggleAcceptingClients}>
                  Accepting new clients
                </Switch>
              </Col>
              {this.showOnlineFlag && (
                <Col className='col-12 col-lg-6 col-xl-4'>
                  <Switch checked={this.onlineBookingEnabled} onChange={this.toggleOnlineBooking}>
                    Enable Calendar Booking
                  </Switch>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>

        {/* <Card>
          <CardBody>
            <div>
              <Label className='mr-1'>Booking time in advance</Label>
              <HelpCircle.Primary tooltipText='This is how soon your next availability can be shown to the consumer.<br/>For example, if you select 24 hours, the earliest appointment slot that<br/>would be shown would be 24 hours from your current date and time.' />
            </div>
            <Select
              classNamePrefix='react-select'
              name='specialisationTags'
              options={options}
              value={this.isSubmitting ? this.selectedOption : selectedOption}
              onChange={(value) => this.onSelectChange(value)}
              isLoading={this.isSubmitting}
              isDisabled={!currentPractitioner.calendarSyncEnabled}
            />
            {!currentPractitioner.calendarSyncEnabled ? (
              <p>Connect Clearhead to your Calendar in order to update this setting</p>
            ) : null}
          </CardBody>
        </Card> */}

        <Card>
          <CardBody>
            <div>
              <Label className='mr-1'>Timezone</Label>
              <HelpCircle.Primary tooltipText='Which timezone would you like your session times based on' />
            </div>
            <Select
              classNamePrefix='react-select'
              name='timezone'
              options={timezoneOptions}
              value={this.isSubmitting ? this.timezone : selectedTimezone}
              onChange={(value) => this.onTimezoneChange(value)}
              isLoading={this.isSubmitting}
            />
          </CardBody>
        </Card>
      </>
    );
  }
}

export default BookingsSection;
