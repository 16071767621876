import styled from 'styled-components';
import { transparentize } from 'polished';
import ReactModalAdapter from './ReactModalAdapter';
import { Close } from '../Icons';

const getOpaqueBackgroundColor = (backgroundColor: string): string =>
  `background-color: ${transparentize(0.53, backgroundColor)};`;

export const StyledFlexModal = styled(ReactModalAdapter).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
    .Overlay {
        ${getOpaqueBackgroundColor('#003473')};
        position: fixed;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1050;
        padding: 0;

        @media screen and (min-width: 768px) {
            padding-top: 20px;
        }
        @media screen and (min-width: 991px) {
            padding-top: 20px;
        }
        @media screen and (min-width: 1200px) {
            padding-top: 40px;
        }
    }
    .Modal {
        display: flex;
        flex-direction: column;
        margin: auto;
        padding: 16px;
        overflow-y: auto;

        border-radius: 0px;
        background-color: ${(props) => props.$backgroundColor || 'white'};
        outline: none;
        z-index: 1050;
        min-height: 100vh;

        @media screen and (min-width: 320px) {
            width: 100%;
        }
        @media screen and (min-width: 768px) {
            width: 600px;
            border-radius: 6px;
            min-height: auto;
        }
        @media screen and (min-width: 991px) {
            width: 500px;
            border-radius: 6px;
            min-height: auto;
        }
        @media screen and (min-width: 1200px) {
            width: 600px;
            border-radius: 6px;
            min-height: auto;
        }
    }
    &[class*='--after-open'] {

    }
    &[class*='--before-close'] {

    }
}`;

export const RegularFlexedModal = styled(ReactModalAdapter).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
    .Overlay {
        ${(props) => getOpaqueBackgroundColor(props.theme.primaryColor)};
        position: fixed;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1050;
        padding: 0;
        @media screen and (min-width: 768px) {
            padding-top: 20px;
        }
        @media screen and (min-width: 991px) {
            padding-top: 20px;
        }
        @media screen and (min-width: 1200px) {
            padding-top: 40px;
        }
    }
    .Modal {
        display: flex;
        flex-direction: column;
        margin: auto;
        overflow-y: auto;

        border-radius: 0px;
        background-color: ${(props) => props.$backgroundColor || 'white'};
        outline: none;
        z-index: 1050;
        min-height: 100vh;

        @media screen and (min-width: 320px) {
            width: 100%;
        }
        @media screen and (min-width: 768px) {
            width: 600px;
            border-radius: 6px;
            min-height: auto;
        }
        @media screen and (min-width: 991px) {
            width: 500px;
            border-radius: 6px;
            min-height: auto;
        }
        @media screen and (min-width: 1200px) {
            width: 600px;
            border-radius: 6px;
            min-height: auto;
        }
    }
    &[class*='--after-open'] {

    }
    &[class*='--before-close'] {

    }
}`;

export const StyledClose = styled(Close)`
  height: 12px;
  width: 12px;
`;

export const StyledContainer = styled.div`
  cursor: pointer;
  @media screen and (max-width: 768px) {
    position: absolute;
    right: 5px;
    padding: 10px;
    margin: -10px;
    z-index: 100;
  }
`;
