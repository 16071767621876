import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import {
  appRoutes,
  auth as authRoutes,
  marketing as marketingRoutes,
} from './index';

import AuthProvider from 'common/AuthProvider';
import DashboardLayout from 'layouts/Dashboard';
import AuthLayout from 'layouts/Auth';
import MarketingLayout from 'layouts/Marketing';
import WizardLayout from 'layouts/Wizard';
import ScrollToTop from 'components/ScrollToTop';
import AuthenticatedRoute from './authenticated_route';
import SetupWizard from 'pages/practitioner-portal/SetupWizard';
import MyAccount from 'pages/practitioner-portal/MyAccount';

const ChildRoutes = ({ layout: Layout, routes, authenticated }) => (
  <Layout>
    <Switch>
      {routes.map((category, index) =>
        category.children ? (
          // Route item with children
          category.children.map((route, index) =>
            authenticated ? (
              <AuthenticatedRoute
                key={index}
                path={route.path}
                exact
                component={route.component}
              />
            ) : (
              <Route
                key={index}
                path={route.path}
                exact
                component={route.component}
              />
            )
          )
        ) : // Route item without children
        authenticated ? (
          <AuthenticatedRoute
            key={index}
            path={category.path}
            exact
            component={category.component}
          />
        ) : (
          <Route
            key={index}
            path={category.path}
            exact
            component={category.component}
          />
        )
      )}
      <Redirect to="/auth/404" />
    </Switch>
  </Layout>
);

const Routes = () => (
  <Router>
    <AuthProvider>
      <ScrollToTop>
        <Switch>
          {/* Auth routes */}
          <Route
            path="/auth/*"
            exact
            component={() => (
              <ChildRoutes
                layout={AuthLayout}
                routes={authRoutes}
                authenticated={false}
              />
            )}
          />

          <Route
            path="/app/setup-wizard"
            exact
            component={() => (
              <WizardLayout navBar={false}>
                <Route
                  exact
                  path="/app/setup-wizard"
                  render={() => <SetupWizard />}
                />
              </WizardLayout>
            )}
          />

          {/* Dashboard routes */}
          <AuthenticatedRoute
            path="/app/my-account"
            exact
            component={() => (
              <DashboardLayout>
                <MyAccount />
              </DashboardLayout>
            )}
          />

          <Route
            path="/app/*"
            exact
            component={() => (
              <ChildRoutes
                layout={DashboardLayout}
                routes={appRoutes}
                authenticated={true}
              />
            )}
          />

          <Route
            exact
            path="/appointment-calendar"
            render={() => <Redirect to="/app/appointment-calendar" />}
          />
          <Route
            exact
            path="/app"
            render={() => <Redirect to="/app/appointment-calendar" />}
          />

          {/* Marketing routes */}
          <Route
            path="/*"
            exact
            component={() => (
              <ChildRoutes
                layout={MarketingLayout}
                routes={marketingRoutes}
                authenticated={false}
              />
            )}
          />
        </Switch>
      </ScrollToTop>
    </AuthProvider>
  </Router>
);

export default Routes;
