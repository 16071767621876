import styled, { css } from 'styled-components';

export type BaseButtonProps = {
  $iconPosition: 'left' | 'right';
};

export const BASE_BUTTON_MODIFIERS = css<BaseButtonProps>`
  &.naked {
    min-height: auto;
    min-width: auto;
    width: auto;
    height: auto;
    border: none;
    margin: 0;
    padding: 0;
    background: none;
    text-decoration: none;
    line-height: 1;
    &:hover {
      cursor: pointer;
    }
  }

  &.small {
    min-height: 40px;
    padding: 0 12px;
  }

  &.icon {
    display: grid;
    ${props => (props.$iconPosition === 'left' ? 'grid-template-columns: 20px 1fr;' : '')}
    ${props =>
      props.$iconPosition === 'right'
        ? 'grid-template-columns: 1fr 20px;'
        : ''}
    column-gap: 10px;
    justify-content: center;
    align-items: center;
  }

  &.square {
    border-radius: 0px;
  }

  &.content {
    min-width: auto;
    width: auto;
  }

  &.full {
    min-width: 100%;
    width: 100%;
  }
`;

const PRIMARY_BUTTON_MODIFIERS = css`
  ${BASE_BUTTON_MODIFIERS}
  &.inverted {
    --btn-primary-background-color: transparent;
    --btn-primary-border-color: var(--primary-100);
    --btn-primary-text-color: var(--primary-100);
    &:hover {
      --btn-primary-background-color: transparent;
      --btn-primary-border-color: var(--primary-dark-100);
      --btn-primary-text-color: var(--primary-dark-100);
    }
    &:focus {
      --btn-primary-background-color: transparent;
      --btn-primary-border-color: var(--primary-dark-100);
      --btn-primary-text-color: var(--primary-dark-100);
    }
    &:disabled,
    [disabled] {
      --btn-primary-background-color: transparent;
      --btn-primary-border-color: var(--btn-primary-disabled-color);
      --btn-primary-text-color: var(--btn-primary-disabled-color);
    }
  }
`;

const SECONDARY_BUTTON_MODIFIERS = css`
  ${BASE_BUTTON_MODIFIERS}
  &.inverted {
    --btn-secondary-background-color: transparent;
    --btn-secondary-border-color: var(--secondary-100);
    --btn-secondary-text-color: var(--secondary-100);
    &:hover {
      --btn-secondary-background-color: transparent;
      --btn-secondary-border-color: var(--secondary-dark-100);
      --btn-secondary-text-color: var(--secondary-dark-100);
    }
    &:focus {
      --btn-secondary-background-color: transparent;
      --btn-secondary-border-color: var(--secondary-dark-100);
      --btn-secondary-text-color: var(--secondary-dark-100);
    }
    &:disabled,
    [disabled] {
      --btn-secondary-background-color: transparent;
      --btn-secondary-border-color: var(--btn-secondary-disabled-color);
      --btn-secondary-text-color: var(--btn-secondary-disabled-color);
    }
  }
`;

const DANGER_BUTTON_MODIFIERS = css`
  ${BASE_BUTTON_MODIFIERS}
  &.inverted {
    --btn-danger-background-color: transparent;
    --btn-danger-border-color: var(--danger-100);
    --btn-danger-text-color: var(--danger-100);
    &:hover {
      --btn-danger-background-color: transparent;
      --btn-danger-border-color: var(--danger-dark-100);
      --btn-danger-text-color: var(--danger-dark-100);
    }
    &:focus {
      --btn-danger-background-color: transparent;
      --btn-danger-border-color: var(--danger-dark-100);
      --btn-danger-text-color: var(--danger-dark-100);
    }
    &:disabled,
    [disabled] {
      --btn-danger-background-color: transparent;
      --btn-danger-border-color: var(--btn-danger-disabled-color);
      --btn-danger-text-color: var(--btn-danger-disabled-color);
    }
  }
`;

export const BaseButtonCss = css`
  min-height: 45px;
  min-width: auto;
  border-radius: 22.5px;
  padding: 0 20px;
  line-height: 1.2;
  letter-spacing: 0.29px;
  font: var(--font-button);
  &:hover,
  &:focus {
    cursor: pointer;
  }
  &:active,
  &:focus {
    outline: none;
  }
  &:disabled,
  [disabled] {
    cursor: no-drop;
  }

  @media screen and (min-width: 400px) {
    min-width: auto;
  }

  @media screen and (min-width: 600px) {
    min-width: 170px;
  }
`;

export const BaseButton = styled.button`
  ${BaseButtonCss}
  ${BASE_BUTTON_MODIFIERS}
`;

export const PrimaryButton = styled.button`
  ${BaseButtonCss}
  border-width: 1px;
  border-style: solid;
  background-color: var(--btn-primary-background-color);
  border-color: var(--btn-primary-border-color);
  color: var(--btn-primary-text-color);

  &:hover {
    --btn-primary-background-color: var(--primary-dark-100);
    --btn-primary-border-color: var(--primary-dark-100);
    --btn-primary-text-color: var(--base-100);
  }
  &:focus {
    --btn-primary-background-color: var(--primary-dark-100);
    --btn-primary-border-color: var(--primary-dark-100);
    --btn-primary-text-color: var(--base-100);
  }
  &:disabled,
  [disabled] {
    --btn-primary-background-color: var(--btn-primary-disabled-color);
    --btn-primary-border-color: var(--btn-primary-disabled-color);
    --btn-primary-text-color: var(--base-100);
  }

  ${PRIMARY_BUTTON_MODIFIERS}
`;

export const SecondaryButton = styled.button`
  ${BaseButtonCss}
  border-width: 1px;
  border-style: solid;
  background-color: var(--btn-secondary-background-color);
  border-color: var(--btn-secondary-border-color);
  color: var(--btn-secondary-text-color);

  &:hover {
    --btn-secondary-background-color: var(--secondary-dark-100);
    --btn-secondary-border-color: var(--secondary-dark-100);
    --btn-secondary-text-color: var(--base-100);
  }
  &:focus {
    --btn-secondary-background-color: var(--secondary-dark-100);
    --btn-secondary-border-color: var(--secondary-dark-100);
    --btn-secondary-text-color: var(--base-100);
  }
  &:disabled,
  [disabled] {
    --btn-primary-background-color: var(--btn-secondary-disabled-color);
    --btn-primary-border-color: var(--btn-secondary-disabled-color);
    --btn-primary-text-color: var(--base-100);
  }
  ${SECONDARY_BUTTON_MODIFIERS}
`;

export const DangerButton = styled.button`
  ${BaseButtonCss}
  border-width: 1px;
  border-style: solid;
  background-color: var(--btn-danger-background-color);
  border-color: var(--btn-danger-border-color);
  color: var(--btn-danger-text-color);

  &:hover {
    --btn-danger-background-color: var(--danger-dark-100);
    --btn-danger-border-color: var(--danger-dark-100);
    --btn-danger-text-color: var(--base-100);
  }
  &:focus {
    --btn-danger-background-color: var(--danger-dark-100);
    --btn-danger-border-color: var(--danger-dark-100);
    --btn-danger-text-color: var(--base-100);
  }
  &:disabled,
  [disabled] {
    --btn-primary-background-color: var(--btn-danger-disabled-color);
    --btn-primary-border-color: var(--btn-danger-disabled-color);
    --btn-primary-text-color: var(--base-100);
  }

  ${DANGER_BUTTON_MODIFIERS}
`;

export const BlackButton = styled.button`
  ${BaseButtonCss}
  border-width: 1px;
  border-style: solid;
  background-color: var(--btn-black-background-color);
  border-color: var(--btn-black-border-color);
  color: var(--btn-black-text-color);

  &:hover {
    --btn-black-background-color: var(--black-100);
    --btn-black-border-color: var(--black-100);
    --btn-black-text-color: var(--base-100);
  }
  &:focus {
    --btn-black-background-color: var(--black-100);
    --btn-black-border-color: var(--black-100);
    --btn-black-text-color: var(--base-100);
  }
  &:disabled,
  [disabled] {
    --btn-black-background-color: var(--btn-black-disabled-color);
    --btn-black-border-color: var(--btn-black-disabled-color);
    --btn-black-text-color: var(--base-100);
  }
  ${BASE_BUTTON_MODIFIERS}
`;

export const WhiteButton = styled.button`
  ${BaseButtonCss}
  border-width: 1px;
  border-style: solid;
  background-color: var(--btn-white-background-color);
  border-color: var(--btn-white-border-color);
  color: var(--btn-white-text-color);

  &:hover {
    --btn-white-background-color: var(--base-100);
    --btn-white-border-color: var(--base-100);
    --btn-white-text-color: var(--blue-100);
  }
  &:focus {
    --btn-white-background-color: var(--base-100);
    --btn-white-border-color: var(--base-100);
    --btn-white-text-color: var(--blue-100);
  }
  &:disabled,
  [disabled] {
    --btn-white-background-color: var(--btn-white-disabled-color);
    --btn-white-border-color: var(--btn-white-disabled-color);
    --btn-white-text-color: var();
  }
  ${BASE_BUTTON_MODIFIERS}
`;
