import styled from 'styled-components';
import TextareaAutosizeDefault from 'react-autosize-textarea';

export const TextareaAutosize = styled(TextareaAutosizeDefault)`
	display: block;
	width: 100%;
	padding: 0.35rem 1rem;
	font-size: 1rem;
	border-radius: 0.2rem;
	border: 1px solid hsl(0, 0%, 80%);
	line-height: 2;
	resize: none;

	:focus {
		border-color: #adc9e9;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(73, 135, 206, 0.25);
	}

	&.disabled {
		background-color: hsl(0, 0%, 95%);
		border: 1px solid hsl(0, 0%, 90%);
	}
`;
