// @flow
import React from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import {
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Col,
  Row,
  Button,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import PhoneInputField from '../../../common/components/PhoneInputField';

const schema = Yup.object().shape({
  emailAddress: Yup.string().email('Please enter a valid email').required('Required'),
  phoneNumber: Yup.string().required('Required'),
});

const ContactInfoForm = inject(
  'auth',
  'practitioners'
)(
  withRouter(
    observer((props) => {
      const { auth, onComplete = () => {}, mode = 'read-only' } = props;

      const formRef = React.useRef(null);

      React.useEffect(() => {
        if (mode === 'read-only' && formRef.current?.dirty) {
          formRef.current.resetForm();
        }
      }, [mode]);

      const disabled = mode === 'read-only';

      const currentPractitioner = auth.currentPractitioner || {};
      let websitePrefix = 'https://';
      let websiteSuffix = '';

      if (currentPractitioner.websiteAddress) {
        websitePrefix = currentPractitioner.websiteAddress.startsWith('http://')
          ? 'http://'
          : 'https://';
        websiteSuffix = currentPractitioner.websiteAddress.replace(websitePrefix, '');
      }

      const initialFormState = {
        websitePrefix: websitePrefix,
        websiteSuffix: websiteSuffix,
        addressDropdownOpen: false,
        ...currentPractitioner,
      };

      const onSubmit = (values, actions) => {
        const { emailAddress, phoneNumber, websitePrefix, websiteSuffix } = values;

        let websiteAddress = '';
        if (websiteSuffix) {
          const newSuffix = websiteSuffix.replace('http://', '').replace('https://', '');
          websiteAddress = websitePrefix + newSuffix;
        }

        const data = {
          id: auth.currentPractitioner.id,
          emailAddress,
          phoneNumber,
          websiteAddress,
        };

        auth.updatePractitioner(data).then((response) => {
          if (!response.hasError) {
            onComplete();
            toast.success('Contact info updated');
          } else {
            response.errors.forEach((err) => {
              actions.setFieldTouched(err.field, true, false);
              actions.setFieldError(err.field, err.defaultMessage);
            });
            actions.setStatus({ message: response.message });
            toast.error(response.message);
          }
          actions.setSubmitting(false);
        });
      };

      return (
        <Formik
          innerRef={formRef}
          initialValues={initialFormState}
          validationSchema={schema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {(formik) => {
            const {
              values,
              handleChange,
              handleSubmit,
              errors,
              status,
              touched,
              handleBlur,
              isSubmitting,
              setFieldValue,
            } = formik;

            return (
              <Form onSubmit={handleSubmit}>
                <Row form>
                  <Col className='col-12 col-lg-6'>
                    <FormGroup>
                      <Label> Contact Email *</Label>
                      <Input
                        bsSize='lg'
                        name='emailAddress'
                        value={values.emailAddress}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        invalid={touched.emailAddress && errors.emailAddress}
                        disabled={true}
                      />
                      {touched.emailAddress && errors.emailAddress && (
                        <FormFeedback>{errors.emailAddress}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>

                  <Col className='col-12 col-lg-6'>
                    <FormGroup>
                      <Label>Phone Number *</Label>
                      <PhoneInputField
                        name='phoneNumber'
                        value={values.phoneNumber}
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        disabled={disabled}
                        invalid={touched.phoneNumber && !!errors.phoneNumber}
                        errors={errors.phoneNumber}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col>
                    <FormGroup>
                      <Label>Website Address</Label>

                      <InputGroup>
                        <Dropdown
                          addonType='prepend'
                          isOpen={values.addressDropdownOpen}
                          toggle={() => {
                            if (!disabled) {
                              setFieldValue('addressDropdownOpen', !values.addressDropdownOpen);
                            }
                          }}
                          title='Http Dropdown'
                        >
                          <DropdownToggle caret disabled={disabled}>
                            {values.websitePrefix}
                          </DropdownToggle>
                          <DropdownMenu>
                            {['http://', 'https://'].map((prefix) => (
                              <DropdownItem
                                key={prefix.replace(/[^a-z]/g, '')}
                                onClick={() => setFieldValue('websitePrefix', prefix)}
                              >
                                {prefix}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>

                        <Input
                          bsSize='lg'
                          name='websiteSuffix'
                          value={values.websiteSuffix}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          invalid={touched.websiteSuffix && errors.websiteSuffix}
                          disabled={disabled}
                        />
                      </InputGroup>

                      {touched.websiteSuffix && errors.websiteSuffix && (
                        <FormFeedback>{errors.websiteSuffix}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                {status?.message && <FormFeedback>{status.message}</FormFeedback>}

                {!disabled && (
                  <div className='text-center mt-3'>
                    <Button color='primary' size='lg' type='submit' disabled={isSubmitting}>
                      Save
                    </Button>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      );
    })
  )
);

export default ContactInfoForm;
