/* Third party */
import React, { Component } from "react";
import { Col, Container, Row, NavLink } from "reactstrap";
import { observer, inject } from "mobx-react";
import { observable } from "mobx";
import * as Icon from "react-feather";
import { withKeycloak } from '@react-keycloak-fork/web';

import KeycloakButton from '../../../common/KeycloakButton';

/* Components */
import { CHLogoWhite, CHTextWhite } from "../Logo";

import {
  NavigationContainer,
  NavList,
  DropdownContent,
  Dropdown,
  DropdownText
} from "./styles";

/* Functions */

@withKeycloak
@inject('auth')
@observer
class Navbar extends Component {
  @observable showDropdown = false;
  @observable scrollPosition = 0;

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  };

  toggleDropdown = () => {
    this.showDropdown = !this.showDropdown;
  };

  handleScroll = (e) => {
    this.scrollPosition = window.scrollY;
  };

  render() {
    const { keycloak } = this.props;
    const redirectUri = new URL(`${window.location.origin}/app`);
    const authButtonText = keycloak.authenticated ? 'My Provider Portal' : 'Sign In';
    const authButtonLink = keycloak.authenticated
      ? '/app'
      : keycloak.createLoginUrl({ redirectUri });

    return (
      <NavigationContainer scrollposition={this.scrollPosition}>
        <Container>
          <Row>
            <Col className="d-flex justify-content-between pt-4 pb-4">
              <a
                className="d-flex justify-content-center align-items-center"
                href="/"
              >
                <CHLogoWhite className="nav-logo" height={30} width={30} />
                <CHTextWhite className="nav-logo d-none d-lg-block" />
              </a>
              <NavList className="d-none d-md-flex">
                <NavLink className='px-4 py-2' href="/about">About</NavLink>
                <NavLink className='px-4 py-2' href="/faq">FAQ</NavLink>
                <NavLink className='px-4 py-2' href="/contact-us">Contact Us</NavLink>
              </NavList>
              <NavList>
                {this.showDropdown ? (
                  <Icon.X
                    alt="Close"
                    onClick={() => this.toggleDropdown()}
                    className="menu-icon mr-3"
                  />
                ) : (
                  <Icon.Menu
                    alt="Menu"
                    onClick={() => this.toggleDropdown()}
                    className="menu-icon d-sm-flex d-md-none mr-3"
                  />
                )}
                <KeycloakButton />
                {this.showDropdown ? (
                  <Dropdown className="d-sm-flex d-md-none">
                    <DropdownContent>
                      <Row className="mt-4">
                        <Col className="text-center">
                          <DropdownText href="/">Home</DropdownText>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="text-center">
                          <DropdownText href="/about">About</DropdownText>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <DropdownText href="/faq">FAQ</DropdownText>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <DropdownText href="/contact-us">
                            Contact Us
                          </DropdownText>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <DropdownText href={authButtonLink}>
                            {authButtonText}
                          </DropdownText>
                        </Col>
                      </Row>
                    </DropdownContent>
                  </Dropdown>
                ) : null}
              </NavList>
            </Col>
          </Row>
        </Container>
      </NavigationContainer>
    );
  }
}

export default Navbar;
