import React from 'react';
import { observer, inject } from 'mobx-react';
import { FooterNav, wizardContainerStyle } from '../WizardForm/styles';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import { WizardFormContext } from '../index';

const ProfileSetupIntroStep = inject('auth')(
  observer(() => {
    const { onPrevious, onNext, currentStep } = React.useContext(WizardFormContext);
    const [isLoading, setIsLoading] = React.useState(false);

    const onSubmit = React.useCallback(async () => {
      setIsLoading(true);
      await onNext();
    }, [onNext]);

    const goBack = async () => {
      setIsLoading(true);
      await onPrevious();
    };

    return (
      <>
        <div className={wizardContainerStyle}>
          <div className='flex flex-col w-full items-center'>
            <div className='max-w-lg text-center'>
              <Heading className='text-dark-blue mb-8' as='h3'>
                Let's set up your profile
              </Heading>
              <Text className='mb-2'>It takes about 5-10 minutes to complete.</Text>
              <Text className='mb-12'>
                The more you complete, the higher your ranking will be in our search results.
              </Text>
            </div>

            <FooterNav className='md:mt-16 xs:mt-8'>
              <div className='flex justify-start'>
                {currentStep > 0 && (
                  <Button
                    variant='primary'
                    modifiers={'inverted'}
                    onClick={goBack}
                    disabled={isLoading}
                  >
                    Back
                  </Button>
                )}
              </div>
              <div className='flex justify-end'>
                <Button
                  variant='primary'
                  onClick={onSubmit}
                  data-test-id='complete-hear-about-us'
                  disabled={isLoading}
                >
                  Next
                </Button>
              </div>
            </FooterNav>
          </div>
        </div>
      </>
    );
  })
);
export default observer(ProfileSetupIntroStep);
