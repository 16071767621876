export const visibilityOptions = [
  // Benoit 10/03: Note these questions are currently shown for all appointments, see below for more info
  { label: 'First Appointment (Required)', value: 'FIRST_ONLY_REQUIRED' },
  { label: 'First Appointment (Not Required)', value: 'FIRST_ONLY_OPTIONAL' },
  // Benoit 10/03: Removing Subsequent Appointment questions as it has only be used by one therapist
  // in production and the API currently considers that all appointments are the first appointment
  // because of missing implementation that isn't straightforward to fix
  // { label: 'Subsequent Appointment (Required)', value: 'SUBSEQUENT_ONLY_REQUIRED' },
  // { label: 'Subsequent Appointment (Not Required)', value: 'SUBSEQUENT_ONLY_OPTIONAL' },
  { label: 'All Appointments (Required)', value: 'ALL_REQUIRED' },
  { label: 'All Appointments (Not Required)', value: 'ALL_OPTIONAL' },
];

export const typeOptions = [
  { label: 'Freeform', value: 'Freeform' },
  { label: 'Multichoice', value: 'Multichoice' },
  { label: 'Checkbox', value: 'Checkbox' },
  { label: 'Date', value: 'Date' },
  { label: 'Gender', value: 'Gender' },
  // Benoit 06/03: Removing this as 90% of the time it is used as a duplicate phone number field
  // { label: 'Phone Number', value: 'PhoneNumber' },
];
