import React from 'react';
import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';
import 'react-phone-number-input/style.css';

interface IPhoneInputFieldProps {
  name?: string;
  defaultCountry?: string;
  disabled?: boolean;
  errors?: string;
  onBlur?: () => {};
  // This is only used if you want to capture the value outside of Formik
  onValueChange?: (value: string) => {};
  invalid?: boolean;
  placeHolder?: string;
  setFieldValue?: (name: string, value: string) => void;
  value?: string;
}

// Match Bootstrap style
const BootstrapStylePhoneInput = styled(PhoneInput)`
  --PhoneInputCountryFlag-height: 1.9rem;
  --PhoneInputCountrySelectArrow-color: rgb(0, 0, 0);
  .PhoneInputCountry {
    margin-left: 2px; /* Fix offset */
  }
  .PhoneInputInput {
    /* From .form-control-lg Boostrap class */
    height: calc(2.7rem + 2px);
    padding: 0.35rem 1rem;
    font-size: 1rem;
    line-height: 2;
    border: 1px solid #ced4da;
    border-radius: 0.2rem;
    /* From .form-control Boostrap class */
    color: #495057;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .PhoneInputInput:focus {
    border-color: #adc9e9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(73, 135, 206, 0.25);
  }
  /* Disabled classes */
  &.PhoneInput--disabled .PhoneInputCountrySelect {
    opacity: 0 !important;
  }
  &.PhoneInput--disabled .PhoneInputCountryIcon {
    opacity: 0.5;
  }
  &.PhoneInput--disabled .PhoneInputInput {
    background-color: #e9ecef;
  }
  /* Invalid classes */
  &.is-invalid .PhoneInputInput {
    border-color: #f44455;
    padding-right: 1.8125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f44455' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f44455' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.125rem);
    background-size: calc(0.75em + 0.25rem) calc(0.75em + 0.25rem);
  }
  &.is-invalid .PhoneInputInput:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 68, 85, 0.25);
  }
`;

// Mimic component from reactstrap and align message with input
const FormFeedback = styled.div`
  display: block;
  width: 100%;
  margin-left: 3.9rem;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f44455;
`;

// This component has 2 objectives:
// - Bring Formik support to react-phone-number-input
// - Style react-phone-number-input to match our current Bootstrap style
const PhoneInputField = (props: IPhoneInputFieldProps) => {
  const {
    name = '',
    defaultCountry = 'NZ',
    disabled = false,
    errors,
    onBlur = () => {},
    onValueChange = () => {},
    invalid = false,
    placeHolder = 'Enter phone number',
    setFieldValue = () => {},
    value,
  } = props;

  const onChange = (phoneNumber: string) => {
    setFieldValue(name, phoneNumber);

    if (onValueChange !== null) {
      onValueChange(phoneNumber);
    }
  };

  return (
    <>
      <BootstrapStylePhoneInput
        className={invalid ? 'is-invalid' : undefined}
        name={name}
        placeholder={placeHolder}
        defaultCountry={defaultCountry}
        initialValueFormat='national'
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      {errors && <FormFeedback>{errors}</FormFeedback>}
    </>
  );
};

export default PhoneInputField;
