import * as React from 'react';
import Link from '../Link';
import clsx from 'clsx';

type FreshdeskLinkProps = {
  children?: React.ReactNode;
  className?: string;
};

const FreshdeskLink: React.FC<FreshdeskLinkProps> = ({ children, className }) => {
  const toggleFreshdeskWidget = () => {
    try {
      (window as any).FreshworksWidget('open');
    } catch (error) {
      console.error("Couldn't toggle Freshworks widget");
    }
  };

  return (
    <Link
      className={clsx('text-sm text-primary-blue-100', className)}
      onClick={toggleFreshdeskWidget}
    >
      {children}
    </Link>
  );
};

export default FreshdeskLink;
