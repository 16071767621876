import { ITypeScale, ITypeWeight } from '../@types';

export const primaryFont =
  'Source Sans Pro, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif';

export const typeScale: ITypeScale = {
  header1: '1.8rem',
  header2: '1.6rem',
  header3: '1.4rem',
  header4: '1.2rem',
  header5: '1.1rem',
  header6: '1rem',
  paragraph: '1rem',
  helperText: '0.8rem',
  copyrightText: '0.7rem',
};

export const typeWeight: ITypeWeight = {
  light: 300,
  regular: 400,
  semiBold: 600,
  bold: 700,
};
