import moment from 'moment';

const formatDate = (date: string) => {
  const momentDate = moment(date);
  if (momentDate.isValid()) {
    return momentDate.format('MMMM Do YYYY');
  }
  return 'N/A';
};

export default formatDate;
