import Requestor from 'utilities/requestor';

class BaseService {
  maxRetryCount = 1;

  makeRequest = (request) => {
    return new Promise((resolve) => {
      request().then((response) => {
        resolve(response);
      });
    }).catch((e) => {
      toast.error('Sorry, something went wrong.');
    });
  };

  get = (url, headers = {}, params = {}) => {
    return Requestor.get(url, headers, params);
  };

  post = (url, data = {}, headers = {}, params = {}) => {
    return Requestor.post(url, data, headers, params);
  };

  put = (url, data = {}, headers = {}, params = {}) => {
    return Requestor.put(url, data, headers, params);
  };

  delete = (url, data = {}, headers = {}, params = {}) => {
    return Requestor.delete(url, data, headers, params);
  };
}

export default new BaseService();
