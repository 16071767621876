import * as React from 'react';
import { ModifierKeys } from 'styled-components-modifiers';
import {
  BaseButton,
  BlackButton,
  WhiteButton,
  DangerButton,
  PrimaryButton,
  SecondaryButton,
} from './styles';

export interface IButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  variant?: 'none' | 'primary' | 'secondary' | 'danger' | 'black' | 'white';
  disabled?: boolean;
  loading?: boolean;
  modifiers?: ModifierKeys;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
}

const getVariant = (variant: string): React.ElementType => {
  switch (variant) {
    case 'secondary':
      return SecondaryButton;
    case 'danger':
      return DangerButton;
    case 'black':
      return BlackButton;
    case 'white':
      return WhiteButton;
    case 'primary':
      return PrimaryButton;
    case 'none':
    default:
      return BaseButton;
  }
};

const Button: React.FC<IButtonProps> = ({
  children,
  disabled,
  loading,
  className = '',
  modifiers = '',
  icon = undefined,
  iconPosition = '',
  variant = 'none',
  ...rest
}) => {
  const ButtonComponent = getVariant(variant);
  const classes = Array.isArray(modifiers) ? modifiers.join(' ') : modifiers;
  return (
    <ButtonComponent
      className={`${classes} ${className}`}
      role="button"
      disabled={disabled || loading}
      $iconPosition={iconPosition}
      {...rest}
    >
      {iconPosition === 'left' ? icon : null}
      {loading ? 'Loading...' : children}
      {iconPosition === 'right' ? icon : null}
    </ButtonComponent>
  );
};

export default Button;
