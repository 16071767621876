import { SVGProps } from 'react';
const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.54712 23L12.412 16.783L22.1373 23V1H2.54712V23Z'
      stroke='currentColor'
      strokeWidth='1.986'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default ArrowRight;
