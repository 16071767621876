import React from 'react';
import { default as ReactSwitch } from 'react-switch';

type SwitchProps = {
  checked?: boolean;
  onChange?: () => void;
  children?: JSX.Element;
};

const Switch: React.FC<SwitchProps> = ({ checked = false, onChange = () => void 0, children }) => (
  <label className='d-flex align-items-center mb-0 cursor-pointer'>
    <ReactSwitch checked={checked} onChange={onChange} onColor='#23cba7' height={24} width={50} />
    <span className='h5 ml-2 mb-0'>{children}</span>
  </label>
);

export default Switch;
