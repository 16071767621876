import React from 'react';
import styled from 'styled-components';
import { Button, Modal, ModalBody } from 'reactstrap';

import exampleImg from 'assets/img/photos/Manage-Availability-Example.jpg';

const ExampleImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const InstructionModal = ({ isModalOpen, toggleModal }) => {
  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal} size="md" centered>
      <ModalBody className="d-flex flex-column">
        <h4 className="text-center font-weight-bold">
          Manage your working hours
        </h4>
        <p className="text-center h5">
          Set the hours you are normally available for therapy every week.
        </p>
        <p className="text-center h5">
          Users can only book available therapy sessions within these hours for
          the services and location you specify, you can change them at any
          time.
        </p>
        <ExampleImage src={exampleImg} className="mt-3" />

        <Button
          size="md"
          className="d-block mx-auto mt-3"
          onClick={toggleModal}
        >
          Continue
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default InstructionModal;
