import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Table,
} from 'reactstrap';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import debounce from 'debounce-promise';
import { toast } from 'react-toastify';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';

import ClientForm from 'modules/clients/components/ClientForm';
import { ViewBookingModal, Loading } from 'common';
import SelectedClient from './selected_client';
import './styles.css';
import ClearheadHoverLogo from './clearhead_hover_logo';

const TableHead = styled.th`
  width: 36px;
  padding: 12px 6px;
`;
const TableDefinition = styled.td`
  width: 36px;
  padding: 12px 6px;
`;

@inject('clients', 'bookings', 'auth')
@observer
class Clients extends Component {
  @observable loading = true;
  @observable appointmentsLoading = false;
  @observable isModalOpen = false;
  @observable formState = null;
  @observable submitType = null;
  @observable selectedClient = null;
  @observable deletedClientsList = new Set();
  @observable pageOffset = 0;
  @observable searchQuery = null;
  @observable loadingMore = false;

  @observable viewBookingModalOpen = false;
  @observable selectedBooking = {};

  constructor(props) {
    super(props);

    props.clients
      .getClients({
        pageOffset: 0,
        searchQuery: this.searchQuery,
        pageSize: 10,
      })
      .then((res) => {
        this.loading = false;
      });

    const wait = 1000; // milliseconds
    const searchQuery = (inputValue) => this.onSearchQuery(inputValue);
    this.debouncedSearchQuery = debounce(searchQuery, wait, {
      leading: false,
    });
  }

  addClient = () => {
    this.isModalOpen = true;
    this.formState = { firstName: '', lastName: '' };
    this.submitType = 'create';
  };

  editClient = (client) => {
    this.isModalOpen = true;
    this.formState = client;
    this.submitType = 'edit';
  };

  deleteClient = (client) => {
    const { clients } = this.props;

    this.selectedClient = null;
    this.deletedClientsList = new Set(this.deletedClientsList).add(client.id);

    clients.deleteClient(client).then((res) => {
      let newDeletedClientsList = new Set(this.deletedClientsList);
      newDeletedClientsList.delete(client.id);
      this.deletedClientsList = newDeletedClientsList;

      if (!res.hasError) {
        toast.success('Client deleted');
      }
    });
  };

  toggleModal = () => {
    this.isModalOpen = !this.isModalOpen;
  };

  selectClient = (client) => {
    const { clients } = this.props;
    this.appointmentsLoading = true;
    clients.getClientDetails(client).then((res) => {
      this.appointmentsLoading = false;

      this.selectedClient = res.data.responseObject;

      if (window.innerWidth < 1200) {
        const clientCard = document.getElementById('clientCard');
        clientCard &&
          window.scrollTo(0, clientCard.getBoundingClientRect().top + window.scrollY - 20);
      }
    });

    if (window.innerWidth < 1200) {
      window.scrollTo(0, Math.max(window.innerHeight, document.body.scrollHeight));
    } else {
      // If the client card would be outside of the viewport, scroll to it
      window.setTimeout(this.scrollToClientTop, 0);
    }
  };

  scrollToClientTop = () => {
    const clientEl = document.getElementById('clientCard');

    if (clientEl) {
      const rect = clientEl.getBoundingClientRect();
      // Get the client cards distance from the top of the page
      const clientDistanceFromTop = window.pageYOffset + rect.top;
      const scrollY = window.scrollY;
      const pageHeight = window.innerHeight;

      if (clientDistanceFromTop < scrollY || clientDistanceFromTop > scrollY + pageHeight) {
        window.scrollTo(0, clientDistanceFromTop - 20);
      }
    }
  };

  getClientsList = (clients) => {
    if (clients && clients.length > 0) {
      const filteredClients = clients.filter((client) => !this.deletedClientsList.has(client.id));
      return filteredClients.map((client) => {
        const {
          firstName,
          lastName,
          phoneNumber,
          email,
          nextSessionUTC,
          previousSessionUTC,
          clearheadAccount = false,
        } = client;
        const nextSessionFormatted = moment
          .utc(nextSessionUTC)
          .tz(this.props.auth.currentPractitioner.timezone)
          .format('DD/MM/YY h:mma');
        const prevSessionFormatted = moment
          .utc(previousSessionUTC)
          .tz(this.props.auth.currentPractitioner.timezone)
          .format('DD/MM/YY h:mma');

        const selectedId = this.selectedClient && this.selectedClient.id;
        const selected = selectedId ? selectedId === client.id : false;

        return (
          <tr
            key={client.id}
            className={`cursor-pointer ${selected ? 'selected' : ''}`}
            onClick={this.selectClient.bind(this, client)}
          >
            <TableDefinition>
              {clearheadAccount ? <ClearheadHoverLogo key={client.id} /> : <div />}
            </TableDefinition>
            <td>{`${firstName} ${lastName}`}</td>
            <td className='d-none d-md-table-cell'>
              <div>{phoneNumber}</div>
              <div>{email}</div>
            </td>
            <td className='d-none d-md-table-cell'>
              <div>{`Next Session: ${nextSessionUTC ? nextSessionFormatted : ''}`}</div>
              <div>{`Prev Session: ${previousSessionUTC ? prevSessionFormatted : ''}`}</div>
            </td>
          </tr>
        );
      });
    }
  };

  loadMoreClients = () => {
    const { clients } = this.props;

    this.pageOffset = this.pageOffset + 1;
    this.loadingMore = true;
    clients
      .getClients({
        pageOffset: this.pageOffset,
        searchQuery: this.searchQuery,
        pageSize: 10,
      })
      .then((res) => (this.loadingMore = false));
  };

  onSearchQuery = (searchQuery) => {
    const {
      clients: { getClients },
    } = this.props;

    this.loading = true;
    this.searchQuery = searchQuery;
    this.pageOffset = 0;
    getClients({
      pageOffset: 0,
      searchQuery: this.searchQuery,
      pageSize: 10,
    }).then((res) => {
      this.loading = false;
    });
  };

  onSelectBooking = (booking) => {
    const { bookings } = this.props;

    bookings.getBookingData(booking.id).then((data) => {
      this.selectedBooking = bookings.fetchedBooking;
      this.viewBookingModalOpen = true;
    });
  };

  toggleViewBookingModal = () => {
    this.viewBookingModalOpen = !this.viewBookingModalOpen;
  };

  clientFormComplete = (allClients) => {
    if (this.selectedClient) {
      // Reselect the selected client
      const newSelectedClient = allClients.find((c) => c.id === this.selectedClient.id);
      this.selectedClient = newSelectedClient;
    }
    this.toggleModal();
  };

  render() {
    const {
      clients: { allClients, lastPage },
    } = this.props;

    return (
      <>
        <Container fluid>
          <div className='d-flex justify-content-between align-items-center mb-4'>
            <Heading className='text-xl text-dark-blue'>Clients</Heading>
            <Button
              color='primary'
              size='md'
              // style={{ height: '30px', width: '120px' }}
              onClick={this.addClient}
              className='rounded-full font-bold h-8'
            >
              Create Contact
            </Button>
          </div>

          <Row>
            <Col lg='12' xl={this.selectedClient ? '8' : '12'}>
              <Card>
                <CardHeader>
                  <Input
                    placeholder='Search'
                    className='d-inline w-100'
                    onChange={(e) => this.debouncedSearchQuery(e.target.value)}
                  />
                </CardHeader>
                <CardBody className='d-flex flex-column'>
                  {this.loading ? (
                    <div>
                      <Loading />
                    </div>
                  ) : (
                    <Table id='clientsTable' className='mb-0' striped hover>
                      <thead>
                        <tr>
                          <TableHead className='border-top-0'> </TableHead>
                          <th className='border-top-0'>Name</th>
                          <th className='border-top-0 d-none d-md-table-cell'>Contact Info</th>
                          <th className='border-top-0 d-none d-md-table-cell'>Activity</th>
                        </tr>
                      </thead>
                      <tbody>{this.getClientsList(allClients)}</tbody>
                    </Table>
                  )}

                  {lastPage === false && !this.loading && (
                    <Button
                      size='lg'
                      color='primary'
                      className='rounded-full bg-transparent text-primary-blue-100 font-bold mt-3 mx-auto'
                      disabled={this.loadingMore}
                      onClick={this.loadMoreClients}
                    >
                      Load more
                    </Button>
                  )}
                </CardBody>
              </Card>
            </Col>

            {this.selectedClient && (
              <Col lg='12' xl='4'>
                <SelectedClient
                  client={this.selectedClient}
                  editClient={this.editClient.bind(this)}
                  deleteClient={this.deleteClient.bind(this)}
                  appointmentsLoading={this.appointmentsLoading}
                  selectBooking={this.onSelectBooking}
                />
              </Col>
            )}
          </Row>
        </Container>

        <Modal isOpen={this.isModalOpen} toggle={this.toggleModal} size='lg' centered>
          <ModalHeader className='bg-light' toggle={this.toggleModal}>
            {`${this.submitType === 'create' ? 'Add' : 'Edit'} Client`}
          </ModalHeader>

          <ModalBody className='p-3'>
            <ClientForm
              formState={this.formState}
              submitType={this.submitType}
              onComplete={this.clientFormComplete.bind(this, allClients)}
            />
          </ModalBody>
        </Modal>

        <ViewBookingModal
          booking={this.selectedBooking}
          bookingStatus={this.selectedBooking.status}
          isModalOpen={this.viewBookingModalOpen}
          toggleModal={this.toggleViewBookingModal}
        />
      </>
    );
  }
}

export default Clients;
