import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withKeycloak } from '@react-keycloak-fork/web';
import { Loading } from 'common/Loading';
import { onboardingDates } from '../configuration/onboardingDates';

@withKeycloak
@inject('auth', 'schedule', 'calendarSync')
@observer
class AuthenticatedRoute extends Component {
  // componentDidMount() {
  //   const { schedule } = this.props;

  // }

  render() {
    const {
      auth,
      schedule,
      calendarSync,
      path,
      exact = true,
      component: Component,
      keycloak,
      ...rest
    } = this.props;
    const skipWizard = sessionStorage.getItem('clearheadwizardskip')
      ? JSON.parse(sessionStorage.getItem('clearheadwizardskip'))
      : false;

    if (keycloak.authenticated && auth.currentPractitioner == null) {
      return <Loading />;
    }

    let parsedDate = null;
    if (auth?.currentPractitioner?.lastOnboarded) {
      parsedDate = new Date(auth.currentPractitioner.lastOnboarded);
    }

    return (
      <>
        <Route
          {...rest}
          path={path}
          exact={exact}
          render={(props) => {
            if (keycloak.authenticated) {
              if (
                (!auth.hasCompletedWizard && !skipWizard) ||
                (parsedDate !== null && parsedDate < onboardingDates.EXPERIENCE_SETUP_DATE) ||
                parsedDate === null
              ) {
                return (
                  <Redirect
                    to={{
                      pathname: '/app/setup-wizard',
                      state: { from: props.location },
                    }}
                  />
                );
              }
              return <Component {...props} />;
            } else {
              window.location = keycloak.createLoginUrl();
            }
          }}
        />
      </>
    );
  }
}

export default AuthenticatedRoute;
