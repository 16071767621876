import * as React from 'react';
import clsx from 'clsx';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';

type Props = {
  onClick: () => void;
  isSelected: boolean;
  isDisabled: boolean;
  HeadingTitle: React.ReactNode;
  EntitlementInformation: React.ReactNode;
  showSessionExtension: boolean;
  onSessionExtensionClick: () => void;
};

const OptionCard: React.FC<Props> = ({
  onClick,
  isSelected,
  isDisabled,
  HeadingTitle,
  EntitlementInformation,
  showSessionExtension,
  onSessionExtensionClick,
}) => {
  let bgColor = 'bg-white border border-white hover:border-dark-green cursor-pointer';
  if (isSelected) {
    bgColor = 'bg-white border border-dark-green cursor-pointer';
  }
  if (isDisabled) {
    bgColor = 'bg-grey cursor-not-allowed';
  }

  return (
    <div className={`flex flex-col w-full py-2 rounded-md ${bgColor}`}>
      <div
        onClick={onClick}
        className={clsx(`flex flex-row justify-between items-center px-2 pr-4`)}
      >
        <div className='flex flex-row justify-start items-center pl-2'>
          <div
            className={clsx(
              'h-4 w-4 rounded-full mr-4 border-bg-primary-blue-15 border-3 ',
              isSelected ? 'bg-dark-green' : 'bg-primary-blue-10'
            )}
          />
          <Heading
            className='flex flex-row items-center justify-start text-dark-blue text-lg'
            as='h5'
          >
            {HeadingTitle}
          </Heading>
        </div>
        {EntitlementInformation}
      </div>
      {isSelected ? (
        showSessionExtension ? (
          <div
            className='bg-green-10 px-4 pt-2 cursor-pointer'
            onClick={(e) => {
              e.preventDefault();
              onSessionExtensionClick();
            }}
          >
            <Text className='text-xs text-primary-blue-100 underline'>
              Click here to request a session extension.
            </Text>
          </div>
        ) : null
      ) : null}
    </div>
  );
};

export default OptionCard;
