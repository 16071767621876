// @flow
import React, { Component } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Select from 'react-select';
import { Button, Form, FormGroup, FormFeedback, Label, Input } from 'reactstrap';

export type ReasonOption = {
  label: string;
  desc?: string;
  value: ReasonType;
};

type ReasonType = 'Unavailable' | 'Reschedule' | 'Wrong Fit' | 'Fee Limit' | 'Other';

const data: ReasonOption[] = [
  {
    label: 'Need to reschedule',
    desc: "Sorry, I need to reschedule with you, I will be in touch shortly to suggest another time. If you don't wish to wait, please feel free to book in with another therapist.",
    value: 'Reschedule',
  },
  {
    label: 'Not accepting new clients',
    desc: "At this stage I'm not accepting new clients, I've left my profile enabled by accident, sorry for the inconvenience.",
    value: 'Unavailable',
  },
  {
    label: "I'm not the right fit",
    desc: 'Sorry it looks like I may not be the right fit for you due to my specialisation.',
    value: 'Wrong Fit',
  },
  {
    label: 'Therapy fee limit too low',
    desc: 'At this stage, I will have to decline this appointment due to personal reasons.',
    value: 'Fee Limit',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

const schema = Yup.object().shape({
  reasonOption: Yup.object({
    label: Yup.string(),
    value: Yup.string(),
  })
    .required('Required')
    .label('Reason option'),
  reason: Yup.string().when('reasonOption', {
    is: (option: any) => option.value === 'Other',
    then: Yup.string().min(10).required('Required').label('Why?'),
  }),
});

type Props = {
  onComplete: Function;
};

@inject('bookings')
@observer
class RejectBookingForm extends Component<Props> {
  @observable forceErrors = false;

  static defaultProps = {
    onComplete: () => {},
  };

  getSubmitError = (status) => {
    if (status && status.message) {
      return <FormFeedback>{status.message}</FormFeedback>;
    }
    return null;
  };

  onValidate = (values) => {
    let errors = {};
    this.forceErrors = false;

    if (!values.reasonOption) {
      errors.reasonOption = 'Please select a reason';
      this.forceErrors = true;
    }

    if (values.reasonOption && values.reasonOption.value === 'Other' && !values.reason) {
      errors.reason = 'Please enter a reason';
      this.forceErrors = true;
    }

    return errors;
  };

  render() {
    const { bookings, formState = {}, onComplete = () => {} } = this.props;

    return (
      <Formik
        initialValues={formState}
        validationSchema={schema}
        validate={this.onValidate}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          const submitFunction =
            formState.mode === 'Manual' ? bookings.rejectManualBooking : bookings.rejectBooking;
          let data = { ...values };

          data.therapistWillRebook = values.reasonOption.value === 'Reschedule';

          submitFunction(data).then((response) => {
            if (!response.hasError) {
              onComplete(response, values.reasonOption.value ?? 'Other');
              toast.success('Booking rejected');
            } else {
              response.errors.forEach((err) => {
                actions.setFieldTouched(err.field, true, false);
                actions.setFieldError(err.field, err.defaultMessage);
              });
              actions.setStatus({ message: response.message });
              toast.error(response.message);
            }
            actions.setSubmitting(false);
          });
        }}
        render={({
          values,
          errors,
          status,
          touched,
          setTouched,
          setFieldValue,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for='reason'>Please select a reason:</Label>
                <Select
                  classNamePrefix='react-select'
                  name='reasonOption'
                  options={data}
                  value={values.reasonOption}
                  onBlur={() => setTouched({ ...touched, reasonOption: true })}
                  onChange={(value) => {
                    setFieldValue('reasonOption', value);
                    if (value.value !== 'Other') {
                      setFieldValue('reason', value.desc);
                    } else {
                      setFieldValue('reason', '');
                    }
                  }}
                  closeMenuOnSelect={true}
                  isMulti={false}
                />
                {(touched.reasonOption || this.forceErrors) && errors.reasonOption && (
                  <FormFeedback className='d-block'>{errors.reasonOption}</FormFeedback>
                )}
              </FormGroup>
              {values && values.reasonOption && values.reasonOption.value === 'Other' ? (
                <FormGroup>
                  <Label for='reason'>Why?</Label>
                  <Input
                    bsSize='lg'
                    name='reason'
                    type='textarea'
                    value={values.reason}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={touched.reason && !!errors.reason}
                  />
                  {errors.reason && <FormFeedback>{errors?.reason}</FormFeedback>}
                </FormGroup>
              ) : null}

              {this.getSubmitError(status)}

              <div className='text-center mt-3'>
                <Button
                  color='primary'
                  size='lg'
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Reject Booking
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

export default RejectBookingForm;
