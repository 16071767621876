import * as React from 'react';

import { BaseCard, CardHeader, CardFooter } from './styles';
import { BoxProps } from '../../@types';

export interface ICard extends BoxProps, React.HTMLAttributes<HTMLDivElement> {
  // required
  children: React.ReactNode;
  // optional
  modifiers?: string[];
}

const Card = (props: ICard): React.ReactElement => {
  return <BaseCard {...props}>{props.children}</BaseCard>;
};

export { BaseCard, Card, CardHeader, CardFooter };
