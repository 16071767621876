import { types } from 'mobx-state-tree';

const AppState = types
	.model('App', {
		isSidebarOpen: types.optional(types.boolean, true),
		isSidebarSticky: types.optional(types.boolean, true),
		isLayoutBoxed: types.optional(types.boolean, false),
	})
	.actions(self => ({
		setSidebarVisibility: bool => {
			self.isSidebarOpen = bool;
		},
		toggleSidebarVisibility: () => {
			self.isSidebarOpen = !self.isSidebarOpen;
		},
		setSidebarSticky: bool => {
			self.isSidebarSticky = bool;
			self.isLayoutBoxed = false;
		},
		toggleSidebarSticky: () => {
			self.isSidebarSticky = !self.isSidebarSticky;
			self.isLayoutBoxed = false;
		},

		enableBoxed: () => {
			self.isLayoutBoxed = true;
		},
		disableBoxed: () => {
			self.isLayoutBoxed = false;
		},
		toggleBoxed: () => {
			self.isLayoutBoxed = !self.isLayoutBoxed;
		},
	}));

export default AppState;
