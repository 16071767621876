import * as React from 'react';

const useWizard = (initialData, steps, initialStep) => {
  const [data, setData] = React.useState(initialData);
  const [currentStep, setCurrentStep] = React.useState(initialStep || 0);
  const [totalSteps] = React.useState(steps);

  const updateData = React.useCallback(
    (partialData) => {
      setData((prevState) => ({
        ...prevState,
        ...partialData,
      }));
    },
    [setData]
  );

  const clearData = () => setData(initialData);

  const isLastStep = React.useCallback(() => {
    return currentStep === steps - 1;
  }, [currentStep, steps]);

  const isFirstStep = React.useCallback(() => {
    return currentStep === 0;
  }, [currentStep]);

  const onNext = React.useCallback(
    async (skip) => {
      const nextPages = skip !== undefined && skip !== 0 ? skip + 1 : 1;
      if (currentStep < steps) {
        setCurrentStep(currentStep + nextPages);
      }
    },
    [currentStep, steps]
  );

  const onPrevious = React.useCallback(
    async (skip) => {
      const prevPages = skip ? skip + 1 : 1;
      if (currentStep > 0) {
        setCurrentStep(currentStep - prevPages);
      }
    },
    [currentStep]
  );

  return {
    data,
    updateData,
    totalSteps,
    currentStep,
    setCurrentStep,
    onNext,
    onPrevious,
    isFirstStep,
    isLastStep,
    clearData,
  };
};

export function createWizardFormContext() {
  return React.createContext({
    data: {},
    updateData: (partialData) => {
      console.log('implement updateData: ', partialData);
    },
    currentStep: 0,
    onNext: async () => {
      console.log('implement the onNext');
    },
    onPrevious: async () => {
      console.log('implement the onPrevious');
    },
    setCurrentStep: (step) => {
      console.log('implementation on setCurrentStep', step);
    },
    isFirstStep: () => false,
    isLastStep: () => false,
    clearData: () => {
      console.log('implementation on clearData');
    },
  });
}

export default useWizard;
