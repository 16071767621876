import styled from "styled-components";

import { Input } from "reactstrap";
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: flex-start;
  flex-direction: row;
  background-color: #f3f3f3;
  position: relative;
`;

export const Image = styled.img`
  object-fit: cover;
  width: 95vw;
  height: 145vh;
  min-height: 800px;
`;

export const TextContainer = styled.div`
  position: absolute;
  margin: 80px 0 0 0;
  background-color: white;
  border-radius: 20px;
  padding: 50px;
  font-size: 14px;
  font-weight: bold;
  width: 38%;
  min-width: 350px;
  box-shadow: 1px 1px 10px #c8c8c8;
`;

export const Header = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: black;
`;

export const InputField = styled(Input)`
  width: 70%;
  padding: 15px;
`;

export const Dot = styled.div`
  color: red;
  display: inline;
`;
