/* Third party */
import React from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  InputGroup,
  InputGroupText,
  Input,
  FormFeedback,
} from 'reactstrap';
import { Observer, observer } from 'mobx-react';
import { observable } from 'mobx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import * as Icon from 'react-feather';

import BaseService from 'services/BaseService';
import { BASE_API_URL } from 'configuration';

import { Section, SectionSpacing, HeaderTitle } from '../styles';

/* Components */
import { Dot } from './styles';

const schema = Yup.object().shape({
  name: Yup.string().required('A name is required'),
  emailAddress: Yup.string()
    .email('Please enter a valid email')
    .required('An email is required'),
  subject: Yup.string(),
  message: Yup.string().required('A message is required'),
});

@observer
class ContactUs extends React.Component {
  @observable forceError = false;

  validate = (validateForm, handleSubmit) => {
    validateForm().then((data) => {
      const hasError = Object.keys(data).some(
        (field) => field && field.length > 0
      );
      if (hasError) {
        this.forceError = true;
      } else {
        handleSubmit();
      }
    });
  };

  onSubmit = (values, actions) => {
    BaseService.post(`${BASE_API_URL}/contactUs`, {
      name: values.name,
      emailAddress: values.emailAddress,
      subject: values.subject,
      message: values.message,
    }).then((res) => {
      if (res.hasError) {
        res.errors.forEach((err) => {
          const fieldName = err.field;
          actions.setFieldTouched(fieldName, true, false);
          actions.setFieldError(fieldName, err.defaultMessage);
        });
      } else {
        alert('Message sent!');
        actions.resetForm({
          values: {
            name: '',
            emailAddress: '',
            subject: '',
            message: '',
          },
        });
        this.messageSent = true;

      }
    }).catch((err) => {
      alert('Message failed to send');
    });
  };

  renderForm = () => (
    <Formik
      validationSchema={schema}
      onSubmit={this.onSubmit}
      initialValues={{
        name: '',
        emailAddress: '',
        subject: '',
        message: '',
      }}
    >
      {({
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        handleSubmit,
        validateForm,
      }) => (
        <Observer>
          {() => (
            <>
              <FormGroup>
                <Label for="name">
                  Name <Dot>*</Dot>
                </Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon.User size={14} />
                  </InputGroupText>
                  <Input
                    name="name"
                    placeholder="Full Name"
                    onBlur={handleBlur}
                    value={values.name}
                    invalid={(touched.name || this.forceError) && !!errors.name}
                    onChange={handleChange}
                  />
                  {touched.name || this.forceError ? (
                    <FormFeedback>{errors.name}</FormFeedback>
                  ) : null}
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label for="emailAddress">
                  Email <Dot>*</Dot>
                </Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon.Mail size={14} />
                  </InputGroupText>
                  <Input
                    type="email"
                    name="emailAddress"
                    placeholder="Email@Email.com"
                    onBlur={handleBlur}
                    invalid={
                      (touched.emailAddress || this.forceError) &&
                      !!errors.emailAddress
                    }
                    value={values.emailAddress}
                    onChange={handleChange}
                  />
                  {touched.emailAddress || this.forceError ? (
                    <FormFeedback>{errors.emailAddress}</FormFeedback>
                  ) : null}
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label for="subject">Subject</Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon.Edit size={14} />
                  </InputGroupText>
                  <Input
                    name="subject"
                    placeholder="Subject of message"
                    onBlur={handleBlur}
                    invalid={
                      (touched.subject || this.forceError) && !!errors.subject
                    }
                    value={values.subject}
                    onChange={handleChange}
                  />
                  {touched.subject || this.forceError ? (
                    <FormFeedback>{errors.subject}</FormFeedback>
                  ) : null}
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <Label for="message">
                  Message <Dot>*</Dot>
                </Label>
                <Input
                  type="textarea"
                  name="message"
                  placeholder="Type your message here"
                  rows={4}
                  onBlur={handleBlur}
                  value={values.message}
                  invalid={
                    (touched.message || this.forceError) && !!errors.message
                  }
                  onChange={handleChange}
                />
                {touched.message || this.forceError ? (
                  <FormFeedback>{errors.message}</FormFeedback>
                ) : null}
              </FormGroup>
              <FormGroup className="d-flex justify-content-center">
                <Button
                  color="primary"
                  className="d-flex align-items-center"
                  onClick={() => this.validate(validateForm, handleSubmit)}
                  disabled={this.messageSent}
                >
                  Send&nbsp;
                  <Icon.Send size={12} />
                </Button>
              </FormGroup>
            </>
          )}
        </Observer>
      )}
    </Formik>
  );

  render() {
    return (
      <>
        <Section backgroundcolor="#4987ce">
          <SectionSpacing>
            <Container>
              <Row className="mt-4">
                <Col sm={12} md={{ size: 8, offset: 2 }}>
                  <HeaderTitle
                    className="mb-0 text-center pt-5 pb-5"
                    color="#FFFFFF"
                  >
                    Contact Us
                  </HeaderTitle>
                </Col>
              </Row>
            </Container>
          </SectionSpacing>
        </Section>
        <Section>
          <SectionSpacing>
            <Container>
              <Row>
                <Col
                  sm={12}
                  md={{ size: 8, offset: 2 }}
                  lg={{ size: 6, offset: 3 }}
                >
                  {this.renderForm()}
                </Col>
              </Row>
            </Container>
          </SectionSpacing>
        </Section>
      </>
    );
  }
}

export default ContactUs;
