/* eslint-disable react/jsx-no-target-blank */
/* Third party */
import React from 'react';
import { Container } from 'reactstrap';

/* Components */
import QuestionItem from './QuestionItem';
import { Child, Text } from './styles';

/* Functions */

const FAQ = () => {
  return (
    <Child>
      <Container className='my-7 pb-5'>
        <QuestionItem
          question={<Text>What are the requirements I need to meet to join Clearhead?</Text>}
          answer={
            <Text>
              Clearhead follows strict guidelines about the therapists we accept onto our database
              and you must be currently registered to one of the following professional bodies:{' '}
              <a
                href='https://support.myclearhead.com/help/sending-the-right-credentials-for-account-approval?version=latest'
                target='_blank'
              >
                View them here.
              </a>{' '}
              If you are part of a professional body that is not listed here and you would like to
              join Clearhead, please get in touch with us at{' '}
              <a href='mailto:therapist-support@myclearhead.com'>
                therapist-support@myclearhead.com
              </a>
              .
            </Text>
          }
        />
        <QuestionItem
          question={
            <Text>What are the costs associated with using Clearhead's system as a therapist?</Text>
          }
          answer={
            <Text>
              It is free to be a part of Clearhead and be listed on our national directory. Our
              client management and booking system is also free to use.
              <br />
              <br />
              The only charge we make is if we refer a new client to you as part of our employer
              funded therapy scheme. We will take a 10% referral fee on each booking. For the
              employer funded therapy scheme, we will be making therapy payments on behalf of the
              employer and so you can guarantee prompt payments from us.
              <br />
              <br />
              If you receive a new client referral from us that is from the general public, at this
              stage we have not implemented a referral fee.
            </Text>
          }
        />
        <QuestionItem
          question={<Text>How can I get in touch with you?</Text>}
          answer={
            <Text>
              You can contact us via email at&nbsp;
              <a href='mailto:therapist-support@myclearhead.com'>
                therapist-support@myclearhead.com
              </a>
            </Text>
          }
        />
        <QuestionItem
          question={<Text>What happens to my information that I share with you?</Text>}
          answer={
            <Text>
              Maintaining your trust and privacy is extremely important to us, as such we have
              different mechanisms to ensure we keep your information secure.
              <ul class='sc-fhYwyz oOfNA'>
                <li class='sc-jzgbtB dWWuPz'>
                  Your data is yours and we do not sell any identifiable data to third parties.
                </li>
                <li class='sc-jzgbtB dWWuPz'>
                  We comply with the Ministry of Health's HISO 10029:2015 standard for data security
                  and privacy.
                </li>
                <li class='sc-jzgbtB dWWuPz'>
                  Your data is encrypted using 256-bit Advanced Encryption Standard (AES-256).
                </li>
                <li class='sc-jzgbtB dWWuPz'>
                  To protect your data as it travels over the Internet, we use Transport Layer
                  Security (HTTPS) for all communications.
                </li>
                <li class='sc-jzgbtB dWWuPz'>
                  Our platform is also hosted in data centers used by Microsoft and Google, these
                  data centers are based in Sydney and have stringent security policies regarding
                  access.
                </li>
                <li class='sc-jzgbtB dWWuPz'>We also ensure regular security audits occur.</li>
                <li class='sc-jzgbtB dWWuPz'>
                  Finally, we are a New Zealand company meaning unlike most other internet companies
                  we follow and abide the New Zealand Privacy Act 2020 and the Health Information
                  Privacy Code 1994.
                </li>
              </ul>
            </Text>
          }
        />
        <QuestionItem
          question={<Text>Is there any cost to the client for requesting an appointment?</Text>}
          answer={
            <Text>
              We do not charge the clients for connecting them up with a therapist regardless of
              whether they come from the general public or if they are part of Clearhead's
              employer-funded therapy scheme.
              <br />
              <br />
              Clients can search for therapists and request available appointments here:{' '}
              <a href='https://www.myclearhead.com/practitioners' target='_blank'>
                https://www.myclearhead.com/practitioners
              </a>
            </Text>
          }
        />
        <QuestionItem
          question={<Text>Where are you based?</Text>}
          answer={
            <Text>We are a proud New Zealand owned company and our team is based in Auckland.</Text>
          }
        />
        <QuestionItem
          question={<Text>Do you have any mental health experts on your team?</Text>}
          answer={
            <Text>
              Our CEO and co-founder is a medical doctor and we collaborated closely with
              Psychiatrist, GPs, Psychologists and users of mental health services throughout the
              design and implementation of the platform. <br />
              <br />
              We also have a clinical psychologist on the team and a clinical advisory board that
              comprises of psychiatrists and GPs.
            </Text>
          }
        />
      </Container>
    </Child>
  );
};

export default FAQ;
