/* Third party */
import React from "react";
import { Col, Row, Container } from "reactstrap";

/* Components */
import { Section, SectionSpacing, SectionTitle, SectionParagraph } from '../../styles';

/* Functions */

const StorySection = () => (
  <Section>
    <SectionSpacing>
      <Container>
        <Row>
          <Col sm={12} md={{ size: 8, offset: 2 }} className="d-flex justify-content-center align-items-center">
            <div>
              <SectionTitle>
                We believe in solving hard but meaningful problems
              </SectionTitle>
              <SectionParagraph color="black">
                For most people seeking help, they often don't know where to start
                or are not comfortable with reaching out in person. There is a huge
                need for mental health support and Clearhead is taking an innovative
                approach to solving this problem.
                <br /> <br />
                Clearhead is an online, one-stop-shop, mental health platform that
                guides anyone to find the therapist that best match their
                needs. Allowing them to search and request appointments based on
                expertise, location, price, availability and many more.
                <br /> <br />
                By joining our national directory you become part of the solution.
                Not only will Clearhead provide you with another source of
                referrals, it will support your clients outside of the therapy room
                and into the future. The best part is, it automates most of the
                time-consuming admin involved with running your own therapy
                practice. Freeing up more of your time to be doing what you love,
                delivering therapy!
              </SectionParagraph>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionSpacing>
  </Section>
);

export default StorySection;
