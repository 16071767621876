import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';
import { ITheme } from '../../styles/themev2';

const GlobalStyle = createGlobalStyle<ITheme>`
  ${normalize()}
  @font-face {
    font-family: 'GT Super Text';
    src: url('/fonts/GTSuperText-Black.eot');
    src: url('/fonts/GTSuperText-Black.eot?#iefix') format('embedded-opentype'),
    url('/fonts/GTSuperText-Black.woff2') format('woff2'),
    url('/fonts/GTSuperText-Black.woff') format('woff'),
      url('/fonts/GTSuperText-Black.ttf') format('truetype'),
      url('/fonts/GTSuperText-Black.svg#GTSuperText-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
        url('/fonts/SourceSansPro-Light.woff2') format('woff2'),
        url('/fonts/SourceSansPro-Light.woff') format('woff'),
        url('/fonts/SourceSansPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
        url('/fonts/SourceSansPro-Regular.woff2') format('woff2'),
        url('/fonts/SourceSansPro-Regular.woff') format('woff'),
        url('/fonts/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
        url('/fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
        url('/fonts/SourceSansPro-SemiBold.woff') format('woff'),
        url('/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
        url('/fonts/SourceSansPro-Bold.woff2') format('woff2'),
        url('/fonts/SourceSansPro-Bold.woff') format('woff'),
        url('/fonts/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: block;
  }
  :root {
    /* ALL COLORS */
    --base-100: #FFFFFF;
    --base-200: #F6F9FC;
    --base-300: #ECF2F9;
    --blue-15: ${(props) => props.colors.blue[15]};
    --blue-50: ${(props) => props.colors.blue[50]};
    --blue-100: ${(props) => props.colors.blue[100]};
    --dark-blue-15: ${(props) => props.colors.darkBlue[15]};
    --dark-blue-50: ${(props) => props.colors.darkBlue[50]};
    --dark-blue-100: ${(props) => props.colors.darkBlue[100]};
    --green-15: ${(props) => props.colors.green[15]};
    --green-50: ${(props) => props.colors.green[50]};
    --green-100: ${(props) => props.colors.green[100]};
    --dark-green-15: ${(props) => props.colors.darkGreen[15]};
    --dark-green-50: ${(props) => props.colors.darkGreen[50]};
    --dark-green-100: ${(props) => props.colors.darkGreen[100]};
    --red-15: ${(props) => props.colors.red[15]};
    --red-50: ${(props) => props.colors.red[50]};
    --red-100: ${(props) => props.colors.red[100]};
    --dark-red-15: ${(props) => props.colors.darkRed[15]};
    --dark-red-50: ${(props) => props.colors.darkRed[50]};
    --dark-red-100: ${(props) => props.colors.darkRed[100]};
    --orange-15: ${(props) => props.colors.orange[15]};
    --orange-50: ${(props) => props.colors.orange[50]};
    --orange-100: ${(props) => props.colors.orange[100]};
    --purple-15: ${(props) => props.colors.purple[15]};
    --purple-50: ${(props) => props.colors.purple[50]};
    --purple-100: ${(props) => props.colors.purple[100]};
    --grey-15: ${(props) => props.colors.grey[15]};
    --grey-50: ${(props) => props.colors.grey[50]};
    --grey-100: ${(props) => props.colors.grey[100]};
    --black-15: ${(props) => props.colors.black[15]};
    --black-50: ${(props) => props.colors.black[50]};
    --black-100: ${(props) => props.colors.black[100]};
    /* BRAND COLORS */
    --primary-15: var(--blue-15);
    --primary-50: var(--blue-50);
    --primary-100: var(--blue-100);
    --primary-dark-15: var(--dark-blue-15);
    --primary-dark-50: var(--dark-blue-50);
    --primary-dark-100: var(--dark-blue-100);
    --secondary-15: var(--green-15);
    --secondary-50: var(--green-50);
    --secondary-100: var(--green-100);
    --secondary-dark-15: var(--dark-green-15);
    --secondary-dark-50: var(--dark-green-50);
    --secondary-dark-100: var(--dark-green-100);
    --danger-15: var(--red-15);
    --danger-50: var(--red-50);
    --danger-100: var(--red-100);
    --danger-dark-15: var(--dark-red-15);
    --danger-dark-50: var(--dark-red-50);
    --danger-dark-100: var(--dark-red-100);
    /* BUTTONS */
    --btn-primary-background-color: var(--primary-100);
    --btn-primary-border-color: var(--primary-100);
    --btn-primary-text-color: var(--base-100);
    --btn-primary-disabled-color: var(--primary-50);
    --btn-secondary-background-color: var(--secondary-100);
    --btn-secondary-border-color: var(--secondary-100);
    --btn-secondary-text-color: var(--base-100);
    --btn-secondary-disabled-color: var(--secondary-50);
    --btn-danger-background-color: var(--danger-100);
    --btn-danger-border-color: var(--danger-100);
    --btn-danger-text-color: var(--base-100);
    --btn-danger-disabled-color: var(--danger-50);
    --btn-black-background-color: var(--black-100);
    --btn-black-border-color: var(--black-100);
    --btn-black-text-color: var(--base-100);
    --btn-danger-disabled-color: var(--black-15);
    --btn-white-background-color: var(--base-100);
    --btn-white-border-color: var(--base-100);
    --btn-white-text-color: var(--blue-100);
    --btn-danger-disabled-color: var(--blue-50);
    /* FONTS */
    --font-h1: normal normal ${(props) => props.fonts.headers.h1.weight} ${(
  props
) => props.fonts.headers.h1.size} ${(props) => props.fonts.headers.h1.family};
    --font-h2: normal ${(props) => props.fonts.headers.h2.weight} ${(props) =>
  props.fonts.headers.h2.size} ${(props) => props.fonts.headers.h2.family};
    --font-h3: normal normal ${(props) => props.fonts.headers.h3.weight} ${(
  props
) => props.fonts.headers.h3.size} ${(props) => props.fonts.headers.h3.family};
    --font-h4: normal normal ${(props) => props.fonts.headers.h4.weight} ${(
  props
) => props.fonts.headers.h4.size} ${(props) => props.fonts.headers.h4.family};
    --font-body: normal normal ${(props) => props.fonts.body.weight} ${(
  props
) => props.fonts.body.size} ${(props) => props.fonts.body.family};
    --font-link: normal normal ${(props) => props.fonts.link.weight} ${(
  props
) => props.fonts.link.size} ${(props) => props.fonts.link.family};
    --font-small: normal normal ${(props) => props.fonts.small.weight} ${(
  props
) => props.fonts.small.size} ${(props) => props.fonts.small.family};
    --font-button: normal normal ${(props) => props.fonts.buttons.weight} ${(
  props
) => props.fonts.buttons.size} ${(props) => props.fonts.buttons.family};
    --font-form-placeholder: normal normal ${(props) =>
      props.fonts.form.placeholder.weight} ${(props) =>
  props.fonts.form.placeholder.size} ${(props) =>
  props.fonts.form.placeholder.family};
    --font-form-label: normal normal ${(props) =>
      props.fonts.form.labels.weight} ${(props) =>
  props.fonts.form.labels.size} ${(props) => props.fonts.form.labels.family};
    --font-form-input: normal normal ${(props) =>
      props.fonts.form.inputs.weight} ${(props) =>
  props.fonts.form.inputs.size} ${(props) => props.fonts.form.inputs.family};
    --font-weight-light: ${(props) => props.weights.light};
    --font-weight-regular: ${(props) => props.weights.regular};
    --font-weight-semi-bold: ${(props) => props.weights.semiBold};
    --font-weight-bold: ${(props) => props.weights.bold};
    --font-body-color: var(--black-100);
    /* Z-INDEXES */
    --z-index--mobile-nav-opened: 2000;
    --z-index--tool-overlay: 200;
    --z-index--bottom-nav: 100;
    /* BACKGROUND */
    --marketing-body-bg: var(--base-200);
    --app-body-bg: var(--base-300);
    /* WIDTH & SPACING */
    --marketing-max-width: 1440px;
    --marketing-spacing-desktop: 80px;
    --marketing-spacing-mobile: 15px;
  }
  html {
    box-sizing: border-box;
    font: var(--font-body);
    font-size: ${(props) => props.fonts.rootFontSize};
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    --current-bg: var(--marketing-body-bg);
    background-color: var(--current-bg);
  }
  h1, h2, h3, h4, h5, h6, label, input, p, span, small, em {
    margin: 0;
  }
  a {
    font: var(--font-body);
    color: var(--primary-100);
  }
`;

export default GlobalStyle;
