import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import { FooterNav, wizardContainerStyle } from '../WizardForm/styles';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';
import TextArea from '@clearhead-ltd/ui-components/dist/v2/TextArea';
import { WizardFormContext } from '../index';
import PractitionerService from '../../../../services/PractitionerService';

const ProfileBioStep = inject('auth')(
  observer((props) => {
    const [isLoading, setIsLoading] = React.useState(false);

    const { onPrevious, onNext, updateData, data, currentStep } =
      React.useContext(WizardFormContext);

    const [bio, setBio] = React.useState(data.biography ? data.biography : '');

    const [characterCount, setCharacterCount] = React.useState(
      data.bioCharacters ? data.bioCharacters : 0
    );

    React.useEffect(() => {
      if (props.auth.currentPractitioner) {
        PractitionerService.fetchPractitioner(props.auth.currentPractitioner.id).then((res) => {
          setBio(res.data.fullBiography);
          setCharacterCount(res.data.fullBiography ? res.data.fullBiography.length : 0);
        });
      }
    }, [props.auth.currentPractitioner]);

    const goBack = async () => {
      setIsLoading(true);
      updateData({ biography: bio, bioCharacters: characterCount });

      await onPrevious();
    };

    const isValid = React.useCallback(() => {
      return characterCount > 10;
    }, [characterCount]);

    const onSubmit = React.useCallback(async () => {
      setIsLoading(true);
      if (isValid()) {
        updateData({ biography: bio, bioCharacters: characterCount });

        const { auth } = props;

        auth.updatePractitioner({
          id: auth.currentPractitioner.id,
          fullBiography: bio,
        });

        // Skip tenant selecting step
        await onNext();
      }
    }, [bio, onNext, isValid]);

    return (
      <>
        <div className={wizardContainerStyle}>
          <div className='flex flex-col w-full items-center'>
            <div className='max-w-xl text-center'>
              <Heading className='text-dark-blue mb-2' as='h3'>
                Add a Profile Bio
              </Heading>
              <Text className='mb-2'>
                Your bio gives people a sense of who you are and your approach to therapy. Most of
                our users don’t understand academic terminology, so we highly recommend using
                everyday language.
              </Text>
              <Text className='mb-4'>
                You can also include a list of keywords not covered by our common tags, so more
                advanced user can pick them up via our search engine. For example, “Keywords:
                psychosynthesis, MiCBT, NZCCP, etc. ”
              </Text>
              <TextArea
                className='flex justify-center w-full max-h-20px'
                placeholder='Enter bio'
                maxLength={500}
                onChange={(e) => {
                  setCharacterCount(e.target.value.length);
                  setBio(e.target.value);
                }}
                defaultValue={
                  props.auth.currentPractitioner ? props.auth.currentPractitioner.fullBiography : ''
                }
              />
              <Text className='flex justify-end text-dark-green'>
                {bio ? `${bio.length}/500` : '0/500'}
              </Text>
            </div>

            <FooterNav className='md:mt-16 xs:mt-8'>
              <div className='flex justify-start'>
                {currentStep > 0 && (
                  <Button
                    variant='primary'
                    modifiers={'inverted'}
                    onClick={goBack}
                    disabled={isLoading}
                  >
                    Back
                  </Button>
                )}
              </div>
              <div className='flex justify-end'>
                <Button
                  variant='primary'
                  onClick={onSubmit}
                  data-test-id='complete-hear-about-us'
                  disabled={!isValid() || isLoading}
                >
                  Next
                </Button>
              </div>
            </FooterNav>
          </div>
        </div>
      </>
    );
  })
);
export default ProfileBioStep;
