import styled from 'styled-components';

import InstagramSvg  from 'jsx:./Images/instagram.svg';
import FacebookSvg  from 'jsx:./Images/facebook.svg';
import LinkedinSvg  from 'jsx:./Images/linkedin-light.svg';

export const FooterContainer = styled.footer`
	padding: 60px 0;
	background-color: #4987ce;
	color: white;
`;

export const Text = styled.p`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const Media = styled.a`
	height: 40px;
	width: 40px;
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	margin-right: 10px;
	&:last-child {
		margin-right: 0px;
	}
`;

export const FacebookIcon = styled(FacebookSvg)`
	width: 20px;
	fill: #4987ce;
`;

export const InstagramIcon = styled(InstagramSvg)`
	width: 24px;
	fill: #4987ce;
`;

export const LinkedinIcon = styled(LinkedinSvg)`
	width: 23px;
	fill: #4987ce;
`;

export const MediaList = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

export const LegalHeader = styled.h5`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 300 !important;
	color: white;
`;

export const Header5 = styled.h5`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 400;
	color: white;
`;

export const Link = styled.a`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 16px;
	font-weight: 300 !important;
	color: white;
	line-height: 26px;
	text-decoration: none;

	&:not(.disable):hover {
		color: #9dd8ff;
	}
`;
