import { flow, types, getEnv } from 'mobx-state-tree';
import moment from 'moment';
import 'moment-timezone';
import CalendarService from 'services/CalendarService';
import InvoiceService from 'services/InvoiceService/index';
import { tagManagerEvent } from '../../../utilities/analytics';

const Block = types
  .model('Block', {
    id: types.maybeNull(types.string),
    blockageTimeUTC: types.string,
    blockageEndTimeUTC: types.string,
    title: types.optional(types.string, ''),
    source: types.maybeNull(types.string, ''),
    deleted: types.optional(types.boolean, false),
    readonly: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get blockageTime() {
      const { auth } = getEnv(self);

      return moment
        .utc(self.blockageTimeUTC)
        .clone()
        .tz(
          auth.currentPractitioner.timezone
            ? auth.currentPractitioner.timezone
            : 'Pacific/Auckland'
        )
        .format('YYYY-MM-DDTHH:mm:ss');
    },
    get blockageEndTime() {
      const { auth } = getEnv(self);

      return moment
        .utc(self.blockageEndTimeUTC)
        .clone()
        .tz(
          auth.currentPractitioner.timezone
            ? auth.currentPractitioner.timezone
            : 'Pacific/Auckland'
        )
        .format('YYYY-MM-DDTHH:mm:ss');
    },
  }))
  .preProcessSnapshot((snap) => {
    if (snap) {
      return {
        ...snap,
        id: snap.id ? snap.id + '' : null,
        title: snap.title || undefined,
        deleted: snap.deleted || false,
      };
    }
  });

const Calendar = types
  .model('Calendar', {
    allBlocks: types.array(Block),
  })
  .actions((self) => ({
    createAppointment: flow(function* (data) {
      const res = yield CalendarService.createAppointment(data);

      if (!res.hasError) {
        const { bookings } = getEnv(self);
        bookings.concatConfirmedBookings(res.data.appointments);
        tagManagerEvent('Appointment Created');
      }
      return res;
    }),

    completeAppointment: flow(function* (appointmentId, data) {
      const res = yield CalendarService.completeAppointment(
        appointmentId,
        data
      );
      if (!res.hasError) {
        return res.data;
      }
      return res;
    }),

    createInvoiceForAppointment: flow(function* (appointmentId) {
      const res = yield InvoiceService.createInvoiceForAppointment(
        appointmentId
      );
      if (!res.hasError) {
        return res.data;
      }
      return res;
    }),
    updateAppointment: flow(function* (data) {
      const res = yield CalendarService.updateAppointment(data);

      if (!res.hasError) {
        const { bookings } = getEnv(self);
        if (data.status === 'Pending') {
          bookings.removePendingBooking(data.id);
          bookings.pushPendingBooking(res.data);
          bookings.sortPendingDescending();
        } else {
          bookings.removeConfirmedBooking(data.id);
          bookings.pushConfirmedBooking(res.data);
          bookings.sortConfirmedDescending();
        }

        bookings.getMonthlyPseudoBookings(res.data.appointmentTime);
        tagManagerEvent('Appointment Updated');
      }
      return res;
    }),

    acceptManualBooking: flow(function* (data) {
      const res = yield CalendarService.approveManualPendingAppointment(data);

      if (!res.hasError) {
        const { bookings } = getEnv(self);
        if (data.status === 'Pending') {
          bookings.removePendingBooking(data.id);
          bookings.pushPendingBooking(res.data);
          bookings.sortPendingDescending();
        } else {
          bookings.removeConfirmedBooking(data.id);
          bookings.pushConfirmedBooking(res.data);
          bookings.sortConfirmedDescending();
        }

        bookings.getMonthlyPseudoBookings(res.data.appointmentTime);
        tagManagerEvent('Manual Booking Accepted');
      }
      return res;
    }),

    getBlocks: flow(function* (data) {
      const res = yield CalendarService.getBlocks(data);
      if (!res.hasError) {
        self.allBlocks = res.data;
      }
      return res;
    }),
    createBlock: flow(function* (data) {
      const res = yield CalendarService.createBlock(data);
      if (!res.hasError) {
        self.allBlocks.push(res.data.responseObject);

        tagManagerEvent('Block Created');
      }
      return res;
    }),
    editBlock: flow(function* (data) {
      const res = yield CalendarService.editBlock(data);
      if (!res.hasError) {
        const newBlocks = self.allBlocks.filter(
          (block) => block.id !== data.id
        );
        newBlocks.push(res.data.responseObject);
        self.allBlocks = newBlocks;

        tagManagerEvent('Block Updated');
      }
      return res;
    }),
    convertBlock: flow(function* (data) {
      const res = yield CalendarService.createAppointment(data);

      if (!res.hasError) {
        const { bookings } = getEnv(self);

        self.allBlocks = self.allBlocks.filter((block) => block.id !== data.id);
        bookings.concatConfirmedBookings(res.data.appointments);
        bookings.sortConfirmedDescending();
      }
      return res;
    }),
    deleteBlock: flow(function* (block) {
      const res = yield CalendarService.deleteBlock(block.id);
      if (!res.hasError) {
        self.allBlocks.remove(block);

        tagManagerEvent('Block Deleted');
      }
      return res;
    }),

    getContactsBySearchTerm: flow(function* (searchTerm, includeDetails = false) {
      return yield CalendarService.getContactsBySearchTerm(searchTerm, includeDetails);
    }),
  }));

export default Calendar;
