import styled from 'styled-components';
import Select from 'react-select';

import { primaryFont } from '../../utils/typography';

export const DropdownLabel = styled.label`
  display: block;
  margin-bottom: 4px;
`;

export interface IBaseSelect {
  menuIsOpen?: boolean;
  menuPlacement?: 'bottom' | 'top';
}

export const BaseSelect = styled(Select)<IBaseSelect>`
  .react-select__control {
    border-radius: ${(props) =>
      props.menuIsOpen
        ? props.menuPlacement === 'bottom'
          ? '4px 4px 0 0'
          : '0 0 4px 4px'
        : '4px'};
    border: 1px solid ${(props) => props.theme.borderColor};

    &--is-focused {
      box-shadow: none;
    }

    * {
      font-family: ${primaryFont};
      font-size: 14px;
    }
  }

  .react-select__control--is-focused {
    border-color: ${(props) => props.theme.borderColorActive};
    box-shadow: 0 0 0 1px ${(props) => props.theme.borderColorActive};
  }

  .react-select__placeholder {
    font-family: ${primaryFont};
    color: hsla(0, 0%, 0%, 0.5);
  }

  .react-select__single-value,
  .react-select__multi-value {
    font-family: ${primaryFont};
    font-size: 14px;
  }

  .react-select__input {
    font-family: var(--font-body);
  }

  .react-select__menu {
    top: auto;
    margin: 0;
    margin-top: ${(props) => (props.menuPlacement === 'bottom' ? '-1px' : '0')};
    margin-bottom: ${(props) => (props.menuPlacement === 'top' ? '-1px' : '0')};
    border-radius: ${(props) => (props.menuPlacement === 'bottom' ? '0 0 4px 4px' : '4px 4px 0 0')};
    border-left: 1px solid ${(props) => props.theme.borderColorActive};
    border-right: 1px solid ${(props) => props.theme.borderColorActive};
    box-shadow: ${(props) =>
      props.menuPlacement === 'bottom'
        ? `0 1px 0 1px ${props.theme.borderColorActive}`
        : `0 -1px 0 1px ${props.theme.borderColorActive}`};
    font-family: ${primaryFont};

    * {
      font-family: ${primaryFont};
      font-size: 14px;
    }
  }

  .react-select__option {
    padding: 10px 16px;
    cursor: pointer;
  }

  .react-select__value-container {
    padding: 6px 16px;
  }

  .react-select__option--is-focused {
    color: white;
    background-color: ${(props) => props.theme.primaryColor};

    :active {
      background-color: ${(props) => props.theme.primaryColor};
    }
  }

  .react-select__group-heading {
    margin-left: 7px;
    margin-right: 7px;
    padding: 0 5px;
    padding-bottom: 5px;
    color: ${(props) => props.theme.primaryColor};
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
  }

  .inline-react-select__indicator-separator {
    display: none;
  }

  .react-select__menu-list {
    margin-left: -1px;
  }
`;
