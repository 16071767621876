import React from 'react';
import styled from 'styled-components';

import CHLogoSvg from 'jsx:assets/svg/ch-logo.svg';
import CHTextSvg from 'jsx:assets/svg/ch-text.svg';

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-bottom: 40px;

  @media screen and (max-width: 765px) {
    height: 30px;
  }
`;

const CHLogo = styled(CHLogoSvg)`
  width: 40px;
  margin-right: 15px;

  path {
    fill: #4987ce;
  }

  @media screen and (max-width: 765px) {
    width: 30px;
  }
`;

const CHText = styled(CHTextSvg)`
  width: 240px;

  path {
    fill: #4987ce;
  }

  @media screen and (max-width: 765px) {
    width: 180px;
  }
`;

var link = (
  <a
    href='mailto:therapist-support@myclearhead.com'
    className='text-primary-blue-100'
    target='blank'
  >
    therapist-support@myclearhead.com
  </a>
);

export const SignUpCompleted = ({ formState }) => {
  return (
    <>
      <div className='text-center mt-4'>
        <LogoContainer>
          <CHLogo />
          <CHText />
        </LogoContainer>
        <p className='lead h2'>
          Thank you for registering your interest in providing mental health and wellbeing services
          through Clearhead.
        </p>
        <p className='lead h2 mb-8'>
          You should receive an email shortly from our team, requesting some additional
          documentation to ensure you meet all of our platform standards.
        </p>
        <p className='italic mb-4'>
          Note: to align with the EAPAA standards, we require all providers to have a minimum of 3
          years experience post qualification.
        </p>
      </div>
    </>
  );
};

export default SignUpCompleted;
