"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = normalizeModifiers;

/**
 * Normalizes string modifier props to be an array.
 * @export
 * @param {ModifierKeys} modifierKeys
 * @returns {ModifierNames}
 */
function normalizeModifiers(modifierKeys) {
  return (Array.isArray(modifierKeys) ? modifierKeys : [modifierKeys]).filter(function (i) {
    return typeof i === 'string' && !!i;
  });
}