import React, { Component } from 'react';
import { inject } from 'mobx-react';

@inject('app')
class Wrapper extends Component {
	render() {
		const {
			app: { isLayoutBoxed },
			children,
		} = this.props;

		return <div className={'wrapper ' + (isLayoutBoxed ? 'wrapper-boxed' : '')}>{children}</div>;
	}
}

export default Wrapper;
