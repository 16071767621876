import { SVGProps } from 'react';
const ArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 25 24' {...props}>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={1.5}
      d='m8.378 7.947-4.517 4.517 4.517 4.517'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M5.41 12.546h16.363'
    />
  </svg>
);
export default ArrowLeft;
