import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Row, Label, Table, Input } from 'reactstrap';

import { HelpCircle } from 'common';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Loading } from 'common';
import * as CronofyElements from 'cronofy-elements';
import CalendarSyncService from 'services/CalendarSyncService';
import { CRONIFY_CLIENT_ID } from 'configuration';

@inject('calendarSync')
@observer
class CalendarSyncSettings extends Component {
  @observable loading = true;
  @observable loadingCalendars = true;
  @observable showAdvanced = false;

  constructor(props) {
    super(props);
    CalendarSyncService.getCalendarSyncToken().then((res) => {
      this.calendarListChanged();
      this.loading = false;

      CronofyElements.CalendarSync({
        element_token: res.data && res.data.token ? res.data.token : undefined,
        target_id: 'cronofy-calendar-sync',
        data_center: 'au',
        single_profile: true,

        authorization: {
          redirect_uri: window.origin + '/auth/sync/complete',
          client_id: CRONIFY_CLIENT_ID,
          scope: 'read_write',
        },
        callback: this.calendarListChanged,
        styles: {
          prefix: 'CalendarSync-Settings',
        },
      });
    });
  }

  render() {
    return (
      <Card>
        <CardBody className='container-fluid'>
          <Row className='mb-1'>
            <Col>
              <h4>Sync your Clearhead calendar with your personal calendar</h4>
              <p className='mt-3'>
                Syncing your calendar means your real time availability will automatically be shown,
                so clients can only book when you don't have other appointments.
                <br />
                When you integrate a calendar, client and appointment details from your selected
                calendar will appear in your Clearhead calendar. This will only be visible to you
                under the “Appointment Calendar” section. This data is encrypted and will not be
                seen by anyone on the Clearhead team or by the public.
              </p>
            </Col>
          </Row>
          <Row className='mb-4'></Row>
          <div id='cronofy-calendar-sync' />
          {this.loading ? <Loading /> : this.renderSyncedCalendarTable()}
        </CardBody>
      </Card>
    );
  }

  calendarListChanged = async () => {
    this.loadingCalendars = true;
    await this.props.calendarSync.getCalendars();
    this.loadingCalendars = false;
  };

  renderSyncedCalendarTable = () => {
    const syncedCalendars = this.props.calendarSync.syncedCalendars;

    if (this.loadingCalendars) {
      return <Loading />;
    }

    if (syncedCalendars && syncedCalendars.length > 0) {
      return (
        <Row className='mt-4'>
          <Col>
            <h4>Synced Calendars</h4>
            <p>
              Enable or disable any of the synced Calendars below, any synced calendar will be used
              to calculate your realtime availability
            </p>
            <Table size='lg' bordered={true}>
              <thead>{this.getCalendarOptionHeaders()}</thead>
              <tbody>{this.getCalendarOptionRows()}</tbody>
            </Table>
            <div className='d-flex justify-content-between align-items-center'>
              <Button
                color='primary'
                size='lg'
                type='submit'
                onClick={async () => {
                  await this.props.calendarSync.saveSyncedCalendars();
                  toast.success('Saved successfully');
                }}
              >
                {'Save'}
              </Button>
            </div>
          </Col>
        </Row>
      );
    } else {
    }
  };

  getCalendarOptionHeaders = () => {
    return (
      <tr>
        <th>
          <div className='d-flex justify-content-start align-items-center'>
            <Label className='mr-1 mb-0'>Sync?</Label>
          </div>
        </th>
        <th>Account</th>
        <th>Name</th>
        <>
          <th>
            <div className='d-flex justify-content-start align-items-center'>
              <Label className='mr-1 mb-0'>Copy Details</Label>
              <HelpCircle.Primary tooltipText='Include Details for each synced event in the Clearhead Calendar.' />
            </div>
          </th>
          <th>
            <div className='d-flex justify-content-start align-items-center'>
              <Label className='mr-1 mb-0'>Publish To</Label>
              <HelpCircle.Primary tooltipText='Any appointments or blocks created in Clearhead will be written to this Calendar' />
            </div>
          </th>
        </>
      </tr>
    );
  };

  getCalendarOptionRows = () => {
    const { calendarSync } = this.props;
    const syncedCalendars = calendarSync.syncedCalendars;

    if (syncedCalendars) {
      return syncedCalendars.map((calendar) => (
        <tr key={calendar.id}>
          <td>
            <Input
              id={`${calendar.calendarId}_syncToClearhead`}
              type='checkbox'
              label=''
              value={calendar.calendarId}
              onChange={(e) => {
                calendarSync.toggleComponentEnabled(e.target.value);
              }}
              checked={calendar.enabled}
            />
          </td>
          <td className='text-capitalize'>{calendar.providerName}</td>
          <td>{calendar.calendarName}</td>
          <>
            <td>
              <Input
                id={`${calendar.calendarId}_copyDetailsToClearhead`}
                type='checkbox'
                label=''
                value={calendar.calendarId}
                onChange={(e) => {
                  calendarSync.toggleCopyDetails(e.target.value);
                }}
                checked={calendar.copyDetails}
                disabled={!calendar.enabled}
              />
            </td>
            <td>
              <Input
                id={`${calendar.calendarId}_publishDetailsFromClearhead`}
                type='checkbox'
                className='ml-4'
                label=''
                value={calendar.calendarId}
                onChange={(e) => {
                  calendarSync.togglePublishTo(e.target.value);
                }}
                checked={calendar.publishEventsTo}
                disabled={!calendar.enabled}
              />
            </td>
          </>
        </tr>
      ));
    }
    return null;
  };
}

export default CalendarSyncSettings;
