import * as React from 'react';
import clsx from 'clsx';
import { ExclamationCircle, TickCircle } from '../Icons';

export type TextFieldProps = {
  defaultValue?: string;
  className?: string;
  invalid?: boolean;
  valid?: boolean;
  inputClassName?: string;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  enableCount?: boolean;
  maxLength?: number;
  name?: string;
  type?: string;
  value?: string;
  onChange?: (newValue: string) => void;
  autoComplete?: string;
  rounded?:
    | 'rounded-none'
    | 'rounded-sm'
    | 'rounded-md'
    | 'rounded-lg'
    | 'rounded-xl'
    | 'rounded-2xl'
    | 'rounded-3xl'
    | 'rounded-full';
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      className,
      type,
      value,
      onChange,
      placeholder,
      enableCount = false,
      maxLength,
      inputClassName,
      invalid,
      valid: isValid,
      disabled,
      readOnly,
      defaultValue,
      rounded = 'rounded-lg',
      iconLeft,
      iconRight,
      onKeyDown,
      ...rest
    },
    ref
  ) => {
    const mLength = maxLength ?? 99;
    const [count, setCount] = React.useState<number>(value?.length ?? 0);
    const showCount = enableCount && maxLength !== undefined && maxLength > 0;

    return (
      <div
        className={clsx(
          'border border-solid font-body flex items-center justify-between leading-6 tracking-[0.27px] bg-white',
          'placeholder:text-grey border-primary-blue-50',
          !(invalid || isValid) && 'focus:border-primary-blue-100',
          invalid && 'border-red focus:border-red',
          isValid && 'border-green focus:border-green',
          iconLeft && 'pl-3',
          iconRight && 'pr-3',
          rounded,
          className
        )}
      >
        {iconLeft}
        <input
          className={clsx(
            'leading-6 tracking-[0.27px] font-normal font-body text-gray-700',
            'outline-none border-0 w-full h-full p-3 bg-white focus:outline-none focus:ring-0',
            (invalid || isValid) && 'pr-2',
            (disabled || readOnly) && '!bg-blue-15',
            rounded,
            inputClassName
          )}
          type={type}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          maxLength={mLength}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setCount(e.target.value.length);
            onChange?.(e.target.value);
          }}
          disabled={disabled}
          readOnly={readOnly}
          ref={ref}
          onKeyDown={onKeyDown}
          {...rest}
        />
        {iconRight}
        {invalid && <ExclamationCircle className='h-5 w-5 mr-3 text-red' />}
        {isValid && <TickCircle className='w-5 h-5 text-green mr-3' />}
        {showCount && (
          <div className='font-body text-green mr-3 text-sm'>
            {count}/{mLength}
          </div>
        )}
      </div>
    );
  }
);
TextField.displayName = 'TextField';

export default TextField;
