import * as React from 'react';
import { inject } from 'mobx-react';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import { FooterNav, OptionCard, wizardContainerStyle } from '../WizardForm/styles';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';
import TextArea from '@clearhead-ltd/ui-components/dist/v2/TextArea';
import { WizardFormContext } from '../index';
import * as CronofyElements from 'cronofy-elements';
import CalendarSyncService from 'services/CalendarSyncService';
import { CRONIFY_CLIENT_ID } from 'configuration';
import './styles.css';

const CalendarSyncStep = inject('auth')((props) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const { onPrevious, onNext, updateData, data, currentStep } = React.useContext(WizardFormContext);

  const [calendarType, setCalendarType] = React.useState(
    data.calendarType ? data.calendarType : ''
  );

  const [otherDetails, setOtherDetails] = React.useState(
    data.calSyncDetails ? data.calSyncDetails : ''
  );

  const [characterCount, setCharacterCount] = React.useState(
    data.calendarCount ? data.calendarCount : 0
  );
  const [calSync, setCalSync] = React.useState(null);
  const [checkCalSync, setCheckCalSync] = React.useState(true);

  React.useEffect(() => {
    if (checkCalSync) {
      CalendarSyncService.getSyncedCalendars().then((res) => {
        if (res.data && res.data.length >= 1) {
          setCalSync(true);
        } else {
          setCalSync(false);
        }
      });
      setCheckCalSync(false);
    }
  }, []);

  React.useEffect(() => {
    sessionStorage.setItem('calendar-sync-onboarding', true);
    CalendarSyncService.getCalendarSyncToken().then((res) => {
      CronofyElements.CalendarSync({
        element_token: res.data && res.data.token ? res.data.token : undefined,
        target_id: 'cronofy-calendar-sync',
        data_center: 'au',
        single_profile: true,

        authorization: {
          redirect_uri: window.origin + '/auth/sync/complete',
          client_id: CRONIFY_CLIENT_ID,
          scope: 'read_write',
        },
        styles: {
          prefix: 'CalendarSync',
        },
      });
    });
  }, []);

  const goBack = async () => {
    setIsLoading(true);
    updateData({
      calendarType: calendarType,
      calSyncDetails: otherDetails,
      calendarCount: characterCount,
    });
    sessionStorage.removeItem('calendar-sync-onboarding');
    await onPrevious();
  };

  const isValid = React.useCallback(() => {
    return characterCount >= 1 || calSync;
  }, [otherDetails, calendarType, calSync]);

  const onSubmit = React.useCallback(async () => {
    setIsLoading(true);
    if (isValid()) {
      updateData({ calendarType: calendarType, calSyncDetails: otherDetails });

      props.auth.updatePractitioner({
        id: props.auth.currentPractitioner.id,
        calendarUsed: `${calendarType} - ${otherDetails || ''}`,
      });
      sessionStorage.removeItem('calendar-sync-onboarding');
      await onNext();
    }
  }, [calendarType, otherDetails, onNext, isValid]);

  return (
    <>
      <div className={wizardContainerStyle}>
        <div className='flex flex-col w-full items-center'>
          <div className='max-w-lg text-center'>
            <Heading className='text-dark-blue mb-2' as='h3'>
              Sync your calendars
            </Heading>
            <Text className='mb-4'>
              This will automatically update your calendar when people book and cancel. It can also
              update your availability, so clients can book freed up appointments.
            </Text>
            <Heading className='text-dark-blue mb-2' as='h4'>
              Select you calendar of choice
            </Heading>
            <div id='cronofy-calendar-sync' className={calSync ? 'show' : 'hide'} />

            <div className='mt-9'>
              <OptionCard
                className={'flex justify-center w-full h-full mx-auto cursor-pointer mb-2'}
                style={{
                  borderColor: 'Other' === calendarType ? '#3BB897' : 'transparent',
                  borderWidth: 'Other' === calendarType ? 'medium' : null,
                }}
                onClick={() => setCalendarType('Other')}
              >
                <Heading as='h4' className='text-dark-blue'>
                  I don't have a calendar to sync
                </Heading>
              </OptionCard>
              {calendarType === 'Other' ? (
                <div className='col-span-1 sm:col-span-2 mb-2'>
                  <TextArea
                    className='w-full'
                    placeholder='How do you manage your appointments?'
                    maxLength={100}
                    onChange={(e) => {
                      setCharacterCount(e.target.value.length);
                      setOtherDetails(e.target.value);
                    }}
                  >
                    {otherDetails ? otherDetails : ''}
                  </TextArea>
                  <Text className='flex justify-end text-dark-green'>
                    {otherDetails ? `${otherDetails.length}/100` : '0/100'}
                  </Text>
                </div>
              ) : null}
            </div>
          </div>

          <FooterNav className='md:mt-16 xs:mt-8'>
            <div className='flex justify-start'>
              {currentStep > 0 && (
                <Button
                  variant='primary'
                  modifiers={'inverted'}
                  onClick={goBack}
                  disabled={isLoading}
                >
                  Back
                </Button>
              )}
            </div>
            <div className='flex justify-end'>
              <Button
                variant='primary'
                onClick={onSubmit}
                data-test-id='complete-hear-about-us'
                disabled={!isValid() || isLoading}
              >
                Next
              </Button>
            </div>
          </FooterNav>
        </div>
      </div>
    </>
  );
});
export default CalendarSyncStep;
