import { getEnv, flow, types } from 'mobx-state-tree';

import ClientService from 'services/ClientService';
import { Booking } from 'modules/bookings/models';
import { tagManagerEvent } from '../../../utilities/analytics';

export const Client = types.model('Client', {
  id: types.identifierNumber,
  email: types.maybeNull(types.string),
  firstName: types.string,
  lastName: types.string,
  gender: types.maybeNull(types.string),
  dateOfBirth: types.maybeNull(types.string),
  ethnicity: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  previousSessionUTC: types.maybeNull(types.string),
  nextSessionUTC: types.maybeNull(types.string),
  contactName: types.maybeNull(types.string),
  appointments: types.maybeNull(types.array(Booking)),
  clearheadAccount: types.maybeNull(types.boolean),
  timezone: types.maybeNull(types.string),
  eligibleForTherapy: types.maybeNull(types.boolean),
  maxAllowedSessions: types.maybeNull(types.number),
  remainingSessions: types.maybeNull(types.number),
  maxChargeSession: types.maybeNull(types.number),
  caseNumber: types.maybeNull(types.string),
});

const Clients = types
  .model('Clients', {
    allClients: types.array(Client),
    totalElements: types.maybeNull(types.integer),
    totalPages: types.maybeNull(types.integer),
    lastPage: types.maybeNull(types.boolean),
  })
  .actions((self) => ({
    getClients: flow(function* (data) {
      const res = yield ClientService.getClients(data);
      if (!res.hasError) {
        const { content, totalElements, totalPages, last } = res.data;

        if (data.pageOffset === 0) {
          self.allClients = content;
        } else {
          self.allClients = self.allClients.concat(content);
        }

        self.totalElements = totalElements;
        self.totalPages = totalPages;
        self.lastPage = last;
      }
      return res;
    }),
    createClient: flow(function* (data) {
      const { auth } = getEnv(self);

      if (!data.timezone) {
        data.timezone = auth.currentPractitioner.timezone;
      }

      const res = yield ClientService.createClient(data);
      if (!res.hasError) {
        self.allClients.push(res.data.responseObject);
        self.totalElements = self.totalElements + 1;

        tagManagerEvent('Client Created');
      }
      return res;
    }),
    editClient: flow(function* (oldClient, data) {
      const { auth } = getEnv(self);

      if (!data.timezone) {
        data.timezone = auth.currentPractitioner.timezone;
      }
      const res = yield ClientService.editClient(data);
      if (!res.hasError) {
        self.allClients = self.allClients.filter((client) => client.id !== oldClient.id);
        self.allClients.push(res.data.responseObject);

        tagManagerEvent('Client Updated');
      }
      return res;
    }),
    deleteClient: flow(function* (data) {
      const res = yield ClientService.deleteClient(data.id);
      if (!res.hasError) {
        self.allClients = self.allClients.filter((client) => client.id !== data.id);
        self.totalElements = self.totalElements - 1;

        tagManagerEvent('Client Deleted');
      }
      return res;
    }),
    getClientDetails: flow(function* (client) {
      const res = yield ClientService.getClientDetails(client.id);
      if (!res.hasError) {
        self.allClients.forEach((oldClient) => {
          if (oldClient.id === client.id) {
            oldClient = res.data.responseObject;
          }
        });
      }
      return res;
    }),
    getClientEligibility: flow(function* (client) {
      const res = yield ClientService.getClientEligibility(client.id);
      return res;
    })
  }));

export default Clients;
