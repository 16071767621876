/* Third party */
import React, { Component } from 'react';
import { Button, Row, Col, Container } from 'reactstrap';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inject } from 'mobx-react';

import backgroundImg from 'assets/img/background.png';
import groupImage from 'assets/img/group-img.jpg';

import featuredImage from 'assets/img/featured@2x.png';
import profileImage from 'assets/img/profile@2x.png';
import profileTwoImage from 'assets/img/profile-2@2x.png';
import calendarUsabilityImage from 'assets/img/calendar-usability@2x.png';
import manageBookingsImage from 'assets/img/manage-bookings@2x.png';
import newAppointmentImage from 'assets/img/new-appointment@2x.png';
import integrationsImage from 'assets/img/integrations@2x.png';

import bridget from 'assets/img/client/bridget.jpg';
import sam from 'assets/img/client/sam.jpg';
import chris from 'assets/img/client/chris.jpg';
import gillian from 'assets/img/client/gillian.jpg';

/* Components */
import {
  Section,
  SectionSpacing,
  SectionSpacingCentered,
  Heading,
  HeaderTitle,
  HeaderParagraph,
  Hint,
  Image,
  CenterText,
  BackgroundImage,
  SectionTitle,
  SectionParagraph,
  CtaLink,
  RightIcon,
  Prompt,
  Tile,
  Highlighted,
  CoverImage,
  Paragraph,
  ClientContainer,
  ClientImage,
  ClientParagraph,
} from '../styles';
/* Functions */

@inject('auth')
class GetStarted extends Component {
  render() {
    const { auth, ...rest } = this.props;
    const link = auth.isAuthenticated ? '/app' : '/auth/sign-up';

    return (
      <CtaLink {...rest} className='flex items-center' href={link}>
        GET STARTED FOR FREE
        <RightIcon viewBox='0 0 24 24' />
      </CtaLink>
    );
  }
}

@inject('auth')
class LandingPage extends Component {
  render() {
    const { auth } = this.props;

    return (
      <>
        <Section backgroundcolor='#f6f9fc'>
          <BackgroundImage src={backgroundImg} alt='Background Image' />
          <Container>
            <Row>
              <Col>
                <CenterText>
                  <Heading className='z-10'>
                    <HeaderTitle color='#FFFFFF'>Focus on why you became a therapist.</HeaderTitle>
                    <HeaderParagraph color='#FFFFFF'>
                      Save time by automating your therapy practice, from booking appointments to
                      invoicing.
                    </HeaderParagraph>
                    <Button
                      color='success'
                      size='lg'
                      href={auth.isAuthenticated ? '/app' : '/auth/sign-up'}
                    >
                      Get Started For Free
                    </Button>
                  </Heading>
                  <Hint className='z-10'>No credit card required</Hint>
                  <Image
                    className='z-10'
                    src={featuredImage}
                    alt='therapist appointment scheduling software'
                  />
                </CenterText>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section backgroundcolor='#f6f9fc'>
          <SectionSpacing>
            <Container>
              <Row className='mb-4'>
                <Col>
                  <Heading>
                    <HeaderTitle color='#000000'>
                      Seamlessly manage your therapy practice’s client relationships
                    </HeaderTitle>
                    <SectionParagraph>
                      <p style={{ textAlign: 'center' }}>
                        Clearhead provides a quick and easy to use appointment management tool for
                        therapists looking for an online booking system. We can help you manage
                        existing clients, while creating new client relationships for your practice
                        through our online therapist directory and search tools.
                      </p>
                    </SectionParagraph>
                  </Heading>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={{ size: 12, order: 12 }}
                  sm={{ size: 12, order: 12 }}
                  md={{ size: 5, order: 1 }}
                  lg={{ size: 5, order: 1 }}
                  className='d-flex justify-content-start align-items-center'
                >
                  <div>
                    <SectionTitle>Customise your profile details</SectionTitle>
                    <SectionParagraph>
                      Conveniently display your therapy practice’s details including your speciality
                      areas, multiple location sites and the different services you offer including
                      online therapy and more to the consumer.
                    </SectionParagraph>
                    <GetStarted />
                  </div>
                </Col>
                <Col
                  xs={{ size: 12, order: 1 }}
                  sm={{ size: 12, order: 1 }}
                  md={{ size: 7, order: 12 }}
                  lg={{ size: 7, order: 12 }}
                >
                  <Image
                    src={profileImage}
                    alt='Therapy practice profile and contact information'
                  />
                </Col>
              </Row>
            </Container>
          </SectionSpacing>
        </Section>
        <Section backgroundcolor='white'>
          <SectionSpacing>
            <Container>
              <Row>
                <Col xs={12} sm={12} md={7} lg={7}>
                  <Image src={calendarUsabilityImage} alt='Appointment Calendar Usability' />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={5}
                  lg={5}
                  className='d-flex justify-content-start align-items-center'
                >
                  <div>
                    <SectionTitle>User-friendly booking management system</SectionTitle>
                    <SectionParagraph>
                      Manage your availability and booking requests with our intuitive appointment
                      calendar and tools. See the details of the appointment requested and choose to
                      accept or decline the request. An email will be automatically sent to the
                      client confirming or declining their appointment request on your behalf.
                    </SectionParagraph>
                    <GetStarted />
                  </div>
                </Col>
              </Row>
            </Container>
          </SectionSpacing>
        </Section>
        <Section backgroundcolor='#f6f9fc'>
          <SectionSpacing>
            <Container>
              <Row>
                <Col
                  xs={{ size: 12, order: 12 }}
                  sm={{ size: 12, order: 12 }}
                  md={{ size: 5, order: 1 }}
                  lg={{ size: 5, order: 1 }}
                  className='d-flex justify-content-start align-items-center'
                >
                  <div>
                    <SectionTitle>Build your specialist therapy practice</SectionTitle>
                    <SectionParagraph>
                      Receive self-referrals from clients who are searching for your speciality
                      areas. Decide if they are right for you based on the answers to screening
                      questions that you set.
                    </SectionParagraph>
                    <GetStarted />
                  </div>
                </Col>
                <Col
                  xs={{ size: 12, order: 1 }}
                  sm={{ size: 12, order: 1 }}
                  md={{ size: 7, order: 12 }}
                  lg={{ size: 7, order: 12 }}
                >
                  <Image src={profileTwoImage} alt='Build your specialist therapist practice' />
                </Col>
              </Row>
            </Container>
          </SectionSpacing>
        </Section>
        <Section backgroundcolor='white'>
          <SectionSpacing>
            <Container>
              <Row>
                <Col xs={12} sm={12} md={7} lg={7}>
                  <Image src={manageBookingsImage} alt='Manage Therapy Bookings Online' />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={5}
                  lg={5}
                  className='d-flex justify-content-start align-items-center'
                >
                  <div>
                    <SectionTitle>Keep on top of your client appointments</SectionTitle>
                    <SectionParagraph>
                      All your therapy appointments are tracked and recorded online so you can
                      quickly search and manage pending, confirmed, and historical bookings in one
                      place. You can also view them as part of your clients list and see when the
                      last and upcoming appointments are for each individual client.
                    </SectionParagraph>
                    <GetStarted />
                  </div>
                </Col>
              </Row>
            </Container>
          </SectionSpacing>
        </Section>
        <Section backgroundcolor='#f6f9fc'>
          <SectionSpacing>
            <Container>
              <Row>
                <Col
                  xs={{ size: 12, order: 12 }}
                  sm={{ size: 12, order: 12 }}
                  md={{ size: 5, order: 1 }}
                  lg={{ size: 5, order: 1 }}
                  className='d-flex justify-content-start align-items-center'
                >
                  <div>
                    <SectionTitle>
                      Create appointments manually with automated client reminders
                    </SectionTitle>
                    <SectionParagraph>
                      It’s quick and easy to schedule appointments in yourself including recurring
                      weekly appointments. Email reminders will automatically be sent to your
                      clients 24 hours before their scheduled appointment.
                    </SectionParagraph>
                    <GetStarted />
                  </div>
                </Col>
                <Col
                  xs={{ size: 12, order: 1 }}
                  sm={{ size: 12, order: 1 }}
                  md={{ size: 7, order: 12 }}
                  lg={{ size: 7, order: 12 }}
                >
                  <Image src={newAppointmentImage} alt='Create and schedule appointments online' />
                </Col>
              </Row>
            </Container>
          </SectionSpacing>
        </Section>
        <Section backgroundcolor='white'>
          <SectionSpacing>
            <Container>
              <Row>
                <Col xs={12} sm={12} md={7} lg={7}>
                  <Image
                    src={integrationsImage}
                    alt='Google &amp; Outlook Outlook Calendar Booking Integrations'
                  />
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={5}
                  lg={5}
                  className='d-flex justify-content-start align-items-center'
                >
                  <div>
                    <SectionTitle>
                      Manage your calendar with Google and Outlook integrations
                    </SectionTitle>
                    <SectionParagraph>
                      Stop being interrupted in your day with request for appointments you don’t
                      have. Calendar synchronisation only displays your real time appointment
                      availability for clients to request from. The confirmed Clearhead bookings
                      then automatically syncs across to your Google or Outlook calendar.
                    </SectionParagraph>
                    <GetStarted />
                  </div>
                </Col>
              </Row>
            </Container>
          </SectionSpacing>
        </Section>
        <Section backgroundcolor='#f6f9fc'>
          <SectionSpacing>
            <Container>
              <Row>
                <Col className='text-center'>
                  <Prompt href='/auth/sign-up'>
                    Sign up and see how <Highlighted>Clearhead</Highlighted> can help you guide your{' '}
                    <br /> <Highlighted>clients</Highlighted> through the ups and downs of modern
                    life
                  </Prompt>
                </Col>
              </Row>
            </Container>
          </SectionSpacing>
        </Section>
        <Section backgroundcolor='white'>
          <CoverImage src={groupImage} alt='Social group' />
          <SectionSpacingCentered>
            <Tile>
              <Prompt href='https://www.facebook.com/groups/630155387743477/' className='mb-4'>
                Join a supportive community of therapists
              </Prompt>
              <Paragraph>
                When you join as a Clearhead provider, you are joining a supportive community of
                therapists. Clearhead facilitates an online forum for you to share information and
                experiences to help you in your professional development as a mental health
                provider.
              </Paragraph>
              <Button
                href='https://www.facebook.com/groups/630155387743477/'
                className='btn-pill'
                color='facebook'
                size='lg'
              >
                <FontAwesomeIcon icon={faFacebookF} className='align-middle mr-1' /> Join Group
              </Button>
            </Tile>
          </SectionSpacingCentered>
        </Section>
        <Section backgroundcolor='white'>
          <SectionSpacing>
            <Container>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={5}
                  lg={5}
                  className='d-flex justify-content-start align-items-center'
                >
                  <div className='w-100 mb-4'>
                    <SectionTitle>What our therapists say:</SectionTitle>
                    <GetStarted />
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={7}
                  lg={7}
                  className='d-flex justify-content-start align-items-center'
                >
                  <Row>
                    <Col
                      xs={12}
                      md={12}
                      lg={6}
                      className='d-flex justify-content-start align-items-center'
                    >
                      <ClientContainer className='mb-3'>
                        <div className='d-flex justify-content-start align-items-start mb-3'>
                          <ClientImage src={sam} alt="Sam O'Sullivan" className='mr-2' />
                          <div>
                            <h4 className='mb-0'>Sam O'Sullivan</h4>
                            <p className='mb-1'>Clinical Psychologist</p>
                          </div>
                        </div>
                        <ClientParagraph>
                          Clearhead has allowed me to seamlessly streamline my client bookings and
                          management of their information by making the process quick and easy and
                          synchronising with my existing calendar. I’m enjoying seeing my sessions
                          tracked in a visual form that is intuitive to understand and I’m excited
                          to use the automated invoicing feature. The customer service has been
                          incredible and I would recommend it to any therapist.
                        </ClientParagraph>
                      </ClientContainer>
                    </Col>
                    <Col
                      xs={12}
                      md={12}
                      lg={6}
                      className='d-flex justify-content-start align-items-center'
                    >
                      <ClientContainer className='mb-3'>
                        <div className='d-flex justify-content-start align-items-start mb-3'>
                          <ClientImage src={chris} alt='Chris Neuenfeldt' className='mr-2' />
                          <div>
                            <h4 className='mb-0'>Chris Neuenfeldt</h4>
                            <p className='mb-1'>Clinical Psychologist</p>
                          </div>
                        </div>
                        <ClientParagraph>
                          I've been using Clearhead for around a year now and love your platform. It
                          really does make practising easy when clients can book their own
                          appointments and the reminders have reduced no shows dramatically.
                        </ClientParagraph>
                      </ClientContainer>
                    </Col>
                    <Col
                      xs={12}
                      md={12}
                      lg={6}
                      className='d-flex justify-content-start align-items-center'
                    >
                      <ClientContainer className='mb-3'>
                        <div className='d-flex justify-content-start align-items-start mb-3'>
                          <ClientImage src={gillian} alt='Gillian Camilleri' className='mr-2' />
                          <div>
                            <h4 className='mb-0'>Gillian Camilleri</h4>
                            <p className='mb-1'>Clinical Psychologist</p>
                          </div>
                        </div>
                        <ClientParagraph>
                          You have made me feel like I am part of the team at Clearhead, and have
                          made the whole process of contracting so easy and enjoyable for me too.
                        </ClientParagraph>
                      </ClientContainer>
                    </Col>
                    <Col
                      xs={12}
                      md={12}
                      lg={6}
                      className='d-flex justify-content-start align-items-center'
                    >
                      <ClientContainer className='mb-3'>
                        <div className='d-flex justify-content-start align-items-start mb-3'>
                          <ClientImage src={bridget} alt='Bridget McNamara' className='mr-2' />
                          <div>
                            <h4 className='mb-0'>Bridget McNamara</h4>
                            <p className='mb-1'>Clinical Psychologist</p>
                          </div>
                        </div>
                        <ClientParagraph>
                          Clearhead has been the most user-friendly booking platform that I have
                          ever used in my private practice for myself as a clinician, and for my
                          clients. It means I can make recurring weekly appointments for example,
                          and always know my clients will be sent a reminder email about their
                          upcoming appointment. The functionality also means that I can amend /
                          cancel appointments as required, as well as clients having the ability to
                          do this. Clearhead has been especially designed and developed to ensure
                          clinicians unique needs have been considered such as working from multiple
                          locations, offering a variety of different services, syncing with further
                          calendars, and much more.
                        </ClientParagraph>
                      </ClientContainer>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </SectionSpacing>
        </Section>
        <Section backgroundcolor='#f6f9fc'>
          <SectionSpacing>
            <Container>
              <Row>
                <Col className='text-center'>
                  <Prompt href='/auth/sign-up'>
                    Join our <Highlighted>national therapist directory</Highlighted> now and{' '}
                    <Highlighted>connect with people</Highlighted> that need your help
                  </Prompt>
                </Col>
              </Row>
            </Container>
          </SectionSpacing>
        </Section>
      </>
    );
  }
}

export default LandingPage;
