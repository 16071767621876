/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow
import React, { Component } from 'react';
import { Formik, FieldArray } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { inject, observer, Observer } from 'mobx-react';
import {
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  ListGroup,
} from 'reactstrap';
import { observable } from 'mobx';

import { AttendeeFormModal } from 'common';

import BookingService from 'services/BookingService';

import AttendeeItem from '../AttendeeItem';

const schema = Yup.object().shape({
  maxAttendees: Yup.number()
    .max(20, 'Maximum of 20 people for a group booking')
    .min(1, 'Minimum of 1 person required for a group booking')
    .required('Required'),
  attendees: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        contactName: Yup.string(),
        dateOfBirth: Yup.string(),
        email: Yup.string(),
        ethnicity: Yup.string(),
        gender: Yup.string(),
        phoneNumber: Yup.string(),
        disabled: Yup.boolean(),
      })
    )
    .test(
      'length',
      'The maximum number of people for a group booking has been reached.',
      function (data) {
        if (this.options.parent.maxAttendees && this.options.parent.maxAttendees > 0) {
          return data.length <= this.options.parent.maxAttendees;
        }
        return true;
      }
    ),
});

const defaultState = {
  maxAttendees: 8,
  asyncInputsDisabled: true,
  attendees: [],
};

@inject('bookings', 'calendar', 'prices')
@observer
class ImmediateGroupForm extends Component {
  @observable forceError = false;
  @observable existingClientFormState = {};
  @observable searchMenuOpen = false;

  @observable attendeeFormState = {};
  @observable attendeeIsOpen = false;

  static defaultProps = {
    onComplete: () => {},
    status: null,
  };

  validate = (validateForm, handleSubmit) => {
    validateForm().then((data) => {
      const hasError = Object.keys(data).some((field) => field && field.length > 0);
      if (hasError) {
        this.forceError = true;
      } else {
        handleSubmit();
      }
    });
  };

  toggleAttendeeModal = () => {
    this.attendeeIsOpen = !this.attendeeIsOpen;
  };

  openAttendeeModal = (attendee, index) => {
    this.attendeeFormState = {
      ...attendee,
      index: index,
    };
    this.toggleAttendeeModal();
  };

  prepareAppointmentData = (values) => {
    const data = {
      ...values,
      mode: 'Appointment',
      overwrite: true,
    };
    return data;
  };

  onCompleteAttendee = async (arrayHelpers, values) => {
    if (values.index >= 0) {
      arrayHelpers.replace(values.index, values);
    } else {
      arrayHelpers.push(values);
    }
    this.attendeeFormState = {};
    this.toggleAttendeeModal();
  };

  getSubmitError = (status) => {
    if (status && status.message) {
      return <FormFeedback>{status.message}</FormFeedback>;
    }
    return null;
  };

  getPriceOptions = () => {
    const {
      prices: { allPrices },
    } = this.props;

    if (allPrices) {
      return allPrices.map((price) => (
        <option key={price.id} data-price-id={price.id} data-session-length={price.sessionLength}>
          {`${price.name} - ${price.sessionLength}min - $${price.cost}`}
        </option>
      ));
    }
    return null;
  };

  handleRemoveAttendee = async (arrayHelpers, attendee, index) => {
    if (attendee.appointment && attendee.appointment.id) {
      // call API to remove the item
      const response = await BookingService.rejectBooking({
        appointmentId: attendee.appointment.id,
        reason: 'Removed by host',
        reopenSlot: true,
      });
      if (!response.hasError) {
        arrayHelpers.remove(index);
      } else {
        toast.error(response.message);
      }
    } else {
      arrayHelpers.remove(index);
    }
  };

  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  waitFor = (ms) => new Promise((r) => setTimeout(r, ms));

  updateContacts = async (groupId, { id, ...rest }, attendees) => {
    const { calendar } = this.props;
    const data = this.getAppointmentRequestData(groupId, rest);
    await this.asyncForEach(attendees, async (attendee) => {
      await this.waitFor(50);
      if (attendee.appointment && attendee.appointment.id) {
        const response = await calendar.updateAppointment({
          id: attendee.appointment.id,
          ...data,
          contact: {
            id: attendee.id,
            firstName: attendee.firstName,
            lastName: attendee.lastName,
            email: attendee.email,
            dateOfBirth: attendee.dateOfBirth,
            ethnicity: attendee.ethnicity,
            gender: attendee.gender,
            phoneNumber: attendee.phoneNumber,
          },
        });
        if (!response.hasError) {
          console.log('Updated', response, attendee);
        } else {
          console.log('Failed Update', response, attendee);
        }
      } else {
        const response = await calendar.createAppointment({
          ...data,
          contact: {
            firstName: attendee.firstName,
            lastName: attendee.lastName,
            email: attendee.email,
            dateOfBirth: attendee.dateOfBirth,
            ethnicity: attendee.ethnicity,
            gender: attendee.gender,
            phoneNumber: attendee.phoneNumber,
          },
        });
        if (!response.hasError) {
          console.log('Created', response, attendee);
        } else {
          console.log('Failed create', response, attendee);
        }
      }
    });
  };

  hasError = (touched, error) => (touched || this.forceError ? (error ? true : false) : false);

  getAppointmentRequestData = (groupId, values) => {
    return {
      chargeId: values.chargeId,
      mode: 'Appointment',
      groupId,
      overwrite: true,
    };
  };

  handleSubmit = async (values, actions) => {
    const { onComplete = () => {}, mode = 'create' } = this.props;
    const successMessage =
      mode === 'create'
        ? 'Appointment created'
        : mode === 'edit'
        ? 'Appointment updated'
        : 'Block Converted';

    actions.setSubmitting(true);
    // const data = {
    //   chargeId: values.chargeId,
    //   maxAttendees: values.maxAttendees,
    //   mode: "Group",
    //   overwrite: true,
    // };
    let response = null;
    if (mode === 'edit') {
      // response = await bookings.updateGroupAppointment(formState.id, data);
    } else {
      // response = await bookings.createGroupAppointment(data);
    }
    if (!response.hasError) {
      // await this.updateContacts(response.data.id, data, values.attendees);
      onComplete();
      toast.success(successMessage);
    } else {
      response.errors.forEach((err) => {
        actions.setFieldTouched(err.field, true, false);
        actions.setFieldError(err.field, err.defaultMessage);
        actions.setStatus({ message: response.message });
      });
      toast.error(response.message);
    }
    actions.setSubmitting(false);
  };

  render() {
    const { formState = {}, mode = 'create' } = this.props;
    const initialValues = {
      ...defaultState,
      ...formState,
    };
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize={false}
        validate={this.onValidate}
        onSubmit={this.handleSubmit}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          validateForm,
          setFieldValue,
          setTouched,
        }) => {
          return (
            <Observer>
              {() => (
                <>
                  <Form onSubmit={() => this.validate(validateForm, handleSubmit)}>
                    <Row form>
                      <Col className='col-12 col-sm-6'>
                        <FormGroup>
                          <Label>Price</Label>
                          <Input
                            bsSize='lg'
                            type='select'
                            name='price'
                            value={values.price}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              const dataset = e.target.selectedOptions[0].dataset;
                              setFieldValue('price', e.target.value);
                              setFieldValue('chargeId', parseInt(dataset.priceId));
                              setFieldValue('overrideEndTime', false);
                              setFieldValue('sessionLength', parseInt(dataset.sessionLength));

                              const newValues = this.checkAutoComplete({
                                values: {
                                  ...values,
                                  overrideEndTime: false,
                                  sessionLength: dataset.sessionLength,
                                },
                                setFieldValue,
                              });

                              this.checkBlockOverlaps({
                                values: newValues,
                                setFieldValue,
                              });
                            }}
                            invalid={this.hasError(touched.price, errors.price)}
                          >
                            <option />
                            {this.getPriceOptions()}
                          </Input>
                          {this.hasError(touched.price, errors.price) ? (
                            <FormFeedback>{errors.price}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col className='col-12 col-sm-6'>
                        <FormGroup>
                          <Label>Max clients *</Label>
                          <Input
                            bsSize='lg'
                            type='number'
                            name='maxAttendees'
                            min={1}
                            max={20}
                            value={values.maxAttendees}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue('maxAttendees', e.target.value);
                            }}
                            invalid={this.hasError(touched.maxAttendees, errors.maxAttendees)}
                          />
                          {this.hasError(touched.maxAttendees, errors.maxAttendees) ? (
                            <FormFeedback>{errors.maxAttendees}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr />
                    <FieldArray
                      name='attendees'
                      render={(arrayHelpers) => {
                        return (
                          <Observer>
                            {() => (
                              <>
                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                  <h5>Clients</h5>
                                  <Button
                                    color='success'
                                    onClick={() => this.openAttendeeModal({ mode: 'create' })}
                                  >
                                    Add client
                                  </Button>
                                </div>
                                <ListGroup>
                                  {values.attendees && values.attendees.length ? (
                                    <>
                                      {values.attendees.map((attendee, index) => (
                                        <AttendeeItem
                                          key={`attendee_${index}`}
                                          attendee={attendee}
                                          onEdit={() =>
                                            this.openAttendeeModal(
                                              { ...attendee, mode: 'edit' },
                                              index
                                            )
                                          }
                                          onRemove={() =>
                                            this.handleRemoveAttendee(arrayHelpers, attendee, index)
                                          }
                                        />
                                      ))}
                                    </>
                                  ) : (
                                    <p>
                                      No clients added, please add one by{' '}
                                      <a
                                        href='#'
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          this.openAttendeeModal({
                                            mode: 'create',
                                          });
                                        }}
                                      >
                                        clicking here
                                      </a>
                                    </p>
                                  )}
                                </ListGroup>
                                {errors && errors.attendees ? (
                                  <FormFeedback className='d-block text-danger mb-2'>
                                    {errors.attendees}
                                  </FormFeedback>
                                ) : null}
                                <AttendeeFormModal
                                  isOpen={this.attendeeIsOpen}
                                  toggleModal={this.toggleAttendeeModal}
                                  attendee={this.attendeeFormState}
                                  onComplete={(attendeeData) =>
                                    this.onCompleteAttendee(arrayHelpers, attendeeData, values)
                                  }
                                  toggleAttendeeModal={this.toggleAttendeeModal}
                                />
                              </>
                            )}
                          </Observer>
                        );
                      }}
                    />

                    <hr />

                    {this.getSubmitError(status)}

                    <div className='text-center mt-5'>
                      <Button
                        color='primary'
                        size='lg'
                        onClick={() => this.validate(validateForm, handleSubmit)}
                        disabled={isSubmitting}
                      >
                        {mode === 'create'
                          ? 'Create Link'
                          : mode === 'edit'
                          ? 'Update Link'
                          : 'Convert Appointment'}
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Observer>
          );
        }}
      />
    );
  }
}
export default ImmediateGroupForm;
