import * as React from 'react';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import clsx from 'clsx';

type Props = {
  title: string;
  description: string;
  onClick: () => void;
  selected?: boolean;
  className?: string;
};

const AppointmentOption: React.FC<Props> = ({
  title,
  description,
  onClick,
  selected,
  className,
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        `flex-1 px-4 py-3 rounded-md border border-dark-blue mr-2 cursor-pointer`,
        selected ? 'bg-dark-blue' : '',
        className
      )}
    >
      <Heading className={clsx(`text-dark-blue mb-1`, selected ? 'text-white' : '')} as='h5'>
        {title}
      </Heading>
      <Text className={clsx(`text-dark-blue text-xs`, selected ? 'text-white' : '')}>
        {description}
      </Text>
    </div>
  );
};

export default AppointmentOption;
