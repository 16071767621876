import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, Spinner } from 'reactstrap';
import Resizer from 'react-image-file-resizer';
import ReactCrop from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';

const UploadPictureModal = inject('auth')(
  observer((props: any) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [processedImage, setProcessedImage] = React.useState<any>(null);
    const [cropState, setCropState] = React.useState<any>(null);
    const [image, setImage] = React.useState<HTMLImageElement>();

    const { isModalOpen } = props;
    const uploadDisabled = processedImage === null;
    const canCrop = processedImage !== null && !loading;

    // When the user selects the image they want to crop, first resize the image before cropping
    const onSelectImage = (e: any) => {
      const originalImageFile = e.target.files[0];
      // This needs to be reset if another image is being selected
      setCropState(null);

      const fileSelect = document?.getElementById('fileSelect') as HTMLSelectElement;
      if (!fileSelect) {
        return;
      }

      // 5MB
      if (originalImageFile.size > 5000000) {
        // Clear the file and notify the user
        fileSelect.value = '';
        toast.error('File must be under 5MB');
      } else if (!originalImageFile.type.startsWith('image')) {
        // Clear the file and notify the user
        fileSelect.value = '';
        toast.error('File must be an image');
      } else {
        setLoading(true);
        Resizer.imageFileResizer(
          originalImageFile,
          400,
          400,
          'JPEG',
          100,
          0,
          (blob: any) => {
            setProcessedImage(new File([blob], 'resizedFile.jpeg'));
            onUploadRawImage(processedImage);
          },
          'blob'
        );
      }
    };

    // Once the cropper has loaded the image
    const imageLoaded = (img: HTMLImageElement) => {
      setImage(img);

      // Set the initial cropstate here, otherwise it won't show up until the user creates on themselves
      if (cropState === null) {
        // For centering the crop
        const width = Math.min(img.width, img.height, 200);
        const height = width;
        const x = (img.width - width) / 2;
        const y = (img.height - height) / 2;

        setCropState({
          unit: 'px',
          width,
          height,
          x,
          y,
          aspect: 1,
        });
      }
      return false;
    };

    // When the cropper updates the crop area
    const setCrop = (cropValues: any) => {
      setCropState({
        ...cropValues,
        aspect: 1,
      });
    };

    const getCroppedResizedImage = (): any => {
      if (image && cropState) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = cropState.width;
        canvas.height = cropState.height;
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          return;
        }

        const pixelRatio = window.devicePixelRatio;
        canvas.width = cropState.width * pixelRatio;
        canvas.height = cropState.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
          image,
          cropState.x * scaleX,
          cropState.y * scaleY,
          cropState.width * scaleX,
          cropState.height * scaleY,
          0,
          0,
          cropState.width,
          cropState.height
        );

        return new Promise((resolve, reject) => {
          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject(null);
              } else {
                // Get the cropped image, then clamp the size to 150x150 px
                const initialCrop = new File([blob], 'initialCrop.jpeg');
                const initialSize = initialCrop.size;
                let quality = 100;

                if (initialSize > 100000) {
                  quality = 85;
                } else if (initialSize > 70000) {
                  quality = 90;
                } else if (initialSize > 50000) {
                  quality = 95;
                } else if (initialSize > 30000) {
                  quality = 98;
                }

                Resizer.imageFileResizer(
                  initialCrop,
                  150,
                  150,
                  'JPEG',
                  quality,
                  0,
                  (blob: any) => {
                    const newFile = new File([blob], 'profileImage.jpeg');
                    resolve(newFile);
                  },
                  'blob'
                );
              }
            },
            'image/jpeg',
            1
          );
        });
      }

      return Promise.reject(null);
    };

    const toggleModal = () => {
      setProcessedImage(null);
      setCropState(null);
      props.toggleModal();
    };

    const onUploadRawImage = async (image: Blob) => {
      const { auth } = props;
      const originalImageFormData = new FormData();
      originalImageFormData.append('file', image);
      await auth.updatePractitionerOriginalImage(originalImageFormData);
      setLoading(false);
    };

    const onUpload = async () => {
      const { auth } = props;
      setLoading(true);
      const croppedImageFile = await getCroppedResizedImage();
      if (croppedImageFile) {
        const processedImageFormData = new FormData();
        processedImageFormData.append('file', croppedImageFile);

        const response = await auth.updatePractitionerResizedImage(processedImageFormData);
        if (!response.hasError) {
          toast.success('Image uploaded');
          setLoading(false);
          setProcessedImage(null);
          toggleModal();
        }
      }
    };

    return (
      <>
        <Modal isOpen={isModalOpen} toggle={toggleModal} size='sm' centered>
          <ModalHeader className='bg-light m-0' toggle={toggleModal}>
            Upload New Account Photo test
          </ModalHeader>

          <ModalBody className='container-fluid'>
            <Input
              id='fileSelect'
              type='file'
              accept='.png, .jpg, .jpeg'
              onChange={onSelectImage}
              disabled={loading}
            />

            <div className='d-flex justify-content-center align-items-center mt-3'>
              {loading && <Spinner type='grow' color='primary' />}
            </div>
            {canCrop && (
              <ReactCrop
                src={URL.createObjectURL(processedImage)}
                crop={cropState as any || {}}
                onImageLoaded={imageLoaded}
                onChange={setCrop}
                circularCrop={true}
                keepSelection={true}
              />
            )}
          </ModalBody>

          <ModalFooter>
            <Button className='mx-auto' onClick={onUpload} disabled={uploadDisabled}>
              Upload
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  })
);

export default UploadPictureModal;
