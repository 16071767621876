import styled from 'styled-components';
import { Input, InputGroup, Label } from 'reactstrap';
import Select from 'react-select';

export const InputNum = styled(Input)`
	display: inline-block;
	width: 80px;
	height: 38px;
`;

export const SelectRepeat = styled(Select)`
	display: inline-block;
	width: 110px;
	height: 38px;
`;

export const EndsGrid = styled.div`
	display: grid;
	grid-template-columns: 80px 175px;
	grid-template-rows: repeat(3, auto);
	grid-template-areas:
		'radioNever  .				 '
		'radioAfter  inputAfter		 '
		'radioOn	 reoccurUntilDate';
	align-items: center;
	grid-gap: 10px;
`;

export const CustomRadio = styled(Input)`
	grid-area: ${props => props.gridArea};
`;

export const CustomRadioLabel = styled(Label)`
	line-height: 21px;
`;

export const CustomInputBox = styled(Input)`
	grid-area: ${props => props.gridArea};
`;

export const CustomInputGroup = styled(InputGroup)`
	grid-area: ${props => props.gridArea};
`;
