import styled from 'styled-components';
import { applyStyleModifiers, ModifierKeys } from 'styled-components-modifiers';

export type BaseTextAreaProps = {
  modifiers?: ModifierKeys;
  align?: '';
};

const BASE_TEXTAREA_MODIFIERS = {
  small: () => `
      font-size: var(--font-small);
    `,
  bold: () => `
      --text-font-weight: var(--font-weight-bold);
    `,
  semiBold: () => `
      --text-font-weight: var(--font-weight-semi-bold);     
    `,
};

export const BaseTextArea = styled.textarea<BaseTextAreaProps>`
  font: var(--font-body);
  display: block;
  padding: 0.45rem 1rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 2;
  border-radius: 10px;
  border: solid 1px #4987ce80;
  color: #495057;
  box-sizing: border-box;
  min-height: 100px;

  :focus {
    box-shadow: 0 0 1px 1px var(--blue-100);
    outline: none;
  }

  --text-font-weight: var(--font-weight-regular);

  font: var(--font-body);
  font-weight: var(--text-font-weight);
  ${applyStyleModifiers(BASE_TEXTAREA_MODIFIERS)}
`;
