import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { navigate } from 'react-big-calendar/lib/utils/constants';

import ChevronLeftSvg from 'jsx:assets/svg/chevron-left.svg';
import ChevronRightSvg from 'jsx:assets/svg/chevron-right.svg';

const LeftIcon = styled(ChevronLeftSvg)`
  height: 19px;
  margin-top: 1px;
  margin-bottom: 1px;
  fill: black;
`;

const RightIcon = styled(ChevronRightSvg)`
  height: 19px;
  margin-top: 1px;
  margin-bottom: 1px;
  fill: black;
`;

class CalendarToolbar extends Component {
  render() {
    let {
      localizer: { messages },
      label,
    } = this.props;

    return (
      <div className='rbc-toolbar'>
        <span className='rbc-btn-group'>
          <button type='button' onClick={this.navigate.bind(null, navigate.TODAY)}>
            {messages.today}
          </button>
          <button type='button' onClick={this.navigate.bind(null, navigate.PREVIOUS)}>
            {/* {messages.previous} */}
            <LeftIcon viewBox='0 0 24 24' />
          </button>
          <button type='button' onClick={this.navigate.bind(null, navigate.NEXT)}>
            {/* {messages.next} */}
            <RightIcon viewBox='0 0 24 24' />
          </button>
        </span>

        <span className='rbc-toolbar-label'>{label}</span>

        <span className='rbc-btn-group'>{this.viewNamesGroup(messages)}</span>
      </div>
    );
  }

  navigate = (action) => {
    this.props.onNavigate(action);
  };

  view = (view) => {
    this.props.onView(view);
  };

  viewNamesGroup = (messages) => {
    let viewNames = this.props.views;
    const view = this.props.view;

    if (viewNames.length > 1) {
      return viewNames.map((name) => (
        <button
          type='button'
          key={name}
          className={view === name ? 'rbc-active' : ''}
          onClick={this.view.bind(null, name)}
        >
          {name === 'agenda' ? 'List View' : messages[name]}
        </button>
      ));
    }
  };
}

CalendarToolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
};

export default CalendarToolbar;
