import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import { FooterNav, wizardContainerStyle } from '../WizardForm/styles';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';
import Plus from 'assets/svg/plus.svg';
import { PillButton, Image } from '../WizardForm/styles';
import { WizardFormContext } from '../index';
import { DropdownMenu, DropdownColourStyles } from '../WizardForm/styles';
import { GenderOptions, EthnicityOptions } from '../../../../common/Data/data';
import { EthnicityOptions } from '../../../../common/Data/ethnicityData';

import PractitionerService from '../../../../services/PractitionerService';
import { BlockLoading } from '../../../../common/Loading';
import DOBField from '../../../../shared-ui/DOBField';
import { toast } from 'react-toastify';

const OtherInformationStep = inject('auth')(
  observer((props) => {
    const [isLoading, setIsLoading] = React.useState(true);

    const { onPrevious, onNext, updateData, data, currentStep } =
      React.useContext(WizardFormContext);

    const [genderOption, setGenderOption] = React.useState(data.gender ? data.gender : '');
    const [languageOption, setLanguageOption] = React.useState(data.languages ?? []);
    const [allLanguages, setAllLanguages] = React.useState([]);
    const [ethnicityOptions, setEthnicityOptions] = React.useState(data.ethnicity ?? []);
    const [dateOfBirth, setDateOfBirth] = React.useState(data.dateOfBirth ?? '');

    React.useEffect(() => {
      const promises = [];
      let tempAllLanguages = [];
      let tempLanguages = [];

      if (props.auth.currentPractitioner) {
        promises.push(
          PractitionerService.getLanguages().then((res) => {
            tempAllLanguages = res.data.map((lang) => ({
              value: lang.languageCode,
              label: lang.languageName,
            }));
          })
        );

        promises.push(
          PractitionerService.fetchPractitioner(props.auth.currentPractitioner.id).then((res) => {
            if (res.data.gender) {
              setGenderOption(
                GenderOptions.filter((o) => {
                  return res.data.gender === o.value;
                })
              );
            }
            if (res.data.languages) {
              tempLanguages = res.data.languages;
            }

            let ethnicityInitial = [];
            if (res.data.ethnicity) {
              ethnicityInitial = res.data.ethnicity.map((ethnicity) => ({
                label: ethnicity,
                value: ethnicity,
              }));
            }
            setEthnicityOptions(ethnicityInitial);

            if (res.data.dateOfBirth) {
              setDateOfBirth(res.data.dateOfBirth);
            }
          })
        );

        Promise.all(promises).then(() => {
          setAllLanguages(tempAllLanguages);
          setLanguageOption(tempAllLanguages.filter((l) => tempLanguages.includes(l.value)));
          setIsLoading(false);
        });
      }
    }, [props.auth.currentPractitioner]);

    const goBack = async () => {
      setIsLoading(true);
      updateData({
        gender: genderOption,
        languages: languageOption,
        ethnicity: ethnicityOptions,
      });

      await onPrevious();
    };

    const isValid = React.useCallback(() => {
      return (
        genderOption.length !== 0 &&
        languageOption.length !== 0 &&
        ethnicityOptions.length !== 0 &&
        dateOfBirth !== ''
      );
    }, [genderOption, languageOption, ethnicityOptions, dateOfBirth]);

    const onSubmit = React.useCallback(async () => {
      setIsLoading(true);
      if (isValid()) {
        updateData({
          gender: genderOption,
          languages: languageOption,
          ethnicity: ethnicityOptions,
          dateOfBirth: dateOfBirth,
        });

        const { auth } = props;
        auth
          .updatePractitioner({
            id: auth.currentPractitioner.id,
            gender: genderOption.value,
            languages: languageOption.map((x) => x.value),
            ethnicity: ethnicityOptions.map((x) => x.value),
            dateOfBirth: dateOfBirth,
          })
          .then((response) => {
            if (!response.hasError) {
              onNext();
            } else {
              toast.error(response.message);
            }
          });
      }
    }, [genderOption, ethnicityOptions, languageOption, dateOfBirth, onNext, isValid]);

    const updateTags = (option, selectedCatagory, setSelectedCatagory) => {
      function containsTag(concerns, option) {
        if (option !== null) {
          if (option.length === 0) {
            return false;
          }
          return concerns.value === option[0].value;
        }
        return true;
      }

      const contains = selectedCatagory.some((e) => containsTag(e, option));
      if (!contains && option) {
        setSelectedCatagory(selectedCatagory.concat(option));
      }
    };

    return (
      <>
        <div className={wizardContainerStyle}>
          <div className='flex flex-col w-full'>
            <Heading className='text-center text-dark-blue mb-8' as='h3'>
              About you
            </Heading>
            {isLoading ? <BlockLoading /> : null}

            {/* Gender */}
            <div className='text-left mb-8'>
              <Heading className='text-dark-blue mb-2' as='h4'>
                What gender do you identify with?
              </Heading>
              <DropdownMenu
                name='gender'
                styles={DropdownColourStyles}
                className='bg-white w-full rounded-md border-primary-blue-50 mb-2'
                placeholder='Select one'
                value={genderOption}
                options={GenderOptions}
                onChange={(option) => setGenderOption(option)}
              />
            </div>

            {/* Ethnicity */}
            <div className='text-left mb-8'>
              <Heading className='text-dark-blue mb-2' as='h4'>
                What are your ethnicities?
              </Heading>
              <DropdownMenu
                name='ethnicity'
                styles={DropdownColourStyles}
                className='bg-white w-full rounded-md border-primary-blue-50 mb-2'
                placeholder='Select one or more'
                options={EthnicityOptions}
                onChange={(e) => updateTags(e, ethnicityOptions, setEthnicityOptions)}
                value={null}
                isMulti={true}
                isSearchable={true}
              />
              {ethnicityOptions.map((name) => (
                <PillButton key={name.value}>
                  <div className='flex'>
                    <Text className='text-white flex justify-start'>{name.label}</Text>
                    <Image
                      src={Plus}
                      alt=''
                      onClick={() =>
                        setEthnicityOptions(
                          ethnicityOptions.filter((item) => item.value !== name.value)
                        )
                      }
                      style={{ filter: 'invert(1)' }}
                    />
                  </div>
                </PillButton>
              ))}
            </div>

            {/* Languages */}
            <div className='text-left mb-8'>
              <Heading className='text-dark-blue mb-2' as='h4'>
                What languages do you support in therapy?
              </Heading>
              <DropdownMenu
                name='langages'
                styles={DropdownColourStyles}
                className='bg-white w-full rounded-md border-primary-blue-50 mb-2'
                placeholder='Select one or more'
                options={allLanguages}
                onChange={(e) => updateTags(e, languageOption, setLanguageOption)}
                value={null}
                isMulti={true}
                isSearchable={true}
              />
              {languageOption.length !== 0
                ? languageOption.map((name) => (
                    <PillButton key={name.value}>
                      <div className='flex'>
                        <Text className='text-white flex justify-start'>{name.label}</Text>
                        <Image
                          src={Plus}
                          alt=''
                          onClick={() =>
                            setLanguageOption(
                              languageOption.filter((item) => item.value !== name.value)
                            )
                          }
                          style={{ filter: 'invert(1)' }}
                        />
                      </div>
                    </PillButton>
                  ))
                : null}
            </div>

            {/* Date of Birth */}
            <div className='text-left mb-8'>
              <Heading className='text-dark-blue' as='h4'>
                What's your date of birth?
              </Heading>
              <Text className='mb-2'>
                Please note, clients will only be able to filter their searches by age groups (e.g.,
                20-35, 36-55, and 56+)
              </Text>
              <DOBField value={dateOfBirth} onChange={setDateOfBirth} />
            </div>

            <FooterNav className='md:mt-16 xs:mt-8'>
              <div className='flex justify-start'>
                {currentStep > 0 && (
                  <Button
                    variant='primary'
                    modifiers={'inverted'}
                    onClick={goBack}
                    disabled={isLoading}
                  >
                    Back
                  </Button>
                )}
              </div>
              <div className='flex justify-end'>
                <Button
                  variant='primary'
                  onClick={onSubmit}
                  data-test-id='complete-hear-about-us'
                  disabled={!isValid() || isLoading}
                >
                  Next
                </Button>
              </div>
            </FooterNav>
          </div>
        </div>
      </>
    );
  })
);
export default OtherInformationStep;
