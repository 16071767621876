import React from 'react';
import { BaseText } from './styles';

type TextModifiers =
  | 'tight'
  | 'extraSmall'
  | 'small'
  | 'large'
  | 'bold'
  | 'semiBold'
  | 'blue'
  | 'darkBlue'
  | '';
export interface IText extends React.HTMLAttributes<HTMLParagraphElement> {
  modifiers?: TextModifiers[];
}

const Text: React.FC<IText> = ({ children, modifiers, className, ...rest }) => (
  <BaseText className={`${className ?? ''}`} modifiers={modifiers} {...rest}>
    {children}
  </BaseText>
);

export default Text;
