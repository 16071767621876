import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

export const initGA = () => {
  if (window && process.env.REACT_APP_ENVIRONMENT === 'production') {
    ReactGA.initialize('UA-120681180-1');
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
    });
    window.GA_INITIALIZED = true;
  }
};

export const tagManagerEvent = (eventName, data) => {
  if (window && process.env.REACT_APP_ENVIRONMENT === 'production') {
    console.log('Tagging for', eventName);
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        ...data,
      },
    });
  }
};

export const logPageView = () => {
  if (window && process.env.REACT_APP_ENVIRONMENT === 'production') {
    console.log(`Logging pageview for ${window.location.pathname}`);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }
};

export const logEvent = (category = '', action = '') => {
  if (window && process.env.REACT_APP_ENVIRONMENT === 'production') {
    if (category && action) {
      ReactGA.event({ category, action });
    }
  }
};

export const logException = (description = '', fatal = false) => {
  if (window && process.env.REACT_APP_ENVIRONMENT === 'production') {
    if (description) {
      ReactGA.exception({ description, fatal });
    }
  }
};
