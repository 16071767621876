import axios from 'axios';

export type HereItemData = {
  title: string;
  address: {
    state: string;
    postalCode: string;
    label: string;
    county: string;
    district: string;
    countryName: string;
    countryCode: string;
    city: string;
  };
  id: string;
  position: {
    lat: number;
    lng: number;
  };
};

export interface IResponse {
  status: number;
  authError: boolean;
  hasError: boolean;
  errors: string[] | null | undefined;
  message: string | null | undefined;
  stacktrace: any | null | undefined;
}

export interface IValidationError {
  message: string;
  errors: IFieldValidationError[];
  timestamp: string;
  defaultMessage: string;
}

export interface IFieldValidationError {
  field: string;
  rejectValue: string;
  message: string;
  defaultMessage: string;
}

export interface IRestResponse<T> extends IResponse {
  hasError: boolean;
  data: T & IValidationError;
}

export type HereItemArray = {
  items: HereItemData[];
};

class PlacesService {
  search = (q = '', c = 'NZL'): Promise<IRestResponse<HereItemArray>> => {
    return axios.get('https://discover.search.hereapi.com/v1/geocode', {
      params: {
        q,
        apiKey: 'uHlwbdQv2Niq_mpdXF68Z_Io4EMHJ8Dx1KQPvy-zwWE',
        in: `countryCode:${c}`,
      },
    });
  };
}

export default new PlacesService();
