import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import moment from "moment";
import { observer, inject } from "mobx-react";

import ImmediateGroupForm from "modules/calendar/components/ImmediateGroupForm";

@inject("bookings", "locations", "prices")
@observer
class ImmediateGroupFormModal extends Component {
  constructor(props) {
    super(props);
    Promise.all([props.locations.getLocations(), props.prices.getPrices()]);
  }

  onComplete = async () => {
    const { bookings, toggleModal } = this.props;
    await bookings.fetchGroupBookings();
    toggleModal();
  };

  render() {
    const {
      isModalOpen,
      toggleModal,
      locations,
      prices,
      mode = "create",
      booking = {},
    } = this.props;

    let formState = {};

    if (mode === "edit") {
      const {
        appointmentTime,
        appointmentEndTime,
        serviceLocation = {},
        chargeId,
        appointments,
      } = booking;

      let attendees = [];

      if (appointments && appointments.length) {
        appointments.forEach((appointment) => {
          const { contact } = appointment;
          attendees.push({
            appointment,
            id: contact.id,
            firstName: contact.firstName,
            lastName: contact.lastName,
            email: contact.email,
            dateOfBirth: contact.dateOfBirth,
            ethnicity: contact.ethnicity,
            gender: contact.gender,
            phoneNumber: contact.phoneNumber,
          });
        });
      }

      const selectedSavedLocation = locations.allLocations.find(
        (savedLocation) => savedLocation.location.id === serviceLocation.id
      );
      const selectedPrice = prices.allPrices.find(
        (price) => price.id === chargeId
      );

      // Set up the default state that should be there no matter what
      let formState = {
        ...booking,
        locationId: serviceLocation.id,
        attendees,
        location: selectedSavedLocation && selectedSavedLocation.nickname,
        price:
          selectedPrice &&
          `${selectedPrice.name} - ${selectedPrice.sessionLength}min - $${selectedPrice.cost}`,
        chargeId: chargeId,
        sessionLength: selectedPrice && selectedPrice.sessionLength,
      };

      formState.appointmentStartDateTime = moment(appointmentTime).format(
        "YYYY-MM-DD"
      );
      const startMoment = moment(appointmentTime).second(0);
      const endMoment = moment(appointmentEndTime).second(0);
      formState.startTime = startMoment;
      formState.endTime = endMoment;
      formState.startTimeOption = {
        label: startMoment.format("h:mma"),
        value: startMoment,
      };
      formState.endTimeOption = {
        label: endMoment.format("h:mma"),
        value: endMoment,
      };
    }

    return (
      <Modal isOpen={isModalOpen} toggle={toggleModal} size="md" centered>
        <ModalHeader className="bg-light" toggle={toggleModal}>
          {mode === "edit" ? "Edit Group Booking" : "New Group Booking"}
        </ModalHeader>

        <ModalBody className="p-3">
          <ImmediateGroupForm
            formState={formState}
            status={booking.status}
            onComplete={() => this.onComplete()}
          />
        </ModalBody>
      </Modal>
    );
  }
}

export default ImmediateGroupFormModal;
