import * as React from 'react';

import Box from '../Box';
import { BaseSelect, DropdownLabel } from './styles';

export interface ISelect {
  // required
  value: Object | Array<Object> | null | undefined;
  options: Array<object> | null;
  onChange: (options: { label: string; value: any } | { label: string; value: any }[]) => void;
  // optional
  name?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  blurInputOnSelect?: boolean;
  menuPlacement?: 'bottom' | 'top';
}

const FormDropdown = (props: ISelect) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { label, menuPlacement = 'bottom', ...rest } = props;

  return (
    <Box width="100%">
      {label && <DropdownLabel htmlFor={props.name}>{label}</DropdownLabel>}
      <BaseSelect
        classNamePrefix="react-select"
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        menuIsOpen={isMenuOpen}
        menuPlacement={menuPlacement}
        {...rest}
      />
    </Box>
  );
};

export default FormDropdown;
