"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateResponsiveModifiers = validateResponsiveModifiers;
exports.default = styleModifierPropTypes;

var _difference = _interopRequireDefault(require("lodash/difference"));

var _flatten = _interopRequireDefault(require("lodash/flatten"));

var _forIn = _interopRequireDefault(require("lodash/forIn"));

var _keys = _interopRequireDefault(require("lodash/keys"));

var _uniq = _interopRequireDefault(require("lodash/uniq"));

var _normalizeModifiers = _interopRequireDefault(require("./utils/normalizeModifiers"));

var _isResponsiveModifiersProp = _interopRequireDefault(require("./utils/isResponsiveModifiersProp"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Returns an error if we have invalid modifiers or sizes with errors
 *
 * @param {string} modifiersPropName
 * @param {string} componentName
 * @param {ModifierNames} invalidModifiers
 * @param {string[]} sizesWithErrors
 * @returns {Error}
 */
function generateError(modifiersPropName, componentName, invalidModifiers, sizesWithErrors) {
  var m = invalidModifiers.length > 1 ? 'modifiers' : 'modifier';
  var modifierList = invalidModifiers.join(', ');
  var k = sizesWithErrors && sizesWithErrors.length > 1 ? 'keys' : 'key';
  return new Error("Invalid ".concat(m, " ").concat(modifierList, " used in prop '").concat(modifiersPropName, "'").concat(sizesWithErrors ? " (size ".concat(k, " ").concat(sizesWithErrors.join(', '), ")") : '', " and supplied to ").concat(componentName, ". Validation failed."));
}
/**
 * Returns the invalid modifiers
 *
 * @param {ModifierKeys} modifierKeys
 * @param {ModifiersConfig} modifiersConfig
 * @returns {string[]}
 */


function getInvalidModifiers(modifierKeys, modifiersConfig) {
  return (0, _difference.default)((0, _normalizeModifiers.default)(modifierKeys), (0, _keys.default)(modifiersConfig));
}
/**
 * Checks for invalid modifiers
 *
 * @param {string} modifiersPropName
 * @param {string} componentName
 * @param {ModifierKeys} modifierKeys
 * @param {ModifiersConfig} modifierConfig
 * @returns {Error|Null}
 */


function validateModifiers(modifiersPropName, componentName, modifierKeys, modifierConfig) {
  var invalidModifiers = getInvalidModifiers(modifierKeys, modifierConfig);

  if (invalidModifiers.length > 0) {
    return generateError(modifiersPropName, componentName, invalidModifiers);
  }

  return null;
}
/**
 * Checks for invalid modfiers for responsive modifiers
 *
 * @export
 * @param {string} modifiersPropName
 * @param {string} componentName
 * @param {ResponsiveModifiersProp} responsiveModifiers
 * @param {ModifiersConfig} modifierConfig
 * @returns {Error|Null}
 */


function validateResponsiveModifiers(modifiersPropName, componentName, responsiveModifiers, modifierConfig) {
  var rawInvalidModifiers = [];
  var rawSizesWithErrors = [];
  (0, _forIn.default)(responsiveModifiers, function (modifiers, size) {
    var invalidModifiers = getInvalidModifiers(modifiers, modifierConfig);

    if (invalidModifiers.length > 0) {
      rawInvalidModifiers.push(invalidModifiers);
      rawSizesWithErrors.push(size);
    }
  });
  var invalidModifiers = (0, _uniq.default)((0, _flatten.default)(rawInvalidModifiers));
  var sizesWithErrors = (0, _uniq.default)(rawSizesWithErrors);

  if (invalidModifiers.length > 0) {
    return generateError(modifiersPropName, componentName, invalidModifiers, sizesWithErrors);
  }

  return null;
}
/**
 * Evaluates the modifiers prop against the modifier config. Throws invalid proptype error
 * if a modifier is supplied in prop and not found in modifier config.
 * @export
 * @param {ModifiersConfig} modifierConfig
 * @returns {Validator<ModifierKeys>}
 */


function styleModifierPropTypes(modifierConfig) {
  var validator = function validator(props, modifiersPropName, componentName) {
    var modifiers = props[modifiersPropName];

    if ((0, _isResponsiveModifiersProp.default)(modifiers)) {
      return validateResponsiveModifiers(modifiersPropName, componentName, modifiers, modifierConfig);
    }

    return validateModifiers(modifiersPropName, componentName, modifiers, modifierConfig);
  };

  return validator;
}