import * as React from 'react';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import clsx from 'clsx';

type LinkProps = {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  textClassName?: string;
  title?: string;
};

const Link: React.FC<LinkProps> = ({
  children,
  onClick,
  disabled = false,
  className,
  textClassName,
  title,
}) => {
  return (
    <button onClick={onClick} disabled={disabled} className={className} title={title}>
      <Text className={clsx('font-bold cursor-pointer text-sm hover:underline', textClassName)}>
        {children}
      </Text>
    </button>
  );
};

export default Link;
