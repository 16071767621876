import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import moment from 'moment';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { MoreHorizontal } from 'react-feather';

import Timeline from 'components/Timeline';
import TimelineItem from 'components/TimelineItem';
import { Loading } from 'common';
import SessionLimitText from '../Bookings/SessionLimitText';

@inject('auth')
@observer
class SelectedClient extends Component {
  @observable pageSize = 5;

  componentDidUpdate = (prevProps) => {
    const { client: oldClient } = prevProps;
    const { client: newClient } = this.props;

    if (oldClient.id !== newClient.id) {
      this.pageSize = 5;
    }
  };

  showMore = () => {
    this.pageSize = this.pageSize + 5;
  };

  getTimelineHistory = () => {
    const {
      client: { appointments },
      appointmentsLoading,
      selectBooking,
    } = this.props;

    if (appointmentsLoading) {
      return <Loading className='mt-4' />;
    } else if (!appointments || appointments.length === 0) {
      return (
        <Timeline className='mt-2'>
          <p className='font-italic'>No appointments yet</p>
        </Timeline>
      );
    }

    const shownAppointments = appointments.slice(0, this.pageSize);

    return (
      <>
        <Timeline className='mt-2'>
          {shownAppointments.map((appt) => {
            const dateTime = moment.utc(appt.appointmentTimeUTC).tz(this.props.auth.currentPractitioner.timezone);
            const dateStr = dateTime.format('ddd Do MMM');
            const timeStr = dateTime.format('h:mma');

            let statusClassName = 'text-muted';
            if (appt.status === 'Confirmed') {
              statusClassName = 'text-success';
            } else if (appt.status === 'Pending') {
              statusClassName = 'text-warning';
            }

            return (
              <TimelineItem
                key={appt.id}
                onClick={selectBooking.bind(this, appt)}
                className='cursor-pointer'
              >
                <strong>Appointment</strong>
                <span className='float-right text-muted text-sm'>{`${appt.durationMin}min`}</span>
                <p>
                  {dateStr}
                  <br />
                  {timeStr}
                  <br />
                  <Label className={statusClassName}>{appt.status}</Label>
                </p>
              </TimelineItem>
            );
          })}
        </Timeline>
        {this.pageSize < appointments.length && (
          <Button className='d-block mx-auto' onClick={this.showMore}>
            Show More
          </Button>
        )}
      </>
    );
  };

  render() {
    const { client, editClient, deleteClient, hideDropdown } = this.props;
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      dateOfBirth,
      gender,
      ethnicity,
      timezone,
      maxChargePerSession,
      eligibleForTherapy,
    } = client;

    const formattedDob = dateOfBirth && moment(dateOfBirth, 'YYYY-MM-DD').format('DD-MM-YYYY');

    const maxSessionPriceText = maxChargePerSession
      ? ` - ${maxChargePerSession} per session max`
      : '';

    return (
      <Card id='clientCard'>
        <CardHeader>
          {!hideDropdown && (
            <div className='card-actions float-right'>
              <UncontrolledDropdown>
                <DropdownToggle tag='a'>
                  <MoreHorizontal />
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem onClick={editClient.bind(this, client)}>Edit</DropdownItem>
                  <DropdownItem onClick={deleteClient.bind(this, client)}>Delete</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )}
          <CardTitle tag='h5' className='mb-0'>
            {`${firstName} ${lastName}`}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Table size='sm' className='my-2 client-table' striped={true}>
            <tbody>
              <tr>
                <td>Name</td>
                <td title={`${firstName} ${lastName}`}>{`${firstName} ${lastName}`}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td title={email ? email : '-'}>{email ? email : '-'}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td title={phoneNumber ? phoneNumber : '-'}>{phoneNumber ? phoneNumber : '-'}</td>
              </tr>
              <tr>
                <td>DOB</td>
                <td title={formattedDob ? formattedDob : '-'}>
                  {formattedDob ? formattedDob : '-'}
                </td>
              </tr>
              <tr>
                <td>Gender</td>
                <td title={gender ? gender : '-'}>{gender ? gender : '-'}</td>
              </tr>
              <tr>
                <td>Ethnicity</td>
                <td title={ethnicity ? ethnicity : '-'}>{ethnicity ? ethnicity : '-'}</td>
              </tr>
              <tr>
                <td>Client Timezone</td>
                <td title={timezone ? timezone : '-'}>{timezone ? timezone : '-'}</td>
              </tr>
              <tr>
                <td>EAP</td>
                <td>
                  {eligibleForTherapy && 'Yes ('}
                  {/* {eligibleForTherapy && <SessionLimitText contact={client} />} */}
                  {eligibleForTherapy && `)${maxSessionPriceText}`}
                  {!eligibleForTherapy && 'No'}
                </td>
              </tr>
            </tbody>
          </Table>

          <hr />

          <strong>Appointment History</strong>
          {this.getTimelineHistory()}
        </CardBody>
      </Card>
    );
  }
}

export default SelectedClient;
