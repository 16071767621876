import * as React from 'react';
import styled from 'styled-components';
import BigCalendar from 'react-big-calendar';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { days } from './data';
import debounce from 'debounce-promise';
import CustomDay from './custom_day';

const moment = extendMoment(Moment);
const localizer = BigCalendar.momentLocalizer(moment);

const scrollToDate = moment().hour(9).minute(0).second(0).toDate();

const components = {
  week: {
    header: ({ date, localizer }) => localizer.format(date, 'dddd'),
  },
};

const StyledCalendar = styled(BigCalendar)`
  .rbc-toolbar { 
    .rbc-btn-group:last-child {
      display: none;
    }

    .rbc-btn-group:first-child > button:first-child  {
      display: none;
    }
  }

  .rbc-time-header > .rbc-row:nth-of-type(2) {
    display: none !important;
  }

  .rbc-label.rbc-time-header-gutter {
    width: 62.2344px !important;
    min-width: 62.2344px !important;
    max-width: 62.2344px !important;
  }
`;

const Calendar = ({
  times,
  setServiceModalOpen,
  addTimeSlot,
  weeklyHours,
  setCurrentModel,
  checkForOverlaps,
}) => {
  const [calendarView, setCalendarView] = React.useState('week');

  React.useEffect(() => {
    checkCalendarSize();
    window.addEventListener('resize', debounce(() => checkCalendarSize(), 25));
  }, []);

  const checkCalendarSize = () => {
    const calendarCard = document.getElementById('js-availabilityCalendarCard');

    if (calendarCard) {
      const cardRect = calendarCard.getBoundingClientRect();
      if (cardRect.width < 800) {
        setCalendarView(BigCalendar.Views.DAY);
        document.getElementsByClassName("rbc-toolbar")[0].classList.remove("hidden");
      } else {
        setCalendarView(BigCalendar.Views.WEEK);
        document.getElementsByClassName("rbc-toolbar")[0].classList.add("hidden");
      }
    }
  };

  const onClick = (event) => {
    const start = moment(event.start).format('HH:mm:ss');
    const end = moment(event.end).format('HH:mm:ss');
    const currentDay = moment(event.start).format('dddd');

    const modalObj = {
      number: {},
      index: null,
      day: null,
    };

    weeklyHours[currentDay].forEach((day, index) => {
      if (day.startTime.value === start && day.endTime.value === end) {
        days.forEach((dayWeek) => {
          if (dayWeek.name === currentDay) {
            modalObj.day = dayWeek;
          }
        });
        modalObj.number = day;
        modalObj.index = index;
      }
    });
    setCurrentModel(modalObj);
    setServiceModalOpen(true);
  };

  const onCreate = (event) => {
    const start = moment(event.start).format('HH:mm:ss');
    const end = moment(event.end).format('HH:mm:ss');
    const currentDay = moment(event.start).format('dddd');

    const timeObj = {
      startTime: { label: moment(event.start).format('hh:mm a'), value: start },
      endTime: { label: moment(event.end).format('hh:mm a'), value: end },
    };

    let conflict = checkForOverlaps(timeObj, currentDay, false);
    if (!conflict) {
      addTimeSlot(currentDay, timeObj);
    }
  };

  return (
    <>
      <StyledCalendar
        style={{ height: 500 }}
        className="w-full"
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        events={times}
        selectable
        scrollToTime={scrollToDate}
        components={components}
        onSelectEvent={(e) => onClick(e)}
        onSelectSlot={(e) => onCreate(e)}
        defaultDate={new Date(2022, 0, 20)}
        view={calendarView}
        views={{ week: true, day: CustomDay }}
      />
    </>
  );
};

export default Calendar;
