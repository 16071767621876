import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { observable } from 'mobx';
import { inject, observer, Observer } from 'mobx-react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
} from 'reactstrap';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import debounce from 'debounce-promise';

import { AsyncSearch, Control } from 'common/AsyncSearch';

import AsyncOption from './async_option';
import ToggleButton from '../ToggleButton';

const moment = extendMoment(Moment);

const schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  contactName: Yup.string(),
  dateOfBirth: Yup.string().test(
    'dateOfBirth',
    'Please enter a valid date of birth, you must be at least 1 years of age',
    (value) => {
      return value ? moment().diff(moment(value), 'years') >= 1 : true;
    }
  ),
  email: Yup.string(),
  ethnicity: Yup.string(),
  gender: Yup.string(),
  phoneNumber: Yup.string(),
});

@inject('locations', 'bookings', 'calendar', 'prices')
@observer
class AttendeeFormModal extends Component {
  @observable forceError = false;
  @observable existingClientFormState = {};
  @observable searchMenuOpen = false;

  static defaultProps = {
    onComplete: () => {},
    status: null,
  };

  constructor(props) {
    super(props);

    const wait = 1000; // milliseconds
    const loadOptions = (inputValue) => this.getAsyncOptions(inputValue);
    this.debouncedLoadOptions = debounce(loadOptions, wait, {
      leading: true,
    });

    this.today = moment().format('YYYY-MM-DD');
  }

  validate = (validateForm, handleSubmit) => {
    validateForm().then((data) => {
      const hasError = Object.keys(data).some((field) => field && field.length > 0);
      if (hasError) {
        this.forceError = true;
      } else {
        handleSubmit();
      }
    });
  };

  hasError = (touched, error) => (touched || this.forceError ? (error ? true : false) : false);

  getAsyncOptions = (value) => {
    const {
      calendar: { getContactsBySearchTerm },
    } = this.props;

    return new Promise((resolve, reject) => {
      this.searchMenuOpen = false;
      getContactsBySearchTerm(value).then((response) => {
        this.searchMenuOpen = true;
        if (!response.hasError) {
          const hits = response.data.content.map((client) => ({
            label: `${client.contactName} ${client.email ? ' - ' + client.email : ''} ${
              client.phoneNumber && client.phoneNumber ? ' - ' + client.phoneNumber : ''
            }`,
            value: client.contactName,
            client,
          }));
          resolve(hits);
        }
        this.searchMenuOpen = true;
        resolve([]);
      });
    });
  };

  getSubmitError = (status) => {
    if (status && status.message) {
      return <FormFeedback>{status.message}</FormFeedback>;
    }
    return null;
  };

  getLocationOptions = () => {
    const {
      locations: { allLocations },
    } = this.props;

    if (allLocations) {
      return allLocations.map((location) => (
        <option key={location.location.id} data-location-id={location.location.id}>
          {location.nickname}
        </option>
      ));
    }
    return null;
  };

  getPriceOptions = () => {
    const {
      prices: { allPrices },
    } = this.props;

    if (allPrices) {
      return allPrices.map((price) => (
        <option key={price.id} data-price-id={price.id} data-session-length={price.sessionLength}>
          {`${price.name} - ${price.sessionLength}min - $${price.cost}`}
        </option>
      ));
    }
    return null;
  };

  onValidate = (values) => {
    let errors = {};

    if (values.startTime && values.endTime) {
      const start = moment(values.startTime);
      const end = moment(values.endTime);
      if (start.isSameOrAfter(end)) {
        errors.startTime = 'Start time must occur before the end time.';
      }
    }

    return errors;
  };

  closeMenu = () => {
    this.searchMenuOpen = false;
  };

  onClientSelect = (formState, option, setFieldValue) => {
    const { client } = option;
    // Remove null fields
    Object.keys(client).forEach((key) => client[key] == null && delete client[key]);

    let contact = {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      ethnicity: '',
      gender: '',
      phoneNumber: '',
      ...client,
    };

    setFieldValue('id', contact.id);
    setFieldValue('firstName', contact.firstName);
    setFieldValue('lastName', contact.lastName);
    setFieldValue('dateOfBirth', contact.dateOfBirth);
    setFieldValue('email', contact.email);
    setFieldValue('ethnicity', contact.ethnicity);
    setFieldValue('gender', contact.gender);
    setFieldValue('phoneNumber', contact.phoneNumber);

    setFieldValue('clientType', 'existing');
    setFieldValue('asyncInputsDisabled', false);
  };

  getAsyncSearch = (formState, setFieldValue) => {
    return (
      <AsyncSearch
        classNamePrefix='react-select'
        placeholder='Search for clients'
        loadOptions={(value) => this.debouncedLoadOptions(value)}
        onChange={(option) => {
          setFieldValue('asyncInputsDisabled', false);
          this.onClientSelect(formState, option, setFieldValue);
        }}
        components={{
          Control,
          Option: AsyncOption,
        }}
        onBlur={this.closeMenu}
        onMenuClose={this.closeMenu}
      />
    );
  };

  onNewClientClick = (mode, setFieldValue) => {
    if (mode !== 'edit') {
      setFieldValue('id', null);
      setFieldValue('firstName', '');
      setFieldValue('lastName', '');
      setFieldValue('dateOfBirth', '');
      setFieldValue('email', '');
      setFieldValue('ethnicity', '');
      setFieldValue('gender', '');
      setFieldValue('phoneNumber', '');
    }
  };

  render() {
    const { isOpen, toggleModal, attendee, onComplete } = this.props;
    const mode = attendee.mode;
    const title = mode === 'edit' ? 'Update Client' : 'Add Client';

    return (
      <Modal isOpen={isOpen} toggle={toggleModal} size='md' centered>
        <ModalHeader className='bg-light' toggle={toggleModal}>
          {title}
        </ModalHeader>

        <ModalBody className='p-3'>
          <Formik
            initialValues={{
              ...attendee,
              clientType: attendee && attendee.id > 0 ? 'existing' : 'new',
              asyncInputsDisabled: true,
            }}
            validationSchema={schema}
            enableReinitialize={false}
            validate={this.onValidate}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              onComplete(values);
              actions.setSubmitting(false);
            }}
            render={({
              values,
              errors,
              status,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              validateForm,
              setFieldValue,
            }) => {
              const firstNameTouched = touched && touched.firstName;
              const firstNameErrors = errors && errors.firstName;
              const lastNameTouched = touched && touched.lastName;
              const lastNameErrors = errors && errors.lastName;
              const emailTouched = touched && touched.email;
              const emailErrors = errors && errors.email;
              const phoneNumberTouched = touched && touched.phoneNumber;
              const phoneNumberErrors = errors && errors.phoneNumber;
              const dateOfBirthTouched = touched && touched.dateOfBirth;
              const dateOfBirthErrors = errors && errors.dateOfBirth;
              const ethnicityTouched = touched && touched.ethnicity;
              const ethnicityErrors = errors && errors.ethnicity;
              const genderTouched = touched && touched.gender;
              const genderErrors = errors && errors.gender;

              const asyncInputsDisabled =
                values.clientType === 'existing' && values.asyncInputsDisabled;

              return (
                <Observer>
                  {() => (
                    <>
                      <Form onSubmit={() => this.validate(validateForm, handleSubmit)}>
                        {mode !== 'edit' && (
                          <>
                            <div className='mb-6 justify-content-center text-center'>
                              <ToggleButton.Secondary
                                className='mr-2'
                                onClick={() => {
                                  this.onNewClientClick(mode, setFieldValue);
                                  setFieldValue('clientType', 'new');
                                }}
                                active={values.clientType === 'new'}
                                title='New'
                              />
                              <ToggleButton.Secondary
                                onClick={() => setFieldValue('clientType', 'existing')}
                                active={values.clientType === 'existing'}
                                title='Existing'
                              />
                            </div>
                            {values.clientType === 'existing' && (
                              <div className='mb-3'>
                                {this.getAsyncSearch(values, setFieldValue)}
                              </div>
                            )}
                          </>
                        )}

                        <Row form>
                          <Col className='col-12 col-sm-6'>
                            <FormGroup>
                              <Label>First Name *</Label>
                              <Input
                                bsSize='lg'
                                name='firstName'
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={this.hasError(firstNameTouched, firstNameErrors)}
                                disabled={asyncInputsDisabled}
                              />
                              {this.hasError(firstNameTouched, firstNameErrors) ? (
                                <FormFeedback>{firstNameErrors}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col className='col-12 col-sm-6'>
                            <FormGroup>
                              <Label>Last Name *</Label>
                              <Input
                                bsSize='lg'
                                name='lastName'
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={this.hasError(lastNameTouched, lastNameErrors)}
                                disabled={asyncInputsDisabled}
                              />
                              {this.hasError(lastNameTouched, lastNameErrors) ? (
                                <FormFeedback>{lastNameErrors}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row form>
                          <Col className='col-12 col-sm-6'>
                            <FormGroup>
                              <Label>Email</Label>
                              <Input
                                bsSize='lg'
                                name='email'
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={this.hasError(emailTouched, emailErrors)}
                                disabled={asyncInputsDisabled}
                              />
                              {this.hasError(emailTouched, emailErrors) ? (
                                <FormFeedback>{emailErrors}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col className='col-12 col-sm-6'>
                            <FormGroup>
                              <Label>Mobile</Label>
                              <Input
                                bsSize='lg'
                                name='phoneNumber'
                                value={values.phoneNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={this.hasError(phoneNumberTouched, phoneNumberErrors)}
                                disabled={asyncInputsDisabled}
                              />
                              {this.hasError(phoneNumberTouched, phoneNumberErrors) ? (
                                <FormFeedback>{phoneNumberErrors}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row form>
                          <Col className='col-12 col-sm-6'>
                            <FormGroup>
                              <Label>Date of Birth</Label>
                              <Input
                                bsSize='lg'
                                type='date'
                                name='dateOfBirth'
                                value={values.dateOfBirth}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={this.hasError(dateOfBirthTouched, dateOfBirthErrors)}
                                disabled={asyncInputsDisabled}
                              />
                              {this.hasError(dateOfBirthTouched, dateOfBirthErrors) ? (
                                <FormFeedback>{dateOfBirthErrors}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col className='col-12 col-sm-6'>
                            <FormGroup>
                              <Label>Ethnicity</Label>
                              <Input
                                bsSize='lg'
                                type='select'
                                name='ethnicity'
                                value={values.ethnicity}
                                onChange={(e) => setFieldValue('ethnicity', e.target.value)}
                                invalid={this.hasError(ethnicityTouched, ethnicityErrors)}
                                disabled={asyncInputsDisabled}
                              >
                                <option />
                                <option>Pakeha / NZ European</option>
                                <option>Māori</option>
                                <option>Samoan</option>
                                <option>Tongan</option>
                                <option>Pacific Islander - Other</option>
                                <option>Chinese</option>
                                <option>Indian</option>
                                <option>Asian - Other</option>
                                <option>European - Other</option>
                                <option>African</option>
                                <option>Other</option>
                              </Input>
                              {this.hasError(ethnicityTouched, ethnicityErrors) ? (
                                <FormFeedback>{ethnicityErrors}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row form>
                          <Col className='col-12 col-sm-6'>
                            <FormGroup>
                              <Label>Gender</Label>
                              <Input
                                bsSize='lg'
                                type='select'
                                name='gender'
                                value={values.gender}
                                onChange={(e) => setFieldValue('gender', e.target.value)}
                                invalid={this.hasError(genderTouched, genderErrors)}
                                disabled={asyncInputsDisabled}
                              >
                                <option />
                                <option>Male</option>
                                <option>Female</option>
                                <option>Gender Diverse</option>
                                <option>Undisclosed</option>
                              </Input>
                              {this.hasError(genderTouched, genderErrors) ? (
                                <FormFeedback>{genderErrors}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>

                        {this.getSubmitError(status)}

                        <div className='text-center mt-2'>
                          <Button
                            color='primary'
                            size='lg'
                            onClick={() => this.validate(validateForm, handleSubmit)}
                            disabled={isSubmitting}
                          >
                            {mode === 'edit' ? 'Update Client' : 'Add Client'}
                          </Button>
                        </div>
                      </Form>
                    </>
                  )}
                </Observer>
              );
            }}
          />
        </ModalBody>
      </Modal>
    );
  }
}

export default AttendeeFormModal;
