import { flow, types } from 'mobx-state-tree';

import PractitionerService from 'services/PractitionerService';
import { tagManagerEvent } from '../../../utilities/analytics';

const IntegrationComponent = types.model('IntegrationComponent', {
  id: types.identifierNumber,
  enabled: types.boolean,
  error: types.boolean,
  errorMessage: types.maybeNull(types.string),
  calendarName: types.string,
  calendarId: types.string,
  externalReference: types.maybeNull(types.string),
  lastSync: types.maybeNull(types.string),
  timezone: types.maybeNull(types.string),
  syncInProgress: types.boolean,
  syncStarted: types.maybeNull(types.string),
  publishEventsTo: types.boolean,
  copyDetails: types.boolean,
});

const Integration = types.model('Integration', {
  id: types.identifierNumber,
  externalSystem: types.string,
  error: types.boolean,
  errorMessage: types.maybeNull(types.string),
  accountEmail: types.maybeNull(types.string),
  lastSync: types.maybeNull(types.string),
  syncInProgress: types.maybeNull(types.boolean),
  syncStarted: types.maybeNull(types.string),
  latestPermissionVersion: types.number,
  components: types.maybeNull(types.array(IntegrationComponent)),
  authenticated: types.boolean,
  initalized: types.boolean,
});

const Integrations = types
  .model('Integrations', {
    connections: types.maybeNull(types.array(Integration)),
    refreshId: types.maybeNull(types.number),
  })
  .actions((self) => ({
    getIntegrations: flow(function* () {
      const response = yield PractitionerService.getPractitionerIntegrations();
      if (!response.hasError) {
        self.connections = response.data;

        if (self.connections.find((a) => a.syncInProgress)) {
          self.refreshIntegrations();
        }
      }

      return response;
    }),
    update: flow(function* (data) {
      const response = yield PractitionerService.updatePractitionerIntegrations(
        data
      );
      if (!response.hasError) {
        self.refreshIntegrations();
      }
    }),
    refreshIntegrations: function () {
      if (!self.refreshId) {
        self.refreshId = setInterval(self.getIntegrations, 10000);
      }
    },
    stopIntegrationsRefresh: function () {
      // If a patch interval exists then reset it
      if (typeof self.refreshId === 'number') {
        clearInterval(self.refreshId);
      }
    },
    getGoogleAuthorizationUrl: flow(function* () {
      return yield PractitionerService.getGoogleAuthorizationUrl();
    }),
    connectToGoogleCalendar: flow(function* (data) {
      const res = yield PractitionerService.connectToGoogleCalendar(data);

      if (!res.hasError) {
        tagManagerEvent('Practitioner Connected Google Calendar');
      }
      self.refreshIntegrations();
      return res;
    }),
    disconnectFromGoogleCalendar: flow(function* () {
      yield PractitionerService.disconnectFromGoogleCalendar();
      return yield self.getIntegrations();
    }),
    syncWithGoogle: flow(function* () {
      yield PractitionerService.syncWithGoogle();
      self.refreshIntegrations();
      return yield self.getIntegrations();
    }),
    getOutlookAuthorizationUrl: flow(function* () {
      return yield PractitionerService.getOutlookAuthorizationUrl();
    }),
    connectToOutlookCalendar: flow(function* (data) {
      const res = yield PractitionerService.connectToOutlookCalendar(data);

      if (!res.hasError) {
        tagManagerEvent('Practitioner Connected Outlook Calendar');
      }
      self.refreshIntegrations();

      return res;
    }),
    disconnectFromOutlookCalendar: flow(function* () {
      yield PractitionerService.disconnectFromOutlookCalendar();
      return yield self.getIntegrations();
    }),
    syncWithOutlook: flow(function* () {
      yield PractitionerService.syncWithOutlook();
      self.refreshIntegrations();

      return yield self.getIntegrations();
    }),
  }))
  .views((self) => ({
    get isGoogleConnected() {
      if (
        self.connections &&
        self.connections.find((data) => data.externalSystem === 'Google')
      ) {
        return true;
      }
      return false;
    },
    get isOutlookConnected() {
      if (
        self.connections &&
        self.connections.find((data) => data.externalSystem === 'Outlook')
      ) {
        return true;
      }
      return false;
    },
    get isError() {
      if (self.connections && self.connections.find((data) => data.error)) {
        return true;
      }
      return false;
    },
    get isSyncInProgress() {
      if (
        self.connections &&
        self.connections.find((data) => data.syncInProgress)
      ) {
        return true;
      }
      return false;
    },
  }));

export default Integrations;
