import React, { Component } from 'react';
import { Container } from 'reactstrap';

import { withKeycloak } from '@react-keycloak-fork/web';

@withKeycloak
class SecuritySettings extends Component {
  render() {
    const { keycloak } = this.props;

    return (
      <Container fluid className="p-0 h-100">
        <iframe
          title="Security Settings"
          src={keycloak.createAccountUrl()}
          style={{
            width: '100%',
            height: '80vh',
            margin: 0,
            padding: 0,
            border: 0,
          }}
        />
      </Container>
    );
  }
}

export default SecuritySettings;
