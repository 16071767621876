import styled from 'styled-components';

export const CenterText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 150px 0 100px 0;
`;

export const HeadingText = styled.h1`
  font-size: 25px;
  color: black;
  font-weight: bold;
`;

export const Child = styled.section``;

export const Question = styled.div`
  border: 1px solid lightgrey;
  padding: 15px;
  background-color: inherit;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
`;

export const Answer = styled.div`
  border: 1px solid lightgrey;
  padding: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 15px;
  background-color: #f5f6ff;

  ul {
    list-style-type: disc;
    padding-left: 1.5rem;
  }
`;

export const Text = styled.div`
  margin: 0 0 0 20px;
`;
