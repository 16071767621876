import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Button, Input } from 'reactstrap';
import { toast } from 'react-toastify';

import { BookingCompleteScreenProps, errorMessage } from '..';
import ToggleButton from '../../../../../common/ToggleButton';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';

type PractitionerDNAScreenProps = {
  history: any;
  calendar: any;
  match: any;
  booking: any;
} & BookingCompleteScreenProps;

const PractitionerDNAScreen: React.FC<PractitionerDNAScreenProps> = ({
  history,
  calendar,
  setScreen,
  match,
  booking,
}) => {
  const [rebook, setRebook] = React.useState<boolean>(true);
  const [reason, setReason] = React.useState<string>('');
  const bookingId = match.params.id;

  const onNext = () => {
    const data = {
      completionStatus: 'THERAPIST_DID_NOT_ATTEND',
      chargeForSession: false,
      reason: rebook ? '' : reason,
      therapistWillRebook: rebook,
    };

    calendar.completeAppointment(bookingId, data).then((res) => {
      if (!res.hasError) {
        if (rebook) {
          history.push(`/app/bookings?createAppointment=true&bookingId=${bookingId}`);
        } else {
          setScreen('COMPLETE_PRACTITIONER_DNA');
        }
      } else {
        toast.error(errorMessage);
      }
    });
  };

  return (
    <>
      <div className='flex sm:items-center flex-col sm:flex-row mb-8 sm:mb-12'>
        <Text className='sm:mr-8 mb-2 sm:mb-0'>
          Would you like to book another time with {booking.contact.contactName}?
        </Text>
        <div>
          <ToggleButton.Secondary
            className='mb-2 sm:mb-0 mr-2'
            onClick={() => setRebook(true)}
            active={rebook}
            title='Yes'
          />
          <ToggleButton.Secondary onClick={() => setRebook(false)} active={!rebook} title='No' />
        </div>
      </div>

      <div className={rebook ? 'hidden' : 'mb-12'}>
        <Text className='mb-2'>Please explain why?</Text>
        <Input bsSize='lg' value={reason} onChange={(x) => setReason(x.currentTarget.value)} />
      </div>
      <Button
        color='primary'
        size='lg'
        className='rounded-full h-10 w-32'
        disabled={!rebook && reason.length == 0}
        onClick={onNext}
      >
        Finish
      </Button>
    </>
  );
};

export default withRouter(inject('calendar')(observer(PractitionerDNAScreen)));
