import { types, flow } from 'mobx-state-tree';
import InvoiceService from 'services/InvoiceService';

import { Client } from '../../clients/models';
import { Booking } from '../../bookings/models';

const InvoiceParty = types.model('InvoiceParty', {
  party: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  floorNumber: types.maybeNull(types.string),
  streetAddress: types.maybeNull(types.string),
  suburb: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  postCode: types.maybeNull(types.string),
  logo: types.maybeNull(types.string),
  region: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
});

const InvoiceSettings = types.model('InvoiceSettings', {
  taxEnabled: types.maybeNull(types.boolean),
  taxNumber: types.maybeNull(types.string),
  bankAccountNumber: types.maybeNull(types.string),
  invoiceFrom: types.maybeNull(InvoiceParty),
  invoiceFooter: types.maybeNull(types.string),
  daysUntilInvoiceDue: types.maybeNull(types.number),
  pricesIncludeTax: types.maybeNull(types.boolean),
});

const AddressItem = types.model('AddressItem', {
  line: types.maybeNull(types.string),
  suburb: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  postcode: types.maybeNull(types.string),
});

const LineItem = types.model('LineItem', {
  id: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
  total: types.maybeNull(types.number),
  tax: types.maybeNull(types.number),
  amount: types.maybeNull(types.number),
});

const Invoice = types
  .model('Invoice', {
    id: types.identifierNumber,
    gstNumber: types.maybeNull(types.string),
    address: types.maybeNull(AddressItem),
    invoiceDate: types.maybeNull(types.string),
    appointment: types.maybeNull(Booking),
    dueDate: types.maybeNull(types.string),
    billTo: types.maybeNull(InvoiceParty),
    billAddress: types.maybeNull(AddressItem),
    billFrom: types.maybeNull(InvoiceParty),
    status: types.maybeNull(types.string),
    lineItems: types.array(LineItem),
    client: types.maybeNull(Client),
    notes: types.maybeNull(types.string),
    creationDateTime: types.maybeNull(types.string),
  })
  .views((self) => ({
    get total() {
      let total = 0;
      if (self.lineItems && self.lineItems.length) {
        self.lineItems.forEach((item) => (total += item.total));
      }
      return total;
    },
    get tax() {
      let total = 0;
      if (self.lineItems && self.lineItems.length) {
        self.lineItems.forEach((item) => (total += item.tax));
      }
      return total;
    },
  }));

const Invoices = types
  .model('Invoices', {
    all: types.map(Invoice),
    settings: types.maybeNull(InvoiceSettings),
  })
  .actions((self) => ({
    search: flow(function* (data) {
      const response = yield InvoiceService.search(data);
      if (!response.hasError) {
        self.all = {};
        response.data.content.forEach((d) => self.all.set(d.id, d));
      }
      return response;
    }),
    related: flow(function* (id) {
      const response = yield InvoiceService.related(id);
      if (!response.hasError) {
        response.data.forEach((d) => self.all.set(d.id, d));
      }
      return response;
    }),
    get: flow(function* (id) {
      const response = yield InvoiceService.get(id);
      if (!response.hasError) {
        self.all.set(response.data.id, response.data);
      }
      return response;
    }),
    create: flow(function* (data) {
      const response = yield InvoiceService.create(data);
      if (response.hasError) {
        self.all.set(response.data.id, response.data);
      }
      return response;
    }),
    update: flow(function* (data) {
      const response = yield InvoiceService.update(data);
      if (!response.hasError) {
        self.all.set(response.data.id, response.data);
      }
      return response;
    }),
    setStatus: flow(function* (id, status) {
      const response = yield InvoiceService.updateStatus(id, status);
      if (!response.hasError) {
        self.all.set(response.data.id, response.data);
      }
      return response;
    }),
    getSettings: flow(function* () {
      const response = yield InvoiceService.getSettings();
      if (!response.hasError) {
        self.settings = response.data;
      }
      return response;
    }),
    setSettings: flow(function* (data) {
      const response = yield InvoiceService.setSettings(data);
      if (!response.hasError) {
        self.settings = response.data;
      }
      return response;
    }),
  }));

export default Invoices;
