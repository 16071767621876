import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Row,
  Button,
  Col,
  CardFooter,
  Table,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  CardBody,
} from 'reactstrap';
import { Formik, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import { observer, Observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import * as Icon from 'react-feather';
import Select from 'react-select';

import CHLogoSvg from 'jsx:assets/svg/ch-logo.svg';
import CHTextSvg from 'jsx:assets/svg/ch-text.svg';

// import { ReactComponent as CHLogoSvg } from 'assets/svg/ch-logo.svg';
// import { ReactComponent as CHTextSvg } from 'assets/svg/ch-text.svg';

import currencyFormatter from 'utilities/currency_formatter';

import { AddNewLineItem, RowRemove } from './styles';

const CHLogo = styled(CHLogoSvg)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const CHText = styled(CHTextSvg)`
  width: 125px;
  /* height: 80px; */
`;

const schema = Yup.object().shape({
  client: Yup.object({
    label: Yup.string(),
    value: Yup.number(),
  }).required('Required'),
  billToOption: Yup.object({
    label: Yup.string(),
    value: Yup.string(),
  }).required('Required'),
  name: Yup.string().when('billToOption', {
    is: (billToOption) => billToOption.value === 'OTHER',
    then: Yup.string().required('Required'),
  }),
  streetAddress: Yup.string().when('billToOption', {
    is: (billToOption) => billToOption.value === 'OTHER',
    then: Yup.string().required('Required'),
  }),
  suburb: Yup.string().when('billToOption', {
    is: (billToOption) => billToOption.value === 'OTHER',
    then: Yup.string().required('Required'),
  }),
  city: Yup.string().when('billToOption', {
    is: (billToOption) => billToOption.value === 'OTHER',
    then: Yup.string().required('Required'),
  }),
  invoiceDate: Yup.string().required('Required'),
  dueDate: Yup.string().required('Required'),
  gstNumber: Yup.string().nullable(),
  items: Yup.array().of(
    Yup.object({
      id: Yup.number().nullable(),
      description: Yup.string().required('Required'),
      amount: Yup.number(),
      total: Yup.number(),
    })
  ),
  notes: Yup.string().nullable(),
});

const defaultItem = {
  description: '',
  total: 0,
  amount: 0,
  id: 0,
};

const BillToOptions = [
  // {
  //   label: 'Clearhead (EAP)',
  //   value: 'CLEARHEAD',
  // },
  {
    label: 'Client',
    value: 'CLIENT',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

@inject('invoices')
@observer
class InvoiceForm extends Component {
  @observable forceError = false;

  @observable gstEnabled = true;

  componentDidMount = () => {
    const { initialValue } = this.props;
    if (
      initialValue &&
      initialValue.gstNumber &&
      initialValue.gstNumber.trim().length
    ) {
      this.gstEnabled = true;
    }
  };

  validate = (validateForm, handleSubmit) => {
    validateForm().then((data) => {
      const hasError = Object.keys(data).some(
        (field) => field && field.length > 0
      );
      if (hasError) {
        this.forceError = true;
      } else {
        handleSubmit();
      }
    });
  };

  onAdd = (arrayHelpers) => {
    arrayHelpers.push({ ...defaultItem });
  };

  calculateTax = (amount, total) => {
    return currencyFormatter().format(total - amount);
  };

  renderItems = (formikProps, arrayHelpers) => {
    let rowItems = null;
    if (formikProps.values.items && formikProps.values.items.length > 0) {
      rowItems = formikProps.values.items.map((item, index) => (
        <tr key={`row_${index}`}>
          <td>
            <Input
              bsSize="sm"
              name={`items[${index}].description`}
              value={formikProps.values.items[index].description}
              onChange={(e) => {
                formikProps.setFieldTouched(
                  `items[${index}].description`,
                  true
                );
                formikProps.setFieldValue(
                  `items[${index}].description`,
                  e.target.value
                );
              }}
            />
            {getIn(formikProps.touched, `items[${index}].description`) ||
              (this.forceErrors &&
                getIn(formikProps.errors, `items[${index}].description`) && (
                  <FormFeedback className="d-block">
                    {formikProps.errors.items[index].description}
                  </FormFeedback>
                ))}
          </td>
          <td>
            <Input
              bsSize="sm"
              name={`items[${index}].amount`}
              type="number"
              value={formikProps.values.items[index].amount}
              onChange={(e) => {
                formikProps.setFieldTouched(`items[${index}].amount`, true);

                // when amount changes also change tax and total
                if (!isNaN(parseFloat(e.target.value))) {
                  let value = e.target.value;
                  value.replace(/^0+/, '');
                  formikProps.setFieldValue(`items[${index}].amount`, value);
                  const plusGST = (
                    parseFloat(value) * (this.gstEnabled ? 1.15 : 1.0)
                  ).toFixed(2);
                  formikProps.setFieldValue(
                    `items[${index}].total`,
                    `${plusGST}`
                  );
                }
              }}
            />
            {getIn(formikProps.touched, `items[${index}].amount`) ||
              (this.forceErrors &&
                getIn(formikProps.errors, `items[${index}].amount`) && (
                  <FormFeedback className="d-block">
                    {formikProps.errors.items[index].amount}
                  </FormFeedback>
                ))}
          </td>
          <th>
            {formikProps.values.items[index].total ||
            formikProps.values.items[index].amount
              ? this.calculateTax(
                  formikProps.values.items[index].amount,
                  formikProps.values.items[index].total
                )
              : `$0.00`}
          </th>
          <td>
            <Input
              bsSize="sm"
              name={`items[${index}].total`}
              type="number"
              value={formikProps.values.items[index].total}
              onChange={(e) => {
                formikProps.setFieldTouched(`items[${index}].total`, true);

                if (!isNaN(parseFloat(e.target.value))) {
                  let value = e.target.value;
                  value.replace(/^0+/, '');
                  formikProps.setFieldValue(`items[${index}].total`, value);
                  const beforeGST = (parseFloat(value) / 1.15).toFixed(2);
                  formikProps.setFieldValue(
                    `items[${index}].amount`,
                    beforeGST
                  );
                }
              }}
            />
            {getIn(formikProps.touched, `items[${index}].total`) ||
              (this.forceErrors &&
                getIn(formikProps.errors, `items[${index}].total`) && (
                  <FormFeedback className="d-block">
                    {formikProps.errors.items[index].total}
                  </FormFeedback>
                ))}
          </td>
          <td className="text-right">
            <RowRemove onClick={() => arrayHelpers.remove(index)} />
          </td>
        </tr>
      ));
    }
    return (
      <>
        {rowItems}
        <AddNewLineItem onClick={() => this.onAdd(arrayHelpers)}>
          <td align="center" colSpan="6">
            <Icon.PlusCircle /> Add item
          </td>
        </AddNewLineItem>
      </>
    );
  };

  getTotalPrice = (formik) => {
    let total = 0;
    const { values } = formik;
    if (values && values.items && values.items.length) {
      values.items.forEach((item) => (total += Number(item.total)));
    }
    return total;
  };

  getAmount = (formik) => {
    let total = 0;
    const { values } = formik;
    if (values && values.items && values.items.length) {
      values.items.forEach((item) => (total += Number(item.amount)));
    }
    return total;
  };

  renderTax = (formik) => {
    const total = this.getTotalPrice(formik);
    const amount = this.getAmount(formik);
    console.log(total, amount);
    return total - amount;
  };

  renderTotal = (formik) => {
    const total = this.getTotalPrice(formik);
    return total;
  };

  renderBillToOptions = (formik) => {
    return (
      <>
        <FormGroup>
          <Label for="billTo">Bill to</Label>
          <Select
            classNamePrefix="react-select"
            name="billToOption"
            options={BillToOptions}
            value={formik.values.billToOption}
            onBlur={() => formik.setFieldTouched('billToOption', true)}
            onChange={(option) => {
              formik.setFieldValue('billToOption', option);
              formik.setFieldTouched('billToOption', true);
            }}
            closeMenuOnSelect
          />
        </FormGroup>
        {formik.values.billToOption.value === 'OTHER' ? (
          <>
            <FormGroup>
              <Label for="name">Name *</Label>
              <Input
                bsSize="lg"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={formik.touched.name && !!formik.errors.name}
              />
              {(formik.touched.name || this.forceErrors) &&
                formik.errors.name && (
                  <FormFeedback className="d-block">
                    {formik.errors.name}
                  </FormFeedback>
                )}
            </FormGroup>
            <FormGroup>
              <Label for="streetAddress">Street Address *</Label>
              <Input
                bsSize="lg"
                name="streetAddress"
                value={formik.values.streetAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched.streetAddress && !!formik.errors.streetAddress
                }
              />
              {(formik.touched.streetAddress || this.forceErrors) &&
                formik.errors.streetAddress && (
                  <FormFeedback className="d-block">
                    {formik.errors.streetAddress}
                  </FormFeedback>
                )}
            </FormGroup>
            <FormGroup>
              <Label for="suburb">Suburb *</Label>
              <Input
                bsSize="lg"
                name="suburb"
                value={formik.values.suburb}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={formik.touched.suburb && !!formik.errors.suburb}
              />
              {(formik.touched.suburb || this.forceErrors) &&
                formik.errors.suburb && (
                  <FormFeedback className="d-block">
                    {formik.errors.suburb}
                  </FormFeedback>
                )}
            </FormGroup>
            <FormGroup>
              <Label for="city">City *</Label>
              <Input
                bsSize="lg"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={formik.touched.city && !!formik.errors.city}
              />
              {(formik.touched.city || this.forceErrors) &&
                formik.errors.city && (
                  <FormFeedback className="d-block">
                    {formik.errors.city}
                  </FormFeedback>
                )}
            </FormGroup>
          </>
        ) : null}
      </>
    );
  };

  onSubmit = async (values, actions) => {
    const { invoices, onSubmit } = this.props;
    let {
      id,
      items,
      client,
      billToOption,
      name,
      streetAddress,
      suburb,
      city,
      ...rest
    } = values;

    let billTo = {};
    if (billToOption.value === 'CLEARHEAD') {
      billTo = {
        party: 'CLEARHEAD',
        name: 'Clearhead (EAP)',
        streetAddress: '6 Leonard Issit Drive',
        suburb: 'Auckland Airport',
        city: 'Auckland 2104',
        email: 'michael.connolly@clearhead.org.nz',
        phoneNumber: '021 125 4527',
      };
    } else if (billToOption.value === 'OTHER') {
      billTo = {
        party: 'OTHER',
        name,
        streetAddress,
        suburb,
        city,
      };
    }

    const data = {
      ...rest,
      lineItems: [
        ...items.map((i) => ({
          ...i,
          amount: parseFloat(i.amount, 10),
          total: parseFloat(i.total, 10),
          tax: (i.total - i.amount).toFixed(2) || 0,
        })),
      ],
      billTo,
      contactId: client.value,
    };
    let response = null;
    if (id && id > 0) {
      response = await invoices.update({ ...data, id });
    } else {
      response = await invoices.create(data);
    }
    actions.setSubmitting(false);
    onSubmit(response);
  };

  render() {
    const { initialValue } = this.props;
    return (
      <Formik
        initialValues={initialValue}
        validationSchema={schema}
        onSubmit={this.onSubmit}
      >
        {(formikProps) => (
          <Observer>
            {() => (
              <>
                <CardBody>
                  <Form>
                    <Row form>
                      <Col xs="4" md="4" className="mb-2 text-left">
                        <div className="text-muted">Bill from</div>
                        <strong>{initialValue.billFrom.name}</strong>
                        <p className="mb-0">
                          {initialValue.billFrom.streetAddress || '-'}, <br />{' '}
                          {initialValue.billFrom.suburb || '-'}, <br />{' '}
                          {initialValue.billFrom.city || '-'}{' '}
                          {initialValue.billFrom.postCode || '-'}
                        </p>
                      </Col>
                      <Col xs="4" md="4" className="mb-2 text-left">
                        <div className="mb-3">
                          <Label>Client *</Label>
                          <p>
                            <strong>{formikProps.values.client.label}</strong>
                          </p>
                        </div>
                        {this.renderBillToOptions(formikProps)}
                      </Col>
                      <Col xs="4" md="4" className="mb-2 text-left">
                        {/* <div className="mb-2">
                          <FormGroup>
                            <Label for="gstNumber">GST Number</Label>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <Input
                                    addon
                                    checked={this.gstEnabled}
                                    onChange={(e) => {
                                      this.gstEnabled = e.target.checked;
                                      if (e.target.checked === false) {
                                        formikProps.setFieldValue(
                                          'gstNumber',
                                          ''
                                        );
                                      }

                                      //recalculate all prices
                                    }}
                                    type="checkbox"
                                    aria-label="Checkbox for following text input"
                                  />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                bsSize="lg"
                                name="gstNumber"
                                placeholder="GST Number"
                                type="number"
                                value={formikProps.values.gstNumber}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                disabled={!this.gstEnabled}
                                invalid={
                                  formikProps.touched.gstNumber &&
                                  !!formikProps.errors.gstNumber
                                }
                              />
                            </InputGroup>
                            {(formikProps.touched.gstNumber ||
                              this.forceErrors) &&
                              formikProps.errors.gstNumber && (
                                <FormFeedback className="d-block">
                                  {formikProps.errors.gstNumber}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </div> */}
                        <div className="mb-2">
                          <FormGroup>
                            <Label for="invoiceDate">Invoice date *</Label>
                            <Input
                              bsSize="lg"
                              name="invoiceDate"
                              type="date"
                              value={formikProps.values.invoiceDate}
                              onChange={formikProps.handleChange}
                              onBlur={formikProps.handleBlur}
                              invalid={
                                formikProps.touched.invoiceDate &&
                                !!formikProps.errors.invoiceDate
                              }
                            />
                            {(formikProps.touched.invoiceDate ||
                              this.forceErrors) &&
                              formikProps.errors.invoiceDate && (
                                <FormFeedback className="d-block">
                                  {formikProps.errors.invoiceDate}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </div>
                        <div className="mb-2">
                          <FormGroup>
                            <Label for="dueDate">Due date *</Label>
                            <Input
                              bsSize="lg"
                              name="dueDate"
                              type="date"
                              value={formikProps.values.dueDate}
                              onChange={formikProps.handleChange}
                              onBlur={formikProps.handleBlur}
                              invalid={
                                formikProps.touched.dueDate &&
                                !!formikProps.errors.dueDate
                              }
                            />
                            {(formikProps.touched.dueDate ||
                              this.forceErrors) &&
                              formikProps.errors.dueDate && (
                                <FormFeedback className="d-block">
                                  {formikProps.errors.dueDate}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                    <Table size="sm" className="mb-6">
                      <thead>
                        <tr>
                          <th style={{ width: '50%' }}>Description</th>
                          <th style={{ width: '15%' }}>Amount</th>
                          <th style={{ width: '10%' }}>Tax</th>
                          <th style={{ width: '15%' }}>Total</th>
                          <th style={{ width: '10%' }}>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray
                          name="items"
                          render={(arrayHelpers) => (
                            <Observer>
                              {() =>
                                this.renderItems(formikProps, arrayHelpers)
                              }
                            </Observer>
                          )}
                        />
                        {this.gstEnabled ? (
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <th>&nbsp;</th>
                            <th>Tax</th>
                            <td className="text-right">
                              {currencyFormatter().format(
                                this.renderTax(formikProps)
                              )}
                            </td>
                            <td>&nbsp;</td>
                          </tr>
                        ) : null}
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <th>&nbsp;</th>
                          <th>Total</th>
                          <td className="text-right">
                            {currencyFormatter().format(
                              this.renderTotal(formikProps)
                            )}
                          </td>
                          <th>&nbsp;</th>
                        </tr>
                      </tbody>
                    </Table>

                    <div className="mb-6">
                      <FormGroup>
                        <Label for="notes">Extra notes</Label>
                        <Input
                          bsSize="lg"
                          name="notes"
                          type="textarea"
                          value={formikProps.values.notes}
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          invalid={
                            formikProps.touched.notes &&
                            !!formikProps.errors.notes
                          }
                        />
                        {(formikProps.touched.notes || this.forceErrors) &&
                          formikProps.errors.notes && (
                            <FormFeedback className="d-block">
                              {formikProps.errors.notes}
                            </FormFeedback>
                          )}
                      </FormGroup>
                    </div>
                    <p
                      className="text-center mb-0 text-muted"
                      style={{ fontSize: '0.5rem' }}
                    >
                      POWERED BY
                    </p>
                    <Row form>
                      <Col className="d-flex mb-3 justify-content-center">
                        <CHLogo />
                        <CHText />
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter className="d-flex align-items-center justify-content-end">
                  <Button
                    color="success"
                    onClick={() =>
                      this.validate(
                        formikProps.validateForm,
                        formikProps.handleSubmit
                      )
                    }
                  >
                    <Icon.Save size={14} /> SAVE
                  </Button>
                </CardFooter>
              </>
            )}
          </Observer>
        )}
      </Formik>
    );
  }
}

export default InvoiceForm;
