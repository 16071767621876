import React, { Component } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from 'styled-components';
import Select from 'react-select';

const moment = extendMoment(Moment);

const CustomSelect = styled(Select)`
  width: 100%;

  .time-select__menu {
    z-index: 10;
  }

  .time-select__control {
    min-height: calc(2.7rem + 2px);
  }
`;

@observer
class TimePicker extends Component {
  @observable isDropdownOpen = false;

  constructor(props) {
    super(props);

    this.options = [];
    this.updateTimes();
  }

  componentDidUpdate = (prevProps) => {
    const { selectedTime: oldSelectedTime = {} } = prevProps;
    const { selectedTime: newSelectedTime = {} } = this.props;

    const oldTime = moment(oldSelectedTime.value);
    const newTime = moment(newSelectedTime.value);

    if (!oldTime.isSame(newTime, 'day')) {
      this.updateTimes();
    }
  };

  updateTimes = () => {
    const { selectedTime = {} } = this.props;
    const start = moment(selectedTime.value).hours(1).minutes(0).seconds(0);
    const end = moment(selectedTime.value).hours(23).minutes(45).seconds(0);
    const range = moment.range(start._d, end._d);
    const timeRageArr = Array.from(range.by('minutes', { step: 15 }));

    this.options = timeRageArr.map((time) => {
      const formattedTime = time.format('h:mma');
      return { label: formattedTime, value: time };
    });
  };

  filterOption = (option, searchStr) => {
    const withoutColon = option.label.split(':').join('');
    return option.label.startsWith(searchStr) || withoutColon.startsWith(searchStr);
  };

  render() {
    const {
      selectedTime,
      onChange,
      onBlur = () => {},
      defaultValue,
      isDisabled = false,
    } = this.props;
    let index = 0;

    if (selectedTime && selectedTime.value) {
      index = this.options.findIndex(
        (x) => moment(x.value).format('h:mma') === moment(selectedTime.value).format('h:mma')
      );
    } else if (defaultValue && defaultValue.value) {
      index = this.options.findIndex(
        (x) => moment(x.value).format('h:mma') === moment(defaultValue.value).format('h:mma')
      );
    }

    return (
      <CustomSelect
        classNamePrefix='time-select'
        components={{ IndicatorSeparator: null }}
        value={this.options[index]}
        options={this.options}
        filterOption={this.filterOption}
        getOptionValue={(option) => option.label}
        onChange={onChange}
        onBlur={onBlur}
        isSearchable={true}
        isDisabled={isDisabled}
      />
    );
  }
}

export default TimePicker;
