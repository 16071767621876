import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { observer } from 'mobx-react';

import ScreeningQuestionForm from 'modules/screening/components/ScreeningQuestionForm';

@observer
class QuestionModal extends Component {
	render() {
		const { isModalOpen, toggleModal, formState, mode, onComplete } = this.props;

		return (
			<Modal isOpen={isModalOpen} toggle={toggleModal} size='lg' centered>
				<ModalHeader className='bg-light' toggle={toggleModal}>
					Add Question
				</ModalHeader>

				<ModalBody className='p-3'>
					<ScreeningQuestionForm formState={formState} mode={mode} onComplete={onComplete} />
				</ModalBody>
			</Modal>
		);
	}
}

export default QuestionModal;
