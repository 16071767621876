import { SVGProps } from 'react';
const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 25 24' {...props}>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={1.5}
      d='m15.821 16.98 4.517-4.516-4.517-4.517'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M18.79 12.382H2.427'
    />
  </svg>
);
export default ArrowRight;
