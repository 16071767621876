/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Button, Collapse, ListGroupItem, Label, Row, Col } from 'reactstrap';
import moment from 'moment';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

@observer
class AttendeeItem extends Component {
  @observable isOpen = false;

  constructor(props) {
    super(props);
    this.isOpen = props.open || false;
  }

  toggle = () => {
    this.isOpen = !this.isOpen;
  };

  renderActions = () => {
    const { onRemove, onEdit } = this.props;
    return (
      <div>
        <Button color='warning' size='sm' className='mr-1' onClick={() => onEdit()}>
          Edit
        </Button>
        <Button color='danger' size='sm' onClick={() => onRemove()}>
          Remove
        </Button>
      </div>
    );
  };

  render() {
    const { attendee } = this.props;
    const dateOfBirth = moment(attendee.dateOfBirth).format('MMM Do YYYY');
    return (
      <>
        <ListGroupItem>
          <div className='d-flex justify-content-between align-items-center'>
            <a
              className='mb-0'
              href='#'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.toggle();
              }}
            >
              {`${attendee.firstName} ${attendee.lastName}`}
            </a>
            <div>{this.renderActions()}</div>
          </div>
          <Collapse isOpen={this.isOpen} cssModule={{ collapse: 'bs-collapse' }}>
            <div className='mb-0'>
              <Row className='mt-2'>
                <Col className='col-12'>
                  <strong className='d-block text-secondary'>Ethnicity</strong>
                  <Label className='text-break'>{attendee.ethnicity || '-'}</Label>
                  <strong className='d-block text-secondary'>Phone Number</strong>
                  <Label className='text-break'>{attendee.phoneNumber || '-'}</Label>
                </Col>
                <Col className='col-12'>
                  <strong className='d-block text-secondary'>Date of Birth</strong>
                  <Label className='text-break'>{attendee.dateOfBirth ? dateOfBirth : '-'}</Label>
                  <strong className='d-block text-secondary'>Gender</strong>
                  <Label className='text-break'>{attendee.gender || '-'}</Label>
                </Col>
                <Col className='col-12'>
                  <strong className='d-block text-secondary'>Email</strong>
                  <Label className='text-break'>{attendee.email || '-'}</Label>
                </Col>
              </Row>
            </div>
          </Collapse>
        </ListGroupItem>
      </>
    );
  }
}

export default AttendeeItem;
