import React from 'react';
import Link from '../../../../common/Link';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { olderThanSpecifiedDays } from '../../../../utilities/olderThanThirtyDays';
import HelpLink from '../HelpLink';

type InvoiceLinkProps = {
  isLoading?: boolean;
  className?: string;
  props: any;
};

const InvoiceLink: React.FC<InvoiceLinkProps> = ({ isLoading, className, props }) => {
  const history = useHistory();
  const {
    booking: { id, invoiceId, invoiceStatus, partnerBooking, appointmentTime, status },
  } = props;

  const show = props.auth.currentPractitioner.invoicingEnabled;

  const isToOld = olderThanSpecifiedDays(appointmentTime, 60);

  if (!invoiceId) {
    if (
      status === 'Rejected' ||
      status === 'Cancelled' ||
      status === 'DidNotAttend' ||
      status === 'Completed' ||
      status === 'Confirmed' ||
      !partnerBooking ||
      !show
    ) {
      return <div></div>;
    }

    if (!props.auth?.currentPractitioner?.invoicingSetup) {
      return (
        <Link
          onClick={() => history.push(`/app/setup-wizard`)}
          disabled={isLoading}
          textClassName='text-primary-blue-100'
          className={clsx(className)}
        >
          Setup Invoicing
        </Link>
      );
    }

    if (isToOld) {
      return (
        <HelpLink
          variant='invoice'
          label='Contact Support to Invoice this session'
          className='mt-4 mr-2 sm:mr-3 sm:text-right w-1/2 sm:w-auto'
        />
      );
    }

    return (
      <Link
        onClick={() => history.push(`/app/booking/complete/${id}`)}
        disabled={isLoading}
        textClassName='text-primary-blue-100'
        className={clsx(className)}
      >
        Mark completed
      </Link>
    );
  }

  let statusClassname = '';
  let labelText;
  switch (invoiceStatus.toLowerCase()) {
    case 'draft': {
      statusClassname = 'text-primary-blue-100';
      labelText = 'Draft invoice';
      break;
    }
    case 'sent': {
      statusClassname = 'text-dark-green';
      labelText = 'Invoice sent';
      break;
    }
    case 'paid': {
      statusClassname = 'text-dark-green';
      labelText = 'Invoice paid';
      break;
    }
    case 'cancelled': {
      statusClassname = 'text-grey-200';
      labelText = 'Invoice cancelled';
      break;
    }
    case 'overdue': {
      statusClassname = 'text-red';
      labelText = 'Invoice overdue';
      break;
    }
    default: {
      statusClassname = 'hidden';
      labelText = '';
      break;
    }
  }

  return (
    <Link
      onClick={() => history.push(`/app/invoice/${props.booking.invoiceId}`)}
      disabled={isLoading}
      textClassName={statusClassname}
      className={className}
    >
      {labelText}
    </Link>
  );
};

export default InvoiceLink;
