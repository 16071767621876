import './styles.css';
import { MapLayer, withLeaflet } from 'react-leaflet';
import L from 'leaflet';
require('leaflet.markercluster');

@withLeaflet
class MarkerClusterGroup extends MapLayer {
	createLeafletElement(props) {
		const el = new L.markerClusterGroup({
			...props,
			maxClusterRadius: 17,
			showCoverageOnHover: false,
			spiderLegPolylineOptions: { weight: 1.5, color: '#333', opacity: 1 },
		});
		this.contextValue = {
			...props.leaflet,
			layerContainer: el,
		};
		return el;
	}
}

export default MarkerClusterGroup;
