import styled from 'styled-components';

import CHLogoSvg from 'jsx:assets/svg/ch-logo.svg';
import CHTextSvg from 'jsx:assets/svg/ch-text.svg';

export const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	margin-bottom: 20px;

	@media screen and (max-width: 765px) {
		height: 30px;
	}
`;

export const CHLogo = styled(CHLogoSvg)`
	width: 40px;
	margin-right: 15px;

	path {
		fill: #4987ce;
	}

	@media screen and (max-width: 765px) {
		width: 30px;
	}
`;

export const CHText = styled(CHTextSvg)`
	width: 240px;

	path {
		fill: #4987ce;
	}

	@media screen and (max-width: 765px) {
		width: 180px;
	}
`;

export const Number = styled.span`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 26px;
	height: 26px;
	margin-right: 10px;
	border-radius: 50%;
	background-color: #2c7be5;
`;

export const NumberLabel = styled.label`
	color: white;
	margin: 0;
`;

export const FormTitle = styled.h4`
	display: inline-block;
	margin: 0;
`;

export const Important = styled.p`
	margin: 0;
	color: red;
`;