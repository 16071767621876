"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = isResponsiveModifiersProp;

var _isObject = _interopRequireDefault(require("lodash/isObject"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Evaluates if the first argument is of the ResponsiveModifiersProp type
 * @export
 * @param {*} val
 * @returns {val is ResponsiveModifiersProp}
 */
function isResponsiveModifiersProp(val) {
  return (0, _isObject.default)(val) && !Array.isArray(val);
}