import * as React from 'react';
import { ModifierKeys } from 'styled-components-modifiers';
import { BaseInput } from './styles';

export interface IInput extends React.HTMLAttributes<HTMLInputElement> {
  modifiers?: ModifierKeys;
  invalid?: boolean;
  name?: string;
  type?: string;
  value?: string;
  disabled?: boolean;
}
const Input = React.forwardRef<HTMLInputElement, IInput>(
  ({ children, className, type, value, ...rest }: IInput, ref: React.Ref<HTMLInputElement>) => (
    <BaseInput className={className} type={type} value={value} {...rest} ref={ref}>
      {children}
    </BaseInput>
  )
);

Input.displayName = 'Input';

export default Input;
