import styled from 'styled-components';
import { Row, Card } from 'reactstrap';

import { BlockLoading } from 'common';
import EditSvg from 'jsx:assets/svg/square-edit-outline.svg';
import CloseSvg from 'jsx:assets/svg/close.svg';
import UpSvg from 'jsx:assets/svg/arrow-up-circle.svg';
import DownSvg from 'jsx:assets/svg/arrow-down-circle.svg';

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const CustomCard = styled(Card)`
  margin-bottom: 0;
`;

export const QuestionRow = styled(Row)`
  position: relative;
  margin-left: 16px;
  margin-bottom: 1.5rem;
`;

export const QuestionLoading = styled(BlockLoading)`
  left: -20px;
  width: calc(100% + 5px);
  border-radius: 4px;
`;

export const DetailsRow = styled(Row)`
  display: grid;
  grid-template-columns: 135px 1fr;

  @media screen and (max-width: 400px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 15px;
  }
`;

export const EditIcon = styled(EditSvg)`
  width: 16px;
  height: 16px;
  fill: white;
`;

export const DeleteIcon = styled(CloseSvg)`
  width: 16px;
  height: 16px;
  fill: white;
`;

export const UpIcon = styled(UpSvg)`
  visibility: ${(props) => (props.first ? 'hidden' : 'visible')};
  position: absolute;
  left: -20px;
  width: 30px;
  height: 30px;
  fill: #8054cc;
  cursor: pointer;
  padding: 0;

  transform: translateY(-50%);
  top: calc(50% - 20px);

  path {
    fill: #8054cc;
  }
`;

export const DownIcon = styled(DownSvg)`
  visibility: ${(props) => (props.last ? 'hidden' : 'visible')};
  position: absolute;
  left: -20px;
  width: 30px;
  height: 30px;
  fill: #8054cc;
  cursor: pointer;
  padding: 0;

  transform: translateY(-50%);
  top: calc(50% + 20px);

  path {
    fill: #8054cc;
  }
`;
