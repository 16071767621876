import { SVGProps } from 'react';
const TickCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16' {...props}>
    <circle cx={8.378} cy={7.622} r={7.622} fill='currentColor' />
    <path
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.27}
      d='m5.005 7.91 2.057 2.534L11.751 4.8'
    />
  </svg>
);
export default TickCircle;
