/* Third party */
import React from 'react';
import { Row, Col, Container } from 'reactstrap';

import angela from 'assets/img/team/angela.jpg';
import michael from 'assets/img/team/michael.png';
import kirushi from 'assets/img/team/kirushi.jpg';
import patrick from 'assets/img/team/patrick.jpg';
import sam from 'assets/img/team/sam.jpg';

/* Components */
import { Image, NameText, OccupationText } from './styles';

import { Section, SectionSpacing, HeaderTitle, Prompt, Highlighted } from '../../styles';

/* Functions */

const TeamSection = () => (
  <>
    <Section id='our-team'>
      <SectionSpacing>
        <Container>
          <Row className='mb-5'>
            <Col>
              <HeaderTitle className='text-center'>Meet the Clearhead Team</HeaderTitle>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs={12} sm={6} md={6} lg={3} className='text-center'>
              <Image src={angela} alt='Dr. Angela Lim' />
              <NameText>Dr. Angela Lim</NameText>
              <OccupationText>CEO & Co-Founder</OccupationText>
            </Col>
            <Col xs={12} sm={6} md={6} lg={3} className='text-center'>
              <Image src={michael} alt='Michael Connolly' />
              <NameText>Michael Connolly</NameText>
              <OccupationText>CTO & Co-Founder</OccupationText>
            </Col>
            <Col xs={12} sm={6} md={6} lg={3} className='text-center'>
              <Image src={kirushi} alt='Kirushi Arunthavasothy' />
              <NameText>Kirushi Arunthavasothy</NameText>
              <OccupationText>Product Lead</OccupationText>
            </Col>
            <Col xs={12} sm={6} md={6} lg={3} className='text-center'>
              <Image src={patrick} alt='Patrick Xu' />
              <NameText>Patrick Xu</NameText>
              <OccupationText>Machine Learning Engineer</OccupationText>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs={12} sm={6} md={6} lg={3} className='text-center'>
              <Image src={sam} alt="Sam O'Sullivan" />
              <NameText>Sam O'Sullivan</NameText>
              <OccupationText>Clinical Psychologist</OccupationText>
            </Col>
          </Row>
        </Container>
      </SectionSpacing>
    </Section>
    <Section backgroundcolor='#f6f9fc'>
      <SectionSpacing>
        <Container>
          <Row>
            <Col className='text-center'>
              <Prompt href='/auth/sign-up'>
                Join our <Highlighted>national directory</Highlighted> now and{' '}
                <Highlighted>connect with people</Highlighted> that need your help
              </Prompt>
            </Col>
          </Row>
        </Container>
      </SectionSpacing>
    </Section>
  </>
);

export default TeamSection;
