import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import RejectBookingForm from 'modules/bookings/components/RejectBookingForm';

@withRouter
class RejectBookingModal extends Component {
  render() {
    const { isModalOpen, toggleModal, formState, onComplete } = this.props;
    const initialFormState = {
      reason: '',
      ...formState,
    };

    const handleComplete = (response, reason) => {
      if (reason === 'Unavailable') {
        // navigate to settings page to turn off availability and show toast alert.
        this.props.history.push(`/app/booking-settings?unavailable=true`);
      } else if (reason === 'Reschedule') {
        // navigate to rebooking and tell them to rebook for their available time.
        onComplete(response);
        const path = `/app/bookings?createAppointment=true&${
          formState.mode === 'Manual' ? 'manualBookingId' : 'bookingId'
        }=${formState.appointmentId}`;

        if (formState.reload) {
          window.location.href = path;
        } else {
          this.props.history.push(path);
        }
      } else {
        // do nothing.
        onComplete(response);
      }
    };

    return (
      <Modal isOpen={isModalOpen} toggle={toggleModal} size='md' centered>
        <ModalHeader className='bg-light' toggle={toggleModal}>
          Please provide a reason for rejecting this booking
        </ModalHeader>

        <ModalBody className='p-3'>
          <Label className='text-danger'>The reason you give will be sent to the client</Label>
          <RejectBookingForm formState={initialFormState} onComplete={handleComplete} />
        </ModalBody>
      </Modal>
    );
  }
}

export default RejectBookingModal;
