const PostCodes = {
	'0600': {
		'country code': 'NZ',
		'postal code': 600,
		'place name': 'Blockhouse Bay',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9182,
		longitude: 174.7002,
		accuracy: 4,
	},
	'0602': {
		'country code': 'NZ',
		'postal code': 602,
		'place name': 'Kelston',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8982,
		longitude: 174.6602,
		accuracy: 4,
	},
	'0604': {
		'country code': 'NZ',
		'postal code': 604,
		'place name': 'Huia',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9983,
		longitude: 174.5667,
		accuracy: 4,
	},
	'0610': {
		'country code': 'NZ',
		'postal code': 610,
		'place name': 'Te Atatu South',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8647,
		longitude: 174.6477,
		accuracy: 4,
	},
	'0612': {
		'country code': 'NZ',
		'postal code': 612,
		'place name': 'McLaren Park',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8882,
		longitude: 174.6102,
		accuracy: 4,
	},
	'0614': {
		'country code': 'NZ',
		'postal code': 614,
		'place name': 'Swanson',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8667,
		longitude: 174.5833,
		accuracy: 4,
	},
	'0618': {
		'country code': 'NZ',
		'postal code': 618,
		'place name': 'Hobsonville',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8,
		longitude: 174.6667,
		accuracy: 4,
	},
	'0620': {
		'country code': 'NZ',
		'postal code': 620,
		'place name': 'Forrest Hill',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7645,
		longitude: 174.7477,
		accuracy: 4,
	},
	'0622': {
		'country code': 'NZ',
		'postal code': 622,
		'place name': 'Belmont',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8075,
		longitude: 174.7864,
		accuracy: 4,
	},
	'0624': {
		'country code': 'NZ',
		'postal code': 624,
		'place name': 'Devonport',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8282,
		longitude: 174.7902,
		accuracy: 4,
	},
	'0626': {
		'country code': 'NZ',
		'postal code': 626,
		'place name': 'Chatswood',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8184,
		longitude: 174.7098,
		accuracy: 4,
	},
	'0627': {
		'country code': 'NZ',
		'postal code': 627,
		'place name': 'North Shore',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8,
		longitude: 174.75,
		accuracy: 4,
	},
	'0629': {
		'country code': 'NZ',
		'postal code': 629,
		'place name': 'Marlborough',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7782,
		longitude: 174.7202,
		accuracy: 4,
	},
	'0630': {
		'country code': 'NZ',
		'postal code': 630,
		'place name': 'Browns Bay',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7193,
		longitude: 174.7367,
		accuracy: 4,
	},
	'0632': {
		'country code': 'NZ',
		'postal code': 632,
		'place name': 'Greenhithe',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7667,
		longitude: 174.6833,
		accuracy: 4,
	},
	'0640': {
		'country code': 'NZ',
		'postal code': 640,
		'place name': 'New Lynn',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9069,
		longitude: 174.687,
		accuracy: 4,
	},
	'0641': {
		'country code': 'NZ',
		'postal code': 641,
		'place name': 'Glen Eden',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9167,
		longitude: 174.65,
		accuracy: 4,
	},
	'0642': {
		'country code': 'NZ',
		'postal code': 642,
		'place name': 'Titirangi',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9375,
		longitude: 174.6558,
		accuracy: 4,
	},
	'0643': {
		'country code': 'NZ',
		'postal code': 643,
		'place name': 'Green Bay',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.931,
		longitude: 174.6749,
		accuracy: 4,
	},
	'0644': {
		'country code': 'NZ',
		'postal code': 644,
		'place name': 'Blockhouse Bay',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9182,
		longitude: 174.7002,
		accuracy: 4,
	},
	'0645': {
		'country code': 'NZ',
		'postal code': 645,
		'place name': 'Glendene',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8882,
		longitude: 174.6402,
		accuracy: 4,
	},
	'0650': {
		'country code': 'NZ',
		'postal code': 650,
		'place name': 'Sunnyvale',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8882,
		longitude: 174.6302,
		accuracy: 4,
	},
	'0651': {
		'country code': 'NZ',
		'postal code': 651,
		'place name': 'Te Atatu',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8333,
		longitude: 174.65,
		accuracy: 4,
	},
	'0652': {
		'country code': 'NZ',
		'postal code': 652,
		'place name': 'Te Atatu South',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8647,
		longitude: 174.6477,
		accuracy: 4,
	},
	'0653': {
		'country code': 'NZ',
		'postal code': 653,
		'place name': 'Swanson',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8667,
		longitude: 174.5833,
		accuracy: 4,
	},
	'0654': {
		'country code': 'NZ',
		'postal code': 654,
		'place name': 'Te Atatu South',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8647,
		longitude: 174.6477,
		accuracy: 4,
	},
	'0655': {
		'country code': 'NZ',
		'postal code': 655,
		'place name': 'Swanson',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8667,
		longitude: 174.5833,
		accuracy: 4,
	},
	'0656': {
		'country code': 'NZ',
		'postal code': 656,
		'place name': 'Massey East',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8282,
		longitude: 174.6202,
		accuracy: 4,
	},
	'0657': {
		'country code': 'NZ',
		'postal code': 657,
		'place name': 'West Harbour',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8182,
		longitude: 174.6102,
		accuracy: 4,
	},
	'0660': {
		'country code': 'NZ',
		'postal code': 660,
		'place name': 'Waitakere',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8513,
		longitude: 174.5448,
		accuracy: 4,
	},
	'0661': {
		'country code': 'NZ',
		'postal code': 661,
		'place name': 'Te Atatu Peninsula',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.841,
		longitude: 174.6522,
		accuracy: 4,
	},
	'0662': {
		'country code': 'NZ',
		'postal code': 662,
		'place name': 'Brighams Creek',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7833,
		longitude: 174.6,
		accuracy: 4,
	},
	'0663': {
		'country code': 'NZ',
		'postal code': 663,
		'place name': 'Massey East',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8282,
		longitude: 174.6202,
		accuracy: 4,
	},
	'0740': {
		'country code': 'NZ',
		'postal code': 740,
		'place name': 'Hauraki',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7996,
		longitude: 174.778,
		accuracy: 4,
	},
	'0741': {
		'country code': 'NZ',
		'postal code': 741,
		'place name': 'Milford',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7716,
		longitude: 174.7659,
		accuracy: 4,
	},
	'0742': {
		'country code': 'NZ',
		'postal code': 742,
		'place name': 'Torbay',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.696,
		longitude: 174.7512,
		accuracy: 4,
	},
	'0743': {
		'country code': 'NZ',
		'postal code': 743,
		'place name': 'Forrest Hill',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7645,
		longitude: 174.7477,
		accuracy: 4,
	},
	'0744': {
		'country code': 'NZ',
		'postal code': 744,
		'place name': 'Devonport',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7775,
		longitude: 174.7399,
		accuracy: 1,
	},
	'0745': {
		'country code': 'NZ',
		'postal code': 745,
		'place name': 'Glenfield',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7826,
		longitude: 174.7211,
		accuracy: 4,
	},
	'0746': {
		'country code': 'NZ',
		'postal code': 746,
		'place name': 'Birkenhead',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8082,
		longitude: 174.7202,
		accuracy: 4,
	},
	'0747': {
		'country code': 'NZ',
		'postal code': 747,
		'place name': 'Marlborough',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7782,
		longitude: 174.7202,
		accuracy: 4,
	},
	'0748': {
		'country code': 'NZ',
		'postal code': 748,
		'place name': 'North Shore',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8,
		longitude: 174.75,
		accuracy: 4,
	},
	'0749': {
		'country code': 'NZ',
		'postal code': 749,
		'place name': 'Birkdale',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7972,
		longitude: 174.7046,
		accuracy: 4,
	},
	'0750': {
		'country code': 'NZ',
		'postal code': 750,
		'place name': 'Takapuna',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7917,
		longitude: 174.7758,
		accuracy: 4,
	},
	'0751': {
		'country code': 'NZ',
		'postal code': 751,
		'place name': 'Glenfield',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7826,
		longitude: 174.7211,
		accuracy: 4,
	},
	'0752': {
		'country code': 'NZ',
		'postal code': 752,
		'place name': 'Albany',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7167,
		longitude: 174.7,
		accuracy: 4,
	},
	'0753': {
		'country code': 'NZ',
		'postal code': 753,
		'place name': 'Browns Bay',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7193,
		longitude: 174.7367,
		accuracy: 4,
	},
	'0754': {
		'country code': 'NZ',
		'postal code': 754,
		'place name': 'Mairangi Bay',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7381,
		longitude: 174.752,
		accuracy: 4,
	},
	'0755': {
		'country code': 'NZ',
		'postal code': 755,
		'place name': 'Albany',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7167,
		longitude: 174.7,
		accuracy: 4,
	},
	'0756': {
		'country code': 'NZ',
		'postal code': 756,
		'place name': 'Paremoremo',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7667,
		longitude: 174.6667,
		accuracy: 4,
	},
	'0757': {
		'country code': 'NZ',
		'postal code': 757,
		'place name': 'Forrest Hill',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7645,
		longitude: 174.7477,
		accuracy: 4,
	},
	'0758': {
		'country code': 'NZ',
		'postal code': 758,
		'place name': 'Mairangi Bay',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7381,
		longitude: 174.752,
		accuracy: 4,
	},
	'0772': {
		'country code': 'NZ',
		'postal code': 772,
		'place name': 'Karekare',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9833,
		longitude: 174.4833,
		accuracy: 4,
	},
	'0781': {
		'country code': 'NZ',
		'postal code': 781,
		'place name': 'Piha',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.95,
		longitude: 174.4667,
		accuracy: 4,
	},
	'0782': {
		'country code': 'NZ',
		'postal code': 782,
		'place name': 'Waitakere',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8513,
		longitude: 174.5448,
		accuracy: 4,
	},
	'0792': {
		'country code': 'NZ',
		'postal code': 792,
		'place name': 'Red Vale',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6833,
		longitude: 174.7,
		accuracy: 4,
	},
	'0793': {
		'country code': 'NZ',
		'postal code': 793,
		'place name': 'Coatesville',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7167,
		longitude: 174.65,
		accuracy: 4,
	},
	'0794': {
		'country code': 'NZ',
		'postal code': 794,
		'place name': 'Dairy Flat',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6667,
		longitude: 174.65,
		accuracy: 4,
	},
	'0800': {
		'country code': 'NZ',
		'postal code': 800,
		'place name': 'Helensville',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6782,
		longitude: 174.4502,
		accuracy: 4,
	},
	'0810': {
		'country code': 'NZ',
		'postal code': 810,
		'place name': 'Huapai',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7667,
		longitude: 174.55,
		accuracy: 4,
	},
	'0812': {
		'country code': 'NZ',
		'postal code': 812,
		'place name': 'Waimauku',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.75,
		longitude: 174.5,
		accuracy: 4,
	},
	'0814': {
		'country code': 'NZ',
		'postal code': 814,
		'place name': 'West Harbour',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8182,
		longitude: 174.6102,
		accuracy: 4,
	},
	'0820': {
		'country code': 'NZ',
		'postal code': 820,
		'place name': 'Riverhead',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.75,
		longitude: 174.6,
		accuracy: 4,
	},
	'0830': {
		'country code': 'NZ',
		'postal code': 830,
		'place name': 'Parakai',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.65,
		longitude: 174.4333,
		accuracy: 4,
	},
	'0840': {
		'country code': 'NZ',
		'postal code': 840,
		'place name': 'Helensville',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6782,
		longitude: 174.4502,
		accuracy: 4,
	},
	'0841': {
		'country code': 'NZ',
		'postal code': 841,
		'place name': 'Huapai',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7667,
		longitude: 174.55,
		accuracy: 4,
	},
	'0842': {
		'country code': 'NZ',
		'postal code': 842,
		'place name': 'Pahitoa',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7833,
		longitude: 174.4833,
		accuracy: 4,
	},
	'0843': {
		'country code': 'NZ',
		'postal code': 843,
		'place name': 'Kaukapakapa',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6167,
		longitude: 174.5,
		accuracy: 4,
	},
	'0871': {
		'country code': 'NZ',
		'postal code': 871,
		'place name': 'Kaukapakapa',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6167,
		longitude: 174.5,
		accuracy: 4,
	},
	'0873': {
		'country code': 'NZ',
		'postal code': 873,
		'place name': 'Parakakau',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.5833,
		longitude: 174.6,
		accuracy: 4,
	},
	'0874': {
		'country code': 'NZ',
		'postal code': 874,
		'place name': 'Parkhurst',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.65,
		longitude: 174.4,
		accuracy: 4,
	},
	'0875': {
		'country code': 'NZ',
		'postal code': 875,
		'place name': 'Helensville',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6782,
		longitude: 174.4502,
		accuracy: 4,
	},
	'0881': {
		'country code': 'NZ',
		'postal code': 881,
		'place name': 'Woodhill',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.75,
		longitude: 174.4333,
		accuracy: 4,
	},
	'0882': {
		'country code': 'NZ',
		'postal code': 882,
		'place name': 'Waimauku',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.75,
		longitude: 174.5,
		accuracy: 4,
	},
	'0883': {
		'country code': 'NZ',
		'postal code': 883,
		'place name': 'Pahitoa',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7833,
		longitude: 174.4833,
		accuracy: 4,
	},
	'0891': {
		'country code': 'NZ',
		'postal code': 891,
		'place name': 'Huapai',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7667,
		longitude: 174.55,
		accuracy: 4,
	},
	'0892': {
		'country code': 'NZ',
		'postal code': 892,
		'place name': 'Kumeu',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7667,
		longitude: 174.5667,
		accuracy: 4,
	},
	'0900': {
		'country code': 'NZ',
		'postal code': 900,
		'place name': 'Wellsford',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.2833,
		longitude: 174.5167,
		accuracy: 4,
	},
	'0910': {
		'country code': 'NZ',
		'postal code': 910,
		'place name': 'Warkworth',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.4,
		longitude: 174.6667,
		accuracy: 4,
	},
	'0920': {
		'country code': 'NZ',
		'postal code': 920,
		'place name': 'Leigh',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.2833,
		longitude: 174.8167,
		accuracy: 4,
	},
	'0930': {
		'country code': 'NZ',
		'postal code': 930,
		'place name': 'Whangaparaoa',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6333,
		longitude: 174.7667,
		accuracy: 4,
	},
	'0931': {
		'country code': 'NZ',
		'postal code': 931,
		'place name': 'Orewa',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.5667,
		longitude: 174.7,
		accuracy: 4,
	},
	'0932': {
		'country code': 'NZ',
		'postal code': 932,
		'place name': 'Red Beach',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6124,
		longitude: 174.6887,
		accuracy: 4,
	},
	'0940': {
		'country code': 'NZ',
		'postal code': 940,
		'place name': 'Wellsford',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.2833,
		longitude: 174.5167,
		accuracy: 4,
	},
	'0941': {
		'country code': 'NZ',
		'postal code': 941,
		'place name': 'Warkworth',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.4,
		longitude: 174.6667,
		accuracy: 4,
	},
	'0942': {
		'country code': 'NZ',
		'postal code': 942,
		'place name': 'Snells Beach',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.4082,
		longitude: 174.7202,
		accuracy: 4,
	},
	'0943': {
		'country code': 'NZ',
		'postal code': 943,
		'place name': 'Whangaparaoa',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6333,
		longitude: 174.7667,
		accuracy: 4,
	},
	'0944': {
		'country code': 'NZ',
		'postal code': 944,
		'place name': 'Silverdale',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6187,
		longitude: 174.6776,
		accuracy: 4,
	},
	'0945': {
		'country code': 'NZ',
		'postal code': 945,
		'place name': 'Red Beach',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6124,
		longitude: 174.6887,
		accuracy: 4,
	},
	'0946': {
		'country code': 'NZ',
		'postal code': 946,
		'place name': 'Orewa',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.5667,
		longitude: 174.7,
		accuracy: 4,
	},
	'0947': {
		'country code': 'NZ',
		'postal code': 947,
		'place name': 'Leigh',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.2833,
		longitude: 174.8167,
		accuracy: 4,
	},
	'0948': {
		'country code': 'NZ',
		'postal code': 948,
		'place name': 'Matakana',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.35,
		longitude: 174.7167,
		accuracy: 4,
	},
	'0950': {
		'country code': 'NZ',
		'postal code': 950,
		'place name': 'Waiwera',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.55,
		longitude: 174.7167,
		accuracy: 4,
	},
	'0951': {
		'country code': 'NZ',
		'postal code': 951,
		'place name': 'Puhoi',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.5035,
		longitude: 174.6676,
		accuracy: 4,
	},
	'0960': {
		'country code': 'NZ',
		'postal code': 960,
		'place name': 'Okiwi',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.15,
		longitude: 175.4167,
		accuracy: 4,
	},
	'0961': {
		'country code': 'NZ',
		'postal code': 961,
		'place name': 'Okupu',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.2667,
		longitude: 175.4333,
		accuracy: 4,
	},
	'0962': {
		'country code': 'NZ',
		'postal code': 962,
		'place name': 'Tryphena',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.3014,
		longitude: 175.4893,
		accuracy: 4,
	},
	'0963': {
		'country code': 'NZ',
		'postal code': 963,
		'place name': 'Port Fitzroy',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1667,
		longitude: 175.35,
		accuracy: 4,
	},
	'0972': {
		'country code': 'NZ',
		'postal code': 972,
		'place name': 'Wayby',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.3333,
		longitude: 174.5667,
		accuracy: 4,
	},
	'0973': {
		'country code': 'NZ',
		'postal code': 973,
		'place name': 'Wharehine',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.3167,
		longitude: 174.4167,
		accuracy: 4,
	},
	'0974': {
		'country code': 'NZ',
		'postal code': 974,
		'place name': 'Te Hana',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.2667,
		longitude: 174.5167,
		accuracy: 4,
	},
	'0977': {
		'country code': 'NZ',
		'postal code': 977,
		'place name': 'Wharehine',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.3167,
		longitude: 174.4167,
		accuracy: 4,
	},
	'0981': {
		'country code': 'NZ',
		'postal code': 981,
		'place name': 'Woodcocks',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.45,
		longitude: 174.5667,
		accuracy: 4,
	},
	'0982': {
		'country code': 'NZ',
		'postal code': 982,
		'place name': 'Algies Bay',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.4353,
		longitude: 174.7403,
		accuracy: 4,
	},
	'0983': {
		'country code': 'NZ',
		'postal code': 983,
		'place name': 'Pohuehue',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.45,
		longitude: 174.65,
		accuracy: 4,
	},
	'0984': {
		'country code': 'NZ',
		'postal code': 984,
		'place name': 'Ahuroa',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.4753,
		longitude: 174.5542,
		accuracy: 4,
	},
	'0985': {
		'country code': 'NZ',
		'postal code': 985,
		'place name': 'Big Omaha',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.3333,
		longitude: 174.7333,
		accuracy: 4,
	},
	'0986': {
		'country code': 'NZ',
		'postal code': 986,
		'place name': 'Tawharanui',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.3833,
		longitude: 174.8,
		accuracy: 4,
	},
	'0991': {
		'country code': 'NZ',
		'postal code': 991,
		'place name': 'Whangapara',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.2333,
		longitude: 175.4,
		accuracy: 4,
	},
	'0992': {
		'country code': 'NZ',
		'postal code': 992,
		'place name': 'Silverdale',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6187,
		longitude: 174.6776,
		accuracy: 4,
	},
	'0993': {
		'country code': 'NZ',
		'postal code': 993,
		'place name': 'Red Beach',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6124,
		longitude: 174.6887,
		accuracy: 4,
	},
	'1010': {
		'country code': 'NZ',
		'postal code': 1010,
		'place name': 'Auckland',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8485,
		longitude: 174.7635,
		accuracy: 4,
	},
	'1011': {
		'country code': 'NZ',
		'postal code': 1011,
		'place name': 'Herne Bay',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8461,
		longitude: 174.7292,
		accuracy: 4,
	},
	'1021': {
		'country code': 'NZ',
		'postal code': 1021,
		'place name': 'Arch Hill',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8597,
		longitude: 174.7531,
		accuracy: 4,
	},
	'1022': {
		'country code': 'NZ',
		'postal code': 1022,
		'place name': 'Point Chevalier',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8633,
		longitude: 174.7049,
		accuracy: 4,
	},
	'1023': {
		'country code': 'NZ',
		'postal code': 1023,
		'place name': 'Epsom',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8874,
		longitude: 174.7706,
		accuracy: 4,
	},
	'1024': {
		'country code': 'NZ',
		'postal code': 1024,
		'place name': 'Epsom',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8874,
		longitude: 174.7706,
		accuracy: 4,
	},
	'1025': {
		'country code': 'NZ',
		'postal code': 1025,
		'place name': 'Sandringham',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8882,
		longitude: 174.7302,
		accuracy: 4,
	},
	'1026': {
		'country code': 'NZ',
		'postal code': 1026,
		'place name': 'Waterview',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8782,
		longitude: 174.6902,
		accuracy: 4,
	},
	'1041': {
		'country code': 'NZ',
		'postal code': 1041,
		'place name': 'Mount Roskill',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9082,
		longitude: 174.7302,
		accuracy: 4,
	},
	'1042': {
		'country code': 'NZ',
		'postal code': 1042,
		'place name': 'Hillsborough',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9182,
		longitude: 174.7502,
		accuracy: 4,
	},
	'1050': {
		'country code': 'NZ',
		'postal code': 1050,
		'place name': 'Remuera',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8804,
		longitude: 174.7982,
		accuracy: 4,
	},
	'1051': {
		'country code': 'NZ',
		'postal code': 1051,
		'place name': 'Ellerslie',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8982,
		longitude: 174.8102,
		accuracy: 4,
	},
	'1052': {
		'country code': 'NZ',
		'postal code': 1052,
		'place name': 'Point Resolution',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8514,
		longitude: 174.7894,
		accuracy: 4,
	},
	'1060': {
		'country code': 'NZ',
		'postal code': 1060,
		'place name': 'Penrose',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9167,
		longitude: 174.8167,
		accuracy: 4,
	},
	'1061': {
		'country code': 'NZ',
		'postal code': 1061,
		'place name': 'Te Papapa',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9182,
		longitude: 174.8002,
		accuracy: 4,
	},
	'1062': {
		'country code': 'NZ',
		'postal code': 1062,
		'place name': 'OTAHUHU',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9382,
		longitude: 174.8402,
		accuracy: 4,
	},
	'1071': {
		'country code': 'NZ',
		'postal code': 1071,
		'place name': 'Kohimarama',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8582,
		longitude: 174.8402,
		accuracy: 4,
	},
	'1072': {
		'country code': 'NZ',
		'postal code': 1072,
		'place name': 'Tamaki',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8882,
		longitude: 174.8602,
		accuracy: 4,
	},
	'1081': {
		'country code': 'NZ',
		'postal code': 1081,
		'place name': 'Surfdale',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7911,
		longitude: 175.0257,
		accuracy: 4,
	},
	'1140': {
		'country code': 'NZ',
		'postal code': 1140,
		'place name': 'Mechanics Bay',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8382,
		longitude: 174.7802,
		accuracy: 4,
	},
	'1141': {
		'country code': 'NZ',
		'postal code': 1141,
		'place name': 'Newmarket',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8698,
		longitude: 174.7757,
		accuracy: 4,
	},
	'1142': {
		'country code': 'NZ',
		'postal code': 1142,
		'place name': 'Newton',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.85,
		longitude: 174.75,
		accuracy: 4,
	},
	'1143': {
		'country code': 'NZ',
		'postal code': 1143,
		'place name': 'Mechanics Bay',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8382,
		longitude: 174.7802,
		accuracy: 4,
	},
	'1144': {
		'country code': 'NZ',
		'postal code': 1144,
		'place name': 'Freemans Bay',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8482,
		longitude: 174.7402,
		accuracy: 4,
	},
	'1145': {
		'country code': 'NZ',
		'postal code': 1145,
		'place name': 'Grafton',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8603,
		longitude: 174.7657,
		accuracy: 4,
	},
	'1147': {
		'country code': 'NZ',
		'postal code': 1147,
		'place name': 'Herne Bay',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8461,
		longitude: 174.7292,
		accuracy: 4,
	},
	'1148': {
		'country code': 'NZ',
		'postal code': 1148,
		'place name': 'Auckland',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8485,
		longitude: 174.7635,
		accuracy: 4,
	},
	'1149': {
		'country code': 'NZ',
		'postal code': 1149,
		'place name': 'Parnell',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8667,
		longitude: 174.7833,
		accuracy: 4,
	},
	'1150': {
		'country code': 'NZ',
		'postal code': 1150,
		'place name': 'Auckland',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8485,
		longitude: 174.7635,
		accuracy: 4,
	},
	'1151': {
		'country code': 'NZ',
		'postal code': 1151,
		'place name': 'Point Resolution',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8514,
		longitude: 174.7894,
		accuracy: 4,
	},
	'1245': {
		'country code': 'NZ',
		'postal code': 1245,
		'place name': 'Western Springs',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8667,
		longitude: 174.7333,
		accuracy: 4,
	},
	'1246': {
		'country code': 'NZ',
		'postal code': 1246,
		'place name': 'Mount Albert',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8782,
		longitude: 174.7202,
		accuracy: 4,
	},
	'1340': {
		'country code': 'NZ',
		'postal code': 1340,
		'place name': 'Wesley',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8982,
		longitude: 174.7302,
		accuracy: 4,
	},
	'1342': {
		'country code': 'NZ',
		'postal code': 1342,
		'place name': 'Balmoral',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8882,
		longitude: 174.7402,
		accuracy: 4,
	},
	'1344': {
		'country code': 'NZ',
		'postal code': 1344,
		'place name': 'One Tree Hill',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8982,
		longitude: 174.7802,
		accuracy: 4,
	},
	'1345': {
		'country code': 'NZ',
		'postal code': 1345,
		'place name': 'Royal Oak',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9082,
		longitude: 174.7702,
		accuracy: 4,
	},
	'1346': {
		'country code': 'NZ',
		'postal code': 1346,
		'place name': 'Sandringham',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8882,
		longitude: 174.7302,
		accuracy: 4,
	},
	'1347': {
		'country code': 'NZ',
		'postal code': 1347,
		'place name': 'One Tree Hill',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8982,
		longitude: 174.7802,
		accuracy: 4,
	},
	'1348': {
		'country code': 'NZ',
		'postal code': 1348,
		'place name': 'Avondale',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8833,
		longitude: 174.7,
		accuracy: 4,
	},
	'1349': {
		'country code': 'NZ',
		'postal code': 1349,
		'place name': 'Epsom',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8874,
		longitude: 174.7706,
		accuracy: 4,
	},
	'1350': {
		'country code': 'NZ',
		'postal code': 1350,
		'place name': 'Owairaka',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8882,
		longitude: 174.7102,
		accuracy: 4,
	},
	'1351': {
		'country code': 'NZ',
		'postal code': 1351,
		'place name': 'Balmoral',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8882,
		longitude: 174.7402,
		accuracy: 4,
	},
	'1352': {
		'country code': 'NZ',
		'postal code': 1352,
		'place name': 'Mount Eden',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8816,
		longitude: 174.762,
		accuracy: 4,
	},
	'1440': {
		'country code': 'NZ',
		'postal code': 1440,
		'place name': 'Three Kings',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8982,
		longitude: 174.7502,
		accuracy: 4,
	},
	'1443': {
		'country code': 'NZ',
		'postal code': 1443,
		'place name': 'Lynfield',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9282,
		longitude: 174.7202,
		accuracy: 4,
	},
	'1445': {
		'country code': 'NZ',
		'postal code': 1445,
		'place name': 'Waikowhai',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9282,
		longitude: 174.7302,
		accuracy: 4,
	},
	'1446': {
		'country code': 'NZ',
		'postal code': 1446,
		'place name': 'Mount Eden',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8816,
		longitude: 174.762,
		accuracy: 4,
	},
	'1541': {
		'country code': 'NZ',
		'postal code': 1541,
		'place name': 'Greenlane',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8906,
		longitude: 174.7943,
		accuracy: 4,
	},
	'1542': {
		'country code': 'NZ',
		'postal code': 1542,
		'place name': 'Ellerslie',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8982,
		longitude: 174.8102,
		accuracy: 4,
	},
	'1543': {
		'country code': 'NZ',
		'postal code': 1543,
		'place name': 'Greenlane',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8906,
		longitude: 174.7943,
		accuracy: 4,
	},
	'1544': {
		'country code': 'NZ',
		'postal code': 1544,
		'place name': 'Ellerslie',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8982,
		longitude: 174.8102,
		accuracy: 4,
	},
	'1545': {
		'country code': 'NZ',
		'postal code': 1545,
		'place name': 'Panmure',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8833,
		longitude: 174.8667,
		accuracy: 4,
	},
	'1546': {
		'country code': 'NZ',
		'postal code': 1546,
		'place name': 'Ellerslie',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8982,
		longitude: 174.8102,
		accuracy: 4,
	},
	'1640': {
		'country code': 'NZ',
		'postal code': 1640,
		'place name': 'Otahuhu',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9382,
		longitude: 174.8402,
		accuracy: 4,
	},
	'1641': {
		'country code': 'NZ',
		'postal code': 1641,
		'place name': 'Panmure',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8833,
		longitude: 174.8667,
		accuracy: 4,
	},
	'1642': {
		'country code': 'NZ',
		'postal code': 1642,
		'place name': 'Mount Wellington',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9082,
		longitude: 174.8202,
		accuracy: 4,
	},
	'1643': {
		'country code': 'NZ',
		'postal code': 1643,
		'place name': 'Onehunga',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9182,
		longitude: 174.7802,
		accuracy: 4,
	},
	'1740': {
		'country code': 'NZ',
		'postal code': 1740,
		'place name': 'Saint Heliers',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.85,
		longitude: 174.8667,
		accuracy: 4,
	},
	'1741': {
		'country code': 'NZ',
		'postal code': 1741,
		'place name': 'Panmure',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8833,
		longitude: 174.8667,
		accuracy: 4,
	},
	'1742': {
		'country code': 'NZ',
		'postal code': 1742,
		'place name': 'Meadowbank',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8682,
		longitude: 174.8202,
		accuracy: 4,
	},
	'1743': {
		'country code': 'NZ',
		'postal code': 1743,
		'place name': 'Point England',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8782,
		longitude: 174.8602,
		accuracy: 4,
	},
	'1744': {
		'country code': 'NZ',
		'postal code': 1744,
		'place name': 'Mission Bay',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8547,
		longitude: 174.8317,
		accuracy: 4,
	},
	'1745': {
		'country code': 'NZ',
		'postal code': 1745,
		'place name': 'Orakei',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.85,
		longitude: 174.8167,
		accuracy: 4,
	},
	'1746': {
		'country code': 'NZ',
		'postal code': 1746,
		'place name': 'New Windsor',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8982,
		longitude: 174.7002,
		accuracy: 4,
	},
	'1840': {
		'country code': 'NZ',
		'postal code': 1840,
		'place name': 'Oneroa',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7782,
		longitude: 175.0102,
		accuracy: 4,
	},
	'1841': {
		'country code': 'NZ',
		'postal code': 1841,
		'place name': 'Onetangi',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7833,
		longitude: 175.0833,
		accuracy: 4,
	},
	'1842': {
		'country code': 'NZ',
		'postal code': 1842,
		'place name': 'Blackpool',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7889,
		longitude: 175.0144,
		accuracy: 4,
	},
	'1843': {
		'country code': 'NZ',
		'postal code': 1843,
		'place name': 'Ostend',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7982,
		longitude: 175.0402,
		accuracy: 4,
	},
	'1971': {
		'country code': 'NZ',
		'postal code': 1971,
		'place name': 'Onetangi',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7833,
		longitude: 175.0833,
		accuracy: 4,
	},
	'2010': {
		'country code': 'NZ',
		'postal code': 2010,
		'place name': 'Pakuranga Heights',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9082,
		longitude: 174.8802,
		accuracy: 4,
	},
	'2012': {
		'country code': 'NZ',
		'postal code': 2012,
		'place name': 'Eastern Beach',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8682,
		longitude: 174.9002,
		accuracy: 4,
	},
	'2013': {
		'country code': 'NZ',
		'postal code': 2013,
		'place name': 'East Tamaki',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.95,
		longitude: 174.9,
		accuracy: 4,
	},
	'2014': {
		'country code': 'NZ',
		'postal code': 2014,
		'place name': 'Shelly Park',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9075,
		longitude: 174.9459,
		accuracy: 4,
	},
	'2016': {
		'country code': 'NZ',
		'postal code': 2016,
		'place name': 'East Tamaki',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.95,
		longitude: 174.9,
		accuracy: 4,
	},
	'2018': {
		'country code': 'NZ',
		'postal code': 2018,
		'place name': 'Beachlands',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8833,
		longitude: 175,
		accuracy: 4,
	},
	'2022': {
		'country code': 'NZ',
		'postal code': 2022,
		'place name': 'Mangere',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9681,
		longitude: 174.7987,
		accuracy: 4,
	},
	'2023': {
		'country code': 'NZ',
		'postal code': 2023,
		'place name': 'Wiri',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9982,
		longitude: 174.8602,
		accuracy: 4,
	},
	'2024': {
		'country code': 'NZ',
		'postal code': 2024,
		'place name': 'Mangere East',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9659,
		longitude: 174.8239,
		accuracy: 4,
	},
	'2025': {
		'country code': 'NZ',
		'postal code': 2025,
		'place name': 'Wiri',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9982,
		longitude: 174.8602,
		accuracy: 4,
	},
	'2102': {
		'country code': 'NZ',
		'postal code': 2102,
		'place name': 'Manurewa',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0182,
		longitude: 174.8802,
		accuracy: 4,
	},
	'2103': {
		'country code': 'NZ',
		'postal code': 2103,
		'place name': 'Weymouth',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0333,
		longitude: 174.8667,
		accuracy: 4,
	},
	'2104': {
		'country code': 'NZ',
		'postal code': 2104,
		'place name': 'Wiri',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9982,
		longitude: 174.8602,
		accuracy: 4,
	},
	'2105': {
		'country code': 'NZ',
		'postal code': 2105,
		'place name': 'Manurewa East',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0182,
		longitude: 174.9102,
		accuracy: 4,
	},
	'2110': {
		'country code': 'NZ',
		'postal code': 2110,
		'place name': 'Red Hill',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0582,
		longitude: 174.9702,
		accuracy: 4,
	},
	'2112': {
		'country code': 'NZ',
		'postal code': 2112,
		'place name': 'Takanini',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0482,
		longitude: 174.9002,
		accuracy: 4,
	},
	'2113': {
		'country code': 'NZ',
		'postal code': 2113,
		'place name': 'Papakura',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0657,
		longitude: 174.9439,
		accuracy: 4,
	},
	'2120': {
		'country code': 'NZ',
		'postal code': 2120,
		'place name': 'Pukekohe',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.202,
		longitude: 174.9036,
		accuracy: 4,
	},
	'2122': {
		'country code': 'NZ',
		'postal code': 2122,
		'place name': 'Waiau Pa',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.1333,
		longitude: 174.75,
		accuracy: 4,
	},
	'2123': {
		'country code': 'NZ',
		'postal code': 2123,
		'place name': 'Waiuku',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2481,
		longitude: 174.7349,
		accuracy: 4,
	},
	'2140': {
		'country code': 'NZ',
		'postal code': 2140,
		'place name': 'Pakuranga',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8833,
		longitude: 174.9167,
		accuracy: 4,
	},
	'2141': {
		'country code': 'NZ',
		'postal code': 2141,
		'place name': 'East Tamaki',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.95,
		longitude: 174.9,
		accuracy: 4,
	},
	'2142': {
		'country code': 'NZ',
		'postal code': 2142,
		'place name': 'East Tamaki',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.95,
		longitude: 174.9,
		accuracy: 4,
	},
	'2143': {
		'country code': 'NZ',
		'postal code': 2143,
		'place name': 'Botany Downs',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.906,
		longitude: 174.9179,
		accuracy: 4,
	},
	'2144': {
		'country code': 'NZ',
		'postal code': 2144,
		'place name': 'Eastern Beach',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8682,
		longitude: 174.9002,
		accuracy: 4,
	},
	'2145': {
		'country code': 'NZ',
		'postal code': 2145,
		'place name': 'Shelly Park',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9075,
		longitude: 174.9459,
		accuracy: 4,
	},
	'2146': {
		'country code': 'NZ',
		'postal code': 2146,
		'place name': 'Shelly Park',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9075,
		longitude: 174.9459,
		accuracy: 4,
	},
	'2147': {
		'country code': 'NZ',
		'postal code': 2147,
		'place name': 'Beachlands',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8833,
		longitude: 175,
		accuracy: 4,
	},
	'2148': {
		'country code': 'NZ',
		'postal code': 2148,
		'place name': 'Maraetai',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8833,
		longitude: 175.05,
		accuracy: 4,
	},
	'2149': {
		'country code': 'NZ',
		'postal code': 2149,
		'place name': 'Whitford',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9333,
		longitude: 174.9667,
		accuracy: 4,
	},
	'2150': {
		'country code': 'NZ',
		'postal code': 2150,
		'place name': 'Mangere',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9681,
		longitude: 174.7987,
		accuracy: 4,
	},
	'2151': {
		'country code': 'NZ',
		'postal code': 2151,
		'place name': 'Favona',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9333,
		longitude: 174.8,
		accuracy: 4,
	},
	'2153': {
		'country code': 'NZ',
		'postal code': 2153,
		'place name': 'Mangere',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9681,
		longitude: 174.7987,
		accuracy: 4,
	},
	'2154': {
		'country code': 'NZ',
		'postal code': 2154,
		'place name': 'Mangere',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9681,
		longitude: 174.7987,
		accuracy: 4,
	},
	'2155': {
		'country code': 'NZ',
		'postal code': 2155,
		'place name': 'Papatoetoe',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9682,
		longitude: 174.8402,
		accuracy: 4,
	},
	'2156': {
		'country code': 'NZ',
		'postal code': 2156,
		'place name': 'Papatoetoe',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9682,
		longitude: 174.8402,
		accuracy: 4,
	},
	'2157': {
		'country code': 'NZ',
		'postal code': 2157,
		'place name': 'Wiri',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9982,
		longitude: 174.8602,
		accuracy: 4,
	},
	'2158': {
		'country code': 'NZ',
		'postal code': 2158,
		'place name': 'Mangere East',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9659,
		longitude: 174.8239,
		accuracy: 4,
	},
	'2159': {
		'country code': 'NZ',
		'postal code': 2159,
		'place name': 'Otara',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9482,
		longitude: 174.8702,
		accuracy: 4,
	},
	'2160': {
		'country code': 'NZ',
		'postal code': 2160,
		'place name': 'Wiri',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9982,
		longitude: 174.8602,
		accuracy: 4,
	},
	'2240': {
		'country code': 'NZ',
		'postal code': 2240,
		'place name': 'Homai',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0167,
		longitude: 174.8833,
		accuracy: 4,
	},
	'2241': {
		'country code': 'NZ',
		'postal code': 2241,
		'place name': 'Wiri',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9982,
		longitude: 174.8602,
		accuracy: 4,
	},
	'2242': {
		'country code': 'NZ',
		'postal code': 2242,
		'place name': 'Weymouth',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0333,
		longitude: 174.8667,
		accuracy: 4,
	},
	'2243': {
		'country code': 'NZ',
		'postal code': 2243,
		'place name': 'Manurewa East',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0182,
		longitude: 174.9102,
		accuracy: 4,
	},
	'2244': {
		'country code': 'NZ',
		'postal code': 2244,
		'place name': 'Pahurehure',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0582,
		longitude: 174.9202,
		accuracy: 4,
	},
	'2245': {
		'country code': 'NZ',
		'postal code': 2245,
		'place name': 'Takanini',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0482,
		longitude: 174.9002,
		accuracy: 4,
	},
	'2246': {
		'country code': 'NZ',
		'postal code': 2246,
		'place name': 'Pahurehure',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0582,
		longitude: 174.9202,
		accuracy: 4,
	},
	'2247': {
		'country code': 'NZ',
		'postal code': 2247,
		'place name': 'Drury',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.1,
		longitude: 174.95,
		accuracy: 4,
	},
	'2248': {
		'country code': 'NZ',
		'postal code': 2248,
		'place name': 'Clevedon',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37,
		longitude: 175.05,
		accuracy: 4,
	},
	'2340': {
		'country code': 'NZ',
		'postal code': 2340,
		'place name': 'Pukekohe',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.202,
		longitude: 174.9036,
		accuracy: 4,
	},
	'2341': {
		'country code': 'NZ',
		'postal code': 2341,
		'place name': 'Waiuku',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2481,
		longitude: 174.7349,
		accuracy: 4,
	},
	'2343': {
		'country code': 'NZ',
		'postal code': 2343,
		'place name': 'Bombay',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.1667,
		longitude: 174.9833,
		accuracy: 4,
	},
	'2344': {
		'country code': 'NZ',
		'postal code': 2344,
		'place name': 'Patumahoe',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.1833,
		longitude: 174.8333,
		accuracy: 4,
	},
	'2345': {
		'country code': 'NZ',
		'postal code': 2345,
		'place name': 'Waiau Pa',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.1333,
		longitude: 174.75,
		accuracy: 4,
	},
	'2571': {
		'country code': 'NZ',
		'postal code': 2571,
		'place name': 'Waikopua',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9167,
		longitude: 174.9833,
		accuracy: 4,
	},
	'2576': {
		'country code': 'NZ',
		'postal code': 2576,
		'place name': 'Whitford',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9333,
		longitude: 174.9667,
		accuracy: 4,
	},
	'2577': {
		'country code': 'NZ',
		'postal code': 2577,
		'place name': 'Drury',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.1,
		longitude: 174.95,
		accuracy: 4,
	},
	'2578': {
		'country code': 'NZ',
		'postal code': 2578,
		'place name': 'Ramarama',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.15,
		longitude: 174.95,
		accuracy: 4,
	},
	'2579': {
		'country code': 'NZ',
		'postal code': 2579,
		'place name': 'Ararimu',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.15,
		longitude: 175.0333,
		accuracy: 4,
	},
	'2580': {
		'country code': 'NZ',
		'postal code': 2580,
		'place name': 'Weymouth',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0333,
		longitude: 174.8667,
		accuracy: 4,
	},
	'2582': {
		'country code': 'NZ',
		'postal code': 2582,
		'place name': 'Clevedon',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37,
		longitude: 175.05,
		accuracy: 4,
	},
	'2583': {
		'country code': 'NZ',
		'postal code': 2583,
		'place name': 'Paparimu',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.1333,
		longitude: 175.1167,
		accuracy: 4,
	},
	'2584': {
		'country code': 'NZ',
		'postal code': 2584,
		'place name': 'Opaheke',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0833,
		longitude: 174.9667,
		accuracy: 4,
	},
	'2585': {
		'country code': 'NZ',
		'postal code': 2585,
		'place name': 'Whakatiri',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.95,
		longitude: 175.1167,
		accuracy: 4,
	},
	'2675': {
		'country code': 'NZ',
		'postal code': 2675,
		'place name': 'Ararimu',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.15,
		longitude: 175.0333,
		accuracy: 4,
	},
	'2676': {
		'country code': 'NZ',
		'postal code': 2676,
		'place name': 'Paerata',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.1667,
		longitude: 174.9,
		accuracy: 4,
	},
	'2677': {
		'country code': 'NZ',
		'postal code': 2677,
		'place name': 'Pukekohe East',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2,
		longitude: 174.95,
		accuracy: 4,
	},
	'2678': {
		'country code': 'NZ',
		'postal code': 2678,
		'place name': 'Mauku',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2,
		longitude: 174.8167,
		accuracy: 4,
	},
	'2679': {
		'country code': 'NZ',
		'postal code': 2679,
		'place name': 'Waiau Pa',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.1333,
		longitude: 174.75,
		accuracy: 4,
	},
	'2681': {
		'country code': 'NZ',
		'postal code': 2681,
		'place name': 'Glenbrook',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2167,
		longitude: 174.7833,
		accuracy: 4,
	},
	'2683': {
		'country code': 'NZ',
		'postal code': 2683,
		'place name': 'Waipipi',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2167,
		longitude: 174.6667,
		accuracy: 4,
	},
	'2684': {
		'country code': 'NZ',
		'postal code': 2684,
		'place name': 'Pollok',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.1333,
		longitude: 174.6167,
		accuracy: 4,
	},
	'2693': {
		'country code': 'NZ',
		'postal code': 2693,
		'place name': 'Te Kohanga',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.3141,
		longitude: 174.8451,
		accuracy: 4,
	},
	'2696': {
		'country code': 'NZ',
		'postal code': 2696,
		'place name': 'Opuatia',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.4048,
		longitude: 175.0002,
		accuracy: 4,
	},
	'2697': {
		'country code': 'NZ',
		'postal code': 2697,
		'place name': 'Te Kohanga',
		'admin name1': 'Auckland',
		'admin code1': 'E7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.3141,
		longitude: 174.8451,
		accuracy: 4,
	},
	'3010': {
		'country code': 'NZ',
		'postal code': 3010,
		'place name': 'Ohinemutu',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1276,
		longitude: 176.2446,
		accuracy: 4,
	},
	'3015': {
		'country code': 'NZ',
		'postal code': 3015,
		'place name': 'Sunnybrook',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1482,
		longitude: 176.2102,
		accuracy: 4,
	},
	'3020': {
		'country code': 'NZ',
		'postal code': 3020,
		'place name': 'Mamaku',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1,
		longitude: 176.0833,
		accuracy: 4,
	},
	'3025': {
		'country code': 'NZ',
		'postal code': 3025,
		'place name': 'Murupara',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.4667,
		longitude: 176.7,
		accuracy: 4,
	},
	'3040': {
		'country code': 'NZ',
		'postal code': 3040,
		'place name': 'Ngapuna',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1468,
		longitude: 176.2747,
		accuracy: 4,
	},
	'3041': {
		'country code': 'NZ',
		'postal code': 3041,
		'place name': 'Ngongotaha',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0833,
		longitude: 176.2,
		accuracy: 4,
	},
	'3042': {
		'country code': 'NZ',
		'postal code': 3042,
		'place name': 'Owhata',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1326,
		longitude: 176.2982,
		accuracy: 4,
	},
	'3043': {
		'country code': 'NZ',
		'postal code': 3043,
		'place name': 'Whakarewarewa',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1603,
		longitude: 176.2651,
		accuracy: 4,
	},
	'3044': {
		'country code': 'NZ',
		'postal code': 3044,
		'place name': 'Glenholme',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1487,
		longitude: 176.2471,
		accuracy: 4,
	},
	'3045': {
		'country code': 'NZ',
		'postal code': 3045,
		'place name': 'Ngapuna',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1468,
		longitude: 176.2747,
		accuracy: 4,
	},
	'3046': {
		'country code': 'NZ',
		'postal code': 3046,
		'place name': 'Koutu',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1197,
		longitude: 176.2383,
		accuracy: 4,
	},
	'3047': {
		'country code': 'NZ',
		'postal code': 3047,
		'place name': 'Mangakakahi',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1335,
		longitude: 176.2344,
		accuracy: 4,
	},
	'3048': {
		'country code': 'NZ',
		'postal code': 3048,
		'place name': 'Tihiotonga',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1726,
		longitude: 176.2432,
		accuracy: 4,
	},
	'3049': {
		'country code': 'NZ',
		'postal code': 3049,
		'place name': 'Mamaku',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1,
		longitude: 176.0833,
		accuracy: 4,
	},
	'3060': {
		'country code': 'NZ',
		'postal code': 3060,
		'place name': 'Reporoa',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.4359,
		longitude: 176.3412,
		accuracy: 4,
	},
	'3062': {
		'country code': 'NZ',
		'postal code': 3062,
		'place name': 'Murupara',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.4667,
		longitude: 176.7,
		accuracy: 4,
	},
	'3072': {
		'country code': 'NZ',
		'postal code': 3072,
		'place name': 'Tarukenga',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0752,
		longitude: 176.1581,
		accuracy: 4,
	},
	'3073': {
		'country code': 'NZ',
		'postal code': 3073,
		'place name': 'Waimangu',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.284,
		longitude: 176.3826,
		accuracy: 4,
	},
	'3074': {
		'country code': 'NZ',
		'postal code': 3074,
		'place name': 'Tikitere',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0667,
		longitude: 176.3667,
		accuracy: 4,
	},
	'3077': {
		'country code': 'NZ',
		'postal code': 3077,
		'place name': 'Guthrie',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.3,
		longitude: 176.15,
		accuracy: 4,
	},
	'3079': {
		'country code': 'NZ',
		'postal code': 3079,
		'place name': 'Ruatahuna',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.6138,
		longitude: 176.9592,
		accuracy: 4,
	},
	'3081': {
		'country code': 'NZ',
		'postal code': 3081,
		'place name': 'Tahorakui',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.479,
		longitude: 176.2724,
		accuracy: 4,
	},
	'3083': {
		'country code': 'NZ',
		'postal code': 3083,
		'place name': 'Tahorakui',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.479,
		longitude: 176.2724,
		accuracy: 4,
	},
	'3110': {
		'country code': 'NZ',
		'postal code': 3110,
		'place name': 'Tauranga',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6861,
		longitude: 176.1667,
		accuracy: 4,
	},
	'3112': {
		'country code': 'NZ',
		'postal code': 3112,
		'place name': 'Hairini',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7333,
		longitude: 176.1667,
		accuracy: 4,
	},
	'3114': {
		'country code': 'NZ',
		'postal code': 3114,
		'place name': 'Omokoroa Beach',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6333,
		longitude: 176.05,
		accuracy: 4,
	},
	'3116': {
		'country code': 'NZ',
		'postal code': 3116,
		'place name': 'Omanu',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6568,
		longitude: 176.2084,
		accuracy: 4,
	},
	'3118': {
		'country code': 'NZ',
		'postal code': 3118,
		'place name': 'Papamoa Beach',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6973,
		longitude: 176.2846,
		accuracy: 4,
	},
	'3119': {
		'country code': 'NZ',
		'postal code': 3119,
		'place name': 'Te Puke',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7782,
		longitude: 176.3302,
		accuracy: 4,
	},
	'3120': {
		'country code': 'NZ',
		'postal code': 3120,
		'place name': 'Poroporo',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9833,
		longitude: 176.9667,
		accuracy: 4,
	},
	'3121': {
		'country code': 'NZ',
		'postal code': 3121,
		'place name': 'Ohope Beach',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9752,
		longitude: 177.0759,
		accuracy: 4,
	},
	'3122': {
		'country code': 'NZ',
		'postal code': 3122,
		'place name': 'Opotiki',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0091,
		longitude: 177.2871,
		accuracy: 4,
	},
	'3123': {
		'country code': 'NZ',
		'postal code': 3123,
		'place name': 'Taneatua',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0667,
		longitude: 177.0167,
		accuracy: 4,
	},
	'3127': {
		'country code': 'NZ',
		'postal code': 3127,
		'place name': 'Kawerau',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1,
		longitude: 176.7,
		accuracy: 4,
	},
	'3129': {
		'country code': 'NZ',
		'postal code': 3129,
		'place name': 'Katikati',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.55,
		longitude: 175.9167,
		accuracy: 4,
	},
	'3140': {
		'country code': 'NZ',
		'postal code': 3140,
		'place name': 'Tauranga',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6861,
		longitude: 176.1667,
		accuracy: 4,
	},
	'3141': {
		'country code': 'NZ',
		'postal code': 3141,
		'place name': 'Tauranga',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6861,
		longitude: 176.1667,
		accuracy: 4,
	},
	'3142': {
		'country code': 'NZ',
		'postal code': 3142,
		'place name': 'Greerton',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7254,
		longitude: 176.1335,
		accuracy: 4,
	},
	'3143': {
		'country code': 'NZ',
		'postal code': 3143,
		'place name': 'Tauranga',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6861,
		longitude: 176.1667,
		accuracy: 4,
	},
	'3144': {
		'country code': 'NZ',
		'postal code': 3144,
		'place name': 'Tauranga',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6861,
		longitude: 176.1667,
		accuracy: 4,
	},
	'3145': {
		'country code': 'NZ',
		'postal code': 3145,
		'place name': 'Otumoetai',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6716,
		longitude: 176.1386,
		accuracy: 4,
	},
	'3146': {
		'country code': 'NZ',
		'postal code': 3146,
		'place name': 'Judea',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6953,
		longitude: 176.1441,
		accuracy: 4,
	},
	'3147': {
		'country code': 'NZ',
		'postal code': 3147,
		'place name': 'Bethlehem',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.695,
		longitude: 176.1086,
		accuracy: 4,
	},
	'3148': {
		'country code': 'NZ',
		'postal code': 3148,
		'place name': 'Hairini',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7333,
		longitude: 176.1667,
		accuracy: 4,
	},
	'3149': {
		'country code': 'NZ',
		'postal code': 3149,
		'place name': 'Omanu',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6568,
		longitude: 176.2084,
		accuracy: 4,
	},
	'3150': {
		'country code': 'NZ',
		'postal code': 3150,
		'place name': 'Mount Maunganui',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6417,
		longitude: 176.1861,
		accuracy: 4,
	},
	'3151': {
		'country code': 'NZ',
		'postal code': 3151,
		'place name': 'Kairua',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7167,
		longitude: 176.2833,
		accuracy: 4,
	},
	'3152': {
		'country code': 'NZ',
		'postal code': 3152,
		'place name': 'Omanu Beach',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.67,
		longitude: 176.229,
		accuracy: 4,
	},
	'3153': {
		'country code': 'NZ',
		'postal code': 3153,
		'place name': 'Te Puke',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7782,
		longitude: 176.3302,
		accuracy: 4,
	},
	'3154': {
		'country code': 'NZ',
		'postal code': 3154,
		'place name': 'Omokoroa Beach',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6333,
		longitude: 176.05,
		accuracy: 4,
	},
	'3158': {
		'country code': 'NZ',
		'postal code': 3158,
		'place name': 'Whakatane',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9585,
		longitude: 176.9855,
		accuracy: 4,
	},
	'3159': {
		'country code': 'NZ',
		'postal code': 3159,
		'place name': 'Poroporo',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9833,
		longitude: 176.9667,
		accuracy: 4,
	},
	'3160': {
		'country code': 'NZ',
		'postal code': 3160,
		'place name': 'Edgecumbe',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9833,
		longitude: 176.8333,
		accuracy: 4,
	},
	'3161': {
		'country code': 'NZ',
		'postal code': 3161,
		'place name': 'Ohope Beach',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9752,
		longitude: 177.0759,
		accuracy: 4,
	},
	'3162': {
		'country code': 'NZ',
		'postal code': 3162,
		'place name': 'Opotiki',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0091,
		longitude: 177.2871,
		accuracy: 4,
	},
	'3163': {
		'country code': 'NZ',
		'postal code': 3163,
		'place name': 'Taneatua',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0667,
		longitude: 177.0167,
		accuracy: 4,
	},
	'3164': {
		'country code': 'NZ',
		'postal code': 3164,
		'place name': 'Waimana',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.15,
		longitude: 177.0833,
		accuracy: 4,
	},
	'3167': {
		'country code': 'NZ',
		'postal code': 3167,
		'place name': 'Te Teko',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0365,
		longitude: 176.7936,
		accuracy: 4,
	},
	'3168': {
		'country code': 'NZ',
		'postal code': 3168,
		'place name': 'Matata',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8833,
		longitude: 176.75,
		accuracy: 4,
	},
	'3169': {
		'country code': 'NZ',
		'postal code': 3169,
		'place name': 'Kawerau',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1,
		longitude: 176.7,
		accuracy: 4,
	},
	'3171': {
		'country code': 'NZ',
		'postal code': 3171,
		'place name': 'Lower Kaimai',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8,
		longitude: 176.0333,
		accuracy: 4,
	},
	'3172': {
		'country code': 'NZ',
		'postal code': 3172,
		'place name': 'Omokoroa Beach',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6333,
		longitude: 176.05,
		accuracy: 4,
	},
	'3173': {
		'country code': 'NZ',
		'postal code': 3173,
		'place name': 'Oropi',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8395,
		longitude: 176.1611,
		accuracy: 4,
	},
	'3175': {
		'country code': 'NZ',
		'postal code': 3175,
		'place name': 'Upper Papamoa',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7667,
		longitude: 176.25,
		accuracy: 4,
	},
	'3176': {
		'country code': 'NZ',
		'postal code': 3176,
		'place name': 'Whakamarama',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7333,
		longitude: 176,
		accuracy: 4,
	},
	'3177': {
		'country code': 'NZ',
		'postal code': 3177,
		'place name': 'Athenree',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.4667,
		longitude: 175.9167,
		accuracy: 4,
	},
	'3178': {
		'country code': 'NZ',
		'postal code': 3178,
		'place name': 'Katikati',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.55,
		longitude: 175.9167,
		accuracy: 4,
	},
	'3182': {
		'country code': 'NZ',
		'postal code': 3182,
		'place name': 'Te Puke',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7782,
		longitude: 176.3302,
		accuracy: 4,
	},
	'3183': {
		'country code': 'NZ',
		'postal code': 3183,
		'place name': 'Upper Papamoa',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7667,
		longitude: 176.25,
		accuracy: 4,
	},
	'3186': {
		'country code': 'NZ',
		'postal code': 3186,
		'place name': 'Ohinepanea',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8219,
		longitude: 176.5643,
		accuracy: 4,
	},
	'3187': {
		'country code': 'NZ',
		'postal code': 3187,
		'place name': 'Papamoa',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7333,
		longitude: 176.3,
		accuracy: 4,
	},
	'3188': {
		'country code': 'NZ',
		'postal code': 3188,
		'place name': 'Ngawaro',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9333,
		longitude: 176.15,
		accuracy: 4,
	},
	'3189': {
		'country code': 'NZ',
		'postal code': 3189,
		'place name': 'Paengaroa',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8167,
		longitude: 176.4167,
		accuracy: 4,
	},
	'3191': {
		'country code': 'NZ',
		'postal code': 3191,
		'place name': 'Otangihaku',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0333,
		longitude: 177,
		accuracy: 4,
	},
	'3192': {
		'country code': 'NZ',
		'postal code': 3192,
		'place name': 'Kawerau',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1,
		longitude: 176.7,
		accuracy: 4,
	},
	'3193': {
		'country code': 'NZ',
		'postal code': 3193,
		'place name': 'Edgecumbe',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9833,
		longitude: 176.8333,
		accuracy: 4,
	},
	'3194': {
		'country code': 'NZ',
		'postal code': 3194,
		'place name': 'Awakaponga',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9333,
		longitude: 176.7667,
		accuracy: 4,
	},
	'3196': {
		'country code': 'NZ',
		'postal code': 3196,
		'place name': 'Waimana',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.15,
		longitude: 177.0833,
		accuracy: 4,
	},
	'3197': {
		'country code': 'NZ',
		'postal code': 3197,
		'place name': 'Toatoa',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1333,
		longitude: 177.5,
		accuracy: 4,
	},
	'3198': {
		'country code': 'NZ',
		'postal code': 3198,
		'place name': 'Waimana',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.15,
		longitude: 177.0833,
		accuracy: 4,
	},
	'3199': {
		'country code': 'NZ',
		'postal code': 3199,
		'place name': 'Omaio',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8167,
		longitude: 177.6667,
		accuracy: 4,
	},
	'3473': {
		'country code': 'NZ',
		'postal code': 3473,
		'place name': 'Kaimai',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8333,
		longitude: 175.9667,
		accuracy: 4,
	},
	'3611': {
		'country code': 'NZ',
		'postal code': 3611,
		'place name': 'Waihi Beach',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.4,
		longitude: 175.9333,
		accuracy: 4,
	},
	'3642': {
		'country code': 'NZ',
		'postal code': 3642,
		'place name': 'Waihi Beach',
		'admin name1': 'Bay of Plenty',
		'admin code1': 'E8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.4,
		longitude: 175.9333,
		accuracy: 4,
	},
	'7260': {
		'country code': 'NZ',
		'postal code': 7260,
		'place name': 'Kekerengu',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.0012,
		longitude: 174.0077,
		accuracy: 4,
	},
	'7310': {
		'country code': 'NZ',
		'postal code': 7310,
		'place name': 'Cheviot',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.8167,
		longitude: 173.2667,
		accuracy: 4,
	},
	'7332': {
		'country code': 'NZ',
		'postal code': 7332,
		'place name': 'Waiau',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.65,
		longitude: 173.05,
		accuracy: 4,
	},
	'7334': {
		'country code': 'NZ',
		'postal code': 7334,
		'place name': 'Hanmer Springs',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.5167,
		longitude: 172.8167,
		accuracy: 4,
	},
	'7340': {
		'country code': 'NZ',
		'postal code': 7340,
		'place name': 'Kaikoura Suburban',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.3833,
		longitude: 173.65,
		accuracy: 4,
	},
	'7341': {
		'country code': 'NZ',
		'postal code': 7341,
		'place name': 'Cheviot',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.8167,
		longitude: 173.2667,
		accuracy: 4,
	},
	'7343': {
		'country code': 'NZ',
		'postal code': 7343,
		'place name': 'Waiau',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.65,
		longitude: 173.05,
		accuracy: 4,
	},
	'7345': {
		'country code': 'NZ',
		'postal code': 7345,
		'place name': 'Culverden',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.7667,
		longitude: 172.85,
		accuracy: 4,
	},
	'7348': {
		'country code': 'NZ',
		'postal code': 7348,
		'place name': 'Hawarden',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.9333,
		longitude: 172.6333,
		accuracy: 4,
	},
	'7360': {
		'country code': 'NZ',
		'postal code': 7360,
		'place name': 'Hanmer Springs',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.5167,
		longitude: 172.8167,
		accuracy: 4,
	},
	'7373': {
		'country code': 'NZ',
		'postal code': 7373,
		'place name': 'Charwell Forks',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.4167,
		longitude: 173.3667,
		accuracy: 4,
	},
	'7374': {
		'country code': 'NZ',
		'postal code': 7374,
		'place name': 'Goose Bay',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.479,
		longitude: 173.5278,
		accuracy: 4,
	},
	'7379': {
		'country code': 'NZ',
		'postal code': 7379,
		'place name': 'Waiau',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.65,
		longitude: 173.05,
		accuracy: 4,
	},
	'7381': {
		'country code': 'NZ',
		'postal code': 7381,
		'place name': 'Spotswood',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.75,
		longitude: 173.2667,
		accuracy: 4,
	},
	'7382': {
		'country code': 'NZ',
		'postal code': 7382,
		'place name': 'Ethelton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.8833,
		longitude: 173.0833,
		accuracy: 4,
	},
	'7383': {
		'country code': 'NZ',
		'postal code': 7383,
		'place name': 'Domett',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.85,
		longitude: 173.2167,
		accuracy: 4,
	},
	'7384': {
		'country code': 'NZ',
		'postal code': 7384,
		'place name': 'Hawkeswood',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.65,
		longitude: 173.3333,
		accuracy: 4,
	},
	'7385': {
		'country code': 'NZ',
		'postal code': 7385,
		'place name': 'Masons Flat',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.9,
		longitude: 172.55,
		accuracy: 4,
	},
	'7387': {
		'country code': 'NZ',
		'postal code': 7387,
		'place name': 'Tormore',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.9,
		longitude: 173.1333,
		accuracy: 4,
	},
	'7391': {
		'country code': 'NZ',
		'postal code': 7391,
		'place name': 'Rotherham',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.7,
		longitude: 172.95,
		accuracy: 4,
	},
	'7392': {
		'country code': 'NZ',
		'postal code': 7392,
		'place name': 'Hanmer Springs',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.5167,
		longitude: 172.8167,
		accuracy: 4,
	},
	'7395': {
		'country code': 'NZ',
		'postal code': 7395,
		'place name': 'Hanmer Springs',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.5167,
		longitude: 172.8167,
		accuracy: 4,
	},
	'7400': {
		'country code': 'NZ',
		'postal code': 7400,
		'place name': 'Bells',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3,
		longitude: 172.5833,
		accuracy: 4,
	},
	'7402': {
		'country code': 'NZ',
		'postal code': 7402,
		'place name': 'Waikuku Beach',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.2833,
		longitude: 172.7167,
		accuracy: 4,
	},
	'7410': {
		'country code': 'NZ',
		'postal code': 7410,
		'place name': 'Amberley',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.1559,
		longitude: 172.7297,
		accuracy: 4,
	},
	'7420': {
		'country code': 'NZ',
		'postal code': 7420,
		'place name': 'Waikari',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.9667,
		longitude: 172.6833,
		accuracy: 4,
	},
	'7430': {
		'country code': 'NZ',
		'postal code': 7430,
		'place name': 'Oxford',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3,
		longitude: 172.1833,
		accuracy: 4,
	},
	'7440': {
		'country code': 'NZ',
		'postal code': 7440,
		'place name': 'Bells',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3,
		longitude: 172.5833,
		accuracy: 4,
	},
	'7441': {
		'country code': 'NZ',
		'postal code': 7441,
		'place name': 'Amberley',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.1559,
		longitude: 172.7297,
		accuracy: 4,
	},
	'7442': {
		'country code': 'NZ',
		'postal code': 7442,
		'place name': 'Waikari',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.9667,
		longitude: 172.6833,
		accuracy: 4,
	},
	'7443': {
		'country code': 'NZ',
		'postal code': 7443,
		'place name': 'Oxford',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3,
		longitude: 172.1833,
		accuracy: 4,
	},
	'7444': {
		'country code': 'NZ',
		'postal code': 7444,
		'place name': 'Cust',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3167,
		longitude: 172.3667,
		accuracy: 4,
	},
	'7445': {
		'country code': 'NZ',
		'postal code': 7445,
		'place name': 'Sefton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.25,
		longitude: 172.6667,
		accuracy: 4,
	},
	'7446': {
		'country code': 'NZ',
		'postal code': 7446,
		'place name': 'Leithfield',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.2,
		longitude: 172.7333,
		accuracy: 4,
	},
	'7447': {
		'country code': 'NZ',
		'postal code': 7447,
		'place name': 'Waipara',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.0667,
		longitude: 172.75,
		accuracy: 4,
	},
	'7448': {
		'country code': 'NZ',
		'postal code': 7448,
		'place name': 'Waikuku Beach',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.2833,
		longitude: 172.7167,
		accuracy: 4,
	},
	'7471': {
		'country code': 'NZ',
		'postal code': 7471,
		'place name': 'West Eyreton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.35,
		longitude: 172.3833,
		accuracy: 4,
	},
	'7472': {
		'country code': 'NZ',
		'postal code': 7472,
		'place name': 'North Loburn',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.2167,
		longitude: 172.5,
		accuracy: 4,
	},
	'7473': {
		'country code': 'NZ',
		'postal code': 7473,
		'place name': 'Waikuku',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3,
		longitude: 172.6833,
		accuracy: 4,
	},
	'7474': {
		'country code': 'NZ',
		'postal code': 7474,
		'place name': 'North Loburn',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.2167,
		longitude: 172.5,
		accuracy: 4,
	},
	'7481': {
		'country code': 'NZ',
		'postal code': 7481,
		'place name': 'Amberley',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.1559,
		longitude: 172.7297,
		accuracy: 4,
	},
	'7482': {
		'country code': 'NZ',
		'postal code': 7482,
		'place name': 'Waipara',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.0667,
		longitude: 172.75,
		accuracy: 4,
	},
	'7483': {
		'country code': 'NZ',
		'postal code': 7483,
		'place name': 'Omihi',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.0167,
		longitude: 172.85,
		accuracy: 4,
	},
	'7491': {
		'country code': 'NZ',
		'postal code': 7491,
		'place name': 'Waikari',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.9667,
		longitude: 172.6833,
		accuracy: 4,
	},
	'7495': {
		'country code': 'NZ',
		'postal code': 7495,
		'place name': 'Oxford',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3,
		longitude: 172.1833,
		accuracy: 4,
	},
	'7500': {
		'country code': 'NZ',
		'postal code': 7500,
		'place name': 'Sheffield',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3833,
		longitude: 172.0167,
		accuracy: 4,
	},
	'7510': {
		'country code': 'NZ',
		'postal code': 7510,
		'place name': 'Darfield',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.4833,
		longitude: 172.1167,
		accuracy: 4,
	},
	'7520': {
		'country code': 'NZ',
		'postal code': 7520,
		'place name': 'Akaroa',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.8038,
		longitude: 172.9682,
		accuracy: 4,
	},
	'7540': {
		'country code': 'NZ',
		'postal code': 7540,
		'place name': 'Sheffield',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3833,
		longitude: 172.0167,
		accuracy: 4,
	},
	'7541': {
		'country code': 'NZ',
		'postal code': 7541,
		'place name': 'Darfield',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.4833,
		longitude: 172.1167,
		accuracy: 4,
	},
	'7542': {
		'country code': 'NZ',
		'postal code': 7542,
		'place name': 'Akaroa',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.8038,
		longitude: 172.9682,
		accuracy: 4,
	},
	'7543': {
		'country code': 'NZ',
		'postal code': 7543,
		'place name': 'Kirwee',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5,
		longitude: 172.2167,
		accuracy: 4,
	},
	'7544': {
		'country code': 'NZ',
		'postal code': 7544,
		'place name': 'Hororata',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5333,
		longitude: 171.95,
		accuracy: 4,
	},
	'7545': {
		'country code': 'NZ',
		'postal code': 7545,
		'place name': 'Duvauchelle',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.75,
		longitude: 172.9333,
		accuracy: 4,
	},
	'7546': {
		'country code': 'NZ',
		'postal code': 7546,
		'place name': 'Little River',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.7667,
		longitude: 172.7833,
		accuracy: 4,
	},
	'7548': {
		'country code': 'NZ',
		'postal code': 7548,
		'place name': 'Doyleston',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.75,
		longitude: 172.3,
		accuracy: 4,
	},
	'7571': {
		'country code': 'NZ',
		'postal code': 7571,
		'place name': 'Hawkins',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.4833,
		longitude: 172.0333,
		accuracy: 4,
	},
	'7572': {
		'country code': 'NZ',
		'postal code': 7572,
		'place name': 'Lake Coleridge',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3667,
		longitude: 171.5333,
		accuracy: 4,
	},
	'7580': {
		'country code': 'NZ',
		'postal code': 7580,
		'place name': 'Craigieburn',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.1,
		longitude: 171.8667,
		accuracy: 4,
	},
	'7581': {
		'country code': 'NZ',
		'postal code': 7581,
		'place name': 'Akaroa',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.8038,
		longitude: 172.9682,
		accuracy: 4,
	},
	'7582': {
		'country code': 'NZ',
		'postal code': 7582,
		'place name': 'Wainui',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.8167,
		longitude: 172.9,
		accuracy: 4,
	},
	'7583': {
		'country code': 'NZ',
		'postal code': 7583,
		'place name': 'Robinsons Bay',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.7667,
		longitude: 172.9667,
		accuracy: 4,
	},
	'7591': {
		'country code': 'NZ',
		'postal code': 7591,
		'place name': 'Ataahua',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.7833,
		longitude: 172.65,
		accuracy: 4,
	},
	'7600': {
		'country code': 'NZ',
		'postal code': 7600,
		'place name': 'Burnham',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.6167,
		longitude: 172.3167,
		accuracy: 4,
	},
	'7602': {
		'country code': 'NZ',
		'postal code': 7602,
		'place name': 'Southbridge',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.8167,
		longitude: 172.25,
		accuracy: 4,
	},
	'7604': {
		'country code': 'NZ',
		'postal code': 7604,
		'place name': 'Prebbleton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5833,
		longitude: 172.5167,
		accuracy: 4,
	},
	'7608': {
		'country code': 'NZ',
		'postal code': 7608,
		'place name': 'Lincoln',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.65,
		longitude: 172.4833,
		accuracy: 4,
	},
	'7610': {
		'country code': 'NZ',
		'postal code': 7610,
		'place name': 'Woodend',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3167,
		longitude: 172.6667,
		accuracy: 4,
	},
	'7614': {
		'country code': 'NZ',
		'postal code': 7614,
		'place name': 'Rolleston',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5833,
		longitude: 172.3833,
		accuracy: 4,
	},
	'7616': {
		'country code': 'NZ',
		'postal code': 7616,
		'place name': 'Springston',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.65,
		longitude: 172.4167,
		accuracy: 4,
	},
	'7630': {
		'country code': 'NZ',
		'postal code': 7630,
		'place name': 'Kaiapoi',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3783,
		longitude: 172.6401,
		accuracy: 4,
	},
	'7632': {
		'country code': 'NZ',
		'postal code': 7632,
		'place name': 'Leeston',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.7667,
		longitude: 172.3,
		accuracy: 4,
	},
	'7640': {
		'country code': 'NZ',
		'postal code': 7640,
		'place name': 'Lincoln',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.65,
		longitude: 172.4833,
		accuracy: 4,
	},
	'7641': {
		'country code': 'NZ',
		'postal code': 7641,
		'place name': 'Woodend',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3167,
		longitude: 172.6667,
		accuracy: 4,
	},
	'7642': {
		'country code': 'NZ',
		'postal code': 7642,
		'place name': 'Southbridge',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.8167,
		longitude: 172.25,
		accuracy: 4,
	},
	'7643': {
		'country code': 'NZ',
		'postal code': 7643,
		'place name': 'Rolleston',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5833,
		longitude: 172.3833,
		accuracy: 4,
	},
	'7644': {
		'country code': 'NZ',
		'postal code': 7644,
		'place name': 'Kaiapoi',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3783,
		longitude: 172.6401,
		accuracy: 4,
	},
	'7645': {
		'country code': 'NZ',
		'postal code': 7645,
		'place name': 'Tai Tapu',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.6667,
		longitude: 172.55,
		accuracy: 4,
	},
	'7646': {
		'country code': 'NZ',
		'postal code': 7646,
		'place name': 'Coalgate',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.4833,
		longitude: 171.9667,
		accuracy: 4,
	},
	'7647': {
		'country code': 'NZ',
		'postal code': 7647,
		'place name': 'Lincoln',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.65,
		longitude: 172.4833,
		accuracy: 4,
	},
	'7649': {
		'country code': 'NZ',
		'postal code': 7649,
		'place name': 'Springfield',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3333,
		longitude: 171.9167,
		accuracy: 4,
	},
	'7656': {
		'country code': 'NZ',
		'postal code': 7656,
		'place name': 'Leeston',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.7667,
		longitude: 172.3,
		accuracy: 4,
	},
	'7657': {
		'country code': 'NZ',
		'postal code': 7657,
		'place name': 'Dunsandel',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.6667,
		longitude: 172.1833,
		accuracy: 4,
	},
	'7670': {
		'country code': 'NZ',
		'postal code': 7670,
		'place name': 'Coutts Island',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.4333,
		longitude: 172.6167,
		accuracy: 4,
	},
	'7671': {
		'country code': 'NZ',
		'postal code': 7671,
		'place name': 'Aylesbury',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5333,
		longitude: 172.2667,
		accuracy: 4,
	},
	'7672': {
		'country code': 'NZ',
		'postal code': 7672,
		'place name': 'Motukarara',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.7333,
		longitude: 172.5833,
		accuracy: 4,
	},
	'7673': {
		'country code': 'NZ',
		'postal code': 7673,
		'place name': 'Whitecliffs',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.4667,
		longitude: 171.9,
		accuracy: 4,
	},
	'7674': {
		'country code': 'NZ',
		'postal code': 7674,
		'place name': 'Springston South',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.6833,
		longitude: 172.4333,
		accuracy: 4,
	},
	'7675': {
		'country code': 'NZ',
		'postal code': 7675,
		'place name': 'Rolleston',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5833,
		longitude: 172.3833,
		accuracy: 4,
	},
	'7676': {
		'country code': 'NZ',
		'postal code': 7676,
		'place name': 'Templeton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.55,
		longitude: 172.4667,
		accuracy: 4,
	},
	'7681': {
		'country code': 'NZ',
		'postal code': 7681,
		'place name': 'Springfield',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3333,
		longitude: 171.9167,
		accuracy: 4,
	},
	'7682': {
		'country code': 'NZ',
		'postal code': 7682,
		'place name': 'Dunsandel',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.6667,
		longitude: 172.1833,
		accuracy: 4,
	},
	'7683': {
		'country code': 'NZ',
		'postal code': 7683,
		'place name': 'Southbridge',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.8167,
		longitude: 172.25,
		accuracy: 4,
	},
	'7691': {
		'country code': 'NZ',
		'postal code': 7691,
		'place name': 'Flaxton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.35,
		longitude: 172.6333,
		accuracy: 4,
	},
	'7692': {
		'country code': 'NZ',
		'postal code': 7692,
		'place name': 'Wetheral',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3667,
		longitude: 172.5833,
		accuracy: 4,
	},
	'7700': {
		'country code': 'NZ',
		'postal code': 7700,
		'place name': 'Allenton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.8883,
		longitude: 171.7401,
		accuracy: 4,
	},
	'7710': {
		'country code': 'NZ',
		'postal code': 7710,
		'place name': 'Rakaia',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.75,
		longitude: 172.0167,
		accuracy: 4,
	},
	'7730': {
		'country code': 'NZ',
		'postal code': 7730,
		'place name': 'Methven',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.6333,
		longitude: 171.65,
		accuracy: 4,
	},
	'7740': {
		'country code': 'NZ',
		'postal code': 7740,
		'place name': 'Hampstead',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.9083,
		longitude: 171.7501,
		accuracy: 4,
	},
	'7741': {
		'country code': 'NZ',
		'postal code': 7741,
		'place name': 'Tinwald',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.9167,
		longitude: 171.7167,
		accuracy: 4,
	},
	'7742': {
		'country code': 'NZ',
		'postal code': 7742,
		'place name': 'Allenton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.8883,
		longitude: 171.7401,
		accuracy: 4,
	},
	'7743': {
		'country code': 'NZ',
		'postal code': 7743,
		'place name': 'Rakaia',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.75,
		longitude: 172.0167,
		accuracy: 4,
	},
	'7745': {
		'country code': 'NZ',
		'postal code': 7745,
		'place name': 'Methven',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.6333,
		longitude: 171.65,
		accuracy: 4,
	},
	'7746': {
		'country code': 'NZ',
		'postal code': 7746,
		'place name': 'Mount Somers',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.7167,
		longitude: 171.4,
		accuracy: 4,
	},
	'7747': {
		'country code': 'NZ',
		'postal code': 7747,
		'place name': 'Hinds',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44,
		longitude: 171.5667,
		accuracy: 4,
	},
	'7748': {
		'country code': 'NZ',
		'postal code': 7748,
		'place name': 'Mayfield',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.8167,
		longitude: 171.4167,
		accuracy: 4,
	},
	'7771': {
		'country code': 'NZ',
		'postal code': 7771,
		'place name': 'Staveley',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.65,
		longitude: 171.4333,
		accuracy: 4,
	},
	'7772': {
		'country code': 'NZ',
		'postal code': 7772,
		'place name': 'Mitcham',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.75,
		longitude: 171.85,
		accuracy: 4,
	},
	'7773': {
		'country code': 'NZ',
		'postal code': 7773,
		'place name': 'Hinds',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44,
		longitude: 171.5667,
		accuracy: 4,
	},
	'7774': {
		'country code': 'NZ',
		'postal code': 7774,
		'place name': 'Flemington',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.0167,
		longitude: 171.7333,
		accuracy: 4,
	},
	'7775': {
		'country code': 'NZ',
		'postal code': 7775,
		'place name': 'Lismore',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.9,
		longitude: 171.4833,
		accuracy: 4,
	},
	'7776': {
		'country code': 'NZ',
		'postal code': 7776,
		'place name': 'Lyndhurst',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.7,
		longitude: 171.7167,
		accuracy: 4,
	},
	'7777': {
		'country code': 'NZ',
		'postal code': 7777,
		'place name': 'Seafield',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.9167,
		longitude: 171.9,
		accuracy: 4,
	},
	'7778': {
		'country code': 'NZ',
		'postal code': 7778,
		'place name': 'Mayfield',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.8167,
		longitude: 171.4167,
		accuracy: 4,
	},
	'7781': {
		'country code': 'NZ',
		'postal code': 7781,
		'place name': 'Dorie',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.8833,
		longitude: 172.15,
		accuracy: 4,
	},
	'7782': {
		'country code': 'NZ',
		'postal code': 7782,
		'place name': 'Highbank',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.6167,
		longitude: 171.75,
		accuracy: 4,
	},
	'7783': {
		'country code': 'NZ',
		'postal code': 7783,
		'place name': 'Te Pirita',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.6667,
		longitude: 171.95,
		accuracy: 4,
	},
	'7784': {
		'country code': 'NZ',
		'postal code': 7784,
		'place name': 'Somerton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.75,
		longitude: 171.9167,
		accuracy: 4,
	},
	'7791': {
		'country code': 'NZ',
		'postal code': 7791,
		'place name': 'Lake Coleridge',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3667,
		longitude: 171.5333,
		accuracy: 4,
	},
	'7901': {
		'country code': 'NZ',
		'postal code': 7901,
		'place name': 'Twizel',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.2584,
		longitude: 170.1006,
		accuracy: 4,
	},
	'7903': {
		'country code': 'NZ',
		'postal code': 7903,
		'place name': 'Pleasant Point',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.2667,
		longitude: 171.1333,
		accuracy: 4,
	},
	'7910': {
		'country code': 'NZ',
		'postal code': 7910,
		'place name': 'Seaview',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.3966,
		longitude: 171.2415,
		accuracy: 4,
	},
	'7912': {
		'country code': 'NZ',
		'postal code': 7912,
		'place name': 'Pareora',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.5,
		longitude: 171.2,
		accuracy: 4,
	},
	'7920': {
		'country code': 'NZ',
		'postal code': 7920,
		'place name': 'Temuka',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.2283,
		longitude: 171.2801,
		accuracy: 4,
	},
	'7924': {
		'country code': 'NZ',
		'postal code': 7924,
		'place name': 'Waimate',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.7284,
		longitude: 171.0501,
		accuracy: 4,
	},
	'7925': {
		'country code': 'NZ',
		'postal code': 7925,
		'place name': 'Fairlie',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.1,
		longitude: 170.8333,
		accuracy: 4,
	},
	'7930': {
		'country code': 'NZ',
		'postal code': 7930,
		'place name': 'Geraldine',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.0906,
		longitude: 171.2446,
		accuracy: 4,
	},
	'7940': {
		'country code': 'NZ',
		'postal code': 7940,
		'place name': 'Timaru',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.3967,
		longitude: 171.2536,
		accuracy: 4,
	},
	'7941': {
		'country code': 'NZ',
		'postal code': 7941,
		'place name': 'Puhuka',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.3583,
		longitude: 171.2301,
		accuracy: 4,
	},
	'7942': {
		'country code': 'NZ',
		'postal code': 7942,
		'place name': 'Parkside',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.4029,
		longitude: 171.2546,
		accuracy: 4,
	},
	'7943': {
		'country code': 'NZ',
		'postal code': 7943,
		'place name': 'Kensington',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.412,
		longitude: 171.247,
		accuracy: 4,
	},
	'7944': {
		'country code': 'NZ',
		'postal code': 7944,
		'place name': 'Twizel',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.2584,
		longitude: 170.1006,
		accuracy: 4,
	},
	'7945': {
		'country code': 'NZ',
		'postal code': 7945,
		'place name': 'Lake Tekapo',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.0064,
		longitude: 170.4803,
		accuracy: 4,
	},
	'7946': {
		'country code': 'NZ',
		'postal code': 7946,
		'place name': 'Glentanner',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.9073,
		longitude: 170.1234,
		accuracy: 4,
	},
	'7947': {
		'country code': 'NZ',
		'postal code': 7947,
		'place name': 'Pleasant Point',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.2667,
		longitude: 171.1333,
		accuracy: 4,
	},
	'7948': {
		'country code': 'NZ',
		'postal code': 7948,
		'place name': 'Temuka',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.2283,
		longitude: 171.2801,
		accuracy: 4,
	},
	'7949': {
		'country code': 'NZ',
		'postal code': 7949,
		'place name': 'Fairlie',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.1,
		longitude: 170.8333,
		accuracy: 4,
	},
	'7956': {
		'country code': 'NZ',
		'postal code': 7956,
		'place name': 'Geraldine',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.0906,
		longitude: 171.2446,
		accuracy: 4,
	},
	'7957': {
		'country code': 'NZ',
		'postal code': 7957,
		'place name': 'Cave',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.3167,
		longitude: 170.95,
		accuracy: 4,
	},
	'7958': {
		'country code': 'NZ',
		'postal code': 7958,
		'place name': 'Winchester',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.2,
		longitude: 171.2833,
		accuracy: 4,
	},
	'7960': {
		'country code': 'NZ',
		'postal code': 7960,
		'place name': 'Waimate',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.7284,
		longitude: 171.0501,
		accuracy: 4,
	},
	'7971': {
		'country code': 'NZ',
		'postal code': 7971,
		'place name': 'Esk Valley',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.5333,
		longitude: 171.0833,
		accuracy: 4,
	},
	'7972': {
		'country code': 'NZ',
		'postal code': 7972,
		'place name': 'Southburn',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.45,
		longitude: 171.1,
		accuracy: 4,
	},
	'7973': {
		'country code': 'NZ',
		'postal code': 7973,
		'place name': 'Seadown',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.3,
		longitude: 171.2667,
		accuracy: 4,
	},
	'7974': {
		'country code': 'NZ',
		'postal code': 7974,
		'place name': 'Claremont',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.4,
		longitude: 171.0833,
		accuracy: 4,
	},
	'7977': {
		'country code': 'NZ',
		'postal code': 7977,
		'place name': 'Waihaorunga',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.75,
		longitude: 170.8167,
		accuracy: 4,
	},
	'7978': {
		'country code': 'NZ',
		'postal code': 7978,
		'place name': 'Waimate',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.7284,
		longitude: 171.0501,
		accuracy: 4,
	},
	'7979': {
		'country code': 'NZ',
		'postal code': 7979,
		'place name': 'Waihao Forks',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.8,
		longitude: 170.9333,
		accuracy: 4,
	},
	'7980': {
		'country code': 'NZ',
		'postal code': 7980,
		'place name': 'Morven',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.8333,
		longitude: 171.1167,
		accuracy: 4,
	},
	'7982': {
		'country code': 'NZ',
		'postal code': 7982,
		'place name': 'Hazelburn',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.2,
		longitude: 170.9833,
		accuracy: 4,
	},
	'7983': {
		'country code': 'NZ',
		'postal code': 7983,
		'place name': 'Sutherlands',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.2833,
		longitude: 171.05,
		accuracy: 4,
	},
	'7984': {
		'country code': 'NZ',
		'postal code': 7984,
		'place name': 'Mawaro',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.3,
		longitude: 170.8833,
		accuracy: 4,
	},
	'7985': {
		'country code': 'NZ',
		'postal code': 7985,
		'place name': 'Waitohi Flat',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.2167,
		longitude: 171.1833,
		accuracy: 4,
	},
	'7986': {
		'country code': 'NZ',
		'postal code': 7986,
		'place name': 'Orton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.1667,
		longitude: 171.4333,
		accuracy: 4,
	},
	'7987': {
		'country code': 'NZ',
		'postal code': 7987,
		'place name': 'Waikarua',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.0333,
		longitude: 170.8833,
		accuracy: 4,
	},
	'7988': {
		'country code': 'NZ',
		'postal code': 7988,
		'place name': 'Esk Valley',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.5333,
		longitude: 171.0833,
		accuracy: 4,
	},
	'7990': {
		'country code': 'NZ',
		'postal code': 7990,
		'place name': 'Sherwood Downs',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.9667,
		longitude: 170.8667,
		accuracy: 4,
	},
	'7991': {
		'country code': 'NZ',
		'postal code': 7991,
		'place name': 'Te Moana',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.0667,
		longitude: 171.1333,
		accuracy: 4,
	},
	'7992': {
		'country code': 'NZ',
		'postal code': 7992,
		'place name': 'Arundel',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.9833,
		longitude: 171.2833,
		accuracy: 4,
	},
	'7999': {
		'country code': 'NZ',
		'postal code': 7999,
		'place name': 'Glentanner',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.9073,
		longitude: 170.1234,
		accuracy: 4,
	},
	'8011': {
		'country code': 'NZ',
		'postal code': 8011,
		'place name': 'Christchurch',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5333,
		longitude: 172.6333,
		accuracy: 4,
	},
	'8013': {
		'country code': 'NZ',
		'postal code': 8013,
		'place name': 'Christchurch',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5333,
		longitude: 172.6333,
		accuracy: 4,
	},
	'8014': {
		'country code': 'NZ',
		'postal code': 8014,
		'place name': 'Saint Albans',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5167,
		longitude: 172.6167,
		accuracy: 4,
	},
	'8022': {
		'country code': 'NZ',
		'postal code': 8022,
		'place name': 'Cashmere Hills',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5833,
		longitude: 172.6333,
		accuracy: 4,
	},
	'8023': {
		'country code': 'NZ',
		'postal code': 8023,
		'place name': 'Hillsborough',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5626,
		longitude: 172.6737,
		accuracy: 4,
	},
	'8024': {
		'country code': 'NZ',
		'postal code': 8024,
		'place name': 'Spreydon',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5567,
		longitude: 172.6169,
		accuracy: 4,
	},
	'8025': {
		'country code': 'NZ',
		'postal code': 8025,
		'place name': 'Halswell',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5833,
		longitude: 172.5667,
		accuracy: 4,
	},
	'8041': {
		'country code': 'NZ',
		'postal code': 8041,
		'place name': 'Fendalton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5167,
		longitude: 172.6,
		accuracy: 4,
	},
	'8042': {
		'country code': 'NZ',
		'postal code': 8042,
		'place name': 'Hei Hei',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5283,
		longitude: 172.5201,
		accuracy: 4,
	},
	'8051': {
		'country code': 'NZ',
		'postal code': 8051,
		'place name': 'Harewood',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.4833,
		longitude: 172.5833,
		accuracy: 4,
	},
	'8052': {
		'country code': 'NZ',
		'postal code': 8052,
		'place name': 'Papanui',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5,
		longitude: 172.6167,
		accuracy: 4,
	},
	'8053': {
		'country code': 'NZ',
		'postal code': 8053,
		'place name': 'Burnside',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.4983,
		longitude: 172.5601,
		accuracy: 4,
	},
	'8061': {
		'country code': 'NZ',
		'postal code': 8061,
		'place name': 'Aranui',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5167,
		longitude: 172.7,
		accuracy: 4,
	},
	'8062': {
		'country code': 'NZ',
		'postal code': 8062,
		'place name': 'Bromley',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5283,
		longitude: 172.6801,
		accuracy: 4,
	},
	'8081': {
		'country code': 'NZ',
		'postal code': 8081,
		'place name': 'Sumner',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5683,
		longitude: 172.7601,
		accuracy: 4,
	},
	'8082': {
		'country code': 'NZ',
		'postal code': 8082,
		'place name': 'Lyttelton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.6031,
		longitude: 172.7176,
		accuracy: 4,
	},
	'8083': {
		'country code': 'NZ',
		'postal code': 8083,
		'place name': 'Ouruhia',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.45,
		longitude: 172.65,
		accuracy: 4,
	},
	'8140': {
		'country code': 'NZ',
		'postal code': 8140,
		'place name': 'Christchurch',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5333,
		longitude: 172.6333,
		accuracy: 4,
	},
	'8141': {
		'country code': 'NZ',
		'postal code': 8141,
		'place name': 'Christchurch',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5333,
		longitude: 172.6333,
		accuracy: 4,
	},
	'8142': {
		'country code': 'NZ',
		'postal code': 8142,
		'place name': 'Christchurch',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5333,
		longitude: 172.6333,
		accuracy: 4,
	},
	'8143': {
		'country code': 'NZ',
		'postal code': 8143,
		'place name': 'Saint Albans',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5167,
		longitude: 172.6167,
		accuracy: 4,
	},
	'8144': {
		'country code': 'NZ',
		'postal code': 8144,
		'place name': 'Christchurch',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5333,
		longitude: 172.6333,
		accuracy: 4,
	},
	'8145': {
		'country code': 'NZ',
		'postal code': 8145,
		'place name': 'Opawa',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.555,
		longitude: 172.6614,
		accuracy: 4,
	},
	'8146': {
		'country code': 'NZ',
		'postal code': 8146,
		'place name': 'Saint Albans',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5167,
		longitude: 172.6167,
		accuracy: 4,
	},
	'8147': {
		'country code': 'NZ',
		'postal code': 8147,
		'place name': 'Linwood',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5333,
		longitude: 172.65,
		accuracy: 4,
	},
	'8148': {
		'country code': 'NZ',
		'postal code': 8148,
		'place name': 'Avonside',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5183,
		longitude: 172.6601,
		accuracy: 4,
	},
	'8149': {
		'country code': 'NZ',
		'postal code': 8149,
		'place name': 'Spreydon',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5567,
		longitude: 172.6169,
		accuracy: 4,
	},
	'8240': {
		'country code': 'NZ',
		'postal code': 8240,
		'place name': 'Sydenham',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.547,
		longitude: 172.6359,
		accuracy: 4,
	},
	'8241': {
		'country code': 'NZ',
		'postal code': 8241,
		'place name': 'Woolston',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5473,
		longitude: 172.6774,
		accuracy: 4,
	},
	'8242': {
		'country code': 'NZ',
		'postal code': 8242,
		'place name': 'Beckenham',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.565,
		longitude: 172.6411,
		accuracy: 4,
	},
	'8243': {
		'country code': 'NZ',
		'postal code': 8243,
		'place name': 'Spreydon',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5567,
		longitude: 172.6169,
		accuracy: 4,
	},
	'8244': {
		'country code': 'NZ',
		'postal code': 8244,
		'place name': 'Somerfield',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5593,
		longitude: 172.6313,
		accuracy: 4,
	},
	'8245': {
		'country code': 'NZ',
		'postal code': 8245,
		'place name': 'Halswell',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5833,
		longitude: 172.5667,
		accuracy: 4,
	},
	'8246': {
		'country code': 'NZ',
		'postal code': 8246,
		'place name': 'Hillsborough',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5626,
		longitude: 172.6737,
		accuracy: 4,
	},
	'8247': {
		'country code': 'NZ',
		'postal code': 8247,
		'place name': 'Mount Pleasant',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5628,
		longitude: 172.7152,
		accuracy: 4,
	},
	'8440': {
		'country code': 'NZ',
		'postal code': 8440,
		'place name': 'Riccarton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5283,
		longitude: 172.5901,
		accuracy: 4,
	},
	'8441': {
		'country code': 'NZ',
		'postal code': 8441,
		'place name': 'Hornby',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.55,
		longitude: 172.5333,
		accuracy: 4,
	},
	'8442': {
		'country code': 'NZ',
		'postal code': 8442,
		'place name': 'Upper Riccarton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5283,
		longitude: 172.5701,
		accuracy: 4,
	},
	'8443': {
		'country code': 'NZ',
		'postal code': 8443,
		'place name': 'Wigram',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.55,
		longitude: 172.55,
		accuracy: 4,
	},
	'8444': {
		'country code': 'NZ',
		'postal code': 8444,
		'place name': 'Ilam',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5183,
		longitude: 172.5701,
		accuracy: 4,
	},
	'8445': {
		'country code': 'NZ',
		'postal code': 8445,
		'place name': 'Templeton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.55,
		longitude: 172.4667,
		accuracy: 4,
	},
	'8540': {
		'country code': 'NZ',
		'postal code': 8540,
		'place name': 'Fendalton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5167,
		longitude: 172.6,
		accuracy: 4,
	},
	'8542': {
		'country code': 'NZ',
		'postal code': 8542,
		'place name': 'Papanui',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5,
		longitude: 172.6167,
		accuracy: 4,
	},
	'8543': {
		'country code': 'NZ',
		'postal code': 8543,
		'place name': 'Harewood',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.4833,
		longitude: 172.5833,
		accuracy: 4,
	},
	'8544': {
		'country code': 'NZ',
		'postal code': 8544,
		'place name': 'Harewood',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.4833,
		longitude: 172.5833,
		accuracy: 4,
	},
	'8545': {
		'country code': 'NZ',
		'postal code': 8545,
		'place name': 'Burnside',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.4983,
		longitude: 172.5601,
		accuracy: 4,
	},
	'8546': {
		'country code': 'NZ',
		'postal code': 8546,
		'place name': 'Belfast',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.45,
		longitude: 172.6333,
		accuracy: 4,
	},
	'8640': {
		'country code': 'NZ',
		'postal code': 8640,
		'place name': 'Dallington',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5083,
		longitude: 172.6701,
		accuracy: 4,
	},
	'8641': {
		'country code': 'NZ',
		'postal code': 8641,
		'place name': 'New Brighton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5167,
		longitude: 172.7333,
		accuracy: 4,
	},
	'8642': {
		'country code': 'NZ',
		'postal code': 8642,
		'place name': 'Bromley',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5283,
		longitude: 172.6801,
		accuracy: 4,
	},
	'8643': {
		'country code': 'NZ',
		'postal code': 8643,
		'place name': 'Aranui',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5167,
		longitude: 172.7,
		accuracy: 4,
	},
	'8840': {
		'country code': 'NZ',
		'postal code': 8840,
		'place name': 'Sumner',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.5683,
		longitude: 172.7601,
		accuracy: 4,
	},
	'8841': {
		'country code': 'NZ',
		'postal code': 8841,
		'place name': 'Lyttelton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.6031,
		longitude: 172.7176,
		accuracy: 4,
	},
	'8842': {
		'country code': 'NZ',
		'postal code': 8842,
		'place name': 'North New Brighton',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.4918,
		longitude: 172.7202,
		accuracy: 4,
	},
	'8941': {
		'country code': 'NZ',
		'postal code': 8941,
		'place name': 'Diamond Harbour',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.6289,
		longitude: 172.7254,
		accuracy: 4,
	},
	'8942': {
		'country code': 'NZ',
		'postal code': 8942,
		'place name': 'Diamond Harbour',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.6289,
		longitude: 172.7254,
		accuracy: 4,
	},
	'8971': {
		'country code': 'NZ',
		'postal code': 8971,
		'place name': 'Allandale',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.65,
		longitude: 172.65,
		accuracy: 4,
	},
	'8972': {
		'country code': 'NZ',
		'postal code': 8972,
		'place name': 'Pigeon Bay',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.688,
		longitude: 172.8929,
		accuracy: 4,
	},
	'9445': {
		'country code': 'NZ',
		'postal code': 9445,
		'place name': 'Duntroon',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.8554,
		longitude: 170.6848,
		accuracy: 4,
	},
	'9446': {
		'country code': 'NZ',
		'postal code': 9446,
		'place name': 'Kurow',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.7334,
		longitude: 170.4696,
		accuracy: 4,
	},
	'9448': {
		'country code': 'NZ',
		'postal code': 9448,
		'place name': 'Omarama',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.4885,
		longitude: 169.9663,
		accuracy: 4,
	},
	'9498': {
		'country code': 'NZ',
		'postal code': 9498,
		'place name': 'Kaingapai',
		'admin name1': 'Canterbury',
		'admin code1': 'E9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.6,
		longitude: 170.5667,
		accuracy: 4,
	},
	'4010': {
		'country code': 'NZ',
		'postal code': 4010,
		'place name': 'Kaiti',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.6682,
		longitude: 178.0302,
		accuracy: 4,
	},
	'4022': {
		'country code': 'NZ',
		'postal code': 4022,
		'place name': 'Te Karaka',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.4667,
		longitude: 177.8667,
		accuracy: 4,
	},
	'4032': {
		'country code': 'NZ',
		'postal code': 4032,
		'place name': 'Ruatoria',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8833,
		longitude: 178.3333,
		accuracy: 4,
	},
	'4040': {
		'country code': 'NZ',
		'postal code': 4040,
		'place name': 'Kaiti',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.6682,
		longitude: 178.0302,
		accuracy: 4,
	},
	'4041': {
		'country code': 'NZ',
		'postal code': 4041,
		'place name': 'Tamarau',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.6782,
		longitude: 178.0502,
		accuracy: 4,
	},
	'4042': {
		'country code': 'NZ',
		'postal code': 4042,
		'place name': 'Te Karaka',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.4667,
		longitude: 177.8667,
		accuracy: 4,
	},
	'4043': {
		'country code': 'NZ',
		'postal code': 4043,
		'place name': 'Ruatoria',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8833,
		longitude: 178.3333,
		accuracy: 4,
	},
	'4044': {
		'country code': 'NZ',
		'postal code': 4044,
		'place name': 'Tiniroto',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.7667,
		longitude: 177.5667,
		accuracy: 4,
	},
	'4045': {
		'country code': 'NZ',
		'postal code': 4045,
		'place name': 'Patutahi',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.6167,
		longitude: 177.9,
		accuracy: 4,
	},
	'4046': {
		'country code': 'NZ',
		'postal code': 4046,
		'place name': 'Tolaga Bay',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.3667,
		longitude: 178.3,
		accuracy: 4,
	},
	'4047': {
		'country code': 'NZ',
		'postal code': 4047,
		'place name': 'Tokomaru',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1333,
		longitude: 178.3,
		accuracy: 4,
	},
	'4048': {
		'country code': 'NZ',
		'postal code': 4048,
		'place name': 'Te Puia',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0667,
		longitude: 178.3,
		accuracy: 4,
	},
	'4049': {
		'country code': 'NZ',
		'postal code': 4049,
		'place name': 'Rangitukia',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7667,
		longitude: 178.45,
		accuracy: 4,
	},
	'4050': {
		'country code': 'NZ',
		'postal code': 4050,
		'place name': 'Te Araroa',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6333,
		longitude: 178.3667,
		accuracy: 4,
	},
	'4051': {
		'country code': 'NZ',
		'postal code': 4051,
		'place name': 'Matawai',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.35,
		longitude: 177.5333,
		accuracy: 4,
	},
	'4052': {
		'country code': 'NZ',
		'postal code': 4052,
		'place name': 'Motu',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.25,
		longitude: 177.55,
		accuracy: 4,
	},
	'4071': {
		'country code': 'NZ',
		'postal code': 4071,
		'place name': 'Waerengaahika',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.6,
		longitude: 177.9333,
		accuracy: 4,
	},
	'4072': {
		'country code': 'NZ',
		'postal code': 4072,
		'place name': 'Pehiri',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.6333,
		longitude: 177.7,
		accuracy: 4,
	},
	'4073': {
		'country code': 'NZ',
		'postal code': 4073,
		'place name': 'Whangara',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.5667,
		longitude: 178.2167,
		accuracy: 4,
	},
	'4075': {
		'country code': 'NZ',
		'postal code': 4075,
		'place name': 'Rakauroa',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.4167,
		longitude: 177.55,
		accuracy: 4,
	},
	'4077': {
		'country code': 'NZ',
		'postal code': 4077,
		'place name': 'Tolaga Bay',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.3667,
		longitude: 178.3,
		accuracy: 4,
	},
	'4078': {
		'country code': 'NZ',
		'postal code': 4078,
		'place name': 'Tiniroto',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.7667,
		longitude: 177.5667,
		accuracy: 4,
	},
	'4079': {
		'country code': 'NZ',
		'postal code': 4079,
		'place name': 'Tokomaru',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1333,
		longitude: 178.3,
		accuracy: 4,
	},
	'4081': {
		'country code': 'NZ',
		'postal code': 4081,
		'place name': 'Waitakaro',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9333,
		longitude: 178.2667,
		accuracy: 4,
	},
	'4082': {
		'country code': 'NZ',
		'postal code': 4082,
		'place name': 'Ruatoria',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8833,
		longitude: 178.3333,
		accuracy: 4,
	},
	'4083': {
		'country code': 'NZ',
		'postal code': 4083,
		'place name': 'Pakihiroa',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.85,
		longitude: 178.0667,
		accuracy: 4,
	},
	'4086': {
		'country code': 'NZ',
		'postal code': 4086,
		'place name': 'Whakaangiangi',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7167,
		longitude: 178.3,
		accuracy: 4,
	},
	'4087': {
		'country code': 'NZ',
		'postal code': 4087,
		'place name': 'Te Araroa',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6333,
		longitude: 178.3667,
		accuracy: 4,
	},
	'4091': {
		'country code': 'NZ',
		'postal code': 4091,
		'place name': 'Ahititi',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.4333,
		longitude: 178.05,
		accuracy: 4,
	},
	'4092': {
		'country code': 'NZ',
		'postal code': 4092,
		'place name': 'Otoko',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.4,
		longitude: 177.6667,
		accuracy: 4,
	},
	'4093': {
		'country code': 'NZ',
		'postal code': 4093,
		'place name': 'Te Karaka',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.4667,
		longitude: 177.8667,
		accuracy: 4,
	},
	'4094': {
		'country code': 'NZ',
		'postal code': 4094,
		'place name': 'Whatatutu',
		'admin name1': 'Gisborne',
		'admin code1': 'F1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.3833,
		longitude: 177.8333,
		accuracy: 4,
	},
	'3360': {
		'country code': 'NZ',
		'postal code': 3360,
		'place name': 'Tarawera',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0333,
		longitude: 176.5833,
		accuracy: 4,
	},
	'4102': {
		'country code': 'NZ',
		'postal code': 4102,
		'place name': 'Haumoana',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6037,
		longitude: 176.9457,
		accuracy: 4,
	},
	'4104': {
		'country code': 'NZ',
		'postal code': 4104,
		'place name': 'Petane',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.3833,
		longitude: 176.8667,
		accuracy: 4,
	},
	'4108': {
		'country code': 'NZ',
		'postal code': 4108,
		'place name': 'North Clyde',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0282,
		longitude: 177.4202,
		accuracy: 4,
	},
	'4110': {
		'country code': 'NZ',
		'postal code': 4110,
		'place name': 'Onekawa',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4982,
		longitude: 176.8802,
		accuracy: 4,
	},
	'4112': {
		'country code': 'NZ',
		'postal code': 4112,
		'place name': 'Puk-kura',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5233,
		longitude: 176.8442,
		accuracy: 4,
	},
	'4120': {
		'country code': 'NZ',
		'postal code': 4120,
		'place name': 'HASTINGS',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6381,
		longitude: 176.8492,
		accuracy: 4,
	},
	'4122': {
		'country code': 'NZ',
		'postal code': 4122,
		'place name': 'Akina',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6482,
		longitude: 176.8402,
		accuracy: 4,
	},
	'4130': {
		'country code': 'NZ',
		'postal code': 4130,
		'place name': 'Havelock North',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6667,
		longitude: 176.8833,
		accuracy: 4,
	},
	'4140': {
		'country code': 'NZ',
		'postal code': 4140,
		'place name': 'Onekawa',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5091,
		longitude: 176.8896,
		accuracy: 4,
	},
	'4141': {
		'country code': 'NZ',
		'postal code': 4141,
		'place name': 'Taradale',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5333,
		longitude: 176.85,
		accuracy: 4,
	},
	'4142': {
		'country code': 'NZ',
		'postal code': 4142,
		'place name': 'Pirimai',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5082,
		longitude: 176.8802,
		accuracy: 4,
	},
	'4143': {
		'country code': 'NZ',
		'postal code': 4143,
		'place name': 'Napier South',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4982,
		longitude: 176.9002,
		accuracy: 4,
	},
	'4144': {
		'country code': 'NZ',
		'postal code': 4144,
		'place name': 'Ahuriri',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4833,
		longitude: 176.9,
		accuracy: 4,
	},
	'4145': {
		'country code': 'NZ',
		'postal code': 4145,
		'place name': 'Greenmeadows',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5182,
		longitude: 176.8602,
		accuracy: 4,
	},
	'4146': {
		'country code': 'NZ',
		'postal code': 4146,
		'place name': 'Pirimai',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5082,
		longitude: 176.8802,
		accuracy: 4,
	},
	'4147': {
		'country code': 'NZ',
		'postal code': 4147,
		'place name': 'Tamatea',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5082,
		longitude: 176.8602,
		accuracy: 4,
	},
	'4148': {
		'country code': 'NZ',
		'postal code': 4148,
		'place name': 'Clive',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5833,
		longitude: 176.9167,
		accuracy: 4,
	},
	'4149': {
		'country code': 'NZ',
		'postal code': 4149,
		'place name': 'Petane',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4131,
		longitude: 176.8767,
		accuracy: 4,
	},
	'4153': {
		'country code': 'NZ',
		'postal code': 4153,
		'place name': 'Saint Leonards',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6282,
		longitude: 176.8302,
		accuracy: 4,
	},
	'4154': {
		'country code': 'NZ',
		'postal code': 4154,
		'place name': 'Flaxmere',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6182,
		longitude: 176.7802,
		accuracy: 4,
	},
	'4155': {
		'country code': 'NZ',
		'postal code': 4155,
		'place name': 'Parkvale',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6382,
		longitude: 176.8502,
		accuracy: 4,
	},
	'4156': {
		'country code': 'NZ',
		'postal code': 4156,
		'place name': 'Parkvale',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6382,
		longitude: 176.8502,
		accuracy: 4,
	},
	'4157': {
		'country code': 'NZ',
		'postal code': 4157,
		'place name': 'Havelock North',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6667,
		longitude: 176.8833,
		accuracy: 4,
	},
	'4158': {
		'country code': 'NZ',
		'postal code': 4158,
		'place name': 'Parkvale',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6382,
		longitude: 176.8502,
		accuracy: 4,
	},
	'4159': {
		'country code': 'NZ',
		'postal code': 4159,
		'place name': 'Mayfair',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6382,
		longitude: 176.8502,
		accuracy: 4,
	},
	'4160': {
		'country code': 'NZ',
		'postal code': 4160,
		'place name': 'Wairoa',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0333,
		longitude: 177.3667,
		accuracy: 4,
	},
	'4161': {
		'country code': 'NZ',
		'postal code': 4161,
		'place name': 'Pahowai',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6006,
		longitude: 176.8942,
		accuracy: 4,
	},
	'4162': {
		'country code': 'NZ',
		'postal code': 4162,
		'place name': 'Tutira',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.2,
		longitude: 176.8667,
		accuracy: 4,
	},
	'4163': {
		'country code': 'NZ',
		'postal code': 4163,
		'place name': 'Frasertown',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.9686,
		longitude: 177.4006,
		accuracy: 4,
	},
	'4164': {
		'country code': 'NZ',
		'postal code': 4164,
		'place name': 'Tuai',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.8167,
		longitude: 177.1667,
		accuracy: 4,
	},
	'4165': {
		'country code': 'NZ',
		'postal code': 4165,
		'place name': 'Nuhaka',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.05,
		longitude: 177.75,
		accuracy: 4,
	},
	'4166': {
		'country code': 'NZ',
		'postal code': 4166,
		'place name': 'Mahia',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0872,
		longitude: 177.9172,
		accuracy: 4,
	},
	'4171': {
		'country code': 'NZ',
		'postal code': 4171,
		'place name': 'Blackburn',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.8667,
		longitude: 176.2833,
		accuracy: 4,
	},
	'4172': {
		'country code': 'NZ',
		'postal code': 4172,
		'place name': 'Te Awanga',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6333,
		longitude: 176.9833,
		accuracy: 4,
	},
	'4174': {
		'country code': 'NZ',
		'postal code': 4174,
		'place name': 'Te Hauke',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.7667,
		longitude: 176.6833,
		accuracy: 4,
	},
	'4175': {
		'country code': 'NZ',
		'postal code': 4175,
		'place name': 'Flaxmere',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6182,
		longitude: 176.7802,
		accuracy: 4,
	},
	'4178': {
		'country code': 'NZ',
		'postal code': 4178,
		'place name': 'Poukawa',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.7667,
		longitude: 176.7333,
		accuracy: 4,
	},
	'4179': {
		'country code': 'NZ',
		'postal code': 4179,
		'place name': 'Puketitiri',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.2833,
		longitude: 176.55,
		accuracy: 4,
	},
	'4181': {
		'country code': 'NZ',
		'postal code': 4181,
		'place name': 'Tutira',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.2,
		longitude: 176.8667,
		accuracy: 4,
	},
	'4182': {
		'country code': 'NZ',
		'postal code': 4182,
		'place name': 'Te Pohue',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.25,
		longitude: 176.6833,
		accuracy: 4,
	},
	'4183': {
		'country code': 'NZ',
		'postal code': 4183,
		'place name': 'Taradale',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5333,
		longitude: 176.85,
		accuracy: 4,
	},
	'4184': {
		'country code': 'NZ',
		'postal code': 4184,
		'place name': 'Puketitiri',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.2833,
		longitude: 176.55,
		accuracy: 4,
	},
	'4186': {
		'country code': 'NZ',
		'postal code': 4186,
		'place name': 'Patoka',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.35,
		longitude: 176.6167,
		accuracy: 4,
	},
	'4188': {
		'country code': 'NZ',
		'postal code': 4188,
		'place name': 'Kotemaori',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0667,
		longitude: 177.0333,
		accuracy: 4,
	},
	'4189': {
		'country code': 'NZ',
		'postal code': 4189,
		'place name': 'Kotemaori',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0667,
		longitude: 177.0333,
		accuracy: 4,
	},
	'4191': {
		'country code': 'NZ',
		'postal code': 4191,
		'place name': 'Raupunga',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0333,
		longitude: 177.2167,
		accuracy: 4,
	},
	'4193': {
		'country code': 'NZ',
		'postal code': 4193,
		'place name': 'Wairoa',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0333,
		longitude: 177.3667,
		accuracy: 4,
	},
	'4195': {
		'country code': 'NZ',
		'postal code': 4195,
		'place name': 'Tuai',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.8167,
		longitude: 177.1667,
		accuracy: 4,
	},
	'4196': {
		'country code': 'NZ',
		'postal code': 4196,
		'place name': 'Opoiti',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.8883,
		longitude: 177.4928,
		accuracy: 4,
	},
	'4197': {
		'country code': 'NZ',
		'postal code': 4197,
		'place name': 'Raupunga',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0333,
		longitude: 177.2167,
		accuracy: 4,
	},
	'4198': {
		'country code': 'NZ',
		'postal code': 4198,
		'place name': 'Waitaniwha',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0592,
		longitude: 177.7989,
		accuracy: 4,
	},
	'4200': {
		'country code': 'NZ',
		'postal code': 4200,
		'place name': 'Waipukurau',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9982,
		longitude: 176.5602,
		accuracy: 4,
	},
	'4202': {
		'country code': 'NZ',
		'postal code': 4202,
		'place name': 'Otane',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.8833,
		longitude: 176.6333,
		accuracy: 4,
	},
	'4203': {
		'country code': 'NZ',
		'postal code': 4203,
		'place name': 'Takapau',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.0333,
		longitude: 176.35,
		accuracy: 4,
	},
	'4210': {
		'country code': 'NZ',
		'postal code': 4210,
		'place name': 'Waipawa',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9382,
		longitude: 176.6002,
		accuracy: 4,
	},
	'4240': {
		'country code': 'NZ',
		'postal code': 4240,
		'place name': 'Waipawa',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9382,
		longitude: 176.6002,
		accuracy: 4,
	},
	'4241': {
		'country code': 'NZ',
		'postal code': 4241,
		'place name': 'Otane',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.8833,
		longitude: 176.6333,
		accuracy: 4,
	},
	'4242': {
		'country code': 'NZ',
		'postal code': 4242,
		'place name': 'Waipukurau',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9982,
		longitude: 176.5602,
		accuracy: 4,
	},
	'4243': {
		'country code': 'NZ',
		'postal code': 4243,
		'place name': 'Takapau',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.0333,
		longitude: 176.35,
		accuracy: 4,
	},
	'4244': {
		'country code': 'NZ',
		'postal code': 4244,
		'place name': 'Onga Onga',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9167,
		longitude: 176.4167,
		accuracy: 4,
	},
	'4245': {
		'country code': 'NZ',
		'postal code': 4245,
		'place name': 'Porangahau',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2789,
		longitude: 176.6283,
		accuracy: 4,
	},
	'4271': {
		'country code': 'NZ',
		'postal code': 4271,
		'place name': 'Patangata',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9167,
		longitude: 176.7167,
		accuracy: 4,
	},
	'4272': {
		'country code': 'NZ',
		'postal code': 4272,
		'place name': 'Ruataniwha',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9333,
		longitude: 176.5,
		accuracy: 4,
	},
	'4273': {
		'country code': 'NZ',
		'postal code': 4273,
		'place name': 'Argyll East',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.8667,
		longitude: 176.5333,
		accuracy: 4,
	},
	'4274': {
		'country code': 'NZ',
		'postal code': 4274,
		'place name': 'Tikokino',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.8167,
		longitude: 176.45,
		accuracy: 4,
	},
	'4275': {
		'country code': 'NZ',
		'postal code': 4275,
		'place name': 'Waipawa',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9382,
		longitude: 176.6002,
		accuracy: 4,
	},
	'4276': {
		'country code': 'NZ',
		'postal code': 4276,
		'place name': 'Pukehou',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.8333,
		longitude: 176.6333,
		accuracy: 4,
	},
	'4277': {
		'country code': 'NZ',
		'postal code': 4277,
		'place name': 'Patangata',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9167,
		longitude: 176.7167,
		accuracy: 4,
	},
	'4278': {
		'country code': 'NZ',
		'postal code': 4278,
		'place name': 'Blackburn',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.8667,
		longitude: 176.2833,
		accuracy: 4,
	},
	'4279': {
		'country code': 'NZ',
		'postal code': 4279,
		'place name': 'Blackburn',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.8667,
		longitude: 176.2833,
		accuracy: 4,
	},
	'4281': {
		'country code': 'NZ',
		'postal code': 4281,
		'place name': 'Maharakeke',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.0167,
		longitude: 176.45,
		accuracy: 4,
	},
	'4282': {
		'country code': 'NZ',
		'postal code': 4282,
		'place name': 'Wanstead',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.1333,
		longitude: 176.5333,
		accuracy: 4,
	},
	'4283': {
		'country code': 'NZ',
		'postal code': 4283,
		'place name': 'Ruataniwha',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9333,
		longitude: 176.5,
		accuracy: 4,
	},
	'4284': {
		'country code': 'NZ',
		'postal code': 4284,
		'place name': 'Wallingford',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2167,
		longitude: 176.6,
		accuracy: 4,
	},
	'4285': {
		'country code': 'NZ',
		'postal code': 4285,
		'place name': 'Waipukurau',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9982,
		longitude: 176.5602,
		accuracy: 4,
	},
	'4286': {
		'country code': 'NZ',
		'postal code': 4286,
		'place name': 'Ashley Clinton',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.95,
		longitude: 176.25,
		accuracy: 4,
	},
	'4288': {
		'country code': 'NZ',
		'postal code': 4288,
		'place name': 'Maharakeke',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.0167,
		longitude: 176.45,
		accuracy: 4,
	},
	'4291': {
		'country code': 'NZ',
		'postal code': 4291,
		'place name': 'Porangahau',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2789,
		longitude: 176.6283,
		accuracy: 4,
	},
	'4292': {
		'country code': 'NZ',
		'postal code': 4292,
		'place name': 'Porangahau',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2789,
		longitude: 176.6283,
		accuracy: 4,
	},
	'4293': {
		'country code': 'NZ',
		'postal code': 4293,
		'place name': 'Porangahau',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2789,
		longitude: 176.6283,
		accuracy: 4,
	},
	'4294': {
		'country code': 'NZ',
		'postal code': 4294,
		'place name': 'Waimarama',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.8,
		longitude: 176.9833,
		accuracy: 4,
	},
	'4295': {
		'country code': 'NZ',
		'postal code': 4295,
		'place name': 'Elsthorpe',
		'admin name1': "Hawke's Bay",
		'admin code1': 'F2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9167,
		longitude: 176.8167,
		accuracy: 4,
	},
	'3920': {
		'country code': 'NZ',
		'postal code': 3920,
		'place name': 'Taumarunui',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.8782,
		longitude: 175.2602,
		accuracy: 4,
	},
	'3924': {
		'country code': 'NZ',
		'postal code': 3924,
		'place name': 'Manunui',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.8833,
		longitude: 175.3333,
		accuracy: 4,
	},
	'3926': {
		'country code': 'NZ',
		'postal code': 3926,
		'place name': 'Ohura',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.8333,
		longitude: 174.9833,
		accuracy: 4,
	},
	'3946': {
		'country code': 'NZ',
		'postal code': 3946,
		'place name': 'Taumarunui',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.8782,
		longitude: 175.2602,
		accuracy: 4,
	},
	'3947': {
		'country code': 'NZ',
		'postal code': 3947,
		'place name': 'Ohura',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.8333,
		longitude: 174.9833,
		accuracy: 4,
	},
	'3948': {
		'country code': 'NZ',
		'postal code': 3948,
		'place name': 'National Park',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.1833,
		longitude: 175.4,
		accuracy: 4,
	},
	'3979': {
		'country code': 'NZ',
		'postal code': 3979,
		'place name': 'Waitewhenua',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.7,
		longitude: 174.9667,
		accuracy: 4,
	},
	'3980': {
		'country code': 'NZ',
		'postal code': 3980,
		'place name': 'Tatu',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.9,
		longitude: 174.95,
		accuracy: 4,
	},
	'3989': {
		'country code': 'NZ',
		'postal code': 3989,
		'place name': 'Oio',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.05,
		longitude: 175.4167,
		accuracy: 4,
	},
	'3990': {
		'country code': 'NZ',
		'postal code': 3990,
		'place name': 'Upper Ruatiti',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.2,
		longitude: 175.2,
		accuracy: 4,
	},
	'3991': {
		'country code': 'NZ',
		'postal code': 3991,
		'place name': 'Aukopae',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.9,
		longitude: 175.1667,
		accuracy: 4,
	},
	'3992': {
		'country code': 'NZ',
		'postal code': 3992,
		'place name': 'Hikumutu',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.95,
		longitude: 175.2833,
		accuracy: 4,
	},
	'3993': {
		'country code': 'NZ',
		'postal code': 3993,
		'place name': 'Kirikau',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0167,
		longitude: 175.1333,
		accuracy: 4,
	},
	'3994': {
		'country code': 'NZ',
		'postal code': 3994,
		'place name': 'Ngapuke',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.85,
		longitude: 175.4667,
		accuracy: 4,
	},
	'3995': {
		'country code': 'NZ',
		'postal code': 3995,
		'place name': 'Matiere',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.75,
		longitude: 175.1,
		accuracy: 4,
	},
	'3996': {
		'country code': 'NZ',
		'postal code': 3996,
		'place name': 'Okahukura',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.7833,
		longitude: 175.2167,
		accuracy: 4,
	},
	'3997': {
		'country code': 'NZ',
		'postal code': 3997,
		'place name': 'Ongarue',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.7167,
		longitude: 175.2833,
		accuracy: 4,
	},
	'3998': {
		'country code': 'NZ',
		'postal code': 3998,
		'place name': 'Waimiha',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.6167,
		longitude: 175.3167,
		accuracy: 4,
	},
	'4287': {
		'country code': 'NZ',
		'postal code': 4287,
		'place name': 'Norsewood',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.0705,
		longitude: 176.2183,
		accuracy: 4,
	},
	'4412': {
		'country code': 'NZ',
		'postal code': 4412,
		'place name': 'Awapuni',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3762,
		longitude: 175.5838,
		accuracy: 4,
	},
	'4414': {
		'country code': 'NZ',
		'postal code': 4414,
		'place name': 'Kelvin Grove',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3287,
		longitude: 175.6451,
		accuracy: 4,
	},
	'4440': {
		'country code': 'NZ',
		'postal code': 4440,
		'place name': 'Hokowhitu',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3626,
		longitude: 175.6338,
		accuracy: 4,
	},
	'4441': {
		'country code': 'NZ',
		'postal code': 4441,
		'place name': 'Terrace End',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3483,
		longitude: 175.6302,
		accuracy: 4,
	},
	'4442': {
		'country code': 'NZ',
		'postal code': 4442,
		'place name': 'Takaro',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3579,
		longitude: 175.5897,
		accuracy: 4,
	},
	'4443': {
		'country code': 'NZ',
		'postal code': 4443,
		'place name': 'West End',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3659,
		longitude: 175.5984,
		accuracy: 4,
	},
	'4444': {
		'country code': 'NZ',
		'postal code': 4444,
		'place name': 'Terrace End',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3483,
		longitude: 175.6302,
		accuracy: 4,
	},
	'4445': {
		'country code': 'NZ',
		'postal code': 4445,
		'place name': 'Awapuni',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3762,
		longitude: 175.5838,
		accuracy: 4,
	},
	'4446': {
		'country code': 'NZ',
		'postal code': 4446,
		'place name': 'Hokowhitu',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3626,
		longitude: 175.6338,
		accuracy: 4,
	},
	'4470': {
		'country code': 'NZ',
		'postal code': 4470,
		'place name': 'Ashhurst',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2911,
		longitude: 175.7547,
		accuracy: 4,
	},
	'4471': {
		'country code': 'NZ',
		'postal code': 4471,
		'place name': 'Aokautere',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3667,
		longitude: 175.6667,
		accuracy: 4,
	},
	'4473': {
		'country code': 'NZ',
		'postal code': 4473,
		'place name': 'Carnarvon',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2833,
		longitude: 175.3167,
		accuracy: 4,
	},
	'4474': {
		'country code': 'NZ',
		'postal code': 4474,
		'place name': 'Tokomaru',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.473,
		longitude: 175.5093,
		accuracy: 4,
	},
	'4475': {
		'country code': 'NZ',
		'postal code': 4475,
		'place name': 'Kairanga',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.35,
		longitude: 175.5333,
		accuracy: 4,
	},
	'4476': {
		'country code': 'NZ',
		'postal code': 4476,
		'place name': 'Rongotea',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2926,
		longitude: 175.4251,
		accuracy: 4,
	},
	'4477': {
		'country code': 'NZ',
		'postal code': 4477,
		'place name': 'Mangawhata',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4,
		longitude: 175.4333,
		accuracy: 4,
	},
	'4478': {
		'country code': 'NZ',
		'postal code': 4478,
		'place name': 'Milson',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3283,
		longitude: 175.6102,
		accuracy: 4,
	},
	'4479': {
		'country code': 'NZ',
		'postal code': 4479,
		'place name': 'Sanson',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2201,
		longitude: 175.4245,
		accuracy: 4,
	},
	'4481': {
		'country code': 'NZ',
		'postal code': 4481,
		'place name': 'Bunnythorpe',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2833,
		longitude: 175.6333,
		accuracy: 4,
	},
	'4500': {
		'country code': 'NZ',
		'postal code': 4500,
		'place name': 'Aramoho',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9019,
		longitude: 175.0647,
		accuracy: 4,
	},
	'4501': {
		'country code': 'NZ',
		'postal code': 4501,
		'place name': 'Gonville',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9388,
		longitude: 175.0457,
		accuracy: 4,
	},
	'4540': {
		'country code': 'NZ',
		'postal code': 4540,
		'place name': 'Wanganui',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9333,
		longitude: 175.05,
		accuracy: 4,
	},
	'4541': {
		'country code': 'NZ',
		'postal code': 4541,
		'place name': 'Wanganui',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9333,
		longitude: 175.05,
		accuracy: 4,
	},
	'4542': {
		'country code': 'NZ',
		'postal code': 4542,
		'place name': 'Wanganui East',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9171,
		longitude: 175.0616,
		accuracy: 4,
	},
	'4543': {
		'country code': 'NZ',
		'postal code': 4543,
		'place name': 'Gonville',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9388,
		longitude: 175.0457,
		accuracy: 4,
	},
	'4547': {
		'country code': 'NZ',
		'postal code': 4547,
		'place name': 'Turakina South',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.0515,
		longitude: 175.2188,
		accuracy: 4,
	},
	'4548': {
		'country code': 'NZ',
		'postal code': 4548,
		'place name': 'Ratana',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.0412,
		longitude: 175.1771,
		accuracy: 4,
	},
	'4571': {
		'country code': 'NZ',
		'postal code': 4571,
		'place name': 'Brunswick',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.85,
		longitude: 175.0167,
		accuracy: 4,
	},
	'4572': {
		'country code': 'NZ',
		'postal code': 4572,
		'place name': 'Wiritoa',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9667,
		longitude: 175.1,
		accuracy: 4,
	},
	'4573': {
		'country code': 'NZ',
		'postal code': 4573,
		'place name': 'Kakatahi',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6833,
		longitude: 175.3333,
		accuracy: 4,
	},
	'4574': {
		'country code': 'NZ',
		'postal code': 4574,
		'place name': 'Mowhanau',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.8667,
		longitude: 174.9,
		accuracy: 4,
	},
	'4575': {
		'country code': 'NZ',
		'postal code': 4575,
		'place name': 'Upokonui',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.7833,
		longitude: 175.2167,
		accuracy: 4,
	},
	'4576': {
		'country code': 'NZ',
		'postal code': 4576,
		'place name': 'Matahiwi',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6,
		longitude: 175.15,
		accuracy: 4,
	},
	'4577': {
		'country code': 'NZ',
		'postal code': 4577,
		'place name': 'Kauangaroa',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9244,
		longitude: 175.2829,
		accuracy: 4,
	},
	'4578': {
		'country code': 'NZ',
		'postal code': 4578,
		'place name': 'Ahuiti',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6333,
		longitude: 175,
		accuracy: 4,
	},
	'4581': {
		'country code': 'NZ',
		'postal code': 4581,
		'place name': 'Turakina',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.047,
		longitude: 175.2147,
		accuracy: 4,
	},
	'4582': {
		'country code': 'NZ',
		'postal code': 4582,
		'place name': 'Okoia',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9333,
		longitude: 175.1333,
		accuracy: 4,
	},
	'4584': {
		'country code': 'NZ',
		'postal code': 4584,
		'place name': 'Whakaihuwaka',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.7667,
		longitude: 175.15,
		accuracy: 4,
	},
	'4585': {
		'country code': 'NZ',
		'postal code': 4585,
		'place name': 'Kakatahi',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6833,
		longitude: 175.3333,
		accuracy: 4,
	},
	'4586': {
		'country code': 'NZ',
		'postal code': 4586,
		'place name': 'Kakatahi',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6833,
		longitude: 175.3333,
		accuracy: 4,
	},
	'4587': {
		'country code': 'NZ',
		'postal code': 4587,
		'place name': 'Nukumaru',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.8333,
		longitude: 174.8,
		accuracy: 4,
	},
	'4625': {
		'country code': 'NZ',
		'postal code': 4625,
		'place name': 'Ohakune',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4082,
		longitude: 175.4002,
		accuracy: 4,
	},
	'4632': {
		'country code': 'NZ',
		'postal code': 4632,
		'place name': 'Raetihi',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4288,
		longitude: 175.2773,
		accuracy: 4,
	},
	'4646': {
		'country code': 'NZ',
		'postal code': 4646,
		'place name': 'Raetihi',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4288,
		longitude: 175.2773,
		accuracy: 4,
	},
	'4660': {
		'country code': 'NZ',
		'postal code': 4660,
		'place name': 'Ohakune',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4082,
		longitude: 175.4002,
		accuracy: 4,
	},
	'4691': {
		'country code': 'NZ',
		'postal code': 4691,
		'place name': 'Rangataua',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4333,
		longitude: 175.4667,
		accuracy: 4,
	},
	'4694': {
		'country code': 'NZ',
		'postal code': 4694,
		'place name': 'Raetihi',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4288,
		longitude: 175.2773,
		accuracy: 4,
	},
	'4696': {
		'country code': 'NZ',
		'postal code': 4696,
		'place name': 'Te Ranga',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.3,
		longitude: 175.2,
		accuracy: 4,
	},
	'4702': {
		'country code': 'NZ',
		'postal code': 4702,
		'place name': 'FEILDING',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2183,
		longitude: 175.5402,
		accuracy: 4,
	},
	'4710': {
		'country code': 'NZ',
		'postal code': 4710,
		'place name': 'Marton',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.0692,
		longitude: 175.3782,
		accuracy: 4,
	},
	'4720': {
		'country code': 'NZ',
		'postal code': 4720,
		'place name': 'Taihape',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6782,
		longitude: 175.798,
		accuracy: 4,
	},
	'4730': {
		'country code': 'NZ',
		'postal code': 4730,
		'place name': 'Hunterville',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9366,
		longitude: 175.5684,
		accuracy: 4,
	},
	'4740': {
		'country code': 'NZ',
		'postal code': 4740,
		'place name': 'FEILDING',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2183,
		longitude: 175.5402,
		accuracy: 4,
	},
	'4741': {
		'country code': 'NZ',
		'postal code': 4741,
		'place name': 'Marton',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.0692,
		longitude: 175.3782,
		accuracy: 4,
	},
	'4742': {
		'country code': 'NZ',
		'postal code': 4742,
		'place name': 'Taihape',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6782,
		longitude: 175.798,
		accuracy: 4,
	},
	'4744': {
		'country code': 'NZ',
		'postal code': 4744,
		'place name': 'Kimbolton',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.0555,
		longitude: 175.7794,
		accuracy: 4,
	},
	'4745': {
		'country code': 'NZ',
		'postal code': 4745,
		'place name': 'Hunterville',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9366,
		longitude: 175.5684,
		accuracy: 4,
	},
	'4746': {
		'country code': 'NZ',
		'postal code': 4746,
		'place name': 'Mangaweka',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.8094,
		longitude: 175.7888,
		accuracy: 4,
	},
	'4771': {
		'country code': 'NZ',
		'postal code': 4771,
		'place name': 'Umutoi',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40,
		longitude: 175.9667,
		accuracy: 4,
	},
	'4774': {
		'country code': 'NZ',
		'postal code': 4774,
		'place name': 'Apiti',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9667,
		longitude: 175.8833,
		accuracy: 4,
	},
	'4775': {
		'country code': 'NZ',
		'postal code': 4775,
		'place name': 'Colyton',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2333,
		longitude: 175.65,
		accuracy: 4,
	},
	'4777': {
		'country code': 'NZ',
		'postal code': 4777,
		'place name': 'Kiwitea',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.1167,
		longitude: 175.7333,
		accuracy: 4,
	},
	'4779': {
		'country code': 'NZ',
		'postal code': 4779,
		'place name': 'Waituna West',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.05,
		longitude: 175.6167,
		accuracy: 4,
	},
	'4780': {
		'country code': 'NZ',
		'postal code': 4780,
		'place name': 'Rewa',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9833,
		longitude: 175.6333,
		accuracy: 4,
	},
	'4781': {
		'country code': 'NZ',
		'postal code': 4781,
		'place name': 'Silverhope',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9659,
		longitude: 175.5283,
		accuracy: 4,
	},
	'4782': {
		'country code': 'NZ',
		'postal code': 4782,
		'place name': 'Pukeroa',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.7841,
		longitude: 175.4972,
		accuracy: 4,
	},
	'4783': {
		'country code': 'NZ',
		'postal code': 4783,
		'place name': 'Pakihikura',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9333,
		longitude: 175.7167,
		accuracy: 4,
	},
	'4784': {
		'country code': 'NZ',
		'postal code': 4784,
		'place name': 'Silverhope',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9659,
		longitude: 175.5283,
		accuracy: 4,
	},
	'4785': {
		'country code': 'NZ',
		'postal code': 4785,
		'place name': 'Poukiore',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.8694,
		longitude: 175.621,
		accuracy: 4,
	},
	'4786': {
		'country code': 'NZ',
		'postal code': 4786,
		'place name': 'Rewa',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9833,
		longitude: 175.6333,
		accuracy: 4,
	},
	'4788': {
		'country code': 'NZ',
		'postal code': 4788,
		'place name': 'Marton',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.0692,
		longitude: 175.3782,
		accuracy: 4,
	},
	'4789': {
		'country code': 'NZ',
		'postal code': 4789,
		'place name': 'Marton',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.0692,
		longitude: 175.3782,
		accuracy: 4,
	},
	'4791': {
		'country code': 'NZ',
		'postal code': 4791,
		'place name': 'Raketapauma',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5513,
		longitude: 175.65,
		accuracy: 4,
	},
	'4792': {
		'country code': 'NZ',
		'postal code': 4792,
		'place name': 'Erehwon',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5165,
		longitude: 175.9651,
		accuracy: 4,
	},
	'4793': {
		'country code': 'NZ',
		'postal code': 4793,
		'place name': 'Ohotu',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.7119,
		longitude: 175.8389,
		accuracy: 4,
	},
	'4794': {
		'country code': 'NZ',
		'postal code': 4794,
		'place name': 'Utiku',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.7334,
		longitude: 175.8438,
		accuracy: 4,
	},
	'4795': {
		'country code': 'NZ',
		'postal code': 4795,
		'place name': 'Waiouru Military Camp',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4706,
		longitude: 175.6817,
		accuracy: 4,
	},
	'4796': {
		'country code': 'NZ',
		'postal code': 4796,
		'place name': 'Taihape',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6782,
		longitude: 175.798,
		accuracy: 4,
	},
	'4797': {
		'country code': 'NZ',
		'postal code': 4797,
		'place name': 'Ruahine',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.8333,
		longitude: 175.9,
		accuracy: 4,
	},
	'4810': {
		'country code': 'NZ',
		'postal code': 4810,
		'place name': 'Ashhurst',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2911,
		longitude: 175.7547,
		accuracy: 4,
	},
	'4814': {
		'country code': 'NZ',
		'postal code': 4814,
		'place name': 'Foxton',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4667,
		longitude: 175.3,
		accuracy: 4,
	},
	'4815': {
		'country code': 'NZ',
		'postal code': 4815,
		'place name': 'Foxton Beach',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4667,
		longitude: 175.2167,
		accuracy: 4,
	},
	'4816': {
		'country code': 'NZ',
		'postal code': 4816,
		'place name': 'Ohakea',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2,
		longitude: 175.4,
		accuracy: 4,
	},
	'4817': {
		'country code': 'NZ',
		'postal code': 4817,
		'place name': 'Sanson',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2201,
		longitude: 175.4245,
		accuracy: 4,
	},
	'4818': {
		'country code': 'NZ',
		'postal code': 4818,
		'place name': 'Bulls',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.1749,
		longitude: 175.3846,
		accuracy: 4,
	},
	'4820': {
		'country code': 'NZ',
		'postal code': 4820,
		'place name': 'Longburn',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.384,
		longitude: 175.5429,
		accuracy: 4,
	},
	'4821': {
		'country code': 'NZ',
		'postal code': 4821,
		'place name': 'Shannon',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.5483,
		longitude: 175.4202,
		accuracy: 4,
	},
	'4822': {
		'country code': 'NZ',
		'postal code': 4822,
		'place name': 'Tangimoana',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3,
		longitude: 175.25,
		accuracy: 4,
	},
	'4847': {
		'country code': 'NZ',
		'postal code': 4847,
		'place name': 'Ashhurst',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2911,
		longitude: 175.7547,
		accuracy: 4,
	},
	'4848': {
		'country code': 'NZ',
		'postal code': 4848,
		'place name': 'Foxton',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4667,
		longitude: 175.3,
		accuracy: 4,
	},
	'4849': {
		'country code': 'NZ',
		'postal code': 4849,
		'place name': 'Foxton Beach',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4667,
		longitude: 175.2167,
		accuracy: 4,
	},
	'4860': {
		'country code': 'NZ',
		'postal code': 4860,
		'place name': 'Shannon',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.5483,
		longitude: 175.4202,
		accuracy: 4,
	},
	'4862': {
		'country code': 'NZ',
		'postal code': 4862,
		'place name': 'Sanson',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2201,
		longitude: 175.4245,
		accuracy: 4,
	},
	'4863': {
		'country code': 'NZ',
		'postal code': 4863,
		'place name': 'Bulls',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.1749,
		longitude: 175.3846,
		accuracy: 4,
	},
	'4864': {
		'country code': 'NZ',
		'postal code': 4864,
		'place name': 'Tokomaru',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.473,
		longitude: 175.5093,
		accuracy: 4,
	},
	'4865': {
		'country code': 'NZ',
		'postal code': 4865,
		'place name': 'Rongotea',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2926,
		longitude: 175.4251,
		accuracy: 4,
	},
	'4866': {
		'country code': 'NZ',
		'postal code': 4866,
		'place name': 'Longburn',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.384,
		longitude: 175.5429,
		accuracy: 4,
	},
	'4867': {
		'country code': 'NZ',
		'postal code': 4867,
		'place name': 'Bunnythorpe',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2833,
		longitude: 175.6333,
		accuracy: 4,
	},
	'4884': {
		'country code': 'NZ',
		'postal code': 4884,
		'place name': 'Pohangina',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.1734,
		longitude: 175.7943,
		accuracy: 4,
	},
	'4891': {
		'country code': 'NZ',
		'postal code': 4891,
		'place name': 'Himatangi',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4,
		longitude: 175.3167,
		accuracy: 4,
	},
	'4893': {
		'country code': 'NZ',
		'postal code': 4893,
		'place name': 'Marotiri',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4833,
		longitude: 175.35,
		accuracy: 4,
	},
	'4894': {
		'country code': 'NZ',
		'postal code': 4894,
		'place name': 'Bulls',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.1749,
		longitude: 175.3846,
		accuracy: 4,
	},
	'4900': {
		'country code': 'NZ',
		'postal code': 4900,
		'place name': 'Eketahuna',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.6462,
		longitude: 175.7037,
		accuracy: 4,
	},
	'4910': {
		'country code': 'NZ',
		'postal code': 4910,
		'place name': 'Pahiatua',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4534,
		longitude: 175.8406,
		accuracy: 4,
	},
	'4920': {
		'country code': 'NZ',
		'postal code': 4920,
		'place name': 'Woodville',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3372,
		longitude: 175.8668,
		accuracy: 4,
	},
	'4930': {
		'country code': 'NZ',
		'postal code': 4930,
		'place name': 'Dannevirke',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2055,
		longitude: 176.1008,
		accuracy: 4,
	},
	'4940': {
		'country code': 'NZ',
		'postal code': 4940,
		'place name': 'Eketahuna',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.6462,
		longitude: 175.7037,
		accuracy: 4,
	},
	'4941': {
		'country code': 'NZ',
		'postal code': 4941,
		'place name': 'Pahiatua',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4534,
		longitude: 175.8406,
		accuracy: 4,
	},
	'4942': {
		'country code': 'NZ',
		'postal code': 4942,
		'place name': 'Dannevirke',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2055,
		longitude: 176.1008,
		accuracy: 4,
	},
	'4943': {
		'country code': 'NZ',
		'postal code': 4943,
		'place name': 'Norsewood',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.0705,
		longitude: 176.2183,
		accuracy: 4,
	},
	'4944': {
		'country code': 'NZ',
		'postal code': 4944,
		'place name': 'Pongaroa',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.5421,
		longitude: 176.1937,
		accuracy: 4,
	},
	'4945': {
		'country code': 'NZ',
		'postal code': 4945,
		'place name': 'Woodville',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3372,
		longitude: 175.8668,
		accuracy: 4,
	},
	'4970': {
		'country code': 'NZ',
		'postal code': 4970,
		'place name': 'Ti-tree Point',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.437,
		longitude: 176.4054,
		accuracy: 4,
	},
	'4971': {
		'country code': 'NZ',
		'postal code': 4971,
		'place name': 'Waipatiki',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.373,
		longitude: 176.2833,
		accuracy: 4,
	},
	'4972': {
		'country code': 'NZ',
		'postal code': 4972,
		'place name': 'Kiritaki',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2392,
		longitude: 175.9827,
		accuracy: 4,
	},
	'4973': {
		'country code': 'NZ',
		'postal code': 4973,
		'place name': 'Waipatiki',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.373,
		longitude: 176.2833,
		accuracy: 4,
	},
	'4974': {
		'country code': 'NZ',
		'postal code': 4974,
		'place name': 'Ngamoko',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.0545,
		longitude: 176.1632,
		accuracy: 4,
	},
	'4975': {
		'country code': 'NZ',
		'postal code': 4975,
		'place name': 'Tahoraiti',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.2335,
		longitude: 176.0597,
		accuracy: 4,
	},
	'4976': {
		'country code': 'NZ',
		'postal code': 4976,
		'place name': 'Raumati',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.1908,
		longitude: 176.1463,
		accuracy: 4,
	},
	'4977': {
		'country code': 'NZ',
		'postal code': 4977,
		'place name': 'Makotuku',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.1206,
		longitude: 176.2319,
		accuracy: 4,
	},
	'4978': {
		'country code': 'NZ',
		'postal code': 4978,
		'place name': 'Piripiri',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.1736,
		longitude: 176.1347,
		accuracy: 4,
	},
	'4979': {
		'country code': 'NZ',
		'postal code': 4979,
		'place name': 'Waione',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4695,
		longitude: 176.2797,
		accuracy: 4,
	},
	'4981': {
		'country code': 'NZ',
		'postal code': 4981,
		'place name': 'Kaitawa',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4966,
		longitude: 175.89,
		accuracy: 4,
	},
	'4982': {
		'country code': 'NZ',
		'postal code': 4982,
		'place name': 'Ngaturi',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4682,
		longitude: 175.9227,
		accuracy: 4,
	},
	'4983': {
		'country code': 'NZ',
		'postal code': 4983,
		'place name': 'Ballance',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4022,
		longitude: 175.8017,
		accuracy: 4,
	},
	'4984': {
		'country code': 'NZ',
		'postal code': 4984,
		'place name': 'Konini',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.5046,
		longitude: 175.7916,
		accuracy: 4,
	},
	'4985': {
		'country code': 'NZ',
		'postal code': 4985,
		'place name': 'Makuri',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.5333,
		longitude: 176.0167,
		accuracy: 4,
	},
	'4986': {
		'country code': 'NZ',
		'postal code': 4986,
		'place name': 'Haunui',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.6085,
		longitude: 176.0463,
		accuracy: 4,
	},
	'4987': {
		'country code': 'NZ',
		'postal code': 4987,
		'place name': 'Konini',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.5046,
		longitude: 175.7916,
		accuracy: 4,
	},
	'4988': {
		'country code': 'NZ',
		'postal code': 4988,
		'place name': 'Mangamaire',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.5172,
		longitude: 175.7526,
		accuracy: 4,
	},
	'4989': {
		'country code': 'NZ',
		'postal code': 4989,
		'place name': 'Makuri',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.5333,
		longitude: 176.0167,
		accuracy: 4,
	},
	'4990': {
		'country code': 'NZ',
		'postal code': 4990,
		'place name': 'Waimiro',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.45,
		longitude: 176.2333,
		accuracy: 4,
	},
	'4992': {
		'country code': 'NZ',
		'postal code': 4992,
		'place name': 'Waione',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4695,
		longitude: 176.2797,
		accuracy: 4,
	},
	'4993': {
		'country code': 'NZ',
		'postal code': 4993,
		'place name': 'Tane',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.5758,
		longitude: 175.8478,
		accuracy: 4,
	},
	'4994': {
		'country code': 'NZ',
		'postal code': 4994,
		'place name': 'Eketahuna',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.6462,
		longitude: 175.7037,
		accuracy: 4,
	},
	'4995': {
		'country code': 'NZ',
		'postal code': 4995,
		'place name': 'Nireaha',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.615,
		longitude: 175.6529,
		accuracy: 4,
	},
	'4996': {
		'country code': 'NZ',
		'postal code': 4996,
		'place name': 'Tiraumea',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.6333,
		longitude: 176.0456,
		accuracy: 4,
	},
	'4997': {
		'country code': 'NZ',
		'postal code': 4997,
		'place name': 'Hopelands',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3522,
		longitude: 175.9528,
		accuracy: 4,
	},
	'4998': {
		'country code': 'NZ',
		'postal code': 4998,
		'place name': 'Papatawa',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3174,
		longitude: 175.9286,
		accuracy: 4,
	},
	'4999': {
		'country code': 'NZ',
		'postal code': 4999,
		'place name': 'Woodville',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3372,
		longitude: 175.8668,
		accuracy: 4,
	},
	'5510': {
		'country code': 'NZ',
		'postal code': 5510,
		'place name': 'Levin',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.6333,
		longitude: 175.275,
		accuracy: 4,
	},
	'5540': {
		'country code': 'NZ',
		'postal code': 5540,
		'place name': 'Levin',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.6333,
		longitude: 175.275,
		accuracy: 4,
	},
	'5541': {
		'country code': 'NZ',
		'postal code': 5541,
		'place name': 'Manakau',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.7167,
		longitude: 175.2167,
		accuracy: 4,
	},
	'5570': {
		'country code': 'NZ',
		'postal code': 5570,
		'place name': 'Ohau',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.6667,
		longitude: 175.25,
		accuracy: 4,
	},
	'5571': {
		'country code': 'NZ',
		'postal code': 5571,
		'place name': 'Levin',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.6333,
		longitude: 175.275,
		accuracy: 4,
	},
	'5572': {
		'country code': 'NZ',
		'postal code': 5572,
		'place name': 'Waitarere',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.55,
		longitude: 175.2,
		accuracy: 4,
	},
	'5573': {
		'country code': 'NZ',
		'postal code': 5573,
		'place name': 'Manakau',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.7167,
		longitude: 175.2167,
		accuracy: 4,
	},
	'5574': {
		'country code': 'NZ',
		'postal code': 5574,
		'place name': 'Waitarere',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.55,
		longitude: 175.2,
		accuracy: 4,
	},
	'5575': {
		'country code': 'NZ',
		'postal code': 5575,
		'place name': 'Koputaroa',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.5778,
		longitude: 175.3384,
		accuracy: 4,
	},
	'5581': {
		'country code': 'NZ',
		'postal code': 5581,
		'place name': 'Manakau',
		'admin name1': 'Manawatu-Wanganui',
		'admin code1': 'F3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.7167,
		longitude: 175.2167,
		accuracy: 4,
	},
	'7100': {
		'country code': 'NZ',
		'postal code': 7100,
		'place name': 'Havelock',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2833,
		longitude: 173.7667,
		accuracy: 4,
	},
	'7145': {
		'country code': 'NZ',
		'postal code': 7145,
		'place name': 'Rai Valley',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2333,
		longitude: 173.5833,
		accuracy: 4,
	},
	'7150': {
		'country code': 'NZ',
		'postal code': 7150,
		'place name': 'Havelock',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2833,
		longitude: 173.7667,
		accuracy: 4,
	},
	'7178': {
		'country code': 'NZ',
		'postal code': 7178,
		'place name': 'Havelock',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2833,
		longitude: 173.7667,
		accuracy: 4,
	},
	'7192': {
		'country code': 'NZ',
		'postal code': 7192,
		'place name': 'Tinline',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2667,
		longitude: 173.5,
		accuracy: 4,
	},
	'7193': {
		'country code': 'NZ',
		'postal code': 7193,
		'place name': 'Kapowai',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.9,
		longitude: 173.8333,
		accuracy: 4,
	},
	'7194': {
		'country code': 'NZ',
		'postal code': 7194,
		'place name': 'Rai Valley',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2333,
		longitude: 173.5833,
		accuracy: 4,
	},
	'7195': {
		'country code': 'NZ',
		'postal code': 7195,
		'place name': 'Tira-ora',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0833,
		longitude: 173.8667,
		accuracy: 4,
	},
	'7201': {
		'country code': 'NZ',
		'postal code': 7201,
		'place name': 'Witherlea',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.5283,
		longitude: 173.9502,
		accuracy: 4,
	},
	'7202': {
		'country code': 'NZ',
		'postal code': 7202,
		'place name': 'Grovetown',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.4833,
		longitude: 173.9667,
		accuracy: 4,
	},
	'7204': {
		'country code': 'NZ',
		'postal code': 7204,
		'place name': 'Renwick',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.5,
		longitude: 173.8333,
		accuracy: 4,
	},
	'7206': {
		'country code': 'NZ',
		'postal code': 7206,
		'place name': 'Renwick',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.5,
		longitude: 173.8333,
		accuracy: 4,
	},
	'7210': {
		'country code': 'NZ',
		'postal code': 7210,
		'place name': 'Seddon',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.6667,
		longitude: 174.0833,
		accuracy: 4,
	},
	'7220': {
		'country code': 'NZ',
		'postal code': 7220,
		'place name': 'Picton',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2907,
		longitude: 174.008,
		accuracy: 4,
	},
	'7240': {
		'country code': 'NZ',
		'postal code': 7240,
		'place name': 'Witherlea',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.5283,
		longitude: 173.9502,
		accuracy: 4,
	},
	'7241': {
		'country code': 'NZ',
		'postal code': 7241,
		'place name': 'Blenheim',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.516,
		longitude: 173.9528,
		accuracy: 4,
	},
	'7242': {
		'country code': 'NZ',
		'postal code': 7242,
		'place name': 'Witherlea',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.5283,
		longitude: 173.9502,
		accuracy: 4,
	},
	'7243': {
		'country code': 'NZ',
		'postal code': 7243,
		'place name': 'Renwick',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.5,
		longitude: 173.8333,
		accuracy: 4,
	},
	'7244': {
		'country code': 'NZ',
		'postal code': 7244,
		'place name': 'Spring Creek',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.4667,
		longitude: 173.9667,
		accuracy: 4,
	},
	'7246': {
		'country code': 'NZ',
		'postal code': 7246,
		'place name': 'Tuamarina',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.4333,
		longitude: 173.95,
		accuracy: 4,
	},
	'7247': {
		'country code': 'NZ',
		'postal code': 7247,
		'place name': 'Seddon',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.6667,
		longitude: 174.0833,
		accuracy: 4,
	},
	'7248': {
		'country code': 'NZ',
		'postal code': 7248,
		'place name': 'Ward',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.8333,
		longitude: 174.1333,
		accuracy: 4,
	},
	'7250': {
		'country code': 'NZ',
		'postal code': 7250,
		'place name': 'Picton',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2907,
		longitude: 174.008,
		accuracy: 4,
	},
	'7251': {
		'country code': 'NZ',
		'postal code': 7251,
		'place name': 'Waikawa',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2667,
		longitude: 174.05,
		accuracy: 4,
	},
	'7271': {
		'country code': 'NZ',
		'postal code': 7271,
		'place name': 'Hillersden',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.6,
		longitude: 173.4333,
		accuracy: 4,
	},
	'7272': {
		'country code': 'NZ',
		'postal code': 7272,
		'place name': 'Renwick',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.5,
		longitude: 173.8333,
		accuracy: 4,
	},
	'7273': {
		'country code': 'NZ',
		'postal code': 7273,
		'place name': 'Tuamarina',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.4333,
		longitude: 173.95,
		accuracy: 4,
	},
	'7274': {
		'country code': 'NZ',
		'postal code': 7274,
		'place name': 'Upcot',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.9,
		longitude: 173.5833,
		accuracy: 4,
	},
	'7275': {
		'country code': 'NZ',
		'postal code': 7275,
		'place name': 'Te Rou',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.5667,
		longitude: 173.4667,
		accuracy: 4,
	},
	'7276': {
		'country code': 'NZ',
		'postal code': 7276,
		'place name': 'Avondale',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.6833,
		longitude: 173.6333,
		accuracy: 4,
	},
	'7281': {
		'country code': 'NZ',
		'postal code': 7281,
		'place name': 'Waikawa',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2667,
		longitude: 174.05,
		accuracy: 4,
	},
	'7282': {
		'country code': 'NZ',
		'postal code': 7282,
		'place name': 'Manaroa',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1167,
		longitude: 174.0333,
		accuracy: 4,
	},
	'7284': {
		'country code': 'NZ',
		'postal code': 7284,
		'place name': 'Whakatahuri',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0167,
		longitude: 174.0333,
		accuracy: 4,
	},
	'7285': {
		'country code': 'NZ',
		'postal code': 7285,
		'place name': 'Taimate',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.7833,
		longitude: 174.15,
		accuracy: 4,
	},
	'7371': {
		'country code': 'NZ',
		'postal code': 7371,
		'place name': 'Langridge',
		'admin name1': 'Marlborough',
		'admin code1': 'F4',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.9833,
		longitude: 173.4167,
		accuracy: 4,
	},
	'7010': {
		'country code': 'NZ',
		'postal code': 7010,
		'place name': 'Nelson East',
		'admin name1': 'Nelson',
		'admin code1': 'F5',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2766,
		longitude: 173.2998,
		accuracy: 4,
	},
	'7011': {
		'country code': 'NZ',
		'postal code': 7011,
		'place name': 'Stoke',
		'admin name1': 'Nelson',
		'admin code1': 'F5',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.3167,
		longitude: 173.2333,
		accuracy: 4,
	},
	'7040': {
		'country code': 'NZ',
		'postal code': 7040,
		'place name': 'Nelson East',
		'admin name1': 'Nelson',
		'admin code1': 'F5',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2766,
		longitude: 173.2998,
		accuracy: 4,
	},
	'7041': {
		'country code': 'NZ',
		'postal code': 7041,
		'place name': 'Stoke',
		'admin name1': 'Nelson',
		'admin code1': 'F5',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.3167,
		longitude: 173.2333,
		accuracy: 4,
	},
	'7042': {
		'country code': 'NZ',
		'postal code': 7042,
		'place name': 'Nelson South',
		'admin name1': 'Nelson',
		'admin code1': 'F5',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.285,
		longitude: 173.2749,
		accuracy: 4,
	},
	'7043': {
		'country code': 'NZ',
		'postal code': 7043,
		'place name': 'Port Nelson',
		'admin name1': 'Nelson',
		'admin code1': 'F5',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.264,
		longitude: 173.2763,
		accuracy: 4,
	},
	'7044': {
		'country code': 'NZ',
		'postal code': 7044,
		'place name': 'Wakatu',
		'admin name1': 'Nelson',
		'admin code1': 'F5',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2967,
		longitude: 173.244,
		accuracy: 4,
	},
	'7045': {
		'country code': 'NZ',
		'postal code': 7045,
		'place name': 'Nelson',
		'admin name1': 'Nelson',
		'admin code1': 'F5',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2708,
		longitude: 173.284,
		accuracy: 4,
	},
	'7046': {
		'country code': 'NZ',
		'postal code': 7046,
		'place name': 'Nelson South',
		'admin name1': 'Nelson',
		'admin code1': 'F5',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.285,
		longitude: 173.2749,
		accuracy: 4,
	},
	'7047': {
		'country code': 'NZ',
		'postal code': 7047,
		'place name': 'Nelson East',
		'admin name1': 'Nelson',
		'admin code1': 'F5',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2766,
		longitude: 173.2998,
		accuracy: 4,
	},
	'7071': {
		'country code': 'NZ',
		'postal code': 7071,
		'place name': 'Hira',
		'admin name1': 'Nelson',
		'admin code1': 'F5',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2153,
		longitude: 173.3961,
		accuracy: 4,
	},
	'0110': {
		'country code': 'NZ',
		'postal code': 110,
		'place name': 'Woodhill',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.7317,
		longitude: 174.3104,
		accuracy: 4,
	},
	'0112': {
		'country code': 'NZ',
		'postal code': 112,
		'place name': 'Whau Valley',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.6982,
		longitude: 174.3002,
		accuracy: 4,
	},
	'0114': {
		'country code': 'NZ',
		'postal code': 114,
		'place name': 'Waro',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.5833,
		longitude: 174.3,
		accuracy: 4,
	},
	'0116': {
		'country code': 'NZ',
		'postal code': 116,
		'place name': 'Ruakaka',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.8833,
		longitude: 174.4,
		accuracy: 4,
	},
	'0140': {
		'country code': 'NZ',
		'postal code': 140,
		'place name': 'Riverside',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.7234,
		longitude: 174.3258,
		accuracy: 4,
	},
	'0141': {
		'country code': 'NZ',
		'postal code': 141,
		'place name': 'Otangarei',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.6882,
		longitude: 174.3102,
		accuracy: 4,
	},
	'0142': {
		'country code': 'NZ',
		'postal code': 142,
		'place name': 'Sherwood Rise',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.7487,
		longitude: 174.3598,
		accuracy: 4,
	},
	'0143': {
		'country code': 'NZ',
		'postal code': 143,
		'place name': 'Morningside',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.7348,
		longitude: 174.3199,
		accuracy: 4,
	},
	'0144': {
		'country code': 'NZ',
		'postal code': 144,
		'place name': 'Tikipunga',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.6882,
		longitude: 174.3202,
		accuracy: 4,
	},
	'0145': {
		'country code': 'NZ',
		'postal code': 145,
		'place name': 'Kensington',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.7103,
		longitude: 174.3135,
		accuracy: 4,
	},
	'0146': {
		'country code': 'NZ',
		'postal code': 146,
		'place name': 'Maungakaramea',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.85,
		longitude: 174.2167,
		accuracy: 4,
	},
	'0147': {
		'country code': 'NZ',
		'postal code': 147,
		'place name': 'Woodhill',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.7317,
		longitude: 174.3104,
		accuracy: 4,
	},
	'0148': {
		'country code': 'NZ',
		'postal code': 148,
		'place name': 'Woodhill',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.7317,
		longitude: 174.3104,
		accuracy: 4,
	},
	'0149': {
		'country code': 'NZ',
		'postal code': 149,
		'place name': 'Riverside',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.7234,
		longitude: 174.3258,
		accuracy: 4,
	},
	'0150': {
		'country code': 'NZ',
		'postal code': 150,
		'place name': 'Hikurangi',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.6,
		longitude: 174.3,
		accuracy: 4,
	},
	'0151': {
		'country code': 'NZ',
		'postal code': 151,
		'place name': 'Ruakaka',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.8833,
		longitude: 174.4,
		accuracy: 4,
	},
	'0152': {
		'country code': 'NZ',
		'postal code': 152,
		'place name': 'Maungatapere',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.75,
		longitude: 174.2,
		accuracy: 4,
	},
	'0153': {
		'country code': 'NZ',
		'postal code': 153,
		'place name': 'Tutukaka',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.6,
		longitude: 174.5333,
		accuracy: 4,
	},
	'0154': {
		'country code': 'NZ',
		'postal code': 154,
		'place name': 'Ngunguru',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.6167,
		longitude: 174.5,
		accuracy: 4,
	},
	'0170': {
		'country code': 'NZ',
		'postal code': 170,
		'place name': 'Puwera',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.7833,
		longitude: 174.2833,
		accuracy: 4,
	},
	'0171': {
		'country code': 'NZ',
		'postal code': 171,
		'place name': 'Mata',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.85,
		longitude: 174.3667,
		accuracy: 4,
	},
	'0172': {
		'country code': 'NZ',
		'postal code': 172,
		'place name': 'Parakao',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.7167,
		longitude: 173.95,
		accuracy: 4,
	},
	'0173': {
		'country code': 'NZ',
		'postal code': 173,
		'place name': 'Kiripaka',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.6333,
		longitude: 174.4333,
		accuracy: 4,
	},
	'0174': {
		'country code': 'NZ',
		'postal code': 174,
		'place name': 'Whangarei Heads',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.8167,
		longitude: 174.5,
		accuracy: 4,
	},
	'0175': {
		'country code': 'NZ',
		'postal code': 175,
		'place name': 'Tahere',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.6833,
		longitude: 174.4333,
		accuracy: 4,
	},
	'0176': {
		'country code': 'NZ',
		'postal code': 176,
		'place name': 'Moengawahine',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.6333,
		longitude: 174.05,
		accuracy: 4,
	},
	'0178': {
		'country code': 'NZ',
		'postal code': 178,
		'place name': 'Maungakaramea',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.85,
		longitude: 174.2167,
		accuracy: 4,
	},
	'0179': {
		'country code': 'NZ',
		'postal code': 179,
		'place name': 'Maungatapere',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.75,
		longitude: 174.2,
		accuracy: 4,
	},
	'0181': {
		'country code': 'NZ',
		'postal code': 181,
		'place name': 'Marua',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.5667,
		longitude: 174.3667,
		accuracy: 4,
	},
	'0182': {
		'country code': 'NZ',
		'postal code': 182,
		'place name': 'Hukerenui',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.5167,
		longitude: 174.1833,
		accuracy: 4,
	},
	'0184': {
		'country code': 'NZ',
		'postal code': 184,
		'place name': 'Whangaruru North',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.35,
		longitude: 174.35,
		accuracy: 4,
	},
	'0185': {
		'country code': 'NZ',
		'postal code': 185,
		'place name': 'Ruatangata East',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.65,
		longitude: 174.2333,
		accuracy: 4,
	},
	'0192': {
		'country code': 'NZ',
		'postal code': 192,
		'place name': 'Rukuwai',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.75,
		longitude: 174.4667,
		accuracy: 4,
	},
	'0193': {
		'country code': 'NZ',
		'postal code': 193,
		'place name': 'Waiotira',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.9333,
		longitude: 174.2,
		accuracy: 4,
	},
	'0200': {
		'country code': 'NZ',
		'postal code': 200,
		'place name': 'Opua',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.3167,
		longitude: 174.1167,
		accuracy: 4,
	},
	'0202': {
		'country code': 'NZ',
		'postal code': 202,
		'place name': 'Russell',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.2615,
		longitude: 174.1226,
		accuracy: 4,
	},
	'0210': {
		'country code': 'NZ',
		'postal code': 210,
		'place name': 'Kawakawa',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.3833,
		longitude: 174.0667,
		accuracy: 4,
	},
	'0211': {
		'country code': 'NZ',
		'postal code': 211,
		'place name': 'Moerewa',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.3833,
		longitude: 174.0333,
		accuracy: 4,
	},
	'0230': {
		'country code': 'NZ',
		'postal code': 230,
		'place name': 'Kerikeri',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.2268,
		longitude: 173.9474,
		accuracy: 4,
	},
	'0241': {
		'country code': 'NZ',
		'postal code': 241,
		'place name': 'Opua',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.3167,
		longitude: 174.1167,
		accuracy: 4,
	},
	'0242': {
		'country code': 'NZ',
		'postal code': 242,
		'place name': 'Russell',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.2615,
		longitude: 174.1226,
		accuracy: 4,
	},
	'0243': {
		'country code': 'NZ',
		'postal code': 243,
		'place name': 'Kawakawa',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.3833,
		longitude: 174.0667,
		accuracy: 4,
	},
	'0244': {
		'country code': 'NZ',
		'postal code': 244,
		'place name': 'Moerewa',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.3833,
		longitude: 174.0333,
		accuracy: 4,
	},
	'0245': {
		'country code': 'NZ',
		'postal code': 245,
		'place name': 'Kerikeri',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.2268,
		longitude: 173.9474,
		accuracy: 4,
	},
	'0246': {
		'country code': 'NZ',
		'postal code': 246,
		'place name': 'Waipapa',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.2,
		longitude: 173.9167,
		accuracy: 4,
	},
	'0247': {
		'country code': 'NZ',
		'postal code': 247,
		'place name': 'Paihia',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.2807,
		longitude: 174.091,
		accuracy: 4,
	},
	'0271': {
		'country code': 'NZ',
		'postal code': 271,
		'place name': 'Haruru',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.2807,
		longitude: 174.0543,
		accuracy: 4,
	},
	'0272': {
		'country code': 'NZ',
		'postal code': 272,
		'place name': 'Manawaora',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.2833,
		longitude: 174.2,
		accuracy: 4,
	},
	'0281': {
		'country code': 'NZ',
		'postal code': 281,
		'place name': 'Pokere',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.4833,
		longitude: 173.9833,
		accuracy: 4,
	},
	'0282': {
		'country code': 'NZ',
		'postal code': 282,
		'place name': 'Karetu',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.3667,
		longitude: 174.15,
		accuracy: 4,
	},
	'0283': {
		'country code': 'NZ',
		'postal code': 283,
		'place name': 'Waihaha',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.3333,
		longitude: 174.2333,
		accuracy: 4,
	},
	'0293': {
		'country code': 'NZ',
		'postal code': 293,
		'place name': 'Puketona',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.3,
		longitude: 173.9667,
		accuracy: 4,
	},
	'0294': {
		'country code': 'NZ',
		'postal code': 294,
		'place name': 'Purerua',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.15,
		longitude: 174.0333,
		accuracy: 4,
	},
	'0295': {
		'country code': 'NZ',
		'postal code': 295,
		'place name': 'Waipapa',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.2,
		longitude: 173.9167,
		accuracy: 4,
	},
	'0310': {
		'country code': 'NZ',
		'postal code': 310,
		'place name': 'Dargaville',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.9333,
		longitude: 173.8833,
		accuracy: 4,
	},
	'0340': {
		'country code': 'NZ',
		'postal code': 340,
		'place name': 'Dargaville',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.9333,
		longitude: 173.8833,
		accuracy: 4,
	},
	'0341': {
		'country code': 'NZ',
		'postal code': 341,
		'place name': 'Te Kopura',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.0333,
		longitude: 173.9333,
		accuracy: 4,
	},
	'0370': {
		'country code': 'NZ',
		'postal code': 370,
		'place name': 'Arapohue',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.9833,
		longitude: 173.9667,
		accuracy: 4,
	},
	'0371': {
		'country code': 'NZ',
		'postal code': 371,
		'place name': 'Redhill',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.0333,
		longitude: 173.85,
		accuracy: 4,
	},
	'0372': {
		'country code': 'NZ',
		'postal code': 372,
		'place name': 'Kairara',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.8,
		longitude: 173.8167,
		accuracy: 4,
	},
	'0373': {
		'country code': 'NZ',
		'postal code': 373,
		'place name': 'Mamaranui',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.8333,
		longitude: 173.75,
		accuracy: 4,
	},
	'0374': {
		'country code': 'NZ',
		'postal code': 374,
		'place name': 'Arapohue',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.9833,
		longitude: 173.9667,
		accuracy: 4,
	},
	'0376': {
		'country code': 'NZ',
		'postal code': 376,
		'place name': 'Tutamoe',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.65,
		longitude: 173.65,
		accuracy: 4,
	},
	'0377': {
		'country code': 'NZ',
		'postal code': 377,
		'place name': 'Baylys Beach',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.9523,
		longitude: 173.747,
		accuracy: 4,
	},
	'0379': {
		'country code': 'NZ',
		'postal code': 379,
		'place name': 'Whatoro',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.75,
		longitude: 173.6833,
		accuracy: 4,
	},
	'0381': {
		'country code': 'NZ',
		'postal code': 381,
		'place name': 'Kirikopuni',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.8167,
		longitude: 174.0167,
		accuracy: 4,
	},
	'0391': {
		'country code': 'NZ',
		'postal code': 391,
		'place name': 'Tangaihe',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1667,
		longitude: 174.0167,
		accuracy: 4,
	},
	'0405': {
		'country code': 'NZ',
		'postal code': 405,
		'place name': 'Kaikohe',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.3982,
		longitude: 173.8002,
		accuracy: 4,
	},
	'0410': {
		'country code': 'NZ',
		'postal code': 410,
		'place name': 'Kaitaia',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.1149,
		longitude: 173.2637,
		accuracy: 4,
	},
	'0420': {
		'country code': 'NZ',
		'postal code': 420,
		'place name': 'Taipa',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -34.996,
		longitude: 173.4666,
		accuracy: 4,
	},
	'0440': {
		'country code': 'NZ',
		'postal code': 440,
		'place name': 'Kaikohe',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.3982,
		longitude: 173.8002,
		accuracy: 4,
	},
	'0441': {
		'country code': 'NZ',
		'postal code': 441,
		'place name': 'Kaitaia',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.1149,
		longitude: 173.2637,
		accuracy: 4,
	},
	'0442': {
		'country code': 'NZ',
		'postal code': 442,
		'place name': 'Mangonui',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -34.9905,
		longitude: 173.5317,
		accuracy: 4,
	},
	'0443': {
		'country code': 'NZ',
		'postal code': 443,
		'place name': 'Rawene',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.4022,
		longitude: 173.5032,
		accuracy: 4,
	},
	'0444': {
		'country code': 'NZ',
		'postal code': 444,
		'place name': 'Omapere',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.5333,
		longitude: 173.3833,
		accuracy: 4,
	},
	'0445': {
		'country code': 'NZ',
		'postal code': 445,
		'place name': 'Opononi',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.5,
		longitude: 173.4,
		accuracy: 4,
	},
	'0446': {
		'country code': 'NZ',
		'postal code': 446,
		'place name': 'Waimamaku',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.5563,
		longitude: 173.4782,
		accuracy: 4,
	},
	'0447': {
		'country code': 'NZ',
		'postal code': 447,
		'place name': 'Okaihau',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.3167,
		longitude: 173.7833,
		accuracy: 4,
	},
	'0448': {
		'country code': 'NZ',
		'postal code': 448,
		'place name': 'Kaeo',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.1,
		longitude: 173.7833,
		accuracy: 4,
	},
	'0449': {
		'country code': 'NZ',
		'postal code': 449,
		'place name': 'Ahipara',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.1667,
		longitude: 173.1667,
		accuracy: 4,
	},
	'0451': {
		'country code': 'NZ',
		'postal code': 451,
		'place name': 'Awanui',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.05,
		longitude: 173.25,
		accuracy: 4,
	},
	'0453': {
		'country code': 'NZ',
		'postal code': 453,
		'place name': 'Kohukohu',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.35,
		longitude: 173.5333,
		accuracy: 4,
	},
	'0460': {
		'country code': 'NZ',
		'postal code': 460,
		'place name': 'Pukenui',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -34.8167,
		longitude: 173.1167,
		accuracy: 4,
	},
	'0462': {
		'country code': 'NZ',
		'postal code': 462,
		'place name': 'Broadwood',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.2667,
		longitude: 173.3833,
		accuracy: 4,
	},
	'0472': {
		'country code': 'NZ',
		'postal code': 472,
		'place name': 'Pakaraka',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.35,
		longitude: 173.95,
		accuracy: 4,
	},
	'0473': {
		'country code': 'NZ',
		'postal code': 473,
		'place name': 'Waima',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.4833,
		longitude: 173.5833,
		accuracy: 4,
	},
	'0474': {
		'country code': 'NZ',
		'postal code': 474,
		'place name': 'Tautoro',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.4667,
		longitude: 173.8333,
		accuracy: 4,
	},
	'0475': {
		'country code': 'NZ',
		'postal code': 475,
		'place name': 'Marangai',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.3333,
		longitude: 173.7167,
		accuracy: 4,
	},
	'0476': {
		'country code': 'NZ',
		'postal code': 476,
		'place name': 'Umawera',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.2833,
		longitude: 173.5833,
		accuracy: 4,
	},
	'0478': {
		'country code': 'NZ',
		'postal code': 478,
		'place name': 'Kaeo',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.1,
		longitude: 173.7833,
		accuracy: 4,
	},
	'0479': {
		'country code': 'NZ',
		'postal code': 479,
		'place name': 'Saies',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.05,
		longitude: 173.7,
		accuracy: 4,
	},
	'0481': {
		'country code': 'NZ',
		'postal code': 481,
		'place name': 'Manukau',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.2333,
		longitude: 173.2167,
		accuracy: 4,
	},
	'0482': {
		'country code': 'NZ',
		'postal code': 482,
		'place name': 'Kaiaka',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.1,
		longitude: 173.4167,
		accuracy: 4,
	},
	'0483': {
		'country code': 'NZ',
		'postal code': 483,
		'place name': 'Lake Ohia',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -34.9833,
		longitude: 173.3667,
		accuracy: 4,
	},
	'0484': {
		'country code': 'NZ',
		'postal code': 484,
		'place name': 'Te Kao',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -34.65,
		longitude: 172.95,
		accuracy: 4,
	},
	'0486': {
		'country code': 'NZ',
		'postal code': 486,
		'place name': 'Waiharera',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -34.9333,
		longitude: 173.2167,
		accuracy: 4,
	},
	'0491': {
		'country code': 'NZ',
		'postal code': 491,
		'place name': 'Broadwood',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.2667,
		longitude: 173.3833,
		accuracy: 4,
	},
	'0492': {
		'country code': 'NZ',
		'postal code': 492,
		'place name': 'Panguru',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.3833,
		longitude: 173.3833,
		accuracy: 4,
	},
	'0494': {
		'country code': 'NZ',
		'postal code': 494,
		'place name': 'Paraoanui',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.05,
		longitude: 173.6167,
		accuracy: 4,
	},
	'0496': {
		'country code': 'NZ',
		'postal code': 496,
		'place name': 'Pawarenga',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.35,
		longitude: 173.25,
		accuracy: 4,
	},
	'0505': {
		'country code': 'NZ',
		'postal code': 505,
		'place name': 'Mangawhai Heads',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.0882,
		longitude: 174.5702,
		accuracy: 4,
	},
	'0510': {
		'country code': 'NZ',
		'postal code': 510,
		'place name': 'Waipu',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.9833,
		longitude: 174.45,
		accuracy: 4,
	},
	'0520': {
		'country code': 'NZ',
		'postal code': 520,
		'place name': 'Rowsells',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1,
		longitude: 174.35,
		accuracy: 4,
	},
	'0530': {
		'country code': 'NZ',
		'postal code': 530,
		'place name': 'Ruawai',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1333,
		longitude: 174.0333,
		accuracy: 4,
	},
	'0540': {
		'country code': 'NZ',
		'postal code': 540,
		'place name': 'Mangawhai',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1167,
		longitude: 174.5667,
		accuracy: 4,
	},
	'0541': {
		'country code': 'NZ',
		'postal code': 541,
		'place name': 'Mangawhai Heads',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.0882,
		longitude: 174.5702,
		accuracy: 4,
	},
	'0542': {
		'country code': 'NZ',
		'postal code': 542,
		'place name': 'Kaiwaka',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1667,
		longitude: 174.45,
		accuracy: 4,
	},
	'0543': {
		'country code': 'NZ',
		'postal code': 543,
		'place name': 'Paparoa',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1,
		longitude: 174.2333,
		accuracy: 4,
	},
	'0545': {
		'country code': 'NZ',
		'postal code': 545,
		'place name': 'Waipu',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.9833,
		longitude: 174.45,
		accuracy: 4,
	},
	'0547': {
		'country code': 'NZ',
		'postal code': 547,
		'place name': 'Rowsells',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1,
		longitude: 174.35,
		accuracy: 4,
	},
	'0549': {
		'country code': 'NZ',
		'postal code': 549,
		'place name': 'Ruawai',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1333,
		longitude: 174.0333,
		accuracy: 4,
	},
	'0550': {
		'country code': 'NZ',
		'postal code': 550,
		'place name': 'Matakohe',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1333,
		longitude: 174.1833,
		accuracy: 4,
	},
	'0571': {
		'country code': 'NZ',
		'postal code': 571,
		'place name': 'Mareretu',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.0333,
		longitude: 174.2667,
		accuracy: 4,
	},
	'0573': {
		'country code': 'NZ',
		'postal code': 573,
		'place name': 'Kaiwaka',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1667,
		longitude: 174.45,
		accuracy: 4,
	},
	'0582': {
		'country code': 'NZ',
		'postal code': 582,
		'place name': 'Waipu',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -35.9833,
		longitude: 174.45,
		accuracy: 4,
	},
	'0583': {
		'country code': 'NZ',
		'postal code': 583,
		'place name': 'Marohemo',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1833,
		longitude: 174.3,
		accuracy: 4,
	},
	'0587': {
		'country code': 'NZ',
		'postal code': 587,
		'place name': 'Ranganui',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.15,
		longitude: 174.4,
		accuracy: 4,
	},
	'0588': {
		'country code': 'NZ',
		'postal code': 588,
		'place name': 'Maungaturoto',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1,
		longitude: 174.3667,
		accuracy: 4,
	},
	'0589': {
		'country code': 'NZ',
		'postal code': 589,
		'place name': 'Huarau',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1,
		longitude: 174.3,
		accuracy: 4,
	},
	'0591': {
		'country code': 'NZ',
		'postal code': 591,
		'place name': 'Ruawai',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1333,
		longitude: 174.0333,
		accuracy: 4,
	},
	'0592': {
		'country code': 'NZ',
		'postal code': 592,
		'place name': 'Rehia',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.0667,
		longitude: 174.0333,
		accuracy: 4,
	},
	'0593': {
		'country code': 'NZ',
		'postal code': 593,
		'place name': 'Pahi',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1572,
		longitude: 174.2283,
		accuracy: 4,
	},
	'0594': {
		'country code': 'NZ',
		'postal code': 594,
		'place name': 'Matakohe',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.1333,
		longitude: 174.1833,
		accuracy: 4,
	},
	'0975': {
		'country code': 'NZ',
		'postal code': 975,
		'place name': 'Topuni',
		'admin name1': 'Northland',
		'admin code1': 'F6',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.2167,
		longitude: 174.4667,
		accuracy: 4,
	},
	'9010': {
		'country code': 'NZ',
		'postal code': 9010,
		'place name': 'Liberton',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8384,
		longitude: 170.5101,
		accuracy: 4,
	},
	'9011': {
		'country code': 'NZ',
		'postal code': 9011,
		'place name': 'Mornington',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8833,
		longitude: 170.4667,
		accuracy: 4,
	},
	'9012': {
		'country code': 'NZ',
		'postal code': 9012,
		'place name': 'Saint Kilda',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8984,
		longitude: 170.4901,
		accuracy: 4,
	},
	'9013': {
		'country code': 'NZ',
		'postal code': 9013,
		'place name': 'Ocean Grove',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9,
		longitude: 170.55,
		accuracy: 4,
	},
	'9014': {
		'country code': 'NZ',
		'postal code': 9014,
		'place name': 'Broad Bay',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.85,
		longitude: 170.6167,
		accuracy: 4,
	},
	'9016': {
		'country code': 'NZ',
		'postal code': 9016,
		'place name': 'Waverley',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8784,
		longitude: 170.5201,
		accuracy: 4,
	},
	'9018': {
		'country code': 'NZ',
		'postal code': 9018,
		'place name': 'Sunnyvale',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8984,
		longitude: 170.4101,
		accuracy: 4,
	},
	'9019': {
		'country code': 'NZ',
		'postal code': 9019,
		'place name': 'Outram',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8667,
		longitude: 170.2333,
		accuracy: 4,
	},
	'9022': {
		'country code': 'NZ',
		'postal code': 9022,
		'place name': 'Maia',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8584,
		longitude: 170.5501,
		accuracy: 4,
	},
	'9023': {
		'country code': 'NZ',
		'postal code': 9023,
		'place name': 'Sawyers Bay',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8333,
		longitude: 170.6,
		accuracy: 4,
	},
	'9024': {
		'country code': 'NZ',
		'postal code': 9024,
		'place name': 'Mosgiel',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.875,
		longitude: 170.3472,
		accuracy: 4,
	},
	'9035': {
		'country code': 'NZ',
		'postal code': 9035,
		'place name': 'Brighton',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.95,
		longitude: 170.3333,
		accuracy: 4,
	},
	'9040': {
		'country code': 'NZ',
		'postal code': 9040,
		'place name': 'Maryhill',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8784,
		longitude: 170.4801,
		accuracy: 4,
	},
	'9041': {
		'country code': 'NZ',
		'postal code': 9041,
		'place name': 'Opoho',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8484,
		longitude: 170.5201,
		accuracy: 4,
	},
	'9042': {
		'country code': 'NZ',
		'postal code': 9042,
		'place name': 'Kaikorai',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8584,
		longitude: 170.4701,
		accuracy: 4,
	},
	'9043': {
		'country code': 'NZ',
		'postal code': 9043,
		'place name': 'North Dunedin',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8584,
		longitude: 170.5101,
		accuracy: 4,
	},
	'9044': {
		'country code': 'NZ',
		'postal code': 9044,
		'place name': 'Saint Kilda',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9,
		longitude: 170.5194,
		accuracy: 4,
	},
	'9045': {
		'country code': 'NZ',
		'postal code': 9045,
		'place name': 'Caversham',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9,
		longitude: 170.4833,
		accuracy: 4,
	},
	'9046': {
		'country code': 'NZ',
		'postal code': 9046,
		'place name': 'Tainui',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8984,
		longitude: 170.5101,
		accuracy: 4,
	},
	'9047': {
		'country code': 'NZ',
		'postal code': 9047,
		'place name': 'Saint Clair',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9167,
		longitude: 170.4833,
		accuracy: 4,
	},
	'9048': {
		'country code': 'NZ',
		'postal code': 9048,
		'place name': 'Portobello',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.85,
		longitude: 170.65,
		accuracy: 4,
	},
	'9049': {
		'country code': 'NZ',
		'postal code': 9049,
		'place name': 'Saint Kilda',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9,
		longitude: 170.5194,
		accuracy: 4,
	},
	'9050': {
		'country code': 'NZ',
		'postal code': 9050,
		'place name': 'Port Chalmers',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8159,
		longitude: 170.621,
		accuracy: 4,
	},
	'9052': {
		'country code': 'NZ',
		'postal code': 9052,
		'place name': 'Green Island',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8884,
		longitude: 170.4301,
		accuracy: 4,
	},
	'9053': {
		'country code': 'NZ',
		'postal code': 9053,
		'place name': 'Mosgiel',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.875,
		longitude: 170.3472,
		accuracy: 4,
	},
	'9054': {
		'country code': 'NZ',
		'postal code': 9054,
		'place name': 'Dunedin',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8742,
		longitude: 170.5036,
		accuracy: 4,
	},
	'9058': {
		'country code': 'NZ',
		'postal code': 9058,
		'place name': 'Dunedin',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8742,
		longitude: 170.5036,
		accuracy: 4,
	},
	'9059': {
		'country code': 'NZ',
		'postal code': 9059,
		'place name': 'Dunedin',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8742,
		longitude: 170.5036,
		accuracy: 4,
	},
	'9062': {
		'country code': 'NZ',
		'postal code': 9062,
		'place name': 'Outram',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8667,
		longitude: 170.2333,
		accuracy: 4,
	},
	'9067': {
		'country code': 'NZ',
		'postal code': 9067,
		'place name': 'Middlemarch',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.5167,
		longitude: 170.1167,
		accuracy: 4,
	},
	'9069': {
		'country code': 'NZ',
		'postal code': 9069,
		'place name': 'Waitati',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.75,
		longitude: 170.5667,
		accuracy: 4,
	},
	'9073': {
		'country code': 'NZ',
		'postal code': 9073,
		'place name': 'Berwick',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.95,
		longitude: 170.1,
		accuracy: 4,
	},
	'9074': {
		'country code': 'NZ',
		'postal code': 9074,
		'place name': 'Clarks',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.7333,
		longitude: 170.05,
		accuracy: 4,
	},
	'9076': {
		'country code': 'NZ',
		'postal code': 9076,
		'place name': 'Wingatui',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8833,
		longitude: 170.3833,
		accuracy: 4,
	},
	'9077': {
		'country code': 'NZ',
		'postal code': 9077,
		'place name': 'Portobello',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.85,
		longitude: 170.65,
		accuracy: 4,
	},
	'9081': {
		'country code': 'NZ',
		'postal code': 9081,
		'place name': 'Mihiwaka',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8,
		longitude: 170.6333,
		accuracy: 4,
	},
	'9082': {
		'country code': 'NZ',
		'postal code': 9082,
		'place name': 'Aramoana',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.7833,
		longitude: 170.7,
		accuracy: 4,
	},
	'9085': {
		'country code': 'NZ',
		'postal code': 9085,
		'place name': 'Mount Cargill',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8,
		longitude: 170.5667,
		accuracy: 4,
	},
	'9091': {
		'country code': 'NZ',
		'postal code': 9091,
		'place name': 'Kuri Bush',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.0247,
		longitude: 170.2297,
		accuracy: 4,
	},
	'9092': {
		'country code': 'NZ',
		'postal code': 9092,
		'place name': 'Mosgiel',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.875,
		longitude: 170.3472,
		accuracy: 4,
	},
	'9210': {
		'country code': 'NZ',
		'postal code': 9210,
		'place name': 'Kaitangata',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.275,
		longitude: 169.85,
		accuracy: 4,
	},
	'9220': {
		'country code': 'NZ',
		'postal code': 9220,
		'place name': 'Milton',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.1208,
		longitude: 169.9694,
		accuracy: 4,
	},
	'9230': {
		'country code': 'NZ',
		'postal code': 9230,
		'place name': 'Balclutha',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.2339,
		longitude: 169.75,
		accuracy: 4,
	},
	'9231': {
		'country code': 'NZ',
		'postal code': 9231,
		'place name': 'Stirling',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.25,
		longitude: 169.7833,
		accuracy: 4,
	},
	'9240': {
		'country code': 'NZ',
		'postal code': 9240,
		'place name': 'Balclutha',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.2339,
		longitude: 169.75,
		accuracy: 4,
	},
	'9241': {
		'country code': 'NZ',
		'postal code': 9241,
		'place name': 'Milton',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.1208,
		longitude: 169.9694,
		accuracy: 4,
	},
	'9242': {
		'country code': 'NZ',
		'postal code': 9242,
		'place name': 'Clydevale',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.1,
		longitude: 169.5333,
		accuracy: 4,
	},
	'9271': {
		'country code': 'NZ',
		'postal code': 9271,
		'place name': 'Glenomaru',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.3833,
		longitude: 169.6833,
		accuracy: 4,
	},
	'9272': {
		'country code': 'NZ',
		'postal code': 9272,
		'place name': 'Hillend',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.1333,
		longitude: 169.75,
		accuracy: 4,
	},
	'9273': {
		'country code': 'NZ',
		'postal code': 9273,
		'place name': 'Warepa',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.25,
		longitude: 169.5833,
		accuracy: 4,
	},
	'9274': {
		'country code': 'NZ',
		'postal code': 9274,
		'place name': 'Greenfield',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.1,
		longitude: 169.5833,
		accuracy: 4,
	},
	'9281': {
		'country code': 'NZ',
		'postal code': 9281,
		'place name': 'Kaitangata',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.275,
		longitude: 169.85,
		accuracy: 4,
	},
	'9282': {
		'country code': 'NZ',
		'postal code': 9282,
		'place name': 'Wangaloa',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.2833,
		longitude: 169.9167,
		accuracy: 4,
	},
	'9291': {
		'country code': 'NZ',
		'postal code': 9291,
		'place name': 'Milburn',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.0833,
		longitude: 170,
		accuracy: 4,
	},
	'9292': {
		'country code': 'NZ',
		'postal code': 9292,
		'place name': 'Tokoiti',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.1333,
		longitude: 170,
		accuracy: 4,
	},
	'9300': {
		'country code': 'NZ',
		'postal code': 9300,
		'place name': 'Frankton',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.0212,
		longitude: 168.7336,
		accuracy: 4,
	},
	'9302': {
		'country code': 'NZ',
		'postal code': 9302,
		'place name': 'Arrowtown',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.9384,
		longitude: 168.8101,
		accuracy: 4,
	},
	'9304': {
		'country code': 'NZ',
		'postal code': 9304,
		'place name': 'Lower Shotover',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.9833,
		longitude: 168.7667,
		accuracy: 4,
	},
	'9305': {
		'country code': 'NZ',
		'postal code': 9305,
		'place name': 'Wanaka',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.7,
		longitude: 169.15,
		accuracy: 4,
	},
	'9310': {
		'country code': 'NZ',
		'postal code': 9310,
		'place name': 'Cromwell',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.0384,
		longitude: 169.2001,
		accuracy: 4,
	},
	'9320': {
		'country code': 'NZ',
		'postal code': 9320,
		'place name': 'Bridge Hill',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.2584,
		longitude: 169.3801,
		accuracy: 4,
	},
	'9330': {
		'country code': 'NZ',
		'postal code': 9330,
		'place name': 'Clyde',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.1865,
		longitude: 169.3154,
		accuracy: 4,
	},
	'9332': {
		'country code': 'NZ',
		'postal code': 9332,
		'place name': 'Ranfurly',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.1333,
		longitude: 170.1,
		accuracy: 4,
	},
	'9340': {
		'country code': 'NZ',
		'postal code': 9340,
		'place name': 'Bridge Hill',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.2584,
		longitude: 169.3801,
		accuracy: 4,
	},
	'9341': {
		'country code': 'NZ',
		'postal code': 9341,
		'place name': 'Clyde',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.1865,
		longitude: 169.3154,
		accuracy: 4,
	},
	'9342': {
		'country code': 'NZ',
		'postal code': 9342,
		'place name': 'Cromwell',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.0384,
		longitude: 169.2001,
		accuracy: 4,
	},
	'9343': {
		'country code': 'NZ',
		'postal code': 9343,
		'place name': 'Wanaka',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.7,
		longitude: 169.15,
		accuracy: 4,
	},
	'9344': {
		'country code': 'NZ',
		'postal code': 9344,
		'place name': 'Albert Town',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.6833,
		longitude: 169.2,
		accuracy: 4,
	},
	'9345': {
		'country code': 'NZ',
		'postal code': 9345,
		'place name': 'Lake Hawea',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.6084,
		longitude: 169.2601,
		accuracy: 4,
	},
	'9346': {
		'country code': 'NZ',
		'postal code': 9346,
		'place name': 'Makarora',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.2333,
		longitude: 169.2333,
		accuracy: 4,
	},
	'9347': {
		'country code': 'NZ',
		'postal code': 9347,
		'place name': 'Tarras',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.8333,
		longitude: 169.4167,
		accuracy: 4,
	},
	'9348': {
		'country code': 'NZ',
		'postal code': 9348,
		'place name': 'Queenstown',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.0302,
		longitude: 168.6627,
		accuracy: 4,
	},
	'9349': {
		'country code': 'NZ',
		'postal code': 9349,
		'place name': 'Frankton',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.0212,
		longitude: 168.7336,
		accuracy: 4,
	},
	'9350': {
		'country code': 'NZ',
		'postal code': 9350,
		'place name': 'Glenorchy',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.8504,
		longitude: 168.3866,
		accuracy: 4,
	},
	'9351': {
		'country code': 'NZ',
		'postal code': 9351,
		'place name': 'Arrowtown',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.9384,
		longitude: 168.8101,
		accuracy: 4,
	},
	'9352': {
		'country code': 'NZ',
		'postal code': 9352,
		'place name': 'Omakau',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.0833,
		longitude: 169.6,
		accuracy: 4,
	},
	'9353': {
		'country code': 'NZ',
		'postal code': 9353,
		'place name': 'Ranfurly',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.1333,
		longitude: 170.1,
		accuracy: 4,
	},
	'9354': {
		'country code': 'NZ',
		'postal code': 9354,
		'place name': 'Naseby',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.0184,
		longitude: 170.1301,
		accuracy: 4,
	},
	'9355': {
		'country code': 'NZ',
		'postal code': 9355,
		'place name': 'Wedderburn',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.0333,
		longitude: 170.0167,
		accuracy: 4,
	},
	'9371': {
		'country code': 'NZ',
		'postal code': 9371,
		'place name': 'Upper Shotover',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.8333,
		longitude: 168.7167,
		accuracy: 4,
	},
	'9372': {
		'country code': 'NZ',
		'postal code': 9372,
		'place name': 'Glenorchy',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.8504,
		longitude: 168.3866,
		accuracy: 4,
	},
	'9376': {
		'country code': 'NZ',
		'postal code': 9376,
		'place name': 'Matakanui',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.0167,
		longitude: 169.5667,
		accuracy: 4,
	},
	'9377': {
		'country code': 'NZ',
		'postal code': 9377,
		'place name': 'Becks',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45,
		longitude: 169.7333,
		accuracy: 4,
	},
	'9381': {
		'country code': 'NZ',
		'postal code': 9381,
		'place name': 'Glendhu',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.6667,
		longitude: 169.0167,
		accuracy: 4,
	},
	'9382': {
		'country code': 'NZ',
		'postal code': 9382,
		'place name': 'Makarora Wharf',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.3167,
		longitude: 169.1833,
		accuracy: 4,
	},
	'9383': {
		'country code': 'NZ',
		'postal code': 9383,
		'place name': 'Lindis Crossing',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.8833,
		longitude: 169.3833,
		accuracy: 4,
	},
	'9384': {
		'country code': 'NZ',
		'postal code': 9384,
		'place name': 'Gibbston',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.0333,
		longitude: 168.9667,
		accuracy: 4,
	},
	'9386': {
		'country code': 'NZ',
		'postal code': 9386,
		'place name': 'Saint Bathans',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.8667,
		longitude: 169.8167,
		accuracy: 4,
	},
	'9387': {
		'country code': 'NZ',
		'postal code': 9387,
		'place name': 'Moa Creek',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.2,
		longitude: 169.65,
		accuracy: 4,
	},
	'9391': {
		'country code': 'NZ',
		'postal code': 9391,
		'place name': 'Clyde',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.1865,
		longitude: 169.3154,
		accuracy: 4,
	},
	'9392': {
		'country code': 'NZ',
		'postal code': 9392,
		'place name': 'Fruitlands',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.35,
		longitude: 169.3,
		accuracy: 4,
	},
	'9393': {
		'country code': 'NZ',
		'postal code': 9393,
		'place name': 'Chatto Creek',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.1333,
		longitude: 169.5167,
		accuracy: 4,
	},
	'9395': {
		'country code': 'NZ',
		'postal code': 9395,
		'place name': 'Gimmerburn',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.1667,
		longitude: 170,
		accuracy: 4,
	},
	'9396': {
		'country code': 'NZ',
		'postal code': 9396,
		'place name': 'Naseby',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.0184,
		longitude: 170.1301,
		accuracy: 4,
	},
	'9397': {
		'country code': 'NZ',
		'postal code': 9397,
		'place name': 'Kokonga',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.2167,
		longitude: 170.25,
		accuracy: 4,
	},
	'9398': {
		'country code': 'NZ',
		'postal code': 9398,
		'place name': 'Paerau',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.4167,
		longitude: 169.95,
		accuracy: 4,
	},
	'9400': {
		'country code': 'NZ',
		'postal code': 9400,
		'place name': 'Oamaru',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.0976,
		longitude: 170.9709,
		accuracy: 4,
	},
	'9401': {
		'country code': 'NZ',
		'postal code': 9401,
		'place name': 'Waiareka Junction',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.1,
		longitude: 170.9333,
		accuracy: 4,
	},
	'9410': {
		'country code': 'NZ',
		'postal code': 9410,
		'place name': 'Hampden',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.3184,
		longitude: 170.8101,
		accuracy: 4,
	},
	'9442': {
		'country code': 'NZ',
		'postal code': 9442,
		'place name': 'Hampden',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.3184,
		longitude: 170.8101,
		accuracy: 4,
	},
	'9443': {
		'country code': 'NZ',
		'postal code': 9443,
		'place name': 'Palmerston',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.4784,
		longitude: 170.7201,
		accuracy: 4,
	},
	'9444': {
		'country code': 'NZ',
		'postal code': 9444,
		'place name': 'Oamaru',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.0976,
		longitude: 170.9709,
		accuracy: 4,
	},
	'9447': {
		'country code': 'NZ',
		'postal code': 9447,
		'place name': 'Otematata',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.6078,
		longitude: 170.191,
		accuracy: 4,
	},
	'9471': {
		'country code': 'NZ',
		'postal code': 9471,
		'place name': 'Seacliff',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.6833,
		longitude: 170.6167,
		accuracy: 4,
	},
	'9472': {
		'country code': 'NZ',
		'postal code': 9472,
		'place name': 'Waikouaiti',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.5884,
		longitude: 170.6501,
		accuracy: 4,
	},
	'9481': {
		'country code': 'NZ',
		'postal code': 9481,
		'place name': 'Stoneburn',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.4333,
		longitude: 170.5667,
		accuracy: 4,
	},
	'9482': {
		'country code': 'NZ',
		'postal code': 9482,
		'place name': 'Katiki',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.4,
		longitude: 170.8333,
		accuracy: 4,
	},
	'9483': {
		'country code': 'NZ',
		'postal code': 9483,
		'place name': 'Macraes Flat',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.3833,
		longitude: 170.4333,
		accuracy: 4,
	},
	'9491': {
		'country code': 'NZ',
		'postal code': 9491,
		'place name': 'Tokarahi',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.95,
		longitude: 170.65,
		accuracy: 4,
	},
	'9492': {
		'country code': 'NZ',
		'postal code': 9492,
		'place name': 'Kauru Hill',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.1,
		longitude: 170.75,
		accuracy: 4,
	},
	'9493': {
		'country code': 'NZ',
		'postal code': 9493,
		'place name': 'Hilderthorpe',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.9833,
		longitude: 171.0667,
		accuracy: 4,
	},
	'9494': {
		'country code': 'NZ',
		'postal code': 9494,
		'place name': 'Island Cliff',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.95,
		longitude: 170.6667,
		accuracy: 4,
	},
	'9495': {
		'country code': 'NZ',
		'postal code': 9495,
		'place name': 'Herbert',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.2333,
		longitude: 170.7833,
		accuracy: 4,
	},
	'9500': {
		'country code': 'NZ',
		'postal code': 9500,
		'place name': 'Roxburgh',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.5406,
		longitude: 169.3147,
		accuracy: 4,
	},
	'9522': {
		'country code': 'NZ',
		'postal code': 9522,
		'place name': 'Tapanui',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9384,
		longitude: 169.2601,
		accuracy: 4,
	},
	'9532': {
		'country code': 'NZ',
		'postal code': 9532,
		'place name': 'Lawrence',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9084,
		longitude: 169.6901,
		accuracy: 4,
	},
	'9534': {
		'country code': 'NZ',
		'postal code': 9534,
		'place name': 'Clinton',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.2,
		longitude: 169.3667,
		accuracy: 4,
	},
	'9535': {
		'country code': 'NZ',
		'postal code': 9535,
		'place name': 'Owaka',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.45,
		longitude: 169.6667,
		accuracy: 4,
	},
	'9542': {
		'country code': 'NZ',
		'postal code': 9542,
		'place name': 'Tapanui',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9384,
		longitude: 169.2601,
		accuracy: 4,
	},
	'9543': {
		'country code': 'NZ',
		'postal code': 9543,
		'place name': 'Lawrence',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9084,
		longitude: 169.6901,
		accuracy: 4,
	},
	'9544': {
		'country code': 'NZ',
		'postal code': 9544,
		'place name': 'Millers Flat',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.6631,
		longitude: 169.4117,
		accuracy: 4,
	},
	'9546': {
		'country code': 'NZ',
		'postal code': 9546,
		'place name': 'Owaka',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.45,
		longitude: 169.6667,
		accuracy: 4,
	},
	'9548': {
		'country code': 'NZ',
		'postal code': 9548,
		'place name': 'Clinton',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.2,
		longitude: 169.3667,
		accuracy: 4,
	},
	'9571': {
		'country code': 'NZ',
		'postal code': 9571,
		'place name': 'Roxburgh Hydro',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.4803,
		longitude: 169.3128,
		accuracy: 4,
	},
	'9572': {
		'country code': 'NZ',
		'postal code': 9572,
		'place name': 'Millers Flat',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.6631,
		longitude: 169.4117,
		accuracy: 4,
	},
	'9583': {
		'country code': 'NZ',
		'postal code': 9583,
		'place name': 'Clinton',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.2,
		longitude: 169.3667,
		accuracy: 4,
	},
	'9584': {
		'country code': 'NZ',
		'postal code': 9584,
		'place name': 'Clinton',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.2,
		longitude: 169.3667,
		accuracy: 4,
	},
	'9585': {
		'country code': 'NZ',
		'postal code': 9585,
		'place name': 'New Haven',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.4667,
		longitude: 169.7167,
		accuracy: 4,
	},
	'9586': {
		'country code': 'NZ',
		'postal code': 9586,
		'place name': 'Puketiro',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.4833,
		longitude: 169.4833,
		accuracy: 4,
	},
	'9587': {
		'country code': 'NZ',
		'postal code': 9587,
		'place name': 'Park Hill',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8167,
		longitude: 169.2167,
		accuracy: 4,
	},
	'9588': {
		'country code': 'NZ',
		'postal code': 9588,
		'place name': 'Raes Junction',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.7833,
		longitude: 169.4667,
		accuracy: 4,
	},
	'9591': {
		'country code': 'NZ',
		'postal code': 9591,
		'place name': 'Beaumont',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8225,
		longitude: 169.5301,
		accuracy: 4,
	},
	'9593': {
		'country code': 'NZ',
		'postal code': 9593,
		'place name': 'Waitahuna',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9833,
		longitude: 169.7667,
		accuracy: 4,
	},
	'9596': {
		'country code': 'NZ',
		'postal code': 9596,
		'place name': 'Ngapuna',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.45,
		longitude: 170.1667,
		accuracy: 4,
	},
	'9597': {
		'country code': 'NZ',
		'postal code': 9597,
		'place name': 'Rocklands',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.6667,
		longitude: 169.9833,
		accuracy: 4,
	},
	'9598': {
		'country code': 'NZ',
		'postal code': 9598,
		'place name': 'Ngapuna',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.45,
		longitude: 170.1667,
		accuracy: 4,
	},
	'9748': {
		'country code': 'NZ',
		'postal code': 9748,
		'place name': 'Kingston',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.3333,
		longitude: 168.7,
		accuracy: 4,
	},
	'9771': {
		'country code': 'NZ',
		'postal code': 9771,
		'place name': 'Conical Hills',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.0667,
		longitude: 169.2333,
		accuracy: 4,
	},
	'9775': {
		'country code': 'NZ',
		'postal code': 9775,
		'place name': 'Waikoikoi',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.0167,
		longitude: 169.15,
		accuracy: 4,
	},
	'9793': {
		'country code': 'NZ',
		'postal code': 9793,
		'place name': 'Kingston',
		'admin name1': 'Otago',
		'admin code1': 'F7',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.3333,
		longitude: 168.7,
		accuracy: 4,
	},
	'9600': {
		'country code': 'NZ',
		'postal code': 9600,
		'place name': 'Te Anau',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.4167,
		longitude: 167.7167,
		accuracy: 4,
	},
	'9610': {
		'country code': 'NZ',
		'postal code': 9610,
		'place name': 'Otautau',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.15,
		longitude: 168,
		accuracy: 4,
	},
	'9620': {
		'country code': 'NZ',
		'postal code': 9620,
		'place name': 'Tuatapere',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.1333,
		longitude: 167.6833,
		accuracy: 4,
	},
	'9630': {
		'country code': 'NZ',
		'postal code': 9630,
		'place name': 'Nightcaps',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9667,
		longitude: 168.0333,
		accuracy: 4,
	},
	'9635': {
		'country code': 'NZ',
		'postal code': 9635,
		'place name': 'Ohai',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9167,
		longitude: 167.95,
		accuracy: 4,
	},
	'9640': {
		'country code': 'NZ',
		'postal code': 9640,
		'place name': 'Te Anau',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.4167,
		longitude: 167.7167,
		accuracy: 4,
	},
	'9641': {
		'country code': 'NZ',
		'postal code': 9641,
		'place name': 'Otautau',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.15,
		longitude: 168,
		accuracy: 4,
	},
	'9642': {
		'country code': 'NZ',
		'postal code': 9642,
		'place name': 'Tuatapere',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.1333,
		longitude: 167.6833,
		accuracy: 4,
	},
	'9643': {
		'country code': 'NZ',
		'postal code': 9643,
		'place name': 'Manapouri',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.5669,
		longitude: 167.6115,
		accuracy: 4,
	},
	'9644': {
		'country code': 'NZ',
		'postal code': 9644,
		'place name': 'Nightcaps',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9667,
		longitude: 168.0333,
		accuracy: 4,
	},
	'9645': {
		'country code': 'NZ',
		'postal code': 9645,
		'place name': 'Ohai',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9167,
		longitude: 167.95,
		accuracy: 4,
	},
	'9672': {
		'country code': 'NZ',
		'postal code': 9672,
		'place name': 'The Key',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.55,
		longitude: 167.9,
		accuracy: 4,
	},
	'9679': {
		'country code': 'NZ',
		'postal code': 9679,
		'place name': 'Te Anau',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.4167,
		longitude: 167.7167,
		accuracy: 4,
	},
	'9682': {
		'country code': 'NZ',
		'postal code': 9682,
		'place name': 'Eastern Bush',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9833,
		longitude: 167.7667,
		accuracy: 4,
	},
	'9683': {
		'country code': 'NZ',
		'postal code': 9683,
		'place name': 'Fairfax',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.2,
		longitude: 168.05,
		accuracy: 4,
	},
	'9689': {
		'country code': 'NZ',
		'postal code': 9689,
		'place name': 'Ngaro',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9,
		longitude: 167.9833,
		accuracy: 4,
	},
	'9691': {
		'country code': 'NZ',
		'postal code': 9691,
		'place name': 'Wairoto',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9833,
		longitude: 167.6,
		accuracy: 4,
	},
	'9710': {
		'country code': 'NZ',
		'postal code': 9710,
		'place name': 'East Gore',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.0884,
		longitude: 168.9501,
		accuracy: 4,
	},
	'9712': {
		'country code': 'NZ',
		'postal code': 9712,
		'place name': 'Mataura',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.1784,
		longitude: 168.8501,
		accuracy: 4,
	},
	'9720': {
		'country code': 'NZ',
		'postal code': 9720,
		'place name': 'Winton',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.15,
		longitude: 168.3333,
		accuracy: 4,
	},
	'9730': {
		'country code': 'NZ',
		'postal code': 9730,
		'place name': 'Lumsden',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.7333,
		longitude: 168.45,
		accuracy: 4,
	},
	'9740': {
		'country code': 'NZ',
		'postal code': 9740,
		'place name': 'East Gore',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.0884,
		longitude: 168.9501,
		accuracy: 4,
	},
	'9741': {
		'country code': 'NZ',
		'postal code': 9741,
		'place name': 'Winton',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.15,
		longitude: 168.3333,
		accuracy: 4,
	},
	'9742': {
		'country code': 'NZ',
		'postal code': 9742,
		'place name': 'Lumsden',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.7333,
		longitude: 168.45,
		accuracy: 4,
	},
	'9743': {
		'country code': 'NZ',
		'postal code': 9743,
		'place name': 'Waikaka',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9167,
		longitude: 169.0167,
		accuracy: 4,
	},
	'9744': {
		'country code': 'NZ',
		'postal code': 9744,
		'place name': 'Riversdale',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9,
		longitude: 168.75,
		accuracy: 4,
	},
	'9745': {
		'country code': 'NZ',
		'postal code': 9745,
		'place name': 'Waikaia',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.7333,
		longitude: 168.85,
		accuracy: 4,
	},
	'9746': {
		'country code': 'NZ',
		'postal code': 9746,
		'place name': 'Balfour',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8333,
		longitude: 168.5833,
		accuracy: 4,
	},
	'9747': {
		'country code': 'NZ',
		'postal code': 9747,
		'place name': 'Mossburn',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.6667,
		longitude: 168.25,
		accuracy: 4,
	},
	'9749': {
		'country code': 'NZ',
		'postal code': 9749,
		'place name': 'Athol',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.5167,
		longitude: 168.5833,
		accuracy: 4,
	},
	'9750': {
		'country code': 'NZ',
		'postal code': 9750,
		'place name': 'Garston',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.4667,
		longitude: 168.6833,
		accuracy: 4,
	},
	'9752': {
		'country code': 'NZ',
		'postal code': 9752,
		'place name': 'Dipton',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9,
		longitude: 168.3667,
		accuracy: 4,
	},
	'9772': {
		'country code': 'NZ',
		'postal code': 9772,
		'place name': 'Pukerau',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.1,
		longitude: 169.1,
		accuracy: 4,
	},
	'9773': {
		'country code': 'NZ',
		'postal code': 9773,
		'place name': 'Wendon Valley',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8833,
		longitude: 168.9667,
		accuracy: 4,
	},
	'9774': {
		'country code': 'NZ',
		'postal code': 9774,
		'place name': 'Mataura',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.1784,
		longitude: 168.8501,
		accuracy: 4,
	},
	'9776': {
		'country code': 'NZ',
		'postal code': 9776,
		'place name': 'Riversdale',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9,
		longitude: 168.75,
		accuracy: 4,
	},
	'9777': {
		'country code': 'NZ',
		'postal code': 9777,
		'place name': 'Waipounamu',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.85,
		longitude: 168.7667,
		accuracy: 4,
	},
	'9778': {
		'country code': 'NZ',
		'postal code': 9778,
		'place name': 'Garston',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.4667,
		longitude: 168.6833,
		accuracy: 4,
	},
	'9779': {
		'country code': 'NZ',
		'postal code': 9779,
		'place name': 'Balfour',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.8333,
		longitude: 168.5833,
		accuracy: 4,
	},
	'9781': {
		'country code': 'NZ',
		'postal code': 9781,
		'place name': 'Lochiel',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.2,
		longitude: 168.3333,
		accuracy: 4,
	},
	'9782': {
		'country code': 'NZ',
		'postal code': 9782,
		'place name': 'Otapiri Gorge',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.05,
		longitude: 168.45,
		accuracy: 4,
	},
	'9783': {
		'country code': 'NZ',
		'postal code': 9783,
		'place name': 'South Hillend',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.05,
		longitude: 168.25,
		accuracy: 4,
	},
	'9791': {
		'country code': 'NZ',
		'postal code': 9791,
		'place name': 'Dipton',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9,
		longitude: 168.3667,
		accuracy: 4,
	},
	'9792': {
		'country code': 'NZ',
		'postal code': 9792,
		'place name': 'Mossburn',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.6667,
		longitude: 168.25,
		accuracy: 4,
	},
	'9794': {
		'country code': 'NZ',
		'postal code': 9794,
		'place name': 'Lumsden',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.7333,
		longitude: 168.45,
		accuracy: 4,
	},
	'9810': {
		'country code': 'NZ',
		'postal code': 9810,
		'place name': 'Waverley',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.3884,
		longitude: 168.3801,
		accuracy: 4,
	},
	'9812': {
		'country code': 'NZ',
		'postal code': 9812,
		'place name': 'Tisbury',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.45,
		longitude: 168.4,
		accuracy: 4,
	},
	'9814': {
		'country code': 'NZ',
		'postal code': 9814,
		'place name': 'Bluff',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.6,
		longitude: 168.3333,
		accuracy: 4,
	},
	'9816': {
		'country code': 'NZ',
		'postal code': 9816,
		'place name': 'Wallacetown',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.3333,
		longitude: 168.2833,
		accuracy: 4,
	},
	'9818': {
		'country code': 'NZ',
		'postal code': 9818,
		'place name': 'Halfmoon Bay (Oban)',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.8989,
		longitude: 168.129,
		accuracy: 4,
	},
	'9822': {
		'country code': 'NZ',
		'postal code': 9822,
		'place name': 'Riverton',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.35,
		longitude: 168.0167,
		accuracy: 4,
	},
	'9825': {
		'country code': 'NZ',
		'postal code': 9825,
		'place name': 'Edendale',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.3167,
		longitude: 168.7833,
		accuracy: 4,
	},
	'9831': {
		'country code': 'NZ',
		'postal code': 9831,
		'place name': 'Wyndham',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.3333,
		longitude: 168.85,
		accuracy: 4,
	},
	'9840': {
		'country code': 'NZ',
		'postal code': 9840,
		'place name': 'Richmond',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.4084,
		longitude: 168.3601,
		accuracy: 4,
	},
	'9841': {
		'country code': 'NZ',
		'postal code': 9841,
		'place name': 'Glengarry',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.3984,
		longitude: 168.3701,
		accuracy: 4,
	},
	'9842': {
		'country code': 'NZ',
		'postal code': 9842,
		'place name': 'East End',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.6042,
		longitude: 168.3472,
		accuracy: 4,
	},
	'9843': {
		'country code': 'NZ',
		'postal code': 9843,
		'place name': 'Waikiwi',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.3833,
		longitude: 168.3333,
		accuracy: 4,
	},
	'9844': {
		'country code': 'NZ',
		'postal code': 9844,
		'place name': 'Strathern',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.4284,
		longitude: 168.3601,
		accuracy: 4,
	},
	'9845': {
		'country code': 'NZ',
		'postal code': 9845,
		'place name': 'Hawthorndale',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.3984,
		longitude: 168.3801,
		accuracy: 4,
	},
	'9847': {
		'country code': 'NZ',
		'postal code': 9847,
		'place name': 'Riverton',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.35,
		longitude: 168.0167,
		accuracy: 4,
	},
	'9848': {
		'country code': 'NZ',
		'postal code': 9848,
		'place name': 'Edendale',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.3167,
		longitude: 168.7833,
		accuracy: 4,
	},
	'9849': {
		'country code': 'NZ',
		'postal code': 9849,
		'place name': 'Wyndham',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.3333,
		longitude: 168.85,
		accuracy: 4,
	},
	'9871': {
		'country code': 'NZ',
		'postal code': 9871,
		'place name': 'Woodlands',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.3667,
		longitude: 168.55,
		accuracy: 4,
	},
	'9872': {
		'country code': 'NZ',
		'postal code': 9872,
		'place name': 'Mabel Bush',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.2833,
		longitude: 168.5333,
		accuracy: 4,
	},
	'9874': {
		'country code': 'NZ',
		'postal code': 9874,
		'place name': 'Waianiwa',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.3,
		longitude: 168.2667,
		accuracy: 4,
	},
	'9875': {
		'country code': 'NZ',
		'postal code': 9875,
		'place name': 'Fortrose',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.5667,
		longitude: 168.8,
		accuracy: 4,
	},
	'9876': {
		'country code': 'NZ',
		'postal code': 9876,
		'place name': 'Makarewa',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.3333,
		longitude: 168.35,
		accuracy: 4,
	},
	'9877': {
		'country code': 'NZ',
		'postal code': 9877,
		'place name': 'Awarua Plains',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.4833,
		longitude: 168.3833,
		accuracy: 4,
	},
	'9879': {
		'country code': 'NZ',
		'postal code': 9879,
		'place name': 'Otatara',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.4333,
		longitude: 168.3,
		accuracy: 4,
	},
	'9881': {
		'country code': 'NZ',
		'postal code': 9881,
		'place name': 'Round Hill',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.3167,
		longitude: 167.8167,
		accuracy: 4,
	},
	'9883': {
		'country code': 'NZ',
		'postal code': 9883,
		'place name': 'Gummies Bush',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.2833,
		longitude: 168.0167,
		accuracy: 4,
	},
	'9884': {
		'country code': 'NZ',
		'postal code': 9884,
		'place name': 'Niagara',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.6,
		longitude: 169.1333,
		accuracy: 4,
	},
	'9891': {
		'country code': 'NZ',
		'postal code': 9891,
		'place name': 'Glenham',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.4,
		longitude: 168.85,
		accuracy: 4,
	},
	'9892': {
		'country code': 'NZ',
		'postal code': 9892,
		'place name': 'Mokoreta',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.4333,
		longitude: 169.0667,
		accuracy: 4,
	},
	'9893': {
		'country code': 'NZ',
		'postal code': 9893,
		'place name': 'Kamahi',
		'admin name1': 'Southland',
		'admin code1': 'F8',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.3333,
		longitude: 168.7167,
		accuracy: 4,
	},
	'4310': {
		'country code': 'NZ',
		'postal code': 4310,
		'place name': 'Westown',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0714,
		longitude: 174.0622,
		accuracy: 4,
	},
	'4312': {
		'country code': 'NZ',
		'postal code': 4312,
		'place name': 'Fitzroy',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.05,
		longitude: 174.1,
		accuracy: 4,
	},
	'4314': {
		'country code': 'NZ',
		'postal code': 4314,
		'place name': 'Oakura',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.1167,
		longitude: 173.95,
		accuracy: 4,
	},
	'4320': {
		'country code': 'NZ',
		'postal code': 4320,
		'place name': 'Waitara',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0016,
		longitude: 174.2384,
		accuracy: 4,
	},
	'4322': {
		'country code': 'NZ',
		'postal code': 4322,
		'place name': 'Eltham',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4292,
		longitude: 174.3,
		accuracy: 4,
	},
	'4330': {
		'country code': 'NZ',
		'postal code': 4330,
		'place name': 'Inglewood',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.1582,
		longitude: 174.1802,
		accuracy: 4,
	},
	'4332': {
		'country code': 'NZ',
		'postal code': 4332,
		'place name': 'Stratford',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.3376,
		longitude: 174.2837,
		accuracy: 4,
	},
	'4335': {
		'country code': 'NZ',
		'postal code': 4335,
		'place name': 'Okato',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.2,
		longitude: 173.8833,
		accuracy: 4,
	},
	'4340': {
		'country code': 'NZ',
		'postal code': 4340,
		'place name': 'Brooklands',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0735,
		longitude: 174.0821,
		accuracy: 4,
	},
	'4341': {
		'country code': 'NZ',
		'postal code': 4341,
		'place name': 'Fitzroy',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.05,
		longitude: 174.1,
		accuracy: 4,
	},
	'4342': {
		'country code': 'NZ',
		'postal code': 4342,
		'place name': 'New Plymouth',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0667,
		longitude: 174.0833,
		accuracy: 4,
	},
	'4343': {
		'country code': 'NZ',
		'postal code': 4343,
		'place name': 'Frankleigh Park',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0782,
		longitude: 174.0602,
		accuracy: 4,
	},
	'4344': {
		'country code': 'NZ',
		'postal code': 4344,
		'place name': 'Moturoa',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0643,
		longitude: 174.0356,
		accuracy: 4,
	},
	'4345': {
		'country code': 'NZ',
		'postal code': 4345,
		'place name': 'Oakura',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.1167,
		longitude: 173.95,
		accuracy: 4,
	},
	'4346': {
		'country code': 'NZ',
		'postal code': 4346,
		'place name': 'Waitara',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0016,
		longitude: 174.2384,
		accuracy: 4,
	},
	'4347': {
		'country code': 'NZ',
		'postal code': 4347,
		'place name': 'Inglewood',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.1582,
		longitude: 174.1802,
		accuracy: 4,
	},
	'4348': {
		'country code': 'NZ',
		'postal code': 4348,
		'place name': 'Okato',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.2,
		longitude: 173.8833,
		accuracy: 4,
	},
	'4349': {
		'country code': 'NZ',
		'postal code': 4349,
		'place name': 'Urenui',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39,
		longitude: 174.3833,
		accuracy: 4,
	},
	'4351': {
		'country code': 'NZ',
		'postal code': 4351,
		'place name': 'Bell Block',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0333,
		longitude: 174.15,
		accuracy: 4,
	},
	'4352': {
		'country code': 'NZ',
		'postal code': 4352,
		'place name': 'Stratford',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.3376,
		longitude: 174.2837,
		accuracy: 4,
	},
	'4353': {
		'country code': 'NZ',
		'postal code': 4353,
		'place name': 'Eltham',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4292,
		longitude: 174.3,
		accuracy: 4,
	},
	'4360': {
		'country code': 'NZ',
		'postal code': 4360,
		'place name': 'Merrilands',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.068,
		longitude: 174.0977,
		accuracy: 4,
	},
	'4371': {
		'country code': 'NZ',
		'postal code': 4371,
		'place name': 'Mangorei',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.15,
		longitude: 174.0833,
		accuracy: 4,
	},
	'4372': {
		'country code': 'NZ',
		'postal code': 4372,
		'place name': 'Hillsborough',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.1,
		longitude: 174.15,
		accuracy: 4,
	},
	'4373': {
		'country code': 'NZ',
		'postal code': 4373,
		'place name': 'Lepperton',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0667,
		longitude: 174.2167,
		accuracy: 4,
	},
	'4374': {
		'country code': 'NZ',
		'postal code': 4374,
		'place name': 'Oakura',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.1167,
		longitude: 173.95,
		accuracy: 4,
	},
	'4375': {
		'country code': 'NZ',
		'postal code': 4375,
		'place name': 'Okoki',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0167,
		longitude: 174.4833,
		accuracy: 4,
	},
	'4377': {
		'country code': 'NZ',
		'postal code': 4377,
		'place name': 'Uruti',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.95,
		longitude: 174.5333,
		accuracy: 4,
	},
	'4378': {
		'country code': 'NZ',
		'postal code': 4378,
		'place name': 'Okau',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.8667,
		longitude: 174.65,
		accuracy: 4,
	},
	'4379': {
		'country code': 'NZ',
		'postal code': 4379,
		'place name': 'Uruti',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.95,
		longitude: 174.5333,
		accuracy: 4,
	},
	'4381': {
		'country code': 'NZ',
		'postal code': 4381,
		'place name': 'Okato',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.2,
		longitude: 173.8833,
		accuracy: 4,
	},
	'4382': {
		'country code': 'NZ',
		'postal code': 4382,
		'place name': 'Sentry Hill',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0333,
		longitude: 174.2,
		accuracy: 4,
	},
	'4383': {
		'country code': 'NZ',
		'postal code': 4383,
		'place name': 'Tikorangi',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0333,
		longitude: 174.2833,
		accuracy: 4,
	},
	'4386': {
		'country code': 'NZ',
		'postal code': 4386,
		'place name': 'Kaimiro',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.2,
		longitude: 174.15,
		accuracy: 4,
	},
	'4387': {
		'country code': 'NZ',
		'postal code': 4387,
		'place name': 'Tarata',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.15,
		longitude: 174.3667,
		accuracy: 4,
	},
	'4388': {
		'country code': 'NZ',
		'postal code': 4388,
		'place name': 'Te Tawa',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.1833,
		longitude: 174.2167,
		accuracy: 4,
	},
	'4389': {
		'country code': 'NZ',
		'postal code': 4389,
		'place name': 'Waiongona',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.1,
		longitude: 174.2167,
		accuracy: 4,
	},
	'4390': {
		'country code': 'NZ',
		'postal code': 4390,
		'place name': 'Ratapiko',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.2,
		longitude: 174.3167,
		accuracy: 4,
	},
	'4391': {
		'country code': 'NZ',
		'postal code': 4391,
		'place name': 'Dawson Falls',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.3238,
		longitude: 174.1046,
		accuracy: 4,
	},
	'4392': {
		'country code': 'NZ',
		'postal code': 4392,
		'place name': 'Puniwhakau',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.3282,
		longitude: 174.6165,
		accuracy: 4,
	},
	'4393': {
		'country code': 'NZ',
		'postal code': 4393,
		'place name': 'Huinga',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.357,
		longitude: 174.4335,
		accuracy: 4,
	},
	'4394': {
		'country code': 'NZ',
		'postal code': 4394,
		'place name': 'Tuna',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.2679,
		longitude: 174.2981,
		accuracy: 4,
	},
	'4395': {
		'country code': 'NZ',
		'postal code': 4395,
		'place name': 'Matau',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.1612,
		longitude: 174.5635,
		accuracy: 4,
	},
	'4396': {
		'country code': 'NZ',
		'postal code': 4396,
		'place name': 'Tahora',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0309,
		longitude: 174.8026,
		accuracy: 4,
	},
	'4397': {
		'country code': 'NZ',
		'postal code': 4397,
		'place name': 'Tahora',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0309,
		longitude: 174.8026,
		accuracy: 4,
	},
	'4398': {
		'country code': 'NZ',
		'postal code': 4398,
		'place name': 'Eltham',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4292,
		longitude: 174.3,
		accuracy: 4,
	},
	'4399': {
		'country code': 'NZ',
		'postal code': 4399,
		'place name': 'Moeroa',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4333,
		longitude: 174.6333,
		accuracy: 4,
	},
	'4510': {
		'country code': 'NZ',
		'postal code': 4510,
		'place name': 'Waverley',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.7667,
		longitude: 174.6333,
		accuracy: 4,
	},
	'4520': {
		'country code': 'NZ',
		'postal code': 4520,
		'place name': 'White Tank',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.7583,
		longitude: 174.4667,
		accuracy: 4,
	},
	'4544': {
		'country code': 'NZ',
		'postal code': 4544,
		'place name': 'Waverley',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.7667,
		longitude: 174.6333,
		accuracy: 4,
	},
	'4545': {
		'country code': 'NZ',
		'postal code': 4545,
		'place name': 'White Tank',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.7583,
		longitude: 174.4667,
		accuracy: 4,
	},
	'4549': {
		'country code': 'NZ',
		'postal code': 4549,
		'place name': 'Waitotara',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.8,
		longitude: 174.7333,
		accuracy: 4,
	},
	'4588': {
		'country code': 'NZ',
		'postal code': 4588,
		'place name': 'Tawhiwhi',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6333,
		longitude: 174.8,
		accuracy: 4,
	},
	'4591': {
		'country code': 'NZ',
		'postal code': 4591,
		'place name': 'Nukuhau',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6,
		longitude: 174.6667,
		accuracy: 4,
	},
	'4592': {
		'country code': 'NZ',
		'postal code': 4592,
		'place name': 'Mangawhio',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.7167,
		longitude: 174.7167,
		accuracy: 4,
	},
	'4597': {
		'country code': 'NZ',
		'postal code': 4597,
		'place name': 'Matukuroa',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6833,
		longitude: 174.5333,
		accuracy: 4,
	},
	'4598': {
		'country code': 'NZ',
		'postal code': 4598,
		'place name': 'Hurleyville',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.6333,
		longitude: 174.5,
		accuracy: 4,
	},
	'4610': {
		'country code': 'NZ',
		'postal code': 4610,
		'place name': 'Hawera',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5917,
		longitude: 174.2833,
		accuracy: 4,
	},
	'4612': {
		'country code': 'NZ',
		'postal code': 4612,
		'place name': 'Manaia',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.55,
		longitude: 174.1333,
		accuracy: 4,
	},
	'4614': {
		'country code': 'NZ',
		'postal code': 4614,
		'place name': 'Normanby',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5333,
		longitude: 174.2833,
		accuracy: 4,
	},
	'4616': {
		'country code': 'NZ',
		'postal code': 4616,
		'place name': 'Opunake',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4556,
		longitude: 173.8583,
		accuracy: 4,
	},
	'4640': {
		'country code': 'NZ',
		'postal code': 4640,
		'place name': 'Hawera',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5917,
		longitude: 174.2833,
		accuracy: 4,
	},
	'4641': {
		'country code': 'NZ',
		'postal code': 4641,
		'place name': 'Manaia',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.55,
		longitude: 174.1333,
		accuracy: 4,
	},
	'4642': {
		'country code': 'NZ',
		'postal code': 4642,
		'place name': 'Kaponga',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4333,
		longitude: 174.15,
		accuracy: 4,
	},
	'4645': {
		'country code': 'NZ',
		'postal code': 4645,
		'place name': 'Opunake',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4556,
		longitude: 173.8583,
		accuracy: 4,
	},
	'4671': {
		'country code': 'NZ',
		'postal code': 4671,
		'place name': 'Okaiawa',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5333,
		longitude: 174.2,
		accuracy: 4,
	},
	'4672': {
		'country code': 'NZ',
		'postal code': 4672,
		'place name': 'Meremere',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5833,
		longitude: 174.3833,
		accuracy: 4,
	},
	'4673': {
		'country code': 'NZ',
		'postal code': 4673,
		'place name': 'Te Roti',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4833,
		longitude: 174.2667,
		accuracy: 4,
	},
	'4674': {
		'country code': 'NZ',
		'postal code': 4674,
		'place name': 'Ararata',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.5167,
		longitude: 174.3667,
		accuracy: 4,
	},
	'4675': {
		'country code': 'NZ',
		'postal code': 4675,
		'place name': 'Matapu',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4833,
		longitude: 174.2333,
		accuracy: 4,
	},
	'4678': {
		'country code': 'NZ',
		'postal code': 4678,
		'place name': 'Kaupokonui',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.55,
		longitude: 174.0667,
		accuracy: 4,
	},
	'4679': {
		'country code': 'NZ',
		'postal code': 4679,
		'place name': 'Riverlea',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4333,
		longitude: 174.0833,
		accuracy: 4,
	},
	'4681': {
		'country code': 'NZ',
		'postal code': 4681,
		'place name': 'Oaonui',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4,
		longitude: 173.8167,
		accuracy: 4,
	},
	'4682': {
		'country code': 'NZ',
		'postal code': 4682,
		'place name': 'Waiteika',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.4833,
		longitude: 173.9,
		accuracy: 4,
	},
	'4684': {
		'country code': 'NZ',
		'postal code': 4684,
		'place name': 'Upper Kahui',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.3167,
		longitude: 173.8833,
		accuracy: 4,
	},
	'4685': {
		'country code': 'NZ',
		'postal code': 4685,
		'place name': 'Parihaka Pa',
		'admin name1': 'Taranaki',
		'admin code1': 'F9',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.3,
		longitude: 173.8333,
		accuracy: 4,
	},
	'2121': {
		'country code': 'NZ',
		'postal code': 2121,
		'place name': 'Tuakau',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2705,
		longitude: 174.9438,
		accuracy: 4,
	},
	'2342': {
		'country code': 'NZ',
		'postal code': 2342,
		'place name': 'Tuakau',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2705,
		longitude: 174.9438,
		accuracy: 4,
	},
	'2440': {
		'country code': 'NZ',
		'postal code': 2440,
		'place name': 'Pokeno',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.245,
		longitude: 175.0205,
		accuracy: 4,
	},
	'2441': {
		'country code': 'NZ',
		'postal code': 2441,
		'place name': 'Meremere',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.319,
		longitude: 175.069,
		accuracy: 4,
	},
	'2471': {
		'country code': 'NZ',
		'postal code': 2471,
		'place name': 'Kopuku',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2833,
		longitude: 175.1667,
		accuracy: 4,
	},
	'2472': {
		'country code': 'NZ',
		'postal code': 2472,
		'place name': 'Pokeno',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.245,
		longitude: 175.0205,
		accuracy: 4,
	},
	'2473': {
		'country code': 'NZ',
		'postal code': 2473,
		'place name': 'Kaiaua',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.1111,
		longitude: 175.2962,
		accuracy: 4,
	},
	'2474': {
		'country code': 'NZ',
		'postal code': 2474,
		'place name': 'Mercer',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.279,
		longitude: 175.048,
		accuracy: 4,
	},
	'2682': {
		'country code': 'NZ',
		'postal code': 2682,
		'place name': 'Otaua',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.3064,
		longitude: 174.7408,
		accuracy: 4,
	},
	'2694': {
		'country code': 'NZ',
		'postal code': 2694,
		'place name': 'Tuakau',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2705,
		longitude: 174.9438,
		accuracy: 4,
	},
	'2695': {
		'country code': 'NZ',
		'postal code': 2695,
		'place name': 'Kaawa',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.5108,
		longitude: 174.8836,
		accuracy: 4,
	},
	'3078': {
		'country code': 'NZ',
		'postal code': 3078,
		'place name': 'Atiamuri',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.3833,
		longitude: 176.0167,
		accuracy: 4,
	},
	'3200': {
		'country code': 'NZ',
		'postal code': 3200,
		'place name': 'Te Rapa',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.75,
		longitude: 175.2167,
		accuracy: 4,
	},
	'3204': {
		'country code': 'NZ',
		'postal code': 3204,
		'place name': 'Frankton Junction',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8,
		longitude: 175.2667,
		accuracy: 4,
	},
	'3206': {
		'country code': 'NZ',
		'postal code': 3206,
		'place name': 'Melville',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.811,
		longitude: 175.2821,
		accuracy: 4,
	},
	'3210': {
		'country code': 'NZ',
		'postal code': 3210,
		'place name': 'Claudelands',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7833,
		longitude: 175.2833,
		accuracy: 4,
	},
	'3214': {
		'country code': 'NZ',
		'postal code': 3214,
		'place name': 'Hamilton',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7833,
		longitude: 175.2833,
		accuracy: 4,
	},
	'3216': {
		'country code': 'NZ',
		'postal code': 3216,
		'place name': 'Hamilton East',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7882,
		longitude: 175.2902,
		accuracy: 4,
	},
	'3218': {
		'country code': 'NZ',
		'postal code': 3218,
		'place name': 'Temple View',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8208,
		longitude: 175.2255,
		accuracy: 4,
	},
	'3225': {
		'country code': 'NZ',
		'postal code': 3225,
		'place name': 'Raglan',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8,
		longitude: 174.8833,
		accuracy: 4,
	},
	'3240': {
		'country code': 'NZ',
		'postal code': 3240,
		'place name': 'Hamilton West',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7882,
		longitude: 175.2702,
		accuracy: 4,
	},
	'3241': {
		'country code': 'NZ',
		'postal code': 3241,
		'place name': 'Te Rapa',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.75,
		longitude: 175.2167,
		accuracy: 4,
	},
	'3242': {
		'country code': 'NZ',
		'postal code': 3242,
		'place name': 'Hamilton West',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7882,
		longitude: 175.2702,
		accuracy: 4,
	},
	'3243': {
		'country code': 'NZ',
		'postal code': 3243,
		'place name': 'Frankton Junction',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8,
		longitude: 175.2667,
		accuracy: 4,
	},
	'3244': {
		'country code': 'NZ',
		'postal code': 3244,
		'place name': 'Hamilton',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7833,
		longitude: 175.2833,
		accuracy: 4,
	},
	'3245': {
		'country code': 'NZ',
		'postal code': 3245,
		'place name': 'Melville',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.811,
		longitude: 175.2821,
		accuracy: 4,
	},
	'3246': {
		'country code': 'NZ',
		'postal code': 3246,
		'place name': 'Melville',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.811,
		longitude: 175.2821,
		accuracy: 4,
	},
	'3247': {
		'country code': 'NZ',
		'postal code': 3247,
		'place name': 'Hamilton East',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7882,
		longitude: 175.2902,
		accuracy: 4,
	},
	'3248': {
		'country code': 'NZ',
		'postal code': 3248,
		'place name': 'Claudelands',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7833,
		longitude: 175.2833,
		accuracy: 4,
	},
	'3249': {
		'country code': 'NZ',
		'postal code': 3249,
		'place name': 'Te Rapa',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.75,
		longitude: 175.2167,
		accuracy: 4,
	},
	'3251': {
		'country code': 'NZ',
		'postal code': 3251,
		'place name': 'Hamilton East',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7882,
		longitude: 175.2902,
		accuracy: 4,
	},
	'3252': {
		'country code': 'NZ',
		'postal code': 3252,
		'place name': 'Claudelands',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7833,
		longitude: 175.2833,
		accuracy: 4,
	},
	'3253': {
		'country code': 'NZ',
		'postal code': 3253,
		'place name': 'Newstead',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7833,
		longitude: 175.35,
		accuracy: 4,
	},
	'3254': {
		'country code': 'NZ',
		'postal code': 3254,
		'place name': 'Newstead',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7833,
		longitude: 175.35,
		accuracy: 4,
	},
	'3255': {
		'country code': 'NZ',
		'postal code': 3255,
		'place name': 'Hamilton East',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7882,
		longitude: 175.2902,
		accuracy: 4,
	},
	'3256': {
		'country code': 'NZ',
		'postal code': 3256,
		'place name': 'Claudelands',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7833,
		longitude: 175.2833,
		accuracy: 4,
	},
	'3257': {
		'country code': 'NZ',
		'postal code': 3257,
		'place name': 'Claudelands',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7833,
		longitude: 175.2833,
		accuracy: 4,
	},
	'3260': {
		'country code': 'NZ',
		'postal code': 3260,
		'place name': 'Matangi',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8048,
		longitude: 175.3952,
		accuracy: 4,
	},
	'3261': {
		'country code': 'NZ',
		'postal code': 3261,
		'place name': 'Whatawhata',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8,
		longitude: 175.15,
		accuracy: 4,
	},
	'3262': {
		'country code': 'NZ',
		'postal code': 3262,
		'place name': 'Horotiu',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7167,
		longitude: 175.2,
		accuracy: 4,
	},
	'3263': {
		'country code': 'NZ',
		'postal code': 3263,
		'place name': 'Te Kowhai',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.75,
		longitude: 175.15,
		accuracy: 4,
	},
	'3264': {
		'country code': 'NZ',
		'postal code': 3264,
		'place name': 'Gordonton',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6667,
		longitude: 175.3,
		accuracy: 4,
	},
	'3265': {
		'country code': 'NZ',
		'postal code': 3265,
		'place name': 'Raglan',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8,
		longitude: 174.8833,
		accuracy: 4,
	},
	'3266': {
		'country code': 'NZ',
		'postal code': 3266,
		'place name': 'Te Uku',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8333,
		longitude: 174.9667,
		accuracy: 4,
	},
	'3281': {
		'country code': 'NZ',
		'postal code': 3281,
		'place name': 'Gordonton',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6667,
		longitude: 175.3,
		accuracy: 4,
	},
	'3282': {
		'country code': 'NZ',
		'postal code': 3282,
		'place name': 'Rukuhia',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8667,
		longitude: 175.2833,
		accuracy: 4,
	},
	'3283': {
		'country code': 'NZ',
		'postal code': 3283,
		'place name': 'Tamahere',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8333,
		longitude: 175.35,
		accuracy: 4,
	},
	'3284': {
		'country code': 'NZ',
		'postal code': 3284,
		'place name': 'Eureka',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7333,
		longitude: 175.4167,
		accuracy: 4,
	},
	'3285': {
		'country code': 'NZ',
		'postal code': 3285,
		'place name': 'Aramiro',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8918,
		longitude: 175.0318,
		accuracy: 4,
	},
	'3288': {
		'country code': 'NZ',
		'postal code': 3288,
		'place name': 'Te Kowhai',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.75,
		longitude: 175.15,
		accuracy: 4,
	},
	'3289': {
		'country code': 'NZ',
		'postal code': 3289,
		'place name': 'Whatawhata',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8,
		longitude: 175.15,
		accuracy: 4,
	},
	'3290': {
		'country code': 'NZ',
		'postal code': 3290,
		'place name': 'Temple View',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8208,
		longitude: 175.2255,
		accuracy: 4,
	},
	'3293': {
		'country code': 'NZ',
		'postal code': 3293,
		'place name': 'Waitetuna',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8333,
		longitude: 175.0333,
		accuracy: 4,
	},
	'3295': {
		'country code': 'NZ',
		'postal code': 3295,
		'place name': 'Te Uku',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8333,
		longitude: 174.9667,
		accuracy: 4,
	},
	'3296': {
		'country code': 'NZ',
		'postal code': 3296,
		'place name': 'Ruapuke',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9247,
		longitude: 174.8138,
		accuracy: 4,
	},
	'3297': {
		'country code': 'NZ',
		'postal code': 3297,
		'place name': 'Raglan',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8,
		longitude: 174.8833,
		accuracy: 4,
	},
	'3300': {
		'country code': 'NZ',
		'postal code': 3300,
		'place name': 'Morrinsville',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6482,
		longitude: 175.5202,
		accuracy: 4,
	},
	'3310': {
		'country code': 'NZ',
		'postal code': 3310,
		'place name': 'Waitoa',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6167,
		longitude: 175.6333,
		accuracy: 4,
	},
	'3320': {
		'country code': 'NZ',
		'postal code': 3320,
		'place name': 'Te Aroha',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.5282,
		longitude: 175.7102,
		accuracy: 4,
	},
	'3330': {
		'country code': 'NZ',
		'postal code': 3330,
		'place name': 'Wharewaka',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.7311,
		longitude: 176.0734,
		accuracy: 4,
	},
	'3332': {
		'country code': 'NZ',
		'postal code': 3332,
		'place name': 'Wairakei',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.6333,
		longitude: 176.1,
		accuracy: 4,
	},
	'3334': {
		'country code': 'NZ',
		'postal code': 3334,
		'place name': 'Turangi',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.9904,
		longitude: 175.8084,
		accuracy: 4,
	},
	'3340': {
		'country code': 'NZ',
		'postal code': 3340,
		'place name': 'Morrinsville',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6482,
		longitude: 175.5202,
		accuracy: 4,
	},
	'3341': {
		'country code': 'NZ',
		'postal code': 3341,
		'place name': 'Waitoa',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6167,
		longitude: 175.6333,
		accuracy: 4,
	},
	'3342': {
		'country code': 'NZ',
		'postal code': 3342,
		'place name': 'Te Aroha',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.5282,
		longitude: 175.7102,
		accuracy: 4,
	},
	'3343': {
		'country code': 'NZ',
		'postal code': 3343,
		'place name': 'Te Aroha',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.5282,
		longitude: 175.7102,
		accuracy: 4,
	},
	'3351': {
		'country code': 'NZ',
		'postal code': 3351,
		'place name': 'Taupo',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.6833,
		longitude: 176.0833,
		accuracy: 4,
	},
	'3352': {
		'country code': 'NZ',
		'postal code': 3352,
		'place name': 'Taupo',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.6833,
		longitude: 176.0833,
		accuracy: 4,
	},
	'3353': {
		'country code': 'NZ',
		'postal code': 3353,
		'place name': 'Turangi',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.9904,
		longitude: 175.8084,
		accuracy: 4,
	},
	'3371': {
		'country code': 'NZ',
		'postal code': 3371,
		'place name': 'Kiwitahi',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7,
		longitude: 175.6,
		accuracy: 4,
	},
	'3372': {
		'country code': 'NZ',
		'postal code': 3372,
		'place name': 'Motumaoho',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7,
		longitude: 175.45,
		accuracy: 4,
	},
	'3373': {
		'country code': 'NZ',
		'postal code': 3373,
		'place name': 'Tahuna',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.5,
		longitude: 175.4833,
		accuracy: 4,
	},
	'3374': {
		'country code': 'NZ',
		'postal code': 3374,
		'place name': 'Te Puninga',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.5833,
		longitude: 175.5667,
		accuracy: 4,
	},
	'3375': {
		'country code': 'NZ',
		'postal code': 3375,
		'place name': 'Morrinsville',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6482,
		longitude: 175.5202,
		accuracy: 4,
	},
	'3377': {
		'country code': 'NZ',
		'postal code': 3377,
		'place name': 'Oruanui',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.5833,
		longitude: 176.0333,
		accuracy: 4,
	},
	'3378': {
		'country code': 'NZ',
		'postal code': 3378,
		'place name': 'Waipahihi',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.7084,
		longitude: 176.0907,
		accuracy: 4,
	},
	'3379': {
		'country code': 'NZ',
		'postal code': 3379,
		'place name': 'Rangitaiki',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.8792,
		longitude: 176.3729,
		accuracy: 4,
	},
	'3381': {
		'country code': 'NZ',
		'postal code': 3381,
		'place name': 'Kuratau',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.8882,
		longitude: 175.7702,
		accuracy: 4,
	},
	'3382': {
		'country code': 'NZ',
		'postal code': 3382,
		'place name': 'Turangi',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.9904,
		longitude: 175.8084,
		accuracy: 4,
	},
	'3391': {
		'country code': 'NZ',
		'postal code': 3391,
		'place name': 'Manawaru',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6333,
		longitude: 175.7667,
		accuracy: 4,
	},
	'3392': {
		'country code': 'NZ',
		'postal code': 3392,
		'place name': 'Mangaiti',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.5,
		longitude: 175.6833,
		accuracy: 4,
	},
	'3393': {
		'country code': 'NZ',
		'postal code': 3393,
		'place name': 'Mangaiti',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.5,
		longitude: 175.6833,
		accuracy: 4,
	},
	'3400': {
		'country code': 'NZ',
		'postal code': 3400,
		'place name': 'Matamata',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8106,
		longitude: 175.7624,
		accuracy: 4,
	},
	'3401': {
		'country code': 'NZ',
		'postal code': 3401,
		'place name': 'Waharoa',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7667,
		longitude: 175.7667,
		accuracy: 4,
	},
	'3410': {
		'country code': 'NZ',
		'postal code': 3410,
		'place name': 'Tirau',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9833,
		longitude: 175.75,
		accuracy: 4,
	},
	'3411': {
		'country code': 'NZ',
		'postal code': 3411,
		'place name': 'Putaruru',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0482,
		longitude: 175.7802,
		accuracy: 4,
	},
	'3415': {
		'country code': 'NZ',
		'postal code': 3415,
		'place name': 'Arapuni',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0709,
		longitude: 175.646,
		accuracy: 4,
	},
	'3420': {
		'country code': 'NZ',
		'postal code': 3420,
		'place name': 'Tokoroa',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.2333,
		longitude: 175.8667,
		accuracy: 4,
	},
	'3421': {
		'country code': 'NZ',
		'postal code': 3421,
		'place name': 'Mangakino',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.3741,
		longitude: 175.771,
		accuracy: 4,
	},
	'3432': {
		'country code': 'NZ',
		'postal code': 3432,
		'place name': 'Leamington',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8833,
		longitude: 175.4667,
		accuracy: 4,
	},
	'3434': {
		'country code': 'NZ',
		'postal code': 3434,
		'place name': 'Leamington',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8833,
		longitude: 175.4667,
		accuracy: 4,
	},
	'3440': {
		'country code': 'NZ',
		'postal code': 3440,
		'place name': 'Matamata',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8106,
		longitude: 175.7624,
		accuracy: 4,
	},
	'3441': {
		'country code': 'NZ',
		'postal code': 3441,
		'place name': 'Waharoa',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7667,
		longitude: 175.7667,
		accuracy: 4,
	},
	'3442': {
		'country code': 'NZ',
		'postal code': 3442,
		'place name': 'Tirau',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9833,
		longitude: 175.75,
		accuracy: 4,
	},
	'3443': {
		'country code': 'NZ',
		'postal code': 3443,
		'place name': 'Putaruru',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0482,
		longitude: 175.7802,
		accuracy: 4,
	},
	'3444': {
		'country code': 'NZ',
		'postal code': 3444,
		'place name': 'Tokoroa',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.2333,
		longitude: 175.8667,
		accuracy: 4,
	},
	'3445': {
		'country code': 'NZ',
		'postal code': 3445,
		'place name': 'Mangakino',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.3741,
		longitude: 175.771,
		accuracy: 4,
	},
	'3450': {
		'country code': 'NZ',
		'postal code': 3450,
		'place name': 'Leamington',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8833,
		longitude: 175.4667,
		accuracy: 4,
	},
	'3451': {
		'country code': 'NZ',
		'postal code': 3451,
		'place name': 'Hautapu',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8608,
		longitude: 175.4535,
		accuracy: 4,
	},
	'3471': {
		'country code': 'NZ',
		'postal code': 3471,
		'place name': 'Okauia',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.787,
		longitude: 175.8399,
		accuracy: 4,
	},
	'3472': {
		'country code': 'NZ',
		'postal code': 3472,
		'place name': 'Hinuera',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8833,
		longitude: 175.7667,
		accuracy: 4,
	},
	'3474': {
		'country code': 'NZ',
		'postal code': 3474,
		'place name': 'Waharoa',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7667,
		longitude: 175.7667,
		accuracy: 4,
	},
	'3475': {
		'country code': 'NZ',
		'postal code': 3475,
		'place name': 'Walton',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.7333,
		longitude: 175.6833,
		accuracy: 4,
	},
	'3481': {
		'country code': 'NZ',
		'postal code': 3481,
		'place name': 'Te Waotu',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.15,
		longitude: 175.7,
		accuracy: 4,
	},
	'3482': {
		'country code': 'NZ',
		'postal code': 3482,
		'place name': 'Ngatira',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1,
		longitude: 175.9,
		accuracy: 4,
	},
	'3483': {
		'country code': 'NZ',
		'postal code': 3483,
		'place name': 'Putaruru',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0482,
		longitude: 175.7802,
		accuracy: 4,
	},
	'3484': {
		'country code': 'NZ',
		'postal code': 3484,
		'place name': 'Tirau',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9833,
		longitude: 175.75,
		accuracy: 4,
	},
	'3485': {
		'country code': 'NZ',
		'postal code': 3485,
		'place name': 'Okoroire',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.95,
		longitude: 175.8,
		accuracy: 4,
	},
	'3491': {
		'country code': 'NZ',
		'postal code': 3491,
		'place name': 'Kinleith',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.2833,
		longitude: 175.9,
		accuracy: 4,
	},
	'3492': {
		'country code': 'NZ',
		'postal code': 3492,
		'place name': 'Mangakino',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.3741,
		longitude: 175.771,
		accuracy: 4,
	},
	'3493': {
		'country code': 'NZ',
		'postal code': 3493,
		'place name': 'Bruntwood',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.85,
		longitude: 175.4333,
		accuracy: 4,
	},
	'3494': {
		'country code': 'NZ',
		'postal code': 3494,
		'place name': 'Pukeatua',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0667,
		longitude: 175.55,
		accuracy: 4,
	},
	'3495': {
		'country code': 'NZ',
		'postal code': 3495,
		'place name': 'Leamington',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8833,
		longitude: 175.4667,
		accuracy: 4,
	},
	'3496': {
		'country code': 'NZ',
		'postal code': 3496,
		'place name': 'Whitehall',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8833,
		longitude: 175.5667,
		accuracy: 4,
	},
	'3500': {
		'country code': 'NZ',
		'postal code': 3500,
		'place name': 'Thames',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.1383,
		longitude: 175.5401,
		accuracy: 4,
	},
	'3503': {
		'country code': 'NZ',
		'postal code': 3503,
		'place name': 'Ngatea',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2833,
		longitude: 175.5,
		accuracy: 4,
	},
	'3506': {
		'country code': 'NZ',
		'postal code': 3506,
		'place name': 'Whangapoua',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7167,
		longitude: 175.6167,
		accuracy: 4,
	},
	'3508': {
		'country code': 'NZ',
		'postal code': 3508,
		'place name': 'Tairua',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0167,
		longitude: 175.85,
		accuracy: 4,
	},
	'3510': {
		'country code': 'NZ',
		'postal code': 3510,
		'place name': 'Whitianga',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8333,
		longitude: 175.7,
		accuracy: 4,
	},
	'3540': {
		'country code': 'NZ',
		'postal code': 3540,
		'place name': 'Thames',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.1383,
		longitude: 175.5401,
		accuracy: 4,
	},
	'3541': {
		'country code': 'NZ',
		'postal code': 3541,
		'place name': 'Ngatea',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2833,
		longitude: 175.5,
		accuracy: 4,
	},
	'3542': {
		'country code': 'NZ',
		'postal code': 3542,
		'place name': 'Whitianga',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.8333,
		longitude: 175.7,
		accuracy: 4,
	},
	'3543': {
		'country code': 'NZ',
		'postal code': 3543,
		'place name': 'Coromandel',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.7611,
		longitude: 175.4963,
		accuracy: 4,
	},
	'3544': {
		'country code': 'NZ',
		'postal code': 3544,
		'place name': 'Tairua',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0167,
		longitude: 175.85,
		accuracy: 4,
	},
	'3545': {
		'country code': 'NZ',
		'postal code': 3545,
		'place name': 'Turua',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2333,
		longitude: 175.5667,
		accuracy: 4,
	},
	'3546': {
		'country code': 'NZ',
		'postal code': 3546,
		'place name': 'Pauanui',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0167,
		longitude: 175.8667,
		accuracy: 4,
	},
	'3547': {
		'country code': 'NZ',
		'postal code': 3547,
		'place name': 'Colville',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6333,
		longitude: 175.4667,
		accuracy: 4,
	},
	'3574': {
		'country code': 'NZ',
		'postal code': 3574,
		'place name': 'Turua',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2333,
		longitude: 175.5667,
		accuracy: 4,
	},
	'3575': {
		'country code': 'NZ',
		'postal code': 3575,
		'place name': 'Tapu',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9833,
		longitude: 175.5,
		accuracy: 4,
	},
	'3576': {
		'country code': 'NZ',
		'postal code': 3576,
		'place name': 'Waitakaruru',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.25,
		longitude: 175.3833,
		accuracy: 4,
	},
	'3577': {
		'country code': 'NZ',
		'postal code': 3577,
		'place name': 'Parawai',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.1499,
		longitude: 175.5599,
		accuracy: 4,
	},
	'3578': {
		'country code': 'NZ',
		'postal code': 3578,
		'place name': 'Nevesville',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.1667,
		longitude: 175.7,
		accuracy: 4,
	},
	'3579': {
		'country code': 'NZ',
		'postal code': 3579,
		'place name': 'Hikuai',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.0833,
		longitude: 175.7833,
		accuracy: 4,
	},
	'3581': {
		'country code': 'NZ',
		'postal code': 3581,
		'place name': 'Ahimia',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.85,
		longitude: 175.4833,
		accuracy: 4,
	},
	'3582': {
		'country code': 'NZ',
		'postal code': 3582,
		'place name': 'Te Rerenga',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.75,
		longitude: 175.6,
		accuracy: 4,
	},
	'3583': {
		'country code': 'NZ',
		'postal code': 3583,
		'place name': 'Kennedys Bay',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6833,
		longitude: 175.55,
		accuracy: 4,
	},
	'3584': {
		'country code': 'NZ',
		'postal code': 3584,
		'place name': 'Colville',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.6333,
		longitude: 175.4667,
		accuracy: 4,
	},
	'3591': {
		'country code': 'NZ',
		'postal code': 3591,
		'place name': 'Coroglen',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9167,
		longitude: 175.7,
		accuracy: 4,
	},
	'3592': {
		'country code': 'NZ',
		'postal code': 3592,
		'place name': 'Coroglen',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -36.9167,
		longitude: 175.7,
		accuracy: 4,
	},
	'3597': {
		'country code': 'NZ',
		'postal code': 3597,
		'place name': 'Ngatea',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2833,
		longitude: 175.5,
		accuracy: 4,
	},
	'3600': {
		'country code': 'NZ',
		'postal code': 3600,
		'place name': 'Paeroa',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.3667,
		longitude: 175.6667,
		accuracy: 4,
	},
	'3610': {
		'country code': 'NZ',
		'postal code': 3610,
		'place name': 'Waihi',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.3833,
		longitude: 175.8333,
		accuracy: 4,
	},
	'3620': {
		'country code': 'NZ',
		'postal code': 3620,
		'place name': 'Whangamata',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2,
		longitude: 175.8667,
		accuracy: 4,
	},
	'3640': {
		'country code': 'NZ',
		'postal code': 3640,
		'place name': 'Paeroa',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.3667,
		longitude: 175.6667,
		accuracy: 4,
	},
	'3641': {
		'country code': 'NZ',
		'postal code': 3641,
		'place name': 'Waihi',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.3833,
		longitude: 175.8333,
		accuracy: 4,
	},
	'3643': {
		'country code': 'NZ',
		'postal code': 3643,
		'place name': 'Whangamata',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2,
		longitude: 175.8667,
		accuracy: 4,
	},
	'3671': {
		'country code': 'NZ',
		'postal code': 3671,
		'place name': 'Netherton',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.3333,
		longitude: 175.6167,
		accuracy: 4,
	},
	'3672': {
		'country code': 'NZ',
		'postal code': 3672,
		'place name': 'Netherton',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.3333,
		longitude: 175.6167,
		accuracy: 4,
	},
	'3673': {
		'country code': 'NZ',
		'postal code': 3673,
		'place name': 'Tirohia',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.4333,
		longitude: 175.65,
		accuracy: 4,
	},
	'3674': {
		'country code': 'NZ',
		'postal code': 3674,
		'place name': 'Komata',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.3667,
		longitude: 175.7333,
		accuracy: 4,
	},
	'3681': {
		'country code': 'NZ',
		'postal code': 3681,
		'place name': 'Waihi',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.3833,
		longitude: 175.8333,
		accuracy: 4,
	},
	'3682': {
		'country code': 'NZ',
		'postal code': 3682,
		'place name': 'Waihi',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.3833,
		longitude: 175.8333,
		accuracy: 4,
	},
	'3691': {
		'country code': 'NZ',
		'postal code': 3691,
		'place name': 'Whangamata',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.2,
		longitude: 175.8667,
		accuracy: 4,
	},
	'3700': {
		'country code': 'NZ',
		'postal code': 3700,
		'place name': 'Starrtown',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.562,
		longitude: 175.1619,
		accuracy: 4,
	},
	'3710': {
		'country code': 'NZ',
		'postal code': 3710,
		'place name': 'Te Kauwhata',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.4,
		longitude: 175.15,
		accuracy: 4,
	},
	'3720': {
		'country code': 'NZ',
		'postal code': 3720,
		'place name': 'Ngaruawahia',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6674,
		longitude: 175.1555,
		accuracy: 4,
	},
	'3721': {
		'country code': 'NZ',
		'postal code': 3721,
		'place name': 'Taupiri',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6167,
		longitude: 175.1833,
		accuracy: 4,
	},
	'3740': {
		'country code': 'NZ',
		'postal code': 3740,
		'place name': 'Starrtown',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.562,
		longitude: 175.1619,
		accuracy: 4,
	},
	'3741': {
		'country code': 'NZ',
		'postal code': 3741,
		'place name': 'Te Kauwhata',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.4,
		longitude: 175.15,
		accuracy: 4,
	},
	'3742': {
		'country code': 'NZ',
		'postal code': 3742,
		'place name': 'Ngaruawahia',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6674,
		longitude: 175.1555,
		accuracy: 4,
	},
	'3771': {
		'country code': 'NZ',
		'postal code': 3771,
		'place name': 'Pukekapia',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.5333,
		longitude: 175.1167,
		accuracy: 4,
	},
	'3772': {
		'country code': 'NZ',
		'postal code': 3772,
		'place name': 'Ruawaro',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.5167,
		longitude: 175.05,
		accuracy: 4,
	},
	'3781': {
		'country code': 'NZ',
		'postal code': 3781,
		'place name': 'Waerenga',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.3667,
		longitude: 175.2333,
		accuracy: 4,
	},
	'3782': {
		'country code': 'NZ',
		'postal code': 3782,
		'place name': 'Whangamarino',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.3491,
		longitude: 175.1135,
		accuracy: 4,
	},
	'3784': {
		'country code': 'NZ',
		'postal code': 3784,
		'place name': 'Waiterimu',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.45,
		longitude: 175.3,
		accuracy: 4,
	},
	'3791': {
		'country code': 'NZ',
		'postal code': 3791,
		'place name': 'Komakorau',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6333,
		longitude: 175.2667,
		accuracy: 4,
	},
	'3792': {
		'country code': 'NZ',
		'postal code': 3792,
		'place name': 'Orini',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.5667,
		longitude: 175.3,
		accuracy: 4,
	},
	'3793': {
		'country code': 'NZ',
		'postal code': 3793,
		'place name': 'Waingaro',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.6833,
		longitude: 175,
		accuracy: 4,
	},
	'3794': {
		'country code': 'NZ',
		'postal code': 3794,
		'place name': 'Pepepe',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.5833,
		longitude: 174.95,
		accuracy: 4,
	},
	'3800': {
		'country code': 'NZ',
		'postal code': 3800,
		'place name': 'Te Awamutu',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0082,
		longitude: 175.3302,
		accuracy: 4,
	},
	'3802': {
		'country code': 'NZ',
		'postal code': 3802,
		'place name': 'Pirongia',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38,
		longitude: 175.2,
		accuracy: 4,
	},
	'3803': {
		'country code': 'NZ',
		'postal code': 3803,
		'place name': 'Ohaupo',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9167,
		longitude: 175.3167,
		accuracy: 4,
	},
	'3840': {
		'country code': 'NZ',
		'postal code': 3840,
		'place name': 'Te Awamutu',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0082,
		longitude: 175.3302,
		accuracy: 4,
	},
	'3841': {
		'country code': 'NZ',
		'postal code': 3841,
		'place name': 'Kihikihi',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0333,
		longitude: 175.35,
		accuracy: 4,
	},
	'3843': {
		'country code': 'NZ',
		'postal code': 3843,
		'place name': 'Kawhia',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0667,
		longitude: 174.8167,
		accuracy: 4,
	},
	'3872': {
		'country code': 'NZ',
		'postal code': 3872,
		'place name': 'Wharepuhunga',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1333,
		longitude: 175.45,
		accuracy: 4,
	},
	'3873': {
		'country code': 'NZ',
		'postal code': 3873,
		'place name': 'Te Kawa',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1,
		longitude: 175.2833,
		accuracy: 4,
	},
	'3874': {
		'country code': 'NZ',
		'postal code': 3874,
		'place name': 'Matapara',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.2167,
		longitude: 175.55,
		accuracy: 4,
	},
	'3875': {
		'country code': 'NZ',
		'postal code': 3875,
		'place name': 'Te Mawhai',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.05,
		longitude: 175.3,
		accuracy: 4,
	},
	'3876': {
		'country code': 'NZ',
		'postal code': 3876,
		'place name': 'Pirongia',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38,
		longitude: 175.2,
		accuracy: 4,
	},
	'3877': {
		'country code': 'NZ',
		'postal code': 3877,
		'place name': 'Matapara',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.2167,
		longitude: 175.55,
		accuracy: 4,
	},
	'3878': {
		'country code': 'NZ',
		'postal code': 3878,
		'place name': 'Hauturu',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1,
		longitude: 174.9333,
		accuracy: 4,
	},
	'3879': {
		'country code': 'NZ',
		'postal code': 3879,
		'place name': 'Kihikihi',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0333,
		longitude: 175.35,
		accuracy: 4,
	},
	'3880': {
		'country code': 'NZ',
		'postal code': 3880,
		'place name': 'Pukeatua',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0667,
		longitude: 175.55,
		accuracy: 4,
	},
	'3881': {
		'country code': 'NZ',
		'postal code': 3881,
		'place name': 'Ohaupo',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9167,
		longitude: 175.3167,
		accuracy: 4,
	},
	'3882': {
		'country code': 'NZ',
		'postal code': 3882,
		'place name': 'Rukuhia',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.8667,
		longitude: 175.2833,
		accuracy: 4,
	},
	'3883': {
		'country code': 'NZ',
		'postal code': 3883,
		'place name': 'Ngaroto',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9833,
		longitude: 175.3,
		accuracy: 4,
	},
	'3885': {
		'country code': 'NZ',
		'postal code': 3885,
		'place name': 'Oparau',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.05,
		longitude: 174.9167,
		accuracy: 4,
	},
	'3886': {
		'country code': 'NZ',
		'postal code': 3886,
		'place name': 'Owhiro',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1667,
		longitude: 174.9,
		accuracy: 4,
	},
	'3889': {
		'country code': 'NZ',
		'postal code': 3889,
		'place name': 'Kawhia',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0667,
		longitude: 174.8167,
		accuracy: 4,
	},
	'3894': {
		'country code': 'NZ',
		'postal code': 3894,
		'place name': 'Te Papatapu',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.95,
		longitude: 174.8833,
		accuracy: 4,
	},
	'3895': {
		'country code': 'NZ',
		'postal code': 3895,
		'place name': 'Moerangi',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -37.9667,
		longitude: 174.9167,
		accuracy: 4,
	},
	'3900': {
		'country code': 'NZ',
		'postal code': 3900,
		'place name': 'Otorohanga',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1833,
		longitude: 175.2,
		accuracy: 4,
	},
	'3910': {
		'country code': 'NZ',
		'postal code': 3910,
		'place name': 'Te Kuiti',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.3363,
		longitude: 175.1701,
		accuracy: 4,
	},
	'3912': {
		'country code': 'NZ',
		'postal code': 3912,
		'place name': 'Piopio',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.4667,
		longitude: 175.0167,
		accuracy: 4,
	},
	'3940': {
		'country code': 'NZ',
		'postal code': 3940,
		'place name': 'Otorohanga',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1833,
		longitude: 175.2,
		accuracy: 4,
	},
	'3941': {
		'country code': 'NZ',
		'postal code': 3941,
		'place name': 'Te Kuiti',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.3363,
		longitude: 175.1701,
		accuracy: 4,
	},
	'3942': {
		'country code': 'NZ',
		'postal code': 3942,
		'place name': 'Piopio',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.4667,
		longitude: 175.0167,
		accuracy: 4,
	},
	'3943': {
		'country code': 'NZ',
		'postal code': 3943,
		'place name': 'Hangatiki',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.25,
		longitude: 175.1667,
		accuracy: 4,
	},
	'3944': {
		'country code': 'NZ',
		'postal code': 3944,
		'place name': 'Benneydale',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.5206,
		longitude: 175.3616,
		accuracy: 4,
	},
	'3945': {
		'country code': 'NZ',
		'postal code': 3945,
		'place name': 'Mahoenui',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.5667,
		longitude: 174.85,
		accuracy: 4,
	},
	'3949': {
		'country code': 'NZ',
		'postal code': 3949,
		'place name': 'Tongariro',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.0333,
		longitude: 175.65,
		accuracy: 4,
	},
	'3970': {
		'country code': 'NZ',
		'postal code': 3970,
		'place name': 'Wairere Falls',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.5167,
		longitude: 175.0167,
		accuracy: 4,
	},
	'3971': {
		'country code': 'NZ',
		'postal code': 3971,
		'place name': 'Waitanguru',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.3833,
		longitude: 174.8667,
		accuracy: 4,
	},
	'3972': {
		'country code': 'NZ',
		'postal code': 3972,
		'place name': 'Maihiihi',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.2333,
		longitude: 175.3833,
		accuracy: 4,
	},
	'3973': {
		'country code': 'NZ',
		'postal code': 3973,
		'place name': 'Honikiwi',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.15,
		longitude: 175.1,
		accuracy: 4,
	},
	'3974': {
		'country code': 'NZ',
		'postal code': 3974,
		'place name': 'Te Kawa',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1,
		longitude: 175.2833,
		accuracy: 4,
	},
	'3975': {
		'country code': 'NZ',
		'postal code': 3975,
		'place name': 'Maihiihi',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.2333,
		longitude: 175.3833,
		accuracy: 4,
	},
	'3976': {
		'country code': 'NZ',
		'postal code': 3976,
		'place name': 'Hangatiki',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.25,
		longitude: 175.1667,
		accuracy: 4,
	},
	'3977': {
		'country code': 'NZ',
		'postal code': 3977,
		'place name': 'Hangatiki',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.25,
		longitude: 175.1667,
		accuracy: 4,
	},
	'3978': {
		'country code': 'NZ',
		'postal code': 3978,
		'place name': 'Mahoenui',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.5667,
		longitude: 174.85,
		accuracy: 4,
	},
	'3981': {
		'country code': 'NZ',
		'postal code': 3981,
		'place name': 'Arapae',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.4167,
		longitude: 175.0833,
		accuracy: 4,
	},
	'3982': {
		'country code': 'NZ',
		'postal code': 3982,
		'place name': 'Puketutu',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.4333,
		longitude: 175.2333,
		accuracy: 4,
	},
	'3983': {
		'country code': 'NZ',
		'postal code': 3983,
		'place name': 'Kopaki',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.4667,
		longitude: 175.2667,
		accuracy: 4,
	},
	'3985': {
		'country code': 'NZ',
		'postal code': 3985,
		'place name': 'Waitanguru',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.3833,
		longitude: 174.8667,
		accuracy: 4,
	},
	'3986': {
		'country code': 'NZ',
		'postal code': 3986,
		'place name': 'Te Kuiti',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.3363,
		longitude: 175.1701,
		accuracy: 4,
	},
	'3987': {
		'country code': 'NZ',
		'postal code': 3987,
		'place name': 'Benneydale',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.5206,
		longitude: 175.3616,
		accuracy: 4,
	},
	'3988': {
		'country code': 'NZ',
		'postal code': 3988,
		'place name': 'Owhiro',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.1667,
		longitude: 174.9,
		accuracy: 4,
	},
	'4350': {
		'country code': 'NZ',
		'postal code': 4350,
		'place name': 'Mokau',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.6974,
		longitude: 174.6192,
		accuracy: 4,
	},
	'4376': {
		'country code': 'NZ',
		'postal code': 4376,
		'place name': 'Awakino',
		'admin name1': 'Waikato',
		'admin code1': 'G1',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.65,
		longitude: 174.6333,
		accuracy: 4,
	},
	'5010': {
		'country code': 'NZ',
		'postal code': 5010,
		'place name': 'Avalon',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1983,
		longitude: 174.9202,
		accuracy: 4,
	},
	'5011': {
		'country code': 'NZ',
		'postal code': 5011,
		'place name': 'Taita',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2,
		longitude: 174.95,
		accuracy: 4,
	},
	'5012': {
		'country code': 'NZ',
		'postal code': 5012,
		'place name': 'Petone',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2283,
		longitude: 174.8702,
		accuracy: 4,
	},
	'5013': {
		'country code': 'NZ',
		'postal code': 5013,
		'place name': 'Muritai',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.3025,
		longitude: 174.892,
		accuracy: 4,
	},
	'5014': {
		'country code': 'NZ',
		'postal code': 5014,
		'place name': 'Wainuiomata',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2667,
		longitude: 174.95,
		accuracy: 4,
	},
	'5016': {
		'country code': 'NZ',
		'postal code': 5016,
		'place name': 'Korokoro',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2167,
		longitude: 174.8667,
		accuracy: 4,
	},
	'5018': {
		'country code': 'NZ',
		'postal code': 5018,
		'place name': 'Maoribank',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1083,
		longitude: 175.0902,
		accuracy: 4,
	},
	'5019': {
		'country code': 'NZ',
		'postal code': 5019,
		'place name': 'Pinehaven',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1583,
		longitude: 175.0002,
		accuracy: 4,
	},
	'5022': {
		'country code': 'NZ',
		'postal code': 5022,
		'place name': 'Elsdon',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1283,
		longitude: 174.8202,
		accuracy: 4,
	},
	'5024': {
		'country code': 'NZ',
		'postal code': 5024,
		'place name': 'Waitangirua',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1183,
		longitude: 174.8802,
		accuracy: 4,
	},
	'5026': {
		'country code': 'NZ',
		'postal code': 5026,
		'place name': 'Karehana Bay',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0719,
		longitude: 174.8575,
		accuracy: 4,
	},
	'5028': {
		'country code': 'NZ',
		'postal code': 5028,
		'place name': 'Grenada North',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1897,
		longitude: 174.8412,
		accuracy: 4,
	},
	'5032': {
		'country code': 'NZ',
		'postal code': 5032,
		'place name': 'Paraparaumu Beach',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.9,
		longitude: 174.9833,
		accuracy: 4,
	},
	'5034': {
		'country code': 'NZ',
		'postal code': 5034,
		'place name': 'Paekakariki',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.9854,
		longitude: 174.9545,
		accuracy: 4,
	},
	'5036': {
		'country code': 'NZ',
		'postal code': 5036,
		'place name': 'Waikanae',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.8833,
		longitude: 175.0667,
		accuracy: 4,
	},
	'5040': {
		'country code': 'NZ',
		'postal code': 5040,
		'place name': 'Alicetown',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2083,
		longitude: 174.8902,
		accuracy: 4,
	},
	'5041': {
		'country code': 'NZ',
		'postal code': 5041,
		'place name': 'Taita',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2,
		longitude: 174.95,
		accuracy: 4,
	},
	'5042': {
		'country code': 'NZ',
		'postal code': 5042,
		'place name': 'Lower Hutt',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2167,
		longitude: 174.9167,
		accuracy: 4,
	},
	'5043': {
		'country code': 'NZ',
		'postal code': 5043,
		'place name': 'Gracefield',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2333,
		longitude: 174.9167,
		accuracy: 4,
	},
	'5044': {
		'country code': 'NZ',
		'postal code': 5044,
		'place name': 'Naenae',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1983,
		longitude: 174.9402,
		accuracy: 4,
	},
	'5045': {
		'country code': 'NZ',
		'postal code': 5045,
		'place name': 'Petone',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2283,
		longitude: 174.8702,
		accuracy: 4,
	},
	'5046': {
		'country code': 'NZ',
		'postal code': 5046,
		'place name': 'Petone',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2283,
		longitude: 174.8702,
		accuracy: 4,
	},
	'5047': {
		'country code': 'NZ',
		'postal code': 5047,
		'place name': 'Eastbourne',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.3,
		longitude: 174.9,
		accuracy: 4,
	},
	'5048': {
		'country code': 'NZ',
		'postal code': 5048,
		'place name': 'Wainuiomata',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2667,
		longitude: 174.95,
		accuracy: 4,
	},
	'5049': {
		'country code': 'NZ',
		'postal code': 5049,
		'place name': 'Homedale',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2667,
		longitude: 174.95,
		accuracy: 4,
	},
	'5050': {
		'country code': 'NZ',
		'postal code': 5050,
		'place name': 'Maungaraki',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2083,
		longitude: 174.8702,
		accuracy: 4,
	},
	'5140': {
		'country code': 'NZ',
		'postal code': 5140,
		'place name': 'Kingsley Heights',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1183,
		longitude: 175.0702,
		accuracy: 4,
	},
	'5141': {
		'country code': 'NZ',
		'postal code': 5141,
		'place name': 'Stokes Valley',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1783,
		longitude: 174.9802,
		accuracy: 4,
	},
	'5142': {
		'country code': 'NZ',
		'postal code': 5142,
		'place name': 'Silverstream',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.15,
		longitude: 175,
		accuracy: 4,
	},
	'5143': {
		'country code': 'NZ',
		'postal code': 5143,
		'place name': 'Trentham',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1333,
		longitude: 175.0333,
		accuracy: 4,
	},
	'5240': {
		'country code': 'NZ',
		'postal code': 5240,
		'place name': 'Ranui Heights',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1383,
		longitude: 174.8402,
		accuracy: 4,
	},
	'5242': {
		'country code': 'NZ',
		'postal code': 5242,
		'place name': 'Onepoto',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1083,
		longitude: 174.8402,
		accuracy: 4,
	},
	'5243': {
		'country code': 'NZ',
		'postal code': 5243,
		'place name': 'Cannons Creek',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1399,
		longitude: 174.8665,
		accuracy: 4,
	},
	'5245': {
		'country code': 'NZ',
		'postal code': 5245,
		'place name': 'Whitby',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1083,
		longitude: 174.8902,
		accuracy: 4,
	},
	'5247': {
		'country code': 'NZ',
		'postal code': 5247,
		'place name': 'Paremata',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1167,
		longitude: 174.8667,
		accuracy: 4,
	},
	'5249': {
		'country code': 'NZ',
		'postal code': 5249,
		'place name': 'Linden',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1483,
		longitude: 174.8302,
		accuracy: 4,
	},
	'5250': {
		'country code': 'NZ',
		'postal code': 5250,
		'place name': 'Waikanae',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.8833,
		longitude: 175.0667,
		accuracy: 4,
	},
	'5252': {
		'country code': 'NZ',
		'postal code': 5252,
		'place name': 'Paraparaumu Beach',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.9,
		longitude: 174.9833,
		accuracy: 4,
	},
	'5254': {
		'country code': 'NZ',
		'postal code': 5254,
		'place name': 'Paraparaumu',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.9167,
		longitude: 175.0167,
		accuracy: 4,
	},
	'5255': {
		'country code': 'NZ',
		'postal code': 5255,
		'place name': 'Raumati Beach',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.9167,
		longitude: 174.9833,
		accuracy: 4,
	},
	'5258': {
		'country code': 'NZ',
		'postal code': 5258,
		'place name': 'Paekakariki',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.9854,
		longitude: 174.9545,
		accuracy: 4,
	},
	'5371': {
		'country code': 'NZ',
		'postal code': 5371,
		'place name': 'Upper Hutt',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1383,
		longitude: 175.0502,
		accuracy: 4,
	},
	'5372': {
		'country code': 'NZ',
		'postal code': 5372,
		'place name': 'Kaitoke',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0833,
		longitude: 175.1667,
		accuracy: 4,
	},
	'5373': {
		'country code': 'NZ',
		'postal code': 5373,
		'place name': 'Homedale',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2667,
		longitude: 174.95,
		accuracy: 4,
	},
	'5381': {
		'country code': 'NZ',
		'postal code': 5381,
		'place name': 'Pukerua Bay',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0333,
		longitude: 174.9,
		accuracy: 4,
	},
	'5391': {
		'country code': 'NZ',
		'postal code': 5391,
		'place name': 'Reikorangi',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.9,
		longitude: 175.0833,
		accuracy: 4,
	},
	'5512': {
		'country code': 'NZ',
		'postal code': 5512,
		'place name': 'Otaki',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.7583,
		longitude: 175.15,
		accuracy: 4,
	},
	'5542': {
		'country code': 'NZ',
		'postal code': 5542,
		'place name': 'Otaki',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.7583,
		longitude: 175.15,
		accuracy: 4,
	},
	'5543': {
		'country code': 'NZ',
		'postal code': 5543,
		'place name': 'Otaki',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.7583,
		longitude: 175.15,
		accuracy: 4,
	},
	'5544': {
		'country code': 'NZ',
		'postal code': 5544,
		'place name': 'Te Horo',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.8,
		longitude: 175.1,
		accuracy: 4,
	},
	'5710': {
		'country code': 'NZ',
		'postal code': 5710,
		'place name': 'Featherston',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1083,
		longitude: 175.3202,
		accuracy: 4,
	},
	'5711': {
		'country code': 'NZ',
		'postal code': 5711,
		'place name': 'Martinborough',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2083,
		longitude: 175.4302,
		accuracy: 4,
	},
	'5712': {
		'country code': 'NZ',
		'postal code': 5712,
		'place name': 'Greytown',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0783,
		longitude: 175.4602,
		accuracy: 4,
	},
	'5713': {
		'country code': 'NZ',
		'postal code': 5713,
		'place name': 'Carterton',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0183,
		longitude: 175.5302,
		accuracy: 4,
	},
	'5740': {
		'country code': 'NZ',
		'postal code': 5740,
		'place name': 'Featherston',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1083,
		longitude: 175.3202,
		accuracy: 4,
	},
	'5741': {
		'country code': 'NZ',
		'postal code': 5741,
		'place name': 'Martinborough',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2083,
		longitude: 175.4302,
		accuracy: 4,
	},
	'5742': {
		'country code': 'NZ',
		'postal code': 5742,
		'place name': 'Greytown',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0783,
		longitude: 175.4602,
		accuracy: 4,
	},
	'5743': {
		'country code': 'NZ',
		'postal code': 5743,
		'place name': 'Carterton',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0183,
		longitude: 175.5302,
		accuracy: 4,
	},
	'5771': {
		'country code': 'NZ',
		'postal code': 5771,
		'place name': 'Kahutara',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2333,
		longitude: 175.3333,
		accuracy: 4,
	},
	'5772': {
		'country code': 'NZ',
		'postal code': 5772,
		'place name': 'Pirinoa',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.35,
		longitude: 175.2,
		accuracy: 4,
	},
	'5773': {
		'country code': 'NZ',
		'postal code': 5773,
		'place name': 'Cross Creek',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1667,
		longitude: 175.2167,
		accuracy: 4,
	},
	'5781': {
		'country code': 'NZ',
		'postal code': 5781,
		'place name': 'Pahautea',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2333,
		longitude: 175.3833,
		accuracy: 4,
	},
	'5782': {
		'country code': 'NZ',
		'postal code': 5782,
		'place name': 'Waipawa',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.4122,
		longitude: 175.5153,
		accuracy: 4,
	},
	'5783': {
		'country code': 'NZ',
		'postal code': 5783,
		'place name': 'Martinborough',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2083,
		longitude: 175.4302,
		accuracy: 4,
	},
	'5784': {
		'country code': 'NZ',
		'postal code': 5784,
		'place name': 'Hinakura',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.3,
		longitude: 175.6333,
		accuracy: 4,
	},
	'5791': {
		'country code': 'NZ',
		'postal code': 5791,
		'place name': 'Carterton',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0183,
		longitude: 175.5302,
		accuracy: 4,
	},
	'5792': {
		'country code': 'NZ',
		'postal code': 5792,
		'place name': 'Parkvale',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.05,
		longitude: 175.55,
		accuracy: 4,
	},
	'5794': {
		'country code': 'NZ',
		'postal code': 5794,
		'place name': 'Greytown',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0783,
		longitude: 175.4602,
		accuracy: 4,
	},
	'5810': {
		'country code': 'NZ',
		'postal code': 5810,
		'place name': 'Masterton',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.9597,
		longitude: 175.6575,
		accuracy: 4,
	},
	'5840': {
		'country code': 'NZ',
		'postal code': 5840,
		'place name': 'Masterton',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.9597,
		longitude: 175.6575,
		accuracy: 4,
	},
	'5842': {
		'country code': 'NZ',
		'postal code': 5842,
		'place name': 'Masterton',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.9597,
		longitude: 175.6575,
		accuracy: 4,
	},
	'5871': {
		'country code': 'NZ',
		'postal code': 5871,
		'place name': 'Bideford',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.8667,
		longitude: 175.8667,
		accuracy: 4,
	},
	'5872': {
		'country code': 'NZ',
		'postal code': 5872,
		'place name': 'Riversdale Beach',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0883,
		longitude: 176.0702,
		accuracy: 4,
	},
	'5881': {
		'country code': 'NZ',
		'postal code': 5881,
		'place name': 'Kopuaranga',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.8333,
		longitude: 175.6667,
		accuracy: 4,
	},
	'5882': {
		'country code': 'NZ',
		'postal code': 5882,
		'place name': 'Mauriceville',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.7833,
		longitude: 175.7,
		accuracy: 4,
	},
	'5883': {
		'country code': 'NZ',
		'postal code': 5883,
		'place name': 'Homewood',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1836,
		longitude: 175.9581,
		accuracy: 4,
	},
	'5884': {
		'country code': 'NZ',
		'postal code': 5884,
		'place name': 'Gladstone',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0833,
		longitude: 175.65,
		accuracy: 4,
	},
	'5885': {
		'country code': 'NZ',
		'postal code': 5885,
		'place name': 'Masterton',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.9597,
		longitude: 175.6575,
		accuracy: 4,
	},
	'5886': {
		'country code': 'NZ',
		'postal code': 5886,
		'place name': 'Upper Tauheru',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.9069,
		longitude: 175.8111,
		accuracy: 4,
	},
	'5887': {
		'country code': 'NZ',
		'postal code': 5887,
		'place name': 'Clareville',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0167,
		longitude: 175.55,
		accuracy: 4,
	},
	'5888': {
		'country code': 'NZ',
		'postal code': 5888,
		'place name': 'Solway',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.9583,
		longitude: 175.6102,
		accuracy: 4,
	},
	'5889': {
		'country code': 'NZ',
		'postal code': 5889,
		'place name': 'Tinui',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.8833,
		longitude: 176.0667,
		accuracy: 4,
	},
	'5890': {
		'country code': 'NZ',
		'postal code': 5890,
		'place name': 'Tauweru',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.9667,
		longitude: 175.8,
		accuracy: 4,
	},
	'5894': {
		'country code': 'NZ',
		'postal code': 5894,
		'place name': 'Tinui',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.8833,
		longitude: 176.0667,
		accuracy: 4,
	},
	'6011': {
		'country code': 'NZ',
		'postal code': 6011,
		'place name': 'Mount Victoria',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.297,
		longitude: 174.7936,
		accuracy: 4,
	},
	'6012': {
		'country code': 'NZ',
		'postal code': 6012,
		'place name': 'Karori',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2837,
		longitude: 174.7414,
		accuracy: 4,
	},
	'6021': {
		'country code': 'NZ',
		'postal code': 6021,
		'place name': 'Wellington',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2866,
		longitude: 174.7756,
		accuracy: 4,
	},
	'6022': {
		'country code': 'NZ',
		'postal code': 6022,
		'place name': 'Miramar',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.316,
		longitude: 174.8154,
		accuracy: 4,
	},
	'6023': {
		'country code': 'NZ',
		'postal code': 6023,
		'place name': 'Owhiro Bay',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.3383,
		longitude: 174.7502,
		accuracy: 4,
	},
	'6035': {
		'country code': 'NZ',
		'postal code': 6035,
		'place name': 'Khandallah',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2443,
		longitude: 174.7885,
		accuracy: 4,
	},
	'6037': {
		'country code': 'NZ',
		'postal code': 6037,
		'place name': 'Ohariu',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2,
		longitude: 174.7667,
		accuracy: 4,
	},
	'6140': {
		'country code': 'NZ',
		'postal code': 6140,
		'place name': 'Mount Victoria',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.297,
		longitude: 174.7936,
		accuracy: 4,
	},
	'6141': {
		'country code': 'NZ',
		'postal code': 6141,
		'place name': 'Mount Victoria',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.297,
		longitude: 174.7936,
		accuracy: 4,
	},
	'6142': {
		'country code': 'NZ',
		'postal code': 6142,
		'place name': 'Mount Victoria',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.297,
		longitude: 174.7936,
		accuracy: 4,
	},
	'6143': {
		'country code': 'NZ',
		'postal code': 6143,
		'place name': 'Kelburn',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2833,
		longitude: 174.7667,
		accuracy: 4,
	},
	'6144': {
		'country code': 'NZ',
		'postal code': 6144,
		'place name': 'Kaiwharawhara',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2667,
		longitude: 174.7833,
		accuracy: 4,
	},
	'6145': {
		'country code': 'NZ',
		'postal code': 6145,
		'place name': 'Kelburn',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2833,
		longitude: 174.7667,
		accuracy: 4,
	},
	'6146': {
		'country code': 'NZ',
		'postal code': 6146,
		'place name': 'Mount Victoria',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.297,
		longitude: 174.7936,
		accuracy: 4,
	},
	'6147': {
		'country code': 'NZ',
		'postal code': 6147,
		'place name': 'Karori',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2837,
		longitude: 174.7414,
		accuracy: 4,
	},
	'6148': {
		'country code': 'NZ',
		'postal code': 6148,
		'place name': 'Mount Victoria',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.297,
		longitude: 174.7936,
		accuracy: 4,
	},
	'6149': {
		'country code': 'NZ',
		'postal code': 6149,
		'place name': 'Wellington',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2866,
		longitude: 174.7756,
		accuracy: 4,
	},
	'6150': {
		'country code': 'NZ',
		'postal code': 6150,
		'place name': 'Kelburn',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2833,
		longitude: 174.7667,
		accuracy: 4,
	},
	'6160': {
		'country code': 'NZ',
		'postal code': 6160,
		'place name': 'Kelburn',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2833,
		longitude: 174.7667,
		accuracy: 4,
	},
	'6241': {
		'country code': 'NZ',
		'postal code': 6241,
		'place name': 'Rongotai',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.3212,
		longitude: 174.8016,
		accuracy: 4,
	},
	'6242': {
		'country code': 'NZ',
		'postal code': 6242,
		'place name': 'Berhampore',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.3215,
		longitude: 174.7754,
		accuracy: 4,
	},
	'6243': {
		'country code': 'NZ',
		'postal code': 6243,
		'place name': 'Miramar',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.316,
		longitude: 174.8154,
		accuracy: 4,
	},
	'6244': {
		'country code': 'NZ',
		'postal code': 6244,
		'place name': 'Strathmore Park',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.3289,
		longitude: 174.8199,
		accuracy: 4,
	},
	'6440': {
		'country code': 'NZ',
		'postal code': 6440,
		'place name': 'Newlands',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2244,
		longitude: 174.8219,
		accuracy: 4,
	},
	'6441': {
		'country code': 'NZ',
		'postal code': 6441,
		'place name': 'Ngaio',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2504,
		longitude: 174.7739,
		accuracy: 4,
	},
	'6442': {
		'country code': 'NZ',
		'postal code': 6442,
		'place name': 'Newlands',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2244,
		longitude: 174.8219,
		accuracy: 4,
	},
	'6443': {
		'country code': 'NZ',
		'postal code': 6443,
		'place name': 'Ngaio',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2504,
		longitude: 174.7739,
		accuracy: 4,
	},
	'6972': {
		'country code': 'NZ',
		'postal code': 6972,
		'place name': 'Karori West',
		'admin name1': 'Wellington',
		'admin code1': 'G2',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2883,
		longitude: 174.7102,
		accuracy: 4,
	},
	'7802': {
		'country code': 'NZ',
		'postal code': 7802,
		'place name': 'Runanga',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.3983,
		longitude: 171.2501,
		accuracy: 4,
	},
	'7803': {
		'country code': 'NZ',
		'postal code': 7803,
		'place name': 'Rewanui',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.3833,
		longitude: 171.3167,
		accuracy: 4,
	},
	'7804': {
		'country code': 'NZ',
		'postal code': 7804,
		'place name': 'Roa',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.35,
		longitude: 171.3833,
		accuracy: 4,
	},
	'7805': {
		'country code': 'NZ',
		'postal code': 7805,
		'place name': 'Marsden',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.5667,
		longitude: 171.2167,
		accuracy: 4,
	},
	'7810': {
		'country code': 'NZ',
		'postal code': 7810,
		'place name': 'Hokitika',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.7167,
		longitude: 170.9667,
		accuracy: 4,
	},
	'7811': {
		'country code': 'NZ',
		'postal code': 7811,
		'place name': 'Kaniere',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.75,
		longitude: 171,
		accuracy: 4,
	},
	'7812': {
		'country code': 'NZ',
		'postal code': 7812,
		'place name': 'Ross',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.8883,
		longitude: 170.8101,
		accuracy: 4,
	},
	'7822': {
		'country code': 'NZ',
		'postal code': 7822,
		'place name': 'Hector',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.6,
		longitude: 171.8833,
		accuracy: 4,
	},
	'7823': {
		'country code': 'NZ',
		'postal code': 7823,
		'place name': 'Granity',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.6333,
		longitude: 171.85,
		accuracy: 4,
	},
	'7825': {
		'country code': 'NZ',
		'postal code': 7825,
		'place name': 'Carters Beach',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.75,
		longitude: 171.5667,
		accuracy: 4,
	},
	'7830': {
		'country code': 'NZ',
		'postal code': 7830,
		'place name': 'Reefton',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.1167,
		longitude: 171.8667,
		accuracy: 4,
	},
	'7832': {
		'country code': 'NZ',
		'postal code': 7832,
		'place name': 'Kumara',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.6283,
		longitude: 171.1801,
		accuracy: 4,
	},
	'7840': {
		'country code': 'NZ',
		'postal code': 7840,
		'place name': 'Cobden',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.4436,
		longitude: 171.2129,
		accuracy: 4,
	},
	'7841': {
		'country code': 'NZ',
		'postal code': 7841,
		'place name': 'Runanga',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.3983,
		longitude: 171.2501,
		accuracy: 4,
	},
	'7842': {
		'country code': 'NZ',
		'postal code': 7842,
		'place name': 'Hokitika',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.7167,
		longitude: 170.9667,
		accuracy: 4,
	},
	'7843': {
		'country code': 'NZ',
		'postal code': 7843,
		'place name': 'Ahaura',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.3492,
		longitude: 171.5393,
		accuracy: 4,
	},
	'7845': {
		'country code': 'NZ',
		'postal code': 7845,
		'place name': 'Moana',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.5833,
		longitude: 171.4667,
		accuracy: 4,
	},
	'7846': {
		'country code': 'NZ',
		'postal code': 7846,
		'place name': 'Ikamatua',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.2667,
		longitude: 171.6833,
		accuracy: 4,
	},
	'7847': {
		'country code': 'NZ',
		'postal code': 7847,
		'place name': 'Springs Junction',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.3167,
		longitude: 172.1833,
		accuracy: 4,
	},
	'7848': {
		'country code': 'NZ',
		'postal code': 7848,
		'place name': 'Waimangaroa',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.7167,
		longitude: 171.7667,
		accuracy: 4,
	},
	'7851': {
		'country code': 'NZ',
		'postal code': 7851,
		'place name': 'Reefton',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.1167,
		longitude: 171.8667,
		accuracy: 4,
	},
	'7856': {
		'country code': 'NZ',
		'postal code': 7856,
		'place name': 'Franz Josef/Waiau',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.3891,
		longitude: 170.1819,
		accuracy: 4,
	},
	'7857': {
		'country code': 'NZ',
		'postal code': 7857,
		'place name': 'Whataroa',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.2833,
		longitude: 170.4167,
		accuracy: 4,
	},
	'7859': {
		'country code': 'NZ',
		'postal code': 7859,
		'place name': 'Weheka',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.4648,
		longitude: 170.0168,
		accuracy: 4,
	},
	'7860': {
		'country code': 'NZ',
		'postal code': 7860,
		'place name': 'Ross',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.8883,
		longitude: 170.8101,
		accuracy: 4,
	},
	'7861': {
		'country code': 'NZ',
		'postal code': 7861,
		'place name': 'Kumara',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.6283,
		longitude: 171.1801,
		accuracy: 4,
	},
	'7865': {
		'country code': 'NZ',
		'postal code': 7865,
		'place name': 'Charleston',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.9059,
		longitude: 171.4383,
		accuracy: 4,
	},
	'7866': {
		'country code': 'NZ',
		'postal code': 7866,
		'place name': 'Carters Beach',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.75,
		longitude: 171.5667,
		accuracy: 4,
	},
	'7867': {
		'country code': 'NZ',
		'postal code': 7867,
		'place name': 'Hector',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.6,
		longitude: 171.8833,
		accuracy: 4,
	},
	'7871': {
		'country code': 'NZ',
		'postal code': 7871,
		'place name': 'Ikamatua',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.2667,
		longitude: 171.6833,
		accuracy: 4,
	},
	'7872': {
		'country code': 'NZ',
		'postal code': 7872,
		'place name': 'Bell Hill',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.55,
		longitude: 171.6,
		accuracy: 4,
	},
	'7873': {
		'country code': 'NZ',
		'postal code': 7873,
		'place name': 'Barrytown',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.25,
		longitude: 171.3333,
		accuracy: 4,
	},
	'7875': {
		'country code': 'NZ',
		'postal code': 7875,
		'place name': 'Aickens',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.7833,
		longitude: 171.6167,
		accuracy: 4,
	},
	'7881': {
		'country code': 'NZ',
		'postal code': 7881,
		'place name': 'Kowhitirangi',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.8667,
		longitude: 171.0167,
		accuracy: 4,
	},
	'7882': {
		'country code': 'NZ',
		'postal code': 7882,
		'place name': 'Goldsborough',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.6667,
		longitude: 171.1167,
		accuracy: 4,
	},
	'7883': {
		'country code': 'NZ',
		'postal code': 7883,
		'place name': 'Kowhitirangi',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.8667,
		longitude: 171.0167,
		accuracy: 4,
	},
	'7884': {
		'country code': 'NZ',
		'postal code': 7884,
		'place name': 'Harihari',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -43.15,
		longitude: 170.55,
		accuracy: 4,
	},
	'7885': {
		'country code': 'NZ',
		'postal code': 7885,
		'place name': 'Fergusons',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.9667,
		longitude: 170.7333,
		accuracy: 4,
	},
	'7891': {
		'country code': 'NZ',
		'postal code': 7891,
		'place name': 'Mokihinui Mine',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.5833,
		longitude: 172.0167,
		accuracy: 4,
	},
	'7892': {
		'country code': 'NZ',
		'postal code': 7892,
		'place name': 'Te Kuha',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.8333,
		longitude: 171.65,
		accuracy: 4,
	},
	'7893': {
		'country code': 'NZ',
		'postal code': 7893,
		'place name': 'Oparara',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2167,
		longitude: 172.1167,
		accuracy: 4,
	},
	'7895': {
		'country code': 'NZ',
		'postal code': 7895,
		'place name': 'Crushington',
		'admin name1': 'West Coast',
		'admin code1': 'G3',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.15,
		longitude: 171.9,
		accuracy: 4,
	},
	'7005': {
		'country code': 'NZ',
		'postal code': 7005,
		'place name': 'Mapua',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.25,
		longitude: 173.1,
		accuracy: 4,
	},
	'7020': {
		'country code': 'NZ',
		'postal code': 7020,
		'place name': 'Richmond',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.3333,
		longitude: 173.1833,
		accuracy: 4,
	},
	'7022': {
		'country code': 'NZ',
		'postal code': 7022,
		'place name': 'Brightwater',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.3833,
		longitude: 173.1167,
		accuracy: 4,
	},
	'7025': {
		'country code': 'NZ',
		'postal code': 7025,
		'place name': 'Wakefield',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.4,
		longitude: 173.05,
		accuracy: 4,
	},
	'7048': {
		'country code': 'NZ',
		'postal code': 7048,
		'place name': 'Mapua',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.25,
		longitude: 173.1,
		accuracy: 4,
	},
	'7050': {
		'country code': 'NZ',
		'postal code': 7050,
		'place name': 'Richmond',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.3333,
		longitude: 173.1833,
		accuracy: 4,
	},
	'7051': {
		'country code': 'NZ',
		'postal code': 7051,
		'place name': 'Brightwater',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.3833,
		longitude: 173.1167,
		accuracy: 4,
	},
	'7052': {
		'country code': 'NZ',
		'postal code': 7052,
		'place name': 'Wakefield',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.4,
		longitude: 173.05,
		accuracy: 4,
	},
	'7053': {
		'country code': 'NZ',
		'postal code': 7053,
		'place name': 'Saint Arnaud',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.8,
		longitude: 172.8333,
		accuracy: 4,
	},
	'7054': {
		'country code': 'NZ',
		'postal code': 7054,
		'place name': 'Collingwood',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.6772,
		longitude: 172.6807,
		accuracy: 4,
	},
	'7055': {
		'country code': 'NZ',
		'postal code': 7055,
		'place name': 'Tapawera',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.4,
		longitude: 172.8167,
		accuracy: 4,
	},
	'7072': {
		'country code': 'NZ',
		'postal code': 7072,
		'place name': 'Lake Rotoroa',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.8,
		longitude: 172.6,
		accuracy: 4,
	},
	'7073': {
		'country code': 'NZ',
		'postal code': 7073,
		'place name': 'Baton',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.3167,
		longitude: 172.7167,
		accuracy: 4,
	},
	'7077': {
		'country code': 'NZ',
		'postal code': 7077,
		'place name': 'Matakitaki',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.9667,
		longitude: 172.3333,
		accuracy: 4,
	},
	'7081': {
		'country code': 'NZ',
		'postal code': 7081,
		'place name': 'Richmond',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.3333,
		longitude: 173.1833,
		accuracy: 4,
	},
	'7091': {
		'country code': 'NZ',
		'postal code': 7091,
		'place name': 'Brightwater',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.3833,
		longitude: 173.1167,
		accuracy: 4,
	},
	'7095': {
		'country code': 'NZ',
		'postal code': 7095,
		'place name': 'Hiwipango',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.5167,
		longitude: 172.9333,
		accuracy: 4,
	},
	'7096': {
		'country code': 'NZ',
		'postal code': 7096,
		'place name': 'Motupiko',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.45,
		longitude: 172.8,
		accuracy: 4,
	},
	'7110': {
		'country code': 'NZ',
		'postal code': 7110,
		'place name': 'Takaka',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.85,
		longitude: 172.8,
		accuracy: 4,
	},
	'7142': {
		'country code': 'NZ',
		'postal code': 7142,
		'place name': 'Takaka',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.85,
		longitude: 172.8,
		accuracy: 4,
	},
	'7144': {
		'country code': 'NZ',
		'postal code': 7144,
		'place name': 'Upper Moutere',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2667,
		longitude: 173,
		accuracy: 4,
	},
	'7146': {
		'country code': 'NZ',
		'postal code': 7146,
		'place name': 'Riwaka',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0833,
		longitude: 173,
		accuracy: 4,
	},
	'7173': {
		'country code': 'NZ',
		'postal code': 7173,
		'place name': 'Mahana',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2667,
		longitude: 173.05,
		accuracy: 4,
	},
	'7175': {
		'country code': 'NZ',
		'postal code': 7175,
		'place name': 'Braeburn',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1833,
		longitude: 173,
		accuracy: 4,
	},
	'7182': {
		'country code': 'NZ',
		'postal code': 7182,
		'place name': 'Onekaka',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.75,
		longitude: 172.7,
		accuracy: 4,
	},
	'7183': {
		'country code': 'NZ',
		'postal code': 7183,
		'place name': 'East Takaka',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.9167,
		longitude: 172.8333,
		accuracy: 4,
	},
	'7196': {
		'country code': 'NZ',
		'postal code': 7196,
		'place name': 'Ngatimoti',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2167,
		longitude: 172.9,
		accuracy: 4,
	},
	'7197': {
		'country code': 'NZ',
		'postal code': 7197,
		'place name': 'Sandy Bay',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0167,
		longitude: 172.9833,
		accuracy: 4,
	},
	'7198': {
		'country code': 'NZ',
		'postal code': 7198,
		'place name': 'Kairuru',
		'admin name1': 'Tasman',
		'admin code1': 'TAS',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.0333,
		longitude: 172.9333,
		accuracy: 4,
	},
	'3076': {
		'country code': 'NZ',
		'postal code': 3076,
		'place name': 'Punaroma',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.2872,
		longitude: 176.3389,
		accuracy: 1,
	},
	'3166': {
		'country code': 'NZ',
		'postal code': 3166,
		'place name': 'Katikati',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.0255,
		longitude: 176.9425,
		accuracy: 1,
	},
	'3380': {
		'country code': 'NZ',
		'postal code': 3380,
		'place name': 'Waitoa',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -38.9393,
		longitude: 175.7893,
		accuracy: 1,
	},
	'4410': {
		'country code': 'NZ',
		'postal code': 4410,
		'place name': 'Summerhill',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3525,
		longitude: 175.6145,
		accuracy: 1,
	},
	'4448': {
		'country code': 'NZ',
		'postal code': 4448,
		'place name': 'Summerhill',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3603,
		longitude: 175.6143,
		accuracy: 1,
	},
	'4787': {
		'country code': 'NZ',
		'postal code': 4787,
		'place name': 'Cliff Road',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -39.9582,
		longitude: 175.5461,
		accuracy: 1,
	},
	'4825': {
		'country code': 'NZ',
		'postal code': 4825,
		'place name': 'Waiouru Military Camp',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4107,
		longitude: 175.4044,
		accuracy: 1,
	},
	'4826': {
		'country code': 'NZ',
		'postal code': 4826,
		'place name': 'Waiouru Military Camp',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4107,
		longitude: 175.4044,
		accuracy: 1,
	},
	'4861': {
		'country code': 'NZ',
		'postal code': 4861,
		'place name': 'Waiouru',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.3394,
		longitude: 175.4771,
		accuracy: 1,
	},
	'4991': {
		'country code': 'NZ',
		'postal code': 4991,
		'place name': 'Aohanga',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -40.4885,
		longitude: 175.9457,
		accuracy: 1,
	},
	'7007': {
		'country code': 'NZ',
		'postal code': 7007,
		'place name': 'Murchison',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.25,
		longitude: 173.1,
		accuracy: 1,
	},
	'7049': {
		'country code': 'NZ',
		'postal code': 7049,
		'place name': 'Murchison',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.2801,
		longitude: 173.2541,
		accuracy: 1,
	},
	'7120': {
		'country code': 'NZ',
		'postal code': 7120,
		'place name': 'Port Motueka',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1053,
		longitude: 173.2456,
		accuracy: 1,
	},
	'7143': {
		'country code': 'NZ',
		'postal code': 7143,
		'place name': 'Port Motueka',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -41.1083,
		longitude: 173.0958,
		accuracy: 1,
	},
	'7300': {
		'country code': 'NZ',
		'postal code': 7300,
		'place name': 'Waioruarangi',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.6475,
		longitude: 173.0876,
		accuracy: 1,
	},
	'7844': {
		'country code': 'NZ',
		'postal code': 7844,
		'place name': 'Haast',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.3489,
		longitude: 171.5086,
		accuracy: 1,
	},
	'7862': {
		'country code': 'NZ',
		'postal code': 7862,
		'place name': 'Maruia',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.1545,
		longitude: 171.3757,
		accuracy: 1,
	},
	'7863': {
		'country code': 'NZ',
		'postal code': 7863,
		'place name': 'Harihari',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.1545,
		longitude: 171.3757,
		accuracy: 1,
	},
	'7864': {
		'country code': 'NZ',
		'postal code': 7864,
		'place name': 'Market Cross',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.1545,
		longitude: 171.3757,
		accuracy: 1,
	},
	'7886': {
		'country code': 'NZ',
		'postal code': 7886,
		'place name': 'Mahitahi',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -42.9033,
		longitude: 170.8867,
		accuracy: 1,
	},
	'9356': {
		'country code': 'NZ',
		'postal code': 9356,
		'place name': 'Mataura',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.0095,
		longitude: 169.5072,
		accuracy: 1,
	},
	'9412': {
		'country code': 'NZ',
		'postal code': 9412,
		'place name': 'Berwen',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.3184,
		longitude: 170.8101,
		accuracy: 1,
	},
	'9430': {
		'country code': 'NZ',
		'postal code': 9430,
		'place name': 'Palmerston',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.1143,
		longitude: 170.6719,
		accuracy: 1,
	},
	'9435': {
		'country code': 'NZ',
		'postal code': 9435,
		'place name': 'Wharekuri',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.1143,
		longitude: 170.6719,
		accuracy: 1,
	},
	'9441': {
		'country code': 'NZ',
		'postal code': 9441,
		'place name': 'Roxburgh',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -44.9399,
		longitude: 170.5447,
		accuracy: 1,
	},
	'9510': {
		'country code': 'NZ',
		'postal code': 9510,
		'place name': 'Waikouaiti',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -45.9419,
		longitude: 169.5499,
		accuracy: 1,
	},
	'9541': {
		'country code': 'NZ',
		'postal code': 9541,
		'place name': 'Waikouaiti',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.032,
		longitude: 169.479,
		accuracy: 1,
	},
	'9846': {
		'country code': 'NZ',
		'postal code': 9846,
		'place name': 'Half-moon Bay',
		'admin name1': '',
		'admin code1': '',
		'admin name2': '',
		'admin code2': '',
		'admin name3': '',
		'admin code3': '',
		latitude: -46.4024,
		longitude: 168.4223,
		accuracy: 1,
	},
};

export default PostCodes;
