import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

// #f1f5fe

export const ColumnHeader = styled.p`
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 700;
`;

export const Tabs = styled.div``;

export const Filters = styled.div``;

export const TableContainer = styled.div`
  * > td {
    box-shadow: none;
  }

  > table {
    margin-bottom: 0;
  }
`;
