import styled from 'styled-components';

import HelpCircleSvg from 'jsx:assets/svg/help-circle.svg';

export const Container = styled.span`
  position: relative;
  display: inline-block;
  width: min-content;
  height: min-content;
  top: -1px;
`;

export const HelpIcon = styled(HelpCircleSvg)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  fill: ${(props) => props.colour};
`;
