import styled from 'styled-components';
import { space, flexbox, layout, position, typography } from 'styled-system';

import { BoxProps } from '../../@types';

export const BaseBox = styled.div<BoxProps>`
  ${layout}
  ${flexbox}
  ${space}
  ${position}
  ${typography}
`;
