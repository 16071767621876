import { getEnv, flow, types } from 'mobx-state-tree';

import PractitionerService from 'services/PractitionerService';

const ServiceLocation = types.model('ServiceLocation', {
  id: types.identifierNumber,
  city: types.maybeNull(types.string),
  latitude: types.maybeNull(types.number),
  locationType: types.string,
  formattedAddress: types.string,
  longitude: types.maybeNull(types.number),
  postCode: types.maybeNull(types.string),
  primaryAddress: types.maybeNull(types.boolean),
  streetAddress: types.maybeNull(types.string),
  suburb: types.maybeNull(types.string),
  notes: types.maybeNull(types.string),
});

const PaymentOption = types.model('PaymentOption', {
  id: types.identifierNumber,
  bookingLength: types.maybeNull(types.string),
  cost: types.maybeNull(types.string),
  criteria: types.maybeNull(types.string),
  defaultPrice: types.boolean,
  description: types.maybeNull(types.string),
  hasCriteria: types.boolean,
  name: types.maybeNull(types.string),
  sessionLength: types.maybeNull(types.string),
});

const Specialisation = types.model('Specialisation', {
  description: types.maybeNull(types.string),
  filterable: types.boolean,
  name: types.string,
  tagType: types.string,
});

const Appointment = types.model('Apointment', {
  id: types.identifierNumber,
  appointmentTime: types.string,
  appointmentEndTime: types.string,
  booked: types.boolean,
  bookingConfirmedOn: types.maybeNull(types.string),
  bookingRequestedOn: types.maybeNull(types.string),
  confirmed: types.boolean,
  durationMin: types.integer,
  paymentOptions: types.maybeNull(PaymentOption),
  serviceLocation: ServiceLocation,
});

const Practitioner = types
  .model('Practitioner', {
    id: types.identifierNumber,
    acceptingNewClients: types.maybeNull(types.boolean),
    bookingVersionNumber: types.maybeNull(types.number),
    emailAddress: types.maybeNull(types.string),
    firstName: types.string,
    fullBiography: types.maybeNull(types.string),
    fundingOptions: types.array(types.string),
    hoursNotice: types.maybeNull(types.integer),
    ethnicity: types.maybeNull(types.array(types.string)),
    languages: types.maybeNull(types.array(types.string)),
    lastName: types.string,
    mappedAppointments: types.maybeNull(types.map(types.map(Appointment))),
    middleName: types.maybeNull(types.string),
    name: types.string,
    nextAppointmentTime: types.maybeNull(types.string),
    onlineBookingEnabled: types.maybeNull(types.boolean),
    paymentOptions: types.array(PaymentOption),
    phoneNumber: types.maybeNull(types.string),
    primaryImageUrl: types.string,
    profession: types.maybeNull(types.string),
    professions: types.array(types.string),
    profileName: types.maybeNull(types.string),
    qualifications: types.array(types.string),
    referredBy: types.maybeNull(types.string),
    registrationNumber: types.maybeNull(types.string),
    serviceLocations: types.array(ServiceLocation),
    serviceLocationColourMap: types.maybeNull(types.map(types.integer)),
    shortBiography: types.maybeNull(types.string),
    tags: types.array(Specialisation),
    highlightedTags: types.array(Specialisation),
    title: types.maybeNull(types.string),
    websiteAddress: types.maybeNull(types.string),
    timezone: types.maybeNull(types.string),
    vaccinationStatus: types.maybeNull(types.string),
    calendarSyncEnabled: types.maybeNull(types.boolean),
    gender: types.maybeNull(types.string),
    onboarded: types.maybeNull(types.boolean),
    calendarUsed: types.maybeNull(types.string),
    platformReason: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    region: types.maybeNull(types.string),
    invoicingEnabled: types.maybeNull(types.boolean),
    invoicingSetup: types.maybeNull(types.boolean),
    dateOfBirth: types.maybeNull(types.string),
    professionalStartDate: types.maybeNull(types.string),
    lastOnboarded: types.maybeNull(types.string),
  })
  .actions((self) => ({
    getAppointments: flow(function* ({ startDate, endDate }) {
      const {
        auth: { token },
      } = getEnv(self);

      const response = yield PractitionerService.getAppointments(
        self.id,
        startDate,
        endDate,
        token
      );
      if (!response.hasError) {
        self.mappedAppointments = {};
        response.data.forEach((appointment) => {
          const { appointmentTime } = appointment;
          const dateKey = appointmentTime.substring(0, 10);
          // This should be a map of dates => map of appointment ids => appointments
          const storedAppointments = self.mappedAppointments.get(dateKey);

          if (storedAppointments) {
            storedAppointments.set(appointment.id, appointment);
            self.mappedAppointments.set(dateKey, storedAppointments);
          } else {
            self.mappedAppointments.set(dateKey, {
              [appointment.id]: appointment,
            });
          }
        });
      }
      return response;
    }),
  }));

export default Practitioner;
