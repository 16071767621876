import * as React from 'react';
import ReactModal from 'react-modal';
import { StyledClose, StyledContainer, RegularFlexedModal, StyledFlexModal } from './styles';
import Box from '../Box';

export interface IModal {
  children: React.ReactNode;
  hideCloseButton?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  isOpen?: boolean;
  onAfterOpen?: () => void;
  scrollElementId?: string;
  disableScroll?: boolean;
  hideModal?: () => void;
  unstyled?: boolean;
  backgroundColor?: string;
}

const Modal: React.FC<IModal> = ({
  hideModal,
  children,
  backgroundColor,
  onAfterOpen = () => undefined,
  shouldCloseOnOverlayClick = true,
  isOpen = false,
  unstyled = false,
}) => {
  React.useEffect(() => {
    ReactModal.setAppElement('body');
  }, []);

  if (unstyled) {
    return (
      <RegularFlexedModal
        isOpen={isOpen}
        onRequestClose={hideModal}
        onAfterOpen={onAfterOpen}
        $backgroundColor={backgroundColor}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        {children}
      </RegularFlexedModal>
    );
  }

  return (
    <StyledFlexModal
      isOpen={isOpen}
      onRequestClose={hideModal}
      onAfterOpen={onAfterOpen}
      $backgroundColor={backgroundColor}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <Box display={'flex'} justifyContent={'flex-end'} position={'relative'}>
        <StyledContainer onClick={hideModal}>
          <StyledClose />
        </StyledContainer>
      </Box>
      {children}
    </StyledFlexModal>
  );
};

export default Modal;
