import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';

import LoadingSpinnerSvg from 'jsx:assets/svg/loading.svg';

const rotate = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 0%, 0.1);
  z-index: 99;

  ${(props) => props.css}
`;

const LoadingSpinnerIcon = styled(LoadingSpinnerSvg)`
  width: 50px;
  height: 50px;
  fill: black;
  animation: ${rotate} 1.5s linear infinite;
`;

class BlockLoading extends Component {
  render() {
    const { css, className = undefined } = this.props;

    return (
      <Container css={css} className={className}>
        <LoadingSpinnerIcon viewBox='0 0 24 24' />
      </Container>
    );
  }
}

export default BlockLoading;
