import BaseService from '../BaseService';
import { BASE_API_URL } from 'configuration';

class ScheduleService {
  static getSchedule = () => {
    return BaseService.get(`${BASE_API_URL}/practitioners/me/schedule`);
  };

  static publishSchedule = (data) => {
    return BaseService.post(`${BASE_API_URL}/practitioners/me/schedule`, data);
  };
}

export default ScheduleService;
