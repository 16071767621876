import BaseService from '../BaseService';
import { BASE_API_URL } from 'configuration';

class InvoiceService {
  static get = (id) =>
    BaseService.get(`${BASE_API_URL}/practitioners/me/invoices/${id}`);

  static createInvoiceForAppointment = (id) => {
    return BaseService.post(
      `${BASE_API_URL}/practitioners/me/appointments/${id}/invoice`
    );
  };
  static create = (data) => {
    return BaseService.post(`${BASE_API_URL}/practitioners/me/invoices`, data);
  };
  static update = (data) => {
    return BaseService.put(`${BASE_API_URL}/practitioners/me/invoices`, data);
  };
  static updateStatus = (id, status) => {
    if (status === 'SENT') {
      return BaseService.get(
        `${BASE_API_URL}/practitioners/me/invoices/${id}/send`
      );
    } else if (status === 'CANCELLED') {
      return BaseService.get(
        `${BASE_API_URL}/practitioners/me/invoices/${id}/cancel`
      );
    } else if (status === 'PAID') {
      return BaseService.get(
        `${BASE_API_URL}/practitioners/me/invoices/${id}/paid`
      );
    }
  };
  static related = (id) => {
    return BaseService.get(
      `${BASE_API_URL}/practitioners/me/invoices/${id}/related`
    );
  };
  static search = (data) => {
    return BaseService.post(
      `${BASE_API_URL}/practitioners/me/invoices/search`,
      data
    );
  };
  static getSettings = () => {
    return BaseService.get(
      `${BASE_API_URL}/practitioners/me/invoices/settings`
    );
  };
  static setSettings = (data) => {
    return BaseService.post(
      `${BASE_API_URL}/practitioners/me/invoices/settings`,
      data
    );
  };
}

export default InvoiceService;
