import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { WizardFormContext } from '../index';
import ManageAvailabilityComponent from '../../ManageAvailability/ManageAvailabilityComponent';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';
import { FooterNav } from '../WizardForm/styles';

const FooterComponent = ({ onSubmit, isValid, isLoading, data }) => {
  const { onPrevious, onNext, currentStep } = React.useContext(WizardFormContext);

  const goBack = async () => {
    await onPrevious();
  };

  const onSubmitButton = async () => {
    if (isValid) {
      await onSubmit();
      await onNext();
    }
  };

  return (
    <div className='m-auto pb-10 px-4 w-full'>
      <FooterNav className='md:mt-16 xs:mt-8'>
        <div className='flex justify-start'>
          {currentStep > 0 && (
            <Button variant='primary' modifiers={'inverted'} onClick={goBack} disabled={isLoading}>
              Back
            </Button>
          )}
        </div>
        <div className='flex justify-end'>
          <Button
            variant={'primary'}
            onClick={onSubmitButton}
            data-test-id='complete-hear-about-us'
            disabled={!isValid || isLoading}
          >
            Next
          </Button>
        </div>
      </FooterNav>
    </div>
  );
};

const AvailabilityStep = inject(
  'auth',
  'schedule',
  'prices',
  'locations'
)(
  observer((props) => {
    return (
      <div className='max-w-screen-lg mx-auto px-4'>
        <ManageAvailabilityComponent footer={FooterComponent} />
      </div>
    );
  })
);
export default AvailabilityStep;
