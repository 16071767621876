import { SVGProps } from 'react';
const LocationPin = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' viewBox='0 0 14 19' {...props}>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M12.53 7.211c0 3.114-5.64 10.447-5.64 10.447S1.254 10.325 1.254 7.211a5.639 5.639 0 1 1 11.277 0Z'
      clipRule='evenodd'
    />
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M9.086 7.211a2.196 2.196 0 1 1-4.39 0 2.196 2.196 0 0 1 4.39 0Z'
      clipRule='evenodd'
    />
  </svg>
);
export default LocationPin;
