import React from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle, Label } from 'reactstrap';
import { observer, inject } from 'mobx-react';

import FreshdeskLink from 'common/FreshdeskLink';
import PersonalInfoForm from 'modules/auth/components/PersonalInfoForm';
import ContactInfoForm from 'modules/auth/components/ContactInfoForm';

import { allCountries } from 'country-region-data'; // Used to get regions in countries
// https://github.com/country-regions/country-region-data
import { getName, registerLocale, alpha3ToAlpha2 } from 'i18n-iso-countries'; // Used to get country 2 and 3 letter codes
// https://github.com/michaelwittig/node-i18n-iso-countries

const BasicInfoSection = inject('auth')(
  observer((props) => {
    const [editingPersonalInfo, setEditingPersonalInfo] = React.useState(false);
    const [editingContactInfo, setEditingContactInfo] = React.useState(false);

    const renderLocationBody = React.useCallback(() => {
      registerLocale(require('i18n-iso-countries/langs/en.json'));

      const {
        auth: { currentPractitioner },
      } = props;

      const countryName = getName(currentPractitioner.country, 'en');
      let regionName = currentPractitioner.region;

      const countryAndRegions = allCountries.find((c) => {
        return c[1] === alpha3ToAlpha2(currentPractitioner.country);
      });

      if (countryAndRegions) {
        const region = countryAndRegions[2].find((r) => r[1] === currentPractitioner.region);

        if (region) {
          regionName = region[0];
        }
      }

      return (
        <div className='form-row'>
          <div className='columns-1 sm:columns-2 mb-1'>
            <div className='w-full'>
              <Label>Country</Label>
              <div className='text-base mb-2'>{countryName ?? '-'}</div>
            </div>
            <div className='w-full'>
              <Label>Region</Label>
              <div className='text-base'>{regionName ?? '-'}</div>
            </div>
          </div>
          <FreshdeskLink>To change your location, please contact therapist support</FreshdeskLink>
        </div>
      );
    }, [props.auth.currentPractitioner]);

    return (
      <>
        <Card>
          <CardHeader className='d-flex justify-content-between align-items-center'>
            <CardTitle tag='h5' className='mb-0'>
              Personal Info
            </CardTitle>

            <Button onClick={() => setEditingPersonalInfo(!editingPersonalInfo)}>
              {editingPersonalInfo ? 'Cancel' : 'Edit'}
            </Button>
          </CardHeader>
          <CardBody>
            <PersonalInfoForm
              mode={editingPersonalInfo ? 'edit' : 'read-only'}
              onComplete={() => setEditingPersonalInfo(!editingPersonalInfo)}
            />
          </CardBody>
        </Card>

        <Card>
          <CardHeader className='d-flex justify-content-between align-items-center'>
            <CardTitle tag='h5' className='mb-0'>
              Contact Info
            </CardTitle>

            <Button onClick={() => setEditingContactInfo(!editingContactInfo)}>
              {editingContactInfo ? 'Cancel' : 'Edit'}
            </Button>
          </CardHeader>
          <CardBody>
            <ContactInfoForm
              mode={editingContactInfo ? 'edit' : 'read-only'}
              onComplete={() => setEditingContactInfo(!editingContactInfo)}
            />
          </CardBody>
        </Card>

        <Card>
          <CardHeader className='d-flex justify-content-between align-items-center'>
            <CardTitle tag='h5' className='mb-0'>
              Location
            </CardTitle>
          </CardHeader>
          <CardBody>{renderLocationBody()}</CardBody>
        </Card>
      </>
    );
  })
);

export default BasicInfoSection;
