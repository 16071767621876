import React, { Component } from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';

import CHLogoSvg from 'jsx:assets/svg/ch-logo.svg';
import CHTextSvg from 'jsx:assets/svg/ch-text.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
`;

const LogoLink = styled(Link)`
  display: flex;
  position: relative;
  margin-left: 20px;

  ::after {
    position: absolute;
    bottom: -8px;
    right: 0px;
    content: 'BETA';
    color: white;
    font-size: 10px;
    text-decoration: none;
  }
`;

const CHLogo = styled(CHLogoSvg)`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const CHText = styled(CHTextSvg)`
  width: 140px;
  /* height: 80px; */
`;

@withRouter
class SidebarLogo extends Component {
  render() {
    return (
      <Container>
        <LogoLink to="/">
          <CHLogo />
          <CHText />
        </LogoLink>
      </Container>
    );
  }
}

export default SidebarLogo;
