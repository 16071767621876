import * as React from 'react';
import ReactModal from 'react-modal';

export interface IReactModalAdapter extends ReactModal.Props {
  className?: string;
  modalClassName?: string;
  $backgroundColor?: string;
}

const ReactModalAdapter: React.FC<IReactModalAdapter> = ({
  className,
  modalClassName,
  ...props
}) => (
  <ReactModal
    className={modalClassName}
    portalClassName={className}
    bodyOpenClassName="portalOpen"
    {...props}
  />
);

export default ReactModalAdapter;
