import * as React from 'react';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import Logo from '@clearhead-ltd/ui-components/dist/v2/Logo';
import { OptionCard } from '../WizardForm/styles';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';
import PeopleBackground from './PeopleBackground.png';
import { Image, Background } from './styles';

const FinalPageStep = inject('auth')(
  observer((props) => {
    React.useEffect(() => {
      const { auth } = props;
      const date = new Date();
      auth.updatePractitioner({
        id: auth.currentPractitioner.id,
        lastOnboarded: convertDateToString(date),
      });
      auth.refresh();
    }, []);

    const convertDateToString = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    };

    const getProfileImage = () => {
      const {
        auth: { userImage },
      } = props;

      return (
        <Image
          src={userImage}
          alt='profileImage'
          className='object-contain inline-block rounded-full'
        />
      );
    };

    return (
      <>
        <div className='md:w-3/4 lg:w-3/5 xl:w-6/12 xxl:w-5/12 xxxl:w-4/12 m-auto xs:px-4 sm:px-4'>
          <div className='flex flex-col w-full items-center'>
            <div className='max-w-lg text-center'>
              <Heading className='text-dark-blue mb-4' as='h3'>
                Congratulations you're all set up!
              </Heading>

              <OptionCard className='w-3/4 sm:w-1/2 m-auto'>
                <div className='m-4'>{getProfileImage()}</div>
                <Heading className='text-dark-blue m-2' as='h4'>
                  {props.auth.currentPractitioner ? props.auth.currentPractitioner.name : ''}
                </Heading>
                <Text className='m-2'>{props.auth.currentPractitioner.profession}</Text>
              </OptionCard>
              <Link to='/app'>
                <Button variant='primary' className='mt-4 mb-20'>
                  Continue to Portal
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div>
          <div className='flex justify-center m-auto'>
            <Logo />
          </div>
          <Background src={PeopleBackground} alt='' className='relative w-full' />
        </div>
      </>
    );
  })
);

export default FinalPageStep;
