import React, { Component } from 'react';
import {
  Dropdown,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import { INVOICE_STATES } from 'configuration';

import { ConfirmModal } from 'common';

@inject('invoices')
@observer
class InvoiceStatusSelect extends Component {
  @observable status = null;

  @observable confirmModalOpen = false;

  static defaultProps = {
    id: null,
    isOpen: false,
    value: null,
    toggle: () => {},
  };

  updateInvoice = async () => {
    if (this.status) {
      const { invoices, id, toastManager } = this.props;
      const response = await invoices.setStatus(id, this.status);
      if (!response.hasError) {
        toastManager.add(
          `Successfully changed to ${INVOICE_STATES[this.status].display}`,
          {
            appearance: 'success',
            autoDismiss: true,
          }
        );
      } else {
        toastManager.add(`Sorry something went wrong`, {
          appearance: 'danger',
          autoDismiss: true,
        });
      }
      this.status = null;
    }
  };

  confirm = (key) => {
    this.status = key;
    this.confirmModalOpen = true;
  };

  getInvoiceMessage = (invoice) => {
    console.log('in modal for ', this.status, invoice);
    if (!INVOICE_STATES[this.status] || !INVOICE_STATES[this.status].value)
      return <p className="text-warning mt-2">Invalid Status</p>;

    if (this.status === 'CANCELLED') {
      return (
        <p className="text-warning mt-2">
          Once cancelled, this invoice will be locked.
        </p>
      );
    } else if (this.status === 'SENT') {
      return (
        <p className="text-warning mt-2">
          This invoice will be sent by email to {invoice.billTo.name} (
          {invoice.billTo.email})
        </p>
      );
    }
  };

  isStatusChangeAllowed(item, toStatus) {
    if (item.billTo.party === 'CLEARHEAD') {
      switch (item.status) {
        case 'DRAFT':
          switch (toStatus) {
            case 'DRAFT':
              return false;
            case 'SENT':
            case 'CANCELLED':
              return true;
            case 'OVERDUE':
            case 'PAID':
              return false;
          }
        case 'SENT':
          switch (toStatus) {
            case 'DRAFT':
            case 'SENT':
            case 'PAID':
            case 'CANCELLED':
              return false;
            case 'OVERDUE':
              return true;
          }
        case 'PAID':
          switch (toStatus) {
            case 'DRAFT':
            case 'SENT':
            case 'PAID':
            case 'CANCELLED':
            case 'OVERDUE':
              return false;
          }
        case 'CANCELLED':
          return false;
        case 'OVERDUE':
          switch (toStatus) {
            case 'DRAFT':
            case 'SENT':
            case 'PAID':
            case 'CANCELLED':
              return false;
            case 'OVERDUE':
              return true;
          }
      }
    } else {
      switch (item.status) {
        case 'DRAFT':
          switch (toStatus) {
            case 'DRAFT':
              return false;
            case 'SENT':
            case 'PAID':
            case 'CANCELLED':
              return true;
            case 'OVERDUE':
              return false;
          }
        case 'SENT':
          switch (toStatus) {
            case 'DRAFT':
            case 'SENT':
              return false;
            case 'PAID':
            case 'CANCELLED':
            case 'OVERDUE':
              return true;
          }
        case 'PAID':
          switch (toStatus) {
            case 'DRAFT':
            case 'SENT':
            case 'PAID':
            case 'CANCELLED':
            case 'OVERDUE':
              return false;
          }
        case 'CANCELLED':
          return false;
        case 'OVERDUE':
          switch (toStatus) {
            case 'DRAFT':
            case 'SENT':
            case 'PAID':
              return false;
            case 'CANCELLED':
            case 'OVERDUE':
              return true;
          }
      }
    }
    return false;
  }

  render() {
    const { isOpen, toggle, id, invoices } = this.props;
    const invoice = invoices.all.get(id);
    if (invoice) {
      if (invoice.status === 'CANCELLED' || invoice.status === 'OVERDUE' ) {
        return (
          <Button disabled className="btn-danger">
            {INVOICE_STATES[invoice.status].display}
          </Button>
        );
      }
      return (
        <Button disabled className="btn-success">
          {INVOICE_STATES[invoice.status].display}
        </Button>
      );
      
    //   return (
    //     <>
    //       <Dropdown isOpen={isOpen} toggle={toggle}>
    //         <DropdownToggle color={INVOICE_STATES[invoice.status].color} caret>
    //           {INVOICE_STATES[invoice.status].display}
    //         </DropdownToggle>
    //         <DropdownMenu>
    //           {Object.keys(INVOICE_STATES)
    //             .filter((key) =>
    //               this.isStatusChangeAllowed(invoice, key)
    //             )
    //             .map((key) => (
    //               <DropdownItem
    //                 key={key}
    //                 onClick={() => this.confirm(key)}
    //                 disabled={INVOICE_STATES[key].value === invoice.status}
    //               >
    //                 {`${
    //                   INVOICE_STATES[key].value === invoice.status
    //                     ? INVOICE_STATES[key].display + ' - Selected'
    //                     : INVOICE_STATES[key].actionLabel
    //                 }`}
    //               </DropdownItem>
    //             ))}
    //         </DropdownMenu>
    //       </Dropdown>
    //       <ConfirmModal
    //         isOpen={this.confirmModalOpen}
    //         onConfirm={() => {
    //           this.updateInvoice();
    //           this.confirmModalOpen = false;
    //         }}
    //         title="Confirm Action"
    //         body={
    //           <>
    //             <p className="m-0">
    //               Updating Invoice from{' '}
    //               <span
    //                 className={`text-${INVOICE_STATES[invoice.status].color}`}
    //               >
    //                 {INVOICE_STATES[invoice.status].display}
    //               </span>
    //               {' to '}
    //               {INVOICE_STATES[this.status] ? (
    //                 <span
    //                   className={`text-${INVOICE_STATES[this.status].color}`}
    //                 >
    //                   {INVOICE_STATES[this.status].display}
    //                 </span>
    //               ) : null}
    //             </p>
    //             {this.getInvoiceMessage(invoice)}
    //           </>
    //         }
    //         toggleModal={() => (this.confirmModalOpen = !this.confirmModalOpen)}
    //         onCancel={() => {
    //           this.status = null;
    //           this.confirmModalOpen = false;
    //         }}
    //       />
    //     </>
    //   );
    }
    return null;
  }
}

export default InvoiceStatusSelect;
