import React from 'react';
import ReactTooltip from 'react-tooltip';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Container, HelpIcon } from './styles';
import { HelpCircleProps } from '.';

/** Help circle with predetermined tooltip for EAP and Public bookings.
 * Checks if the user has invoicing enabled.
 * @augments partnerBooking determines if the booking is an EAP booking.
 */
const HelpCircleBooking: React.FC<HelpCircleProps> = (props) => {
  const { tooltipText, colour, height, width, place, offset, key, partnerBooking } = props;

  let text = 'For Public Bookings you must invoice the client directly for the service.';

  if (partnerBooking) {
    text = `Payment for EAP clients is handled directly by Clearhead${
      props.auth?.currentPractitioner?.invoicingEnabled
        ? '.'
        : ', you should receive invoicing instructions in your email'
    }`;
  }

  return (
    <Container data-for={`help-circle_${key ?? ''}`} data-tip={tooltipText ?? text}>
      <HelpIcon width={width ?? '22px'} height={height ?? '22px'} colour={colour ?? '#003473'} />
      <ReactTooltip
        id={`help-circle_${key ?? ''}`}
        data-offset={offset ?? 'bottom'}
        place={place ?? 'bottom'}
        effect='solid'
        multiline
        border
      />
    </Container>
  );
};

export default withRouter(inject('auth')(observer(HelpCircleBooking)));
