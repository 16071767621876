import React, { Component } from 'react';
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col
} from 'reactstrap';
import moment from 'moment';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
class DateSelectModal extends Component {
  @observable dateInput = null;

  constructor(props) {
    super(props);
    this.dateInput = props.defaultDate
      ? moment(props.defaultDate).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
  }

  onSelectDate = e => {
    this.dateInput = e.target.value;
  };

  onConfirm = () => {
    const { setCalendarDate } = this.props;
    setCalendarDate(moment(this.dateInput));
  };

  render() {
    const { isModalOpen, toggleModal } = this.props;

    return (
      <Modal isOpen={isModalOpen} toggle={toggleModal} size="sm" centered>
        <ModalHeader className="bg-light" toggle={toggleModal}>
          Select A Date
        </ModalHeader>

        <ModalBody className="container-fluid px-6">
          <Row className="mb-3">
            <Col>
              <Input
                type="date"
                value={this.dateInput}
                onChange={this.onSelectDate}
              />
            </Col>
          </Row>
          <Row className="justify-content-center text-center">
            <Col>
              <Button onClick={this.onConfirm}>Confirm</Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default DateSelectModal;
