// @flow
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Col,
  Row,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from 'reactstrap';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';

import { GstCheckbox } from '../styles';
import { getName } from 'i18n-iso-countries';

const schema = Yup.object().shape({
  invoicingEnabled: Yup.boolean(),
  taxEnabled: Yup.boolean(),
  pricesIncludeTax: Yup.boolean(),
  taxNumber: Yup.string()
    .when('taxEnabled', {
      is: true,
      then: Yup.string().required('Required'),
    })
    .label('GST number'),
  bankAccountNumber: Yup.string().min(18).max(19).required('Required').label('Bank account number'),
  daysUntilInvoiceDue: Yup.string(),
  // paymentRemindersDays: Yup.string(),
  invoiceFrom: Yup.object().shape({
    name: Yup.string().required('Required'),
    streetAddress: Yup.string().required('Required'),
    suburb: Yup.string(),
    city: Yup.string().required('Required'),
    postCode: Yup.string().required('Required'),
    country: Yup.string(),
  }),
  invoiceFooter: Yup.string(),
});

const BankNumberRegex = /^[0-9]{0,2}-?[0-9]{0,4}-?[0-9]{0,7}-?[0-9]{0,3}$/;

type InvoiceFormProps = {
  initialFormState: any;
  mode?: any;
  onSubmit: any;
  formRef: any;
  disabled: boolean;
  setDisabled: (val: boolean) => void;
};

const InvoiceForm: React.FC<InvoiceFormProps> = ({
  initialFormState,
  onSubmit,
  formRef,
  disabled,
  setDisabled,
  mode,
}) => {
  React.useEffect(() => {
    if (initialFormState.bankAccountNumber) {
      setDisabled(true);
    }
  }, []);

  React.useEffect(() => {
    if (formRef?.current && mode === 'read-only') {
      formRef.current.resetForm();
    }
  }, [formRef, mode]);

  const getSubmitError = (status) => {
    if (status && status.message) {
      return <FormFeedback>{status.message}</FormFeedback>;
    }
    return null;
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialFormState}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          status,
          touched,
          setFieldValue,
          setTouched,
        } = formik;

        const fromNameTouched = touched.invoiceFrom && touched.invoiceFrom.name;
        const fromNameErrors = errors.invoiceFrom && errors.invoiceFrom.name;
        const fromStreetAddressTouched = touched.invoiceFrom && touched.invoiceFrom.streetAddress;
        const fromStreetAddressErrors = errors.invoiceFrom && errors.invoiceFrom.streetAddress;
        const fromSuburbTouched = touched.invoiceFrom && touched.invoiceFrom.suburb;
        const fromSuburbErrors = errors.invoiceFrom && errors.invoiceFrom.suburb;
        const fromCityTouched = touched.invoiceFrom && touched.invoiceFrom.city;
        const fromCityErrors = errors.invoiceFrom && errors.invoiceFrom.city;
        const fromPostCodeTouched = touched.invoiceFrom && touched.invoiceFrom.postCode;
        const fromPostCodeErrors = errors.invoiceFrom && errors.invoiceFrom.postCode;

        const countries = require('i18n-iso-countries');
        countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

        return (
          <Form
            onSubmit={() => {
              handleSubmit();
            }}
          >
            <Row form>
              <Col className='col-12 col-sm-6'>
                <FormGroup>
                  <Label className='mr-2'>GST Registered:</Label>

                  <GstCheckbox
                    id='checkEnableInput'
                    type='checkbox'
                    label='GST Registered'
                    className='mr-2'
                    checked={values.taxEnabled}
                    onChange={(e) => {
                      const value = e.target.checked;
                      setFieldValue('taxEnabled', value);
                      if (!value) {
                        setFieldValue('taxNumber', '');
                        setFieldValue('pricesIncludeTax', false);
                      }
                    }}
                  />
                  <Input
                    bsSize='lg'
                    name='taxNumber'
                    placeholder={values.taxEnabled ? 'Enter your GST number' : ''}
                    value={values.taxNumber}
                    onKeyDown={(e) => {
                      if (e.key === 'Backspace') {
                        if (values.taxNumber.length === 3 || values.taxNumber.length === 8) {
                          e.preventDefault();
                          setFieldValue('taxNumber', values.taxNumber.slice(0, -2));
                        }
                      }
                    }}
                    onChange={(e) => {
                      let userInput = e.target.value;
                      setFieldValue('taxNumber', userInput);
                    }}
                    onBlur={() => setTouched({ ...touched, taxNumber: true })}
                    invalid={touched.taxNumber && !!errors.taxNumber}
                    disabled={!values.taxEnabled}
                  />
                  {values.taxEnabled && touched.taxNumber && errors.taxNumber && (
                    <FormFeedback>{errors.taxNumber}</FormFeedback>
                  )}
                </FormGroup>
                {values.taxEnabled && (
                  <FormGroup>
                    <Label className='mr-2'>My prices are GST inclusive</Label>
                    <GstCheckbox
                      id='checkGSTInclusive'
                      type='checkbox'
                      className='mr-2'
                      checked={values.pricesIncludeTax}
                      onChange={(e) => {
                        setFieldValue('pricesIncludeTax', e.target.checked);
                      }}
                    />
                  </FormGroup>
                )}
              </Col>
              <Col className='col-12 col-sm-6'>
                <FormGroup>
                  <Label>Bank Account Number</Label>
                  <Input
                    bsSize='lg'
                    name='bankAccountNumber'
                    placeholder='xx-xxxx-xxxxxxx-xxx'
                    value={values.bankAccountNumber}
                    onKeyDown={(e) => {
                      if (e.key === 'Backspace') {
                        if (
                          values.bankAccountNumber.length === 3 ||
                          values.bankAccountNumber.length === 8 ||
                          values.bankAccountNumber.length === 16
                        ) {
                          e.preventDefault();
                          setFieldValue('bankAccountNumber', values.bankAccountNumber.slice(0, -2));
                        }
                      }
                    }}
                    onChange={(e) => {
                      let userInput = e.target.value;

                      if (BankNumberRegex.test(userInput)) {
                        if (
                          userInput.length === 2 ||
                          userInput.length === 7 ||
                          userInput.length === 15
                        ) {
                          userInput += '-';
                        }
                        setFieldValue('bankAccountNumber', userInput);
                      }
                    }}
                    onBlur={() => setTouched({ ...touched, bankAccountNumber: true })}
                    invalid={touched.bankAccountNumber && !!errors.bankAccountNumber}
                    disabled={disabled}
                  />
                  {touched.bankAccountNumber && errors.bankAccountNumber && (
                    <FormFeedback>{errors.bankAccountNumber}</FormFeedback>
                  )}
                  <Text className='text-sm italic mt-1'>
                    Once your Bank Account number is set, you can only update it by requesting help
                    from our support team.
                  </Text>
                </FormGroup>
              </Col>
            </Row>

            {/* <Row form>
          <Col className="col-12 col-sm-6">
            <FormGroup>
              <Label>Invoice Due Date</Label>
              <CustomInput
                type="radio"
                id="radio1Week"
                name="daysUntilInvoiceDue"
                label="1 Week"
                className="mb-2"
                checked={values.dueDate1Week}
                onChange={() => {
                  setFieldValue('daysUntilInvoiceDue', 7);
                  setFieldValue('dueDate1Week', true);
                  setFieldValue('dueDate2Week', false);
                  setFieldValue('dueDate3Week', false);
                  setFieldValue('dueDate4Week', false);
                }}
              />
              <CustomInput
                type="radio"
                id="radio2Week"
                name="daysUntilInvoiceDue"
                label="2 Weeks"
                className="mb-2"
                checked={values.dueDate2Week}
                onChange={() => {
                  setFieldValue('daysUntilInvoiceDue', 14);
                  setFieldValue('dueDate2Week', true);
                  setFieldValue('dueDate1Week', false);
                  setFieldValue('dueDate3Week', false);
                  setFieldValue('dueDate4Week', false);
                }}
              />
              <CustomInput
                type="radio"
                id="radio3Week"
                name="daysUntilInvoiceDue"
                label="3 Weeks"
                className="mb-2"
                checked={values.dueDate3Week}
                onChange={() => {
                  setFieldValue('daysUntilInvoiceDue', 21);
                  setFieldValue('dueDate3Week', true);
                  setFieldValue('dueDate1Week', false);
                  setFieldValue('dueDate2Week', false);
                  setFieldValue('dueDate4Week', false);
                }}
              />
              <CustomInput
                type="radio"
                id="radio4Week"
                name="daysUntilInvoiceDue"
                label="4 Weeks"
                className="mb-2"
                checked={values.dueDate4Week}
                onChange={() => {
                  setFieldValue('daysUntilInvoiceDue', 28);
                  setFieldValue('dueDate4Week', true);
                  setFieldValue('dueDate1Week', false);
                  setFieldValue('dueDate2Week', false);
                  setFieldValue('dueDate3Week', false);
                }}
              />
            </FormGroup>
          </Col>
        </Row> */}

            <Row form className='mt-2'>
              <Col>
                <Card className='bg-light'>
                  <CardHeader className='bg-light'>
                    <CardTitle tag='h5' className='mb-0'>
                      Invoice From
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row form>
                      <Col className='col-12 col-sm-6'>
                        <FormGroup>
                          <Label>Name</Label>
                          <Input
                            bsSize='lg'
                            name='invoiceFrom[name]'
                            value={values.invoiceFrom.name}
                            onBlur={() => {
                              setTouched({
                                ...touched,
                                invoiceFrom: {
                                  ...touched.invoiceFrom,
                                  name: true,
                                },
                              });
                            }}
                            onChange={handleChange}
                            invalid={fromNameTouched && fromNameErrors}
                          />
                          {fromNameTouched && fromNameErrors && (
                            <FormFeedback className='d-block'>{fromNameErrors}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col className='col-12 col-sm-6'>
                        <FormGroup>
                          <Label>Street Address</Label>
                          <Input
                            bsSize='lg'
                            name='invoiceFrom[streetAddress]'
                            value={values.invoiceFrom.streetAddress}
                            onBlur={() =>
                              setTouched({
                                ...touched,
                                invoiceFrom: {
                                  ...touched.invoiceFrom,
                                  streetAddress: true,
                                },
                              })
                            }
                            onChange={handleChange}
                            invalid={fromStreetAddressTouched && fromStreetAddressErrors}
                          />
                          {fromStreetAddressTouched && fromStreetAddressErrors && (
                            <FormFeedback className='d-block'>
                              {fromStreetAddressErrors}
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row form>
                      <Col className='col-12 col-sm-6'>
                        <FormGroup>
                          <Label>
                            Suburb <em>- Optional</em>
                          </Label>
                          <Input
                            bsSize='lg'
                            name='invoiceFrom[suburb]'
                            value={values.invoiceFrom.suburb}
                            onBlur={() =>
                              setTouched({
                                ...touched,
                                invoiceFrom: {
                                  ...touched.invoiceFrom,
                                  suburb: true,
                                },
                              })
                            }
                            onChange={handleChange}
                            invalid={fromSuburbTouched && fromSuburbErrors}
                          />
                          {fromSuburbTouched && fromSuburbErrors && (
                            <FormFeedback className='d-block'>{fromSuburbErrors}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col className='col-12 col-sm-6'>
                        <FormGroup>
                          <Label>City</Label>
                          <Input
                            bsSize='lg'
                            name='invoiceFrom[city]'
                            value={values.invoiceFrom.city}
                            onBlur={() =>
                              setTouched({
                                ...touched,
                                invoiceFrom: {
                                  ...touched.invoiceFrom,
                                  city: true,
                                },
                              })
                            }
                            onChange={handleChange}
                            invalid={fromCityTouched && fromCityErrors}
                          />
                          {fromCityTouched && fromCityErrors && (
                            <FormFeedback className='d-block'>{fromCityErrors}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row form>
                      <Col className='col-12 col-sm-6'>
                        <FormGroup>
                          <Label>Post Code</Label>
                          <Input
                            bsSize='lg'
                            name='invoiceFrom[postCode]'
                            value={values.invoiceFrom.postCode}
                            onBlur={() =>
                              setTouched({
                                ...touched,
                                invoiceFrom: {
                                  ...touched.invoiceFrom,
                                  postCode: true,
                                },
                              })
                            }
                            onChange={handleChange}
                            invalid={fromPostCodeTouched && fromPostCodeErrors}
                          />
                          {fromPostCodeTouched && fromPostCodeErrors && (
                            <FormFeedback className='d-block'>{fromPostCodeErrors}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col className='col-12 col-sm-6'>
                        <FormGroup>
                          <Label>Country</Label>
                          <Input
                            bsSize='lg'
                            name='invoiceFrom[country]'
                            value={getName(values.invoiceFrom.country, 'en') ?? 'New Zealand'}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row form>
              <Col>
                <FormGroup>
                  <Label>
                    Invoice Footer <em>- Optional, max length 255 characters</em>
                  </Label>
                  <Input
                    bsSize='lg'
                    type='textarea'
                    name='invoiceFooter'
                    maxLength={255}
                    value={values.invoiceFooter}
                    onBlur={() => setTouched({ ...touched, invoiceFooter: true })}
                    onChange={handleChange}
                    invalid={touched.invoiceFooter && errors.invoiceFooter}
                  />
                  {touched.invoiceFooter && errors.invoiceFooter && (
                    <FormFeedback className='d-block'>{errors.invoiceFooter}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>

            {getSubmitError(status)}
          </Form>
        );
      }}
    </Formik>
  );
};

export default InvoiceForm;
