/* Third party */
import React from "react";
import { Col, Row, Container, Card, CardBody } from "reactstrap";

/* Components */
import { HeadingText, Text } from "./styles";
import { Section, HeaderTitle, SectionSpacing } from '../../styles';
/* Functions */

const ValueSection = () => (
  <Section backgroundcolor="#f6f9fc">
    <SectionSpacing>
      <Container>
        <Row className="d-flex justify-content-center mb-4">
          <Col>
            <HeaderTitle className="text-center">Our Core Values</HeaderTitle>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={3} className="text-center">
            <Card>
              <CardBody>
                <HeadingText>Manaakitanga</HeadingText>
                <Text>
                  A uniquely Māori principle that captures the idea of caring,
                  supporting and uplifting others. Our platform takes a personalised
                  approach to guide our users to get the help they need.
                </Text>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={3} className="text-center">
            <Card>
              <CardBody>
                <HeadingText>Collaboration</HeadingText>
                <Text>
                  We work in partnership with our users and mental health
                  professionals to design a platform that our users love. Delivering
                  impact at scale through the communities we foster.
                </Text>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={3} className="text-center">
            <Card>
              <CardBody>
                <HeadingText>Inspire Excellence</HeadingText>
                <Text>
                  We create a culture that empowers our people to take initiative
                  and ownership to build a platform they are proud of and
                  continuously improve upon.
                </Text>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={3} className="text-center">
            <Card>
              <CardBody>
                <HeadingText>Be Brave</HeadingText>
                <Text>
                  We are purpose-driven and committed to achieving our Big Hairy
                  Audacious Goal (Our Vision) in an innovative manner.
                </Text>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </SectionSpacing>
  </Section>
);

export default ValueSection;
