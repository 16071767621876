import React, { Component } from 'react';
import { Loading } from 'common';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

const Link = styled.label`
  color: var(--blue);
  text-decoration: underline;
  cursor: pointer;
`;

@inject('calendarSync')
@withRouter
@observer
class CalendarSyncConfirm extends Component {
  @observable emailValidated = false;
  @observable loading = true;
  @observable emailAddressError = false;
  @observable errorMessage = null;

  componentDidMount = () => {
    const {
      location: { search },
    } = this.props;

    let requestObj = {};
    if (search) {
      let hashes = search.slice(search.indexOf('?') + 1).split('&');
      let params = {};
      hashes.forEach((hash) => {
        let [key, val] = hash.split('=');
        params[key] = decodeURIComponent(val);
      });

      if (params.error) {
        if (params.error === 'access_denied') {
          this.errorMessage =
            'Access to your Calendar was denied. Please try again and grant Clearhead Access to your Calendar';
          this.loading = false;
        } else {
          this.errorMessage = params.error;
          this.loading = false;
        }
      } else if (!params.code) {
        this.errorMessage = 'Invalid Address';
        this.loading = false;
      } else {
        requestObj = { authCode: params.code };
        this.connectToCalendar(requestObj);
      }
    } else {
      this.errorMessage = 'Invalid Address';
      this.loading = false;
    }
  };

  connectToCalendar = (requestObj) => {
    const { calendarSync, history } = this.props;
    this.loading = true;
    if (requestObj) {
      calendarSync.connectToCalendar(requestObj).then((response) => {
        if (!response.hasError) {
          this.loading = false;
          const inOnboarding = sessionStorage.getItem(
            'calendar-sync-onboarding'
          );
          if (inOnboarding) {
            sessionStorage.removeItem('calendar-sync-onboarding');
            history.push('/app/setup-wizard');
          } else {
            history.push('/app/my-account?section=calendar-sync');
          }
        } else {
          if (response.status === 302) {
            window.location = response.data;
          } else {
            if (response.errors && response.errors.length > 0) {
              this.errorMessage = response.errors[0].defaultMessage;
            } else {
              this.errorMessage = response.message;
            }
            this.emailValidated = false;
          }
        }
        this.loading = false;
      });
    }
  };

  returnFromError = () => {
    this.props.history.push('/app/my-account');
  };

  returnToCalendar = () => {
    this.props.history.push('/app/appointment-calendar');
  };

  render() {
    if (this.loading) {
      return (
        <div>
          <p className="h4"> Connecting to your Calendar... </p> <Loading />
        </div>
      );
    } else if (this.errorMessage) {
      return (
        <div>
          <p className="h3">
            An error occurred while connecting to your Calendar:
          </p>{' '}
          <p className="h4"> {this.errorMessage} </p>{' '}
          <p>
            Click <Link onClick={this.returnFromError}> here </Link> to return
            to the my account page{' '}
          </p>{' '}
        </div>
      );
    } else if (!this.loading) {
      return (
        <div>
          <p className="h2"> Calendar Connected Successfully </p>{' '}
          <p className="h4">
            Your Calendar is currently being synced in the background.Depending
            on the size of your calendar this may take up to an hour.You can
            safely close this window.{' '}
          </p>{' '}
          <p>
            Click <Link onClick={this.returnToCalendar}> here </Link> to return
            to your calendar{' '}
          </p>{' '}
        </div>
      );
    }
  }
}

export default CalendarSyncConfirm;
