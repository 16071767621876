import { SVGProps } from 'react';
const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.7165 16.5616L4.51888 16.5758C3.79262 16.5773 3.20431 15.9742 3.20358 15.2293L3.19409 6.27217C3.19336 5.52736 3.78021 4.92417 4.50501 4.92267L12.7026 4.90918C13.4282 4.90768 14.0165 5.51087 14.0179 6.25493L14.0274 15.2129C14.0282 15.9562 13.4406 16.5609 12.7165 16.5616Z'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.0229 10.3251L19.0273 14.9089L16.5273 12.6212L14.0273 10.3343L16.5221 8.03746L19.0176 5.74219L19.0229 10.3251Z'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default ArrowRight;
