"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = applyStyleModifiers;

var _constants = require("./constants");

var _isResponsiveModifiersProp = _interopRequireDefault(require("./utils/isResponsiveModifiersProp"));

var _modifiedStyles = _interopRequireDefault(require("./utils/modifiedStyles"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Returns a function that evaluates a modifiersConfig object against a component's props.
 * This function will return a string of CSS styles based on those inputs.
 * @export
 * @param {ModifiersConfig} modifiersConfig
 * @param {string} [modifiersPropName="modifiers"]
 * @returns
 */
function applyStyleModifiers(modifiersConfig) {
  var modifiersPropName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'modifiers';
  return function (props) {
    var modifiers = props[modifiersPropName];

    if ((0, _isResponsiveModifiersProp.default)(modifiers)) {
      return (0, _modifiedStyles.default)(modifiers[props.size || _constants.DEFAULT_MODIFIERS_KEY], modifiersConfig, props);
    }

    return (0, _modifiedStyles.default)(modifiers, modifiersConfig, props);
  };
}