import styled from 'styled-components';
import * as Icon from 'react-feather';

import theme from 'styles/theme';

export const RowRemove = styled(Icon.MinusCircle)`
  color: ${theme.danger};

  &:hover {
    cursor: pointer;
  }
`;

export const AddNewLineItem = styled.tr`
  td {
    padding: 16px 10px 16px 10px;
    color: ${theme.success};
  }
  &:hover {
    cursor: pointer;
    & > td {
      color: ${theme.success};
    }
  }
`;
