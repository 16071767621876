import BaseService from '../BaseService';
import { BASE_API_URL } from 'configuration';

class PractitionerService {
  static signUp = (data) => {
    return BaseService.post(`${BASE_API_URL}/practitioners/register`, data);
  };

  static fetchPractitioner = (practitionerId) => {
    return BaseService.get(`${BASE_API_URL}/practitioners/${practitionerId}`);
  };

  static updatePractitioner = (data) => {
    return BaseService.put(`${BASE_API_URL}/practitioners/${data.id}`, data);
  };

  static updatePractitionerOriginalImage = (practitionerId, formData) => {
    return BaseService.post(
      `${BASE_API_URL}/practitioners/${practitionerId}/uploadImage?imageType=original`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
  };

  static updatePractitionerResizedImage = (practitionerId, formData) => {
    return BaseService.post(
      `${BASE_API_URL}/practitioners/${practitionerId}/uploadImage`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
  };

  static getTags = () => {
    return BaseService.get(`${BASE_API_URL}/tags`);
  };

  static getLanguages = () => {
    return BaseService.get(`${BASE_API_URL}/languages`);
  };

  static getPractitionerIntegrations = () => {
    return BaseService.get(`${BASE_API_URL}/integration`);
  };

  static updatePractitionerIntegrations = (data) => {
    return BaseService.put(
      `${BASE_API_URL}/integration/${data.externalSystem.toLowerCase()}`,
      data
    );
  };

  static getGoogleAuthorizationUrl = () => {
    return BaseService.get(`${BASE_API_URL}/integration/google/signin`);
  };

  static connectToGoogleCalendar = (data) => {
    return BaseService.post(`${BASE_API_URL}/integration/google/signin`, data);
  };

  static disconnectFromGoogleCalendar = () => {
    return BaseService.delete(`${BASE_API_URL}/integration/google`);
  };

  static syncWithGoogle = () => {
    return BaseService.get(`${BASE_API_URL}/integration/google/sync`);
  };

  static getOutlookAuthorizationUrl = () => {
    return BaseService.get(`${BASE_API_URL}/integration/outlook/signin`);
  };

  static connectToOutlookCalendar = (data) => {
    return BaseService.post(`${BASE_API_URL}/integration/outlook/signin`, data);
  };
  static disconnectFromOutlookCalendar = () => {
    return BaseService.delete(`${BASE_API_URL}/integration/outlook`);
  };

  static syncWithOutlook = () => {
    return BaseService.get(`${BASE_API_URL}/integration/outlook/sync`);
  };

  static getAppointments = (practitionerId, startDate, endDate) => {
    return BaseService.get(
      `${BASE_API_URL}/practitioners/${practitionerId}/sessions?startDateTime=${startDate}&endDateTime=${endDate}`
    );
  };
}

export default PractitionerService;
