import styled from 'styled-components';

import CHLogoSvg from 'jsx:assets/svg/ch-logo.svg';
import CHTextSvg from 'jsx:assets/svg/ch-text.svg';

export const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	margin-bottom: 20px;

	@media screen and (max-width: 765px) {
		height: 30px;
	}
`;

export const CHLogo = styled(CHLogoSvg)`
	width: 40px;
	margin-right: 15px;

	path {
		fill: #4987ce;
	}

	@media screen and (max-width: 765px) {
		width: 30px;
	}
`;

export const CHText = styled(CHTextSvg)`
	width: 240px;

	path {
		fill: #4987ce;
	}

	@media screen and (max-width: 765px) {
		width: 180px;
	}
`;
