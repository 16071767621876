import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { HelpCircle, Settings } from 'react-feather';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withKeycloak } from '@react-keycloak-fork/web';
import ReactTooltip from 'react-tooltip';

import { MissingAvatar } from 'common';
import Link from '../common/Link';

const Image = styled.img`
  object-fit: cover;
`;

@withKeycloak
@withRouter
@inject('app', 'auth')
@observer
class NavbarComponent extends Component {
  getPractitionerImage = () => {
    const {
      auth: { userImage },
    } = this.props;

    if (userImage) {
      return <Image src={userImage} className='avatar img-fluid rounded-circle mr-1' />;
    }
    return <MissingAvatar />;
  };

  navigateMyAccount = () => {
    const { history } = this.props;
    history.push('/app/my-account');
  };

  signOut = () => {
    const { auth, history, keycloak } = this.props;
    history.push('/');
    auth.logout();
    keycloak.logout();
  };

  toggleFreshdeskWidget = () => {
    try {
      (window as any).FreshworksWidget('open');
    } catch (error) {
      console.error("Couldn't toggle Freshworks widget");
    }
  };

  render() {
    const {
      app,
      auth: { currentPractitioner },
      sidebarToggle = true,
    } = this.props;

    return (
      <Navbar color='white' light expand container={false} className='border-b'>
        {sidebarToggle && (
          <span className='sidebar-toggle d-flex mr-2' onClick={app.toggleSidebarVisibility}>
            <i className='hamburger align-self-center' />
          </span>
        )}

        <Collapse navbar cssModule={{ collapse: 'bs-collapse' }}>
          <Nav className='ml-auto flex items-center' navbar>
            <div className='mr-2' data-for='help-circle-groove' data-tip='Need help? Click here'>
              <Link onClick={this.toggleFreshdeskWidget} className='nav-link'>
                <HelpCircle className='align-middle w-5 h-5 sm:w-6 sm:h-6' />
              </Link>
              <ReactTooltip
                id='help-circle-groove'
                data-offset='bottom'
                place='bottom'
                effect='solid'
                multiline
                border
              />
            </div>
            <UncontrolledDropdown nav inNavbar>
              <span className='d-inline-block d-sm-none h-100'>
                <DropdownToggle className='h-100 flex items-center' nav>
                  <Settings size={20} className='align-middle' />
                </DropdownToggle>
              </span>
              <span className='d-none d-sm-inline-block h-100'>
                <DropdownToggle className='h-100 d-inline-flex align-items-center' nav caret>
                  {this.getPractitionerImage()}
                  <span className='text-dark text-base mr-1'>
                    {currentPractitioner && currentPractitioner.name}
                  </span>
                </DropdownToggle>
              </span>
              <DropdownMenu end>
                <DropdownItem onClick={this.navigateMyAccount}>My account</DropdownItem>
                <DropdownItem onClick={this.signOut}>Sign out</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default NavbarComponent;
