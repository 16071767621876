import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { withKeycloak } from '@react-keycloak-fork/web';
import { SignUpCompleted } from 'modules/auth/components/SignUpCompleted';
import {
  ProfileForm,
  ContactForm,
  CountryForm,
  // BioForm,
} from 'modules/auth/components/SignUpForms';
import { LogoContainer, CHLogo, CHText, Number, NumberLabel, FormTitle, Important } from './styles';

@withKeycloak
@withRouter
@observer
class SignUp extends Component {
  @observable currentSection = 'country';
  @observable showCompleted = false;
  @observable formState = {
    contactEmailAddress: '',
    firstName: '',
    lastName: '',
    professions: [],
    websiteAddress: '',
  };

  componentDidMount() {
    const { keycloak, history } = this.props;
    if (keycloak.authenticated && keycloak.tokenParsed.scope.includes('practitioner')) {
      history.push('/app');
    }
  }

  onLoginComplete = (values) => {
    this.formState = values;
    this.currentSection = 'country';
  };

  onProfileComplete = (values) => {
    this.formState = values;
    this.showCompleted = true;
  };

  onContactComplete = (values) => {
    this.formState = values;
    this.currentSection = 'profile';
  };

  onCountryComplete = (values) => {
    this.formState = values;
    this.currentSection = 'contact';
  };

  goBack = (to) => {
    this.currentSection = to;
  };

  getCurrentSection = (currSection) => {
    switch (currSection) {
      case 'profile':
        return (
          <>
            <div className='d-flex flex-row align-items-center mb-3'>
              <Number>
                <NumberLabel>3</NumberLabel>
              </Number>
              <FormTitle>Profile Information</FormTitle>
            </div>
            <ProfileForm
              formState={this.formState}
              onBack={this.goBack.bind(this, 'contact')}
              onComplete={this.onProfileComplete}
            />
          </>
        );
      case 'contact':
        return (
          <>
            <div className='d-flex flex-row align-items-center mb-3'>
              <Number>
                <NumberLabel>2</NumberLabel>
              </Number>
              <FormTitle>Contact Information</FormTitle>
            </div>
            <ContactForm
              formState={this.formState}
              onBack={this.goBack.bind(this, 'country')}
              onComplete={this.onContactComplete}
            />
          </>
        );
      case 'country':
        return (
          <>
            <div className='d-flex flex-row align-items-center mb-3'>
              <Number>
                <NumberLabel>1</NumberLabel>
              </Number>
              <FormTitle>Location</FormTitle>
            </div>
            <CountryForm
              formState={this.formState}
              // onBack={this.goBack.bind(this, 'contact')}
              onComplete={this.onCountryComplete}
            />
          </>
        );

      default:
        return null;
    }
  };

  render() {
    if (this.showCompleted) {
      return <SignUpCompleted formState={this.formState} />;
    }

    return (
      <>
        <div className='text-center mt-4 mb-4'>
          <LogoContainer>
            <CHLogo />
            <CHText />
          </LogoContainer>
          <p className='lead h5'>Sign up as a provider</p>
        </div>

        <Card>
          <CardBody>
            <Important className='text-center'>
              You must be a registered mental health professional to create an account
            </Important>
            <div className='m-sm-4'>{this.getCurrentSection(this.currentSection)}</div>
            <a className='d-block text-center mb-1' href='/app'>
              Already have an account? Sign in here
            </a>
            <a
              className='d-block text-center'
              href='https://www.myclearhead.com/app'
              rel='noopener noreferrer'
            >
              Not a mental health professional? Sign up here
            </a>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default SignUp;
