import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Button } from 'reactstrap';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';

type FinishScreenProps = {
  history: any;
  message: string;
};

const FinishScreen: React.FC<FinishScreenProps> = ({ history, message }) => {
  return (
    <>
      <Heading as='h4' className='text-dark-blue mb-4'>
        Your appointment has been completed.
      </Heading>
      <Text className='mb-12 max-w-prose'>{message}</Text>
      <Button
        onClick={() => history.push('/app/bookings')}
        color='primary'
        size='lg'
        className='rounded-full h-10 w-40'
      >
        Back to bookings
      </Button>
    </>
  );
};

export default withRouter(inject()(observer(FinishScreen)));
