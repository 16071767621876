"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = applyResponsiveStyleModifiers;

var _constants = require("./constants");

var _isResponsiveModifiersProp = _interopRequireDefault(require("./utils/isResponsiveModifiersProp"));

var _modifiedStyles = _interopRequireDefault(require("./utils/modifiedStyles"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * TODO: (DEPRECATED) The functionality provided here is now incorporated into
 * `styleModifierPropTypes`. This should be removed before the v2 release.
 */

/**
 * Returns a function that evaluates a modifiersConfig object against a component's props,
 * including a size prop. This function will return a string of CSS styles based on those inputs.
 * @export
 * @param {ModifiersConfig} modifiersConfig
 * @param {string} [modifiersPropName="responsiveModifiers"]
 * @returns
 */
function applyResponsiveStyleModifiers(modifiersConfig) {
  var modifiersPropName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'responsiveModifiers';
  return function (props) {
    var responsiveModifiers = props[modifiersPropName];

    if ((0, _isResponsiveModifiersProp.default)(responsiveModifiers)) {
      return (0, _modifiedStyles.default)(responsiveModifiers[props.size || _constants.DEFAULT_MODIFIERS_KEY], modifiersConfig, props);
    }

    return null;
  };
}