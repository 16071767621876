import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import FreshdeskLink from 'common/FreshdeskLink';

type BasicProfessionalInfoForm = {
  auth: any;
};

const BasicProfessionalInfoForm: React.FC<BasicProfessionalInfoForm> = ({ auth }) => {
  const { profession, professionalStartDate, dateOfBirth } = auth.currentPractitioner;

  const getFormattedDate = (date: string) => {
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  };

  return (
    <div>
      <Row form className='mb-4'>
        <Col>
          <FormGroup>
            <Label>Profession:</Label>
            <div className='text-base'>{profession}</div>
          </FormGroup>
        </Col>
      </Row>

      <Row form className='mb-4'>
        <Col>
          <FormGroup>
            <Label>Date of registration:</Label>
            {/* change after api changes deployed */}
            <div className='text-base'>
              {professionalStartDate ? getFormattedDate(professionalStartDate) : '--/--/----'}
            </div>
          </FormGroup>
        </Col>
      </Row>

      <Row form className='mb-4'>
        <Col>
          <FormGroup>
            <Label>Date of birth:</Label>
            {/* change after api changes deployed */}
            <div className='text-base'>
              {dateOfBirth ? getFormattedDate(dateOfBirth) : 'No DOB registered'}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <div className=' mb-4 flex-row lg:flex text-center lg:text-left mx-auto'>
        <FreshdeskLink>
          To change your basic information, please contact therapist support.
        </FreshdeskLink>
      </div>
    </div>
  );
};

export default withRouter(inject('auth')(observer(BasicProfessionalInfoForm)));
