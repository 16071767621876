import { SVGProps } from 'react';
const Filter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 25 25' {...props}>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2.105}
      d='M4.7 7.438h17.172M6.864 12.65h12.843M9.82 17.861h6.933'
    />
  </svg>
);
export default Filter;
