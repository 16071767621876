import BaseService from '../BaseService';
import { BASE_API_URL } from 'configuration';

class ScreeningService {
	static fetchQuestions = () => {
		return BaseService.get(`${BASE_API_URL}/practitioners/me/forms`);
	};

	static updateQuestions = data => {
		return BaseService.put(`${BASE_API_URL}/practitioners/me/forms`, data);
	};
}

export default ScreeningService;
