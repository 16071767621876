import styled from 'styled-components';

import CHLogoSvg from 'jsx:assets/svg/ch-logo.svg';
import GoogleLogoSvg from 'jsx:assets/svg/google.svg';
import OutlookLogoSvg from 'jsx:assets/svg/outlook.svg';

export const Icon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background-color: white;
	padding: 3px;
`;

export const CHLogo = styled(CHLogoSvg)`
	width: 12px;

	path {
		fill: #4987ce;
	}
`;

export const GoogleLogo = styled(GoogleLogoSvg)`
	width: 12px;
`;

export const OutlookLogo = styled(OutlookLogoSvg)`
	width: 12px;
`;