import BaseService from '../BaseService';
import { BASE_API_URL } from 'configuration';

class CalendarSyncService {
  static connectToCalendar = (data) => {
    return BaseService.post(
      `${BASE_API_URL}/practitioners/me/calendarsync/connect`,
      data
    );
  };
  static getCalendarSyncToken = () => {
    return BaseService.get(
      `${BASE_API_URL}/practitioners/me/calendarsync/token`
    );
  };

  static getSyncedCalendars = () => {
    return BaseService.get(
      `${BASE_API_URL}/practitioners/me/calendarsync/calendars`
    );
  };
  static saveSyncedCalendars = (data) => {
    return BaseService.post(
      `${BASE_API_URL}/practitioners/me/calendarsync/calendars`,
      data
    );
  };
}

export default CalendarSyncService;
