import BaseService from '../BaseService';
import { BASE_API_URL } from 'configuration';

class PriceService {
	static getPrices = () => {
		return BaseService.get(`${BASE_API_URL}/practitioners/me/charges`);
	};
	static createPrice = data => {
		return BaseService.post(`${BASE_API_URL}/practitioners/me/charges`, data);
	};
	static deletePrice = id => {
		return BaseService.delete(`${BASE_API_URL}/practitioners/me/charges/${id}`);
	};
	static editPrice = data => {
		return BaseService.put(`${BASE_API_URL}/practitioners/me/charges`, data);
	};
}

export default PriceService;
