import styled from "styled-components";

import CHLogoWhiteSvg from "jsx:assets/svg/ch-logo-white.svg";
import CHTextWhiteSvg from "jsx:assets/svg/ch-text-white.svg";
import CHLogoSvg from "jsx:assets/svg/ch-logo.svg";
import CHTextSvg from "jsx:assets/svg/ch-text.svg";

export const CHLogo = styled(CHLogoSvg)`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  margin-right: 10px;
`;

export const CHText = styled(CHTextSvg)`
  width: 140px;
`;

export const CHLogoWhite = styled(CHLogoWhiteSvg)`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  margin-right: 10px;
`;

export const CHTextWhite = styled(CHTextWhiteSvg)`
  width: 140px;
`;
