import React from 'react';
import { observer, inject } from 'mobx-react';
import { FooterNav, wizardContainerStyle } from '../WizardForm/styles';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import { WizardFormContext } from '../index';

const ReOnboardingIntroStep = inject('auth')(
  observer(() => {
    const { onPrevious, onNext, currentStep } = React.useContext(WizardFormContext);
    const [isLoading, setIsLoading] = React.useState(false);

    const onSubmit = React.useCallback(async () => {
      setIsLoading(true);
      await onNext();
    }, [onNext]);

    const goBack = async () => {
      setIsLoading(true);
      await onPrevious();
    };

    return (
      <>
        <div className={wizardContainerStyle}>
          <div className='flex flex-col w-full items-center'>
            <div className='max-w-lg text-center'>
              <Heading className='text-dark-blue mb-8' as='h3'>
                We need you to update your profile
              </Heading>
              <Text className='mb-2'>
                We're releasing a new version of our Find a Therapist search engine soon!
              </Text>
              <Text className='mb-2'>
                We have included new therapist “tags” to improve our ability for clients to find the
                right therapist for them. We believe this will support you to form strong
                therapeutic alliances and ultimately lead to better outcomes for everyone.
              </Text>
              <Text className='mb-4'>
                In order to do this, we need you to update your profile. Once you do so, you’ll be
                able to enter your portal once again.
              </Text>
              <Text className='mb-8'>We apologise for the inconvenience.</Text>
            </div>

            <FooterNav className='md:mt-16 xs:mt-8'>
              <div className='flex justify-start'>
                {currentStep > 0 && (
                  <Button
                    variant='primary'
                    modifiers={'inverted'}
                    onClick={goBack}
                    disabled={isLoading}
                  >
                    Back
                  </Button>
                )}
              </div>
              <div className='flex justify-end'>
                <Button
                  variant='primary'
                  onClick={onSubmit}
                  data-test-id='complete-hear-about-us'
                  disabled={isLoading}
                >
                  Next
                </Button>
              </div>
            </FooterNav>
          </div>
        </div>
      </>
    );
  })
);
export default observer(ReOnboardingIntroStep);
