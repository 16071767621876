// @flow
import React, { Component } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import { Form, FormGroup, FormFeedback, Label, Input, Col, Row, Button } from 'reactstrap';
import Select from 'react-select';
import { observable, toJS } from 'mobx';

import { FundingOptions } from 'configuration';
import { GenderOptions } from '../../../../common/Data/data';
import { EthnicityOptions } from '../../../../common/Data/ethnicityData';
import { TextareaAutosize } from './styles';
import PractitionerService from 'jsx:services/PractitionerService';

const schema = Yup.object().shape({
  fullBiography: Yup.string().required('Required'),
});

@inject('auth', 'practitioners')
@withRouter
@observer
class ProfessionalInfoForm extends Component {
  formRef = null;

  @observable forceErrors = false;

  static defaultProps = {
    onComplete: () => {},
  };

  @observable loading = true;
  @observable selectedSpecialisationTags = [];
  @observable selectedClientTags = [];
  @observable selectedTherapyTags = [];
  @observable selectedHighlightedTags = [];
  @observable languageOptions = [];
  @observable selectedLanguages = [];
  @observable selectedEthnicities = [];
  @observable selectedFundingOptions = [];

  constructor(props) {
    super(props);

    const promises = [];

    promises.push(props.practitioners.getTags());

    promises.push(
      PractitionerService.getLanguages().then((res) => {
        const tempLanguageOptions = res.data?.map((lang) => ({
          value: lang.languageCode,
          label: lang.languageName,
        }));

        this.languageOptions = tempLanguageOptions;
      })
    );

    Promise.all(promises).then(() => {
      this.setSelectedTags();
      this.loading = false;
    });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.mode === 'edit' && this.props.mode === 'read-only') {
      if (this.formRef) {
        this.formRef.resetForm();
      }
    }
  };

  setSelectedTags = () => {
    if (this.props.auth.currentPractitioner && this.languageOptions) {
      const {
        tags,
        fundingOptions,
        ethnicity = [],
        languages = [],
        highlightedTags,
      } = this.props.auth.currentPractitioner;

      let specialisationTags = [];
      let clientTags = [];
      let therapyTags = [];
      tags.forEach((tag) => {
        if (tag.tagType === 'Specialization') {
          specialisationTags.push({ label: tag.description ?? tag.name, value: tag.name, tag });
        } else if (tag.tagType === 'ClientType') {
          clientTags.push({ label: tag.description ?? tag.name, value: tag.name, tag });
        } else if (tag.tagType === 'TherapyType') {
          therapyTags.push({ label: tag.description ?? tag.name, value: tag.name, tag });
        }
      });

      this.selectedSpecialisationTags = specialisationTags;
      this.selectedClientTags = clientTags;
      this.selectedTherapyTags = therapyTags;

      this.selectedHighlightedTags = highlightedTags.map((t) => {
        return { label: t.description ?? t.name, value: t.name, t };
      });

      this.selectedFundingOptions = fundingOptions.map((value) => ({
        label: value,
        value: value,
      }));

      this.selectedLanguages = this.languageOptions.filter((l) => languages.includes(l.value));

      this.selectedEthnicities = ethnicity.map((value) => ({
        label: value,
        value,
      }));
    }
  };

  getSubmitError = (status) => {
    if (status && status.message) {
      return <FormFeedback>{status.message}</FormFeedback>;
    }
    return null;
  };

  onValidate = (values) => {
    let errors = {};
    this.forceErrors = false;

    if (!values.specialisationTags || values.specialisationTags.length === 0) {
      errors.specialisationTags = 'Must have at least one tag selected.';
      this.forceErrors = true;
    }

    if (!values.clientTags || values.clientTags.length === 0) {
      errors.clientTags = 'Must have at least one tag selected.';
      this.forceErrors = true;
    }

    if (!values.therapyTags || values.therapyTags.length === 0) {
      errors.therapyTags = 'Must have at least one tag selected.';
      this.forceErrors = true;
    }

    return errors;
  };

  render() {
    const {
      auth,
      practitioners: { allTags, specialisationTags, clientTags, therapyTags },
      onComplete = () => {},
      mode = 'read-only',
    } = this.props;

    const disabled = mode === 'read-only';

    const formState = {
      ...auth.currentPractitioner,
      biography: auth.currentPractitioner.fullBiography,
      qualifications: auth.currentPractitioner.qualifications.join(', '),
      specialisationTags: this.selectedSpecialisationTags,
      clientTags: this.selectedClientTags,
      therapyTags: this.selectedTherapyTags,
      highlightedTags: this.selectedHighlightedTags,
      fundingOptions: this.selectedFundingOptions,
      languages: this.selectedLanguages,
      ethnicity: this.selectedEthnicities,
      gender: GenderOptions.filter(
        (gender: any) => gender.value === auth.currentPractitioner.gender
      ),
    };

    return (
      <Formik
        innerRef={(ref) => (this.formRef = ref)}
        initialValues={formState}
        validationSchema={schema}
        enableReinitialize={true}
        validate={this.onValidate}
        onSubmit={(values, actions) => {
          const highlightedTags = values.highlightedTags.map((a) => a.value);
          if (highlightedTags.length < 3 || highlightedTags > 5) {
            toast.error('You must select between 3 and 5 highlighted specialisation tags.');
            actions.setSubmitting(false);
            actions.setFieldError(
              'highlightedTags',
              'You must select at least 3 highlighted specialisation tags'
            );

            return;
          }

          const sTags = values.specialisationTags.map((a) => a.value);
          const cTags = values.clientTags.map((a) => a.value);
          const tTags = values.therapyTags.map((a) => a.value);
          const allTags = sTags.concat(cTags).concat(tTags);

          const {
            title,
            firstName,
            middleName,
            lastName,
            emailAddress,
            phoneNumber,
            fundingOptions,
            languages,
            ethnicity,
            alias,
            fullBiography,
            shortBiography,
            websiteAddress,
          } = values;

          let data = {
            id: auth.currentPractitioner.id,
            title,
            firstName,
            middleName,
            lastName,
            emailAddress,
            phoneNumber,
            fundingOptions: fundingOptions ? fundingOptions.map((f) => f.value) : [],
            languages: languages ? languages.map((l) => l.value) : [],
            ethnicity: ethnicity ? ethnicity.map((e) => e.value) : [],
            alias,
            fullBiography,
            shortBiography,
            websiteAddress,
            tags: allTags,
            highlightedTags,
            qualifications: values.qualifications.split(',').map((q) => q.trim()),
            gender: values.gender.value,
          };

          auth.updatePractitioner(data).then((response) => {
            if (!response.hasError) {
              this.setSelectedTags();
              onComplete();
              toast.success("'About You' info updated");
            } else {
              response.errors.forEach((err) => {
                actions.setFieldTouched(err.field, true, false);
                actions.setFieldError(err.field, err.defaultMessage);
              });
              actions.setStatus({ message: response.message });
              toast.error(response.message);
            }
            actions.setSubmitting(false);
          });
        }}
        render={({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setTouched,
          setFieldValue,
        }) => {
          const onSpecialisationTagsChange = (value) => {
            setFieldValue('specialisationTags', value);

            // If tag is removed from specialisationTags, remove from highlightedTags
            // Get all tags that exist in both specialisationTags and highlightedTags
            const filteredHTags = values.highlightedTags.filter((hTag: Tag) =>
              value.some((sTag) => sTag.value === hTag.value)
            );

            // If there are less highlightedTags, update highlightedTags
            if (filteredHTags.length < values.highlightedTags.length) {
              setFieldValue('highlightedTags', filteredHTags);
            }
          };

          const onHighlightedTagsChange = (value) => {
            if (value.length > 5) {
              toast.error('Highlighted tags are limited to a maxiumum of 5 specialisations');
              return;
            }

            setFieldValue('highlightedTags', value);

            // If highlightTag is not in specialisationTags, add to specialisationTags
            // Get all tags that do not exist in specialisationTags
            const newTags = value.filter(
              (hTag) => !values.specialisationTags.some((sTag: Tag) => sTag.value === hTag.value)
            );

            // If there are new tags, add to specialisationTags
            if (newTags.length > 0) {
              const updatedSTags = values.specialisationTags.concat(newTags);
              setFieldValue('specialisationTags', updatedSTags);
            }
          };

          return (
            <Form onSubmit={handleSubmit} className={`${this.loading ? 'loading' : ''}`}>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label>Biography *</Label>
                    <TextareaAutosize
                      name='fullBiography'
                      className={disabled ? 'disabled' : ''}
                      value={values.fullBiography}
                      onBlur={() => setTouched({ ...touched, fullBiography: true })}
                      onChange={handleChange}
                      invalid={touched.fullBiography && errors.fullBiography}
                      disabled={disabled}
                      maxRows={8}
                    />
                    {touched.fullBiography && errors.fullBiography && (
                      <FormFeedback className='d-block'>{errors.fullBiography}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label>Highlighted Specialisation Tags *</Label>
                    <Select
                      classNamePrefix='react-select'
                      name='highlightedTags'
                      options={toJS(specialisationTags)}
                      value={values.highlightedTags}
                      onBlur={() => setTouched({ ...touched, highlightedTags: true })}
                      onChange={(value) => {
                        onHighlightedTagsChange(value);
                      }}
                      closeMenuOnSelect={false}
                      isMulti
                      isDisabled={disabled}
                    />
                    {(touched.highlightedTags || this.forceErrors) && errors.highlightedTags && (
                      <FormFeedback className='d-block'>{errors.highlightedTags}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label>Specialisation Tags *</Label>
                    <Select
                      classNamePrefix='react-select'
                      name='specialisationTags'
                      options={toJS(specialisationTags)}
                      value={values.specialisationTags}
                      onBlur={() => setTouched({ ...touched, specialisationTags: true })}
                      onChange={(value) => {
                        onSpecialisationTagsChange(value);
                      }}
                      closeMenuOnSelect={false}
                      isMulti
                      isDisabled={disabled}
                    />
                    {(touched.specialisationTags || this.forceErrors) &&
                      errors.specialisationTags && (
                        <FormFeedback className='d-block'>{errors.specialisationTags}</FormFeedback>
                      )}
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label>Focuses *</Label>
                    <Select
                      classNamePrefix='react-select'
                      name='clientTags'
                      options={toJS(clientTags)}
                      value={values.clientTags}
                      onBlur={() => setTouched({ ...touched, clientTags: true })}
                      onChange={(value) => setFieldValue('clientTags', value)}
                      closeMenuOnSelect={false}
                      isMulti
                      isDisabled={disabled}
                    />
                    {(touched.clientTags || this.forceErrors) && errors.clientTags && (
                      <FormFeedback className='d-block'>{errors.clientTags}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label>Therapy Tags *</Label>
                    <Select
                      classNamePrefix='react-select'
                      name='therapyTags'
                      options={toJS(therapyTags)}
                      value={values.therapyTags}
                      onBlur={() => setTouched({ ...touched, therapyTags: true })}
                      onChange={(value) => setFieldValue('therapyTags', value)}
                      closeMenuOnSelect={false}
                      isMulti
                      isDisabled={disabled}
                    />
                    {(touched.therapyTags || this.forceErrors) && errors.therapyTags && (
                      <FormFeedback className='d-block'>{errors.therapyTags}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label>Qualifications</Label>
                    <Input
                      bsSize='lg'
                      name='qualifications'
                      value={values.qualifications}
                      onChange={handleChange}
                      onBlur={() => setTouched({ ...touched, qualifications: true })}
                      invalid={touched.qualifications && !!errors.qualifications}
                      disabled={disabled}
                    />
                    {touched.qualifications && errors.qualifications && (
                      <FormFeedback>{errors.qualifications}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label>Funding Options</Label>
                    <Select
                      classNamePrefix='react-select'
                      name='fundingOptions'
                      options={FundingOptions}
                      value={values.fundingOptions}
                      onBlur={() => setTouched({ ...touched, fundingOptions: true })}
                      onChange={(options) => setFieldValue('fundingOptions', options)}
                      closeMenuOnSelect={false}
                      isMulti
                      isDisabled={disabled}
                    />
                    {(touched.fundingOptions || this.forceErrors) && errors.fundingOptions && (
                      <FormFeedback className='d-block'>{errors.fundingOptions}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label>Ethnicity</Label>
                    <Select
                      classNamePrefix='react-select'
                      name='ethnicity'
                      options={EthnicityOptions}
                      value={values.ethnicity}
                      onBlur={() => setTouched({ ...touched, ethnicity: true })}
                      onChange={(options) => setFieldValue('ethnicity', options)}
                      closeMenuOnSelect={false}
                      isMulti
                      isDisabled={disabled}
                    />
                    {(touched.ethnicity || this.forceErrors) && errors.ethnicity && (
                      <FormFeedback className='d-block'>{errors.ethnicity}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label>Gender</Label>
                    <Select
                      classNamePrefix='react-select'
                      name='gender'
                      options={GenderOptions}
                      value={values.gender}
                      onBlur={() => setTouched({ ...touched, gender: true })}
                      onChange={(options) => setFieldValue('gender', options)}
                      isDisabled={disabled}
                    />
                    {(touched.gender || this.forceErrors) && errors.gender && (
                      <FormFeedback className='d-block'>{errors.gender}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label>Languages Spoken</Label>
                    <Select
                      classNamePrefix='react-select'
                      name='languages'
                      options={this.languageOptions}
                      value={values.languages}
                      onBlur={() => setTouched({ ...touched, languages: true })}
                      onChange={(options) => setFieldValue('languages', options)}
                      closeMenuOnSelect={false}
                      isMulti
                      isDisabled={disabled}
                    />
                    {(touched.languages || this.forceErrors) && errors.languages && (
                      <FormFeedback className='d-block'>{errors.languages}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              {this.getSubmitError(status)}

              {!disabled && (
                <div className='text-center mt-3'>
                  <Button
                    color='primary'
                    size='lg'
                    type='submit'
                    loading={`${isSubmitting}`}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              )}
            </Form>
          );
        }}
      />
    );
  }
}

export default ProfessionalInfoForm;
