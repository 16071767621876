import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import ProfessionalInfoForm from 'modules/auth/components/ProfessionalInfoForm';
import BasicProfessionalInfoForm from 'modules/auth/components/BasicProfessionalInfoForm';

@inject('auth')
@observer
class ProfessionalInfoSection extends Component {
  @observable editingProfessionalInfo = false;

  toggleEditProfessionalInfo = () => {
    this.editingProfessionalInfo = !this.editingProfessionalInfo;
  };

  render() {
    return (
      <>
        <Card>
          <CardHeader className='d-flex justify-content-between align-items-center'>
            <CardTitle tag='h5' className='mb-0'>
              About You
            </CardTitle>

            <Button
              // className={this.editingProfessionalInfo ? 'invisible' : ''}
              onClick={this.toggleEditProfessionalInfo}
            >
              {this.editingProfessionalInfo ? 'Cancel' : 'Edit'}
            </Button>
          </CardHeader>
          <CardBody>
            <ProfessionalInfoForm
              mode={this.editingProfessionalInfo ? 'edit' : 'read-only'}
              onComplete={this.toggleEditProfessionalInfo}
            />
          </CardBody>
        </Card>
        <Card>
          <CardHeader className='d-flex justify-content-between align-items-center'>
            <CardTitle tag='h5' className='mb-0'>
              Basic Information
            </CardTitle>
          </CardHeader>
          <CardBody>
            <BasicProfessionalInfoForm />
          </CardBody>
        </Card>
      </>
    );
  }
}

export default ProfessionalInfoSection;
