import styled from 'styled-components';
import CHHeadIcon from '../../assets/ch-logo.svg';
import CHTextIcon from '../../assets/ch-text.svg';

export const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CHHead = styled(CHHeadIcon)`
  height: 26px;
  width: auto;
  margin-right: 10px;
`;

export const CHText = styled(CHTextIcon)`
  height: 10px;
  width: auto;
`;

export const CHHeadWhite = styled(CHHead)`
  > path {
    fill: white;
  }
`;

export const CHTextWhite = styled(CHText)`
  > path {
    fill: white;
  }
`;
