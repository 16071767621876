import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Badge, Collapse } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import Close from 'jsx:assets/svg/close.svg';

import { SidebarLogo } from 'common';
import { appRoutes } from 'routes/index';

const IconNameGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
`;

const SidebarCategory = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, isOpen, children, onClick, location, to }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === '/' && path === '/dashboard')
        ? 'active'
        : '';
    };

    return (
      <li className={'sidebar-item ' + getSidebarItemClass(to)}>
        <span
          data-toggle='collapse'
          className={'sidebar-link flex' + (!isOpen ? ' collapsed' : '')}
          onClick={onClick}
          aria-expanded={isOpen ? 'true' : 'false'}
        >
          <Icon size={18} className='align-middle mr-4' />
          <span className='align-middle'>{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className='sidebar-badge'>
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen} cssModule={{ collapse: 'bs-collapse' }}>
          <ul id='item' className={'sidebar-dropdown list-unstyled'}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to, toggleSidebarVisibility }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname === path ? 'active' : '';
    };

    const hideOnClickMobile = () => {
      if (window.innerWidth <= 576) {
        toggleSidebarVisibility();
      }
    };

    return (
      <li className={'sidebar-item ' + getSidebarItemClass(to)}>
        <NavLink
          to={to}
          className='sidebar-link'
          activeClassName='active'
          onClick={hideOnClickMobile}
        >
          <IconNameGrid>
            {Icon ? <Icon size={18} className='align-middle mr-4' /> : null}
            {name}
          </IconNameGrid>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className='sidebar-badge'>
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

@withRouter
@inject('app', 'auth')
@observer
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggle = (index) => {
    this.setState((state) => ({
      [index]: !state[index],
    }));
  };

  signOut = () => {
    const { auth, history } = this.props;
    auth.logout();
    history.push('/');
  };

  // TODO: Component needs to be refactored to a function component, this
  // is deprecated and doesn't work (collapse elements do not open)
  componentWillMount() {
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname;

    appRoutes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === '/' ? true : false;

      this.setState(() => ({
        [index]: isActive || isOpen || isHome,
      }));
    });
  }

  render() {
    const {
      app: { isSidebarSticky, isSidebarOpen, toggleSidebarVisibility },
      auth: { currentPractitioner },
    } = this.props;

    const isInvoicingEnabled = currentPractitioner?.invoicingEnabled;

    return (
      <nav
        className={
          'sidebar' +
          (!isSidebarOpen ? ' toggled' : '') +
          (isSidebarSticky ? ' sidebar-sticky' : '')
        }
      >
        <div className='sidebar-content'>
          <PerfectScrollbar>
            <div className='flex items-center justify-between'>
              <SidebarLogo />
              <span
                className='sidebar-toggle d-flex mr-6 sm:hidden'
                onClick={toggleSidebarVisibility}
              >
                <Close />
              </span>
            </div>

            <ul className='sidebar-nav'>
              {appRoutes
                .filter((a) => !a.excludeFromNav && !(a.name === 'Invoices' && !isInvoicingEnabled))
                .map((category, index) => {
                  return (
                    <React.Fragment key={index}>
                      {category.header ? (
                        <li className='sidebar-header'>{category.header}</li>
                      ) : null}

                      {category.children ? (
                        <SidebarCategory
                          name={category.name}
                          badgeColor={category.badgeColor}
                          badgeText={category.badgeText}
                          icon={category.icon}
                          to={category.path}
                          isOpen={this.state[index]}
                          onClick={() => this.toggle(index)}
                        >
                          {category.children.map((route, index) => (
                            <SidebarItem
                              key={index}
                              name={route.name}
                              to={route.path}
                              badgeColor={route.badgeColor}
                              badgeText={route.badgeText}
                              icon={route.icon}
                              toggleSidebarVisibility={toggleSidebarVisibility}
                            />
                          ))}
                        </SidebarCategory>
                      ) : (
                        <SidebarItem
                          name={category.name}
                          to={category.path}
                          icon={category.icon}
                          badgeColor={category.badgeColor}
                          badgeText={category.badgeText}
                          toggleSidebarVisibility={toggleSidebarVisibility}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
            </ul>
          </PerfectScrollbar>
        </div>
      </nav>
    );
  }
}

export default Sidebar;
