import * as React from 'react';
import { observer, inject } from 'mobx-react';
import ManageAvailabilityComponent from './ManageAvailabilityComponent';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';

const FooterComponent = ({ onSubmit, isValid, isLoading }) => {
  return (
    <Button variant={'primary'} onClick={onSubmit} disabled={!isValid || isLoading}>
      Confirm
    </Button>
  );
};

const AvailabilityStep = inject(
  'auth',
  'schedule',
  'prices',
  'locations'
)(
  observer(() => {
    return (
      <>
        <div className='flex flex-col w-full items-center mx-auto'>
          <ManageAvailabilityComponent footer={FooterComponent} />
        </div>
      </>
    );
  })
);

export default AvailabilityStep;
