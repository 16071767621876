import React from 'react';
import clsx from 'clsx';
import { ToggleButtonProps } from '.';

type RawToggleButtonProps = ToggleButtonProps & {
  className: string;
  activeClassName: string;
  inactiveClassName: string;
};

const RawToggleButton: React.FC<RawToggleButtonProps> = ({
  className = '',
  activeClassName = '',
  inactiveClassName = '',
  onClick = () => void 0,
  active = true,
  title,
}) => (
  <button
    className={clsx(
      'px-4 py-2 rounded font-sans text-xs border', // Button appearance
      active ? activeClassName : inactiveClassName, // Active / Inactive style
      className // Colour-specific and other styles
    )}
    type='button'
    onClick={onClick}
  >
    {title}
  </button>
);

export default RawToggleButton;
