import * as React from 'react';
import { CHHead, CHText, CHHeadWhite, CHTextWhite, LogoContainer } from './styles';

export interface ILogoProps {
  variant?: 'none' | 'white';
  className?: string;
}

const Logo: React.FC<ILogoProps> = ({ className, variant = 'none' }) => {
  return (
    <LogoContainer className={className}>
      {variant == 'white' ? <CHHeadWhite /> : <CHHead />}
      {variant == 'white' ? <CHTextWhite /> : <CHText />}
    </LogoContainer>
  );
};

export default Logo;
