import * as React from 'react';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import clsx from 'clsx';

import HelpCircle from '../../../../../common/HelpCircle';
import SessionLimitText from '../../SessionLimitText';

type BookingCardFooter = {
  partnerBooking: boolean;
  contact: any; // Should add a proper type later
};

const BookingCardFooter: React.FC<BookingCardFooter> = ({ partnerBooking, contact }) => {
  return (
    <div
      className={clsx(
        'rounded-b-md px-3 py-2',
        partnerBooking ? 'bg-light-green' : 'bg-primary-blue-10'
      )}
    >
      {partnerBooking ? (
        <div className='flex justify-between items-center'>
          <div className='flex items-center'>
            <Text className='text-dark-blue mr-2 font-semibold text-sm'>EAP</Text>
            <HelpCircle.Booking partnerBooking={partnerBooking} />
          </div>
          {/* <Text className='text-dark-blue font-semibold text-sm'>
            <SessionLimitText contact={contact} />
          </Text> */}
        </div>
      ) : (
        <div className='flex items-center'>
          <Text className='text-dark-blue mr-2 font-semibold text-sm'>Public</Text>
          <HelpCircle.Booking />
        </div>
      )}
    </div>
  );
};

export default BookingCardFooter;
