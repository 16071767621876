import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import RepeatBookingForm from 'modules/bookings/components/RepeatBookingForm';

@observer
class RepeatModal extends Component {
	@observable modalType = 'create';

	render() {
		const {
			isModalOpen,
			isEditing,
			toggleModal,
			formState,
			currentDate,
			onCancel,
			onComplete,
			mode = 'create',
		} = this.props;

		return (
			<Modal isOpen={isModalOpen} toggle={toggleModal} size='sm' centered>
				<ModalHeader className='bg-light' toggle={isEditing ? toggleModal : onCancel}>
					Repeat Appointment
				</ModalHeader>

				<ModalBody className='p-3'>
					<RepeatBookingForm
						mode={mode}
						formState={formState}
						currentDate={currentDate}
						onComplete={onComplete}
					/>
				</ModalBody>
			</Modal>
		);
	}
}

export default RepeatModal;
