import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Container, Input, Row, Col, Button } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import debounce from 'debounce-promise';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';

import {
  RejectBookingModal,
  ViewBookingModal,
  EditBookingModal,
  Loading,
  BookingModal,
} from 'common';

import {
  FilterButton,
  FilterContainer,
  FilterButtonsContainer,
  FilterButtonContainer,
  FilterCount,
} from 'styles/filters';

import BookingCard from './BookingCard';
import EmptyCardLabel from './EmptyCardLabel';
import clsx from 'clsx';
import { errorMessage } from '../Booking/Complete';
import { toast } from 'react-toastify';

const TAB_ARRAY = ['PENDING', 'CONFIRMED', 'PENDING-COMPLETION', 'COMPLETED'];

@inject('bookings', 'auth')
@observer
class Bookings extends Component {
  debouncedSearchQuery;

  @observable loading = true;

  @observable rejectModalOpen = false;
  @observable rejectFormState = {};
  @observable viewBookingModalOpen = false;
  @observable appointmentModalOpen = false;
  @observable selectedBooking = {};
  @observable selectedBookingStatus = null;
  @observable editAppointmentModalOpen = false;
  @observable pendingPageOffset = 0;
  @observable confirmedPageOffset = 0;
  @observable pendingConfirmationPageOffset = 0;
  @observable historicalPageOffset = 0;
  @observable searchQuery = '';
  @observable activeFilter = 'PENDING';

  @observable invoiceLoading = false;
  @observable invoice = null;
  @observable bookingType = 'create';

  constructor(props) {
    super(props);
    const wait = 1000; // milliseconds
    const searchQuery = (inputValue) => this.onSearchQuery(inputValue);
    this.debouncedSearchQuery = debounce(searchQuery, wait, {
      leading: false,
    });
  }

  componentDidMount = () => {
    this.getBookings();
    this.createAppointmentOnLoad();
    this.tabOnLoad();
  };

  tabOnLoad = () => {
    const params = new URLSearchParams(location.search);
    let tab = params.get('tab');
    if (tab) {
      const exists = TAB_ARRAY.some((x) => x === tab);
      if (exists) {
        this.activeFilter = tab;
      }
    }
  };

  setTabAndLocation = (tab: string) => {
    this.activeFilter = tab;
    const params = new URLSearchParams(location.search);
    params.set('tab', tab);
    this.props.history.replace(`${location.pathname}?${params.toString()}`);
  };

  createAppointmentOnLoad = () => {
    const params = new URLSearchParams(location.search);
    const createAppointment = params.get('createAppointment');
    const bookingId = params.get('bookingId');
    const manualBookingId = params.get('manualBookingId');
    if (createAppointment) {
      if (bookingId) {
        this.props.bookings.getBookingData(bookingId).then((data) => {
          this.onRebookModal(data, 'Manual');
        });
      } else if (manualBookingId) {
        this.props.bookings.getManualBookingData(manualBookingId).then((data) => {
          this.onRebookModal(data, 'Manual');
        });
      } else {
        this.toggleAppointmentModal();
      }
    }
  };

  renderSearchBar = () => (
    <Input
      className='mb-3 font-semibold py-2'
      placeholder='Search'
      onChange={(e) => this.debouncedSearchQuery(e.target.value)}
      defaultValue={this.searchQuery}
    />
  );

  getPendingCards = (pendingBookings, pendingManualBookings) => {
    const {
      bookings: { lastPending },
    } = this.props;

    if (this.loading) {
      return <Loading />;
    }

    const cards = [];
    if (pendingManualBookings.length > 0) {
      cards.push(
        pendingManualBookings.map((pending) => {
          return (
            <BookingCard
              key={pending.id}
              booking={pending}
              type='Manual'
              onAccept={() => this.getBookings()}
              openRejectModal={this.openRejectModal.bind(this, pending.id, 'Manual')}
              expandBooking={this.onExpandBooking.bind(this, pending, 'Manual')}
              onEdit={this.onEditModal.bind(this, pending, 'Manual')}
            />
          );
        })
      );
    }

    if (pendingBookings.length > 0) {
      cards.push(
        pendingBookings.map((pending) => {
          return (
            <BookingCard
              key={pending.id}
              booking={pending}
              type='Pending'
              onAccept={() => this.getBookings()}
              openRejectModal={this.openRejectModal.bind(this, pending.id, 'Appointment')}
              expandBooking={this.onExpandBooking.bind(this, pending, 'Pending')}
              onEdit={this.onEditModal.bind(this, pending, 'Pending')}
            />
          );
        })
      );
    }

    if (cards.length === 0) {
      return <EmptyCardLabel>No new referrals</EmptyCardLabel>;
    } else {
      return (
        <>
          {cards}
          {lastPending === false && !this.loading && (
            <Button color='primary' size='lg' className='m-auto' onClick={this.loadMorePending}>
              Load More
            </Button>
          )}
        </>
      );
    }
  };

  getBookings = () => {
    this.loading = true;
    const data = { pageOffset: 0, pageSize: 20 };
    Promise.all([this.props.bookings.getAllBookings(data)]).then(() => {
      this.loading = false;
    });
  };

  getConfirmedCards = (confirmedBookings) => {
    const {
      bookings: { lastConfirmed },
    } = this.props;
    if (this.loading) {
      return <Loading />;
    } else if (confirmedBookings.length > 0) {
      return (
        <>
          {/* {this.renderSearchBar()} */}
          {confirmedBookings.map((confirmed) => {
            return (
              <BookingCard
                key={confirmed.id}
                booking={confirmed}
                type='Confirmed'
                openRejectModal={this.openRejectModal.bind(this, confirmed.id, 'Appointment')}
                expandBooking={this.onExpandBooking.bind(this, confirmed, 'Confirmed')}
                onEdit={this.onEditModal.bind(this, confirmed, 'Manual')}
              />
            );
          })}
          {lastConfirmed === false && !this.loading && (
            <Button color='primary' size='lg' className='m-auto' onClick={this.loadMoreConfirmed}>
              Load More
            </Button>
          )}
        </>
      );
    }
    return <EmptyCardLabel>No confirmed bookings</EmptyCardLabel>;
  };

  getPendingCompletionCards = (pendingCompletionBookings) => {
    const {
      bookings: { lastPendingConfirmation },
    } = this.props;
    if (this.loading) {
      return <Loading />;
    } else if (pendingCompletionBookings.length > 0) {
      return (
        <>
          {/* {this.renderSearchBar()} */}
          {pendingCompletionBookings.map((pendingCompletion) => {
            return (
              <BookingCard
                key={pendingCompletion.id}
                booking={pendingCompletion}
                type='Historical'
                openRejectModal={this.openRejectModal.bind(
                  this,
                  pendingCompletion.id,
                  'Appointment'
                )}
                expandBooking={this.onExpandBooking.bind(this, pendingCompletion, 'Confirmed')}
                onEdit={this.onEditModal.bind(this, pendingCompletion, 'Manual')}
                onRebook={this.onRebookModal.bind(this, pendingCompletion, 'Manual')}
              />
            );
          })}
          {lastPendingConfirmation === false && !this.loading && (
            <Button
              color='primary'
              size='lg'
              className='m-auto'
              onClick={this.loadMorePendingConfirmation}
            >
              Load More
            </Button>
          )}
        </>
      );
    }
    return <EmptyCardLabel>No pending completion bookings</EmptyCardLabel>;
  };

  getHistoricalCards = (historicalBookings) => {
    const {
      bookings: { lastHistorical },
    } = this.props;
    if (this.loading) {
      return <Loading />;
    } else if (historicalBookings.length > 0) {
      return (
        <>
          {this.renderSearchBar()}
          {historicalBookings.map((historic) => {
            return (
              <BookingCard
                key={historic.id}
                booking={historic}
                type='Historical'
                expandBooking={this.onExpandBooking.bind(this, historic, historic.status)}
                onRebook={this.onRebookModal.bind(this, historic, 'Manual')}
              />
            );
          })}
          {lastHistorical === false && !this.loading && (
            <Button color='primary' size='lg' className='m-auto' onClick={this.loadMoreHistorical}>
              Load More
            </Button>
          )}
        </>
      );
    }
    return (
      <>
        {this.renderSearchBar()}
        <EmptyCardLabel>No completed bookings</EmptyCardLabel>{' '}
        <Button
          color='primary'
          size='md'
          // style={{ height: '30px', width: '120px' }}
          onClick={() => this.onSearchQuery('')}
          className='rounded-full font-bold h-8'
        >
          Reset search
        </Button>
      </>
    );
  };

  onRebookModal = (booking, bookingStatus) => {
    this.selectedBooking = booking;
    this.selectedBookingStatus = bookingStatus;
    this.appointmentModalOpen = true;
    this.bookingType = 'rebook';
  };

  onEditModal = (booking, bookingStatus) => {
    this.selectedBooking = booking;
    this.selectedBookingStatus = bookingStatus;
    this.editAppointmentModalOpen = true;
    this.viewBookingModalOpen = false;
  };

  openRejectModal = (id, mode = 'Appointment') => {
    this.rejectFormState = { appointmentId: id, mode, reload: true };
    this.viewBookingModalOpen = false;
    this.rejectModalOpen = true;
  };

  closeRejectModal = () => {
    this.getBookings();
    this.rejectFormState = {};
    this.rejectModalOpen = false;
  };

  toggleRejectModal = () => {
    this.rejectModalOpen = !this.rejectModalOpen;
  };

  toggleViewBookingModal = () => {
    this.viewBookingModalOpen = !this.viewBookingModalOpen;
  };

  toggleEditAppointmentModal = () => {
    this.editAppointmentModalOpen = !this.editAppointmentModalOpen;
  };

  loadMorePending = () => {
    const {
      bookings: { getPendingBookings },
    } = this.props;
    this.pendingPageOffset = this.pendingPageOffset + 1;

    const data = {
      pageOffset: this.pendingPageOffset,
      pageSize: 20,
    };
    getPendingBookings(data);
  };

  loadMoreConfirmed = () => {
    const {
      bookings: { getConfirmedBookings },
    } = this.props;
    this.confirmedPageOffset = this.confirmedPageOffset + 1;

    const data = {
      pageOffset: this.confirmedPageOffset,
      pageSize: 20,
    };
    getConfirmedBookings(data);
  };

  loadMorePendingConfirmation = () => {
    const {
      bookings: { getPendingConfirmationBookings },
    } = this.props;
    this.pendingConfirmationPageOffset = this.pendingConfirmationPageOffset + 1;

    const data = {
      pageOffset: this.pendingConfirmationPageOffset,
      pageSize: 20,
    };
    getPendingConfirmationBookings(data);
  };

  loadMoreHistorical = () => {
    const {
      bookings: { getHistoricalBookings },
    } = this.props;
    this.historicalPageOffset = this.historicalPageOffset + 1;

    const data = {
      pageOffset: this.historicalPageOffset,
      searchQuery: this.searchQuery,
      pageSize: 20,
    };
    getHistoricalBookings(data);
  };

  onSearchQuery = (searchQuery) => {
    const {
      bookings: { getHistoricalBookings },
    } = this.props;

    this.loading = true;
    this.searchQuery = searchQuery;
    this.pendingPageOffset = 0;
    this.confirmedPageOffset = 0;
    this.historicalPageOffset = 0;

    const data = { pageOffset: 0, searchQuery, pageSize: 10 };
    getHistoricalBookings(data)
      .then((res) => {
        this.loading = false;
      })
      .catch((err) => {
        console.error('Error while fetching bookings', err);
        toast.error(errorMessage);
        this.loading = false;
      });
  };

  onExpandBooking = (booking, bookingStatus) => {
    this.selectedBooking = booking;
    this.selectedBookingStatus = bookingStatus;
    this.viewBookingModalOpen = true;
    this.invoice = null;
    this.invoiceLoading = true;
  };

  toggleAppointmentModal = () => {
    this.bookingType = 'create';
    this.appointmentModalOpen = !this.appointmentModalOpen;
  };

  onAppointmentCreate = () => {
    const { bookings } = this.props;
    this.appointmentModalOpen = false;
    bookings.sortConfirmedDescending();
  };

  getBookingCards = () => {
    const {
      bookings: {
        confirmedBookings,
        pendingConfirmationBookings,
        pendingBookings,
        historicalBookings,
        pendingManualBookings,
      },
    } = this.props;
    switch (this.activeFilter) {
      case 'PENDING':
        return this.getPendingCards(pendingBookings, pendingManualBookings);
      case 'CONFIRMED':
        return this.getConfirmedCards(confirmedBookings);
      case 'PENDING-COMPLETION':
        return this.getPendingCompletionCards(pendingConfirmationBookings);
      case 'COMPLETED':
        return this.getHistoricalCards(historicalBookings);
      default:
        break;
    }
  };

  render() {
    const {
      bookings: { totalConfirmed, totalPending, totalPendingConfirmation, totalPendingManual },
      auth: {
        currentPractitioner: { invoicingEnabled },
      },
    } = this.props;

    return (
      <>
        <Container fluid className='max-w-7xl'>
          <div className='d-flex flex-col sm:flex-row justify-content-between mb-3'>
            <Heading className='text-xl text-dark-blue mb-4 sm:mb-0'>Bookings</Heading>
            <div className='flex flex-row'>
              <Button
                color='primary'
                size='md'
                // style={{ height: '30px', width: '120px' }}
                onClick={this.getBookings}
                className='rounded-full bg-transparent text-primary-blue-100 font-bold h-8 mr-2'
              >
                Refresh
              </Button>
              <Button
                color='primary'
                size='md'
                // style={{ height: '30px', width: '120px' }}
                onClick={this.toggleAppointmentModal}
                className='rounded-full font-bold h-8'
              >
                Create Appointment
              </Button>
            </div>
          </div>

          <FilterContainer className='d-flex justify-content-center align-items-center'>
            <FilterButtonsContainer>
              <FilterButtonContainer>
                <FilterButton
                  className={`${this.activeFilter === 'PENDING' ? 'is-active' : ''} text-dark-blue`}
                  onClick={() => this.setTabAndLocation('PENDING')}
                >
                  New Referrals <FilterCount>{totalPending + totalPendingManual}</FilterCount>
                </FilterButton>
              </FilterButtonContainer>
              <FilterButtonContainer>
                <FilterButton
                  className={`${
                    this.activeFilter === 'CONFIRMED' ? 'is-active' : ''
                  } text-dark-blue`}
                  onClick={() => this.setTabAndLocation('CONFIRMED')}
                >
                  Confirmed <FilterCount>{totalConfirmed}</FilterCount>
                </FilterButton>
              </FilterButtonContainer>
              {invoicingEnabled && (
                <FilterButtonContainer>
                  <FilterButton
                    className={clsx(
                      this.activeFilter === 'PENDING-COMPLETION' && 'is-active',
                      'text-dark-blue'
                    )}
                    onClick={() => this.setTabAndLocation('PENDING-COMPLETION')}
                  >
                    Pending Completion <FilterCount>{totalPendingConfirmation}</FilterCount>
                  </FilterButton>
                </FilterButtonContainer>
              )}
              <FilterButtonContainer>
                <FilterButton
                  className={`${
                    this.activeFilter === 'COMPLETED' ? 'is-active' : ''
                  } text-dark-blue`}
                  onClick={() => this.setTabAndLocation('COMPLETED')}
                >
                  Completed
                </FilterButton>
              </FilterButtonContainer>
            </FilterButtonsContainer>
          </FilterContainer>

          <Col
            xs='12'
            sm={{ size: 8, offset: 2 }}
            md={{ size: 8, offset: 2 }}
            lg={{ size: 6, offset: 3 }}
            className='d-flex flex-col justify-content-start align-items-center pt-2 pb-2'
          >
            {this.getBookingCards()}
          </Col>
        </Container>

        <BookingModal
          isModalOpen={this.appointmentModalOpen}
          toggleModal={this.toggleAppointmentModal}
          onComplete={this.onAppointmentCreate}
          showTypeButtons={false}
          booking={this.selectedBooking}
          bookingType={this.bookingType}
        />

        <RejectBookingModal
          isModalOpen={this.rejectModalOpen}
          toggleModal={this.toggleRejectModal}
          formState={this.rejectFormState}
          onComplete={this.closeRejectModal}
        />

        <ViewBookingModal
          isModalOpen={this.viewBookingModalOpen}
          toggleModal={this.toggleViewBookingModal}
          booking={this.selectedBooking}
          bookingStatus={this.selectedBookingStatus}
          invoice={this.invoice}
          invoiceLoading={this.invoiceLoading}
        />

        <EditBookingModal
          mode='edit'
          isModalOpen={this.editAppointmentModalOpen}
          toggleModal={this.toggleEditAppointmentModal}
          booking={this.selectedBooking}
        />
      </>
    );
  }
}

export default Bookings;
