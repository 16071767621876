import styled from 'styled-components';
import { applyStyleModifiers, ModifierKeys } from 'styled-components-modifiers';

interface IBaseHeading {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  modifiers?: ModifierKeys;
}

const BASE_HEADING_MODIFIERS = {
  h1: () => `
    --header-font: var(--font-h1);
    --header-font-size: 2.375rem;
      --header-line-height: 44px;
    --header-letter-spacing: -0.2px;
    @media screen and (min-width: 768px) {
      --header-font-size: 2.775rem;
      --header-line-height: 56px;
    }
    @media screen and (min-width: 1080px) {
      --header-font-size: 3.4375rem;
      --header-line-height: 64px;
    }
    
    `,
  h2: () => `
    --header-font: var(--font-h2);
    --header-font-size: 2.375rem;
    --header-line-height: 44px;
    --header-letter-spacing: -0.14px;
    @media screen and (max-width: 768px) {
      --header-font-size: 1.5625rem;
      --header-line-height: 34px;
    }
    `,
  h3: () => `
    --header-font: var(--font-h3);
    --header-font-size: 1.5625rem;
    --header-line-height: 29px;
    --header-letter-spacing: -0.09px;
    `,
  h4: () => `
    --header-font: var(--font-h4);
    --header-line-height: 20px;
    --header-font-size: 1.25rem;
  `,
  h5: () => `
    --header-font: var(--font-h4);
    --header-line-height: 20px;
    --header-font-size: 1rem;
  `,
  bold: () => `
    --header-font-weight: var(--font-weight-bold); 
  `,
  semiBold: () => `
    --header-font-weight: var(--font-weight-semi-bold); 
  `,
  regular: () => `
    --header-font-weight: var(--font-weight-regular); 
  `,
  light: () => `
    --header-font-weight: var(--font-weight-light); 
  `,
};

export const BaseHeading = styled.h1<IBaseHeading>`
  --header-font: var(--font-h1);
  --header-font-size: 3.4375rem;
  --header-font-weight: var(--font-weight-bold);
  --header-line-height: 44px;
  --header-letter-spacing: -0.14px;

  ${applyStyleModifiers(BASE_HEADING_MODIFIERS)};

  font: var(--header-font);
  font-weight: var(--header-font-weight);
  font-size: var(--header-font-size);
  line-height: var(--header-line-height);
  letter-spacing: var(--header-letter-spacing);
`;
