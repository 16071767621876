// @flow
import React, { Component } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Button, Row, Col, Form, FormGroup, FormFeedback, Label, Input } from 'reactstrap';

import { TimePicker } from 'common';

const schema = Yup.object().shape({
  startDate: Yup.date().required('Required'),
  endDate: Yup.date().required('Required'),
  startTime: Yup.date().required('Required'),
  endTime: Yup.date().required('Required'),
  title: Yup.string(),
});

type Props = {
  onComplete: Function;
};

const defaultState = {
  clientType: 'new',
  startTime: moment('12:00:00', 'H:mm:ss'),
  startTimeOption: { label: '12:00pm', value: moment('12:00:00', 'H:mm:ss') },
  endTime: moment('13:00:00', 'H:mm:ss'),
  endTimeOption: { label: '1:00pm', value: moment('13:00:00', 'H:mm:ss') },
};

@inject('locations', 'calendar', 'auth')
@observer
class BlockForm extends Component<Props> {
  static defaultProps = {
    onComplete: () => {},
  };

  constructor(props) {
    super(props);

    this.today = moment().format('YYYY-MM-DD');
  }

  getSubmitError = (status) => {
    if (status && status.message) {
      return <FormFeedback>{status.message}</FormFeedback>;
    }
    return null;
  };

  getLocationOptions = () => {
    const {
      locations: { allLocations },
    } = this.props;

    if (allLocations) {
      return allLocations.map((location) => (
        <option data-location-id={location.id}>{location.nickname}</option>
      ));
    }
    return null;
  };

  onValidate = (values) => {
    let errors = {};

    const start = moment(values.startTime);
    const end = moment(values.endTime);
    if (start.isSameOrAfter(end)) {
      errors.invalidTimes = 'Start time must occur before the end time.';
    }

    return errors;
  };

  render() {
    const { calendar, formState = {}, onComplete = () => {}, mode = 'create' } = this.props;
    const initialValues = {
      ...defaultState,
      ...formState,
    };
    const submit = mode === 'create' ? calendar.createBlock : calendar.editBlock;
    const successMessage = mode === 'create' ? 'Block created!' : 'Block updated!';

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize={true}
        validate={this.onValidate}
        onSubmit={(values, actions) => {
          const startTime = moment(values.startTime);
          const endTime = moment(values.endTime);
          const mStart = moment(values.startDate, 'YYYY-MM-DD');
          mStart.hour(startTime.hour());
          mStart.minute(startTime.minute());
          mStart.second(startTime.second());
          const mEnd = moment(values.endDate, 'YYYY-MM-DD');
          mEnd.hour(endTime.hour());
          mEnd.minute(endTime.minute());
          mEnd.second(endTime.second());

          const data = {
            id: values.blockId,
            title: values.title,
            blockageTime: mStart.format('YYYY-MM-DDTHH:mm:ss'),
            blockageEndTime: mEnd.format('YYYY-MM-DDTHH:mm:ss'),
            overwrite: true,
            timezone: this.props.auth.currentPractitioner.timezone,
          };

          submit(data).then((response) => {
            if (!response.hasError) {
              onComplete();
              toast.success(successMessage);
            } else {
              response.errors.forEach((err) => {
                actions.setFieldTouched(err.field, true, false);
                actions.setFieldError(err.field, err.defaultMessage);
              });
              actions.setStatus({ message: response.message });
              toast.error(response.message);
            }
            actions.setSubmitting(false);
          });
        }}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row className='justify-content-center text-center' form>
                <Col className='col-5'>
                  <FormGroup>
                    <Label>Block Start Date *</Label>
                    <Input
                      bsSize='lg'
                      name='startDate'
                      type='date'
                      min={this.today}
                      value={values.startDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.startDate && !!errors.startDate}
                    />
                    {errors.startDate && <FormFeedback>{errors.startDate}</FormFeedback>}
                  </FormGroup>
                  <FormGroup>
                    <TimePicker
                      onChange={(option) => {
                        setFieldValue('startTime', option.value);
                        setFieldValue('startTimeOption', option);
                      }}
                      selectedTime={values.startTimeOption}
                    />
                    {errors.startTime && <FormFeedback>{errors.startTime}</FormFeedback>}
                  </FormGroup>
                </Col>

                <Col className='col-1 my-auto'>To</Col>

                <Col className='col-5'>
                  <FormGroup>
                    <Label>Block End Date *</Label>
                    <Input
                      bsSize='lg'
                      name='endDate'
                      type='date'
                      min={this.today}
                      value={values.endDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.endDate && !!errors.endDate}
                    />
                    {errors.endDate && <FormFeedback>{errors.endDate}</FormFeedback>}
                  </FormGroup>
                  <FormGroup>
                    <TimePicker
                      onChange={(option) => {
                        setFieldValue('endTime', option.value);
                        setFieldValue('endTimeOption', option);
                      }}
                      selectedTime={values.endTimeOption}
                    />
                    {errors.endTime && <FormFeedback>{errors.endTime}</FormFeedback>}
                  </FormGroup>
                </Col>

                {errors.invalidTimes && (
                  <Col className='col-12'>
                    {errors.invalidTimes && (
                      <FormFeedback className='d-block text-danger'>
                        {errors.invalidTimes}
                      </FormFeedback>
                    )}
                  </Col>
                )}
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label>Title (optional)</Label>
                    <Input
                      bsSize='lg'
                      name='title'
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.title && !!errors.title}
                    />
                    {errors.title && <FormFeedback>{errors.title}</FormFeedback>}
                  </FormGroup>
                </Col>
              </Row>

              {this.getSubmitError(status)}

              <div className='text-center mt-10 mb-4'>
                <Button
                  color='primary'
                  size='lg'
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {mode === 'create' ? 'Block Time' : 'Save'}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

export default BlockForm;
