import * as React from 'react';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';

export type StepsProgressBarProps = {
  stepIndex: number;
  stepCount: number;
  className?: string;
};

const StepsProgressBar: React.FC<StepsProgressBarProps> = ({ className, stepIndex, stepCount }) => {
  const stepPercentage = Math.floor(((stepIndex + 1) / stepCount) * 100);
  return (
    <div className={className}>
      <Text className='text-dark-blue mb-1 !text-xs !font-semibold'>{`STEP ${
        stepIndex + 1
      } OF ${stepCount}`}</Text>
      <div className='w-full sm:w-1/2 lg:w-1/3 bg-primary-blue-50 rounded-full h-2 mx-auto'>
        <div
          className='bg-primary-blue-100 h-2 rounded-full'
          style={{ width: `${stepPercentage}%` }}
        ></div>
      </div>
    </div>
  );
};

export default StepsProgressBar;
