import { flow, types } from 'mobx-state-tree';

import PractitionerService from 'services/PractitionerService';

const Tag = types.model('Tag', {
  label: types.string,
  value: types.string,
});

const Practitioners = types
  .model('Practitioners', {
    allTags: types.array(Tag),
    specialisationTags: types.array(Tag),
    clientTags: types.array(Tag),
    therapyTags: types.array(Tag),
    hasFetchedTags: types.optional(types.boolean, false),
  })
  .preProcessSnapshot((snap) => {
    if (snap) {
      return {
        ...snap,
        hasFetchedTags: snap.hasFetchedTags || undefined,
      };
    }
  })
  .actions((self) => ({
    getTags: flow(function* () {
      if (self.allTags.length > 0) {
        return { hasError: false };
      }
      const response = yield PractitionerService.getTags();
      if (!response.hasError) {
        self.allTags = response.data
          .map((tag) => ({
            label: tag.description ?? tag.name,
            value: tag.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        self.specialisationTags = response.data
          .filter((tag) => tag.tagType === 'Specialization')
          .map((tag) => ({
            label: tag.description ?? tag.name,
            value: tag.name,
            tag,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        self.clientTags = response.data
          .filter((tag) => tag.tagType === 'ClientType')
          .map((tag) => ({
            label: tag.description ?? tag.name,
            value: tag.name,
            tag,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        self.therapyTags = response.data
          .filter((tag) => tag.tagType === 'TherapyType')
          .map((tag) => ({
            label: tag.description ?? tag.name,
            value: tag.name,
            tag,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        self.hasFetchedTags = true;
      }
      return response;
    }),
  }));

export default Practitioners;
