import styled from 'styled-components';
import { DropdownMenu } from '../SetupWizard/WizardForm/styles';
import { Card } from 'reactstrap';
import {
  List,
  LocationPin,
  Bin,
  Add,
} from '@clearhead-ltd/ui-components/dist/v2/Icons';

export const GetTimeDropDown = styled(DropdownMenu)`
  .react-select__indicators {
    display: none;
  }

  .react-select__menu {
    width: 110px;
  }
`;

export const StyledCard = styled(Card)`
  border-radius: 8px;
  border: 1px solid #a4c3e6;
  padding: 12px;
`;

export const ListIcon = styled(List)`
  align-items: center;
  height: 24px;
  width: 24px;
  path {
    stroke: ${(props) => props.colour};
  }
`;

export const LocationPinIcon = styled(LocationPin)`
  align-items: center;
  height: 24px;
  width: 24px;
  path {
    stroke: ${(props) => props.colour};
  }
`;

export const BinIcon = styled(Bin)`
  align-items: center;
  height: 20px;
  width: 20px;
  path {
    stroke: black;
    opacity: 0.8;
    stroke-width: 1.5;
  }
`;

export const AddIcon = styled(Add)`
  align-items: center;
  height: 22px;
  width: 22px;
  path {
    stroke: black;
  }
`;

export const ColouredInput = styled.input`
  background-color: black;
  border-radius: 4px;
  border: 2px solid #bcbcbc;

  :checked {
    border: 2px solid red;
    background-color: red;
  }
  :after {
    border: 2px solid red;
    background-color: red;
  }
`;

export const ViewTabButton = styled.button`
  min-height: 50px;
  padding: 12px;
  :focus {
    outline: none;
  }
  ${(props) =>
    props.selected
      ? 'background-color: #4987CE; border: 1px solid #4987CE;'
      : 'background-color: transparent; border: 1px solid #A4C3E6;'}
`;
