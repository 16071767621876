import styled from 'styled-components';
import { FormGroup } from 'reactstrap';
import Creatable from 'react-select/creatable';

import DeleteSvg from 'jsx:assets/svg/close.svg';

export const QuestionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-template-rows: auto auto;
  grid-auto-flow: row;
  grid-template-areas:
    'question  questionType'
    'showWhen  questionType';
  grid-column-gap: 20px;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'question	 '
      'showWhen	 '
      'questionType';
  }
`;

export const CustomFormGroup = styled(FormGroup)`
  grid-area: ${(props) => props.gridArea};
`;

export const DeleteIcon = styled(DeleteSvg)`
  grid-area: deleteBtn;
  align-self: baseline;
  width: 20px;
  cursor: pointer;
`;

export const CustomCreatableSelect = styled(Creatable)`
  margin-top: 10px;
`;
