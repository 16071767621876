import styled from 'styled-components';

export const Image = styled.img `
  object-fit: cover;
  height: 113px;
  width: 113px;
  border-radius: 50%;
`;

export const Background = styled.img `
  position: relative;
  margin-top: -25%;
  z-index: -1;

  @media screen and (max-width: 1024px) {
    bottom 0;
    margin-top: 0;
  }

  @media screen and (min-width: 1600px) {
    bottom 0;
    margin-top: 0;
  }
`;