import React from 'react';
import ReactTooltip from 'react-tooltip';

import { Container, HelpIcon } from './styles';
import { HelpCircleProps } from '.';

const HelpCirclePrimary: React.FC<HelpCircleProps> = ({
  tooltipText,
  colour,
  height,
  width,
  place,
  offset,
  key,
}) => {
  return (
    <Container data-for={`help-circle_${key ?? ''}`} data-tip={tooltipText}>
      <HelpIcon width={width ?? '22px'} height={height ?? '22px'} colour={colour ?? '#003473'} />
      <ReactTooltip
        id={`help-circle_${key ?? ''}`}
        data-offset={offset ?? 'bottom'}
        place={place ?? 'bottom'}
        effect='solid'
        multiline
        border
      />
    </Container>
  );
};

export default HelpCirclePrimary;
