import { SVGProps } from 'react';
const Refresh = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 22 19' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.9375 7.66016L4.8036 11.1995L8.34287 8.33339'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
    />
    <path
      d='M12.7119 17.1865C16.9571 17.1865 20.3985 13.7452 20.3985 9.50001C20.3985 5.25485 16.9571 1.81348 12.7119 1.81348C8.46677 1.81348 5.02539 5.25485 5.02539 9.50001'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);
export default Refresh;
