import React from 'react';

import Wrapper from '../components/Wrapper';
import Main from '../components/Main';
import Navbar from '../components/Navbar';
import Content from '../components/Content';

const Wizard = ({ children, navBar = true }) => (
	<React.Fragment>
		<Wrapper>
			<Main>
				{ navBar ? <Navbar sidebarToggle={false} /> : null }
				<Content>{children}</Content>
			</Main>
		</Wrapper>
	</React.Fragment>
);

export default Wizard;
