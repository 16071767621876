import styled from 'styled-components';

const Key = styled.label`
	margin: 5px 15px 5px 0;
	padding-left: 5px;
	font-size: 16px;
	border-left: 16px solid ${props => props.colour};
	line-height: 16px;
	white-space: nowrap;
	user-select: none;
`;
export default Key;
