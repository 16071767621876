import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';

import { CHLogoWhite } from '../Logo';
import {
  Media,
  FooterContainer,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  MediaList,
  LegalHeader,
  Header5,
  Link,
} from './styles';

class Footer extends Component {
  render() {
    return (
      <FooterContainer>
        <Container>
          <Row>
            <Col sm={12} md={6} lg={3} className='mb-4'>
              <CHLogoWhite height={40} width={40} className='mb-4' />
              <LegalHeader>
                © {new Date().getFullYear()} Clearhead. All rights reserved.
                <br />
                Proudly made in NZ.
              </LegalHeader>
              <MediaList>
                <Media href='https://www.linkedin.com/company/clear-head/'>
                  <LinkedinIcon />
                </Media>

                <Media href='https://www.facebook.com/clearhead.nz'>
                  <FacebookIcon />
                </Media>

                <Media href='https://www.instagram.com/clearheadnz/'>
                  <InstagramIcon />
                </Media>
              </MediaList>
            </Col>

            <Col sm={12} md={6} lg={3} className='mb-4'>
              <Header5>Quick Links</Header5>
              <Link href='https://www.myclearhead.com/'>My Wellbeing Plan</Link>
              <Link href='https://provider.myclearhead.com'>Provider Portal</Link>
              <Link href='https://www.myclearhead.com/practitioners'>Find a Therapist</Link>
              <Link href='https://www.myclearhead.com/resources'>Useful Resources</Link>
            </Col>
            <Col sm={12} md={6} lg={3} className='mb-4'>
              <Header5>Company</Header5>
              <Link href='https://www.myclearhead.com/about'>Our Story</Link>
              <Link href='https://www.myclearhead.com/our-team'>Our Team</Link>
              <Link href='https://www.myclearhead.com/blog'>Our Blog</Link>
            </Col>
            <Col sm={12} md={6} lg={3} className='mb-4'>
              <Header5>Support &amp; Legal</Header5>
              <Link href='/faq'>FAQ</Link>
              <Link href='https://www.myclearhead.com/privacy-policy'>Privacy Policy</Link>
              <Link href='https://www.myclearhead.com/provider-terms-of-use'>Terms of Use</Link>
              <Link href='/contact-us'>Contact Us</Link>
            </Col>
          </Row>
        </Container>
      </FooterContainer>
    );
  }
}

export default Footer;
