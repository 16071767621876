"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = responsiveStyleModifierPropTypes;

var _isResponsiveModifiersProp = _interopRequireDefault(require("./utils/isResponsiveModifiersProp"));

var _styleModifierPropTypes = require("./styleModifierPropTypes");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * TODO: (DEPRECATED) The functionality provided here is now incorporated into
 * `styleModifierPropTypes`. This should be removed before the v2 release.
 */

/**
 * Evaluates the modifiers prop against the modifier config. Throws invalid proptype error
 * if a modifier is supplied in prop and not found in modifier config.
 * @export
 * @param {ModifiersConfig} modifierConfig
 * @returns {Validator<ModifierKeys>}
 */
function responsiveStyleModifierPropTypes(modifierConfig) {
  var validator = function validator(props, propName, componentName) {
    var responsiveModifiers = props[propName];

    if ((0, _isResponsiveModifiersProp.default)(responsiveModifiers)) {
      return (0, _styleModifierPropTypes.validateResponsiveModifiers)(propName, componentName, responsiveModifiers, modifierConfig);
    }

    return null;
  };

  return validator;
}