/* Third party */
import React from 'react';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Helmet } from 'react-helmet';

/* Components */
import IndexSection from './IndexSection';

import QuestionSection from './QuestionSection';

// noinspection JSAnnotator
const FAQ = () => (
  <>
    <Helmet
      script={[
        helmetJsonLdProp({
          '@context': 'url',
          '@type': 'FAQPage',
          mainEntity: [
            {
              '@type': 'Question',
              name: 'What are the requirements I need to meet to join Clearhead?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'Clearhead follows strict guidelines about the therapists we accept\n' +
                  '              onto our database and you must be currently registered to one of\n' +
                  '              the following professional bodies:\n' +
                  '              <ul >\n' +
                  '                <li >\n' +
                  '                  NZ Psychologists Board registered psychologists (NZPB)\n' +
                  '                </li>\n' +
                  '                <li >\n' +
                  '                  Psychotherapist Board of Aotearoa NZ registered\n' +
                  '                  psychotherapists (PBANZ)\n' +
                  '                </li>\n' +
                  '                <li>\n' +
                  '                  Australian, New Zealand & Asian Creative Arts Therapies\n' +
                  '                  Association (ANZACATA)\n' +
                  '                </li>\n' +
                  '                <li>\n' +
                  '                  NZ Association of Counsellors member (NZAC)\n' +
                  '                </li>\n' +
                  '                <li>\n' +
                  '                  NZ Christian Counsellors Association member (NZCCA)\n' +
                  '                </li>\n' +
                  '              </ul>\n' +
                  '              If you are part of a professional body that is not listed here and\n' +
                  '              you would like to join Clearhead, please get in touch with us at\n' +
                  '              contact@myclearhead.com.',
              },
            },
            {
              '@type': 'Question',
              name: "What are the costs associated with using Clearhead's system as a therapist?",
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'It is free to be a part of Clearhead and be listed on our national\n' +
                  '              directory. Our client management and booking system is also free\n' +
                  '              to use.\n' +
                  '              <br />\n' +
                  '              <br />\n' +
                  '              The only charge we make is if we refer a new client to you as part\n' +
                  '              of our employer funded therapy scheme. We will take a 10% referral\n' +
                  '              fee on each booking. For the employer funded therapy scheme, we\n' +
                  '              will be making therapy payments on behalf of the employer and so\n' +
                  '              you can guarantee prompt payments from us.\n' +
                  '              <br />\n' +
                  '              <br />\n' +
                  '              If you receive a new client referral from us that is from the\n' +
                  '              general public, at this stage we have not implemented a referral\n' +
                  '              fee.',
              },
            },
            {
              '@type': 'Question',
              name: 'How can I get in touch with you?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  ' You can contact us via email at&nbsp;\n' +
                  '              <a href="mailto:contact@myclearhead.com">\n' +
                  '                contact@myclearhead.com\n' +
                  '              </a>',
              },
            },
            {
              '@type': 'Question',
              name: 'What happens to my information that I share with you?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'Maintaining your trust and privacy is extremely important to us,\n' +
                  '              as such we have different mechanisms to ensure we keep your\n' +
                  '              information secure.\n' +
                  '              <ul>\n' +
                  '                <li>\n' +
                  '                  Your data is yours and we do not sell any identifiable data to\n' +
                  '                  third parties.\n' +
                  '                </li>\n' +
                  '                <li>\n' +
                  "                  We comply with the Ministry of Health's HISO 10029:2015\n" +
                  '                  standard for data security and privacy.\n' +
                  '                </li>\n' +
                  '                <li>\n' +
                  '                  Your data is encrypted using 256-bit Advanced Encryption\n' +
                  '                  Standard (AES-256).\n' +
                  '                </li>\n' +
                  '                <li>\n' +
                  '                  To protect your data as it travels over the Internet, we use\n' +
                  '                  Transport Layer Security (HTTPS) for all communications.\n' +
                  '                </li>\n' +
                  '                <li>\n' +
                  '                  Our platform is also hosted in data centers used by Microsoft\n' +
                  '                  and Google, these data centers are based in Sydney and have\n' +
                  '                  stringent security policies regarding access and are\n' +
                  '                  recommended by the New Zealand Ministry of Health.\n' +
                  '                </li>\n' +
                  '                <li>\n' +
                  '                  We also ensure regular security audits occur.\n' +
                  '                </li>\n' +
                  '                <li >\n' +
                  '                  Finally, we are a New Zealand company meaning unlike most\n' +
                  '                  other internet companies we follow and abide the New Zealand\n' +
                  '                  Privacy Act 1993 and the Health Information Privacy Code 1994.\n' +
                  '                </li>\n' +
                  '              </ul>',
              },
            },
            {
              '@type': 'Question',
              name: 'Is there any cost to the client for requesting an appointment?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'We do not charge the clients for connecting them up with a\n' +
                  '              therapist regardless of whether they come from the general public\n' +
                  "              or if they are part of Clearhead's employer-funded therapy scheme.\n" +
                  '              <br />\n' +
                  '              <br />\n' +
                  '              Clients can search for therapists and request available\n' +
                  "              appointments here:{' '}\n" +
                  '              <a\n' +
                  '                href="https://www.myclearhead.com/practitioners"\n' +
                  '                rel="noopener noreferrer"\n' +
                  '                target="_blank"\n' +
                  '              >\n' +
                  '                https://www.myclearhead.com/practitioners\n' +
                  '              </a>',
              },
            },
            {
              '@type': 'Question',
              name: 'Where are you based?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: ' We are a proud New Zealand owned company and our team is based in Auckland.',
              },
            },
            {
              '@type': 'Question',
              name: 'Do you have any mental health experts on your team?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: 'Our CEO and co-founder is a medical doctor and we collaborated closely with Psychiatrist, GPs, Psychologists and users of mental health services throughout the design and implementation of the platform. <br /> <br /> We also have a clinical psychologist on the team and a clinical advisory board that comprises of psychiatrists and GPs.',
              },
            },
          ],
        }),
      ]}
    />
    <IndexSection />
    <QuestionSection />
  </>
);

export default FAQ;
