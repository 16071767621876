import styled from "styled-components";

export const CenterText = styled.div`
  font-size: 30px;
  margin: 40px 0 40px 0;
  color: black;
  text-align: center;
`;

export const HeadingText = styled.h1`
  font-size: 20px;
  color: black;
  flex-direction: column;
  margin: 0 0 10px 10px;
`;

export const Text = styled.p`
  font-size: 15px;
  margin: 0 10px 0 10px;
  padding: 0 0 10px 0;
`;

export const Image = styled.img`
  height: 120px;
  margin: 0 0 0 10px;
  border-radius: 50px;
`;

export const Line = styled.hr`
  margin: 0 0 30px 0;
  width: 100%;
  height: 1px;
`;

export const Index = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px 10px 15px;
  background-color: white;
  border-radius: 15px;
  font-weight: bold;
  box-shadow: 1px 1px 5px #c8c8c8;
  top: 265px;
  position: absolute;
`;

export const Child = styled.section``;

export const Link = styled.a`
  text-decoration: none;
`;

export const Card = styled.div`
  border-radius: 15px;
  box-shadow: 1px 1px 10px #c8c8c8;
  margin: 0 0 80px 0;
`;
