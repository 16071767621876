import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 22 23' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle
      cx='9.49465'
      cy='9.55505'
      r='7.25'
      transform='rotate(-45 9.49465 9.55505)'
      stroke='currentColor'
      strokeWidth='2'
    />
    <path
      d='M14.9946 15.0551L19.578 19.6384'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default SvgComponent;
