import {
  BookOpen as BookOpenIcon,
  MapPin as MapPinIcon,
  Users as UsersIcon,
  Calendar as CalendarIcon,
  DollarSign as DollarIcon,
  HelpCircle,
  User as UserIcon,
  Settings as SettingsIcon,
  Lock as LockIcon,
  Archive,
} from 'react-feather';

// Marketing
import LandingPage from 'pages/marketing/LandingPage';
import ContactUs from 'pages/marketing/ContactUs';
// import Pricing from "pages/marketing/Pricing";
import FAQ from 'pages/marketing/FAQ';
import About from 'pages/marketing/About';

// Auth
import Forbidden from 'pages/practitioner-portal/auth/Forbidden';
import SignUp from 'pages/practitioner-portal/auth/SignUp';
import Login from 'pages/practitioner-portal/auth/Login';

import Page404 from 'pages/practitioner-portal/auth/Page404';
import Page500 from 'pages/practitioner-portal/auth/Page500';

// App
import AppointmentCalendar from 'pages/practitioner-portal/AppointmentCalendar';
import ManageAvailability from 'pages/practitioner-portal/ManageAvailability';
import Bookings from 'pages/practitioner-portal/Bookings';
import BookingComplete from 'pages/practitioner-portal/Booking/Complete';
import Invoices from 'pages/practitioner-portal/Invoices';
import Invoice from 'pages/practitioner-portal/Invoice';
import Clients from 'pages/practitioner-portal/Clients';
import SavedPrices from 'pages/practitioner-portal/ManagePrices';
import SavedLocations from 'pages/practitioner-portal/ManageLocations';
import ScreeningQuestions from 'pages/practitioner-portal/ManageScreeningQuestions';
import BookingSettings from 'pages/practitioner-portal/ManageBookingSettings';
import MyAccount from 'pages/practitioner-portal/MyAccount';
import SecuritySettings from 'pages/practitioner-portal/SecuritySettings';
import Integration from 'pages/practitioner-portal/Integration';
import CalendarSyncConfirm from '../pages/practitioner-portal/CalendarSyncConfirm/index';

const marketingRoutes = {
  path: '/',
  name: 'Marketing',
  children: [
    {
      path: '/',
      name: 'Home',
      component: LandingPage,
    },
    {
      path: '/contact-us',
      name: 'ContactUs',
      component: ContactUs,
    },
    {
      path: '/about',
      name: 'About',
      component: About,
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: FAQ,
    },
  ],
};

const authRoutes = {
  path: '/auth',
  name: 'Auth',
  icon: UsersIcon,
  badgeColor: 'secondary',
  children: [
    {
      path: '/auth/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/auth/forbidden',
      name: 'Forbidden',
      component: Forbidden,
    },
    {
      path: '/auth/sign-up',
      name: 'Sign Up',
      component: SignUp,
    },
    {
      path: '/auth/404',
      name: '404 Page',
      component: Page404,
    },
    {
      path: '/auth/500',
      name: '500 Page',
      component: Page500,
    },
    {
      path: '/auth/integration/:loginSystem',
      name: 'My Account',
      component: Integration,
    },
    {
      path: '/auth/sync/complete',
      name: 'CompleteIntegration',
      component: CalendarSyncConfirm,
    },
  ],
};

const AppointmentCalendarRoute = {
  path: '/app/appointment-calendar',
  name: 'Appointment Calendar',
  icon: CalendarIcon,
  component: AppointmentCalendar,
};

const BookingCompletionRoute = {
  path: '/app/booking/complete/:id',
  name: 'Booking Completion',
  component: BookingComplete,
  excludeFromNav: true,
};

const BookingsRoute = {
  path: '/app/bookings',
  name: 'Bookings',
  icon: BookOpenIcon,
  component: Bookings,
};

const InvoiceRoute = {
  path: '/app/invoice/:id',
  name: 'Invoice',
  component: Invoice,
  excludeFromNav: true,
};

const InvoicesRoute = {
  path: '/app/invoices',
  name: 'Invoices',
  icon: Archive,
  component: Invoices,
};

const ClientsRoute = {
  path: '/app/clients',
  name: 'Clients',
  icon: UsersIcon,
  component: Clients,
};

const ManageAvailabilityRoute = {
  path: '/app/manage-availability',
  name: 'Manage Availability',
  icon: CalendarIcon,
  component: ManageAvailability,
};

const PricesRoute = {
  path: '/app/prices',
  name: 'Manage Services / Prices',
  icon: DollarIcon,
  component: SavedPrices,
};

const LocationsRoute = {
  path: '/app/locations',
  name: 'Manage Locations',
  icon: MapPinIcon,
  component: SavedLocations,
};

const ScreeningQuestionsRoute = {
  path: '/app/questions',
  name: 'Manage Screening Questions',
  icon: HelpCircle,
  component: ScreeningQuestions,
};

const BookingSettingsRoute = {
  path: '/app/booking-settings',
  name: 'Booking Settings',
  icon: BookOpenIcon,
  component: BookingSettings,
};

const TherapySettingsRoute = {
  name: 'Therapy Settings',
  icon: SettingsIcon,
  children: [
    ManageAvailabilityRoute,
    PricesRoute,
    LocationsRoute,
    ScreeningQuestionsRoute,
    BookingSettingsRoute,
  ],
};

const MyAccountRoute = {
  path: '/app/my-account',
  name: 'Profile Information',
  icon: UserIcon,
  component: MyAccount,
};

const keycloakAccountSettings = {
  path: '/app/security-settings',
  name: 'Security Settings',
  icon: LockIcon,
  component: SecuritySettings,
};

// App routes
export const appRoutes = [
  AppointmentCalendarRoute,
  BookingsRoute,
  BookingCompletionRoute,
  InvoicesRoute,
  InvoiceRoute,
  ClientsRoute,
  TherapySettingsRoute,
  MyAccountRoute,
  keycloakAccountSettings,
];

// Auth specific routes
export const auth = [authRoutes];

export const marketing = [marketingRoutes];
