import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { observer } from 'mobx-react';

@observer
class ConfirmModal extends Component {
  render() {
    const { isOpen, toggleModal, onCancel, onConfirm, body, title } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggleModal} size='sm' centered>
        <ModalHeader className='bg-light' toggle={toggleModal}>
          {title}
        </ModalHeader>
        <ModalBody className='p-3'>{body}</ModalBody>
        <ModalFooter className='d-flex justify-content-end align-items-center'>
          <div>
            <Button color='link' onClick={onCancel}>
              Cancel
            </Button>
            <Button color='primary' onClick={onConfirm}>
              Confirm
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ConfirmModal;
