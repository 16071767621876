import React from 'react';
import { useKeycloak } from '@react-keycloak-fork/web'
import { Button } from "reactstrap";

const KeycloakButton = () => {
  const { keycloak } = useKeycloak();
  const url = new URL(`${window.location.origin}/app`);
  if (keycloak.authenticated) {
    return (
      <Button
        size="lg"
        className="align-middle d-none d-md-block"
        color="success"
        href="/app"
      >
        Dashboard
      </Button>
    )
  }
  return (
    <Button
      size="lg"
      className="align-middle d-none d-md-block"
      color="success"
      onClick={() => {
        keycloak.login({
          redirectUri: url,
        });
      }}
    >
      Sign in
    </Button>
  )
}

export default KeycloakButton;