import styled from 'styled-components';
import { ArrowRight } from 'react-feather';

export const Section = styled.section`
  position: relative;
  background-color: ${(props) => props.backgroundcolor || '#ffffff'};
`;

export const SectionSpacing = styled.div`
  padding: 8em 0;
  @media screen and (max-width: 991px) {
    padding: 6em 0;
  }
`;

export const SectionSpacingCentered = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Tile = styled.div`
  background-color: #ffffff;
  box-shadow: 1px 1px 10px #c8c8c8;
  padding: 4em 6em;
  margin: 0 2em;
  text-align: center;
  border-radius: 0.2rem;
  max-width: 600px;

  @media screen and (max-width: 991px) {
    padding: 2em 4em;
    margin: 0 1em;
    max-width: 100%;
  }
`;

export const CenterText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 0 50px 0;
  padding: 80px 0 0 0;
  flex-direction: column;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  object-fit: cover;

  width: 100%;
  @media screen and (min-width: 320px) {
    height: 100vh;
  }
  @media screen and (min-width: 991px) {
    height: 120vh;
  }
  @media screen and (min-width: 1200px) {
    height: auto;
  }
`;

export const CoverImage = styled.img`
  object-fit: cover;
  width: 100%;
  @media screen and (min-width: 320px) {
    min-height: 800px;
  }
  @media screen and (min-width: 460px) {
    min-height: 500px;
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 0 12em;
  margin-bottom: 20px;
  @media screen and (max-width: 991px) {
    padding: 0 2em;
  }
`;

export const HeaderTitle = styled.h1`
  font-size: 56px;
  color: ${(props) => props.color || '#0b2536'};
  margin-bottom: 20px;
  font-weight: 700;
  @media screen and (max-width: 991px) {
    font-size: 48px;
  }
`;

export const HeaderParagraph = styled.p`
  font-size: 18px;
  color: ${(props) => props.color || '#0b2536'};
  margin-bottom: 30px;
  font-weight: 300;
`;

export const Hint = styled.p`
  font-size: 16px;
  color: #9dd8ff;
  margin-bottom: 20px;
`;

export const Image = styled.img`
  max-width: 950px;
  width: 100%;
  height: auto;
  margin-bottom: 0;

  @media screen and (max-width: 765px) {
    max-width: 100%;
    margin-bottom: 2em;
  }
`;

export const SectionTitle = styled.h1`
  font-size: 36px;
  color: #0b2536;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: left;

  @media screen and (max-width: 991px) {
    font-size: 32px;
  }

  @media screen and (max-width: 765px) {
    text-align: center;
  }
`;

export const SectionParagraph = styled.p`
  font-size: 18px;
  color: ${(props) => props.color || '#506370'};
  margin-bottom: 20px;
  font-weight: 300;
  line-height: 1.8;
  text-align: left;

  @media screen and (max-width: 765px) {
    text-align: center;
  }
`;

export const CtaLink = styled.a`
  display: block;
  font-weight: 600;
  font-size: 16px;
  color: #4987ce;
  text-decoration: none;
  text-align: left;
  &:hover {
    text-decoration: none;
  }

  @media screen and (max-width: 765px) {
    text-align: center;
  }
`;

export const RightIcon = styled(ArrowRight)`
  color: #4987ce;
  margin-left: 8px;
  width: 16px;
  height: 16px;
`;

export const Prompt = styled.a`
  display: block;
  font-size: 36px;
  color: ${(props) => props.color || '#0b2536'};
  text-decoration: none;
  font-weight: 700;
  line-height: 1.6;
  margin-bottom: 0;
  &:hover,
  &:focus {
    cursor: pointer;
    color: inherit;
  }
  @media screen and (max-width: 991px) {
    font-size: 32px;
  }
`;

export const Highlighted = styled.span`
  color: #4987ce;
`;

export const Paragraph = styled.p`
  font-size: 18px;
  color: #506370;
  margin-bottom: 20px;
  font-weight: 300;
  line-height: 1.8;
`;

export const ClientContainer = styled.div`
  padding: 2em 3em;
  border-radius: 0.2rem;
  border: 1px solid #ecebea;
  background-color: #ffffff;
  max-width: 100%;
`;

export const ClientImage = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
`;

export const ClientParagraph = styled.p`
  font-size: 14px;
`;
