import React, { Component } from 'react';
import styled from 'styled-components';

const Separator = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.2);
  }
`;

const Label = styled.label`
  font-weight: 600;
  font-size: 0.75rem;
  padding: 0;
  text-transform: uppercase;
  background-color: white;
  z-index: 0;
  padding-right: 6px;
`;

type HorizontalSeparatorProps = {
  className?: string;
  children?: string;
};

const HorizontalSeparator: React.FC<HorizontalSeparatorProps> = ({ className, children }) => (
  <Separator className={className}>
    <Label>{children}</Label>
  </Separator>
);

export default HorizontalSeparator;
