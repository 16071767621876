import * as React from 'react';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { Add } from '@clearhead-ltd/ui-components/dist/v2/Icons';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import { OptionCard } from '../SetupWizard/WizardForm/styles';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';
import Input from '@clearhead-ltd/ui-components/dist/v2/Form/Input';
import Modal from '@clearhead-ltd/ui-components/dist/v2/Modal';
import OptionLabel from '@clearhead-ltd/ui-components/dist/v2/OptionLabel';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import pencilIMG from '../../../assets/img/pencil.png';
import Switch from 'common/Switch';
import { AsyncSearch } from 'common/AsyncSearch';
import PlacesService from 'services/PlacesService';
import debounce from 'debounce-promise';
import * as Yup from 'yup';
import { BlockLoading } from '../../../common/Loading';
import ToggleButton from '../../../common/ToggleButton';
import { BinIcon } from '../ManageAvailability/styles';

const StyledSearch = styled(AsyncSearch)`
  min-width: 0px !important;
  .react-select__control {
    padding-left: 0;
    padding-right: 0;
    border-width: 2px !important;
    border-color: #a4c3e6;
    height: 48px;
    border-radius: 8px !important;
    font-size: 16px;
  }

  // Opacity was being set to 0 after selection, causing text to vanish
  .react-select__input {
    opacity: 1 !important;
  }
`;

const PlusIcon = styled(Add)`
  height: 18px;
  width: 18px;
  path {
    stroke: #4987ce;
    fill: #4987ce;
    stroke-width: 2;
  }
`;

const LocationsComponent = inject(
  'locations',
  'app',
  'auth'
)(
  observer((props) => {
    const [locationModalOpen, setLocationModalOpen] = React.useState(false);
    const [editingModal, setEditingModal] = React.useState(false);
    const [locationArray, setLocationArray] = React.useState([]);
    const [locationToEdit, setLocationToEdit] = React.useState();
    const [onlineEnabled, setOnlineEnabled] = React.useState(true);
    const [onlineLocation, setOnlineLocation] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const updateOnlineEnabled = (isEnabled) => {
      setOnlineEnabled(isEnabled);

      if (isEnabled) {
        const newLocation = {
          nickname: 'Online',
          type: 'Online',
        };
        createLocation(newLocation, 'Online Therapy enabled').then((response) => {
          if (response.hasError) {
            // Revert switch on error
            setOnlineEnabled(false);
          }
        });
      } else {
        onlineLocation.forEach((e) => {
          removeLocation(e, 'Online Therapy disabled').then((response) => {
            if (response.hasError) {
              // Revert switch on error
              setOnlineEnabled(true);
            }
          });
        });
      }
    };

    const resetModal = () => {
      setEditingModal(false);
      setLocationModalOpen(false);
    };

    React.useEffect(() => {
      const { auth } = props;
      if (auth.currentPractitioner) {
        setIsLoading(true);
        fetchLocations().then(() => setIsLoading(false));
      }
    }, [props.auth.currentPractitioner]);

    React.useEffect(() => {
      if (props.setIsValid) {
        const isValid = locationArray.length > 0 || (onlineEnabled && onlineLocation.length > 0);
        props.setIsValid(isValid);
      }
    }, [locationArray, onlineEnabled, onlineLocation]);

    const fetchLocations = async () => {
      const locations = await props.locations.getLocations();
      setLocationArray(
        locations.data.responseObject.filter((lock) => lock.location.locationType !== 'Online')
      );
      setOnlineEnabled(
        locations.data.responseObject.some((lock) => lock.location.locationType === 'Online')
      );
      setOnlineLocation(
        locations.data.responseObject.filter((lock) => lock.location.locationType === 'Online')
      );
    };

    const createLocation = async (data, toastMessage = 'Location has been added') => {
      const newLocation = {
        nickname: `${data.nickname}`,
        location: {
          locationType: data.type,
          streetAddress: `${data.addressLineOne}`,
          suburb: `${data.suburb}`,
          city: `${data.city}`,
          postCode: `${data.postCode}`,
          notes: `${data.notes}`,
        },
      };
      if (data.type === 'Region') {
        delete newLocation.location.streetAddress;
      }
      return props.locations.createLocation(newLocation).then((response) => {
        if (response.hasError) {
          toast.error(response.message);
          return response;
        } else {
          fetchLocations();
          resetModal();
          toast.success(toastMessage);
          return response;
        }
      });
    };

    const editLocation = async (data, toastMessage = 'Location has been updated') => {
      const updatedLocation = {
        id: `${locationToEdit.id}`,
        nickname: `${data.nickname}`,
        location: {
          locationType: data.type,
          streetAddress: `${data.addressLineOne}`,
          suburb: `${data.suburb}`,
          city: `${data.city}`,
          postCode: `${data.postCode}`,
          notes: `${data.notes}`,
        },
      };
      if (data.type === 'Region') {
        delete updatedLocation.location.streetAddress;
      }
      return props.locations.editLocation(updatedLocation).then((response) => {
        if (response.hasError) {
          toast.error(response.message);
          return response;
        } else {
          fetchLocations();
          resetModal();
          toast.success(toastMessage);
          return response;
        }
      });
    };

    const removeLocation = async (data, toastMessage = 'Location has been removed') => {
      return props.locations.deleteLocation(data).then((response) => {
        if (response.hasError) {
          toast.error(response.message);
          return response;
        } else {
          fetchLocations();
          toast.success(toastMessage);
          return response;
        }
      });
    };

    const editLocationModal = (location) => {
      setLocationToEdit(
        locationArray.find((e) => {
          return e.id === location.id;
        })
      );
    };

    const setFullAddress = (e) => {
      if (e.fullAddress == null) {
        e.fullAddress = `${e.addressLineOne}, ${e.suburb} ${e.postCode}, ${e.city}`;
      } else {
        e.fullAddress = e.fullAddress.label;
      }
    };

    const schema = Yup.object().shape({
      nickname: Yup.string().required('Please enter a location name'),
      addressLineOne: Yup.string().when('type', {
        is: 'Address',
        then: Yup.string().required('Required'),
      }),
      suburb: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      type: Yup.string().required('Required'),
      postCode: Yup.string(),
    });

    const loadOptions = (value, type) => onSearch(value, type);
    const debouncedLoadOptions = debounce(loadOptions, 1200, {
      leading: false,
    });

    const onSearch = (query, type) => {
      const country = props.auth.currentPractitioner.country;
      return PlacesService.search(query, country ?? 'NZL').then((response) => {
        const options = mapResponse(response.data, type);
        return options;
      });
    };

    const mapResponse = (data, type) => {
      let locations = [];
      if (data && data.items && data.items.length) {
        if (type === 'Address') {
          locations = data.items.filter((item) => {
            return item.resultType === 'houseNumber';
          });
        } else {
          locations = data.items;
        }
        locations = locations.map((item) => ({
          label: item.title,
          value: {
            ...item.position,
            address: item.address,
          },
        }));
      }
      return locations;
    };

    return (
      <div className='mt-8 w-full'>
        <div className='text-center px-6'>
          <Heading className='text-dark-blue mb-6' as={'h3'}>
            Add your Locations
          </Heading>
          {isLoading ? <BlockLoading /> : null}
          {locationArray.map((location) => (
            <div key={location.id} className='flex mb-6 w-full'>
              <OptionCard className='w-full text-left min-h-20'>
                <div className='flex'>
                  <Heading className='text-dark-blue' as={'h5'}>
                    {location.nickname}
                  </Heading>
                </div>
                <div className='text-md'>{location.location.formattedAddress}</div>
              </OptionCard>
              <div>
                <Button
                  className='ml-2 mt-2'
                  variant='none'
                  modifiers='naked'
                  onClick={() => {
                    editLocationModal(location);
                    setEditingModal(true);
                    setLocationModalOpen(true);
                  }}
                >
                  <img className='h-5' src={pencilIMG} alt='' />
                </Button>
                <Button
                  variant='none'
                  modifiers='naked'
                  className='ml-2 mt-3'
                  onClick={() => {
                    removeLocation(location);
                  }}
                >
                  <BinIcon className='fill-current cursor-pointer outline-hidden' />
                </Button>
              </div>
            </div>
          ))}
          <Button
            variant='none'
            modifiers='naked'
            className='w-full'
            onClick={() => {
              setLocationModalOpen(true);
              setEditingModal(false);
            }}
          >
            <OptionLabel className='bg-primary-blue-15 w-full hover:bg-primary-blue-50'>
              <div className='flex mr-auto w-full items-vertical'>
                <PlusIcon />
                <Heading className='text-dark-blue ml-2' as={'h5'}>
                  Add a Location
                </Heading>
              </div>
            </OptionLabel>
          </Button>
          <Heading className='text-dark-blue mb-1 mt-12 text-left' as={'h5'}>
            Online Therapy
          </Heading>
          <Text className='text-left mb-2'>
            You will need to use Zoom, Google Meets or an alternative platform to facilitate these
            sessions. Links for these sessions will need to be separately sent out to clients.
          </Text>
          <Switch checked={onlineEnabled} onChange={updateOnlineEnabled}>
            Enable online video sessions as an option for your clients.
          </Switch>
        </div>

        {locationModalOpen ? (
          <Modal className='min-h-min' isOpen={locationModalOpen} hideModal={resetModal}>
            <Formik
              initialValues={
                editingModal
                  ? {
                      nickname: locationToEdit.nickname,
                      addressLineOne: locationToEdit.location.streetAddress,
                      city: locationToEdit.location.city,
                      suburb: locationToEdit.location.suburb,
                      postCode: locationToEdit.location.postCode,
                      notes: locationToEdit.location.notes,
                      type: locationToEdit.location.locationType,
                    }
                  : {
                      nickname: '',
                      addressLineOne: '',
                      city: '',
                      suburb: '',
                      postCode: '',
                      notes: '',
                      type: '',
                    }
              }
              validationSchema={schema}
              validateOnChange={false}
              validateOnBlur={true}
              onSubmit={(values) => {
                editingModal ? editLocation(values) : createLocation(values);
              }}
              render={({ values, touched, errors, handleSubmit, setFieldValue }) => {
                return (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                      setFullAddress(values);
                    }}
                  >
                    <div className='p-4'>
                      <div>
                        <Heading className='text-dark-blue text-center mb-4' as='h4'>
                          {editingModal ? 'Edit Location' : 'Add a Location'}
                        </Heading>
                      </div>

                      <div>
                        <Heading className='text-dark-blue' as='h5'>
                          Location Name
                        </Heading>
                        <Input
                          type='text'
                          name='nickname'
                          className='border-2 border-primary-blue-50 rounded-md w-full h-12 p-2 mt-2 text-base'
                          placeholder='e.g. CBD Office'
                          value={values.nickname}
                          invalid={touched.nickname && !!errors.nickname}
                          onChange={(e) => setFieldValue('nickname', e.target.value)}
                          onBlur={(e) => {
                            setFieldValue('nickname', e.target.value.trim());
                          }}
                        />
                        {errors.nickname && (
                          <div className='text-red text-base'>{errors.nickname}</div>
                        )}
                      </div>
                      <Heading className='text-dark-blue text-left mt-4' as='h5'>
                        {!editingModal && 'Do you want to add an '}Address or Region?
                      </Heading>
                      <div className='mb-2 mt-2 flex gap-2'>
                        <ToggleButton.Primary
                          onClick={(e) => {
                            setFieldValue('type', 'Address');
                          }}
                          active={values.type === 'Address'}
                          className='mb-2'
                          title='Address'
                        />
                        <ToggleButton.Primary
                          onClick={(e) => {
                            setFieldValue('type', 'Region');
                          }}
                          active={values.type === 'Region'}
                          className='mb-2'
                          title='Region'
                        />
                      </div>
                      {values.type !== '' && (
                        <>
                          {values.type === 'Address' && (
                            <div>
                              <Heading className='text-dark-blue mt-4' as='h5'>
                                Address
                              </Heading>
                              <StyledSearch
                                className='mt-2 min-w-0'
                                classNamePrefix='react-select'
                                name='addressLineOne'
                                placeholder='Search'
                                value={values.addressLineOne}
                                loadOptions={(value) => debouncedLoadOptions(value, values.type)}
                                onChange={(e) => {
                                  setFieldValue('fullAddress', e);
                                  let address = e.value.address;
                                  if (address.houseNumber || address.street) {
                                    setFieldValue(
                                      'addressLineOne',
                                      `${address.houseNumber} ${address.street}`
                                    );
                                  }
                                  if (address.district) {
                                    setFieldValue('suburb', `${address.district}`);
                                  }
                                  setFieldValue('city', `${address.city}`);
                                  setFieldValue('postCode', `${address.postalCode}`);
                                }}
                                defaultInputValue={editingModal ? values.addressLineOne : ''}
                                inputValue={values.addressLineOne}
                                onInputChange={(e, action) => {
                                  if (action.action === 'input-change') {
                                    setFieldValue('addressLineOne', e);
                                  }
                                }}
                              />
                              {errors.addressLineOne && (
                                <div className='text-red text-base'>{errors.addressLineOne}</div>
                              )}
                            </div>
                          )}
                          <div>
                            <Heading className='text-dark-blue mt-4' as='h5'>
                              Suburb
                            </Heading>
                            <Input
                              type='text'
                              name='suburb'
                              className='border-2 border-primary-blue-50 rounded-md w-full h-12 p-2 mt-2 text-base'
                              placeholder='Suburb Name'
                              value={values.suburb}
                              onChange={(e) => setFieldValue('suburb', e.target.value)}
                              onBlur={(e) => {
                                setFieldValue('suburb', e.target.value.trim());
                              }}
                            />
                            {errors.suburb && (
                              <div className='text-red text-base'>{errors.suburb}</div>
                            )}
                          </div>
                          <div className='sm:flex mt-4'>
                            <div className='sm:w-64 w-full'>
                              <Heading className='text-dark-blue' as='h5'>
                                City
                              </Heading>
                              <Input
                                name='city'
                                className='border-2 border-primary-blue-50 rounded-md w-full h-12 p-2 mt-2 mb-4 text-base'
                                placeholder='City'
                                value={values.city}
                                invalid={touched.city && !!errors.city}
                                onChange={(e) => setFieldValue('city', e.target.value)}
                                onBlur={(e) => {
                                  setFieldValue('city', e.target.value.trim());
                                }}
                              />
                              {errors.city && (
                                <div className='text-red text-base -mt-4 mb-4'>{errors.city}</div>
                              )}
                            </div>
                            <div className='sm:w-64 w-full sm:ml-auto'>
                              <Heading className='text-dark-blue' as='h5'>
                                Postcode
                              </Heading>
                              <Input
                                name='postCode'
                                type='text'
                                className='border-2 border-primary-blue-50 rounded-md w-full h-12 p-2 mt-2 text-base'
                                placeholder='Postcode'
                                value={values.postCode}
                                invalid={touched.postCode && !!errors.postCode}
                                onChange={(e) => setFieldValue('postCode', e.target.value)}
                                onBlur={(e) => {
                                  setFieldValue('postCode', e.target.value.trim());
                                }}
                              />
                              {errors.postCode && (
                                <div className='text-red text-base'>{errors.postCode}</div>
                              )}
                            </div>
                          </div>
                          <div>
                            <Heading className='text-dark-blue mt-4' as='h5'>
                              Notes
                            </Heading>
                            <Input
                              type='text'
                              name='notes'
                              className='border-2 border-primary-blue-50 rounded-md w-full h-12 p-2 mt-2 text-base'
                              placeholder='e.g. Access from driveway'
                              value={values.notes}
                              onChange={(e) => setFieldValue('notes', e.target.value)}
                              onBlur={(e) => {
                                setFieldValue('notes', e.target.value.trim());
                              }}
                            />
                          </div>
                          <div className='flex w-full mt-4'>
                            <div>
                              <Button
                                variant='primary'
                                type='button'
                                modifiers={'inverted'}
                                onClick={resetModal}
                              >
                                Cancel
                              </Button>
                            </div>
                            <div className='ml-auto'>
                              <Button
                                variant='primary'
                                data-test-id='complete-account-type'
                                type='submit'
                              >
                                {editingModal ? 'Save' : 'Add Location'}
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </form>
                );
              }}
            />
          </Modal>
        ) : null}
      </div>
    );
  })
);

export default LocationsComponent;
