import React, { Component } from 'react';
import styled from 'styled-components';

import ChLogoSvg from 'jsx:assets/svg/ch-logo.svg';

const Option = styled.div`
	display: grid;
	grid-template-columns: 19px 1fr;
	grid-column-gap: 8px;
	padding: 8px 12px;
	font-size: inherit;
	background-color: transparent;
	color: inherit;
	cursor: pointer;
	user-select: none;
	box-sizing: border-box;

	:hover {
		color: white;
		background-color: #9d7bd8;

		path {
			fill: white;
		}
	}
`;

const ChLogoIcon = styled(ChLogoSvg)`
	height: 19px;
	fill: var(--blue);
`;

class AsyncOption extends Component {
	render() {
		const {
			innerRef,
			innerProps,
			children,
			data: { client },
		} = this.props;

		return (
			<Option innerRef={innerRef} {...innerProps}>
				{client.clearheadAccount ? <ChLogoIcon /> : <div />}
				{children}
			</Option>
		);
	}
}

export default AsyncOption;
