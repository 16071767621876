export const BASE_URL = `${window.ENV.REACT_APP_BASE_URL}`;
export const BASE_API_URL = `${BASE_URL}/api/v1`;
export const CRONIFY_CLIENT_ID = `${window.ENV.REACT_APP_CRONIFY_CLIENT_ID}`;
export const KEYCLOAK_URL = `${window.ENV.REACT_APP_KEYCLOAK_AUTH_URL}`;

export const STATES = Object.freeze({
  IDLE: 'Idle',
  PROCESSING: 'Processing',
  ERROR: 'Error',
  DONE: 'Done',
});

export const INVOICE_STATES = Object.freeze({
  DRAFT: {
    display: 'Draft',
    value: 'DRAFT',
    color: 'secondary',
    actionLabel: 'N/A',
  },
  SENT: {
    display: 'Sent',
    value: 'SENT',
    color: 'primary',
    actionLabel: 'Send Invoice',
  },
  OVERDUE: {
    display: 'Overdue',
    value: 'OVERDUE',
    color: 'danger',
    actionLabel: 'Send Reminder',
  },
  PAID: {
    display: 'Paid',
    value: 'PAID',
    color: 'success',
    actionLabel: 'Mark as Paid',
  },
  CANCELLED: {
    display: 'Cancelled',
    value: 'CANCELLED',
    color: 'danger',
    actionLabel: 'Cancel Invoice',
  },
  OVERDUE: {
    display: 'Overdue',
    value: 'OVERDUE',
    color: 'danger',
    actionLabel: '',
  },
});
export const MAX_CHALLENGES = 3;
export const MAX_GOALS = 3;
export const MAX_ACTIONS = 5;

export const STORAGE_KEY = 'clearheadau';
export const INVOICES_KEY = 'clearhead_invoices';
export const AUTH_KEY = 'clearheadtokens';

export const ResourceTypes = [
  { value: 'Books', label: 'Books' },
  { value: 'Website', label: 'Websites' },
  { value: 'Video', label: 'Videos' },
  { value: 'Audio', label: 'Audio' },
  { value: 'App', label: 'Apps' },
  { value: 'Brochure', label: 'Documents' },
  { value: 'SupportGroup', label: 'Support Groups' },
  { value: 'Helpline', label: 'Helplines' },
  // { value: 'Courses', label: 'Courses' },
];

export const AddResourceTypes = [
  { value: 'Books', label: 'Book' },
  { value: 'Website', label: 'Website' },
  { value: 'Video', label: 'Video' },
  { value: 'Audio', label: 'Audio' },
  { value: 'App', label: 'App' },
  { value: 'Brochure', label: 'Document' },
  { value: 'SupportGroup', label: 'Support Group' },
  { value: 'Helpline', label: 'Helpline' },
];

export const Emotions = {
  depressed: 'Depressed',
  sad: 'Sad',
  meh: 'Meh',
  fine: 'Fine',
  happy: 'Happy',
  anxious: 'Anxious',
  confused: 'Confused',
  tired: 'Tired',
  angry: 'Angry',
  lonely: 'Lonely',
};

export const LocationColours = ['#4987ce', '#a537fd', '#f89406', '#23cba7', '#67809f', '#c0392b'];

export const GraphWheelColours = {
  green: '#00b81f',
  orange: '#ff8c1a',
  purple: '#9b00c2',
  blue: '#003ac2',
};

export const SupportPersonTypes = [
  { label: 'Friend', value: 'Friend' },
  { label: 'Family', value: 'Family' },
  { label: 'Partner', value: 'Partner' },
];

export const DayOptions = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
];

export const DayOptionMap = {
  Monday: { label: 'Monday', value: 'Monday' },
  Tuesday: { label: 'Tuesday', value: 'Tuesday' },
  Wednesday: { label: 'Wednesday', value: 'Wednesday' },
  Thursday: { label: 'Thursday', value: 'Thursday' },
  Friday: { label: 'Friday', value: 'Friday' },
  Saturday: { label: 'Saturday', value: 'Saturday' },
  Sunday: { label: 'Sunday', value: 'Sunday' },
};

export { default as PostCodes } from './postcodes';
export { PhoneRegex, HomePhoneRegex, MobilePhoneRegex } from './phone_regex';

export const UrlParamRegex = /\??(.*?)=(.*?)(&|$)/g;

export const ProfessionOptions = [
  { label: 'Clinical Psychologist', value: 'Clinical Psychologist' },
  { label: 'Psychologist', value: 'Psychologist' },
  { label: 'Counsellor', value: 'Counsellor' },
  { label: 'Psychotherapist', value: 'Psychotherapist' },
  { label: 'Indigenous Health Practitioner', value: 'Indigenous Health Practitioner' },
  { label: 'Art Therapist', value: 'Art Therapist' },
  { label: 'Social Worker', value: 'Social Worker' },
  { label: 'Occupational Therapist', value: 'Occupational Therapist' },
  { label: 'Other Specialist Provider', value: 'Other Specialist Provider' },
];

export const FundingOptions = [
  { label: 'ACC Sensitive Claims', value: 'ACC Sensitive Claims' },
  { label: 'I Am Hope', value: 'I Am Hope' },
];
