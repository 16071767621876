import React from 'react';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import ToggleButton from '../../../../../common/ToggleButton';
import { BookingCompleteScreenProps, errorMessage } from '..';
import { toast } from 'react-toastify';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import clsx from 'clsx';

type LandingScreenProps = {
  calendar?: any;
  match?: any;
  history?: any;
} & BookingCompleteScreenProps;

const LandingScreen: React.FC<LandingScreenProps> = ({ match, calendar, history, setScreen }) => {
  const [wentAhead, setWentAhead] = React.useState<boolean>(true);
  const [iDidNotAttend, setIDidNotAttend] = React.useState<boolean>(true);
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const completeAppointment = (bookingId: string) => {
    calendar
      .completeAppointment(bookingId, {
        completionStatus: 'COMPLETED',
        chargeForSession: true,
      })
      .then((res) => {
        if (!res.hasError) {
          history.push(`/app/invoice/${res.invoiceId}`);
        } else {
          toast.error(errorMessage);
        }
      });
  };

  const didNotAttend = () => {
    if (iDidNotAttend) {
      setScreen('PRACTITIONER_DNA');
    } else {
      setScreen('CLIENT_DNA');
    }
  };

  const onNext = () => {
    if (wentAhead) {
      setDisabled(true);
      const bookingId = match.params.id;
      if (bookingId) {
        completeAppointment(bookingId);
      }
    } else {
      didNotAttend();
    }
  };

  return (
    <>
      <div className='flex sm:items-center flex-col sm:flex-row mb-8 sm:mb-12'>
        <Text className='sm:mr-2 mb-2 sm:mb-0 w-60'>Did the appointment go ahead?</Text>
        <div>
          <ToggleButton.Secondary
            className='mb-2 sm:mb-0 mr-2'
            onClick={() => setWentAhead(true)}
            active={wentAhead}
            title='Yes'
          />
          <ToggleButton.Secondary
            onClick={() => setWentAhead(false)}
            active={!wentAhead}
            title='No'
          />
        </div>
      </div>

      <div
        className={clsx(
          wentAhead ? 'hidden' : 'mb-12',
          'mb-12 flex sm:items-center flex-col sm:flex-row'
        )}
      >
        <Text className='sm:mr-2 mb-2 sm:mb-0 w-60'>Why did it not go ahead?</Text>
        <div>
          <ToggleButton.Secondary
            className='mb-2 sm:mb-0 mr-2'
            onClick={() => setIDidNotAttend(true)}
            active={iDidNotAttend}
            title='I could not attend'
          />
          <ToggleButton.Secondary
            onClick={() => setIDidNotAttend(false)}
            active={!iDidNotAttend}
            title='Client did not attend'
          />
        </div>
      </div>

      <Button
        color='primary'
        size='lg'
        className='rounded-full h-10 w-32'
        onClick={onNext}
        disabled={disabled}
      >
        Next
      </Button>
    </>
  );
};

export default withRouter(inject('auth', 'calendar')(observer(LandingScreen)));
