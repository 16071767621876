import { SVGProps } from 'react';
const Close = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 12' {...props}>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='m8.682 3.278-6.04 6.04M2.643 3.278l6.039 6.04'
    />
  </svg>
);
export default Close;
