import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';

const InvoiceSettingsSaveModal = inject('auth')(
  observer((props: any) => {
    const { isModalOpen } = props;

    const onSave = () => {
      props.onSubmit();
    };

    const toggleModal = () => {
      props.toggleModal();
    };

    return (
      <>
        <Modal isOpen={isModalOpen} toggle={toggleModal} size='sm' centered>
          <ModalHeader className='bg-light m-0' toggle={toggleModal}>
            Confirm your details
          </ModalHeader>

          <ModalBody className='w-full flex flex-col'>
            <Text className='mb-2'>
              Please confirm your bank account number, you will not be able to change it after you
              press 'Save'.
            </Text>
            {props.bankAccountNumber && (
              <Text className='mb-4 '>
                Your bank account number:{' '}
                <span className='font-semibold'>{props.bankAccountNumber}</span>
              </Text>
            )}
            <div className='flex mt-2'>
              <Button className='mx-auto mr-4' onClick={onSave}>
                Save
              </Button>
              <Button className='mx-auto bg-red' onClick={toggleModal}>
                Cancel
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  })
);

export default InvoiceSettingsSaveModal;
