import * as React from 'react';
import styled from 'styled-components';
import { HelpCircle } from 'common';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import Button from '@clearhead-ltd/ui-components/dist/v2/Button';
import Modal from '@clearhead-ltd/ui-components/dist/v2/Modal';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import { BinIcon, ColouredInput, GetTimeDropDown } from './styles';
import { DropdownColourStyles } from '../SetupWizard/WizardForm/styles';
import ChevronDownSvg from 'jsx:assets/svg/chevron-down.svg';
import ChevronUpSvg from 'jsx:assets/svg/chevron-up.svg';

const moment = extendMoment(Moment);

const DownIcon = styled(ChevronDownSvg)`
  height: 19px;
  width: 19px;
  fill: #2c7be5;
  margin-left: 5px;
`;

const UpIcon = styled(ChevronUpSvg)`
  height: 19px;
  width: 19px;
  fill: #2c7be5;
  margin-left: 5px;
`;

export const advancedSettings = [
  { label: 'On the hour', value: 'allowOnHour' },
  { label: 'Quarter past the hour', value: 'allowOnQuarterPastHour' },
  { label: 'Half past the hour', value: 'allowOnHalfHour' },
  { label: 'Quarter to the hour', value: 'allowOnQuarterToHour' },
];

const CalendarModal = ({
  serviceModalOpen,
  setServiceModalOpen,
  currentModel,
  serviceArray,
  locationArray,
  weeklyHours,
  setWeeklyHours,
  deleteTime,
}) => {
  const [times, setTimes] = React.useState(currentModel.number);
  const [prices, setPrices] = React.useState(currentModel.number.prices);
  const [locations, setLocations] = React.useState(currentModel.number.locations);
  const [defaultModel, setDefaultModel] = React.useState({});
  const [advancedSettingsOpen, setAdvancedSettingsOpen] = React.useState(false);
  const [advancedSettingsSelect, setAdvancedSettingsSelect] = React.useState(
    currentModel.number.advancedSettings
  );

  React.useEffect(() => {
    if (serviceModalOpen) {
      setTimes(currentModel.number);
      setPrices(currentModel.number.prices);
      setLocations(currentModel.number.locations);
      setDefaultModel(currentModel);
      setAdvancedSettingsSelect(currentModel.number.advancedSettings);
    }
  }, [serviceModalOpen]);

  const updateTime = (option, time) => {
    const placeholder = { ...times };

    placeholder[time] = option;
    setTimes(placeholder);
  };

  const getTime = (time, colour) => {
    const start = moment().hours(0).minutes(0).seconds(0);
    const end = moment().hours(23).minutes(45).seconds(0);
    const range = moment.range(start._d, end._d);
    const timeRageArr = Array.from(range.by('minutes', { step: 15 }));

    const options = timeRageArr.map((time) => {
      return {
        label: moment(time).format('hh:mma'),
        value: moment(time).format('HH:mm:ss'),
      };
    });

    //this is needed as the selected item must be the exact item from the list
    const selectedTime = options.find((v) => v.value === times[time].value);

    return (
      <>
        <GetTimeDropDown
          colour={colour}
          styles={DropdownColourStyles}
          name='time'
          className='bg-white w-full rounded-md border-primary-blue-50'
          placeholder=''
          isSearchable={true}
          value={selectedTime}
          options={options}
          onChange={(option) => updateTime(option, time)}
        />
      </>
    );
  };

  //check that times do not overlap
  const isValid = () => {
    const range = moment.range(
      moment(times.startTime.value, 'h:mma'),
      moment(times.endTime.value, 'h:mma')
    );

    let placeholder = weeklyHours;

    placeholder[currentModel.day.name][currentModel.index] = times;

    let counter = 0;
    let output = true;

    placeholder[currentModel.day.name].forEach((timeSlot) => {
      const tempRange = moment.range(
        moment(timeSlot.startTime.value, 'h:mma'),
        moment(timeSlot.endTime.value, 'h:mma')
      );
      if (range.overlaps(tempRange, { adjacent: false }) && !range.isSame(tempRange)) {
        output = false;
      }
      if (range.isSame(tempRange)) {
        counter++;
      }
    });

    if (counter > 1) {
      output = false;
    }

    return output;
  };

  //check that a valid time range is specified
  const checkTimes = () => {
    if (
      times.startTime.value === times.endTime.value ||
      !moment(moment(times.startTime.value, 'h:mma')).isBefore(
        moment(times.endTime.value, 'h:mma'),
        'minute'
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  const onCancel = () => {
    let placeholder = { ...weeklyHours };

    placeholder[currentModel.day.name][currentModel.index] = defaultModel.number;

    setServiceModalOpen(false);
  };

  const onPriceChange = (price) => {
    const { checked, id } = price.target;
    // Input properties are converted to strings
    const intId = parseInt(id.split('-')[1]);

    if (checked) {
      setPrices(prices.concat(intId));
    } else {
      setPrices(prices.filter((priceId) => priceId !== intId));
    }
  };

  const onLocationChange = (price) => {
    const { checked, id } = price.target;
    // Input properties are converted to strings
    const intId = parseInt(id.split('-')[1]);
    if (checked) {
      setLocations(locations.concat(intId));
    } else {
      setLocations(locations.filter((priceId) => priceId !== intId));
    }
  };

  const onAdvancedSettingsChange = (e, selectedOption) => {
    const { checked } = e.target;
    // // Input properties are converted to strings
    // const intId = parseInt(id.split('-')[1]);
    if (checked) {
      setAdvancedSettingsSelect(advancedSettingsSelect.concat(selectedOption));
    } else {
      setAdvancedSettingsSelect(
        advancedSettingsSelect.filter((option) => option !== selectedOption)
      );
    }
  };

  const onSubmit = () => {
    let placeholder = { ...weeklyHours };
    placeholder[currentModel.day.name][currentModel.index].prices = prices;
    placeholder[currentModel.day.name][currentModel.index].locations = locations;
    placeholder[currentModel.day.name][currentModel.index].advancedSettings =
      advancedSettingsSelect;

    setWeeklyHours(placeholder);
    setServiceModalOpen(false);
  };

  const deleteCurrentTime = () => {
    deleteTime(currentModel.day.name, currentModel.index);
    onCancel();
  };

  return (
    <>
      <Modal
        isOpen={serviceModalOpen}
        hideModal={() => {
          setServiceModalOpen(false);
          onCancel();
        }}
      >
        <div>
          <div className='relative'>
            <BinIcon
              className='fill-current cursor-pointer outline-hidden absolute md:-top-4'
              onClick={() => deleteCurrentTime()}
            />
          </div>
          <Heading className='text-center text-dark-blue mb-4 mt-4 p-4' as='h4'>
            Select services and locations that will be available during this time
          </Heading>
          <div className='w-11/12 mb-6 mx-auto'>
            <div className='flex items-center gap-4 text-lg mb-8'>
              <div className='flex-1'>
                <hr />
              </div>
              <div className='flex-none'>{currentModel.day.name}</div>
              <div className='flex-1'>
                <hr />
              </div>
            </div>
            <div className='flex sm:w-1/2 mx-auto'>
              {getTime('startTime', !checkTimes() || !isValid() ? 'red' : '#A4C3E6')}
              <Text className='px-6 self-center'>To</Text>
              {getTime('endTime', !checkTimes() || !isValid() ? 'red' : '#A4C3E6')}
            </div>

            {!isValid() ? (
              <Text className='text-center text-red mt-4'>
                Times overlap with another set of times
              </Text>
            ) : null}

            {!checkTimes() ? (
              <Text className='text-center text-red mt-4'>Please enter a valid time</Text>
            ) : null}
          </div>
          <div className='w-full sm:w-2/3 m-auto'>
            <div className='justify-center mb-6'>
              <div className='mb-6'>
                <Heading className='text-dark-blue my-4' as='h5'>
                  Services
                </Heading>
                {serviceArray.map((service) => (
                  <div className='flex items-center mb-2' key={service.id}>
                    <input
                      type='checkbox'
                      className='w-5 h-5'
                      id={`price-${service.id}`}
                      label={`${service.name} $${service.cost} (${service.sessionLength}mins)`}
                      onChange={(e) => onPriceChange(e)}
                      checked={prices.includes(service.id)}
                    />
                    <Text className='ml-2'>{`${service.name} $${service.cost} (${service.sessionLength}mins)`}</Text>
                  </div>
                ))}
              </div>
              <div>
                <Heading className='text-dark-blue my-4' as='h5'>
                  Locations
                </Heading>
                {locationArray.map((location) => (
                  <div className='flex items-center mb-2' key={location.location.id}>
                    <ColouredInput
                      type='checkbox'
                      className='w-5 h-5'
                      id={`price-${location.location.id}`}
                      label={location.nickname}
                      onChange={(e) => onLocationChange(e)}
                      checked={locations.includes(location.location.id)}
                    />
                    <Text className='ml-2'>{location.nickname}</Text>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <Text
                className='text-primary-blue-100 cursor-pointer flex items-center justify-center mb-6'
                onClick={() => setAdvancedSettingsOpen(!advancedSettingsOpen)}
              >
                Advanced Settings
                {advancedSettingsOpen ? (
                  <UpIcon viewBox='0 0 24 24' />
                ) : (
                  <DownIcon viewBox='0 0 24 24' />
                )}
              </Text>

              {advancedSettingsOpen ? (
                <div className='mb-4'>
                  <div className='flex items-center mb-4'>
                    <Heading className='text-dark-blue mr-2' as='h5'>
                      Allow appointments to be booked:
                    </Heading>
                    <HelpCircle.Primary
                      tooltipText='This setting controls when a user is allowed to book an appointment. <br />
									For example, if your available hours is set for 1pm-3pm on a Monday: <br />
									When "On the hour" and "Half past the hour" is selected, <br />
									available sessions would be displayed as starting at 1pm, 1:30pm, 2pm and 2:30pm'
                    />
                  </div>
                  {advancedSettings.map((option) => (
                    <div className='flex items-center mb-1' key={option.label}>
                      <ColouredInput
                        type='checkbox'
                        className='w-5 h-5'
                        id={option.label}
                        onChange={(e) => onAdvancedSettingsChange(e, option.label)}
                        checked={advancedSettingsSelect.includes(option.label)}
                      />
                      <Text className='ml-2'>{option.label}</Text>
                    </div>
                  ))}
                  {advancedSettingsSelect.length < 1 && (
                    <Text className='text-center text-red mt-4'>
                      Please pick at least one option for appointments to start
                    </Text>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <div className='flex m-auto justify-center mt-4'>
            <div className='flex justify-start'>
              <Button className='mr-2 mt-4 bg-white' variant='primary' onClick={onCancel}>
                <Text modifiers='bold' className='text-primary-blue-100'>
                  Cancel
                </Text>
              </Button>
            </div>
            <div className='flex justify-end'>
              <Button
                className='ml-2 mt-4'
                variant='primary'
                onClick={() => onSubmit()}
                disabled={!checkTimes() || !isValid() || advancedSettingsSelect.length < 1}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CalendarModal;
