import * as React from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import { inject, observer } from 'mobx-react';
import olderThanThirtyDays, {
  olderThanSpecifiedDays,
} from '../../../utilities/olderThanThirtyDays';

type StatusAlertProps = {
  booking: any;
};

const StatusAlert: React.FC<StatusAlertProps> = (props: any) => {
  const { id, invoiceId, status, cancellationBy, cancellationReason, appointmentTime, contact } =
    props.booking;

  const invoicingEnabled = props.auth.currentPractitioner.invoicingEnabled;
  const invoicingSetup = props.auth.currentPractitioner.invoicingSetup;
  const isToOld = olderThanSpecifiedDays(appointmentTime, 60);

  let alertClassName;
  let alertText;
  let actionPath = '';

  if (invoiceId) {
    actionPath = `/app/invoice/${invoiceId}`;
    alertText = 'View invoice.';
  }

  switch (status) {
    case 'PendingCompletion':
      if (invoicingEnabled && !invoicingSetup) {
        alertText = 'Setup Invoicing';
        actionPath = `/app/setup-wizard`;
        alertClassName = 'bg-secondary-yellow-100 border-secondary-yellow-100';
      } else if (invoicingEnabled) {
        if (isToOld) {
          alertText = 'Mark session as completed';
          actionPath = `/app/booking/complete/${id}`;
          alertClassName = 'bg-secondary-yellow-100 border-secondary-yellow-100';
        } else if (isToOld) {
          alertText = 'Contact Support';
          alertClassName = 'bg-secondary-yellow-100 border-secondary-yellow-100';
        }
      }
      break;

    case 'Completed':
      if (invoiceId) {
        alertText = 'Your invoice has been received.';
        actionPath = `/app/invoice/${invoiceId}`;
        alertClassName = 'bg-dark-green border-dark-green';
      }
      break;
    case 'Rejected':
      alertText = 'This session was rejected by you.';
      alertClassName = 'bg-red border-red';
      break;
    case 'Cancelled':
      alertClassName = 'bg-red border-red';
      switch (cancellationBy) {
        case 'System':
          alertText = 'This session was cancelled by us.';
          break;
        case 'User':
          alertText = `${contact.firstName} ${contact.lastName} cancelled this session.`;
          break;
        case 'Practitioner':
          alertText = cancellationReason
            ? `You cancelled due to: ${cancellationReason}.`
            : 'You cancelled this session.';
          break;
        default:
          alertText = 'This session has been cancelled.';
          break;
      }
      break;
    case 'DidNotAttend':
      alertClassName = 'bg-red border-red';
      switch (cancellationBy) {
        case 'System':
          alertText = 'This session was cancelled by us.';
          break;
        case 'User':
          alertText = `${contact.firstName} ${contact.lastName} did not attend this session.`;
        case 'Practitioner':
          alertText = cancellationReason
            ? `You cancelled due to: ${cancellationReason}.`
            : 'You cancelled this session.';
        default:
          alertText = 'This session has been cancelled.';
          break;
      }
      break;
  }

  if (!alertText) {
    return <></>;
  }

  let component = actionPath ? (
    <NavLink to={actionPath} textClassName='text-primary-blue-100 text-sm' className='text-sm'>
      {alertText}
    </NavLink>
  ) : (
    <Text className='text-sm'>{alertText}</Text>
  );

  return (
    <div className='w-full'>
      <div
        className={clsx(
          'mt-4 mx-2 p-2 border border-solid rounded-md bg-opacity-25 text-center',
          alertClassName
        )}
      >
        {component}
      </div>
    </div>
  );
};

export default inject('auth')(observer(StatusAlert));
