import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import LandingScreen from './LandingScreen';
import PractitionerDNAScreen from './PractitionerDNAScreen';
import ClientDNAScreen from './ClientDNAScreen';
import FinishScreen from './FinishScreen';
import { toast } from 'react-toastify';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';

export const errorMessage =
  'Something went wrong. Please try again later or contact support at therapist-support@myclearhead.com';

type BookingCompleteProps = {
  match?: any;
  bookings?: any;
  history?: any;
};

export type BookingCompleteScreenProps = {
  setScreen: (screen: ScreenType) => void;
};

type ScreenType =
  | 'LANDING'
  | 'PRACTITIONER_DNA'
  | 'CLIENT_DNA'
  | 'COMPLETE_PRACTITIONER_DNA'
  | 'COMPLETE_CLIENT_DNA';

const BookingComplete: React.FC<BookingCompleteProps> = ({ bookings, match, history }) => {
  const [screen, setScreen] = React.useState<ScreenType>('LANDING');
  const [booking, setBooking] = React.useState();

  const bookingId = match.params.id;

  React.useEffect(() => {
    bookings.getBookingData(bookingId).then((data) => {
      if (data.status !== 'PendingCompletion') {
        toast.error('This appointment has already been marked as completed.');
        history.push('/app/bookings');
      }

      setBooking(data);
    });
  }, []);

  const renderHeadingSection = () => {
    return (
      <>
        <Heading as='h4' className='mb-4 text-dark-blue'>
          Complete your appointment
        </Heading>
        <Text className='max-w-prose mb-16'>
          Please confirm that your appointment went ahead. Before creating your invoice, we need you
          to answer a few questions
        </Text>
      </>
    );
  };

  const renderScreen = () => {
    switch (screen) {
      case 'LANDING':
        return (
          <>
            {renderHeadingSection()}
            <LandingScreen setScreen={setScreen} />
          </>
        );
      case 'PRACTITIONER_DNA':
        return (
          <>
            {renderHeadingSection()}
            <PractitionerDNAScreen setScreen={setScreen} booking={booking} />
          </>
        );
      case 'CLIENT_DNA':
        return (
          <>
            {renderHeadingSection()}
            <ClientDNAScreen setScreen={setScreen} />
          </>
        );
      case 'COMPLETE_PRACTITIONER_DNA':
        return (
          <FinishScreen message='We sometimes review cancellations, if we have any questions about this cancellation we will get in touch with you.' />
        );
      case 'COMPLETE_CLIENT_DNA':
        return <FinishScreen message='The appointment has not been invoiced.' />;
      default:
        return null;
    }
  };

  return (
    <>
      <Card>
        <CardBody className='pt-8'>{renderScreen()}</CardBody>
      </Card>
    </>
  );
};

export default withRouter(inject('auth', 'calendar', 'bookings')(observer(BookingComplete)));
