import Keycloak from 'keycloak-js';
import { KEYCLOAK_URL } from 'configuration';

const keycloakCfg = {
  url: KEYCLOAK_URL,
  realm: 'Providers',
  clientId: 'provider-portal',
};

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak(keycloakCfg);

export default keycloak;
