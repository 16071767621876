import { SVGProps } from 'react';
const LocationPin = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width='44' height='44' rx='22' fill='currentColor' />
    <path
      d='M27.9583 22H16.0417'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M22 27.9583V16.0417'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default LocationPin;
