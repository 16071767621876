import React, { Component } from 'react';
import styled from 'styled-components';

const DayBase = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	/* margin: 0 auto; */
	margin-right: 10px;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	background-color: ${props => (props.isSelected ? '#9d7bd8' : '#EEE')};
	border: 2px solid ${props => (props.isSelected ? '#9d7bd8' : '#EEE')};
	&:hover {
		cursor: pointer;
	}
`;

const DayText = styled.p`
	margin: 0;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	color: ${props => (props.isSelected ? 'white' : '#333')};
	user-select: none;
`;

class Day extends Component {
	static defaultProps = {
		isSelected: false,
	};

	render() {
		const { onClick, isSelected, children } = this.props;
		return (
			<DayBase isSelected={isSelected} onClick={onClick.bind(this, !isSelected)}>
				<DayText isSelected={isSelected}>{children}</DayText>
			</DayBase>
		);
	}
}

export default Day;
