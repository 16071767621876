import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import * as Icon from 'react-feather';

import { Question, Answer } from '../styles';

const QuestionItem = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className='mb-2'>
      <Question onClick={toggle} outline color='primary'>
        {props.question}
        {isOpen ? <Icon.ChevronUp alt='Up' /> : <Icon.ChevronDown alt='Down' />}
      </Question>
      <Collapse isOpen={isOpen} cssModule={{ collapse: 'bs-collapse' }}>
        <Answer>{props.answer}</Answer>
      </Collapse>
    </div>
  );
};

export default QuestionItem;
