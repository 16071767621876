const olderThanThirtyDays = (dateTime: string) => {
  return olderThanSpecifiedDays(dateTime, 30);
};

export const olderThanSpecifiedDays = (dateTime: string, numberOfDays: number) => {
  const thirtyDaysInMs = numberOfDays * 24 * 60 * 60 * 1000;
  const today = new Date();
  const appointmentDate = new Date(dateTime);
  const olderThanThirtyDays = today.getTime() - appointmentDate.getTime() >= thirtyDaysInMs;

  return olderThanThirtyDays;
};

export default olderThanThirtyDays;
