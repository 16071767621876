// @flow
import React, { Component } from 'react';

import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { inject, observer, Observer } from 'mobx-react';
import {
  UncontrolledAlert,
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Spinner,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import debounce from 'debounce-promise';
import { observable } from 'mobx';
import Heading from '@clearhead-ltd/ui-components/dist/v2/Heading';
import Text from '@clearhead-ltd/ui-components/dist/v2/Text';
import { faBell } from '@fortawesome/free-regular-svg-icons';

import { TimePicker, HorizontalSeparator } from 'common';
import { AsyncSearch, Control } from 'common/AsyncSearch';
import AsyncOption from './async_option';
import EligibilityOption from './EligibilityOption';

const moment = extendMoment(Moment);

const schema = Yup.object().shape({
  appointmentStartDateTime: Yup.date()
    .required('Required')
    .test('Valid Date', 'Please enter a valid date', (date) => {
      const nowLastMonth = moment().add(-1, 'months').startOf('day');
      const mDate = moment(date);
      return mDate.isSameOrAfter(nowLastMonth) ? true : false;
    }),
  price: Yup.string().required('Required'),
  location: Yup.string().required('Required'),
  startTime: Yup.date().required('Start Time Required'),
  endTime: Yup.date().required('End Time Required'),

  contact: Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    contactName: Yup.string(),
    dateOfBirth: Yup.string()
      .nullable()
      .test(
        'dateOfBirth',
        'Please enter a valid date of birth, you must be at least 1 years of age',
        (value) => {
          return value ? moment().diff(moment(value), 'years') >= 1 : true;
        }
      ),
    email: Yup.string(),
    ethnicity: Yup.string().nullable(),
    gender: Yup.string().nullable(),
    phoneNumber: Yup.string(),
    timezone: Yup.string().required(),
    tenantCode: Yup.string(),
  }),
});

const defaultState = {
  clientType: 'existing',
  email: '',
  isRepeating: false,
  appointmentStartDateTime: '',
  startTime: moment().hour(12).minute(0),
  endTime: moment().hour(13).minute(0),
  asyncInputsDisabled: true,
};

// This file is way too long and this component needs to be split in multiple components
@inject('locations', 'calendar', 'prices', 'bookings', 'clients', 'auth')
@observer
class AppointmentForm extends Component {
  @observable forceError = false;
  @observable existingClientFormState = {};
  @observable searchMenuOpen = false;
  @observable repeatValue = 'No repeat';
  @observable repeatModalOpen = false;
  @observable repeatFormState = null;
  @observable fetchingEligibility = true;
  @observable selectedId: any = undefined;
  @observable defaultSelectedTenant: any = undefined;
  @observable selectedTenant: any = undefined;
  @observable eligibilityOptions = [];

  static defaultProps = {
    onComplete: () => {},
    status: null,
  };

  constructor(props) {
    super(props);

    const wait = 1000; // milliseconds
    const loadOptions = (inputValue) => this.getAsyncOptions(inputValue);
    this.debouncedLoadOptions = debounce(loadOptions, wait, {
      leading: false,
    });

    this.checkBlockOverlaps({ values: props.formState });
  }

  componentDidMount(): void {
    if (
      this.props &&
      this.props.formState &&
      this.props.formState.contact &&
      this.props.formState.contact.id
    ) {
      this.props.clients.getClientEligibility(this.props.formState.contact).then((response) => {
        this.eligibilityOptions = response.data;

        if (this.props.formState.partnerBooking) {
          //match by the case number first, as this is where we track sessions against
          let limit = response.data
            .flatMap((item) => item.limits)
            .find((limit) => limit.caseNumber === this.props.formState.caseNumber);

          if (!limit) {
            //if we don't find the limit in the response, then find it based off the type of session it is
            if (this.props.formState.therapyFundedBy?.toLowerCase() === 'unimed') {
              limit = response.data.find(
                (x) =>
                  x.fundedBy?.toLowerCase() === this.props.formState.therapyFundedBy?.toLowerCase()
              )?.limits[0];
            } else {
              limit = response.data.find(
                (x) => x.tenantName?.toLowerCase() === this.props.formState.tenant?.toLowerCase()
              )?.limits[0];
            }
          }

          let tenant = limit
            ? response.data.find((x) => x.limits.some((y) => y.id === limit?.id))
            : undefined;
          if (tenant && tenant.tenantName) {
            this.selectedTenant = tenant.tenantName;
            this.defaultSelectedTenant = tenant.tenantName;
          }
          this.selectedId = limit ? limit.id : null;
        } else {
          this.selectedTenant = 'public';
        }
        this.fetchingEligibility = false;
      }).catch(e => {
        this.fetchingEligibility = false;
        console.warn("Failed to load client eligibility", e);
        alert('Failed to load funding options. Please contact therapist-support@myclearhead.com');
      });
    }
  }

  validate = (validateForm, handleSubmit) => {
    validateForm().then((data) => {
      const hasError = Object.keys(data).some((field) => field && field.length > 0);
      if (hasError) {
        this.forceError = true;
      } else {
        handleSubmit();
      }
    });
  };

  getAsyncOptions = (value) => {
    const {
      calendar: { getContactsBySearchTerm },
    } = this.props;

    return new Promise((resolve, reject) => {
      this.searchMenuOpen = false;
      getContactsBySearchTerm(value, false).then((response) => {
        this.searchMenuOpen = true;
        if (!response.hasError) {
          const hits = response.data.content.map((client) => ({
            label: `${client.contactName} ${client.email ? ' - ' + client.email : ''} ${
              client.phoneNumber && client.phoneNumber ? ' - ' + client.phoneNumber : ''
            }`,
            value: client.contactName,
            client,
          }));
          resolve(hits);
        }
        this.searchMenuOpen = true;
        resolve([]);
      });
    });
  };

  getSubmitError = (status) => {
    if (status && status.message) {
      return <FormFeedback>{status.message}</FormFeedback>;
    }
    return null;
  };

  getLocationOptions = () => {
    const {
      locations: { allLocations },
    } = this.props;

    if (allLocations) {
      return allLocations.map((location) => (
        <option key={location.location.id} data-location-id={location.location.id}>
          {location.nickname}
        </option>
      ));
    }
    return null;
  };

  getPriceOptions = () => {
    const {
      prices: { allPrices },
    } = this.props;

    if (allPrices) {
      return allPrices.map((price) => (
        <option key={price.id} data-price-id={price.id} data-session-length={price.sessionLength}>
          {`${price.name} - ${price.sessionLength}min - $${price.cost}`}
        </option>
      ));
    }
    return null;
  };

  onValidate = (values) => {
    let errors = {};

    if (values.appointmentStartDateTime && values.startTime) {
      const date = moment(values.appointmentStartDateTime);
      const nowLastMonth = moment().add(-1, 'months');
      if (date.isSameOrBefore(nowLastMonth)) {
        const time = moment(values.startTime);
        if (time.isSameOrBefore(nowLastMonth)) {
          errors.startTime = 'Cannot create an appointment in the past.';
        }
      }
    }

    if (values.startTime && values.endTime) {
      const start = moment(values.startTime);
      const end = moment(values.endTime);
      if (start.isSameOrAfter(end)) {
        errors.startTime = 'Start time must occur before the end time.';
      }
    }

    return errors;
  };

  closeMenu = () => {
    this.searchMenuOpen = false;
  };

  onClientSelect = (formState, option, setFieldValue) => {
    const { client } = option;

    let contact = {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      ethnicity: '',
      gender: '',
      phoneNumber: '',
      timezone: this.props.auth.currentPractitioner.timezone,
      ...client,
    };

    setFieldValue('contact', contact);
    setFieldValue('clientType', 'existing');
    setFieldValue('asyncInputsDisabled', false);

    this.props.clients.getClientDetails(client).then((response) => {
      const fullContact = response.data.responseObject;
      Object.keys(fullContact).forEach(
        (key) => fullContact[key] == null && delete fullContact[key]
      );

      let contact = {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        email: '',
        ethnicity: '',
        gender: '',
        phoneNumber: '',
        timezone: this.props.auth.currentPractitioner.timezone,
        ...fullContact,
      };

      setFieldValue('contact', contact);
      setFieldValue('clientType', 'existing');
      setFieldValue('asyncInputsDisabled', false);
    });

    this.props.clients.getClientEligibility(client).then((response) => {
      this.eligibilityOptions = response.data;

      this.fetchingEligibility = false;
    });
  };

  getAsyncSearch = (formState, setFieldValue) => {
    return (
      <>
        <HorizontalSeparator className='justify-start mt-2 mb-2'>
          Select contact
        </HorizontalSeparator>
        <AsyncSearch
          classNamePrefix='react-select'
          placeholder='e.g. Matthew Star'
          loadOptions={(value) => this.debouncedLoadOptions(value)}
          onChange={(option) => {
            setFieldValue('asyncInputsDisabled', false);
            this.onClientSelect(formState, option, setFieldValue);
          }}
          components={{
            Control,
            Option: AsyncOption,
          }}
          onBlur={this.closeMenu}
          onMenuClose={this.closeMenu}
        />
      </>
    );
  };

  onNewClientClick = (setFieldValue) => {
    setFieldValue('contact', {
      id: null,
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      ethnicity: '',
      gender: '',
      phoneNumber: '',
      timezone: this.props.auth.currentPractitioner.timezone,
    });
    setFieldValue('clientType', 'new');
  };

  getRepeatOptions = (canRepeat, editingRecurring) => {
    if (editingRecurring) {
      return <option data-repeat={true}>Repeat</option>;
    } else if (canRepeat) {
      return (
        <>
          <option data-repeat={false}>No repeat</option>
          <option data-repeat={true}>Repeat</option>
        </>
      );
    }

    return null;
  };

  resetRepeatModalState = (setFieldValue) => {
    setFieldValue('isRepeating', false);
    setFieldValue('repeatFormState', null);
    setFieldValue('editRepeatFormState', null);
    setFieldValue('repeatModalOpen', false);
  };

  onRepeatModalComplete = (setFieldValue, formState, editFormState) => {
    setFieldValue('isRepeating', true);
    setFieldValue('repeatFormState', formState);
    setFieldValue('editRepeatFormState', editFormState);
    setFieldValue('repeatModalOpen', false);
  };

  checkSessionTimeMismatch = ({ values, setFieldValue }) => {
    if (values.startTime && values.endTime && values.sessionLength) {
      const start = moment(values.startTime);
      const end = moment(values.endTime);
      const range = moment.range(start, end);
      const rangeDiffMins = range.diff('minutes');
      const sessionLength = parseInt(values.sessionLength);

      // This allows for - 10 minutes off the block length, so a 50 minute session length is OK for a 60 minute block
      if (rangeDiffMins > sessionLength + 10 || sessionLength > rangeDiffMins) {
        setFieldValue(
          'sessionTimeMismatch',
          "The times selected doesn't match the length of the price selected. Are you sure this is correct?"
        );
      } else {
        setFieldValue('sessionTimeMismatch', null);
      }
    } else if (values.sessionTimeMismatch) {
      setFieldValue('sessionTimeMismatch', null);
    }
  };

  renderEligiblity = (values: any, setFieldValue: any) => {
    return (
      <div className='flex flex-col'>
        <Text className='text-dark-blue text-sm font-bold mb-2'>
          Please select one of the available funding options:
        </Text>
        {this.eligibilityOptions.map((x: any) => (
          <EligibilityOption
            key={x.tenantName}
            data={x}
            clientId={values.contact.id}
            defaultSelectedTenant={this.defaultSelectedTenant}
            therapistName={this.props.auth.currentPractitioner.name}
            therapistEmail={this.props.auth.currentPractitioner.emailAddress}
            practitionerId={this.props.auth.currentPractitioner.id}
            selected={this.selectedTenant === x.tenantName}
            onClick={(id: string) => {
              this.selectedId = id;
              this.selectedTenant = x.tenantName;
              setFieldValue('fundedSession', true);
            }}
            className={'mb-2'}
          />
        ))}
        <EligibilityOption
          key={'public'}
          data={{
            fundedBy: 'public',
            tenantName: 'public',
            entitlementDisplayName: 'Public',
          }}
          clientId={values.contact.id}
          therapistName={this.props.auth.currentPractitioner.name}
          therapistEmail={this.props.auth.currentPractitioner.emailAddress}
          practitionerId={this.props.auth.currentPractitioner.id}
          selected={this.selectedTenant === 'public'}
          onClick={() => {
            this.selectedId = undefined;
            this.selectedTenant = 'public';
            setFieldValue('fundedSession', false);
          }}
          className={''}
        />
      </div>
    );
  };

  checkBlockOverlaps = ({ values, setFieldValue }) => {
    if (values.startTime && values.endTime) {
      const {
        calendar: { allBlocks },
      } = this.props;

      const start = moment(values.startTime);
      const end = moment(values.endTime);
      const requestedRange = moment.range(start, end);

      let collisionStart = null;
      let collisionEnd = null;
      const hasBlockCollision = allBlocks.some((block) => {
        if (values.blockId && values.blockId === block.id) {
          return false;
        }
        const blockStart = moment(block.blockageTime);
        const blockEnd = moment(block.blockageEndTime);
        const blockRange = moment.range(blockStart, blockEnd);

        const overlaps = blockRange.overlaps(requestedRange);
        if (overlaps) {
          collisionStart = blockStart;
          collisionEnd = blockEnd;
        }
        return overlaps;
      });

      if (hasBlockCollision) {
        // If setFieldValue exists, this is being called by formik, otherwise it's being called by the constructor
        if (setFieldValue) {
          setFieldValue(
            'blockCollision',
            `This time overlaps with a block from ${collisionStart.format(
              'h:mma'
            )} - ${collisionEnd.format('h:mma')}`
          );
        } else {
          defaultState.blockCollision = `This time overlaps with a block from ${collisionStart.format(
            'h:mma'
          )} - ${collisionEnd.format('h:mma')}`;
        }
      } else {
        setFieldValue && setFieldValue('blockCollision', null);
        defaultState.blockCollision = null;
      }
    } else if (values.blockCollision) {
      setFieldValue && setFieldValue('blockCollision', null);
      defaultState.blockCollision = null;
    }
  };

  checkAutoComplete = ({ values, setFieldValue }) => {
    if (values.startTime && values.endTime && values.sessionLength) {
      const start = moment(values.startTime);
      const sessionLength = Math.max(0, parseInt(values.sessionLength));

      if (!values.overrideEndTime) {
        // Round session length up to its closest 15 minute interval
        const updatedSessionLength = Math.ceil(sessionLength / 15) * 15;
        const newEnd = moment(start).add(updatedSessionLength, 'minutes');
        const endTimeOption = { label: newEnd.format('h:mma'), value: newEnd };

        setFieldValue('endTime', newEnd);
        setFieldValue('endTimeOption', endTimeOption);

        return { ...values, endTime: newEnd, endTimeOption };
      }
    }

    return values;
  };

  getTenantDisplayName = (tenantCode: string) => {
    if (tenantCode && this.eligibilityOptions && this.eligibilityOptions.length > 0) {
      const data: any = this.eligibilityOptions.find((x: any) => x.tenantName === tenantCode);
      if (data && data.tenantName) {
        return data.entitlementDisplayName;
      }
    }
    return tenantCode.toLocaleUpperCase();
  };

  renderWarningFundingProvider = () => {
    if (this.defaultSelectedTenant && this.selectedTenant && this.defaultSelectedTenant !== this.selectedTenant) {
      return (
        <UncontrolledAlert color='warning' className='alert-outline mt-4'>
          <div className='alert-icon'>
            <FontAwesomeIcon icon={faBell} fixedWidth />
          </div>
          <div className='alert-message pr-12'>
            <span>
              Notice of change, the original funding provider for this session has been updated. The claimed session will be returned to the original provider.
            </span>
            <p>
              <b>{this.getTenantDisplayName(this.defaultSelectedTenant)}</b> -> <b>{this.getTenantDisplayName(this.selectedTenant)}</b>
            </p>
          </div>
        </UncontrolledAlert>
      );
    }
  };

  renderInfo = () => {
    if (this.defaultSelectedTenant === undefined && this.selectedTenant !== undefined) {
      let text = ''
      if (this.selectedTenant !== 'public') {
        text = 'This session is funded by our EAP program. To ensure prompt payment processing, please book follow-up appointments in the Clearhead system. We appreciate your cooperation!'
      } else {
        text = 'Please note that this session is not covered by our EAP program. Invoices and payments should be arranged directly with your client. Thank you for your understanding.';
      }
      return (
        <UncontrolledAlert color='warning' className='alert-outline mt-4'>
          <div className='alert-icon'>
            <FontAwesomeIcon icon={faBell} fixedWidth />
          </div>
          <div className='alert-message pr-12'>
            <p>
              {text}
            </p>
          </div>
        </UncontrolledAlert>
      );
    };
  }


  hasError = (touched, error) => (touched || this.forceError ? (error ? true : false) : false);

  render() {
    const { calendar, formState = {}, onComplete = () => {}, status, mode = 'create' } = this.props;

    const initialValues = {
      ...defaultState,
      ...formState,
    };

    if (!initialValues.contact.timezone) {
      initialValues.contact.timezone = this.props.auth.currentPractitioner.timezone;
    }

    const submit =
      mode === 'create'
        ? calendar.createAppointment
        : mode === 'edit'
        ? status === 'Manual'
          ? calendar.acceptManualBooking
          : calendar.updateAppointment
        : calendar.convertBlock;

    const isPending = status !== null && (status === 'Pending' || status === 'Manual');
    const successMessage =
      mode === 'create'
        ? 'Appointment created'
        : mode === 'edit'
        ? 'Appointment updated'
        : 'Block Converted';

    const buttonLabel =
      mode === 'create'
        ? 'Create Appointment'
        : mode === 'edit'
        ? status === 'Manual'
          ? 'Approve Appointment'
          : 'Update Appointment'
        : 'Convert Appointment';

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize={false}
        validate={this.onValidate}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          const startTime = moment(values.startTime);
          startTime.set('second', 0);
          const endTime = moment(values.endTime);
          endTime.set('second', 0);

          const apptDate = moment(values.appointmentStartDateTime, 'YYYY-MM-DD');
          apptDate.hour(startTime.hour());
          apptDate.minute(startTime.minute());
          apptDate.second(startTime.second());
          const duration = moment.range(startTime, endTime).diff('minutes');

          const data = {
            ...values,
            appointmentLengthMins: duration,
            appointmentStartDateTime: apptDate.format('YYYY-MM-DDTHH:mm:ss'),
            location: { id: values.locationId },
            mode: 'Appointment',
            overwrite: true,
            fundedSession: this.selectedTenant === 'public' ? false : values.fundedSession,
            recurrence: values.repeatFormState,
            timezone: this.props.auth.currentPractitioner.timezone,
            fundingId: this.selectedId,
          };

          submit(data).then((response) => {
            if (!response.hasError) {
              onComplete(data);
              toast.success(successMessage);
            } else {
              response.errors.forEach((err) => {
                if (err.field === 'appointmentStartDateTime') {
                  actions.setFieldTouched('startTime', true, false);
                  actions.setFieldError('startTime', err.defaultMessage);
                  response.message = err.defaultMessage;
                } else {
                  actions.setFieldTouched(err.field, true, false);
                  actions.setFieldError(err.field, err.defaultMessage);
                  actions.setStatus({ message: response.message });
                }
              });

              toast.error(response.message);
            }
            actions.setSubmitting(false);
          });
        }}
        render={({
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          validateForm,
          setFieldValue,
          setTouched,
        }) => {
          let isEndDateDisabled = values.price && !values.overrideEndTime;

          const hasStartAndEndTimes = !!values.startTime && !!values.endTime;

          const isEditMode =
            this.props.formState && this.props.formState.contact && this.props.formState.contact.id;

          return (
            <Observer>
              {() => (
                <>
                  <Form onSubmit={() => this.validate(validateForm, handleSubmit)}>
                    <Row form>
                      <Col className='col-12'>
                        {values.clientType === 'existing' && !isEditMode && (
                          <div className='mb-3'>{this.getAsyncSearch(values, setFieldValue)}</div>
                        )}
                      </Col>
                    </Row>
                    {values.contact && values.contact.email ? (
                      <>
                        <HorizontalSeparator className='justify-start mt-2 mb-2'>
                          Select Funding Option
                        </HorizontalSeparator>

                        <Row>
                          <Col className='col-12'>
                            <div className='px-3 py-3 rounded-t-md bg-light-green'>
                              <div className='flex justify-between items-center mb-1'>
                                <Heading className='text-dark-blue text-lg' as='h5'>
                                  {values.contact.firstName} {values.contact.lastName}
                                </Heading>
                                <Text className='text-dark-blue text-sm'>
                                  {values.contact.timezone}
                                </Text>
                              </div>
                              <Text className='text-dark-blue text-sm mb-3'>
                                {values.contact.email || 'No email provided.'} | {values.contact.phoneNumber || 'No phone number provided.'}
                              </Text>
                              <div className='flex flex-col'>
                                {this.fetchingEligibility ? (
                                  <Spinner color='primary' size='sm' />
                                ) : (
                                  this.renderEligiblity(values, setFieldValue)
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                    {this.renderWarningFundingProvider()}
                    {this.renderInfo()}
                    {this.selectedId !== undefined || this.selectedTenant === 'public' ? (
                      <>
                        <HorizontalSeparator className='justify-start mt-4 mb-2'>
                          Select Appointment Details
                        </HorizontalSeparator>
                        <Row form>
                          <Col className='col-12 col-sm-6'>
                            <FormGroup>
                              <Label>Appointment Date *</Label>
                              <Input
                                bsSize='lg'
                                name='appointmentStartDateTime'
                                type='date'
                                min={moment().add(-1, 'months').format('YYYY-MM-DD')}
                                value={values.appointmentStartDateTime}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={this.hasError(
                                  touched.appointmentStartDateTime,
                                  errors.appointmentStartDateTime
                                )}
                              />
                              {this.hasError(
                                touched.appointmentStartDateTime,
                                errors.appointmentStartDateTime
                              ) ? (
                                <FormFeedback>{errors.appointmentStartDateTime}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col className='col-12 col-sm-6'>
                            <FormGroup>
                              <Label>Location *</Label>
                              <Input
                                bsSize='lg'
                                type='select'
                                name='location'
                                value={values.location}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  const dataset = e.target.selectedOptions[0].dataset;
                                  setFieldValue('location', e.target.value);
                                  setFieldValue('locationId', parseInt(dataset.locationId));
                                }}
                                invalid={this.hasError(touched.location, errors.location)}
                              >
                                <option />
                                {this.getLocationOptions(this.searchMenuOpen)}
                              </Input>
                              {this.hasError(touched.location, errors.location) ? (
                                <FormFeedback>{errors.location}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row form>
                          <Col className='col-12'>
                            <FormGroup>
                              <Label>Service *</Label>
                              <Input
                                bsSize='lg'
                                type='select'
                                name='price'
                                value={values.price}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  const dataset = e.target.selectedOptions[0].dataset;
                                  setFieldValue('price', e.target.value);
                                  setFieldValue('chargeId', parseInt(dataset.priceId));
                                  setFieldValue('overrideEndTime', false);
                                  setFieldValue('sessionLength', parseInt(dataset.sessionLength));

                                  const newValues = this.checkAutoComplete({
                                    values: {
                                      ...values,
                                      overrideEndTime: false,
                                      sessionLength: dataset.sessionLength,
                                    },
                                    setFieldValue,
                                  });

                                  this.checkBlockOverlaps({
                                    values: newValues,
                                    setFieldValue,
                                  });
                                }}
                                invalid={this.hasError(touched.price, errors.price)}
                              >
                                <option />
                                {this.getPriceOptions()}
                              </Input>
                              {hasStartAndEndTimes && values.sessionTimeMismatch ? (
                                <FormFeedback className='d-block text-danger text-center'>
                                  {values.sessionTimeMismatch}
                                </FormFeedback>
                              ) : null}
                              {this.hasError(touched.price, errors.price) ? (
                                <FormFeedback>{errors.price}</FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row className='mb-3' form>
                          <Label>Time</Label>
                          <Col className='col-sm-3 col-5'>
                            <TimePicker
                              className='form-select-lg'
                              onChange={(option) => {
                                setFieldValue('startTime', option.value);
                                setFieldValue('startTimeOption', option);

                                const newValues = this.checkAutoComplete({
                                  values: { ...values, startTime: option.value },
                                  setFieldValue,
                                });

                                this.checkSessionTimeMismatch({
                                  values: newValues,
                                  setFieldValue,
                                });

                                this.checkBlockOverlaps({
                                  values: newValues,
                                  setFieldValue,
                                });
                              }}
                              onBlur={() => setTouched({ ...touched, startTime: true })}
                              selectedTime={values.startTimeOption}
                              defaultValue={{
                                label: '12:00pm',
                                value: moment().hour(12).minute(0).second(0),
                              }}
                            />
                          </Col>
                          <Col className='col-sm-1 col-2 mt-3'>To</Col>
                          <Col className='col-sm-3 col-5'>
                            <TimePicker
                              onChange={(option) => {
                                setFieldValue('endTime', option.value);
                                setFieldValue('endTimeOption', option);
                                this.checkSessionTimeMismatch({
                                  values: {
                                    ...values,
                                    endTime: option.value,
                                  },
                                  setFieldValue,
                                });

                                this.checkBlockOverlaps({
                                  values: {
                                    ...values,
                                    endTime: option.value,
                                  },
                                  setFieldValue,
                                });
                              }}
                              onBlur={() => setTouched({ ...touched, endTime: true })}
                              selectedTime={values.endTimeOption}
                              isDisabled={isEndDateDisabled}
                              defaultValue={{
                                label: '1:00pm',
                                value: moment().hour(13).minute(0).second(0),
                              }}
                            />
                            {isEndDateDisabled && !values.overrideEndTime && (
                              <Label
                                className='form-control-lg mb-0 w-100 text-primary-blue-100 cursor-pointer px-0'
                                onClick={(e) => setFieldValue('overrideEndTime', true)}
                              >
                                Override end time
                              </Label>
                            )}
                          </Col>

                          <Col className='col-12'>
                            <FormGroup className='m-0'>
                              {hasStartAndEndTimes && errors.startTime && (
                                <FormFeedback className='d-block text-danger'>
                                  {errors.startTime}
                                </FormFeedback>
                              )}
                              {hasStartAndEndTimes && errors.endTime && (
                                <FormFeedback className='d-block text-danger'>
                                  {errors.endTime}
                                </FormFeedback>
                              )}
                              {values.blockCollision && (
                                <FormFeedback className='d-block text-danger'>
                                  {values.blockCollision}
                                </FormFeedback>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        {this.getSubmitError(status)}
                        <div className='text-center mt-10 mb-4'>
                          <Button
                            color='primary'
                            size='lg'
                            onClick={() => this.validate(validateForm, handleSubmit)}
                            disabled={isSubmitting}
                          >
                            {buttonLabel}
                          </Button>
                        </div>
                      </>
                    ) : null}
                  </Form>
                </>
              )}
            </Observer>
          );
        }}
      />
    );
  }
}

export default AppointmentForm;
